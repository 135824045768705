<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar px-24-px py-05-rem" tabindex="0">

  <div class="bg-color-background-1 d-flex mt-2">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.chronometer_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-24 ml-16 text-md-size-18 text-white align-self-center">
        Velocidad
      </h3>
    </div>
  </div>

  <div class="d-flex">
    <div class="d-flex justify-space-around full-width py-container-speed">
      <div class="container-graphic-speed">
        <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
      </div>
      <div class="container-graphic-speed">
        <sit-speedometer [properties]="avgConcordanceProps"></sit-speedometer>
      </div>
    </div>
  </div>

  <div class="bg-color-background-1 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.speed_range_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-24 lh-32-px ml-16 text-md-size-18 text-white align-self-center">
        Rango de velocidad
      </h3>
    </div>
  </div>

  <div class="full-height full-width">
    <sit-chart-column-waze [data]="listspeed" [id]="'speed-range'" [valueXAttrib]="'dta'" [valueXText]="'Número de corredores'" [valueYText]="'km/h'" className="h-350"></sit-chart-column-waze>
  </div>

  <div class="bg-color-background-1 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.road_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="camino icono título rutas principales"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-24 ml-16 text-md-size-18 lh-32-px text-white align-self-center">
        Rutas principales
      </h3>
    </div>
  </div>

  <!-- <div class="table-title d-flex fd-row text-white text-weight-400 justify-space-around bg-color-secondary-4 py-10-px">
    <span>VELOCIDAD</span>
    <span>TIEMPO</span>
    <span>RANGO</span>
    <span>CONCORDANCIA</span>
  </div> -->

  <div class="list-cards gap-10-px py-24-px" [scrollHandler]="panelActionsDispatcher">
    <ng-container *ngFor="let card of cards">
      <sit-card-traffic [card]="card"></sit-card-traffic>
    </ng-container>
  </div>
</div>
