import { IModelincidentsModel } from '../../../../../types/src/lib/models/ui/modal-incidents.model';
import { Component, Input, OnInit } from "@angular/core";
import { IncidentsLocationsModel } from '@shared/models/LocationsIncidents.model';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: "sit-modal-incidents",
  templateUrl: "./modal-incidents.component.html",
  styleUrls: ["./modal-incidents.component.scss"],
})
export class ModalIncidentsComponent implements OnInit{
  @Input() properties : IModelincidentsModel | undefined ;
  Icon = Icon;

  constructor() { }
  ngOnInit(): void {
  }

}
