<div class="container-flex text-white">
  <div class="row-top">
    <div class="card"> 
      <div class="icon-type">
      <ng-container *ngIf="properties.tipo_inter.includes('PEATONAL') ">
          <sit-icon
          [icon]="Icon.peaton_yellow"
           alt="peaton"
           sizeClass="icon-size-40"
           ngClass="icon-type"
           >
          </sit-icon>
      </ng-container>
      <ng-container  *ngIf="properties.tipo_inter.includes('BICIUSUARIOS') ">
          <sit-icon
          [icon]="Icon.bicycles_blue"
           alt="Bicicleta_azul"
           sizeClass="icon-size-40"
           ngClass="icon-type">
          </sit-icon>
      </ng-container>
      <ng-container *ngIf="properties.tipo_inter.includes('VEHICULAR')  ">
          <sit-icon
          [icon]="Icon.car_blue"
           alt="auto_verde"
           sizeClass="icon-size-40"
           ngClass="icon-type">
            </sit-icon>
      </ng-container>
    </div> 
      <span class="card-tipo text-color-primary-1">Tipo de intercecion</span>
    </div>
    <div class="card mid">
      <sit-icon
      [icon]="Icon.check_w"
       alt="peaton"
       sizeClass="icon-size-40"
       ngClass="icon-check"
      >
      </sit-icon>
      <span >{{  properties?.operacion?.toLowerCase() }}</span>
      <span class="card-tipo text-color-primary-1">Operacion</span>
    </div>
    <div class="card">
      <sit-icon
      [icon]="Icon.zoom_w"
       alt="peaton"
       sizeClass="icon-size-40"
       ngClass="icon-zoom"
       >
      </sit-icon>
      <span >{{  properties?.localidad?.toLowerCase() }}</span>
      <span class="card-tipo text-color-primary-1">Localidad</span>
    </div>
  </div>

  <div class="row-info">
    <div class="info-text">
      <span>Infra-cicloruta</span>
      <span class="text-white">{{ properties?.infra_cicl?.toLowerCase() }}</span>  
    </div>
    <div class="info-text">
      <span>Equipo</span>
      <span class="text-white">{{ properties?.codigo }}</span>  
    </div>
  </div>
</div>
