import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataset } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {
  IGraficoLineModel,
  IGraphicConfigModel
} from "@types-custom/models/ui/graphic-model";

/*
** Graphic Line
** A component that render a chart based on properties passed.
*/

@Component({
  selector: 'graphic-line',
  templateUrl: './graphic-line.component.html',
  styleUrls: ['./graphic-line.component.scss']
})
export class GraphicLineComponent implements OnInit {

  @Input() props!: IGraficoLineModel;
  @Input() config!: IGraphicConfigModel;

  @ViewChild(BaseChartDirective, { static: true }) chart!: BaseChartDirective;

  public lineChartLabels: BaseChartDirective['labels'][] = [];
  public lineChartLegend = true;

  public lineChartData: ChartDataset[] = [
    { data: [], label: '' }
  ];

  constructor() {
     /* TODO document why this constructor is empty */
    }

  ngOnInit(): void {
    const { manageGraphic, isFilter } = this.config;
    this.props.dataSource.getAPIGraphic(manageGraphic, isFilter).subscribe(this.updateData.bind(this));
  }

  updateData(data: any) {
    const { manageConfigData, manageListColor } = this.props;
    const { manageGraphic, colorGraphic, isFilter } = this.config;

    if( manageConfigData[manageGraphic].multiLines && isFilter) {
      let distinctDate = data.map( (item: any) => {return new Date(item.date.split('+')[0]); });

      distinctDate.sort((a: any, b: any) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });

      distinctDate = distinctDate.filter((n: any, i: any) => distinctDate.map(Number).indexOf(+n) === i);

      this.lineChartLabels =   distinctDate.map( (item: any) => {
        return formatDate(item, 'hh:mm', 'en-US');  ;
        });

        let distinctLabel = data.map( (item: any) => {return item[manageConfigData[manageGraphic].fieldLabel]; });
        distinctLabel = distinctLabel.filter((n: any, i: any) => distinctLabel.indexOf(n) === i);

      this.lineChartData = distinctLabel.map( (label: any) => { return new Object({'label': label,
      'data':  distinctDate.map( (item: any) => {
            return (((data.filter((n: any) => +(new Date(n.date.split('+')[0])) == +(item) && n[manageConfigData[manageGraphic].fieldLabel] ==  label)).length == 0)?
            0:
            (data.filter((n: any) => +(new Date(n.date.split('+')[0])) == +(item) && n[manageConfigData[manageGraphic].fieldLabel] ==  label))[0][manageConfigData[manageGraphic].fieldCount])
            }),
            ...manageListColor[colorGraphic]

      })});

    }
    else{

      this.lineChartData = [{'label': manageConfigData[manageGraphic].titulo,
        'data':  data.map( (item: any) => {
              return item[manageConfigData[manageGraphic].fieldCount];
              }),
              ...manageListColor[colorGraphic]
        }];

      this.lineChartLabels =   data.map( (item: any) => {
        return formatDate(new Date(item.date.split('+')[0]), 'hh:mm', 'en-US');
        });

    }
      this.chart.update();
  }

}
