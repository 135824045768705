import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingOverlayService {
  private activeHttpCalls: string[] = [];

  private loadingDisplaySubject = new BehaviorSubject<boolean>(false);
  loadingDisplayObservable = this.loadingDisplaySubject.asObservable();

  addHttpActiveCall(url: string): void {
    this.activeHttpCalls.push(url);
    this.validateLoadingDisplay();
  }

  removeHttpActiveCall(url: string): void {
    const activeCallIndex = this.activeHttpCalls.findIndex(
      (activeHttpCall) => activeHttpCall === url
    );
    this.activeHttpCalls.splice(activeCallIndex, 1);
    this.validateLoadingDisplay();
  }

  private validateLoadingDisplay(): void {
    const activeCallsCount = this.activeHttpCalls.length;
    const isLoadingVisible = activeCallsCount > 0;
    this.loadingDisplaySubject.next(isLoadingVisible);
  }
}
