import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';

@Directive({
  selector: '[SheetBottom]',
  providers: [DrawerPanelService],
})
export class SheetBottomDirective implements OnInit {
  @Input() classContainer: string | undefined;
  @Input() breakpoint = [0.08, 0.5, 0.8];
  @Input() sizeInitial = '90px';
  @Input() currentPosition = -1;

  heightEl: number | undefined;
  maxWidth = 960;
  startPosition = 0;
  next = true;

  constructor(
    private elementRef: ElementRef,
    private drawerPanelService: DrawerPanelService
  ) {}

  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer$.subscribe((status) => {
      this.collapseDrawer(status);
    });
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    if (this.maxWidth < document.documentElement.clientWidth) {
      return;
    }

    if (this.startPosition == 0) {
      this.startPosition = $event.clientY;
      this.heightEl = document.documentElement.clientHeight;
    }

    if (this.next) {
      this.currentPosition++;
      this.next = this.currentPosition < this.breakpoint.length - 1;
    } else {
      this.currentPosition = 0;
      this.next = true;
    }

    if (this.heightEl != undefined && this.currentPosition == 0) {
      (<HTMLStyleElement>(
        document.querySelector(`.${this.classContainer}`)
      )).style.height = this.sizeInitial;
    }

    if (this.heightEl != undefined && this.currentPosition != 0) {
      (<HTMLStyleElement>(
        document.querySelector(`.${this.classContainer}`)
      )).style.height =
        this.heightEl * this.breakpoint[this.currentPosition] + 'px';

      (<HTMLStyleElement>(
        document.querySelector(`.${this.classContainer}`)
      )).style.maxHeight =
        this.heightEl * this.breakpoint[this.currentPosition] + 'px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: UIEvent) {
    const media = window.matchMedia('(max-width:' + this.maxWidth + 'px)');

    if (!media.matches) {
      (<HTMLStyleElement>(
        document.querySelector(`.${this.classContainer}`)
      )).style.removeProperty('height');
    }
  }

  collapseDrawer(collapse: boolean) {
    if (!collapse) {
      return;
    }

    if (this.maxWidth < document.documentElement.clientWidth) {
      return;
    }

    this.startPosition = document.documentElement.clientHeight;
    this.heightEl = document.documentElement.clientHeight;
    this.currentPosition = 0;
    this.next = true;

    if (this.heightEl != undefined) {
      (<HTMLStyleElement>(
        document.querySelector(`.${this.classContainer}`)
      )).style.height = this.sizeInitial;
      this.drawerPanelService.collapseDrawer(false);
    }
  }
}
