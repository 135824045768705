import { Component, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';


@Component({
  selector: 'modal-forgot-pass',
  templateUrl: './modal-forgot-pass.component.html',
  styleUrls: ['./modal-forgot-pass.component.scss'],
})
export class ModalForgotPassComponent implements OnInit {

  buttonAttributes: IButtonModel | undefined = undefined;
  signinButtonProps: IButtonModel | undefined;
  infoIcon = Icon.info;
  Icon = Icon;

  ngOnInit(): void {
    this.initializeProps();
  }

  private initializeProps(): void {
    this.buttonAttributes = {
      label: 'CANCELAR',
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button_primary,
        spanClass: IconClassesEnum.text_white_primary,
      },
    };

    this.signinButtonProps = {
      label: 'ACEPTAR',
      classAttributes: {
        buttonClass: ButtonClassesEnum.primary_1_button,
        spanClass: IconClassesEnum.text_complementary_2,
      },
    };
  }

}
