<div class="d-flex fd-col vh-100 overflow-y-auto custom-scrollbar" tabindex="0">
  <p class="d-flex mb-8 text-size-14 text-gray-3 pt-md-15 text-justify lh-150 p-1-em">
    Permite consultar la cantidad y tipo de vehículos que se desplazan por un punto determinado cada minuto, mostrando ubicación y su actividad en las últimas 8 horas. Haga clic en un punto sobre el mapa para consultar la información.
  </p>

  <div class="d-flex fd-col mb-32">
    <div class="bg-color-background-1 d-flex mb-16">
      <div class="left-subtitle-block bg-color-primary-1"></div>
      <div class="d-flex full-width py-05">
        <sit-icon [icon]="Icon.density_w" class="ml-28" [sizeClass]="'icon-size-32'"
          alt="capacidad icono aforo"></sit-icon>
        <h3
          class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
          GENERAL
        </h3>
      </div>
    </div>
    <div class="d-flex fd-row justify-space-evenly">
      <div class="chart-container">
        <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
      </div>
      <div class="chart-container">
        <div class="d-flex fd-col text-white align-items-center">
          <sit-icon [icon]="Icon.car_blue" [sizeClass]="'icon-size-64'"
            alt="automóvil icono indicador flujo vehicular"></sit-icon>
          <span class="text-weight-600 text-size-24">{{
            carsFlowIndicator
            }}</span>
          <label>Flujo</label>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex fd-col mb-16">
    <div class="bg-color-background-1 d-flex mb-16">
      <div class="left-subtitle-block bg-color-primary-1"></div>
      <div class="d-flex full-width py-05">
        <sit-icon [icon]="Icon.density_w" class="ml-28" [sizeClass]="'icon-size-32'"
          alt="capacidad icono aforo"></sit-icon>
        <h3
          class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
          FLUJO
        </h3>
      </div>
    </div>
    <div class="chart-line-container d-flex fd-row full-width">
      <sit-chart-line [properties]="flowCountdataGraphicList" [colorScale]="flowCountcolorScale" class="full-width">
      </sit-chart-line>
    </div>
  </div>

  <div class="d-flex fd-col mb-16">
    <div class="bg-color-background-1 d-flex mb-16">
      <div class="left-subtitle-block bg-color-primary-1"></div>
      <div class="d-flex full-width py-05">
        <sit-icon [icon]="Icon.speed_range_w" class="ml-28" [sizeClass]="'icon-size-32'"
          alt="velocímetro icono título velocidad"></sit-icon>
        <h3
          class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
          VELOCIDAD
        </h3>
      </div>
    </div>
    <div class="chart-line-container d-flex fd-row full-width">
      <sit-chart-line [properties]="avgSpeeddataGraphicList" [colorScale]="avgSpeedcolorScale" class="full-width">
      </sit-chart-line>
    </div>
  </div>

  <div class="bg-color-background-1 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <sit-icon [icon]="Icon.density_w" class="ml-28" [sizeClass]="'icon-size-32'"
        alt="capacidad icono aforo"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        CONTEO POR LOCALZACIÓN
      </h3>
    </div>
  </div>
  <div>
    <table class="table-co2">
      <thead class="bg-color-secondary-4 text-color-white text-size-12">
        <th [style.width.%]="45">Localización</th>
        <th>Velocidad</th>
        <th>Conteo general</th>
      </thead>
      <tbody class="text-color-white text-size-12">
        <ng-container *ngFor="let item of cards">
          <tr (click)="openModal(item)">
            <td>{{ item.address }}</td>
            <td>{{ item.avgSpeed }}</td>
            <td>{{ item.flowCount }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
