<div class="d-flex fd-row f-wrap flex-sm-nowrap align-items-center justify-center bg-color-filter">
  <div class="text-modal-succsess">
    <sit-icon
      [icon]="Icon.help_modal"
      [sizeClass]="'icon-size-400 icon-sm-size-23'"
      class="icon-user-check"
    ></sit-icon>
    <span class="text-white text-size-20 text-sm-size-20 text-weight-600 ml-2-em">
        ¿ Está seguro de {{properties.title}} el recurso ?
    </span>
    <form class="login-form mt-3-em ml-2-em" [formGroup]="supportForm">
      <p class="text-white text-size-15 text-sm-size-15 text-weight-500">Describa el motivo para {{properties.title}} el recurso físico *</p>
        <div class="mat-input d-flex fd-row align-items-center mb-16">
            <mat-form-field
                    class="textWhite"
                    appearance="outline"
                    [ngClass]="{
                'error-field':
                supportForm.controls.motive.invalid &&
                supportForm.controls.motive.touched
              }"
            >
              <mat-label class="text-white">Motivo</mat-label>
              <textarea matInput formControlName="motive"></textarea>
              <!-- <input matInput formControlName="motive" /> -->
              <mat-error>
                {{ motiveErrorMessage }}
              </mat-error>
            </mat-form-field>
        </div>

    </form>
  </div>
</div>

<div class="modal-actions d-flex align-items-end justify-end mt-3-em mr-2-em">
  <ng-container>
    <sit-button
            (click)="onCancel()"
            [buttonProperties]="buttonCancelAttributes"
    ></sit-button>
  </ng-container>
  <ng-container>
    <sit-button
            (click)="
            supportForm.valid ? changeUserState() : validateFormGroup()
        "
            [disabled]="!supportForm.valid"
            [buttonProperties]="buttonSaveAttributes"
    ></sit-button>
  </ng-container>
</div>
