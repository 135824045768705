import {Component, Input} from "@angular/core";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-dynamic-kpi",
  templateUrl: "./dynamic-kpi.component.html",
  styleUrls: ["./dynamic-kpi.component.scss"],
})
export class DynamicKPIComponent {
  @Input() type : number = 0
  @Input() barLoader : boolean = false

  @Input() data : any;
  protected readonly Icon = Icon;
}
