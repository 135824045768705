import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsCranesNumberModel {
  Qty: number;
  density: number;
  LocNombre: string;
  id: string;
  name: string;
  updatedDate: string;
  agentType: string;
  title: string;
  markerPopupText: string;
  icon: unknown;
}

export class CranesNumberModel extends MarkerModelBase {
  static className = 'CranesNumberModel';
  classProperties: IPropsCranesNumberModel;

  constructor(props: IPropsCranesNumberModel, geometry: IGeometryModel) {
    super(geometry, CranesNumberModel.className);
    this.classProperties = props;
  }
}
