import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { AgentsService } from '@shared/services/layer-service/agents-service/agents.service';
import { GeometryTypeEnum } from '@types-custom/models/business/marker.model';
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: "map-filter-alerts-waze",
  templateUrl: "./alerts-waze.component.html",
  styleUrls: ["./alerts-waze.component.scss"],
})
export class AlertsWazeComponent implements OnInit {
  @Input() properties: any;
  Icon = Icon;
  options: any[];
  filteredOptions: Observable<any[]>;
  agent = new FormControl<string>('');
  fb = new UntypedFormBuilder();
  showConventions!:boolean;

  IncidentButtonProps: IButtonModel = {
    label: 'Accidente',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  RoadCloseButtonProps: IButtonModel = {
    label: 'Vía cerrada',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  ClimaticButtonProps: IButtonModel = {
    label: 'Climática',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  CleanButtonProps: IButtonModel = {
    label: 'Limpiar',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  validateActiveIncidents!: boolean;
  validateActiveRoadClosed!: boolean;
  validateActiveClimatic!: boolean;
  validateActiveLocations!: boolean;

  locations: any[] = [];
  filter: any[] = [];

  constructor(private agentsService: AgentsService,
  ) {}

  ngOnInit() {

    this.validateActiveIncidents = JSON.parse(localStorage.getItem('incidents'));
    this.validateActiveRoadClosed = JSON.parse(localStorage.getItem('roadClosed'));
    this.validateActiveClimatic = JSON.parse(localStorage.getItem('Climatic'));

    if(localStorage.getItem('locality') != undefined && localStorage.getItem('locality') != ''){
      this.agent.setValue(JSON.parse(localStorage.getItem('locality')));
    }
    
    // this.agentsService.getAgentsLocation().pipe().subscribe((list:any)=>{
    //   this.locations = list.features;
    // });

    this.loadDataLocations();

    switch (this.properties.layer) {
      case 'Agents':
        this.options = this.properties.data[0].map((e: any) => {
          return { id: e.classProperties.name, name: e.classProperties.name };
        });
        break;
      case 'AgentsHistorical':
        this.options = [
          ...new Set(
            this.properties.data[0]
              .filter((e: any) => e.geometryType === GeometryTypeEnum.Point)[0]
              .feature.features.map((e: any) => e.properties.id)
          ),
        ]
          .sort((a: any, b: any) => a.localeCompare(b))
          .map((e: any) => {
            return { id: e, name: e };
          });

        break;
    }

    this.filteredOptions = this.agent.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.name;

        return name
          ? this._filterOptions(name as string)
          : this.options.slice();
      })
    );
  }

  loadDataLocations(){
    this.locations = [
      {
        "LocNombre": "ANTONIO NARIÑO"
      },
      {
        "LocNombre": "BARRIOS UNIDOS"
      },
      {
        "LocNombre": "BOSA"
      },
      {
        "LocNombre": "CANDELARIA"
      },
      {
        "LocNombre": "CHAPINERO"
      },
      {
        "LocNombre": "CIUDAD BOLÍVAR"
      },
      {
        "LocNombre": "ENGATIVÁ"
      },
      {
        "LocNombre": "FONTIBÓN"
      },
      {
        "LocNombre": "KENNEDY"
      },
      {
        "LocNombre": "LOS MÁRTIRES"
      },
      {
        "LocNombre": "PUENTE ARANDA"
      },
      {
        "LocNombre": "RAFAEL URIBE URIBE"
      },
      {
        "LocNombre": "SAN CRISTÓBAL"
      },
      {
        "LocNombre": "SANTA FE"
      },
      {
        "LocNombre": "SUBA"
      },
      {
        "LocNombre": "SUMAPAZ"
      },
      {
        "LocNombre": "TEUSAQUILLO"
      },
      {
        "LocNombre": "TUNJUELITO"
      },
      {
        "LocNombre": "USAQUÉN"
      },
      
      {
        "LocNombre": "USME"
      }
    ];
  }

  displayFn(alert: any): string {
    return alert && alert.LocNombre ? alert.LocNombre : '';
  }

  private _filterOptions(agent: string): any[] {
    return this.options.filter((option) =>
      option.name.toLowerCase().includes(agent.toLowerCase())
    );
  }

  filterAgent(agent: any): void {
    let filter: string[] = [];

    if (agent) {
      filter = ['==', 'name', agent.name];
    }

    switch (this.properties.layer) {
      case 'Agents':
        this.properties.mapFilterDispatcher$.next({ filter: filter });
        break;
      case 'AgentsHistorical':
        this.filterAgentsHistorical({
          layer: this.properties.layer + '-Heat',
          filter: undefined,
          visibility: filter.length <= 0,
        });

        this.filterAgentsHistorical({
          layer: this.properties.layer,
          filter: filter,
          visibility: filter.length > 0,
        });

        this.filterAgentsHistorical({
          layer: this.properties.layer + '-Lines',
          filter: filter,
          visibility: filter.length > 0,
        });
        break;
    }
  }

  filterAgentsHistorical(filter: any) {
    this.properties.mapFilterDispatcher$.next(filter);
  }

  OnFilter(FilterType:any){
    if (FilterType == "incidents"){
      if(!this.validateActiveIncidents){
        this.validateActiveIncidents = true;
        this.validateActiveRoadClosed = false;
        this.validateActiveClimatic = false;

        localStorage.setItem('incidents','true');
        localStorage.setItem('roadClosed','false');
        localStorage.setItem('Climatic','false');

        let filter: any[] = [];

        //console.log(this.filter.length);

        if(this.filter.length >= 1 && this.filter.length <= 3){
            this.filter = this.filter.filter((item1:any)=> item1[1] != "type")
            this.filter.push(["==","type","ACCIDENT"])
            
        }
          // this.filter.push(["==","type","ACCIDENT"])
        else if(this.filter.length == 0){
          this.filter = ["all",
                 ["==","type","ACCIDENT"],
          ];
        }        

        //console.log(this.filter);
        
        this.properties.mapFilterDispatcher$.next({ filter: this.filter });
        // this.setGraphic(this.cards);
      }
    }else if(FilterType == "roadClosed"){
      if(!this.validateActiveRoadClosed){
        this.validateActiveRoadClosed = true;
        this.validateActiveIncidents = false;
        this.validateActiveClimatic = false;

        localStorage.setItem('roadClosed','true');
        localStorage.setItem('incidents','false');
        localStorage.setItem('Climatic','false');

        let filter: string[] = [];

        if(this.filter.length >= 1 && this.filter.length <= 3){
            this.filter = this.filter.filter((item1:any)=> item1[1] != "type")
            this.filter.push(["==","type","ROAD_CLOSED"])

        } else if(this.filter.length == 0){
          this.filter = ["all",
          ["==","type","ROAD_CLOSED"],
          ];
        }    

        // if(this.filter.length > 0){
        //   this.filter.push(["==","type","ROAD_CLOSED"])
        // }else{
        //   this.filter = ["all",
        //          ["==","type","ROAD_CLOSED"],
        //   ];
        // }

        // filter = ['==', 'type', "ROAD_CLOSED"];

        //console.log(this.filter);
        
        this.properties.mapFilterDispatcher$.next({ filter: this.filter });
        
        // this.properties.mapFilterDispatcher$.next({ filter: filter });
        // this.setGraphic(this.cards);
      }
    }
    else{
      if(!this.validateActiveClimatic){
        this.validateActiveClimatic = true;
        this.validateActiveIncidents = false;
        this.validateActiveRoadClosed = false;

        localStorage.setItem('Climatic','true');
        localStorage.setItem('incidents','false');
        localStorage.setItem('roadClosed','false');

        let filter: string[] = [];

        filter = ['==', 'type', "WEATHERHAZARD"];

        if(this.filter.length >= 1 && this.filter.length <= 3){
          
          this.filter = this.filter.filter((item1:any)=> item1[1] != "type")
          this.filter.push(["==","type","WEATHERHAZARD"])
                
        
        // }else if(this.filter.length == 3){
        //   this.filter.forEach((item:any)=>{
        //     if(item[1] == "type"){
        //       // console.log(item);
        //       this.filter = this.filter.filter((item1:any)=> item1[1] != "type")
        //       this.filter.push(["==","type","WEATHERHAZARD"])
        //       // console.log(this.filter);
        //     }
        //   })
        // }s
        }else if(this.filter.length == 0){
          this.filter = ["all",
          ["==","type","WEATHERHAZARD"],
          ];
        }    

        // if(this.filter.length > 0){
        //   this.filter.push(["==","type","WEATHERHAZARD"])
        // }else{
        //   this.filter = ["all",
        //          ["==","type","WEATHERHAZARD"],
        //   ];
        // }

        //console.log(this.filter);
        
        this.properties.mapFilterDispatcher$.next({ filter: this.filter });
        
        // this.properties.mapFilterDispatcher$.next({ filter: filter });
        // this.setGraphicRoute(this.cards);
      }
    }
  }

  filterAlert(alert: any): void {
    let filter: string[] = [];

    //console.log(alert);

    filter = ['==', 'locality', alert.LocNombre];

    if(this.filter.length >= 1 && this.filter.length <= 3){
        this.filter = this.filter.filter((item1:any)=> item1[1] != "locality")
        this.filter.push(['==', 'locality', alert.LocNombre]); 
    }
    else if(this.filter.length == 0){
      this.filter = ["all",
                    ["==","locality",alert.LocNombre],
      ];
    } 

    // if(this.filter.length > 0){
    //   this.filter.push(['==', 'locality', alert.LocNombre]);
    // }else{
    //   this.filter = ["all",
    //                 ["==","locality",alert.LocNombre],
    //   ];
    // }

    //console.log(this.filter);

    localStorage.setItem('locality',JSON.stringify(alert));
    //console.log(JSON.stringify(localStorage.getItem('locality')));
        
    this.properties.mapFilterDispatcher$.next({ filter: this.filter });
  }

  resetFilter(){
    let filter: string[] = [];
    this.filter = [];
    this.properties.mapFilterDispatcher$.next({ filter: filter });
    this.validateActiveClimatic = false;
    this.validateActiveIncidents = false;
    this.validateActiveRoadClosed = false;
    localStorage.setItem('Climatic','false');
    localStorage.setItem('incidents','false');
    localStorage.setItem('roadClosed','false');
    this.agent.reset('');
  }

  resetFilterLocations(){
    let filter: string[] = [];
    this.filter = this.filter.filter((item1:any)=> item1[1] != "locality")
    this.properties.mapFilterDispatcher$.next({ filter: this.filter });
    this.agent.reset('');
  }

  showConventionsPanel(){
    if(!this.showConventions){
      this.showConventions = true;
    }else{
      this.showConventions = false;
    }
  }
}
