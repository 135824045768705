import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ILocationPoint } from '@types-custom/models/business/location.model';
import {
  AbstractPanelManagementDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, catchError, concatMap, delay, forkJoin, map, mergeMap, Observable, of, pipe, tap, throwError } from 'rxjs';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';
import {
  IDynamicFieldAnswer,
  IDynamicFieldAnswers,
  IIncidentDataBody,
  IInvolvedVehicle,
} from '@types-custom/models/business/manage-incident-databody.model';

import {
  IIncidentParameter
} from '@shared/models/incident-parameter.model';

import { isoFormatDate, sumHoursDateTime } from '@shared/utils/functions/format-date';
import { IncidentStateStatModel } from '@types-custom/models/ui/incidents-model';
import { IDynamicFormModel } from '@types-custom/models/ui/generic-form.model';

@Injectable({ providedIn: 'root' })
export class IncidentsManageService
  implements AbstractPanelManagementDataSource<any>{

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) { }

  private readonly BASE_INCIDENT_URL = this.environment.incidentUrl;
  private readonly INCIDENT_URL = `${this.BASE_INCIDENT_URL}/incidents/`;
  private readonly INCIDENT_CATEGORY_URL = `${this.BASE_INCIDENT_URL}/incident-categories/`;

  private dataSubject = new BehaviorSubject<any[]>([]);
  data$ = this.dataSubject.asObservable();
  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<any | ILocationPoint<string> | undefined>;
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  formatDate = isoFormatDate();
  hoursSum = sumHoursDateTime(this.formatDate.time, 1);
  formFiltersValue: any | undefined = {
    recordsPerPage: this.pageInfo.getValue().pageSize,
    page: this.pageInfo.getValue().page,
    beginDate: isoFormatDate().fullDate,
  };

  private apiSelectOptionsMap: {
    [key: string]: (argument: any, argument2?: any) => Observable<any>;
  } = {
      [IncidentsListEnum.NUM_VEHICULO_INPLICADO]: this.getInvolvedVehicle.bind(this),
      [IncidentsListEnum.NUM_LESIONADOS]: this.getInjuredAmount.bind(this),
      [IncidentsListEnum.NUM_FALLECIDOS]: this.getDeadAmount.bind(this),
      [IncidentsListEnum.ESTADO_CANCELACION_SERVICIO]: this.getTypeCancelService.bind(this),
      [IncidentsListEnum.AGENTES_TRANSITO]: this.getAgentsList.bind(this),
      [IncidentsListEnum.AGENTES_POLICIA]: this.getAgentsList.bind(this),
      [IncidentsListEnum.ESTADO_ACUERDO_INCIDENTE]: this.getStatusAgreement.bind(this),
    };

  private getUrl(endpoint: string, api?: string): string {
    if (api)
      return `${api}${endpoint}`
    return `${this.INCIDENT_URL}${endpoint}`;
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
  }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  buildExcelPdfUrl(fileType: string, paramUrl?: boolean): string {
    let urlBase = this.getUrl('export/', paramUrl && this.INCIDENT_CATEGORY_URL);
    if (fileType === fileTypes.EXCEL) {
      urlBase += 'excel';
    } else if (fileType === fileTypes.PDF) {
      urlBase += 'pdf';
    }
    return urlBase;
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value;
    const url = this.getUrl('pagination/data');
    return this.httpClient
      .get<IPaginatorModel>(url, {
        params: { ...this.formFiltersValue, recordsPerPage: pageInfo.pageSize, page: pageInfo.page }
      })
      .pipe(
        tap((response: any) => {
          const _pageInfo: IPaginatorModel = {
            page: response.currentPage,
            pageSize: pageInfo.pageSize,
            totalCount: response.totalRecords,
          };
          this.pageInfo.next(_pageInfo);
          this.dataSubject.next(response.records ?? []);
        })
      );
  }

  getAnyIncidentList(listCategoryCode: string, id: number | undefined): Observable<{ value: string, name: string }[]> {
    const params: { [key: string]: string | number } = { incidentTypeCode: listCategoryCode };
    id && (params.parentId = (['22', '23'].includes(`${id}`) ? 7 : id)); //TODO: link objects with parent id's on DB

    if (listCategoryCode == 'Corredor'){
      return this.httpClient.get<any[]>(
        `${this.environment.trafficUrl}/corridor-geom/all`
      )
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.composedName,
          })
          )
        ),
      );
    }
    return this.httpClient
      .get<any[]>(
        this.getUrl('by-type-or-parent', this.INCIDENT_CATEGORY_URL),
        { params: params }
      )
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.name,
          }))
        )
      );
  }

  getStateCounter() {
    return this.httpClient
      .get<IncidentStateStatModel[]>(
        this.getUrl('count-by-state'),
        { params: { ...this.formFiltersValue } })
      .pipe(
        map((response) =>
          response.map((item) => ({ ...item } as IncidentStateStatModel)
          )
        )
      );
  }

  getTypeCancelService(): Observable<any[]> {
    return of([
      { value: 'true', name: 'Si' },
      { value: 'false', name: 'No' },
    ]);
  }

  getStatusAgreement(): Observable<any[]> {
    return of([
      { value: 'true', name: 'Si, hubo acuerdo' },
      { value: 'false', name: 'No' }
    ]);
  }

  getDeadAmount(_: string, parentValue?: number | undefined): Observable<any[]> {
    return this.getInjuredAmount();
  }

  getInjuredAmount(): Observable<any[]> {
    return of(Array.from(Array(21).keys(), n => { return { value: n.toString(), name: n.toString() }; }));
  }

  getInvolvedVehicle(type: string, parentValue: number): Observable<any[]> {
    let arrSize = 0;
    switch (+parentValue) {
      case 1:
      case 173: // In case of Siniestro
        return this.getAnyIncidentList(type, undefined).pipe(map(list => list.map(n => { return { value: n.name, name: n.name } })))
      case 2: // varado, it needs to be wrote due to DB restrictions
        arrSize = 1;
        break;
      default:
        break;
    }
    return of(Array.from(Array(arrSize).keys(), n => { return { value: (n + 1).toString(), name: (n + 1).toString() }; }));
  }

  setParam(parameter: IIncidentParameter): Observable<IIncidentParameter> {
    return this.httpClient.put<IIncidentParameter>(
      `${this.INCIDENT_CATEGORY_URL}change-state/${parameter.id}`, {},
      { params: { state: parameter.state } }
    );
  }

  getAgentsList(agentAuthorityTypeId: string): Observable<any> {
    return this.httpClient
      .get<any[]>(
        `${this.environment.agentsUrl}/agents/type/${agentAuthorityTypeId}`
      )
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.name,
          }))
        )
      );
  }

  getSelectOptions(optionType: string, value?: number) {
    if (!optionType) return of([]);

    if (!this.apiSelectOptionsMap[optionType]) {
      return this.getAnyIncidentList(optionType, value);
    }
    const fn = this.apiSelectOptionsMap[optionType];

    return fn ? fn(optionType, value) : of([]);
  }

  getValueById(id: string, params: { [key: string]: string }): Observable<any> {
    const URL = `${this.BASE_INCIDENT_URL}/incident-categories-features/${id}/state`;
    return this.httpClient.get(URL, { params }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 404) {
      console.log('Recurso no encontrado.');
    } else {
      console.error('An error occurred at fetch:', error.error.message);
      const err = new Error('test'); throwError(() => err);
    }
    return of(null);
  }

  getIndividualData(rowData: any) {
    return this.httpClient
      .get<any>(this.getUrl(rowData.id))
      .pipe(
        mergeMap((response) => {
          const dataBody: IIncidentDataBody = {
            ...response
          };

          dataBody.beginDate = response.creationDate;
          dataBody.endDate = response.updateDate ?? response.creationDate;

          let index = dataBody.beginDate?.lastIndexOf(":");
          dataBody.beginDate = dataBody.beginDate?.slice(0, index);

          index = dataBody.endDate?.lastIndexOf(":");
          dataBody.endDate = dataBody.endDate?.slice(0, index);

          dataBody.involvedAmount = response.involvedVehicles?.length;
          response.involvedVehicles.forEach((involved: IInvolvedVehicle, index: number) => {
            const nameKeyInvolvedVehicle = `vehicleTypeId${index + 1}`;
            const nameKeyInjuredAmount = `injuredAmount${index + 1}`;
            const nameKeyDeadAmount = `deadAmount${index + 1}`;
            dataBody[nameKeyInvolvedVehicle] = `${involved.vehicleTypeId}`;
            dataBody[nameKeyInjuredAmount] = involved.amountInjuredAdult;
            dataBody[nameKeyDeadAmount] = involved.deadCount;
          });
          return this.httpClient.get<IDynamicFieldAnswers>(`${this.BASE_INCIDENT_URL}/fields/save-answers/${response.id}`).pipe(map(
            (dfields: IDynamicFieldAnswers) => {
              const remappedDynamicFields: { [key: string]: any } = {}
              dfields.answers.forEach(f => remappedDynamicFields[`dynamic_${f.fieldId}`] = isNaN(+f.answer) ? f.answer : +f.answer)
              return { ...remappedDynamicFields, ...dataBody }
            }
          ));
        }
        )
      );

  }

  searchFromFilters(formFiltersValue: any) {
    if (formFiltersValue) {
      Object.keys(formFiltersValue).forEach((key) => {
        formFiltersValue[key] = formFiltersValue[key] ?? null;
      });

      if (formFiltersValue.beginDate) {
        formFiltersValue.beginDate = formFiltersValue.beginDate.includes(':00.000z') ? formFiltersValue.beginDate : `${formFiltersValue.beginDate}:00.000z`;
      }
      if (formFiltersValue.endDate) {
        formFiltersValue.endDate = formFiltersValue.endDate.includes(':00.000z') ? formFiltersValue.endDate : `${formFiltersValue.endDate}:00.000z`;
      }
      if (
        Object.keys(formFiltersValue)
          .map((prop) => formFiltersValue[prop])
          .every((value) => !value)
      ) {
        formFiltersValue.endDate = isoFormatDate().zeroTimeDate;
      }
    } else {
      formFiltersValue = { endDate: `${this.formatDate.date}T${this.hoursSum}` };
    }

    Object.keys(formFiltersValue).forEach((k: string) => {
      if (!formFiltersValue[k])
        delete formFiltersValue[k];
    });
    this.formFiltersValue = formFiltersValue;
    this.selected$.next(formFiltersValue);
  }

  private mapVehicles(formValue: any, mainKey: string, subInjuKey: string, subDeadKey: string): IInvolvedVehicle[] {
    const vehicles: IInvolvedVehicle[] = [];
    Object.keys(formValue).map((key) => {
      if (key.includes(mainKey)) {
        const idInvolved = key.replace(mainKey, '');
        vehicles.push({
          id: +idInvolved,
          vehicleTypeId: formValue[key],
          amountInjuredAdult: +formValue[`${subInjuKey}${idInvolved}`],
          deadCount: +formValue[`${subDeadKey}${idInvolved}`],
          amountInjuredChildren: 0,
        });
        delete formValue[key];
        delete formValue[`${subInjuKey}${idInvolved}`];
        delete formValue[`${subDeadKey}${idInvolved}`];
      }
    });
    return vehicles;
  }

  private mapDynamicFields(formValue: any): IDynamicFieldAnswer[] {
    const dynamicFields: IDynamicFieldAnswer[] = [];
    Object.keys(formValue).map((key) => {
      if (key.includes('dynamic_')) {
        const fieldKey = key.split('_')[1];
        dynamicFields.push({
          fieldId: fieldKey,
          answer: `${formValue[key]}`
        });
        delete formValue[key];
      }
    })
    return dynamicFields;
  }

  getDynamicFields(): Observable<IDynamicFormModel[]> {
    const URL = `${this.BASE_INCIDENT_URL}/fields/all`;
    return this.httpClient.get<IDynamicFormModel[]>(URL);
  }

  submitCreateForm(formValue: any) {
    const url = this.INCIDENT_URL + '';
    const urlAnswers = `${this.BASE_INCIDENT_URL}/fields/save-answers`;


    const involvedVehiclesArr: IInvolvedVehicle[] = this.mapVehicles(formValue, 'vehicleTypeId', 'injuredAmount', 'deadAmount');
    const dynamicResponses = this.mapDynamicFields(formValue);


    const iDate = new Date(formValue.incidentTime);
    iDate?.setHours(iDate.getHours() - 5);
    iDate?.setSeconds(0);
    formValue.endDate

    const dataBody: IIncidentDataBody = {
      ...formValue,
      id: 0,
      sourceId: +formValue.sourceId ?? 0,
      stateId: +formValue.stateId ?? 0,
      classesId: +formValue.classesId ?? 0,
      // locationName: formValue.Localidad ?? 'localidaddd',
      involvedVehicles: involvedVehiclesArr ?? [],

      latitude: formValue.latitude || '0',
      longitude: formValue.longitude || '0',

      incidentTime: (iDate).toISOString() ?? isoFormatDate().fullDate,
    };
    return this.httpClient.post(url, dataBody).pipe(
      concatMap((incidentResult: { [key: string]: any }) => {
        return this.httpClient.post(urlAnswers, {
          incidentId: incidentResult.id,
          answers: dynamicResponses
        }).pipe(map(response => { return { ...incidentResult, ...response } }))
      })
    );
  }

  submitEditForm(formValue: any) {
    const url = this.INCIDENT_URL;
    const urlAnswers = `${this.BASE_INCIDENT_URL}/fields/save-answers`;

    const involvedVehiclesArr: IInvolvedVehicle[] = this.mapVehicles(formValue, 'vehicleTypeId', 'injuredAmount', 'deadAmount');
    const dynamicResponses = this.mapDynamicFields(formValue);

    const dataBody: IIncidentDataBody = {
      ...formValue,
      sourceId: +formValue.sourceId ?? 0,
      stateId: +formValue.stateId ?? 0,
      classesId: +formValue.classesId ?? 0,
      // locationName: formValue.Localidad ?? 'localidad',
      involvedVehicles: involvedVehiclesArr ?? [],
      incidentTime: formValue.incidentTime,
    };
    return this.httpClient.put(url, dataBody).pipe(
      concatMap((incidentResult: { [key: string]: any }) => {
        return this.httpClient.post(urlAnswers, {
          incidentId: incidentResult.id,
          answers: dynamicResponses
        }).pipe(map(response => { return { ...incidentResult, ...response } }))
      })
    );
  }

  submitCreateParameter(formValue: any): Observable<IIncidentParameter> {
    const url = this.INCIDENT_CATEGORY_URL;

    const dataBody: IIncidentParameter = {
      id: 0,
      state: true,
      name: formValue.name,
      incidentTypeCode: formValue.incidentTypeCode
    };
    return this.httpClient.post<IIncidentParameter>(url, dataBody);
  }

  getAutocompleteControlList(
    controlFormKey: string,
    controlValueToSearch: string
  ) {
    if (controlValueToSearch === 'a') {
      return of([
        {
          value: 'a1-value',
          name: 'a1',
        },
        {
          value: 'a2-value',
          name: 'a2',
        },
      ]);
    } else if (controlValueToSearch === 'ab') {
      return of([
        {
          value: 'ab1-value',
          name: 'ab1',
        }
      ]);
    } else {
      return of([]);
    }
  }

  getDateTime() {
    return isoFormatDate();
  }

  clearFilter() {
    this.formFiltersValue = {};
  }
}
