import { Component, Input, OnInit } from '@angular/core';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  IModalAforoModel,
  IOptionTypeVehicle,
  vehicleTypeEnum,
} from '@types-custom/models/ui/modal-aforo-model';
import { ISpeedometerModel } from '@types-custom/models/ui/speedometer-model';
@Component({
  selector: 'modal-aforo',
  templateUrl: './modal-aforo.component.html',
  styleUrls: ['./modal-aforo.component.scss'],
})
export class ModalAforoComponent implements OnInit {
  @Input() properties: IModalAforoModel;

  graphicChartMap: { [key: string]: () => void } = {
    ['FLUJO']: this.graphicFlowCountChartLine.bind(this),
    ['VELOCIDAD']: this.graphicAvgSpeedChartLine.bind(this),
  };

  Icon = Icon;

  typeOptions: Array<string> = ['FLUJO', 'VELOCIDAD'];
  defaultOption = 'FLUJO';

  graphicDataGraphicList: ITimeLineGraphicModel;
  graphicColorScale: any = [{}];

  dataAvgCountAndSpeed: any[] = [];

  carsFlowIndicator = 0;
  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    value: 0,
  };

  btnTypeIndicator: IOptionTypeVehicle[] = [
    {
      type: vehicleTypeEnum.CARS,
      icon: Icon.car_blue,
      alt: 'icono indicador automóvil',
      active: true,
      value: 0,
      tooltip:'Automovil',
    },
    {
      type: vehicleTypeEnum.MOTORBIKES,
      icon: Icon.check_w,
      alt: 'icono indicador motocicleta',
      active: false,
      value: 0,
      tooltip:'Moto',
    },
    {
      type: vehicleTypeEnum.VANS,
      icon: Icon.check_w,
      alt: 'icono indicador camioneta tipo van',
      active: false,
      value: 0,
      tooltip:'Vans',
    },
    {
      type: vehicleTypeEnum.TRUCKS,
      icon: Icon.check_blue,
      alt: 'icono indicador camión',
      active: false,
      value: 0,
      tooltip:'Camión',
    },
    {
      type: vehicleTypeEnum.BUS,
      icon: Icon.bus_blue,
      alt: 'icono indicador bus',
      active: false,
      value: 0,
      tooltip:'Autobus',
    },
    {
      type: vehicleTypeEnum.UNKNOWN,
      icon: Icon.point_options_black,
      alt: 'icono indicador otros',
      active: false,
      value: 0,
      tooltip:'Otros',
    },
  ];

  constructor() {
    //
  }

  ngOnInit(): void {
    this.properties.aforoDataSource
      .getModalAforoMeasurements(this.properties.id)
      .subscribe((res: any) => {
        this.setIndicators(res.grid);
        this.dataAvgCountAndSpeed = res?.avgCountAndSpeed;
        this.graphicChartMap[this.defaultOption]();
      });
  }

  setIndicators(indicators: any[]) {
    let count = 0;
    let sumAvgSpeed = 0;

    this.btnTypeIndicator.map((item) =>
      indicators.map((indicator) => {
        if (item.type === indicator.vehicleType) {
          item.value = item.value + indicator.countTotal;
          this.carsFlowIndicator =
            this.carsFlowIndicator + indicator.countTotal;
          sumAvgSpeed = sumAvgSpeed + indicator.avgSpeed;
          count++;
        }
      })
    );

    this.avgSpeedProps.value = Math.round(sumAvgSpeed / count);
  }

  optionTypeSelected(optionType: any) {
    this.defaultOption = optionType.value;
    this.graphicChartMap[this.defaultOption]();
  }

  selectedVehicleType(indicator: any) {
    this.btnTypeIndicator.map((item: any) => {
      item.active = indicator.type === item.type ? true : false;
    });
    this.graphicChartMap[this.defaultOption]();
  }

  graphicFlowCountChartLine(): void {
    const dataGraphic: { time: string; value: number }[] = [];
    this.dataAvgCountAndSpeed.map((element: any) => {
      if (
        element.vehicleType ===
        this.btnTypeIndicator.find((item) => item.active == true).type
      ) {
        dataGraphic.push({
          time: element.hour,
          value: element.countTotal,
        });
      }
    });

    if (dataGraphic.length <= 0) {
      dataGraphic.push({
        time: '0',
        value: 0,
      });
    }

    this.graphicDataGraphicList = {
      data: dataGraphic,
      label: '',
      labelX: 'Hora',
      labelY: '',
    };
    this.graphicColorScale = [
      { minValue: 0, maxValue: 10000, color: '#FF9901' },
    ];
  }

  graphicAvgSpeedChartLine(): void {
    const dataGraphic: { time: string; value: number }[] = [];
    this.dataAvgCountAndSpeed.map((element: any) => {
      if (
        element.vehicleType ===
        this.btnTypeIndicator.find((item) => item.active == true).type
      ) {
        dataGraphic.push({
          time: element.hour,
          value: element.avgSpeed,
        });
      }
    });
    if (dataGraphic.length <= 0) {
      dataGraphic.push({
        time: '0',
        value: 0,
      });
    }

    this.graphicDataGraphicList = {
      data: dataGraphic,
      label: 'Km/h',
      labelX: 'Hora',
      labelY: '',
    };
    this.graphicColorScale = [
      { minValue: 0, maxValue: 10000, color: '#FF9901' },
    ];
  }
}
