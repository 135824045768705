import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { Console } from 'console';
import { BehaviorSubject } from 'rxjs';

/*
 ** Map Filter
 ** Render a Map Filter
 */
@Component({
  selector: 'map-filter',
  templateUrl: './map-filter.component.html',
  styleUrls: ['./map-filter.component.scss'],
})
export class MapFilterComponent implements OnInit {
  @Input() properties: BehaviorSubject<any>;
  Icon = Icon;
  showConventions:boolean;
  filterShow = true;
  filterAlerts:boolean =true;
  conventionHeatMap:boolean = false;
  conventionAlerts:boolean = false;

  ngOnInit(): void {
    this.properties.pipe().subscribe((layers:any) =>{
      layers?.forEach((element:any) => {
        if(element.title == "FILTRAR POR ALERTAS"){
          this.filterAlerts = true;
          this.conventionAlerts = true;
          this.conventionHeatMap = false;
          localStorage.setItem('incidents','false');
          localStorage.setItem('roadClosed','false');
          localStorage.setItem('Climatic','false');
          localStorage.setItem('locality','');
        }
        else if(element.title == "FILTRAR POR INCIDENTES MAPA DE CALOR"){
            this.conventionHeatMap = true;
            this.conventionAlerts = false;
            this.filterAlerts = true;

        }
        else{
          this.filterAlerts = false;
        }

      
      });
    });
  }

  onShowFilter() {
   if(this.filterShow){
    this.filterShow = false
   }else{
    this.filterShow = true
   }
  }

  showConventionsPanel(){
    this.showConventions = !this.showConventions;
  }
}

