import { Component, Input, OnInit } from '@angular/core';
import { TitlePanelGridMap } from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { ExportExcelService } from '@official/services/data-grid-service/export-excel-service/export-excel.service';
import {
  gridTableheadersMap,
  GridTypeEnum,
} from '@official/utils/mappers/grid-mapper';
import { PmvService } from '@shared/services/layer-service/pmv-service/pmv.service';
import { PmvConsultaService } from '@shared/services/layer-service/pmv-service/pmvconsulta.service';
import { PmvEditarService } from '@shared/services/layer-service/pmv-service/pmveditar.service';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { ICardPmwModel } from '@types-custom/models/ui/card-pmv-model';
import {
  GridPageActions,
  IGridPageModel,
} from '@types-custom/models/ui/grid-page-model';
import { IGridTableCellDispatcher } from '@types-custom/models/ui/grid-table-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'list-message-pmv',
  templateUrl: './list-message.component.html',
  styleUrls: ['./list-message.component.scss'],
})
export class ListProgramMessageComponent implements OnInit {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() ipforsearch: any;

  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() dataselect: any;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;

  Icon = Icon;
  cards: ICardPmwModel[] = [];

  createButtonProps: IButtonModel | undefined = undefined;
  titlePanel = '';
  textPanel = '';
  titleGridPanel = '';
  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  gridColumnActionsEdit = new Subject<IGridTableCellDispatcher>();
  gridColumnActionsDelete = new Subject<IGridTableCellDispatcher>();

  gridPageProps: IGridPageModel;
  gridRowData: any;

  servicePanelDataSource: AbstractPanelManagementDataSource;
  isAllowDownload = true;

  layerName = GridTypeEnum.PMVEditarProgramados as GridTypeEnum;

  constructor(
    private pmvServices: PmvService,
    private exportExcelService: ExportExcelService,
    private pmvService: PmvConsultaService,
    private PmvEditarService: PmvEditarService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.initializePanel();
  }

  private initializePanel(): void {
    this.titlePanel = this.layerName;

    this.titleGridPanel =
      TitlePanelGridMap[this.panelOnManagement] ??
      `REGISTROS DE ${this.titlePanel}`;

    this.createButtonProps = {
      label: `CREAR MENSAJE`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.pmvService.setIp(this.ipforsearch.ip);

    this.servicePanelDataSource = this.pmvService;
    this.gridPageProps = {
      headers: gridTableheadersMap([
        this.gridColumnActionsEdit,
        this.gridColumnActionsDelete,
      ])[this.layerName],
      dataSource: this.servicePanelDataSource,
      isAllowDownload: this.isAllowDownload,
    };

    this.servicePanelDataSource.init();

    this.gridColumnActionsEdit.subscribe((columnAction) =>
      this.handleColumnAction(columnAction)
    );

    this.gridColumnActionsDelete.subscribe((columnAction) =>
      this.handleColumnActionDelete(columnAction)
    );
  }

  handleDownloadGridAction(fileType: GridPageActions): void {
    this.pmvService.ExpIp2 = this.PmvEditarService.ExpIp;
    const excelUrl = this.servicePanelDataSource.buildExcelPdfUrl(
      fileType as unknown as string
    );
    this.exportExcelService.downloadFile(
      excelUrl,
      this.layerName,
      fileType as unknown as string
    );
  }

  handleColumnAction(columnAction: IGridTableCellDispatcher) {
    this.gridRowData = columnAction.row;
    this.dataselect = columnAction.row;
    this.pmvServices.setdata(columnAction.row);
    this.onPanelManageAction(this.panelManageActionsEnum.EDITSCHEDLUE);
  }

  handleColumnActionDelete(columnAction: IGridTableCellDispatcher) {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: { infoMessage: '¿Está seguro que desea eliminar el registro?' },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.gridRowData = columnAction.row;
    this.modalService.confirmationModal(modal);
  }
  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.SEARCH);
    this.pmvService
      .deleteMessagesSchedlue(this.ipforsearch.ip, this.gridRowData.id)
      .subscribe((data) => {
        //console.log('deleteee ' + data);
        this.servicePanelDataSource.init();
      });
  }
}
