import { ILocationOption } from "@types-custom/models/business/location.model";

export interface IIncidentParameter {
    id: number,
    name: string,
    parentId?: number,
    state: boolean,
    incidentTypeCode: number
}

export enum IParamListStatusOption {
    ACTIVO = 'Activo',
    INACTIVO = 'Inactivo'
}
export enum IParamListTypeOption {
    // PENDING = 'Pendiente',
    FUENTE = 'Fuente',
    IMPLICADOS = 'Implicados',
    OBJETO = 'Objeto',
    // APPROVED = 'Aprobado',
    ZONA = 'Zona',
}

export const parameterListOptions = [
    {
        label: IParamListStatusOption.ACTIVO,
        value: 'true',
    } as ILocationOption,

    {
        label: IParamListStatusOption.INACTIVO,
        value: 'false',
    } as ILocationOption,
];
export const parameterListTypeOptions = [
    {
        label: IParamListTypeOption.FUENTE,
        value: IParamListTypeOption.FUENTE,
    } as ILocationOption,
    {
        label: IParamListTypeOption.IMPLICADOS,
        value: 'CantidadImplicado',
    } as ILocationOption,
    {
        label: IParamListTypeOption.OBJETO,
        value: IParamListTypeOption.OBJETO,
    } as ILocationOption,
    {
        label: IParamListTypeOption.ZONA,
        value: IParamListTypeOption.ZONA,
    } as ILocationOption,
];

