<div class="container">
  <div class="text-blue-1 text-weight-700 sit-card" *ngFor="let card of cards">
      <div class="location-title d-flex pl-1">
        <span class="text-white text-size-20 text-sm-size-20 text-weight-600" *ngIf="card.revType == 'CREATE'">Creación</span>
        <span class="text-white text-size-20 text-sm-size-20 text-weight-600" *ngIf="card.revType == 'UPDATE' && card.state != 'Inactivo'">Edición</span>
        <span class="text-white text-size-20 text-sm-size-20 text-weight-600" *ngIf="card.revType == 'UPDATE' && card.state == 'Inactivo' ">Inactivación</span>
        <div class="d-flex fd-row d-flex" style="margin-left: 13.2rem;">
          <sit-icon
          [icon]="Icon.calendar_historic"
          [sizeClass]="'icon-size-24'"
          alt="Fecha icono calendario"
          ></sit-icon>
          <span class="ml-05 text-white mr-1-em">{{
            card.logDate | date: 'YYYY-MM-dd'
          }}</span>
          <sit-icon
          [icon]="Icon.hours_historic"
          [sizeClass]="'icon-size-24'"
          alt="Hora icono reloj"
          ></sit-icon>
          <span class="ml-05 text-white">{{
            card.logDate | date: 'HH:mm'
          }}</span>
        </div>
        
      </div>
      <div class="location-title d-flex fd-row pl-1 text-justify mt-1-em">
        <span class="text-white text-size-18 text-sm-size-18 text-weight-500"> ({{ card?.state}}) &nbsp; </span>
        <span class="text-white text-size-18 text-sm-size-18 text-weight-500"> {{ card?.stateMotive }}</span>
      </div>
      <div class="location-title d-flex fd-row pl-10 mt-1-em ml-1-em" *ngIf="card.condition == 'Excelente'">
        <sit-icon
          [icon]="Icon.state_excelente"
          [sizeClass]="'icon-size-24'"
          alt="Fecha icono calendario"
          ></sit-icon>
          <span class="ml-05 text-white mr-1-em">{{
            card.condition
          }}</span>
      </div>
      <div class="location-title d-flex fd-row pl-10 mt-1-em ml-1-em" *ngIf="card.condition == 'Bueno'">
        <sit-icon
          [icon]="Icon.state_exc"
          [sizeClass]="'icon-size-24'"
          alt="Fecha icono calendario"
          ></sit-icon>
          <span class="ml-05 text-white mr-1-em">{{
            card.condition
          }}</span>
      </div>
      <div class="location-title d-flex fd-row pl-10 mt-1-em ml-1-em" *ngIf="card.condition == 'Regular'">
        <sit-icon
          [icon]="Icon.state_bue"
          [sizeClass]="'icon-size-24'"
          alt="Fecha icono calendario"
          ></sit-icon>
          <span class="ml-05 text-white mr-1-em">{{
            card.condition
          }}</span>
      </div>
      <div class="location-title d-flex fd-row pl-10 mt-1-em ml-1-em" *ngIf="card.condition == 'Malo'">
        <sit-icon
          [icon]="Icon.state_ma"
          [sizeClass]="'icon-size-24'"
          alt="Fecha icono calendario"
          ></sit-icon>
          <span class="ml-05 text-white mr-1-em">{{
            card.condition
          }}</span>
      </div>
      <div class="location-title d-flex fd-row pl-10 mt-1-em ml-1-em">
        <sit-icon
          [icon]="Icon.login_user"
          [sizeClass]="'icon-size-24'"
          alt="Fecha icono calendario"
          ></sit-icon>
          <span class="ml-05 text-white mr-1-em">{{
            card.userName
          }}</span>
      </div>

<!-- 
          <span> {{ card?.condition }}</span>
          <span> {{ card?.stateMotive }}</span> -->
      
  </div>
  
  <!-- <div class="text-blue-1 text-weight-700 sit-card" (click)="openModal()">
      <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-18 text-weight-700">
          <span> {{ card?.corridor }}</span>
      </div>
      <div class="tittleGraphic mt-1-em">
          <span class="text-weight-400 text-size-14 text-md-size-16 text-color-white align-self-center">{{ card?.orientation}}</span>
      </div>
      <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
          <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center">
              <div class="container-graphic-speed">
                  <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
              </div>
          </div>
          
  
          <div class="d-flex fd-col mt-1-em mb-1 align-items-center">
              <sit-icon
              [icon]="Icon.speed_green"
              alt="gorra agente"
              [sizeClass]="'icon-size-48'"
            ></sit-icon>
            <span class="text-white text-size-18 text-weight-600">{{ card?.timeMinutes}} min</span>
            <span class="text-white text-size-12 text-weight-600 mt-05-em">Tiempo recorrido</span>
          </div>
      </div>
  </div> -->
</div>
