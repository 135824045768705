<div class="d-flex justify-start">
    <label class="toggler-wrapper"
        [matTooltip]="(parameter.state?'Desactivar ':'Activar ') + parameter.name?.toLowerCase()">
        <input type="checkbox" [(ngModel)]="parameter.state" (change)="toggleActivate(parameter)">
        <div class="toggler-slider">
            <div class="toggler-knob"></div>
        </div>
        <!-- <sit-icon [icon]="Icon.delete_blue" [sizeClass]="'icon-size-28'" [tooltip]="'Eliminar parámetro'"></sit-icon> -->
    </label>
    <!-- <sit-icon [icon]="icon.delete_blue" [sizeClass]="'icon-size-28'" [tooltip]="'Eliminar parámetro'"></sit-icon> -->
</div>