import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject, Subscription, Observable, skip, tap, map } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';

import { Bound, IAddressModel } from '@types-custom/models/business/google-api.model';
import { IGooglePlacesModel } from '@types-custom/models/business/google-places.model';
import { ILocationControlModel } from '@types-custom/models/ui/location-control.model';
import { IMapEvent, MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { InputTypeEnum } from '@types-custom/models/ui/generic-form.model';
import { IncidentStatusCodeEnum } from '@types-custom/models/business/manage-incident-databody.model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';
import { IncidentFormEnum } from '@types-custom/models/ui/incidents-model';
import { FilterType } from '@shared/models/geo-api-type.model';
import { PanelManageActionsEnum } from "@types-custom/models/ui/panel-manage-model";

declare let google: any;

@Component({
    selector: 'location-control-from-api',
    templateUrl: './location-control-from-api.component.html',
    styleUrls: ['./location-control-from-api.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: LocationControlFromApiComponent,
        },
    ],
})
export class LocationControlFromApiComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() properties: ILocationControlModel;
    private unsubscribe = new Subject();
    private subscription: Subscription;
    private autocompleteListener: any;

    private inpsrc = false;

    ngOnInit(): void {
        this.initForm();
        this.setListeners(); // This might be in the afterInit
    }

    ngAfterViewInit(): void {
        // Code related to AfterViewInit
    }

    ngOnDestroy(): void {
        this.properties.geoLocationService.pointerData$.next(undefined);
        this.unsubscribe.next(undefined);
        this.unsubscribe.complete();
        this.subscription?.unsubscribe();
        if (this.autocompleteListener) {
            google.maps.event.removeListener(this.autocompleteListener);
        }
    }

    private initForm(): void {
        // const {formGroup} = this.properties;
        // formGroup.addControl('latlng', new FormControl(''));
        // formGroup.addControl('latlng2', new FormControl(''));

        if (this.properties.panelAction === PanelManageActionsEnum.EDIT) {
            this.inpsrc = true;
            setTimeout(() => {
                this.inpsrc = false;
            }, 1250);
        }

        this.subscription = this.properties.geoLocationService.pointerData$
            .subscribe({
                next: (pointerData: IMapEvent) => this.handleMapClickLocation(pointerData),
                error: (err: any) => console.error('Error handling map click:', err)
            });
    }

    private setListeners(): void {
        const { locationControlViewChild$, formGroup } = this.properties;
        locationControlViewChild$.subscribe({
            next: (viewChild) => this.initGoogleInputField(viewChild),
            error: (err) => console.error('Error initializing Google input field:', err)
        });
        // formGroup.get('latlng')?.valueChanges.subscribe({
        //   next: (data) => this.handleLatLngChanges(data),
        //   error: (err) => console.error('Error handling lat/lng changes:', err)
        // });
    }

    private patchToFormControl(value: any, form: AbstractControl, key: string): void {
        form.get(key)?.patchValue(value);
    }

    private handleMapClickLocation(pointerData: IMapEvent): void {
        if (this.inpsrc) return;

        this.updateFormWithLocationData(pointerData);
    }

    private updateFormWithLocationData(pointerData: IMapEvent): void {
        const form = this.properties.formGroup.get(this.properties.formName);
        this.properties.geoLocationService
            .getAddress2(pointerData).subscribe({
                next: (locationFromGoogle: IAddressModel) => this.updateFormFields(locationFromGoogle, form),
                error: (error: any) => console.error('Error fetching address:', error) 
            }
            );
    }

    private updateFormWithGeoLocationData(address: string): void {
        this.inpsrc = true;
        const form = this.properties.formGroup.get(this.properties.formName);

        // Creating an observer object
        const observer = {
            next: (locationFromGoogle: IAddressModel) => {
                const { lat, lng } = locationFromGoogle;
                const event: IMapEvent = {
                    type: MapEventTypeEnum.CLICK,
                    lng,
                    lat,
                };
                if (lng && lat) {
                    this.properties.geoLocationService.clickEventsDispatcher.next(event);
                }
                this.updateFormFields(locationFromGoogle, form);
            },
            error: (error: any) => console.error('Error fetching address:', error),
            complete: () => {
                setTimeout(() => {
                    this.inpsrc = false;
                }, 750)
            },

        };

        this.properties.geoLocationService.getLocationFromAddress(address).subscribe(observer);
    }


    private updateFormFields(locationFromGoogle: IAddressModel, form: AbstractControl): void {
        this.setGeometryPointField(locationFromGoogle);
        this.patchToFormControl(locationFromGoogle.lat, form, IncidentFormEnum.latitude);
        this.patchToFormControl(locationFromGoogle.lng, form, IncidentFormEnum.longitude);
        //this.updateLocationFields(locationFromGoogle, form);
        this.patchToFormControl(locationFromGoogle.addressStr, form, InputTypeEnum.LOCATION_INPUT);
        
        this.updateLocationWithGeojson(locationFromGoogle.lat, locationFromGoogle.lng)
        .subscribe({
            next: (localityUpdated: string) => {
                this.patchToFormControl(localityUpdated, form, InputTypeEnum.LOCALITY);
            }
        });
    }

    private updateLocationFields(locationFromGoogle: IAddressModel, form: AbstractControl): void {
        const normalizer = this.properties.geoLocationService.normalizeName;

        // Updating locality field
        const localityValue = this.properties.cachedLocationList.get(IncidentsListEnum.LOCALIDAD)
            ?.find(element => locationFromGoogle.localityDescriptor?.includes(normalizer(element.name)))?.value;
        form.get(InputTypeEnum.LOCALITY)?.patchValue(localityValue);
        form.get(InputTypeEnum.LOCATION_ID)?.patchValue(localityValue);

        // Updating brokerId field
        const brokerIdValue = this.properties.cachedLocationList.get(IncidentsListEnum.CORREDOR)
            ?.find(element => element.name === locationFromGoogle.closestFeature?.properties.composed_name)?.value;
        form.get(IncidentFormEnum.brokerId)?.patchValue(brokerIdValue ?? locationFromGoogle.closestFeature?.properties.composed_name);

        // Updating orientId field
        const orientIdValue = this.properties.cachedLocationList.get(IncidentsListEnum.ORIENTACION)
            ?.find(element => normalizer(this.properties.geoLocationService.mapOrientation(locationFromGoogle.closestFeature?.properties.composed_name.substr(-2))) === normalizer(element.name))?.value;
        form.get(IncidentFormEnum.orientId)?.patchValue(orientIdValue ?? '');
    }

    private updateLocationWithGeojson (lat:any, lng:any): Observable<string>{
        return this.properties.geoLocationService
        .getRelativeLocality(lat, lng).pipe(
            map((response: any) => response.properties.name))
    }

    private handleLatLngChanges(data: any, type?: MapEventTypeEnum): void {


        const { lat, lng } = data;
        const event: IMapEvent = {
            type: MapEventTypeEnum.CLICK,
            lng,
            lat,
        };

        this.handleMapClickLocation(event);
        if (lng && lat) {
            this.properties.geoLocationService.clickEventsDispatcher.next(event);
        }
    }

    private initGoogleInputField(locationControlViewChild: any): void {
        let elem = locationControlViewChild;
        if (!elem)
            elem = document.querySelector('#location-controller-element');
        else
            elem = elem.nativeElement
        if (!elem) return
        const bounds: Bound = this.properties.geoLocationService.getBounds();
        const bogotaBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(bounds.swlat, bounds.swlng), // Coordenadas del punto suroeste
            new google.maps.LatLng(bounds.nelat, bounds.nelng),  // Coordenadas del punto noreste
        );
        const autocomplete = new google.maps.places.Autocomplete(
            elem,
            {
                componentRestrictions: { country: 'CO' },
                types: [FilterType.GEOCODE],

                bounds: bogotaBounds,
                strictBounds: true
            }
        );
        // (unsubscriber at autocompleteListener) remove when the component is destroyed.
        this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry?.location)
                this.setNewMarkerPoint(place);
        });
        elem.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.updateFormWithGeoLocationData(elem.value);
            }
        });
    }

    // private searchPlaceFromInput(inputText: string): void {
    //   const service = new google.maps.places.PlacesService(document.createElement('div'));
    //   service.findPlaceFromQuery({
    //     query: inputText,
    //     fields: ['name', 'geometry'],
    //   }, (results: any, status: any) => {
    //     if (status === google.maps.places.PlacesServiceStatus.OK) {
    //       this.setNewMarkerPoint(results?.[0]);
    //     }
    //   });
    // }

    private setNewMarkerPoint(place: IGooglePlacesModel): void {
        const { lat, lng } = place.geometry.location;
        this.handleLatLngChanges({
            lat: lat(),
            lng: lng(),
        });
    }

    private setGeometryPointField(locationFromGoogle: IAddressModel): void {
        const lat = locationFromGoogle.lat;
        const lng = locationFromGoogle.lng;
        this.properties.formGroup
            .get(this.properties.formName)
            .get('point')
            ?.patchValue([lat, lng]);

        // this.properties.formGroup.get('latlng2').patchValue([lat, lng]);

        this.disableFormOnEditClosed();
    }

    private disableFormOnEditClosed(): void {
        const { panelAction, statusId, formGroup } = this.properties;
        const STATUS_CLOSED = parseInt(IncidentStatusCodeEnum.CERRADO);

        if (panelAction && statusId === STATUS_CLOSED) {
            formGroup.setErrors({ closed: true });
        }
    }
}
