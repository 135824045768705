import {IModelincidentsModel} from '@types-custom/models/ui/modal-incidents.model';
import {IModelastHoursModel} from '@types-custom/models/ui/modal-incidents-lastHours.model';
import {CameraModel} from '@shared/models/camera.model';
import {Co2Model} from '@shared/models/co2.model';
import {SituationsModel} from '@shared/models/situations.model';
import {TrafficModel} from '@shared/models/traffic.model';
import {Icon} from '@types-custom/models/ui/icon-model';
import {AbstractSensorDataSource, IModalCo2Model,} from '@types-custom/models/ui/modal-co2-model';
import {AbstractTrafficDataSource, IModalTrafficModel,} from '@types-custom/models/ui/modal-traffic-model';
import {IModalExodusAndReturnWazeModel} from '@types-custom/models/ui/modal-exodus-and-return-waze-model';
import {TrafficSpeedRangeModel} from '@shared/models/traffic-speed-range.model';
import {AbstractPlaybackControl, IModalCamerasModel,} from '@types-custom/models/ui/modal-camera.modal';
import {TrafficConcordanceModel} from '@shared/models/traffic-concordance.model';
import {TrafficLightModel} from '@shared/models/traffic-light.model';
import {IModalTrafficLightModel} from '@types-custom/models/ui/modal-traffic-light-model';
import {AbstractAforoSensorDataSource, IModalAforoModel,} from '@types-custom/models/ui/modal-aforo-model';
import {AforoModel} from '@shared/models/aforo.model';
import {AbstractIncidentDataSource, IModalRoadworksModel,} from '@types-custom/models/ui/modal-roadworks-model';
import {AgentsModel} from '@shared/models/agents.model';
import {IModalAgentsModel} from '@types-custom/models/ui/modal-agents-model';
import {TrafficManagmentPlansPathModel} from '@shared/models/trafficmanagmentplans-path.model';
import {IModalTrafficManagmentPathModel} from '@types-custom/models/ui/modal-trafficManagment-path';
import {IModalStopBusPathModel} from '@types-custom/models/ui/modal-stopBus-path';
import {StopBusPathModel} from '@shared/models/stopbus-path.model';
import {ZonesPathModel} from '@shared/models/zones-path.model';
import {IModalZonesPathModel} from '@types-custom/models/ui/modal-zones-path';
import {RoadRunPathModel} from '@shared/models/roadrun-path.model';
import {IModalRoadRunPathModel} from '@types-custom/models/ui/modal-road-run';
import {RuntDatexModel} from '@shared/models/runt-datex.model';
import {AbstractRuntDatexDataSource, IModalRuntDatexModel,} from '@types-custom/models/ui/modal-runt-datex.model';
import {coiModel} from '@shared/models/coi.model';
import {IcoiModel} from '@types-custom/models/ui/modal-coi';
import {coilineModel} from '@shared/models/coiline.model';
import {IcoilineModel} from '@types-custom/models/ui/modal-coiline';
import {IModalAgentsLocationModel} from '@types-custom/models/ui/modal-agents-location-model';
import {IModalCranesLocationModel} from '@types-custom/models/ui/modal-cranes-location-model';
import {IModalCranesModel} from '@types-custom/models/ui/modal-cranes-model';
import {AgentsNumberModel} from '@shared/models/agents-number.model';
import {RoadCorridorsModel} from "@shared/models/road-corridors.model";
import {VehiclesModel} from '@shared/models/vehicles.model';
import {IModalVehicleModel} from '@types-custom/models/ui/modal-vehicle-model';
import {IModalCoosLineModel} from '@types-custom/models/ui/modal-coos-line-model';
import {coosLineModel} from '@shared/models/coosLine-model';
import {CoosModel} from '@shared/models/coos.model';
import {IModalCoosModel} from '@types-custom/models/ui/modal-coos-model';
import {TrafficJamWazeModel} from "@shared/models/traffic-jams-waze.model";
import {AbstractTrafficJamWazeDataSource, IModalTrafficJamWazeModel} from "@types-custom/models/ui/modal-wazejam-model";
import {ExodusAndReturnWazeModel} from '@shared/models/exodus-and-return-waze.model';
import {AbstracIncidentsDataSourceGID, IModalRoadCorridors} from "@types-custom/models/ui/modal-road-corridors-model";
import {WazeDataTrafficModel} from "@shared/models/WazeDataTrafficModel";
import {
  AbstractSpeedRangeWazeSource,
  AbstractTrafficDataWazeSource,
  IModalTrafficDataWazeModel
} from "@types-custom/models/ui/modal-trafficdatawaze-model";
import {AlertsDuplicatesWazeModel, AlertsWazeModel} from "@shared/models/alerts-waze-model";
import {IModalAlertsDuplicatesWazeModel, IModalAlertsWazeModel} from "@types-custom/models/ui/modal.alerts.waze.model";
import {CranesModel} from '@shared/models/cranes.model';
import {CranesNumberModel} from '@shared/models/cranes-number.model';
import {IncidentsLocationsModel} from '@shared/models/LocationsIncidents.model';
import {LastHoursModel} from '@shared/models/LastHoursIncidents.model';
import {FieldResourcesLocationsModel} from "@shared/models/field-resources-model";
import {
  AbstractFieldResourcesDataSource,
  IModalFieldResourcesModel
} from "@types-custom/models/ui/modal-field-resources-model";
import {IncidentsFieldResourcesModel} from "@shared/models/incidents-field-resources-model";
import {IModalIncidentsFieldResourcesModel} from "@types-custom/models/ui/modal-incident-field-resource-model";
import {AbstractTrafficBikesDataSource, IModalBikeModel} from "@types-custom/models/ui/modal-bikes.model";
import {BikesModel} from "@shared/models/Bikes.model";
import { incidentAddResourcesModel } from '@shared/models/incidentAddResources.model';
import {AirQualityModel} from "@shared/models/air_quality/AirQuality.model";
import {
  AbstractAirQualityDataSource,
  IModalAirQuality
} from "@types-custom/models/ui/air_quality/air-quality-modal.model";

const getCo2ModelToModalCo2Model =
  (sensorDataSource?: AbstractSensorDataSource) =>
  (model: Co2Model): { properties: IModalCo2Model } => {
    return {
      properties: {
        icon: Icon.sensor_w,
        alt: 'título modal icono sensor',
        id: `${model.classProperties.id}`,
        location: model.classProperties.location,
        title: model.classProperties.location,
        carbonDioxide: model.classProperties.carbonDioxide,
        temperature: model.classProperties.temperature,
        humidity: model.classProperties.humidity,
        pressure: model.classProperties.pressure,
        sensorDataSource: sensorDataSource,
      },
    };
  };

const getCoiModelToModalCoiModel =
  (model:  coiModel): { properties:IcoiModel  } => {
    return {
      properties: {
        icon: Icon.coi_blue,
        alt: 'título modal icono Coi',
        id: `${model.classProperties.id}`,
        location: model.classProperties.location,
        title: model.classProperties.title,
        observaciones: model.classProperties.observaciones,
        contratista: model.classProperties.contratista,
        fini: model.classProperties.fini,
        ffin: model.classProperties.ffin,
        direccion:model.classProperties.direccion,
        tcoi: model.classProperties.tcoi,
        htra: model.classProperties.htra,
        hcie: model.classProperties.hcie,
      },
    };
  };

  const getCoilineModelToModalCoilineModel =
  (model:  coilineModel): { properties:IcoilineModel  } => {
    return {
      properties: {
        icon: Icon.coi_blue,
        alt: 'título modal icono Coi',
        id: `${model.classProperties.id}`,
        location: model.classProperties.location,
        title: model.classProperties.title,
        observaciones: model.classProperties.observaciones,
        contratista: model.classProperties.contratista,
        fini: model.classProperties.fini,
        ffin: model.classProperties.ffin,
        direccion:model.classProperties.direccion,
        tcoi: model.classProperties.tcoi,
        htra: model.classProperties.htra,
        hcie: model.classProperties.hcie,
      },
    };
  };

const getAforoModelToModalModel =
  (sensorDataSource?: AbstractAforoSensorDataSource) =>
  (model: AforoModel): { properties: IModalAforoModel } => {
    return {
      properties: {
        icon: Icon.density_w,
        alt: 'título modal icono aforo',
        id: `${model.classProperties.id}`,
        address: model.classProperties.address,
        title: model.classProperties.address,
        avgSpeed: 0, //TODO: Temporarily set to zero.
        flowCount: 0, //TODO: Temporarily set to zero.
        lightVehicles: null, //model.classProperties.lightVehicles,
        heaveyVehicles: null, //model.classProperties.heaveyVehicles,
        largeHeaveyVehicles: null, //model.classProperties.largeHeaveyVehicles,
        trucks: null, //model.classProperties.trucks,
        buses: null, //model.classProperties.buses,
        bikes: null, //model.classProperties.bikes,
        aforoDataSource: sensorDataSource,
      },
    };
  };

const getSituationModelToModalModel =
  (incidentsDataSource?: AbstractIncidentDataSource) =>
  (model: SituationsModel): { properties: IModalRoadworksModel } => {
    return {
      properties: {
        icon: Icon.alert_black,
        alt: 'título modal icono alerta incidente',
        id: `${model.classProperties.id}`,
        title: `ID:${model.classProperties.id}\u00A0\u00A0\u00A0\u00A0 ESTADO:${model.classProperties.status}`,
        status: model.classProperties.status,
        eventDate: model.classProperties.situationType as string,
        creationDate: model.classProperties.creationDate as string,
        location: model.classProperties.situationType,
        type: model.classProperties.id,
        situationType: model.classProperties.status,
        incidentsDataSource: incidentsDataSource,
      },
    };
  };

const getTrafficModelToModalModel =
  (trafficDataSource?: AbstractTrafficDataSource) =>
      (model: TrafficModel): { properties: IModalTrafficModel } => {
        let distance = (Number.parseInt(model.classProperties.distance) < 1000) ?
            `${Number.parseInt(model.classProperties.distance)} m` :
            `${(Number.parseInt(model.classProperties.distance)/1000).toFixed(2)} Km`;
        return {
          properties: {
            icon: Icon.road_blue,
            alt: 'título modal icono camino',
            id: model.classProperties.id,
            title: `${model.classProperties.nameFrom} - ${model.classProperties.nameTo} (${distance.toString()})`,
            speed: model.classProperties.speed,
            iconSpeed: Icon.chronometer_w,
            concordance: model.classProperties.concordance,
            distance: model.classProperties.distance,
            location: `${model.classProperties.nameFrom} - ${model.classProperties.nameTo}`,
            elapsedTime: model.classProperties.elapsedTime,
            expectedValue: model.classProperties.expectedValue,
            trafficDataSource: trafficDataSource,
          },
        };
      };

const getIncidentsFieldResourcesModelToModal =
    (fieldResourcesDataSource?: AbstractFieldResourcesDataSource ) =>
        (model: IncidentsFieldResourcesModel): { properties: IModalIncidentsFieldResourcesModel } => {
          return {
            properties: {
              icon                        : model.classProperties.resourceGroupId === 17 ? Icon.GuideGroup_Blue : model.classProperties.resourceGroupId === 19 ? Icon.SchoolCycle_Blue :  model.classProperties.resourceGroupId === 20 ? Icon.Centipede_Blue : Icon.alert_blue,
              alt                         : 'Título modal icono camino',
              title                       : (model.classProperties.resourceId) ? `${model.classProperties.resourceId} - ${model.classProperties.resourceFirstName} ${model.classProperties.resourceLastName} - ${model.classProperties.resourceIndicative}`
                                          : `ID ${model.classProperties.incidentId}`,
              type                        : model.classProperties.type,
              incidentId                  : model.classProperties.incidentId,
              incidentClassId             : model.classProperties.incidentClassId,
              incidentClassName           : model.classProperties.incidentClassName,
              incidentTypeId              : model.classProperties.incidentTypeId,
              incidentTypeName            : model.classProperties.incidentTypeName,
              incidentPriorityId          : model.classProperties.incidentPriorityId,
              incidentPriorityName        : model.classProperties.incidentPriorityName,
              incidentAddress             : model.classProperties.incidentAddress,
              incidentStatusId            : model.classProperties.incidentStatusId,
              incidentStatusName          : model.classProperties.incidentStatusName,
              incidentTime                : model.classProperties.incidentTime,
              incidentSourceId            : model.classProperties.incidentSourceId,
              incidentSourceName          : model.classProperties.incidentSourceName,
              incidentLocalityName        : model.classProperties.incidentLocalityName,
              incidentCountsGroup         : model.classProperties.incidentCountsGroup,
              resourceId                  : model.classProperties.resourceId,
              resourceFirstName           : model.classProperties.resourceFirstName,
              resourceLastName            : model.classProperties.resourceLastName,
              resourceDocumentTypeId      : model.classProperties.resourceDocumentTypeId,
              resourceDocumentTypeName    : model.classProperties.resourceDocumentTypeName,
              resourceDocument            : model.classProperties.resourceDocument,
              resourceEmail               : model.classProperties.resourceEmail,
              resourcePhone               : model.classProperties.resourcePhone,
              resourceStateId             : model.classProperties.resourceStateId,
              resourceStateName           : model.classProperties.resourceStateName,
              resourceGroupId             : model.classProperties.resourceGroupId,
              resourceGroupName           : model.classProperties.resourceGroupName,
              resourceIndicative          : model.classProperties.resourceIndicative,
              resourceJobId               : model.classProperties.resourceJobId,
              resourceJobName             : model.classProperties.resourceJobName,
              resourceUpdateLocationDate  : model.classProperties.resourceUpdateLocationDate,
              resourceLocalityName        : model.classProperties.resourceLocalityName,
              resourcesDataSource: fieldResourcesDataSource,
            },
          };
        };
        

const getFieldResourcesModelToModal =
    (fieldResourcesDataSource?: AbstractFieldResourcesDataSource ) =>
  (model: FieldResourcesLocationsModel): { properties: IModalFieldResourcesModel } => {
    return {
      properties: {
        icon: model.classProperties.groupId === 17 ? Icon.GuideGroup_Blue : model.classProperties.groupId === 19 ? Icon.SchoolCycle_Blue : Icon.Centipede_Blue,
        alt: 'título modal icono camino',
        title : `ID ${model.classProperties.id}`,
        id: model.classProperties.id,
        firstName : model.classProperties.firstName,
        lastName : model.classProperties.lastName,
        localityName : model.classProperties.localityName,
        groupId : model.classProperties.groupId,
        groupName : model.classProperties.groupName,
        stateId : model.classProperties.stateId,
        stateName : model.classProperties.stateName,
        updateLocationDate : model.classProperties.updateLocationDate,
        resourcesDataSource: fieldResourcesDataSource,
      },
    };
  };

  const getFieldResourcesAddModelToModal =
    (fieldResourcesDataSource?: AbstractFieldResourcesDataSource ) =>
  (model: FieldResourcesLocationsModel): { properties: IModalFieldResourcesModel } => {
    return {
      properties: {
        icon: model.classProperties.groupId === 17 ? Icon.GuideGroup_Blue : model.classProperties.groupId === 19 ? Icon.SchoolCycle_Blue : Icon.Centipede_Blue,
        alt: 'título modal icono camino',
        title : `${model.classProperties.id} - ${model.classProperties.firstName} ${model.classProperties.lastName } - ${model.classProperties.groupName}  `,
        id: model.classProperties.id,
        firstName : model.classProperties.firstName,
        lastName : model.classProperties.lastName,
        localityName : model.classProperties.localityName,
        groupId : model.classProperties.groupId,
        groupName : model.classProperties.groupName,
        stateId : model.classProperties.stateId,
        stateName : model.classProperties.stateName,
        updateLocationDate : model.classProperties.updateLocationDate,
        resourcesDataSource: fieldResourcesDataSource,
      },
    };
  };

const getRoadCorridorsToModalModel =
    (trafficDataSource?: AbstractTrafficDataSource,
     incidentsDataSource? : AbstracIncidentsDataSourceGID) =>
        (model: RoadCorridorsModel): { properties: IModalRoadCorridors } => {
          return {
            properties: {
              icon: Icon.road_blue,
              alt: 'título modal icono carretera',
              id: model.classProperties.id,
              title: model.classProperties.composed_name,
              speed: model.classProperties.speed.toString(),
              iconSpeed: Icon.speed_range_w,
              distance: model.classProperties.distance,
              speedRange: [],
              incidents: [],
              isCorridors : model.classProperties.isCorridor,
              incidentsDataSource : incidentsDataSource,
              trafficDataSource: trafficDataSource,
            },
          };
        };

const getTrafficJamWazeModelToModalModel =
    (trafficJamWazeDataSource?: AbstractTrafficJamWazeDataSource) =>
        (model: TrafficJamWazeModel): { properties: IModalTrafficJamWazeModel } => {
          return {
            properties: {
              icon: Icon.waze_blue,
              alt: 'título modal icono velocímetro',
              id: model.classProperties.id,
              uuid: model.classProperties.id,
              title: `#${model.classProperties.id} ${model.classProperties.street}`,
              speedkmh: model.classProperties.speedkmh.toString(),
              iconSpeed: Icon.chronometer_w,
              length0: model.classProperties.length0,
              delay: model.classProperties.delay,
              level: `${model.classProperties.level}`,
              receptiontime: model.classProperties.receptiontime,
              updpubmilis: model.classProperties.updpubmilis,
              pubtime: model.classProperties.pubtime,
              trafficJamDataSource: trafficJamWazeDataSource,
            },
          };
        };

const getTrafficDataWazeModelToModalModel =
    (trafficDataWazeDataSource?: AbstractTrafficDataWazeSource,
     speedRangeWazeDataSource? : AbstractSpeedRangeWazeSource) =>
        (model: WazeDataTrafficModel): { properties: IModalTrafficDataWazeModel } => {
          return {
            properties: {
              icon: Icon.road_blue,
              category: model.classProperties.category,
              alt: 'título modal icono velocímetro',
              expectedValue: model.classProperties.historyTimeMinutes,
              id: model.classProperties.id.toString(),
              uuid: model.classProperties.id.toString(),
              distance: model.classProperties.length.toString(),
              iconSpeed: Icon.speed_range_w,
              name: model.classProperties.name,
              orientation: model.classProperties.orientation,
              publicationDate: model.classProperties.publicationDate,
              title: model.classProperties.name,
              receptionDate: model.classProperties.receptionDate,
              elapsedTime: model.classProperties.timeMinutes,
              updateDate: model.classProperties.updateDate,
              speed: model.classProperties.velocity.toString(),
              trafficDataWazeSource: trafficDataWazeDataSource,
              SpeedRangeDataWazeSource: speedRangeWazeDataSource,
            },
          };
        };

const getWazeAlertsToModalModel = (
    model: AlertsWazeModel
): { properties: IModalAlertsWazeModel } => {
  return {
    properties: {
      icon: (model.classProperties.type == 'WEATHERHAZARD') ? Icon.ClimateWazeM :
            (model.classProperties.type == 'ACCIDENT') ? Icon.AccidentWazeM : Icon.CloseWazeM,
      alt: 'título modal icono waze',
      title: model.classProperties.street ,
      reliability: model.classProperties.reliability,
      type: model.classProperties.type,
      subType:model.classProperties.subType ,
      pubTime: model.classProperties.pubTime ,
      receptionTime: model.classProperties.receptionTime ,
      description:model.classProperties.description ,
      street: model.classProperties.street ,
      locality: model.classProperties.locality ,
      localityId: model.classProperties.localityId,
      timeAlerts: model.classProperties.timeAlerts
    }
  };
};


const getAirModelToModalModel = (AbstractAirQualityDataSource?: AbstractAirQualityDataSource) =>
    (model: AirQualityModel): { properties: IModalAirQuality } => {
      return {
        properties: {
          icon                  : Icon.StationIconPoint,
          alt                   : 'Titulo Modal Calidad del Aire Estaciones',
          id                    : model.classProperties.id,
          title                 : `ID #${model.classProperties.id} - ${model.classProperties.location}`,
          name                  : model.classProperties.id,
          location              : model.classProperties.location,
          temperature           : model.classProperties.temperature,
          humidity              : model.classProperties.humidity,
          pressure              : model.classProperties.pressure,
          keepAlive             : model.classProperties.keepAlive,
          airQualityDataSource  : AbstractAirQualityDataSource,
        },
      };
    }


const getBikesModelToModalModel = (trafficBikesDataSource?: AbstractTrafficBikesDataSource) =>
    (model: BikesModel): { properties: IModalBikeModel } => {
      return {
        properties: {
          icon: Icon.waze_blue,
          alt: 'título modal icono velocímetro',
          id: model.classProperties.id,
          title: `ID #${model.classProperties.id} ${model.classProperties.name}`,
          name : model.classProperties.name,
          locality : model.classProperties.locality,
          orientation : model.classProperties.orientation,
          trafficBikesDataSource: trafficBikesDataSource,
        },
      };
    }


const getCameraModelToModalCameraModel =
  (playBackControl?: AbstractPlaybackControl) =>
  (model: CameraModel): { properties: IModalCamerasModel } => {
    return {
      properties: {
        icon: Icon.camera_play_w,
        alt: 'título modal icono cámara',
        title: model.classProperties.id,
        titleModal: `${model.classProperties.name}`,
        streamPlayer: true,
        playBackControl: playBackControl,
      },
    };
  };

const geCoosLineModelToModal = (
  model: coosLineModel
): { properties: IModalCoosLineModel } => {
  return {
    properties: {
      icon: Icon.coos_blue,
      alt: 'título modal icono coos',
      id: `${model.classProperties.id}`,
      title: model.classProperties.title,
      location:model.classProperties.location ,
      hourClose: model.classProperties.hourClose ,
      hourWork: model.classProperties.hourWork ,
      contractor:model.classProperties.contractor ,
      observations: model.classProperties.observations ,
      typeCoos: model.classProperties.typeCoos ,
      typeInt: model.classProperties.typeInt ,
      fechaIni: model.classProperties.fechaIni, 
      fechaFin: model.classProperties.fechaFin ,
      shapeLength:model.classProperties.shapeLength ,
    }
  };
};

const getCoosModelToModal = (
  model: CoosModel
): { properties: IModalCoosModel } => {
  return {
    properties: {
      icon: Icon.coos_blue,
      alt: 'título modal Coos',
      id: `${model.classProperties.id}`,
      title: model.classProperties.title,
      location: model.classProperties.location,
      hourClose: model.classProperties.hourClose,
      hourWork: model.classProperties.hourWork,
      contractor: model.classProperties.contractor,
      observations: model.classProperties.observations,
      typeCoos: model.classProperties.typeCoos,
      typeInt: model.classProperties.typeInt,
      fechaIni: model.classProperties.fechaIni,
      fechaFin: model.classProperties.fechaFin,
    },
  };
};

const getTrafficLightModelToModal = (
  model: TrafficLightModel
): { properties: IModalTrafficLightModel } => {
  return {
    properties: {
      icon: Icon.traffic_light_blue,
      alt: 'título modal icono semáforo',
      direccion: model.classProperties.direccion,
      localidad: model.classProperties.localidad,
      operacion: model.classProperties.operacion,
      tipo_inter: model.classProperties.tipo_inter,
      infra_cicl: model.classProperties.infra_cicl,
      codigo: model.classProperties.codigo,
      title: model.classProperties.title,
    },
  };
};

const getAgentsModelToModal = (
  model: AgentsModel
): { properties: IModalAgentsModel } => {
  return {
    properties: {
      icon: Icon.agents_blue,
      alt: 'título modal icono gorra agentes',
      id: `${model.classProperties.id}`,
      name: model.classProperties.name,
      agentType: model.classProperties.agentType,
      updatedDate: model.classProperties.updatedDate,
      title: model.classProperties.title,
      grpName: model.classProperties.grpName,
      devId: model.classProperties.devId,
      speed: model.classProperties.speed,
      locName: model.classProperties.locName
    },
  };
};

const getExodusAndReturnToModalModel = (
  model: ExodusAndReturnWazeModel
  ): { properties: IModalExodusAndReturnWazeModel } => {
    return {
      properties: {
        icon: Icon.road_blue,
        alt: 'título modal icono velocímetro',
        id: model.classProperties.id,
        title: `${model.classProperties.nameFrom} - ${model.classProperties.nameTo}`,
        speed: model.classProperties.speed,
        iconSpeed: Icon.speed_range_w,
        concordance: model.classProperties.concordance,
        distance: model.classProperties.distance,
        location: `${model.classProperties.nameFrom} - ${model.classProperties.nameTo}`,
        elapsedTime: model.classProperties.elapsedTime,
        expectedValue: model.classProperties.expectedValue,
        speedRange: model.classProperties.speedRange,
      },
    };
  };

const getAgentsNumberModelToModal = (
  model: AgentsNumberModel
): { properties: IModalAgentsLocationModel } => {
  return {
    properties: {
      icon: Icon.agents_blue,
      alt: 'título modal icono gorra agentes',
      id: `${model.classProperties.id}`,
      name: model.classProperties.name,
      Qty: model.classProperties.Qty,
      density: model.classProperties.density,
      agentType: model.classProperties.agentType,
      updatedDate: model.classProperties.updatedDate,
      LocNombre: model.classProperties.LocNombre,
      title: model.classProperties.title
    },
  };
};

const getCranesNumberModelToModal = (
  model: CranesNumberModel
): { properties: IModalCranesLocationModel } => {
  return {
    properties: {
      icon: Icon.icon_crane,
      alt: 'título modal icono gorra grúas',
      id: `${model.classProperties.id}`,
      name: model.classProperties.name,
      Qty: model.classProperties.Qty,
      density: model.classProperties.density,
      LocNombre: model.classProperties.LocNombre,
      title: model.classProperties.title
    },
  };
};

const getVehiculesModelToModal = (
  model: VehiclesModel
): { properties: IModalVehicleModel } => {
  return {
    properties: {
      icon: Icon.car_blue,
      alt: 'título modal icono vehiculos',
      id: `${model.classProperties.siteId}`,
      customClass: 'modalVehicles',
      address: model.classProperties.address,
      title: model.classProperties.title,
    },
  };
};

const getCranesModelToModal = (
  model: CranesModel
): { properties: IModalCranesModel } => {
  return {
    properties: {
      icon: Icon.icon_crane,
      alt: 'título modal icono gorra grúas',
      id: `${model.classProperties.id}`,
      speed: model.classProperties.speed,
      location: model.classProperties.location,
      time: model.classProperties.time,
      title: model.classProperties.title,
      status: model.classProperties.status,
      inactive: model.classProperties.inactive,
      typeField: model.classProperties.typeField
    },
  };
};

const getTrafficManagmentPathModelToModal = (
  model: TrafficManagmentPlansPathModel
): { properties: IModalTrafficManagmentPathModel } => {
  return {
    properties: {
      icon: Icon.check_w,
      alt: 'título modal icono pmt',
      globalid: `${model.classProperties.globalid}`,
      direccion: model.classProperties.direccion,
      type: model.classProperties.type,
      empresacon: model.classProperties.empresacon,
      contratista: model.classProperties.contratista,
      observacion: model.classProperties.observacion,
      title: model.classProperties.title,
    },
  };
};

const getStopBusPathModelToModal = (
  model: StopBusPathModel
): { properties: IModalStopBusPathModel } => {
  return {
    properties: {
      icon: Icon.bus_blue,
      alt: 'título modal icono bus',
      id: `${model.classProperties.id}`,
      paradaNombre: model.classProperties.paradaNombre,
      paradaDireccion: model.classProperties.paradaDireccion,
      paradaTipo: model.classProperties.paradaTipo,
      paradaCodigo: model.classProperties.paradaCodigo,
      title: model.classProperties.title,
    },
  };
};

const getZonesPathModelToModal = (
  model: ZonesPathModel
): { properties: IModalZonesPathModel } => {
  return {
    properties: {
      icon: Icon.check_w,
      alt: 'título modal icono puntero',
      id: `${model.classProperties.id}`,
      ancho: model.classProperties.ancho,
      capacidad: model.classProperties.capacidad,
      direccion: model.classProperties.direccion,
      editor: model.classProperties.editor,
      estado: model.classProperties.estado,
      fechaEstado: model.classProperties.fechaEstado,
      horaFin: model.classProperties.horaFin,
      horaInicio: model.classProperties.horaInicio,
      largo: model.classProperties.largo,
      objectId: model.classProperties.objectId,
      reglamento: model.classProperties.reglamento,
      tarifa: model.classProperties.tarifa,
      tarifaAuto: model.classProperties.tarifaAuto,
      tarifaMoto: model.classProperties.tarifaMoto,
      tipo: model.classProperties.tipo,
      title: model.classProperties.title,
    },
  };
};

const getRoadRunPathModelToModal = (
  model: RoadRunPathModel
): { properties: IModalRoadRunPathModel } => {
  return {
    properties: {
      icon: Icon.street_w,
      alt: 'título modal icono camino',
      id: `${model.classProperties.id}`,
      ancho: model.classProperties.ancho,
      capacidad: model.classProperties.capacidad,
      nombreVia: model.classProperties.nombreVia,
      largo: model.classProperties.largo,
      velocidad: model.classProperties.velocidad,
      tipo: model.classProperties.tipo,
      sentido: model.classProperties.sentido,
      title: model.classProperties.title,
    },
  };
};

const getRuntDatexModelToModalModel =
  (runtDatexDataSource?: AbstractRuntDatexDataSource) =>
  (model: RuntDatexModel): { properties: IModalRuntDatexModel } => {
    return {
      properties: {
        id: model.classProperties.id,
        icon: Icon.camera_play_w,
        alt: 'título modal icono cámara runt ',
      title: model.classProperties.title,
        titleModal: model.classProperties.title,
        runtDatexDataSource: runtDatexDataSource,
      },
    };
  };

  const getIncidentsToModalModel =(
  model: IncidentsLocationsModel
    ): { properties: IModelincidentsModel } => {
    return {
      properties: {
        address: model.classProperties.address,
        location_name: model.classProperties.location_name,
        class_name: model.classProperties.class_name,
        type_name: model.classProperties.type_name,
        priority_name: model.classProperties.priority_name,
        source_name: model.classProperties.source_name,
        state_name: model.classProperties.state_name,
        create_date: model.classProperties.create_date,
        gravity_name: model.classProperties.gravity_name,
        corredor_name: model.classProperties.corredor_name,
        description: model.classProperties.description,
        incident_time: model.classProperties.incident_time,
        id: model.classProperties.id,
        icon: Icon.alert_blue,
        alt: 'título modal icono de incidentes ',
        title: model.classProperties.address,
      },
    };
  };

  const getLastHoursModelToModal = (
    model: LastHoursModel
  ): { properties: IModelastHoursModel } => {
    return {
      properties: {
        id: model.classProperties.id,
        address: model.classProperties.address,
        location_name: model.classProperties.location_name,
        class_name: model.classProperties.class_name,
        type_name: model.classProperties.type_name,
        priority_name: model.classProperties.priority_name,
        source_name: model.classProperties.source_name,
        state_name: model.classProperties.state_name,
        create_date: model.classProperties.create_date,
        gravity_name: model.classProperties.gravity_name,
        corredor_name: model.classProperties.corredor_name,
        description: model.classProperties.description,
        incident_time: model.classProperties.incident_time,
        category_name: model.classProperties.category_name,
        count_incidents: model.classProperties.count_incidents,
        alt: 'icono de incidentes',
        title: model.classProperties.id,
      },
    };
  };

  const getWazeAlertsDuplicatesToModalModel = (
    model: AlertsDuplicatesWazeModel
): { properties: IModalAlertsDuplicatesWazeModel } => {
  return {
    properties: {
      id: model.classProperties.id,
      icon: (model.classProperties.type == 'WEATHERHAZARD') ? Icon.ClimateWazeM :
            (model.classProperties.type == 'ACCIDENT') ? Icon.AccidentWazeM : Icon.CloseWazeM,
      alt: 'título modal icono waze',
      title: model.classProperties.street ,
      reliability: model.classProperties.reliability,
      type: model.classProperties.type,
      subType:model.classProperties.subType ,
      pubTime: model.classProperties.pubTime ,
      receptionTime: model.classProperties.receptionTime ,
      description:model.classProperties.description ,
      street: model.classProperties.street ,
      locality: model.classProperties.locality ,
      localityId: model.classProperties.localityId,
      timeAlerts: model.classProperties.timeAlerts,
      duplicates: JSON.parse(model.classProperties.duplicates),
      latitude: model.classProperties.latitude,
      longitude: model.classProperties.longitude,
    }
  };
};


export const generateGeoModalMap = (
    playBackControl?          : AbstractPlaybackControl,
    sensorDataSource?         : AbstractSensorDataSource,
    aforoSensorDataSource?    : AbstractAforoSensorDataSource,
    incidentsDataSource?      : AbstractIncidentDataSource,
    trafficDataSource?        : AbstractTrafficDataSource,
    incidentsDataSourceGID?   : AbstracIncidentsDataSourceGID,
    trafficJamWazeDataSource? : AbstractTrafficJamWazeDataSource,
    trafficDataWazeSource?    : AbstractTrafficDataWazeSource,
    speedRangeWazeSource?     : AbstractSpeedRangeWazeSource,
    runtDatexDataSource?      : AbstractRuntDatexDataSource,
    bikesDataSource?          : AbstractTrafficBikesDataSource,
    fieldResourcesDataSource? : AbstractFieldResourcesDataSource,
    airQualityDataSource?     : AbstractAirQualityDataSource,
): { [p: string]: any } => {
  const mapper = {
    [CameraModel.className]: getCameraModelToModalCameraModel(playBackControl),
    [Co2Model.className]: getCo2ModelToModalCo2Model(sensorDataSource),
    [AforoModel.className]: getAforoModelToModalModel(aforoSensorDataSource),
    [SituationsModel.className]:
      getSituationModelToModalModel(incidentsDataSource),
    [TrafficModel.className]: getTrafficModelToModalModel(trafficDataSource),
    [TrafficSpeedRangeModel.className]:
      getTrafficModelToModalModel(trafficDataSource),
    [RoadCorridorsModel.className]:
        getRoadCorridorsToModalModel(trafficDataSource,incidentsDataSourceGID),
    [TrafficJamWazeModel.className]:
        getTrafficJamWazeModelToModalModel(trafficJamWazeDataSource),
    [WazeDataTrafficModel.className]:
    getTrafficDataWazeModelToModalModel(trafficDataWazeSource,speedRangeWazeSource),
    [AlertsWazeModel.className]: getWazeAlertsToModalModel,
    [TrafficConcordanceModel.className]:
      getTrafficModelToModalModel(trafficDataSource),
    [coosLineModel.className]: geCoosLineModelToModal,
    [CoosModel.className]: getCoosModelToModal,
    [TrafficLightModel.className]: getTrafficLightModelToModal,
    [coiModel.className]: getCoiModelToModalCoiModel,
    [coilineModel.className]: getCoilineModelToModalCoilineModel,
    [AgentsModel.className]: getAgentsModelToModal,
    [CranesModel.className]: getCranesModelToModal,
    [AgentsNumberModel.className]: getAgentsNumberModelToModal,
    [CranesNumberModel.className]: getCranesNumberModelToModal,
    [VehiclesModel.className]: getVehiculesModelToModal,
    [TrafficManagmentPlansPathModel.className]:
      getTrafficManagmentPathModelToModal,
    [StopBusPathModel.className]: getStopBusPathModelToModal,
    [ZonesPathModel.className]: getZonesPathModelToModal,
    [RoadRunPathModel.className]: getRoadRunPathModelToModal,
    [RuntDatexModel.className]:
      getRuntDatexModelToModalModel(runtDatexDataSource),
    [IncidentsLocationsModel.className]: getIncidentsToModalModel,
    [LastHoursModel.className]: getLastHoursModelToModal,
    [FieldResourcesLocationsModel.className]: getFieldResourcesModelToModal(fieldResourcesDataSource),
    [IncidentsFieldResourcesModel.className]: getIncidentsFieldResourcesModelToModal(fieldResourcesDataSource),
    [BikesModel.className]: getBikesModelToModalModel(bikesDataSource),
    [AlertsDuplicatesWazeModel.className]: getWazeAlertsDuplicatesToModalModel,
    [AforoModel.className]                      : getAforoModelToModalModel(aforoSensorDataSource),
    [AgentsModel.className]                     : getAgentsModelToModal,
    [AgentsNumberModel.className]               : getAgentsNumberModelToModal,
    [AlertsWazeModel.className]                 : getWazeAlertsToModalModel,
    [BikesModel.className]                      : getBikesModelToModalModel(bikesDataSource),
    [CameraModel.className]                     : getCameraModelToModalCameraModel(playBackControl),
    [Co2Model.className]                        : getCo2ModelToModalCo2Model(sensorDataSource),
    [coilineModel.className]                    : getCoilineModelToModalCoilineModel,
    [coiModel.className]                        : getCoiModelToModalCoiModel,
    [coosLineModel.className]                   : geCoosLineModelToModal,
    [CoosModel.className]                       : getCoosModelToModal,
    [CranesModel.className]                     : getCranesModelToModal,
    [CranesNumberModel.className]               : getCranesNumberModelToModal,
    [FieldResourcesLocationsModel.className]    : getFieldResourcesModelToModal(fieldResourcesDataSource),
    [incidentAddResourcesModel.className]       : getFieldResourcesAddModelToModal(fieldResourcesDataSource),
    [IncidentsFieldResourcesModel.className]    : getIncidentsFieldResourcesModelToModal(fieldResourcesDataSource),
    [IncidentsLocationsModel.className]         : getIncidentsToModalModel,
    [LastHoursModel.className]                  : getLastHoursModelToModal,
    [RoadCorridorsModel.className]              : getRoadCorridorsToModalModel(trafficDataSource,incidentsDataSourceGID),
    [RoadRunPathModel.className]                : getRoadRunPathModelToModal,
    [RuntDatexModel.className]                  : getRuntDatexModelToModalModel(runtDatexDataSource),
    [SituationsModel.className]                 : getSituationModelToModalModel(incidentsDataSource),
    [StopBusPathModel.className]                : getStopBusPathModelToModal,
    [TrafficConcordanceModel.className]         : getTrafficModelToModalModel(trafficDataSource),
    [TrafficJamWazeModel.className]             : getTrafficJamWazeModelToModalModel(trafficJamWazeDataSource),
    [TrafficLightModel.className]               : getTrafficLightModelToModal,
    [TrafficManagmentPlansPathModel.className]  : getTrafficManagmentPathModelToModal,
    [TrafficModel.className]                    : getTrafficModelToModalModel(trafficDataSource),
    [TrafficSpeedRangeModel.className]          : getTrafficModelToModalModel(trafficDataSource),
    [VehiclesModel.className]                   : getVehiculesModelToModal,
    [WazeDataTrafficModel.className]            : getTrafficDataWazeModelToModalModel(trafficDataWazeSource,speedRangeWazeSource),
    [ZonesPathModel.className]                  : getZonesPathModelToModal,
    [AirQualityModel.className]                 : getAirModelToModalModel(airQualityDataSource),
  };
  return mapper;
};
