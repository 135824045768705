import { HttpClient } from '@angular/common/http';
import { IAgencyModel } from '@shared/models/agency.model';

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRoleModel, IDocumentTypeModel } from '@shared/models/user.model';
import { IMenuModel } from '@shared/models/role.model';

@Injectable()
export class CommonService {
  url!: string;
  authUrl!: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {
    this.url = this.environment.commonUrl;
    this.authUrl = this.environment.authUrl;
  }

  getAgencies(): Observable<IAgencyModel[]> {
    const path = 'agencies';
    return this.httpClient.get<IAgencyModel[]>(`${this.url}/${path}/`);
  }

  getDocumentType(): Observable<IDocumentTypeModel[]> {
    return this.httpClient.get<IDocumentTypeModel[]>(
      `${this.environment.type}`
    );
  }

  getRoles(): Observable<IRoleModel[]> {
    const path = 'roles';
    return this.httpClient.get<IDocumentTypeModel[]>(
      `${this.authUrl}/${path}/`
    );
  }

  getRolesGrid(): Observable<IRoleModel[]> {
    return this.httpClient.get<IDocumentTypeModel[]>(
      `${this.environment.role}`
    );
  }

  getListPhysicalResources(code: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.listPhysicalResources + `/?typeCode=${code}`}`
    );
  }

  getListCategoriesIncident(code: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.incidentUrl + `/incident-categories/by-type-or-parent?incidentTypeCode=${code}`}`
    );
  }

  getSubListCategoriesIncident(code: any, parentId: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.incidentUrl + `/incident-categories/by-type-or-parent?incidentTypeCode=${code}&parentId=${parentId}`}`
    );
  }

  getMenusGrid(): Observable<IMenuModel[]> {
    const path = 'menu/active';
    return this.httpClient.get<IDocumentTypeModel[]>(
      `${this.environment.authUrl}/${path}`
    );
  }

  getListHumanResources(code: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.resource}/categories/?typeCode=${code}`
    );
  }
  getListNoveltyVehicle(code?: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      //`${this.environment.vehicleNovelty}/categories/?typeCode=${code}`
      "https://qa.gateway.sitbogota.com/incidents/api/v1/incident-categories/by-type-or-parent?incidentTypeCode=Implicado&onlyActive=true&includeRemoved=false" //TODO: JOHANN
    );
  }
}
