<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar" tabindex="0">
  <div class="pb-1-rem pt-20">
    <div class="d-flex px-1 count-box">
      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
            <sit-icon [icon]="Icon.AccidentWazeM" [sizeClass]="'icon-size-46'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">{{this.totalAccidents}}</span>
            
          </span>
          <span class="subject">
            Accidentes
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">
  
          <span class="title">
            <sit-icon [icon]="Icon.CloseWazeM" [sizeClass]="'icon-size-46'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">{{this.totalCloses}}</span>
          </span>
          <span class="subject">
            Vías Cerradas
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">

          <span class="title">
            <sit-icon [icon]="Icon.ClimateWazeM" [sizeClass]="'icon-size-46'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">
              {{this.totalClimate}}
            </span>
          </span>
          <span class="subject">
            Clímaticas
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex px-1 fd-col ">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.alert_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
        Tipos de Alertas
      </h3>
    </div>
    <div class="d-flex fd-row chart-line-container">
      <sit-chart-series [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width h-350">
      </sit-chart-series>
    </div>
  </div>

  <div class="d-flex px-1 fd-col">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.alert_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        Localidades
      </h3>
    </div>
    <div class="full-height full-width">
      <sit-chart-column [data]="listTerritories" [id]="'type'" [valueXAttrib]="'dta'"
        className="h-700 special-chart w-100"></sit-chart-column>
    </div>
  </div>


  <div class="d-flex px-1 mb-1-em">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.alert_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="camino icono título rutas principales"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        Registro de Alertas
      </h3>
    </div>
  </div>

  <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher">
    <ng-container *ngFor="let card of cards; let i = index" class="pb-1-rem">
      <sit-list-waze-alerts [card]="card" [number]="i"></sit-list-waze-alerts>
    </ng-container>
  </div>

</div>