import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class IndicatorService {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}


  getIndicators() {
    return this.httpClient.get<any>(
      `${this.environment.incidentUrl}/indicators/`
    );
  }

}
