<div
  class="dynamic-filters-container width-md-80 flex-md-column gap-md-2"
  [ngClass]="{ vertical: layout === DynamicFilterLayoutEnum.VERTICAL }">
  <div
    *ngIf="properties?.showBttnConventions"
    (click)="onShowPanel()"
    class="bttn d-flex fd-row align-items-center borders text-blue-2 bg-white cursor-pointer">
    <sit-icon
      [alt]="'Abrir panel de convenciones del mapa'"
      [icon]="Icon.info"
      [sizeClass]="'icon-size-24'"></sit-icon>
    <label class="text-size-16 ml-8 text-blue-2 text-weight-500 cursor-pointer">Convenciones</label>
  </div>
  <div class="dynamic-filters pr-md-0" [style]="styleValue">
    <ng-container *ngIf="properties?.isSpacerFirst">
      <div></div>
    </ng-container>
    <ng-container *ngIf="filtersSubject | async as filters">
      <ng-container *ngFor="let filter of filters">
        <ng-container [ngSwitch]="filter.type">
          <!-- <div> -->
          <ng-container *ngSwitchCase="DynamicFilterTypeEnum.SELECTION">
            <ng-container
              *ngTemplateOutlet="
                dropdownFilter;
                context: {
                  dropdownAttributes: {
                    iconClass: IconClassesEnum.blue_icon
                  },
                  label: filter.label,
                  dataSource$: filter.options,
                  selection$: filter.value,
                  filterPosition: filters.indexOf(filter),
                  filterOnChange: filterChangeDispatcher,
                  search: filter.searchChange
                }
              ">
            </ng-container>
          </ng-container>
          <!-- </div> -->
          <ng-container *ngSwitchCase="DynamicFilterTypeEnum.TEXT">
            <ng-container
              *ngTemplateOutlet="
                textFilter;
                context: { label: filter.label, selection$: filter.value, search: filter.searchChange }
              "></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="DynamicFilterTypeEnum.SELECTION_BUTTON">
            <ng-container
              *ngTemplateOutlet="
                dropdownButtonFilter;
                context: {
                  dropdownAttributes: {
                    iconClass: IconClassesEnum.blue_icon
                  },
                  label: filter.label,
                  dataSource$: filter.options,
                  selection$: filter.value,
                  filterPosition: filters.indexOf(filter),
                  filterOnChange: filterChangeDispatcher,
                  search: filter.searchChange
                }
              "></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="DynamicFilterTypeEnum.CHIPS_BUTTON">
            <ng-container
              *ngTemplateOutlet="
                chipsButtonFilter;
                context: {
                  dropdownAttributes: {
                    iconClass: IconClassesEnum.blue_icon
                  },
                  label: filter.label,
                  dataSource$: filter.options,
                  selection$: filter.value,
                  filterPosition: filters.indexOf(filter),
                  filterOnChange: filterChangeDispatcher,
                  multiple: filter.multiple,
                  search: filter.searchChange
                }
              "></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="DynamicFilterTypeEnum.DATE_PICK">
            <ng-container *ngTemplateOutlet="
                dropdownButtonFilterDatePicker;
                context: {
                  dropdownAttributes: {
                    iconClass: IconClassesEnum.blue_icon
                  },
                  label: filter.label,
                  dataSource$: filter.options,
                  selection$: filter.value,
                  filterPosition: filters.indexOf(filter),
                  filterOnChange: filterChangeDispatcher,
                  search: filter.searchChange
                }
              "></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="!properties?.hiddenButtonsFilters">
    <div
      class="dynamic-filters-actions d-md-flex grid-md-template-none justify-md-content-end"
      [ngClass]="{ vertical: layout === DynamicFilterLayoutEnum.VERTICAL }">
      <sit-button
        (click)="cleanFiltersValue()"
        [buttonProperties]="cleanbuttonAttributes"></sit-button>
      <sit-button
        (click)="search()"
        [buttonProperties]="buttonAttributes"></sit-button>
    </div>
  </ng-container>
</div>

<ng-template #textFilter let-label="label" let-selection="selection$" let-search="search">
  <div class="input-container">
    <input
      *ngIf="filtersSubject | async as filters"
      placeholder="{{ label }}"
      type="text"
      (change)="handleTextChange(selection, $event)"
      (input)="handleValueChange(search, selection, $event)"
      value="{{ selection.value }}" />
  </div>
</ng-template>

<ng-template
  #dropdownFilter
  let-dropdownAttributes="dropdownAttributes"
  let-label="label"
  let-dataSource$="dataSource$"
  let-selection$="selection$"
  let-filterPosition="filterPosition"
  let-filterOnChange="filterOnChange"
  let-search="search">
  <div>
    <sit-dropdown
      [classesAttributes]="dropdownAttributes"
      [label]="label"
      [dataSource$]="dataSource$"
      [selection$]="selection$"
      [filterPosition]="filterPosition"
      [filterOnChange]="filterOnChange"
      (changeValue)="handleSelectionValueChange(search)"
      >
    </sit-dropdown>
  </div>
</ng-template>

<ng-template
  #dropdownButtonFilter
  let-dropdownAttributes="dropdownAttributes"
  let-label="label"
  let-dataSource$="dataSource$"
  let-selection$="selection$"
  let-filterPosition="filterPosition"
  let-filterOnChange="filterOnChange"
  let-search="search">
  <div>
    <sit-dropdown-button
      [classesAttributes]="dropdownAttributes"
      [label]="label"
      [dataSource$]="dataSource$"
      [selection$]="selection$"
      [filterPosition]="filterPosition"
      [filterOnChange]="filterOnChange"
      (changeValue)="handleSelectionValueChange(search)"
      >
    </sit-dropdown-button>
  </div>
</ng-template>

<ng-template
        #dropdownButtonFilterDatePicker
        let-dropdownAttributes="dropdownAttributes"
        let-label="label"
        let-dataSource$="dataSource$"
        let-selection$="selection$"
        let-filterPosition="filterPosition"
        let-filterOnChange="filterOnChange"
        let-search="search">
  <div>
    <sit-dropdown-button-date-picker
            [classesAttributes]="dropdownAttributes"
            [label]="label"
            [dataSource$]="dataSource$"
            [selection$]="selection$"
            [filterPosition]="filterPosition"
            [filterOnChange]="filterOnChange"
            (changeValue)="handleSelectionValueChange(search)"
    >
    </sit-dropdown-button-date-picker>
  </div>
</ng-template>

<ng-template
  #chipsButtonFilter
  let-dropdownAttributes="dropdownAttributes"
  let-dataSource$="dataSource$"
  let-selection$="selection$"
  let-filterPosition="filterPosition"
  let-filterOnChange="filterOnChange"
  let-multiple="multiple"
  let-search="search">
  <div class="d-flex">
    <sit-chips-button-filter
      [classesAttributes]="dropdownAttributes"
      [dataSource$]="dataSource$"
      [selection$]="selection$"
      [filterPosition]="filterPosition"
      [filterOnChange]="filterOnChange"
      (changeValue)="handleSelectionValueChange(search)"
      [multiple]="multiple"
      >
    </sit-chips-button-filter>
  </div>
</ng-template>
