import { Icon } from "@types-custom/models/ui/icon-model";
import { IGeometryLineModel, IGeometryModel, MarkerModelBase } from "@types-custom/models/business/marker.model";

export interface IPropsTAlertsWazeModel {
    id?: number;
    reliability: number;
    type: string;
    subType: string,
    icon: Icon;
    pubTime: string,
    receptionTime: string;
    description: string;
    street: string;
    locality: string,
    localityId: string,
    timeAlerts: string,
    markerPopupText: string,
    duplicates?: string,
    dispatcher$?: any,
    panelDispatcher?: any,
    latitude?: number,
    longitude?: number,
}

export class AlertsWazeModel extends MarkerModelBase {
    static className = 'AlertsWazeModel';
    classProperties: IPropsTAlertsWazeModel;

    constructor(
        props: IPropsTAlertsWazeModel,
        geometry: IGeometryLineModel | IGeometryModel,
        lat?: number,
        lng?: number
    ) {
        super(geometry, AlertsWazeModel.className, lat, lng);
        this.classProperties = props;
        this.geometry = geometry;
    }
}

export class AlertsDuplicatesWazeModel extends MarkerModelBase {
    static className = 'AlertsDuplicatesWazeModel';
    classProperties: IPropsTAlertsWazeModel;

    constructor(
        props: IPropsTAlertsWazeModel,
        geometry: IGeometryLineModel | IGeometryModel,
        lat?: number,
        lng?: number
    ) {
        super(geometry, AlertsDuplicatesWazeModel.className, lat, lng);
        this.classProperties = props;
        this.geometry = geometry;
    }
}