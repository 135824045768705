import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerPanelService {
  private collapseDrawerSubject = new BehaviorSubject<boolean>(false);
  private showFilterPanelSubject = new BehaviorSubject<boolean>(false);

  collapseDrawer$ = this.collapseDrawerSubject.asObservable();
  showFilterPanel$ = this.showFilterPanelSubject.asObservable();


  collapseDrawer(collapse: boolean): void {
    this.collapseDrawerSubject.next(collapse);
  }

  showFilterPanel(showPanel: boolean): void {
    this.showFilterPanelSubject.next(showPanel);
  }
}
