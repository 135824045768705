import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { agentsHistoricalFromAPIMapper, agentsNumbersFromAPIMapper, cranesHistoricalFromAPIMapper, cranesNumbersFromAPIMapper } from '@shared/utils/mappers/geo-api-map';
import {
  GeometryTypeEnum,
  ILayerModel,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { map, Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import {agentGeoJsonMockData} from 'libs/sdk/src/lib/mock/agent-geo-json-data';
import {craneGeoJsonMockData} from 'libs/sdk/src/lib/mock/crane-geo-json-data';
import {craneLastMeasurementsGeoJsonMockData} from 'libs/sdk/src/lib/mock/crane-last-geo-json-data';
import {agentNumberGeoJsonMockData} from 'libs/sdk/src/lib/mock/agent-number-geo-json-data';
import {agentLocationMockData} from 'libs/sdk/src/lib/mock/agent-location-geo-json';
import { of } from 'rxjs';
import { AgentsNumberModel } from '@shared/models/agents-number.model';
import { agentHistoricalGeoJsonMockData } from '@sdk/mock/agent-historical-geo-json';
import { craneHistoricalGeoJsonMockData } from '@sdk/mock/crane-historical-geo-json';

@Injectable({
  providedIn: 'root'
})
export class CranesService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  getCranesGeoJSON(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.cranesUrl}/cranes/format-geo-json`
    );

    // let geojson2 = {'type':'FeatureCollection', 'features':[] as any[]}
    // geojson2['features'] = craneGeoJsonMockData.map(function(crane){
    //     let geojsonAgent = {"geometry":{'coordinates':[crane['Longitude'],crane['Latitude']], 'type':'Point'},
    //                         "properties":crane,
    //                         "type": 'Feature'}
    //     return geojsonAgent
    // })

    // return of(geojson2);
  }

  getCranesNumberGeoJSON(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.cranesUrl}/cranes/format-geo-json`
    );

    // return of(craneGeoJsonMockData);
  }

  getCranesHistoricalGeoJSON(): Observable<any> {
    // return this.httpClient.get<any>('assets/mock/agentsHistorical.json');
    return this.httpClient.get<any>(
      `${this.environment.cranesUrl}/cranes-history/format-geo-json?recordsPerPage=1000&page=0&hours=24`
    );

    // let geojson2 = {'type':'FeatureCollection', 'features':[] as any[]}
    // geojson2['features'] = craneHistoricalGeoJsonMockData.map(function(crane){
    //     let geojsonAgent = {"geometry":{'coordinates':[crane['longitude'],crane['latitude']], 'type':'Point'},
    //                         "properties":crane,
    //                         "type": 'Feature'}
    //     return geojsonAgent
    // })
    // return of(geojson2);
  }

  getMeasurementCranes(): Observable<any> {
    return this.httpClient.get<any>(`${this.environment.cranesHistoryUrl}/cranes-history/last-group-by-hour`).pipe(
      map(response => 
        this.formatData(response))
    );
    // return of(craneLastMeasurementsGeoJsonMockData).pipe(
    //   map(response => 
    //     this.formatData(response))
    // );
  }

  formatData(data: any[]) {
    const dataFormatted: any = {};
    const colors=['#04ADC4','#4C60C6','#5B379F','#EA5222','#EB9008']
  
    const mapper: {[key: string]: string} = {
      'GANCHO EXTRA PESADO':'GANCHO EXTRA PESADO',
      'GANCHO LIVIANO':'GANCHO LIVIANO',
      'GANCHO PESADO':'GANCHO PESADO',
      'PLANCHON':'PLANCHON',
      'PLANCHON DE MOTOS':'PLANCHON DE MOTOS'
    }
    data.forEach((item: any,index: number) => {
      const formattedTime = item.hour.toString();
  
      if (!dataFormatted[formattedTime]) {
        dataFormatted[formattedTime] = { hour: formattedTime };
      }
  
      dataFormatted[formattedTime][mapper[item.craneType]] = item.craneCount;
      dataFormatted[formattedTime]['color'] = colors[index % colors.length];
    });
  
    return Object.values(dataFormatted).sort(
      (hourDetailA: any, hourDetailB: any) => {
        const hourA = parseInt(hourDetailA.hour);
        const hourB = parseInt(hourDetailB.hour);
        return hourA - hourB;
      }
    );
  }

  getGraphicByLocality(locatityName:any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.cranesUrl}/cranes-history/graph-by-locality?localityName=${locatityName}`
    );
  }

  getAgentsNumberGeoJSON(): Observable<any>{
    return this.httpClient.get<any>(
      `${this.environment.agentsUrl}/agents/current`
    );
    // return of(agentNumberGeoJsonMockData);
  }

  getAgentsLocation() {
    return this.httpClient.get(`${this.environment.baseApiUrlPolygons}`)
    // return of(agentLocationMockData);
  }

  getAllCranes(){
    return this.httpClient.get(`${this.environment.agentsUrl}/parameters/?code=CranesTotal`)
  }

  getCranesMapper(cranesHistorical: any): ILayerModel[] {
    const layer: ILayerModel[] = [];

    const sourceFeatures = cranesHistorical.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            id: String(e.properties.id),
            time: formatDate(e.properties.coordinateDate, 'YYYY-MM-dd HH:mm:ss', 'en-US'),
            title: formatDate(e.properties.coordinateDate, 'YYYY-MM-dd HH:mm:ss', 'en-US'),
            icon: Icon.dot_w,
          },
        };
      })
      .sort((a: any, b: any) =>
        a.properties.time.localeCompare(b.properties.time)
      );

    const source: any = { type: 'FeatureCollection', features: sourceFeatures };

    const agentsHistoryPoints = source.features.map((e: any) =>
      cranesHistoricalFromAPIMapper(e)
    );
    
    const agentsHistoryLines = this.getCranesLinesHistorical(source).map(
      (e: any) => cranesHistoricalFromAPIMapper(e)
    );

    layer.push({
      marker: agentsHistoryPoints,
      geometryType: GeometryTypeEnum.HeatMap,
      feature: source,
      visibility: true,
    });

    layer.push({
      marker: agentsHistoryPoints,
      geometryType: GeometryTypeEnum.Point,
      feature: source,
      visibility: false,
    });

    layer.push({
      marker: agentsHistoryLines,
      geometryType: GeometryTypeEnum.LineString,
      feature: source,
      visibility: false,
    });

    return layer;
  }

  getCranesNumberMapper(agentsNumber: any): ILayerModel[] {
    const layer: ILayerModel[] = [];

    const sourceFeatures = agentsNumber.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            id: String(e.properties.LocCodigo),
            markerPopupText: `<img src="../../../../assets/icons/crane_w.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
            <span class="mr-3-em" style="margin-left:2.1rem">${e.properties.Qty}</span> <span style="margin-left:2.9rem">${e.properties.density.toFixed(2)}</span> <br> 
            <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
            <span style="display:flex;justify-content:center">${e?.properties?.LocNombre}</span>`,
            icon: Icon.dot_w,
          },
        };
      })

    const source: any = { type: 'FeatureCollection', features: sourceFeatures };

    const agentsHistoryPoints = source.features.map((e: any) =>
      cranesNumbersFromAPIMapper(e)
    );

    layer.push({
      marker: agentsHistoryPoints,
      geometryType: GeometryTypeEnum.MultiPolygon,
      feature: source,
      visibility: true
    });
    return layer;
  }

  getCranesLinesHistorical(data: any): any[] {
    const cranesIds = [
      ...new Set(data.features.map((e: any) => e.properties.id)),
    ];

    const cranesLineFeatures = [];

    for (const item of cranesIds) {
      const coordinates = data.features
        .filter((e: any) => e.properties.id === item)
        .map((e: any) => e.geometry.coordinates);

      const properties = data.features.filter(
        (e: any) => e.properties.id === item
      )[0].properties;

      const agentsLineFeature = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: GeometryTypeEnum.LineString,
          coordinates: coordinates,
        },
      };
      cranesLineFeatures.push(agentsLineFeature);
    }

    return cranesLineFeatures;
  }

  getAgentsLinesNumber(data: any): any[] {
    const agentsIds = [
      ...new Set(data.features.map((e: any) => e.properties.LocCodigo)),
    ];

    const agentsLineFeatures = [];

    for (const item of agentsIds) {
      const coordinates = data.features
        .filter((e: any) => e.properties.LocCodigo === item)
        .map((e: any) => e.geometry.coordinates);

      const properties = data.features.filter(
        (e: any) => e.properties.LocCodigo === item
      )[0].properties;

      const agentsLineFeature = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: GeometryTypeEnum.LineString,
          coordinates: coordinates,
        },
      };
      agentsLineFeatures.push(agentsLineFeature);
    }

    return agentsLineFeatures;
  }
  
}
