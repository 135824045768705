import { Icon } from '@types-custom/models/ui/icon-model';
import { Observable } from 'rxjs';

export interface IModalRuntDatexModel {
  id: string;
  icon: Icon;
  alt: string;
  title?: string;
  titleModal?: string;
  runtDatexDataSource?: AbstractRuntDatexDataSource;
}

export interface IRuntModalCameraResponse {
  indicators: IRuntIndicatorModel[];
  lastPlatesRegister: IRuntPlatesModel[];
}
export interface IRuntIndicatorModel {
  vehicleClass: string;
  soatExpired: string;
  technoExpired: string;
}

export interface IRuntIndicator {
  type: string;
  text: string;
  icon: Icon;
  alt: string;
  value: string;
}
export interface IRuntPlatesModel {
  id: string;
  plate: string;
  vehicleClass: string;
  dateReading: string;
  soatExpired: string;
  technoExpired: string;
}

export abstract class AbstractRuntDatexDataSource {
  abstract getModalCameraRunt(cameraId: string): Observable<any>;
}

const addIndicator = (
  vehicleClass: string,
  type: string,
  value?: string
): IRuntIndicator => {
  let text: string;
  let icon: Icon;
  let alt: string;
  if (type === 'soat') {
    text = `Total ${vehicleClass} sin SOAT`;
    icon = vehicleClass === 'Vehículos' ? Icon.car_blue : Icon.bicycles_blue;
    alt = `icono indicador ${text}`;
  } else {
    text = `Total ${vehicleClass} sin Tecnomecánica`;
    icon = vehicleClass === 'Vehículos' ? Icon.car_black : Icon.bicycles_blue;
    alt = `icono indicador ${text}`;
  }

  return {
    type,
    text,
    icon,
    alt,
    value: value || '-',
  };
};

export const getRuntIndicators = (
  indicators: IRuntIndicatorModel[]
): IRuntIndicator[] => {
  const runtIndicators: IRuntIndicator[] = [];

  if (indicators.length === 0) {
    runtIndicators.push(addIndicator('Vehículos', 'soat'));
    runtIndicators.push(addIndicator('Motocicletas', 'soat'));
    runtIndicators.push(addIndicator('Vehículos', 'technomecanic'));
    runtIndicators.push(addIndicator('Motocicletas', 'technomecanic'));
  } else {
    indicators.forEach((e: IRuntIndicatorModel) => {
      const vehicleClass =
        e.vehicleClass === 'cars' ? 'Vehículos' : 'Motocicletas';
      runtIndicators.push(addIndicator(vehicleClass, 'soat', e.soatExpired));
      runtIndicators.push(addIndicator(vehicleClass, 'technomecanic', e.technoExpired));
    });
  }

  return runtIndicators.sort((a: IRuntIndicator, b: IRuntIndicator) =>
    a.type.localeCompare(b.type)
  );
};
