<div class="form-container pr-24 pl-24 mb-32 mt-32">

  <!-- <ng-container *ngIf="userEdited.id">
    <div class="d-flex fd-col pb-16 align-items-flex-end">
      <sit-button
        (click)="
          registerFormGroup.valid ? validateUserSubmit() : validateFormGroup()
        "
        [disabled]="!registerFormGroup.valid"
        [buttonProperties]="buttonEditAttributes"
      ></sit-button>
    </div>
  </ng-container> -->

  <form [formGroup]="registerFormGroup" class="login-form">
    <span class="seccionTitle text-color-white">Información del Usuario</span>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Correo electrónico</mat-label>
        <input matInput formControlName="email" matTooltipPosition="below" matTooltipClass="custom-tooltip"
          matTooltip="Admite letras mayúsculas y minúsculas, números y carácter @ . _ -"/>
        <mat-error>{{ emailErrorMessage }}</mat-error>
      </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Tipo de documento</mat-label>

        <mat-select matNativeControl formControlName="documentTypeId" panelClass="topunit">
          <mat-option *ngFor="let item of typeDocument; let i=index" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>

      </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Documento</mat-label>
        <input matInput formControlName="document" />
        <mat-error>{{ documentErrorMessage }}</mat-error>
      </mat-form-field>
    </div>


    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">

      <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
          'error-field':
            registerFormGroup.controls.firstName.invalid &&
            registerFormGroup.controls.firstName.touched
        }">
        <mat-label class="text-white">Nombres</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error>
          {{ nameErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
          'error-field':
            registerFormGroup.controls.lastName.invalid &&
            registerFormGroup.controls.lastName.touched
        }">
        <mat-label class="text-white">Apellidos</mat-label>
        <input matInput formControlName="lastName"  />
        <mat-error>
          {{ lastNameErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
    
    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
          'error-field':
            registerFormGroup.controls.phone.invalid &&
            registerFormGroup.controls.phone.touched
        }">
        <mat-label class="text-white">Celular</mat-label>
        <input matInput formControlName="phone"/>
        <mat-error>
          {{ phoneErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>   

    <div class="pt-2 mb-1 mt-15">

      <button 
        (click)="changeValue() "
        [ngClass]="estado === 1? 'activo':''"
        class="cursor-pointer button-state ">
        Activo
      </button>
      <button (click)="changeValue()"
        [ngClass]="estado === 2?  'activo':''"
        class="button-state btn-inactive">
        Inactivo
      </button>
    </div>

    <span class="seccionTitle text-color-white">Caracterización de recursos en campo</span>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Grupo al que Pertenece</mat-label>
        
        <mat-select matNativeControl formControlName="groupId" (change)="cargarCargos()">
          <mat-option *ngFor="let item of listGroupName  ; let i=index" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>

      </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Cargo</mat-label>
        <mat-select matNativeControl formControlName="jobId">
          <mat-option *ngFor="let item of cargos; let i=index" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
      <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
          'error-field':
            registerFormGroup.controls.indicative.invalid &&
            registerFormGroup.controls.indicative.touched
        }">
        <mat-label class="text-white">Indicativo</mat-label>
        <input matInput formControlName="indicative" />
        <mat-error>
          {{ indicativeErrorMesage }}
        </mat-error>
      </mat-form-field>
    </div>
    
  
  </form>

  <!-- <ng-container *ngIf="!userEdited.id"> -->
  <div class="modal-actions d-flex align-items-end justify-end pt-30">
    <ng-container>
      <sit-button (click)="onCancel()" [buttonProperties]="buttonCancelAttributes"></sit-button>
    </ng-container>
    <ng-container>
      <sit-button (click)="registerFormGroup.valid ? onSubmit() : validateFormGroup() "
       [disabled]="!registerFormGroup.valid" [buttonProperties]="buttonSaveAttributes"></sit-button>
    </ng-container>
  </div>
  <!-- </ng-container> -->
</div>