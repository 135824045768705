import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractSensorDataSource } from '@types-custom/models/ui/modal-co2-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Co2Service implements AbstractSensorDataSource {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) { }


  getLastMeasurement(sensorId: string): Observable<any> {
    return this.httpClient.get<any[]>(`${this.environment.environmentalUrl}/sensor-history/last-measurements/${sensorId}`);
  }

  public getCo2GeoJson(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.environment.environmentalUrl}/sensor/format-geo-json?status=ACTIVO`);
  }

  public getMeasurementCo2(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.environment.environmentalUrl}/sensor-history/last-measurements/co2`);
  }

  getCo2Mapper(co2Obj: any) {

    const {geoCo2, measurementsCo2} = co2Obj;
    const item_selected = {} as any;

    const geojson = geoCo2['features'].map((section: any) => {
      const sensor_item = measurementsCo2['sensors'].filter((item: any) => item.id === section.properties.id);

      if (sensor_item.length > 0) {
        item_selected['sensor'] = sensor_item[0];
      }
      else {
        return;
      }

      section['sensors'] = item_selected['sensor'];
      return section;
    })
    .filter((e: any) => e !== undefined);

    return geojson;
  }



  // Air Quality
  public getAirQualityGeoJson(): Observable<any[]> {
    return this.httpClient.get<any[]>(
        //`${this.environment.environmentalUrl}/air-quality/format-geo-json?status=ACTIVO`
        `assets/mock/air_quality/airquality.mock.json`
    );
  }

  public getIndicatorsByStationId(id : string): Observable<any[]> {
    return this.httpClient.get<any[]>(
        //`${this.environment.environmentalUrl}/air-quality/format-geo-json?status=ACTIVO`
        `assets/mock/air_quality/air_indicators.mock.json`
    );
  }

  public getMeasurementsByStationId(id : string, indicator_id : number): Observable<any[]> {
    return this.httpClient.get<any[]>(
        //`${this.environment.environmentalUrl}/air-quality/format-geo-json?status=ACTIVO`
        `assets/mock/air_quality/air_measurements.mock.json`
    );
  }

}
