<div class="bg-color-background-1 d-flex">
  <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
  <div class="d-flex full-width py-05">
    <sit-icon alt="Volver" [icon]="Icon.arrow_backward_w" (click)="handleConfirmation()" class="text-blue-1 ml-28"
      [sizeClass]="'icon-size-24'"></sit-icon>
    <h3
      class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0">
      EDITAR MENSAJE
    </h3>
  </div>
</div>

<div class="bodymessage">
  <div class="d-flex fd-col" *ngIf="dataMessage">
    <div class="mat-input d-flex fd-row align-items-center mb-16">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Posicion del mensaje</mat-label>
        <input readonly matInput value="{{ dataMessage.messageNumber }}" />
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex fd-col">
    <div class="mat-input d-flex fd-row align-items-center mb-16">
      <mat-form-field class="textWhite" appearance="outline">
        <mat-label class="text-white">Pagina</mat-label>
        <mat-select [(value)]="selecteddefault" (selectionChange)="onSelectEvent($event.value)">
          <mat-option *ngFor="let item of pages" [value]="item.value">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="mat-input d-flex fd-row align-items-center">
        <button style="border: 0; height: 2.5rem" (click)="addPage()" mat-raised-button>
          <mat-icon class="white-icon">add</mat-icon>
        </button>
      </div>
      <div class="mat-input d-flex fd-row align-items-center">
        <button style="border: 0; height: 2.5rem" (click)="deletepage()" mat-icon-button>
          <mat-icon class="white-icon">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="pageForm" class="login-form">
    <div class="d-flex fd-col">
      <div class="mat-input d-flex fd-row align-items-center mb-16">
        <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
            'error-field': pageForm.get('pageTime').hasError('required')
          }">
          <mat-label class="text-white">Tiempo de pagina (seg)</mat-label>
          <input matInput formControlName="pageTime" type="number" />
        </mat-form-field>
      </div>
    </div>

    <hr />
    <h5 class="textWhite">Cuadro de texto</h5>

    <div class="d-flex fd-col">
      <div class="fd-row align-items-center mb-16">
        <div class="row">
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite groupinput" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('pageBackgroundColorHeigth')
                  .hasError('required')
              }">
              <mat-label class="text-white">Alto cuadro de texto</mat-label>
              <input matInput formControlName="pageBackgroundColorHeigth" type="number" />
            </mat-form-field>
          </div>
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('pageBackgroundColorWidth')
                  .hasError('required')
              }">
              <mat-label class="text-white">Ancho cuadro de texto</mat-label>
              <input matInput formControlName="pageBackgroundColorWidth" type="number" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="fd-row align-items-center mb-16">
        <div class="row">
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite groupinput" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('pageBackgroundColorX')
                  .hasError('required')
              }">
              <mat-label class="text-white">Posicion eje x</mat-label>
              <input matInput formControlName="pageBackgroundColorX" type="number" />
            </mat-form-field>
          </div>
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('pageBackgroundColorY')
                  .hasError('required')
              }">
              <mat-label class="text-white">Posicion eje y</mat-label>
              <input matInput formControlName="pageBackgroundColorY" type="number" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="mat-input d-flex fd-row align-items-center mb-16">
        <div class="mat-input d-flex fd-row align-items-center">
          <h5 class="textWhite" style="margin-right: 10px">
            Alineacion Vertical
          </h5>
        </div>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="mat-input d-flex fd-row align-items-center mb-16">
        <mat-button-toggle-group formControlName="justificationPage" #alhor="matButtonToggleGroup">
          <mat-button-toggle value="bottom" aria-label="Text align left">
            <mat-icon>vertical_align_bottom</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="center" aria-label="Text align center">
            <mat-icon>vertical_align_center</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="top" aria-label="Text align right">
            <mat-icon>vertical_align_top</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="fd-row align-items-center mb-16">
        <div class="row">
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm.get('font').hasError('required')
              }">
              <mat-label class="text-white groupinput">Fuente</mat-label>
              <mat-select formControlName="font">
                <mat-option *ngFor="let item of dataFonts" [value]="item.number">
                  {{ item.name }} - Alto {{ item.height }}px
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite groupinput" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('spacingCharacter')
                  .hasError('required')
              }">
              <mat-label class="text-white">Separacion de caracteres</mat-label>
              <input matInput formControlName="spacingCharacter" maxlength="2" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="d-flex fd-col">
      <div class="d-flex fd-row align-items-center">
        <h5 class="textWhite" style="margin-right: 8px">Color Fondo</h5>
        <div class="input-color-container">
          <input type="color" id="input-color" class="input-color" value="{{ valueinputbackgroundcolor }}"
            (change)="changebackground($event)" />
        </div>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="fd-row align-items-center mb-16">
        <div class="row">
          <div class="col-4 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('BackgroundColorR')
                  .hasError('required')
              }">
              <mat-label class="text-white">R</mat-label>
              <input matInput formControlName="BackgroundColorR" maxlength="3" readonly />
            </mat-form-field>
          </div>
          <div class="col-4 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('BackgroundColorG')
                  .hasError('required')
              }">
              <mat-label class="text-white">G</mat-label>
              <input matInput formControlName="BackgroundColorG" maxlength="3" readonly />
            </mat-form-field>
          </div>
          <div class="col-4 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm
                  .get('BackgroundColorB')
                  .hasError('required')
              }">
              <mat-label class="text-white">B</mat-label>
              <input matInput formControlName="BackgroundColorB" maxlength="3" readonly />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="d-flex fd-row align-items-center">
        <h5 class="textWhite" style="margin-right: 8px">Color Texto</h5>
        <div class="input-color-container">
          <input type="color" id="input-color" class="input-color" value="{{ valueinputtextcolor }}"
            (change)="changetextcolor($event)" />
        </div>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="fd-row align-items-center mb-16">
        <div class="row">
          <div class="col-4 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm.get('TextColorR').hasError('required')
              }">
              <mat-label class="text-white">R</mat-label>
              <input matInput formControlName="TextColorR" readonly />
            </mat-form-field>
          </div>
          <div class="col-4 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm.get('TextColorG').hasError('required')
              }">
              <mat-label class="text-white">G</mat-label>
              <input matInput formControlName="TextColorG" readonly />
            </mat-form-field>
          </div>
          <div class="col-4 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm.get('TextColorB').hasError('required')
              }">
              <mat-label class="text-white">B</mat-label>
              <input matInput formControlName="TextColorB" readonly />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <h5 class="textWhite">Iconos</h5>

    <div class="d-flex fd-col">
      <div class="mat-input d-flex fd-row align-items-center mb-16">
        <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
            'error-field': pageForm.get('graphic').hasError('required')
          }">
          <mat-label class="text-white">Iconos</mat-label>
          <mat-select formControlName="graphic">
            <mat-option *ngFor="let item of dataGraphics" [value]="item.number">
              {{ item.number }}.&nbsp; &nbsp; {{ item.name }} - Alto
              {{ item.height }}px - Ancho {{ item.width }}px
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex fd-col">
      <div class="fd-row align-items-center mb-16">
        <div class="row">
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm.get('graphicEjeX').hasError('required')
              }">
              <mat-label class="text-white">Posicion Eje X</mat-label>
              <input matInput formControlName="graphicEjeX" type="number" />
            </mat-form-field>
          </div>
          <div class="col-6 mat-input">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': pageForm.get('graphicEjeY').hasError('required')
              }">
              <mat-label class="text-white">Posicion Eje Y</mat-label>
              <input matInput formControlName="graphicEjeY" type="number" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="mat-input d-flex fd-row align-items-center">
      <h5 class="textWhite">Mensaje</h5>
      <button type="button" style="border: 0; height: 2.5rem; width: 0px; margin-left: 9.5vw" (click)="addTicket()"
        mat-raised-button>
        <mat-icon class="white-icon">add</mat-icon>
      </button>
      <h5 class="textWhite">Agregar Linea de Texto</h5>
    </div>

    <div formArrayName="lines" *ngFor="let t of lines.controls; let i = index">
      <div [formGroupName]="i">
        <div class="d-flex fd-col">
          <div class="mat-input d-flex fd-row align-items-center mb-16">
            <mat-form-field class="textWhite" appearance="outline" [ngClass]="{
                'error-field': t.get('text').hasError('required')
              }">
              <mat-label class="text-white">Texto</mat-label>
              <input matInput formControlName="text" />
            </mat-form-field>
            <button type="button" style="border: 0; height: 2.5rem" (click)="deleteLine(i)" mat-raised-button>
              <mat-icon class="white-icon">delete</mat-icon>
            </button>
          </div>
        </div>

        <div class="mat-input d-flex fd-row align-items-center">
          <h5 class="textWhite" style="margin-right: 10px">
            Alineacion horizontal
          </h5>
        </div>
        <div class="mat-input d-flex fd-row align-items-center mb-16">
          <mat-button-toggle-group formControlName="justificationLine" #alhor="matButtonToggleGroup">
            <mat-button-toggle value="left" aria-label="Text align left">
              <mat-icon>format_align_left</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="center" aria-label="Text align center">
              <mat-icon>format_align_center</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="right" aria-label="Text align right">
              <mat-icon>format_align_right</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </form>

  <div class="d-flex fd-row justify-end mt-16">
    <div class="modal-actions d-flex align-items-end justify-end pt-30">
      <sit-button (click)="handleConfirmation()" [buttonProperties]="cancelButtonProps" class="width-49"></sit-button>
      <sit-button (click)="pageForm.valid ? handleSendForm() : undefined" [disabled]="!pageForm.valid"
        [buttonProperties]="sendButtonProps" class="width-49 ml-16"></sit-button>
    </div>
    <span class="textWhite" *ngIf="pageForm.hasError('passwordNotMatch')">
      error en el formulario
    </span>
  </div>
</div>