import { Injectable } from '@angular/core';
import { ActionType } from '@types-custom/models/ui/menu.model';
import { IAction, IActionHandler } from '../action.service';

@Injectable()
export class MapActionHandler implements IActionHandler {
  canHandle(action: IAction): boolean {
    return action.event.actionType === ActionType.Map;
  }
  handle(action: IAction): void {
    action.dispatcher.next(action.event);
  }
}
