import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ILocationPoint } from '@types-custom/models/business/location.model';
import {
  AbstractPanelManagementDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, forkJoin, map, Observable, of, tap } from 'rxjs';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
import { SensorOptionTypeEnum } from '@types-custom/models/ui/sensor-option-type-model';
import { IManageSensorDataBody } from '@types-custom/models/business/manage-sensor-data-form';

@Injectable({ providedIn: 'root' })
export class SensorsManageServiceService
  implements AbstractPanelManagementDataSource<any>
{
  private apiSelectOptionsMap: {
    [key: string]: () => Observable<any[]>;
  } = {
    [SensorOptionTypeEnum.TYPE]: this.getSensorType.bind(this),
    [SensorOptionTypeEnum.LOCALITY]: this.getListLocality.bind(this),
    [SensorOptionTypeEnum.STATUS]: this.getListStatus.bind(this),
  };

  private dataSubject = new BehaviorSubject<any[]>([]);
  data$ = this.dataSubject.asObservable();
  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<any | ILocationPoint<string> | undefined>;
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  formFiltersValue: any | undefined = undefined;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  public init() {
    this.setSelectedDataSubject(this.selected$);
  }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  buildUrl(pageObject: IPaginatorModel): string {
    const filterObject = this.selected$.value;

    let url = `${this.environment.environmentalUrl}/sensor/pagination/data?recordsPerPage=${pageObject.pageSize}&page=${pageObject.page}`;

    if (this.formFiltersValue) {
      Object.keys(this.formFiltersValue).map((filterKey: string) => {
        if (filterKey !== undefined && filterKey !== null && filterKey !== '') {
          if (
            this.formFiltersValue[filterKey] !== undefined &&
            this.formFiltersValue[filterKey] !== null &&
            this.formFiltersValue[filterKey] !== ''
          ) {
            url = url + `&${filterKey}=${this.formFiltersValue[filterKey]}`;
          }
        }
      });
    }

    return url;
  }

  buildExcelPdfUrl(fileType: string): string {
    let urlBase = '';
    if (fileType === fileTypes.EXCEL) {
      urlBase = `${this.environment.environmentalUrl}/sensor/export/excel?`;
    } else if (fileType === fileTypes.PDF) {
      urlBase = `${this.environment.environmentalUrl}/sensor/export/pdf?`;
    }
    if (this.formFiltersValue) {
      Object.keys(this.formFiltersValue).forEach(
        (filterKey: string, index: number) => {
          if (
            filterKey !== undefined &&
            filterKey !== null &&
            filterKey !== ''
          ) {
            if (
              this.formFiltersValue[filterKey] !== undefined &&
              this.formFiltersValue[filterKey] !== null &&
              this.formFiltersValue[filterKey] !== ''
            ) {
              urlBase =
                urlBase + `&${filterKey}=${this.formFiltersValue[filterKey]}`;
            }
          }
        }
      );
    }

    return urlBase;
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value;

    const url = this.buildUrl(pageInfo);
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.currentPage,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalRecords,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        const data: any[] = response.records;
        this.dataSubject.next(data ?? []);
      })
    );
  }

  getSensorType(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.environment.environmentalUrl}/sensor-type/`)
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.type,
          }))
        )
      );
  }

  getListLocality(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        `${this.environment.incidentUrl}/incident-categories/category-code/comunas`
      )
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.name,
          }))
        )
      );
  }

  getListStatus(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.environment.camerasUrl}/status/`)
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.status,
          }))
        )
      );
  }

  getSelectOptions(optionType: string) {
    const fn = this.apiSelectOptionsMap[optionType];
    return fn ? fn() : of([]);
  }

  getIndividualData(rowData: any) {
    return this.httpClient
      .get<any>(`${this.environment.environmentalUrl}/sensor/${rowData.id}`)
      .pipe(
        map((response) => ({
          id: response.id,
          name: response.name,
          point: [response.latitude, response.longitude],
          latitude: response.latitude,
          longitude: response.longitude,
          address: response.address,
          manufacturer: response.manufacturer,
          locationId: `${response.communeId}`,
          protocol: response.protocol,
          sensorTypeId: `${response.sensorTypeId}`,
          statusId: `${response.statusId}`,
          versionFw: response.versionFw,
          model: response.model,
          serial: response.serial,
          userName: response.userName,
          password: response.password,
          mac: response.mac,
          gateway: response.gateway,
          description: response.description,
        }))
      );
  }

  searchFromFilters(formFiltersValue: any) {
    this.formFiltersValue = formFiltersValue;
    this.selected$.next(formFiltersValue);
  }

  submitCreateForm(formValue: any) {
    const url = `${this.environment.environmentalUrl}/sensor/`;

    const data: IManageSensorDataBody = {
      id: null,
      name: formValue.name,
      address: formValue.address,
      latitude: formValue.point?.[0],
      longitude: formValue.point?.[1],
      communeId: formValue.locationId,
      manufacturer: formValue.manufacturer,
      protocol: formValue.protocol,
      sensorTypeId: formValue.sensorTypeId,
      statusId: formValue.statusId,
      versionFw: formValue.versionFw,
      model: formValue.model,
      serial: formValue.serial,
      userName: formValue.userName,
      password: formValue.password,
      mac: formValue.mac,
      gateway: formValue.gateway,
      description: formValue.description,
    };

    return this.httpClient.post(url, data);
  }

  submitEditForm(formValue: any) {
    const url = `${this.environment.environmentalUrl}/sensor/`;

    const data: IManageSensorDataBody = {
      id: formValue.id,
      name: formValue.name,
      address: formValue.address,
      latitude:
        formValue.latlng2.length > 0
          ? formValue.latlng2[0]
          : formValue.point.substring(0, formValue.point.indexOf(',')),
      longitude:
        formValue.latlng2.length > 0
          ? formValue.latlng2[1]
          : formValue.point.substring(formValue.point.indexOf(',') + 1),
      communeId: formValue.locationId,
      manufacturer: formValue.manufacturer,
      protocol: formValue.protocol,
      sensorTypeId: formValue.sensorTypeId,
      statusId: formValue.statusId,
      versionFw: formValue.versionFw,
      model: formValue.model,
      serial: formValue.serial,
      userName: formValue.userName,
      password: formValue.password,
      mac: formValue.mac,
      gateway: formValue.gateway,
      description: formValue.description,
    };

    return this.httpClient.put(url, data);
  }
}
