import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, AuthConfig } from 'angular-oauth2-oidc';

import { OAuthConfigService } from '../../services/OAuth/oauth.service';
import { authConfig, OAuthModuleConfig } from './oauth.config';

export function init_app(authConfigService: OAuthConfigService) {
  return () => authConfigService.initAuth();
}

@NgModule({
  imports: [ HttpClientModule, OAuthModule.forRoot() ],
  providers: [
    OAuthConfigService,
    { provide: AuthConfig, useValue: authConfig },
    OAuthModuleConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [ OAuthConfigService ],
      multi: true
    }
  ]
})
export class OAuthConfigModule { }
