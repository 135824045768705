import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'official-roles-management',
  templateUrl: './roles-management.component.html',
  styleUrls: ['./roles-management.component.scss'],
})
export class RolesManagementComponent {

}
