import { Component, OnInit } from "@angular/core";
import {ICardRoadCorridorsModel} from "@types-custom/models/ui/card-road-corridors.model";
import { Icon } from '@types-custom/models/ui/icon-model';
import {BehaviorSubject} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsModel} from "@types-custom/models/ui/incidents-model";
import * as am4core from "@amcharts/amcharts4/core";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {ICardTrafficDataWazeModel} from "@types-custom/models/ui/card-traffic-data-waze-model";
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from "@types-custom/models/ui/button-model";

@Component({
  selector: "sit-panel-exodus-return-waze",
  templateUrl: "./panel-exodus-return-waze.component.html",
  styleUrls: ["./panel-exodus-return-waze.component.scss"],
})
export class PanelExodusReturnWazeComponent implements OnInit {
  Icon = Icon;
  cards: ICardTrafficDataWazeModel[] = [];
  cardsMainRoutes: ICardTrafficDataWazeModel[] = [];
  avSpeedIndicator = 0;
  avFlowIndicator = 0;
  listspeedMaxEnter: Array<any> = [];
  listspeedMaxLeaving: Array<any> = [];
  listspeedMinEnter: Array<any> = [];
  listspeedMinEnterAct: Array<any> = [];
  listspeedMinLeaving: Array<any> = [];
  listspeedMinLeavingAct: Array<any> = [];
  listrouteMaxEnter: Array<any> = [];
  listrouteMaxLeaving: Array<any> = [];
  listrouteMinEnter: Array<any> = [];
  listrouteMinLeaving: Array<any> = [];
  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;
  //avgSpeedValue: 0;
  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  validateActiveSpeed!: boolean;
  validateActiveRoad!: boolean;

  colorsSpeed = [
    { id: 60, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 50, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 40, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 30, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 20, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 10, color: am4core.color('rgba(255,153,1,0.7)') },
    { id: 0, color: am4core.color('rgba(255,87,34,0.7)') },
  ];

  bordersSpeed = [
    { id: 60, color: am4core.color('rgb(0,196,121)') },
    { id: 50, color: am4core.color('rgb(0,196,121)') },
    { id: 40, color: am4core.color('rgb(0,196,121)') },
    { id: 30, color: am4core.color('rgb(0,196,121)') },
    { id: 20, color: am4core.color('rgb(0,196,121)') },
    { id: 10, color: am4core.color('rgb(255,153,1)') },
    { id: 0, color: am4core.color('rgb(255,87,34)') },
  ];

  strFilter = "";
  strFilterType = "vehiculosFlow";
  strTitle = "Conteo de vehiculos";
  strIcon = "icon-serva";
  strcolor = "#CCFFCE";

  constructor() {
    //
  }

  ngOnInit(): void {
    this.validateActiveSpeed = true;
    this.setGraphic(this.cards);
    this.setIndicators(this.cards);
  }

  setIndicators(cards: ICardTrafficDataWazeModel[]) {
    let sumspeedAvg = 0;
    let totDistance = 0;
    let totIncidents = 0;

    cards.map((cards: ICardTrafficDataWazeModel) => {
      if (!isNaN(+cards.speed)) {
        sumspeedAvg = sumspeedAvg + +cards.speed * (+cards.distance / 1000);
        if (+cards.speed > 0) {
          totDistance = totDistance + +cards.distance / 1000;
        }
      }
    });

    this.avgSpeedProps.value = Math.round(sumspeedAvg / totDistance);

    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );

    this.cards.forEach((item:any)=>{
      this.cardsMainRoutes.push(item);
    })

    // this.cardsMainRoutes = this.cardsMainRoutes.sort((a:any,b:any) => b.corridor > a.corridor ? -1:1);

    this.cardsMainRoutes = this.cardsMainRoutes.sort((a:any, b:any) => a.corridor.localeCompare(b.corridor) || a.orientation.localeCompare(b.orientation));
    
    // let cardsEnter = this.sortCardsEnterArray(cards.filter((item:any)=> item.orientation == 'Entrando'));
    //console.log(this.cardsMainRoutes);
  }

  setGraphic(cards: ICardTrafficDataWazeModel[]) {
    const lookup = {} as any;
    const lookupD = {} as any;

    // console.log(cards);
    let cardsMaxEnter = cards.filter((item:any)=> item.orientation == 'Entrando');
    let num = cards.length;

    let cardsMaxLeaving = cards.filter((item:any)=> item.orientation == 'Saliendo');
    let numMaxLeaving = cards.length;

    let cardsMin = cards.filter((item:any)=> item.orientation == 'Entrando');
    let numMin = 1;

    let cardsMinLeaving = cards.filter((item:any)=> item.orientation == 'Saliendo');
    let numMinLeaving = 1;

    cardsMaxEnter.map((card: ICardTrafficDataWazeModel) => {
      var speed = parseFloat(card.speed).toFixed(2);

      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
        const object: any = new Object();
        object['id'] = num;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getColor(speed),

        this.listspeedMaxEnter.push(object);
        this.listspeedMaxEnter.sort();
        num--;
        //console.log(this.listspeedMaxEnter);
    });

    cardsMaxLeaving.map((card: ICardTrafficDataWazeModel) => {
      var speed = parseFloat(card.speed).toFixed(2);

      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
        const object: any = new Object();
        object['id'] = numMaxLeaving;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getColor(speed),

        this.listspeedMaxLeaving.push(object);
        this.listspeedMaxLeaving.sort();
        numMaxLeaving--;
        //console.log(this.listspeedMaxLeaving);
    });

    cardsMin.map((card: ICardTrafficDataWazeModel) => {
      var speed = parseFloat(card.speed).toFixed(2);

      if(speed.length <= 4){
        speed = '0'+speed;
      }

      //console.log(card);
      
        const object: any = new Object();
        object['id'] = numMin;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getColor(speed),

        this.listspeedMinEnter.push(object);
        this.listspeedMinEnter.sort();
        numMin++;
      });

      // console.log(this.listspeedMinEnter);
      // let val = [...this.listspeedMinEnter].sort((a:any,b:any) => a.qty - b.qty);
      // console.log(val);
      // val.forEach((item:any)=>{
      //   console.log(item);
      //   this.listspeedMinEnterAct.push(item);
      // })

      // console.log(this.listspeedMinEnterAct);

    cardsMinLeaving.map((card: ICardTrafficDataWazeModel) => {
      var speed = parseFloat(card.speed).toFixed(2);

      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
      //console.log(card);
      
        const object: any = new Object();
        object['id'] = numMinLeaving;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getColor(speed),

        this.listspeedMinLeaving.push(object);
        this.listspeedMinLeaving.sort();
        numMinLeaving++;
        console.log(this.listspeedMinLeaving);
    });

    // this.listspeed.forEach((item) => {
    //   item.qty = lookup[item.id];
    //   item.dta = lookupD[item.id];
    // });
  }

  setGraphicRoute(cards: ICardTrafficDataWazeModel[]) {
    const lookup = {} as any;
    const lookupD = {} as any;

    // console.log(cards);
    let cardsRouteMaxEnter = cards.filter((item:any)=> item.orientation == 'Entrando');
    let num = cards.length;

    let cardsRouteMaxLeaving = cards.filter((item:any)=> item.orientation == 'Saliendo');
    let numMaxLeaving = cards.length;

    let cardsRouteMin = cards.filter((item:any)=> item.orientation == 'Entrando');
    let numMin = 1;

    let cardsRouteMinLeaving = cards.filter((item:any)=> item.orientation == 'Saliendo');
    let numMinLeaving = 1;

    cardsRouteMaxEnter.map((card: ICardTrafficDataWazeModel) => {
      var speed = card.timeMinutes.toFixed(2);

      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
        const object: any = new Object();
        object['id'] = num;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getRoad(speed),

        this.listrouteMaxEnter.push(object);
        this.listrouteMaxEnter.sort();
        num--;
        console.log(this.listrouteMaxEnter);
    });

    cardsRouteMaxLeaving.map((card: ICardTrafficDataWazeModel) => {
      var speed = card.timeMinutes.toFixed(2);

      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
        const object: any = new Object();
        object['id'] = numMaxLeaving;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getRoad(speed),

        this.listrouteMaxLeaving.push(object);
        this.listrouteMaxLeaving.sort();
        numMaxLeaving--;
        console.log(this.listrouteMaxLeaving);
    });

    cardsRouteMin.map((card: ICardTrafficDataWazeModel) => {
      var speed = card.timeMinutes.toFixed(2);
      
      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
        const object: any = new Object();
        object['id'] = numMin;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getRoad(speed),

        this.listrouteMinEnter.push(object);
        this.listrouteMinEnter.sort();
        numMin++;
        console.log(this.listrouteMinEnter);
    });

    cardsRouteMinLeaving.map((card: ICardTrafficDataWazeModel) => {
      var speed = card.timeMinutes.toFixed(2);
      
      if(speed.length <= 4){
        speed = '0'+speed;
      }
      
        const object: any = new Object();
        object['id'] = numMinLeaving;
        object['label'] = card.corridor;
        object['dta'] = 'Número';
        object['qty'] = speed    
        object['color'] = this.getRoad(speed),

        this.listrouteMinLeaving.push(object);
        this.listrouteMinLeaving.sort();
        numMinLeaving++;
        console.log(this.listrouteMinLeaving);
    });

    // this.listspeed.forEach((item) => {
    //   item.qty = lookup[item.id];
    //   item.dta = lookupD[item.id];
    // });
  }

  top3MaxArray(numbers:any) {  
    numbers = numbers.sort((a:any,b:any) => b.speed - a.speed);
    return [numbers[0], numbers[1], numbers[2]];
  };

  top3MinArray(numbers:any) {  
    numbers = numbers.sort((a:any,b:any) => a.speed - b.speed);
    return [numbers[0], numbers[1], numbers[2]];
  };

  orderArrayReverse(numbers:any){
    numbers = numbers.sort((a:any,b:any) => a.speed - b.speed);
    return numbers;
  }

  top3RouteMaxArray(numbers:any) {  
    numbers = numbers.sort((a:any,b:any) => b.timeMinutes - a.timeMinutes);
    return [numbers[0], numbers[1], numbers[2]];
  };

  top3RouteMinArray(numbers:any) {  
    numbers = numbers.sort((a:any,b:any) => a.timeMinutes - b.timeMinutes);
    return [numbers[0], numbers[1], numbers[2]];
  };

  sortCardsEnterArray(numbers:any) {  
    numbers = numbers.sort((a:any,b:any) => b.corridor > a.corridor ? -1:1);
    return numbers;
  };

  getColor(speed: any){

    let color : any;

    if(speed>50){
      color = am4core.color('rgba(0,196,121,0.7)');
    }else if(speed<=50 && speed>40){
      color = am4core.color('rgba(0,196,121,0.7)');
    }else if(speed<=40 && speed>30){
      color = am4core.color('rgba(0,196,121,0.7)');
    }else if(speed<=30 && speed>20){
      color = am4core.color('rgba(0,196,121,0.7)');
    }else if(speed<=20 && speed>10){
      color = am4core.color('rgba(255,153,1,0.7)');
    }else{
      color = am4core.color('rgba(255,50,50,0.71)');
    }
    
    return color;
  }

  getRoad(speed: any){

    let color : any;

    if(speed<=10){
      color = am4core.color('rgba(0,196,121,0.7)');
    }else if(speed<=20 && speed>10){
      color = am4core.color('rgba(0,196,121,0.7)');
    }else if(speed<=30 && speed>20){
      color = am4core.color('rgba(255,153,1,0.7)');
    }else if(speed<=40 && speed>30){
      color = am4core.color('rgba(255,153,1,0.7)');
    }else if(speed<=50 && speed>40){
      color = am4core.color('rgba(255,153,1,0.7)');
    }else{
      color = am4core.color('rgba(255,50,50,0.71)');
    }
    
    return color;
  }

  fluxButtonProps: IButtonModel = {
    label: 'Velocidad',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  speedButtonProps: IButtonModel = {
    label: 'Recorrido',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  OnFilter(FilterType:any){
    this.strFilterType = FilterType;
    if (FilterType == "speed"){
      if(!this.validateActiveSpeed){
        this.validateActiveSpeed = true;
        this.validateActiveRoad = false;
        this.setGraphic(this.cards);
      }
    }
    else{
      if(!this.validateActiveRoad){
        this.validateActiveRoad = true;
        this.validateActiveSpeed = false;
        this.setGraphicRoute(this.cards);
      }
    }
    console.log(this.validateActiveSpeed);
    console.log(this.validateActiveRoad);
  }
}
