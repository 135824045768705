import {
  IGeometryLineModel,
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsTrafficConcordanceModel {
  expectValue?: number;
  id: string;
  nameFrom: string;
  nameTo: string;
  distance: string;
  speed: string;
  levelOfService: string;
  concordance: string;
  composed_name : string;
  elapsedTime: number;
  expectedValue: number;
  readTime: string;
  readings: number;
  time: string;
  icon: Icon;
}

export class TrafficConcordanceModel extends MarkerModelBase {
  static className = 'TrafficConcordanceModel';
  classProperties: IPropsTrafficConcordanceModel;

  constructor(
    props: IPropsTrafficConcordanceModel,
    geometry: IGeometryLineModel | IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, TrafficConcordanceModel.className, lat, lng);
    this.classProperties = props;
    this.geometry = geometry;
  }
}
