import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ILocationOption } from '@types-custom/models/business/location.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IButtonModel,
  IClassesAttributes,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Dropdown
 ** Render a dropdown that load items from a specified datasource.
 */
@Component({
  selector: 'sit-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() dataSource$:
    | BehaviorSubject<ILocationOption[] | undefined>
    | undefined;
  @Input() label: string | undefined;

  @Input() selection$: BehaviorSubject<any> | undefined;
  @Input() classesAttributes: IClassesAttributes | undefined;
  @Input() filterPosition!: number;
  @Input() filterOnChange!: BehaviorSubject<number>;
  
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();

  _dataSource$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  subscription$ = new Subject();
  matSelectItem = '';

  ngOnInit(): void {
    if (this.dataSource$) {
      this.dataSource$
        .pipe(takeUntil(this.subscription$))
        .subscribe((data) => this._dataSource$.next(data));
    }

    if (this.selection$) {
      this.selection$
        .pipe(takeUntil(this.subscription$))
        .subscribe((value) => (this.matSelectItem = value ?? ''));
    }
  }

  ngOnDestroy(): void {
    this.subscription$.next(undefined);
    this.subscription$.complete();
  }

  onSelectionChange($event: any) {
    this.selection$?.next($event.value);
    this.filterOnChange?.next(this.filterPosition);
    this.changeValue.emit($event.value)
  }
}
