import {Icon} from "@types-custom/models/ui/icon-model";
import {IGeometryLineModel, IGeometryModel, MarkerModelBase} from "@types-custom/models/business/marker.model";
import {IPropsRoadCorridorsModel} from "@shared/models/road-corridors.model";

export interface IPropsWazeDataTrafficModel {
    id: string;
    uuid: string;
    tid: string,
    icon: Icon;
    name : string,
    velocity: number;
    length: string;
    timeMinutes: number;
    historyTimeMinutes : number;
    publicationDate : string;
    updateDate: string,
    receptionDate : string;
    speedRange? : [];
    category : string;
    orientation : string;
    corridor: string;
    markerPopupText: string;
}

export class WazeDataTrafficModel extends MarkerModelBase {
    static className = 'WazeDataTrafficModel';
    classProperties: IPropsWazeDataTrafficModel;

    constructor(
        props: IPropsWazeDataTrafficModel,
        geometry: IGeometryLineModel | IGeometryModel,
        lat?: number,
        lng?: number
    ) {
        super(geometry, WazeDataTrafficModel.className, lat, lng);
        this.classProperties = props;
        this.geometry = geometry;
    }
}