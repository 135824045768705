import { Component, Input, OnInit } from '@angular/core';
import {
  ButtonClassesEnum,
  IButtonModel,
  IClassesAttributes,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import {
  DynamicFilterLayoutEnum,
  DynamicFilterTypeEnum,
  IDynamicFilterModel,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import { BehaviorSubject, Subject } from 'rxjs';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Dynamic Filter
 ** Allows you to filter data based on the specified model.
 */

@Component({
  selector: 'dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styleUrls: ['./dynamic-filter.component.scss'],
})
export class DynamicFilterComponent implements OnInit {
  @Input() properties!: IDynamicFilterModel;
  @Input() layout = DynamicFilterLayoutEnum.HORIZONTAL;

  readonly DynamicFilterLayoutEnum = DynamicFilterLayoutEnum;

  styleValue!: string;

  buttonAttributes: IButtonModel = {};
  cleanbuttonAttributes: IButtonModel = {};

  dropdownAttributes: IClassesAttributes = {};

  readonly DynamicFilterTypeEnum = DynamicFilterTypeEnum;
  readonly IconClassesEnum = IconClassesEnum;

  filtersSubject = new BehaviorSubject<ISdkDynamicFilterModel[]>([]);
  filterChangeDispatcher = new BehaviorSubject(0);
  filterChangeValue: BehaviorSubject<string | undefined>;
  Icon = Icon;

  constructor() {
    this.generateButtonAttributes();
    this.filtersSubject.subscribe(this.handleFilters.bind(this));
  }

  ngOnInit(): void {
    this.filtersSubject.next(this.properties.dataSource.filterList);

    this.filterChangeDispatcher.subscribe((filterPosition) =>
      this.handleCleanFiltersChanges(filterPosition)
    );

    // this.filterChangeValue.subscribe((filterValue) => 
    //   this.handleValueChange(filterValue)
    // );
  }

  private handleFilters(filters: ISdkDynamicFilterModel[]): void {
    if (this.layout === DynamicFilterLayoutEnum.VERTICAL) {
      this.styleValue = 'grid-template-columns: 1fr';
    } else if (this.properties?.isSpacerFirst) {
      this.styleValue = filters.reduce(
        (pre) => `${pre} minmax(200px, 220px)`,
        'padding-right: 1.5rem; grid-template-columns: 1fr'
      );
    } else {
      this.styleValue = '';
    }
  }

  private generateButtonAttributes(): void {
    this.buttonAttributes = {
      label: 'BUSCAR',
      classAttributes: {
        buttonClass: ButtonClassesEnum.blue_button,
      },
    };
    this.cleanbuttonAttributes = {
      label: 'LIMPIAR FILTRO',
      classAttributes: {
        buttonClass: ButtonClassesEnum.blue_button,
      },
    };
  }

  search(): void {
    const {
      properties: { sdkDynamicFiltersService, filtersDispatcher },
    } = this;
    const model = sdkDynamicFiltersService.getModel();
    filtersDispatcher.next(model);
    this.properties.isActiveFilter?.next(
      !this.properties.isActiveFilter?.value
    );
  }

  cleanFiltersValue(): void {
    const {
      properties: { sdkDynamicFiltersService, filtersDispatcher },
    } = this;
    sdkDynamicFiltersService.cleanFilters();
    const model = sdkDynamicFiltersService.getModel();
    filtersDispatcher.next(model);
  }

  handleTextChange(valueSubject: BehaviorSubject<any>, $event: any): void {
    valueSubject.next($event?.target?.value);
  }

  handleCleanFiltersChanges(filterPosition: number) {
    //
  }

  handleValueChange(searh: boolean, valueSubject: BehaviorSubject<any>, $event: any): void {
    if (searh) {
      valueSubject.next($event?.target?.value);
      this.search();
    }
  }

  handleSelectionValueChange(searh: boolean): void {
    if (searh) {
      this.search();
    }
  }

  onShowPanel() {
    this.properties.showPanelConventions$?.value
      ? this.properties.showPanelConventions$?.next(false)
      : this.properties.showPanelConventions$?.next(true);
  }
}
