import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, } from "@angular/forms";
import { ExportExcelService } from "@official/services/data-grid-service/export-excel-service/export-excel.service";
import { VehicleNoveltyService } from "@official/services/data-grid-service/vehicle-novelty-service/vehicle-novelty.service";
import { SdkDynamicFiltersService } from "@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service";
import { CommonService } from "@shared/services/common-service/common-service.service";
import { ButtonClassesEnum, IButtonModel } from "@types-custom/models/ui/button-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { INoveltyVehicleModel } from '@types-custom/models/ui/modal-edit-novelty-vehicle';
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalConfirmationComponent } from "@ui-core/components/modal-confirmation/modal-confirmation.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";

@Component({
  selector: "sit-modal-edit-novelty-vehicle",
  templateUrl: "./modal-edit-novelty-vehicle.component.html",
  styleUrls: ["./modal-edit-novelty-vehicle.component.scss"],
  providers: [
    VehicleNoveltyService,
    SdkDynamicFiltersService,
    ExportExcelService,
    CommonService,
  ]
})
export class ModalEditNoveltyVehicleComponent implements OnInit {
  @Input() properties: INoveltyVehicleModel | undefined;
  registerFormGroup: UntypedFormGroup;
  fb = new UntypedFormBuilder();

  // @Input() properties: INoveltyVehicleModel | undefined = {


  ngOnInit(): void {
    this.initializeForm();

  }

  Icon = Icon;
  files: any;
  filesEdit: any = [];


  constructor(
    private modalService: ModalService,
    private vehicleService: VehicleNoveltyService,
  ) { }
  private initializeForm(): void {
    const formControls: { [key: string]: any } = {
      description: [
        { value: '', disabled: false },
        [
          Validators.required,
        ],
      ],
    };

    // this.rolesList.forEach((item: string) => {
    //   formControls[item] = [''];
    // });

    this.registerFormGroup = this.fb.group(formControls);

  }

  buttonSaveAttributes: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_resources,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonCancelAttributes: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_cancel,
      spanClass: 'text-weight-600 text-size-16'
    },
  };

  onCancel(): void {
    this.modalService.closeConfirmationModal();
  }

  validateUserSubmit(): void {
    const dataBody = {
      typeEventId: this.properties.id,
      physicalResourceid: this.properties.physicalResourceId,
      comments: this.registerFormGroup.get('description').getRawValue()
    };

    this.modalService.closeConfirmationModal();
    this.vehicleService.postComment(dataBody).subscribe({
      next: (response: any) => {
        this.registerFormGroup.disable();

        setTimeout(() => {
          this.showModalSuccess('La actividad fue creada exitosamente');
        }, 200);
      },
      error: (error: any) => this.handlingError(error)

    });
  }

  private handlingError(error: any): void {
    console.error({ error });
  }

  validateFormGroup(): void {
    this.registerFormGroup.markAllAsTouched();
  }

  showModalSuccess(message: string): void {
    this.modalService.closeConfirmationModal();
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      leftLineClass: 'bg-color-state-3',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }
}