import { Component, Input } from "@angular/core";
import { DrawerPanelService } from "@ui-core/services/drawer-panel/drawer-panel.service";
import {Icon} from "@types-custom/models/ui/icon-model";
import { ICardIncidentsLocationsModel } from "@types-custom/models/ui/cards-incidents-locations";


@Component({
  selector: "sit-incidents-cards",
  templateUrl: "./incidents-cards.component.html",
  styleUrls: ["./incidents-cards.component.scss"],
})
export class IncidentsCardsComponent {
  @Input() card:
  | ICardIncidentsLocationsModel
  | undefined;

@Input() number : number

constructor(private drawerPanelService: DrawerPanelService) {
}

date : string = '';
time : string = '';

ngOnInit(): void {
this.drawerPanelService.collapseDrawer(true);
this.date = this.convertDate(new Date(this.card.pubTime))
this.time = this.convertTime(new Date(this.card.pubTime))

}

convertDate(date : Date) : any {
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();

return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}

convertTime(date : Date): any {
const hour = date.getHours();
const minutes = date.getMinutes();

const sub = hour >= 12 ? 'PM' : 'AM';

const hour12 = hour % 12 === 0 ? 12 : hour % 12;

return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${sub}`;
}
openModal(): void {

  this.drawerPanelService.collapseDrawer(true);
  this.card.cardMapLink?.markerDispatcher.next(
    this.card.cardMapLink.markerModel
  );
}

protected readonly Icon = Icon;
}
