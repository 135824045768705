<!-- Botón para desplegar el menú (basado en chips.svg) -->
<button (click)="toggleDropdown()" class="dropdown-button" [ngClass]="this.selectedItem ? 'active' : ''">
    <span class="text-white">{{ this.selectedItem || label }}</span>
    <!-- Ícono o representación del SVG "Chips" -->
    <div class="icon-chips"></div>
</button>

<!-- Menú desplegable (basado en roles.svg) -->
<div [ngClass]="{'show': showDropdown}" class="dropdown-menu">
    <!-- Aquí puedes colocar las opciones del menú -->
    <!-- <ng-container *ngFor="let item of _dataSource$ | async as items">
        <div class="dropdown-item" (click)="selectItem(item)">
            {{ item.label }}
        </div>
    </ng-container> -->
    
    <mat-chip-listbox (change)="onSelectionChange($event)">
        <ng-container *ngFor="let item of _dataSource$ | async as items">
            <mat-chip-option  [value]="item.value" (click)="setLabel(item.label)">{{ item.label }} </mat-chip-option>
        </ng-container>
    </mat-chip-listbox>
    
</div>
