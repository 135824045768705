<ng-container *ngIf="properties | async as props">
  <ng-container *ngIf="props.toggleDispatcher$ | async">
    <aside class="dynamic-side-menu-container right montserrat-font" tabindex="0">

          <div class="menu right panel-res d-flex fd-col" >
            <div class="handle-container">
              <div class="handle d-none d-md-block"></div>
            </div>
            <section
              class="title d-flex bg-color-background-1 bg-md-white align-items-center border-md-top-right-25 border-md-top-left-25"
              SheetBottom classContainer="menu">
              <!-- <div class="left-title-block bg-color-primary-1"></div> -->
              <div class="d-flex full-width special-p">
                <sit-icon [icon]="props.titleIcon" class="icon-title" [sizeClass]="'icon-size-40'"
                  [alt]="props.alt"></sit-icon>
                <h2
                  class=" title-panel flex-grow text-weight-600 text-size-24 ml-8 text-md-size-18 text-white align-self-center">
                  {{ props.title }} <span class="text-blue">{{props.subtitle}}</span>
                </h2>
                <sit-icon-button [alt]="'Cerrar'" (click)="toggleMenu()" (click)="disableClickDispatcher()"
                  [icon]="Icon.close_w" [colorClass]="'text-white'" class="text-blue">
                </sit-icon-button>
              </div>
            </section>

            <section class="content">
              <ng-container *ngIf="props.content as toRender; else ngContent">
                <ng-container *ngIf="isComponentVisible | async">
                  <sit-renderer [toRender]="toRender"></sit-renderer>
                </ng-container>
              </ng-container>
              <ng-template #ngContent>
                <ng-content></ng-content>
              </ng-template>
            </section>
          </div>


    </aside>
  </ng-container>
</ng-container>
