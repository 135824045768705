import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { GeometryTypeEnum } from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: 'map-filter-agents-historical',
  templateUrl: './map-filter-agents-historical.component.html',
  styleUrls: ['./map-filter-agents-historical.component.scss'],
})
export class MapFilterAgentsHistoricalComponent implements OnInit {
  @Input() properties: any;
  Icon = Icon;
  historicalDate = '';
  historicalHour = '';
  sliderMax = 0;
  sliderOptions: any[];
  autoPlaySlider: any = null;
  sliderValue = 0;
  currentDate = new Date();

  ngOnInit() {
    this.setSlider();
  }

  setSlider() {
    const featuresHeatMap = this.properties.data[0].filter(
      (e: any) => e.geometryType === GeometryTypeEnum.HeatMap
    )[0].feature.features;

    this.sliderOptions = [
      ...new Set(featuresHeatMap.map((e: any) => e.properties.time)),
    ].sort((a: any, b: any) => {
      return a.localeCompare(b);
    });

    this.historicalDate = this.sliderOptions[0];
    this.sliderMax = this.sliderOptions.length;

  }

  autoPlay() {
    if (this.autoPlaySlider) {
      this.stopAutoPlay();
      return;
    }

    this.autoPlaySlider = setInterval(() => {
      if (this.sliderValue === this.sliderMax) {
        this.stopAutoPlay();
        return;
      }
      this.filter(this.sliderValue);
      this.sliderValue += 1;
    }, 500);
  }

  stopAutoPlay() {
    clearInterval(this.autoPlaySlider);
    this.autoPlaySlider = null;
  }

  filter(value: number) {
    const date = this.sliderOptions[value === -1 ? 0 : value];
    this.historicalDate = date;
    const filterValue = value === -1 ? undefined : date;

    this.filterHeatLayer(filterValue);
    this.filterPointAndLineLayer(filterValue);
  }

  filterHeatLayer(date: any) {
    let filter: string[] = [];
    if (date) {
      filter = ['==', 'time', date];
    }

    //console.log(this.properties);
    //console.log(filter);

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer + '-Heat',
      filter: filter,
    });
  }

  filterPointAndLineLayer(date: any) {
    const featuresPoint = this.properties.data[0]
      .filter((e: any) => e.geometryType === GeometryTypeEnum.Point)[0]
      .feature.features.filter((e: any) =>
        date ? e.properties.time <= date : e
      )
      .sort((a: any, b: any) =>
        a.properties.time.localeCompare(b.properties.time)
      );


    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer,
      filter: undefined,
      source: featuresPoint ? featuresPoint : undefined,
    });

    const lineSource = this.generateFilteredFeatures(featuresPoint);

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer + '-Lines',
      filter: undefined,
      source: lineSource ? lineSource : undefined,
    });
  }

  generateFilteredFeatures(data: any): any[] {
    const agentsIds = [...new Set(data.map((e: any) => e.properties.id))];

    const agentsLineFeatures = [];

    for (const item of agentsIds) {
      const coordinates = data
        .filter((e: any) => e.properties.id === item)
        .map((e: any) => e.geometry.coordinates);

      const properties = data.filter((e: any) => e.properties.id === item)[0]
        .properties;

      const agentsLineFeature = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: GeometryTypeEnum.LineString,
          coordinates: coordinates,
        },
      };
      agentsLineFeatures.push(agentsLineFeature);
    }
    return agentsLineFeatures;
  }
}
