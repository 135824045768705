import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder } from "@angular/forms";
import { GeoAPITypeEnum } from "@shared/models/geo-api-type.model";
import { GeoApiService } from "@shared/services/geo-api-service/geo-api.service";
import { GeometryTypeEnum, MarkerModelBase } from "@types-custom/models/business/marker.model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { Observable, fromEvent, map, startWith } from "rxjs";
import { FieldResourcesService } from "@shared/services/layer-service/field-resources/field-resources.service";

@Component({
  selector: "sit-assignable-resources",
  templateUrl: "./assignable-resources.component.html",
  styleUrls: ["./assignable-resources.component.scss"],
})
export class AssignableResourcesComponent implements OnInit {
  @Input() properties: any;

  resourceControl = new FormControl('');
  options: any[] = [];
  filteredOptions: Observable<any[]>;

  ResourcesGroupTypeList: any[] = [
    {
      name: "Grupo Guía",
      value: 17
    },
    {
      name: "Ciempiés",
      value: 20
    },
    {
      name: "Al Colegio En Bici",
      value: 19
    },
    {
      name: "Agentes Civiles",
      value: 18
    }
  ]

  filterCategoryOptions: string[];
  defaultDateStart = (new Date(new Date().setHours(0, 0, 0, 0))).toISOString();
  defaultDateEnd = (new Date().toISOString());

  Icon = Icon;
  incidentsTypes: [] = [];
  incidentForm: FormGroup;

  constructor(
    private geoApiService: GeoApiService,
    private fieldResourcesService : FieldResourcesService,
  ) { }

  ngOnInit() {
    this.fieldResourcesService.getFilter().subscribe((data) => {
      if(data) {
        this.resourceControl.setValue(data);
      }
    });
  }

  reloadData(response: any) {
    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const AssignableResourcesLayer = response;

    AssignableResourcesLayer.forEach((mark: any) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type:  (mark as any).geometry.type,
          coordinates: (mark as any).geometry.coordinates,
        },
        className : (mark as any).className,

      };
      points.markers.push(mark);
      points.dataFeatures.push(feature as never);
    });

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer,
      filter: undefined,
      source: points.dataFeatures
    });

    document.querySelector<HTMLElement>('#closeFilter').click();
    setTimeout(() => {
      document.querySelector<HTMLElement>('#closeFilter').parentElement.classList.add('applied-filter');
      document.querySelector('.marker-active-situations-layer')?.classList.remove('marker-active-situations-layer');
    }, 10);
  }

  public clearFilter() {
    this.resourceControl.setValue('')
  }


  private generateURL() {
    let url = 'format-geo-json?recordsPerPage=1000&page=0';

    if (this.resourceControl.value) {
      // @ts-ignore
      let data: any[] = this.resourceControl.value
      data.forEach((item: any) => {
        url += `&groups=${item}`
      });
    }
    url += '&hours=24';
    return url;
  }

  applyFilter() {
    const initialFilterValue: { [key: string]: any } = {
      recordsPerPage: 1000,
      page: 0,
      indicative: '',
    };
    initialFilterValue.groups = this.resourceControl.value;
    const url = this.generateURL();
    this.fieldResourcesService.setFilter(this.resourceControl.value)
    this.geoApiService.getResourceHumanLis(GeoAPITypeEnum.incidentAddResources, true, url )
      .subscribe({
        next: this.reloadData.bind(this),
      });
  }

  updateSelection(control: AbstractControl, event: any, item: any) {
    const selectedItemsControl = control;
    const selectedItems = selectedItemsControl?.value || [];
    if (event.target.checked) {
      selectedItems.push(item.value);
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: AbstractControl, item: any): boolean {
    return control.value.includes(item.value);
  }

  displayFn(human: any): string {
    return human && human.value ? human.value : '';
  }

}
