import { LoginGuard } from '@official/guards/login.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OFFICIALRoutes } from '@official/routes/routes';
import { LogoutGuard } from '@official/guards/logout.guard';

@NgModule({
  imports: [RouterModule.forRoot(OFFICIALRoutes)],
  exports: [RouterModule],
  providers: [LoginGuard, LogoutGuard],
})
export class AppRoutingModule {}
