import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { Observable, debounceTime, distinctUntilChanged, map, retry, startWith, switchMap } from "rxjs";
import {FieldResourcesService} from "@shared/services/layer-service/field-resources/field-resources.service";


@Component({
  selector: "sit-modal-incidents-resource",
  templateUrl: "./modal-incidents-resource.component.html",
  styleUrls: ["./modal-incidents-resource.component.scss"],
})
export class ModalIncidentsResourceComponent implements OnInit{
  @Input() properties : any | undefined;

  Icon = Icon;
  items = new Array(10);

  listScheduledActivities: any[];
  listUnScheduledActivities: any[];
  listIncidentsWithoutAssign:any[];


  props:{
    listScheduledActivity: (Id: number, value?: string) => Observable<any>,
    listUnscheduledActivity:(Id: number, value?: string) => Observable<any>,
    listIncidentsWithoutAssign :() => Observable<any>
  }

  constructor(private incidentsResourcesService : FieldResourcesService) {
  }

  ngOnInit(): void {
    this.props = this.properties.resourcesDataSource;
    this.init()
  }

  init(): void {
    this.props.listScheduledActivity(this.properties?.resourceId).subscribe({
      next: (response) => { 
        this.listScheduledActivities = response;
      
      },
      error: (error: any) => console.log(error)
    });

    this.props.listUnscheduledActivity(this.properties?.resourceId).subscribe({
      next: (response) => { 
        this.listUnScheduledActivities = response;
      
      },
      error: (error: any) => console.log(error)
    });

    this.props.listIncidentsWithoutAssign().subscribe({
      next: (response) => { 
        this.listIncidentsWithoutAssign = response;
      },
      error: (error: any) => console.log(error)
    });

    

  }

  AssignIncident(id : number) {
    this.incidentsResourcesService.addHumanResourceIncident(id,this.properties.resourceId).subscribe((res : any) => {
      this.ngOnInit()
    })
  }
}


