import {Component, Input} from "@angular/core";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsModel} from "@types-custom/models/ui/incidents-model";
import { Icon } from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-incidents",
  templateUrl: "./incidents.component.html",
  styleUrls: ["./incidents.component.scss"],
})
export class IncidentsComponent {
  Icon = Icon;
  @Input() properties: IncidentsModel = {
    value: 50,
    divId: 'id',
    descriptionLabel : 'Incidentes verificados',
    labelsClass: 'gap-row-10-px',
  };
}
