import { Component } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  icon = Icon.traffic_arrows_black;
}
