import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsCoosModel {
  id: string;
  location: string;
  hourClose: string;
  hourWork: string;
  contractor: string;
  observations: string;
  typeCoos: string;
  typeInt: string;
  fechaIni: string;
  fechaFin: string;
  title: string;
  markerPopupText: string;
  icon: Icon;
}

export class CoosModel extends MarkerModelBase {
  static className = 'CoosModel';
  classProperties: IPropsCoosModel;

  constructor(
    props: IPropsCoosModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, CoosModel.className, lat, lng);
    this.classProperties = props;

    this.geometry = geometry;
  }
}
