<div
  DragDrop
  (fileDropped)="onDroppedFile($event)"
  class="drag-drop-box full-width d-flex align-items-center justify-center mb-8"
>
  <label for="files" class="text-white"
    >Arrastre aquí su(s) foto(s) o haga click para añadir</label
  >
  <input
    #filesInput
    id="files"
    type="file"
    [attr.accept]="getFileTypesAllowed"
    (change)="onBrowseFile($event)"
    class="full-width full-height"
    multiple
  />
</div>
<div class="file-list text-white mt-1-em">
  <div *ngFor="let file of files; let i = index" class="d-flex fd-row">
    <img *ngIf="file.path" [src]="file.urlAct" alt="your image" width="100" height="100" />
    <img *ngIf="!file.path" [src]="getUrl(file)" alt="your image" width="100" height="100" />
    <!-- <span class="text-white">{{ i + 1 }}. {{ formatBytes(file.size) }}</span>
    <span class="ml-16 text-white">{{ file.name }}</span> -->
    <sit-icon
      [icon]="icon"
      [sizeClass]="'icon-size-20'"
      (click)="deleteFile(i,file)"
      class="cursor-pointer text-white"
    ></sit-icon>
  </div>
</div>
