<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar" tabindex="0">
  <div class="pb-1-rem pt-20">
    <div class="d-flex px-1 count-box">
      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
            <sit-icon [icon]="Icon.eye_blue" [sizeClass]="'icon-size-40'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">{{this.toChecked}}</span>
          </span>
          <span class="subject">
            Por verificar
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
            <sit-icon [icon]="Icon.check_blue" [sizeClass]="'icon-size-40'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">{{this.verified}}</span>
          </span>
          <span class="subject">
            Verificados
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">

          <span class="title">
            <sit-icon [icon]="Icon.layers_blue" [sizeClass]="'icon-size-40'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">
              {{this.Duplicates}}
            </span>
          </span>
          <span class="subject">
            Duplicados
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
            <sit-icon [icon]="Icon.close_blue" [sizeClass]="'icon-size-40'" alt="icono persona">
            </sit-icon>
            <span class="text-size-32 mt-4">{{this.Closed}}</span>
          </span>
          <span class="subject">
            Cerrrados
          </span>
        </div>
      </div>

    </div>
  </div>

  <div class="d-flex px-1 fd-col mb-1-em">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.alert_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
       Clase de Incidente
      </h3>
    </div>
    <div class="d-flex fd-row chart-line-container">
      <sit-chart-series [properties]="dataGraphicLine" [colorScale]="colorScale" class="full-width 
      h-350 ">
      </sit-chart-series>
    </div>
  </div>

  <div class="d-flex px-1 fd-col mb-1-em ">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.areas_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
        Incidentes por Localidad
      </h3>
    </div>
    <div class="chart-line-container">
      <sit-chart-column *ngIf="listIncidentsLocations.length < 5" [data]="listIncidentsLocations" [valueXAttrib]="'dta'" [id]="'speed-range'" [valueXText]="'Número de incidentes'" className="h-350"></sit-chart-column>
      <sit-chart-column *ngIf="listIncidentsLocations.length > 5" [data]="listIncidentsLocations" [valueXAttrib]="'dta'" [id]="'speed-range'" [valueXText]="'Número de incidentes'" className="h-700"></sit-chart-column>
    </div>
  </div>

  <div class="d-flex px-1 fd-col mb-1-em">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.areas_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
        Incidentes por Prioridad
      </h3>
    </div>
    <div class="chart-line-container">
      <sit-chart-column-waze [data]="listIncidentsPriority" [id]="'id'" [valueXAttrib]="'dta'" className="h-350"></sit-chart-column-waze>
    </div>
  </div>

  <div class="d-flex px-1 fd-col mb-1-em">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.alert_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="velocímetro icono título rango de velocidad"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center">
        Incidentes
      </h3>
    </div>
  </div>

  <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher">
    <ng-container *ngFor="let card of cards; let i = index" class="pb-1-rem">
      <sit-incidents-cards [card]="card" [number]="i"></sit-incidents-cards>
    </ng-container>
  </div>

</div>
