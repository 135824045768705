<div class="d-flex sit-cards-array">
    <sit-trafficjam-card [card]="properties" [type]="1"></sit-trafficjam-card>
    <sit-trafficjam-card [card]="properties" [type]="2"></sit-trafficjam-card>
    <sit-trafficjam-card [card]="properties" [type]="3"></sit-trafficjam-card>
    <sit-trafficjam-card [card]="properties" [type]="4"></sit-trafficjam-card>
</div>
<div class="d-flex sitc-cards-footer-jam text-white full-width text-weight-600">

    <div class="d-flex gap-6-row1">
        <span class=""> Publicación </span>
        <span class="icon-l"> <sit-icon [icon]="Icon.calendar_w" [sizeClass]="'icon-size-20'"></sit-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{properties.pubtime}} </span>
        <span class="icon-l"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-20'"></sit-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{properties.pubtimeHour}} </span>
    </div>

    <div class="d-flex gap-6-row2">
        <span class=""> Recepción </span>
        <span class="icon-l"> <sit-icon [icon]="Icon.calendar_w" [sizeClass]="'icon-size-20'"></sit-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{properties.receptiontime}} </span>
        <span class="icon-l"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-20'"></sit-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{properties.receptiontimeHour}} </span>
    </div>
    
</div>

<!-- <div class="d-flex sitc-cards-footer-jam-2 text-white full-width text-weight-600">

    <div class="d-flex gap-6-row1">
        <span class=""></span>
        <span class="icon-l"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-20'"></sit-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{properties.pubtime}} </span>
    </div>

    <div class="d-flex gap-6-row2">
        <span class=""></span>
        <span class="icon-l"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-20'"></sit-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{properties.receptiontimeHour}} </span>
    </div>
    
</div> -->