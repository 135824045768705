import { Component, Input, OnInit } from '@angular/core';
import { IModalRoadworksModel } from '@types-custom/models/ui/modal-roadworks-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { Icon } from '@types-custom/models/ui/icon-model';
import { SituationTypeEnum } from '@shared/models/situations.model';

/*
 ** Modal Roadworks
 ** Render a modal that displays information about roads.
 */

@Component({
  selector: 'modal-roadworks',
  templateUrl: './modal-roadworks.component.html',
  styleUrls: ['./modal-roadworks.component.scss'],
})
export class ModalRoadworksComponent implements OnInit {
  @Input() properties: IModalRoadworksModel | undefined;
  Icon = Icon;
  dataSource: any = {};
  incidentsMapper: { [key: string]: any } = {};

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit() {
    this.drawerPanelService.collapseDrawer(true);
    this.properties.incidentsDataSource
      .getDataModal(this.properties.id)
      .subscribe((res) => {
        this.dataSource = res;
        this.fillIncident(this.dataSource);
      });
  }

  fillIncident(dataSource: any) {
    this.incidentsMapper = {
      'Estado Semáforo': [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Tipo de Semáforo',
          alt: 'icono Tipo de Semáforo',
          icon: Icon.check_w,
          value: dataSource.trafficLightTypeName,
        },
        {
          title: 'Tipo de Daño',
          alt: 'icono Tipo de Daño',
          icon: Icon.check_w,
          value: dataSource.trafficLightDamageTypeName,
        },
      ],
      Horarios: [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Registro de Turnos',
          alt: 'icono Registro de Turnos',
          icon: Icon.check_w,
          value: dataSource.turnRegistName,
        },
      ],
      'Estados de las Vías': [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Tipo de Reporte de la via',
          alt: 'icono Tipo de Reporte de la via',
          icon: Icon.check_w,
          value: dataSource.roadReportTypeName,
        },
        {
          title: 'Zona',
          alt: 'icono Zona',
          icon: Icon.check_w,
          value: dataSource.zoneName,
        },
      ],
      'Señales de Tránsito': [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Tipo de Señal del Reporte',
          alt: 'icono Tipo de Señal del Reporte',
          icon: Icon.check_w,
          value: dataSource.signalReportTypeName,
        },
        {
          title: 'Tipo de daño',
          alt: 'icono Tipo de daño',
          icon: Icon.check_w,
          value: dataSource.damageTypeName,
        },
      ],
      'Fallidos de Grúas': [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Motivo de Cancelación',
          alt: 'icono Motivo de Cancelación',
          icon: Icon.check_w,
          value: dataSource.cancelReasonName,
        },
      ],
      'Accidentes Conciliados': [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Tipo Gravedad Accidente',
          alt: 'icono Tipo Gravedad Accidente',
          icon: Icon.check_w,
          value: dataSource.gravityAccidentTypeName,
        },
        {
          title: 'Clase de Accidente',
          alt: 'icono Clase de Accidente',
          icon: Icon.check_w,
          value: dataSource.accidentTypeName,
        },
        {
          title: 'Tipo de Servicio',
          alt: 'icono Tipo de Servicio',
          icon: Icon.check_w,
          value: dataSource.serviceTypeName,
        },
      ],
      Inmovilizaciones: [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Placa Vehículo Inmovilizado',
          alt: 'icono Placa Vehículo Inmovilizado',
          icon: Icon.check_w,
          value: dataSource.immobilizedVehiclePlate,
        },
        {
          title: 'Móvil Grúa',
          alt: 'icono Móvil Grúa',
          icon: Icon.check_w,
          value: dataSource.craneMobileName,
        },
        {
          title: 'Código de Infracción',
          alt: 'icono Código de Infracción',
          icon: Icon.check_w,
          value: dataSource.immobilizationInfractionCode,
        },
      ],
      'Atención de Quejas': [
        {
          title: 'Tipo de Novedad',
          alt: 'icono Tipo de Novedad',
          icon: Icon.check_w,
          value: dataSource.incidentTypeName,
        },
        {
          title: 'Tipo de Autoridad',
          alt: 'icono Tipo de Autoridad',
          icon: Icon.check_w,
          value: dataSource.authorityTypeName,
        },
        {
          title: 'Agente',
          alt: 'icono Agente',
          icon: Icon.check_w,
          value: dataSource.agentName,
        },
        {
          title: 'Tipo de Solicitud',
          alt: 'icono Tipo de Solicitud',
          icon: Icon.check_w,
          value: dataSource.requestTypeName,
        },
        {
          title: 'Estado de la Queja',
          alt: 'icono Estado de la Queja',
          icon: Icon.check_w,
          value: dataSource.complaintStatusName,
        },
      ],
    };
  }
}
