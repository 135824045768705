<div class="bg-color-background-1 d-flex mt-2 ml-16 left-line">
  <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
  <div class="d-flex full-width py-05">
    <sit-icon
      alt="Volver"
      [icon]="Icon.arrow_backward_w"
      (click)="handleConfirmation('regresar')"
      class="text-blue-1 ml-28"
      [sizeClass]="'icon-size-24'"
    ></sit-icon>
    <h3
      class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0"
    >
      {{ titlePanel }}
    </h3>
  </div>
</div>

<div class="form-container pr-16 pl-16 mb-32 mt-32">
  <generic-form [properties]="searchFormProps"></generic-form>
  <div class="d-flex fd-row justify-end">
    <sit-button
      (click)="handleClearFilter()"
      [buttonProperties]="cancelButtonProps"
      class="width-49"
    ></sit-button>
    <sit-button
      (click)="searchFormGroup.valid ? handleSendForm() : validateFormGroup()"
      [disabled]="!searchFormGroup.valid"
      [buttonProperties]="sendButtonProps"
      class="width-49 ml-16"
    ></sit-button>
  </div>
</div>
