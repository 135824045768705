import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { Subscription } from 'rxjs';


/**
 * Directive to collapse a drawer panel
 * Bottom Sheet Directive based on aspect-ratio assuming width of 100%
 * classContainer: selector class of the container element
 */
@Directive({
  selector: '[BottomSheetAR]',
  providers: [DrawerPanelService],
})
export class BottomSheetARDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() classContainer: string | undefined;
  @Input() initialSize = 34;    // size in pixels
  @Input() invertedAspectRatio = 9/16;  // for ease of use should be inverted (16/9, 4/3, 3/2, 1/1, etc)

  elementHeight: number | undefined;
  maxWidth = 1000;
  _hidden = false;

  private collapseDrawerSubscription: Subscription;

  constructor(
    private drawerPanelService: DrawerPanelService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.collapseDrawerSubscription = this.drawerPanelService.collapseDrawer$.subscribe(this.collapseDrawer);
  }

  ngAfterViewInit(): void {
    if (this._hidden){
      const element = this.getContainerElement();
      element && this.renderer.setStyle(element, 'height', `${this.initialSize}px`);
    }
  }

  ngOnDestroy(): void {
    this.collapseDrawerSubscription.unsubscribe();
  }

  private getContainerElement(): HTMLElement | null {
    return document.querySelector(`.${this.classContainer}`);
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    if (this.maxWidth < document.documentElement.clientWidth) return;

    // Calculate the height based on the aspect ratio of 16/9 plus the height of the header
    const calculatedHeight = this._hidden ? (document.documentElement.clientWidth * this.invertedAspectRatio )+34 : this.initialSize;

    const containerEl = this.getContainerElement();
    if (containerEl) {
      this.renderer.setStyle(containerEl, 'height', `${calculatedHeight}px`);
      this.renderer.setStyle(containerEl, 'max-height', `${calculatedHeight}px`);
    }
    this._hidden = !this._hidden;
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: UIEvent) {
    const calculatedHeight = this._hidden ? ((document.documentElement.clientWidth * 9) / 16)+34 : this.initialSize;
    const media = window.matchMedia('(max-width:' + this.maxWidth + 'px)');
    !media.matches && this.renderer.setStyle(this.getContainerElement(), 'height', calculatedHeight + 'px');
  }

  collapseDrawer(collapse: boolean) {
    if (!collapse) return;

    if (this.maxWidth < document.documentElement.clientWidth) return;

    this.elementHeight = document.documentElement.clientHeight;

    if (this.elementHeight !== undefined) {
      this.renderer.setStyle(this.getContainerElement(), "height", this.initialSize);
      this.drawerPanelService.collapseDrawer(false);
    }
  }
}