export interface IStateIndicatorModel {
  label?: string;
  stateClass?: StateIndicatorsClassesEnum;
  customClass?: string;
}

export enum StateIndicatorsClassesEnum {
  active_state = 'active-state',
  inactive_state = 'inactive-state',
  pending_state = 'pending-state',
  rejected_state = 'rejected-state',
}

export const StateKeyClassMap: { [key: string]: StateIndicatorsClassesEnum } = {
  ['Activo']: StateIndicatorsClassesEnum.active_state,
  ['Inactivo']: StateIndicatorsClassesEnum.inactive_state,
  ['Pendiente']: StateIndicatorsClassesEnum.pending_state,
  ['Rechazado']: StateIndicatorsClassesEnum.rejected_state,
};
