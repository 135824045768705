import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import { IFieldCreateModel, IFieldEditModel, IFieldManagmentModel, IStateParemter, fieldStateList, fieldTypeList } from '@shared/models/field.model';
import { ILocationOption, ILocationPoint } from '@types-custom/models/business/location.model';
import { AbstractSdkDynamicFilterDataSource, DynamicFilterTypeEnum, IDynamicFilterModel, ISdkDynamicFilterModel } from '@types-custom/models/ui/dynamic-filter';
import { AbstractPaginatorDataSource, IPaginatorModel } from '@types-custom/models/ui/paginator-model';
import { StateIndicatorsClassesEnum } from '@types-custom/models/ui/state-indicator-model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldsManageService 
  implements
    AbstractPaginatorDataSource<any>,
    AbstractSdkDynamicFilterDataSource 
{

  private dataSubject = new BehaviorSubject<IFieldManagmentModel[]>([]);
  data$ = this.dataSubject.asObservable();
  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  states$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  type$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);

  dataMap: { [key: number]: any } = {
    0: (): string => `pathState`,
    3: (): string => `pathFields`,
  };

  typeFieldMap: { [key: string]: string } = {
    'text': 'Texto',
    'list': 'Selección única',
    'binary': 'Binario'
  };

  filterList: ISdkDynamicFilterModel<any, any>[] = [

    {
      key: 'name',
      type: DynamicFilterTypeEnum.TEXT,
      label: 'Buscar',
      value: new BehaviorSubject(undefined),
      searchChange: true
    },
    {
      key: 'state',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Estado',
      value: new BehaviorSubject(undefined),
      options: this.states$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'type',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Tipos de campo',
      value: new BehaviorSubject(undefined),
      options: this.type$,
      optionsMapper: undefined,
      searchChange: true
    }
  ];

  generateDynamicFIltersModel(): IDynamicFilterModel {
    const dynamicFiltersModel: IDynamicFilterModel = {
      sdkDynamicFiltersService: this.sdkDynamicFiltersService,
      dataSource: this,
      filtersDispatcher: this.selected$,
      showBttnConventions: false,
      hiddenButtonsFilters: true
    };
    return dynamicFiltersModel;
  }

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private sdkDynamicFiltersService: SdkDynamicFiltersService
  ) { }
  

  buildUrl(dataObject: IPaginatorModel): string {
    const data = dataObject.data;
    let url: string;
    if (data) {
      const type = data['type'];
      const name = data['name'];
      const state = data['state'];

      url = `${this.environment.incidentUrl}/fields/pagination/data?recordsPerPage=${
        dataObject.pageSize
      }&page=${dataObject.page}${type ? '&type=' + type : ''}${
        state ? '&state=' + state : ''
      }${name ? '&name=' + name : ''}`;
    } else {
      url = `${this.environment.incidentUrl}/fields/pagination/data?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}`;
    }
    return url;
  }

  fetchData(
    value: any,
    next: ISdkDynamicFilterModel<any, any>,
    index: number
  ): Observable<any> {
    const path = this.dataMap[index]?.();
    return this.httpClient.get(path);
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value; //TODO: review approach
    const url = this.buildUrl(pageInfo);
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.currentPage,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalRecords,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        const data: IFieldManagmentModel[] = response.records.map(
          (item: IFieldManagmentModel) => {
            item.nameType = this.typeFieldMap[item.type];

            item.stateParameter = {
              id: item.id,
              enabled: item.enabled,
              name: item.name
            }

            return item;
          }
       
        );

        this.dataSubject.next(data ?? []);
      }),
      tap((response: any) => {
        const data: any[] = this.mapDataToGrid(response.records);
        this.dataSubject.next(data ?? []);
      })
    );
  }

  private mapDataToGrid(data: any[]): any[] {
    return data.map((row) => ({
      ...row,
      stateIndicatorProperties: {
        label: row.enabled == true ? 'Activo' : 'Inactivo',
        stateClass:
          row.enabled == true
            ? StateIndicatorsClassesEnum.active_state
            : StateIndicatorsClassesEnum.inactive_state,
      },
    }));
  }

  public clearData(): void {
    this.filterList.forEach((filter) => {
      filter.value.next(undefined);
    });
  }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
    this.states$.next(fieldStateList);
    this.type$.next(fieldTypeList);
  }

  public createField(field: IFieldCreateModel): Observable<any> {
    const url = `${this.environment.incidentUrl}/fields/`;
    return this.httpClient.post<IFieldCreateModel>(url, field);
  }

  public updateField(field: IFieldEditModel): Observable<any> {
    const url = `${this.environment.incidentUrl}/fields/`;
    return this.httpClient.put<IFieldEditModel>(url, field);
  }

  public updateState(stateField: IStateParemter): Observable<any> {
    const url = `${this.environment.incidentUrl}/fields/enable`;
    return this.httpClient.put<IStateParemter>(url, stateField);
  }
  
}
