import {Component, Input, OnInit} from "@angular/core";
import {IcardConvention} from "@types-custom/models/ui/map-conventions-model";
import {Icon} from "@types-custom/models/ui/icon-model";

@Component({
  selector: "sit-general-card",
  templateUrl: "./general-card.component.html",
  styleUrls: ["./general-card.component.scss"],
})
export class GeneralCardComponent implements OnInit{
  @Input() properties :  IcardConvention[] | undefined;
  @Input() size :  string | undefined;

  ngOnInit() {

  }

  protected readonly Icon = Icon;

}
