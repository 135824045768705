import { Component, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ScrollHandlingEventsEnum } from '@types-custom/models/ui/scroll-model';
import { ISpeedometerModel } from '@types-custom/models/ui/speedometer-model';
import * as am4core from '@amcharts/amcharts4/core';
import { BehaviorSubject } from 'rxjs';
import { ICardTrafficConcordanceModel } from '@types-custom/models/ui/card-traffic-concordance-model';
import {
  adjustConcordanceRotation,
  adjustSpeedometerRotation,
} from '@ui-core/utils/functions/get-indicator-rotation';

@Component({
  selector: 'panel-traffic-concordance',
  templateUrl: './panel-traffic-concordance.component.html',
  styleUrls: ['./panel-traffic-concordance.component.scss'],
})
export class PanelTrafficConcordanceComponent implements OnInit {
  Icon = Icon;
  cards: ICardTrafficConcordanceModel[] = [];
  avSpeedIndicator = 0;
  avFlowIndicator = 0;
  listConcordance: Array<any> = [];
  panelActionsDispatcher!: BehaviorSubject<
    ScrollHandlingEventsEnum | undefined
  >;
  //avgSpeedValue: 0;
  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'MEDIA',
    unitLabel: 'KM/H',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  avgConcordanceProps: ISpeedometerModel = {
    chartType: 5,
    divId: 'avgConcordance',
    descriptionLabel: 'MEDIA',
    unitLabel: '%',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  ngOnInit(): void {
    this.setIndicators(this.cards);
    this.setGraphic(this.cards);
  }

  setIndicators(cards: ICardTrafficConcordanceModel[]) {
    let sumspeedAvg = 0;
    let totDistance = 0;
    let QtysumconcordanceAvg = 0;
    let sumconcordanceAvg = 0;

    cards.map((cards: ICardTrafficConcordanceModel) => {
      cards.speed = (+cards.speed).toFixed(2);
      if (!isNaN(+cards.speed)) {
        sumspeedAvg = sumspeedAvg + +cards.speed * (+cards.distance / 1000);
        if (+cards.speed > 0) {
          totDistance = totDistance + +cards.distance / 1000;
        }
      }
    });

    cards.map((cards: ICardTrafficConcordanceModel) => {
      if (!isNaN(+cards.concordance)) {
        QtysumconcordanceAvg += 1;
        const concordance = Math.round(+cards.concordance);
        sumconcordanceAvg = sumconcordanceAvg + concordance;
      }
    });

    this.avgSpeedProps.value = Math.round(sumspeedAvg / totDistance);
    this.avgConcordanceProps.value = Math.round(
      sumconcordanceAvg / QtysumconcordanceAvg
    );

    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
      this.avgSpeedProps.value
    );

    this.avgConcordanceProps.handClockRotation = adjustConcordanceRotation(
      this.avgConcordanceProps.value
    );
  }

  setGraphic(cards: ICardTrafficConcordanceModel[]) {
    const colorRange = [
      { min: -100, max: -51, color: am4core.color('#FC0606') },
      { min: -50, max: -41, color: am4core.color('#F11100') },
      { min: -40, max: -31, color: am4core.color('#F12B24') },
      { min: -30, max: -21, color: am4core.color('#F05855') },
      { min: -20, max: -11, color: am4core.color('#F37B7A') },
      { min: -10, max: 10, color: am4core.color('#0372D9') },
      { min: 11, max: 20, color: am4core.color('#75993F') },
      { min: 21, max: 30, color: am4core.color('#84AF3D') },
      { min: 31, max: 40, color: am4core.color('#99C73B') },
      { min: 41, max: 50, color: am4core.color('#AADE12') },
      {
        min: 51,
        max: Number.MAX_SAFE_INTEGER,
        color: am4core.color('#70EC00'),
      },
    ];
    const lookup = {} as any;
    this.listConcordance = [];

    cards.forEach((card) => {
      const concordanceOpt = Math.round(+card.concordance);
      const concordance =
        Math.floor(
          +(concordanceOpt > 100 ? 100 - concordanceOpt : concordanceOpt) / 10
        ) * 10;

      const colorObject = colorRange.find((range) => {
        return concordance >= range.min && concordance <= range.max;
      });

      const color = colorObject ? colorObject.color : am4core.color('#FC0606');

      if (concordance in lookup) {
        lookup[concordance].qty++;
      } else {
        lookup[concordance] = {
          id: concordance,
          color,
          label: concordance,
          qty: 1,
        };
        this.listConcordance.push(lookup[concordance]);
      }
    });
  }
}
