<div class="card-footer">
    <label class="card-tit">
      <sit-icon
      alt="icon calendario"
      [icon]="Icon.calendar_w"
      [sizeClass]="'icon-size-24'"
      class="icon-footer"
      >
      </sit-icon>
        </label>
    <span class="text-white"> <strong>{{ properties?.creationDate | date: 'dd/MM/yyy' }} </strong> </span>
  </div><br>

  <div class="card-footer">
    <label class="card-tit">
      <sit-icon
      alt="icon grupoguia"
      [icon]="Icon.GrupoGuia"
      [sizeClass]="'icon-size-24'"
      class="icon-footer"
      >
      </sit-icon>
        </label>
        <span class="text-white"><strong> {{ properties?.humanResourceId }}</strong></span> 
        <span class="text-white"><strong> {{ properties?.humanResourceNames}} {{ properties?.humanResourceLastNames}}</strong></span>
      
    <div class="text-modal">
        <div class="text-cont">
                     <p class="text-white">
          El recurso {{ properties?.humanResourceNames }} {{ properties?.humanResourceLastNames}} informa que en el sistema no se registra asignación vehículo para el desarrollo de sus actividades, por lo que generó la novedad con la siguiente informacion:
        </p>
        </div>
      </div><br>

      <div class="card-footer">
        <label class="card-tit">
        </label>
        <span class="text-white"><strong> {{ properties?.physicalResourceId }} {{ properties?.physicalResourceTypeName}}</strong></span> 

        <p class="text-white"> El superior del recurso en campo, autorizó por medio de clave el uso del vehículo, y la realización del preoperacional correspondiente.</p>

  </div>