<!-- <ng-template> -->
    <div style="width:550px">
        <mat-tab-group class="text-size-16 text-white text-weight-500 mb-25 text-md-size-16 test-label custom-tab-group">
            <mat-tab label="Actividades">
                <div class="d-flex full-width">
                    <mat-tab-group >
                        <mat-tab label="Programadas" >
                            <div class="custom-scrollbar" style="overflow-y: scroll; max-height:500px; width: 550px;">
                                <div class="p-20-px" *ngFor="let item of listScheduledActivities">
                                    <div class="d-flex justify-start align-items-center mb-8">
                                        <sit-icon
                                        [icon]="Icon.calendar_outlined_white"
                                        [sizeClass]="'icon-size-28'">
                                        </sit-icon>
                                        <span class="ml-8">{{item.beginDate | date:'dd/MM/YYYY'}}</span>
                                    </div>
                                    <div class="card-container">
                                        <span class="mb-12"> {{item.objective}}</span>
                                        <span class="mb-12 text-blue"> {{item.corridorName}}</span>
                                        <div class="container-insed-card mb-12">
                                            <span class="ml-8">{{item.spot}}</span>
                                        </div>
                                        <div class="d-flex justify-start align-items-center">
                                            <sit-icon
                                            [icon]="Icon.time_blue"
                                            [sizeClass]="'icon-size-28'">
                                            </sit-icon>
                                            <span class="ml-8">{{item.beginDate | date:'hh:mm'}} ————— {{item.endDate | date:'hh:mm'}}</span>
                                            <span class="ml-8 index">+1</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="No programadas">
                            <div class="custom-scrollbar" style="overflow-y: scroll; max-height:500px; width: 550px;">
                                <div class="p-20-px" *ngFor="let item of listUnScheduledActivities">
                                    <div class="d-flex justify-start align-items-center mb-8">
                                        <sit-icon
                                        [icon]="Icon.calendar_outlined_white"
                                        [sizeClass]="'icon-size-28'">
                                        </sit-icon>
                                        <span class="ml-8"> {{item.incidentTime | date:'dd/MM/YYYY'}}</span>
                                        <sit-icon
                                        class="ml-32"
                                        [icon]="Icon.time"
                                        [sizeClass]="'icon-size-28'">
                                        </sit-icon>
                                        <span class="ml-8">{{item.incidentTime | date:'hh:mm a'}}</span>
                                    </div>
                                    <div class="card-container">
                                        <div class="d-flex fd-row justify-space-between">
                                            <span class="mb-12 text-blue"> {{item.address}} </span>
                                            <span >{{item.statusName}}</span>
                                        </div>
                                        <div class="d-flex fd-row">
                                            <div class="insed-card-black mb-12">
                                                <span >{{item.className}}</span>
                                                <span class="text-button-clean-filter">Clase</span>
                                            </div>
                                            <div class="insed-card-black mb-12 ml-16">
                                                <span>{{item.typeName}}</span>
                                                <span class="text-button-clean-filter">Tipo</span>
                                            </div>
                                            <div class="insed-card-black mb-12 ml-16">
                                                <span>{{item.gravityName}}</span>
                                                <span class="text-button-clean-filter">Prioridad</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-tab>
            <mat-tab label="Incidentes">
                   <div class="custom-scrollbar container-incidents" style="overflow-y: scroll; max-height:500px; width: 550px;">

                    <div class="incidents-container p-20-px" *ngFor="let item of listIncidentsWithoutAssign">
                        <div class="insed-card-incident custom-scrollbar mb-12" style="overflow-y: scroll;">
                            <div class="d-flex fd-row justify-space-between">
                                <span class="mb-12 text-blue"> {{item.address}} </span>
                                <sit-icon
                                [icon]="Icon.add_w"
                                [sizeClass]="'icon-size-28'"
                                (click)="AssignIncident(item.id)">
                                </sit-icon>
                            </div>
                            <div class="line"></div>
                            <span class="mt-4">{{item.className}}</span>
                            <span >{{item.typeName}}</span>
                            <div class="d-flex fd-row justify-space-between">
                                <span>{{item.priorityName}}</span>
                                <span class="text-button-clean-filter">{{item.statusName}}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <!-- </ng-template> -->
    