import { Component, Input, OnInit } from '@angular/core';
import {
  IRoleModelCheckBox,
  IUserEditModel,
  IUserManagmentModel,
  userStateList,
} from '@shared/models/user.model';
import { ILocationOption } from '@types-custom/models/business/location.model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IClassesAttributes,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { atLeastOneRoleValidator, validateEquals } from './register.validator';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccesUserComponent } from '@ui-core/components/modal-succes-user/modal-succes-user.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ManagePhysicalResourcesService } from '@official/services/data-grid-service/manage-physical-resource-service/manage-physical-resources.service';
import { CommonService } from '@shared/services/common-service/common-service.service';

@Component({
  selector: "edit-physical-resource",
  templateUrl: "./edit-physical-resource.component.html",
  styleUrls: ["./edit-physical-resource.component.scss"],
})
export class EditPhysicalResourceComponent implements OnInit {
  Icon = Icon;
  buttonSaveAttributes: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_resources,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonEditAttributes: IButtonModel = {
    label: 'EDITAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_create,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonCancelAttributes: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_cancel,
      spanClass: 'text-weight-600 text-size-16'
    },
  };
  dropdownAttributes: IClassesAttributes = {
    iconClass: IconClassesEnum.blue_icon,
  };
  states = new BehaviorSubject<ILocationOption[] | undefined>(userStateList);
  statesSelection = new BehaviorSubject<string | undefined>(undefined);
  @Input()
  @Input()
  roles: any[] = [];

  @Input()
  physicalResourceEdited: any = {};
  @Input()
  agenciesList: ILocationOption[] = [];
  registerFormGroup: UntypedFormGroup;
  fb = new UntypedFormBuilder();
  showPass = false;
  showPassConfirm = false;
  passwordEqual = true;

  rolesList: any[] = [];

  formationType: any = [];
  bussines: any = [];
  typeResources: any = [];
  conditions: any = [];

  public progress_1: number = 0;
  public progress_2: number = 0;
  public reader = new FileReader();

  documentsCreated: any[] = [];

  files: any;
  filesEdit: any = [];

  constructor(
      private physicalResourceService: ManagePhysicalResourcesService,
      private modalService: ModalService,
      public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    // this.rolesList = this.roles.map((e) => e.name);
    this.setList();
    this.initializeForm();
    this.setDataForm();
  }

  setList(){
    this.commonService.getListPhysicalResources('AreaNegocio').subscribe((res: any) => {
        this.bussines = res;
    });

    this.commonService.getListPhysicalResources('TipoRecurso').subscribe((res: any) => {
      this.typeResources = res;
    });

    this.commonService.getListPhysicalResources('Condicion').subscribe((res: any) => {
      this.conditions = res;
    });

  }

  compareFn(c1: number, c2: number): boolean {
    return c1 && c2 && c1 == c2;
  }

  loadFiles(file:any){
    this.files = file;
  }

  setDataForm() {
    if (this.physicalResourceEdited && this.physicalResourceEdited.id) {
      this.registerFormGroup.patchValue({
        businessAreaId: this.physicalResourceEdited.businessAreaId,
        code: this.physicalResourceEdited.code,
        resourceTypeId: this.physicalResourceEdited.resourceTypeId,
        feature: this.physicalResourceEdited.feature,
        conditionId: this.physicalResourceEdited.conditionId,
        observations: this.physicalResourceEdited.observations
      });


      this.filesEdit = this.physicalResourceEdited.files;
    }
  }

  private initializeForm(): void {
    const formControls: { [key: string]: any } = {
      businessAreaId: [
        { value: '', disabled: false },
        [
          Validators.required,
        ],
      ],
      code: [
        { value: '', disabled: false },
        [
          Validators.pattern(
            /^[A-Za-z0-9]*$/
          ),
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.required,
        ],
      ],
      resourceTypeId: [
        { value: '', disabled: false },
        [
          Validators.required,
        ],
      ],
      feature: [
        { value: '', disabled: false },
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200),
        ],
      ],
      conditionId: [
        { value: '', disabled: false },
        [
          Validators.required,
        ],
      ],
      observations: [
        { value: '', disabled: false },
        [
          Validators.minLength(3),
          Validators.maxLength(500),
        ],
      ],
    };

    // this.rolesList.forEach((item: string) => {
    //   formControls[item] = [''];
    // });

    this.registerFormGroup = this.fb.group(formControls);

  }

  get phoneErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
        'phone'
    ) as FormControl;
    return form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 15'
        : form.hasError('pattern')
            ? 'Este campo sólo acepta números enteros'
            : '';
  }

  get businessErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('businessAreaId') as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio': '';
  }

  get codeErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('code') as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio'
        :  form.hasError('minlength')
        ? 'Este campo debe tener una longitud mínima de 3'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 100'
            : form.hasError('pattern')
                ? 'Caracter no permitido'
                : '';
  }

  get typeResourceErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('resourceTypeId') as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio': '';
  }

  get featureErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('feature') as FormControl;
    return form.hasError('required')
    ? 'Campo obligatorio' 
        : form.hasError('minlength')
        ? 'Este campo debe tener una longitud mínima de 3'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 200'
                : '';
  }

  get conditionErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('conditionId') as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio': '';
  }

  get observationsErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('observations') as FormControl;
    return form.hasError('minlength')
        ? 'Este campo debe tener una longitud mínima de 3'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 500'
                : '';
  }
  

  get lastNameErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
        'lastName'
    ) as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 60'
            : form.hasError('pattern')
                ? 'Caracter no permitido'
                : '';
  }

  get emailErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
        'email'
    ) as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 80'
            : form.hasError('minlength')
                ? 'Longitud mínima requerida (10)'
                : form.hasError('pattern')
                    ? 'Correo no valido'
                    : '';
  }

  get passwordErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
        'password'
    ) as FormControl;
    return form.hasError('required')
        ? 'Campo obligatorio'
        : form.hasError('maxlength')
            ? 'Este campo debe tener una longitud máxima de 60'
            : form.hasError('minlength')
                ? 'Longitud mínima requerida (8)'
                : form.hasError('pattern')
                    ? 'Contraseña no válida'
                    : '';
  }
  get rolesSelected() {
    return this.roles.filter((value) => {
      return value.isChecked;
    });
  }

  private handlingError(error: any): void {
    console.error({ error });
  }

  private getRolesSelected(): string[] {
    const rolesSelected: string[] = [];

    this.rolesList.forEach((e) => {
      if (this.registerFormGroup.get(e).value) {
        rolesSelected.push(e);
      }
    });
    return rolesSelected;
  }

  validateUserSubmit(): void {

    this.onSubmit();
  }
  onSubmit(): void {
    if (!this.physicalResourceEdited.id) {
      const registerBody = {
        businessAreaId: this.registerFormGroup.value.businessAreaId,
        code: this.registerFormGroup.value.code,
        resourceTypeId: this.registerFormGroup.value.resourceTypeId,
        feature: this.registerFormGroup.value.feature,
        conditionId: this.registerFormGroup.value.conditionId,
        observations: this.registerFormGroup.value.observations
      };

      var form = new FormData();
      form.append("businessAreaId", registerBody.businessAreaId);
      form.append("code", registerBody.code);
      form.append("resourceTypeId", registerBody.resourceTypeId);
      form.append("feature", registerBody.feature);
      form.append("conditionId", registerBody.conditionId);
      form.append("observations", registerBody.observations);

      if(this.files?.length > 0){
        this.files.forEach((item:any,index:any) => {
          form.append(`files[${index}].file`, item);
          form.append(`files[${index}].name`, 'image_'+index);
        });
      }

      this.physicalResourceService.createPhysicalResource(form).subscribe({
        next: (response: any) => {
          this.modalService.cleanModals$.next([]);
          setTimeout(() => {
            this.showModalSuccess('El recurso fue creado exitosamente');
            this.physicalResourceService.init();
            this.init();
          }, 200);
        },
          error: (error: any) => this.showModalError(error.error.errors[0]),
      });
    } else {
      const registerBody = {
        businessAreaId: this.registerFormGroup.value.businessAreaId,
        code: this.registerFormGroup.value.code,
        resourceTypeId: this.registerFormGroup.value.resourceTypeId,
        feature: this.registerFormGroup.value.feature,
        conditionId: this.registerFormGroup.value.conditionId,
        observations: this.registerFormGroup.value.observations
      };

      var form = new FormData();
      form.append("id", this.physicalResourceEdited.id);
      form.append("businessAreaId", registerBody.businessAreaId);
      form.append("code", registerBody.code);
      form.append("resourceTypeId", registerBody.resourceTypeId);
      form.append("feature", registerBody.feature);
      form.append("conditionId", registerBody.conditionId);
      form.append("observations", registerBody.observations);
      form.append("stateId", this.physicalResourceEdited.stateId);
      form.append("assignationTypeId", this.physicalResourceEdited.assignationTypeId);

      if(this.files?.length > 0){
        this.files = this.files.filter((item:any)=> item.size);
        this.files.forEach((item:any,index:any) => {
          form.append(`files[${index}].file`, item);
          form.append(`files[${index}].name`, 'image_'+index);
        });
      }

      this.physicalResourceService.updatePhysicalResource(form).subscribe({
        next: (response: any) => {
          this.modalService.cleanModals$.next([]);
          setTimeout(() => {
            this.showModalSuccess('El recurso se ha editado con éxito');
            this.physicalResourceService.init();
            this.init();
          }, 200);
        },
        error: (error: any) => this.handlingError(error),
      });
    }
  }

  onCancel(): void {
      this.modalService.cleanModals$.next([]);
  }

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }
  showModalError(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-1',
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
  }

  checkPassword() {
    this.registerFormGroup.controls.confirmPassword.updateValueAndValidity();
  }

  get compareEquals(): boolean {
    return (
        !this.registerFormGroup.controls.confirmPassword.hasError('required') &&
        this.registerFormGroup.get('confirmPassword').hasError('noEquals') &&
        this.registerFormGroup.get('password').dirty &&
        this.registerFormGroup.get('confirmPassword').dirty
    );
  }

  modalCanelar(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccesUserComponent,
        data: {
          infoMessage: 'Está seguro que desea cancelar ?',
          icon: Icon.questions_w,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: () => {
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        this.modalService.closeConfirmationModal();
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }

  validateFormGroup(): void {
    this.registerFormGroup.markAllAsTouched();
  }

  loadFile (event: any) {
    const files: FileList = event.target.files;
    const idInput = event.target.id;
    let name = '';
    if (idInput == 'upload_support') {
       name = 'supportSpan';
    }
    if (idInput == 'upload_category') {
       name = 'categorySpan';
    }

    const percent = document.getElementById(name);
    percent != null ? (percent.innerHTML = '0%') : null;

    const tab = event.target.id == 'upload_support' ? 0 : 1;
    let userId = '';

    if (localStorage.getItem('id')) {
       userId = localStorage.getItem('id')!;
    }

    Array.from(files).forEach((file: any) => {

       this.reader = new FileReader();
       this.reader.readAsArrayBuffer(file);
       this.reader.addEventListener('progress', (event2: any) => {
          const totalPercent = (event2.loaded / event2.total) * 100;
          percent != null ? (percent.innerHTML = `${Math.round(totalPercent)}%`) : null;

          if (name === 'supportSpan') {
             this.progress_1 = totalPercent;
          }
          if (name === 'categorySpan') {
             this.progress_2 = totalPercent;
          }
       });

       const setId = this.documentsCreated.length + 1;
       const date = new Date().toLocaleString();
       const resource: any = {
          name: file.name,
          url: '',
          external: false,
          file
       };

       const document = {
          name: file.name,
          enable: true,
          id: setId,
          date,
          resource,
          categoryId: 0,
          userId,
          isNew: true,
          resourceId: 0,
          isCategorized: tab == 0 ? false : true
       };

       this.documentsCreated.push(document);
      //  this.splitDocuments();

       setTimeout(() => {
          this.progress_1 = 0;
          percent != null ? (percent.innerHTML = '0%') : null;
       }, 1000);
    });

    event.target.value = '';
 }

 compareCategoryObjects(object1: any, object2: any) {
  return object1 && object2 && object1.id == object2.id;
}
}
