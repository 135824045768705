export const vehiclesJsonMockData = [
  {
      "siteid": 1,
      "address": "Autopista norte x calle 192",
      "location": "Usaquen",
      "name": "Autopista norte - calle 192",
      "latitude": 4.770023463553006,
      "longitude": -74.04310494754579
  },
  {
      "siteid": 5,
      "address": "Avenida calle 26 x carrera 68D",
      "location": "Fontibon",
      "name": "Avenida calle 26 - carrera 68D El Tiempo",
      "latitude": 4.65692575907078,
      "longitude": -74.10577636645996
  },
  {
      "siteid": 9,
      "address": "Calle 17 x Avenida ciudad de Cali",
      "location": "Fontibon",
      "name": "Calle 17 - Avenida ciudad de Cali",
      "latitude": 4.660698908898577,
      "longitude": -74.13713895417483
  },
  {
      "siteid": 10,
      "address": "Calle 127 x Autopista Norte",
      "location": "Usaquen",
      "name": "Calle 127 - Autopista Norte",
      "latitude": 4.706307034307195,
      "longitude": -74.05221430732993
  },
  {
      "siteid": 12,
      "address": "Avenida 1 de Mayo x carrera 8A",
      "location": "Antonio Nariño",
      "name": "Avenida 1 de Mayo x carrera 8A",
      "latitude": 4.575284343597611,
      "longitude": -74.09403962793074
  },
  {
      "siteid": 19,
      "address": "Transversal 5 x calle 89 Vía la Calera",
      "location": "Chapinero",
      "name": "Transversal 5 vía la Calera",
      "latitude": 4.6697194096941415,
      "longitude": -74.0436237422541
  },
  {
      "siteid": 20,
      "address": "Carrera 7 x calle 193",
      "location": "Usaquen",
      "name": "Carrera 7 - calle 193",
      "latitude": 4.769791896718204,
      "longitude": -74.0270703187098
  },
  {
      "siteid": 2,
      "address": "Autopista norte x calle 127",
      "location": "Usaquen",
      "name": "Autopista norte - calle 127 carril lento",
      "latitude": 4.705750042969995,
      "longitude": -74.05421466102668
  },
  {
      "siteid": 4,
      "address": "Autopista norte x calle 127",
      "location": "Usaquen",
      "name": "Autopista norte - calle 127 carril rápido",
      "latitude": 4.708101272493014,
      "longitude": -74.05380087534238
  },
  {
      "siteid": 3,
      "address": "Autopista sur x calle 63 sur",
      "location": "Ciudad Bolívar",
      "name": "Autopista Sur - Calle 63 Sur",
      "latitude": 4.5968173890390265,
      "longitude": -74.17937895673965
  },
  {
      "siteid": 13,
      "address": "Calle 72 x Carrera 84Bis",
      "location": "Engativa",
      "name": "Calle 72 - Carrera 84Bis",
      "latitude": 4.695081596933032,
      "longitude": -74.1049477689739
  },
  {
      "siteid": 14,
      "address": "Calle 80 x Carrera 119",
      "location": "Engativa",
      "name": "Calle 80 - Carrera 119",
      "latitude": 4.726047727144343,
      "longitude": -74.12463008802183
  },
  {
      "siteid": 16,
      "address": "Carrera 10 x calle 19 sur",
      "location": "Antonio Nariño",
      "name": "Carrera 10 - calle 19 sur",
      "latitude": 4.576647401531473,
      "longitude": -74.0940804008015
  },
  {
      "siteid": 18,
      "address": "Avenida carrera 86 x calle 72A",
      "location": "Engativa",
      "name": "Avenida carrera 86 - calle 72A",
      "latitude": 4.69669101032489,
      "longitude": -74.10543858987388
  },
  {
      "siteid": 17,
      "address": "Avenida carrera 68 x calle 8",
      "location": "Puente Aranda",
      "name": "Avenida carrera 68 - calle 8",
      "latitude": 4.630071909500608,
      "longitude": -74.12203213111786
  },
  {
      "siteid": 11,
      "address": "Calle 17 x Río Bogotá",
      "location": "Fontibon",
      "name": "Calle 17 - Río Bogotá",
      "latitude": 4.696123857262143,
      "longitude": -74.17178718352987
  },
  {
      "siteid": 8,
      "address": "Calle 13 x Avenida Boyaca",
      "location": "Fontibon",
      "name": "Calle 13 - Avenida Boyacá",
      "latitude": 4.6471276989381165,
      "longitude": -74.12512159858133
  },
  {
      "siteid": 7,
      "address": "Avenida Boyaca x calle 13",
      "location": "Fontibon",
      "name": "Avenida Boyacá - calle 13",
      "latitude": 4.650613792740708,
      "longitude": -74.12523961578431
  },
  {
      "siteid": 15,
      "address": "Avenida de las Américas x carrera 68D",
      "location": "Kennedy",
      "name": "Calle 9 - Avenida de las Américas",
      "latitude": 4.629289339799259,
      "longitude": -74.12468304992595
  },
  {
      "siteid": 6,
      "address": "Avenida ciudad de Cali x calle 43 sur",
      "location": "Kennedy",
      "name": "Avenida ciudad de Cali - calle 43 sur",
      "latitude": 4.628545141275203,
      "longitude": -74.17088873220196
  }
]
