import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ModalNoticeService {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  getTextNoticePrivacy() {
    return this.httpClient.get(`${this.environment.authUrl}/auth/getterms`);
  }

  getTextNoticeTreatment() {
    return this.httpClient.get(`${this.environment.authUrl}/auth/getterms`);
  }
}
