import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'state-toogle',
  templateUrl: './state-toogle.component.html',
  styleUrls: ['./state-toogle.component.scss'],
})
export class StateToogleComponent implements OnInit {
  @Input() isActive: string | undefined;
  @Input() formControlInstance: AbstractControl | undefined;

  sizeClass = 'icon-size-24';

  ngOnInit(): void {
    this.formControlInstance?.patchValue(false, { emitEvent: false });
    this.controlInstanceChangesSubscriptions();
  }

  handleToogleAction() {
    if (this.isActive && this.formControlInstance) {
      this.isActive = undefined;
      this.formControlInstance?.patchValue(false, { emitEvent: false });
    } else {
      this.isActive = 'true';
      this.formControlInstance?.patchValue(true, { emitEvent: false });
    }
  }

  controlInstanceChangesSubscriptions() {
    this.formControlInstance?.valueChanges.subscribe((isActiveFromEdit) => {
      this.isActive = isActiveFromEdit === 'true' ? 'true' : undefined;
    });
  }
}
