<div class="contenedor">
  <div
    *ngFor="let indicator of indicators | async"
    class="indicators-container"
  >
    <div class="indicator-icon">
      <sit-icon class="d-flex"
        [icon]="indicator.icon"
        [sizeClass]="'icon-size-32'"
        alt="{{ indicator.tooltipTag }}"
        [tooltip]="indicator.tooltipTag"
      >
      </sit-icon>
    </div>
    <div class="indicator pl-05">
      <span>{{ indicator.data }}</span>
    </div>
  </div>
</div>
