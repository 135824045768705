import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardPmwModel } from '@types-custom/models/ui/card-pmv-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { MapboxDataService } from '@sdk/services/map-box-data/mapbox-data.service';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { PmvConsultaService } from 'libs/shared/src/lib/services/layer-service/pmv-service/pmvconsulta.service';
import { StatusforcameraipDTO } from '@shared/models/pmv/DTO/statusforcameraipDTO.model';
import {
  GridPageActions,
  IGridPageModel,
} from '@types-custom/models/ui/grid-page-model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { ExportExcelService } from '@official/services/data-grid-service/export-excel-service/export-excel.service';
import {
  gridTableheadersMap,
  GridTypeEnum,
} from '@official/utils/mappers/grid-mapper';
import { IGridTableCellDispatcher } from '@types-custom/models/ui/grid-table-model';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { ModalService } from '@ui-core/services/modal/modal.service';
import {
  NameManagePanelMap,
  TextManagePanelMap,
} from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';

@Component({
  selector: 'citizen-panel-consulta-pmv',
  templateUrl: './panel-consulta-pmv.component.html',
  styleUrls: ['./panel-consulta-pmv.component.scss'],
})
export class PanelConsultaPmvComponent implements OnInit, OnDestroy {
  Icon = Icon;
  cards: ICardPmwModel[] = [];
  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];
  totalPwm = 0;

  subscription = new Subscription();
  dataStatusforcamera!: StatusforcameraipDTO;
  datacameraSelect!: any;
  showdata = false;

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }
  panelAction$ = new BehaviorSubject(this.panelManageActionsEnum.GRID);

  gridColumnActions = new Subject<IGridTableCellDispatcher>();
  gridPageProps: IGridPageModel;
  gridRowData: any;
  textPanel = '';

  servicePanelDataSource: AbstractPanelManagementDataSource;
  layerName = GridTypeEnum.PMVconsulta as GridTypeEnum;
  isAllowDownload = false;

  @Input() serviceDataSource: any;

  constructor(
    private exportExcelService: ExportExcelService,
    private drawerPanelService: DrawerPanelService,
    private mapboxDataService: MapboxDataService,
    private modalService: ModalService,
    private pmvService: PmvConsultaService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.showdata = false;
  }

  ngOnInit(): void {
    this.showdata = false;
    this.totalPwm = this.cards.length ?? 0;
    this.datacameraSelect = this.cards[0];
    // this.pmvService.setIp(this.cards[0].ip);
    this.initializePanel();
    this.setdataStatusForCamera(this.cards[0].ip);
  }

  private initializePanel(): void {
    this.textPanel = TextManagePanelMap[MapEventTypeEnum.PMV];
    this.servicePanelDataSource = this
      .pmvService as unknown as AbstractPanelManagementDataSource;
    this.gridPageProps = {
      headers: gridTableheadersMap([this.gridColumnActions])[this.layerName],
      dataSource: this.servicePanelDataSource,
      isAllowDownload: this.isAllowDownload,
    };

    this.servicePanelDataSource.init();

    this.gridColumnActions.subscribe((columnAction) =>
      this.handleColumnAction(columnAction)
    );
  }

  async setdataStatusForCamera(data: any) {
    this.pmvService.setIp(data);
    [this.dataStatusforcamera] = await Promise.all([
      lastValueFrom(this.pmvService.getStatusforcameraip(data)),
    ]);
    this.initializePanel();
  }

  handleDownloadGridAction(fileType: GridPageActions): void {
    this.pmvService.ExpIp2 = this.cards[0].ip;
    const excelUrl = this.servicePanelDataSource.buildExcelPdfUrl(
      fileType as unknown as string
    );
    this.exportExcelService.downloadFile(
      excelUrl,
      this.layerName,
      fileType as unknown as string
    );
  }

  handleColumnAction(columnAction: IGridTableCellDispatcher) {
    this.gridRowData = columnAction.row;
    this.onPanelManageAction(this.panelManageActionsEnum.EDIT);
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  cleanModals() {
    this.modalService.cleanModals$.next([]);
  }
}
