/**
 * Determines the "Levenshtein distance" between two words using dp
 * it takes into account insertion, deletion and substitution
 * @param str1  first word to compare
 * @param str2  second word to compare
 * @returns the distance between <strong>str1</strong> and <strong>str2</strong> 
 */
export function word_distance(str1: string, str2: string){
    let lenS1 = str1.length, lenS2 = str2.length;
    if (lenS1 > lenS2) {
        [str1, str2] = [str2, str1];
        [lenS1, lenS2] = [lenS2, lenS1];
    }

    let currentRow = Array.from({length: lenS1 + 1}, (_, i) => i);
    for (let i = 1; i <= lenS2; i++) {
        let previousRow = currentRow;
        currentRow = [i].concat(Array(lenS1).fill(0));
        for (let j = 1; j <= lenS1; j++) {
            let add = previousRow[j] + 1;
            let del = currentRow[j - 1] + 1;
            let change = previousRow[j - 1];
            if (str1[j - 1] !== str2[i - 1]) {
                change += 1;
            }
            currentRow[j] = Math.min(add, del, change);
        }
    }

    return currentRow[lenS1];
}