<div class="card-header full-width d-flex">
  <div
    class="bg-color-complementary-2 align-items-center justify-center d-flex width-50 full-sm-width date-container pl-1"
  >
    <sit-icon
      [icon]="Icon.calendar_w"
      alt="icono indicador calendario"
      class="mt-05 mb-05"
      [sizeClass]="'icon-size-20'"
    ></sit-icon>
    <span class="text-size-16 text-white pl-1 text-res"
      >Fecha del Suceso:
      {{ dataSource.incidentDate | date: 'YYYY/MM/dd - HH:mm' }}</span
    >
  </div>
  <div
    class="bg-color-complementary-2 align-items-center justify-center d-flex width-50 full-sm-width pl-1"
  >
    <sit-icon
      [icon]="Icon.calendar_w"
      alt="icono indicador calendario"
      class="mt-05 mb-05"
      [sizeClass]="'icon-size-20'"
    ></sit-icon>
    <span class="text-size-16 text-white pl-1 text-res"
      >Fecha de Creación:
      {{ dataSource.creationTime | date: 'YYYY/MM/dd - HH:mm' }}</span
    >
  </div>
</div>
<div class="grid-container text-white text-weight-600">
  <div
    class="border"
    *ngFor="let item of incidentsMapper[dataSource.incidentTypeName]"
  >
    <div class="card">
      <span class="text-size-16 text-res">{{ item.title }}</span>
      <sit-icon
        [icon]="item.icon"
        [alt]="item.alt"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-64 icon-res-mod'"
      ></sit-icon>
      <span class="text-size-16 text-res">{{ item.value | uppercase }}</span>
    </div>
  </div>
</div>
