import { AfterViewInit, Component, Input } from '@angular/core';
import { IGridTableCellDispatcher } from '@types-custom/models/ui/grid-table-model';
import { Icon, IconBaseComponent } from '@types-custom/models/ui/icon-model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'sit-card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.scss'],
})
export class CardMenuComponent implements AfterViewInit {
  @Input() sizeClass = 'icon-size-24';
  @Input() tooltip = '';
  @Input() options: any[] = [];
  @Input() props: any = new Object();

  icon = Icon;

  ngAfterViewInit(): void {
  }


  handleClick(
    dispatcher: Subject<IGridTableCellDispatcher> | undefined,
    row: any,
    key: string | undefined,
    index: number
  ): void {
    if (dispatcher) {
      const data = {
        row,
        index,
        key,
      };

      dispatcher.next(data);
    }
  }

}
