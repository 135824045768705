import { Component, Input, OnInit } from "@angular/core";
import { IModalAgentsLocationModel } from '@types-custom/models/ui/modal-agents-location-model';
import { Icon } from "@types-custom/models/ui/icon-model";
import { ITimeLineGraphicModel } from "@types-custom/models/ui/chart-line-color-model";
import { AgentsService } from "@shared/services/layer-service/agents-service/agents.service";

@Component({
  selector: "modal-agents-location",
  templateUrl: "./modal-agents-locations.component.html",
  styleUrls: ["./modal-agents-locations.component.scss"],
})
export class ModalAgentsLocationsComponent implements OnInit{
  @Input() properties: IModalAgentsLocationModel | undefined;
  Icon = Icon;

  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];

  constructor(private serviceDataSource: AgentsService) {}

  ngOnInit(): void {
    //console.log(this.properties);

    this.serviceDataSource.getGraphicByLocality(this.properties.LocNombre).subscribe((res: any) => {
      this.setGraphicAgents(res);
    });
  }

  setGraphicAgents(data: any): void {
    const sortedData = data
    .map((e: any) => {
      return { time: ('0' + e.date).slice(12,17), value: e.count };
    }).sort((a: any, b: any) => ('0' + a.date).slice(12,17).localeCompare(('0' + b.date).slice(12,17)));

    this.dataGraphicList = {
      data: sortedData,
      label: 'agentes',
      labelX: 'Horas',
      labelY: 'Agentes activos',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#FF9901' }];
  }
}
