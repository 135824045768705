import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { editFormModelMap } from '@official/pages/map-viewer/models/managament-panel/edit-panel.model';
import { nameManagePanel } from '@official/pages/map-viewer/models/managament-panel/name-panel';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import {
  IGenericFormModel,
  ParametricFormI,
} from '@types-custom/models/ui/generic-form.model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { GeoLocationService } from '@shared/services/geo-location/geo-location.service';
import { MessageEditSuccess } from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { remapObjectToOneLevel } from '@ui-core/utils/functions/remap-object';

@Component({
  selector: 'edit-manage-panel',
  templateUrl: './edit-manage-panel.component.html',
  styleUrls: ['./edit-manage-panel.component.scss'],
})
export class EditManagePanelComponent implements OnInit, AfterViewInit {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  @Input() gridRowData: any;
  @Input() properties: any;

  actionDataForm$ = new Subject<any>();

  Icon = Icon;
  titlePanel = '';
  messageSuccess = '';
  fb = new UntypedFormBuilder();
  editFormGroup = this.fb.group({});
  editFormProps: IGenericFormModel;

  constructor(
    private geoLocationService: GeoLocationService,
    private modalService: ModalService
  ) { }

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  sendButtonProps: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  ngOnInit(): void {
    this.titlePanel = nameManagePanel[this.panelOnManagement];
    this.messageSuccess = MessageEditSuccess[this.panelOnManagement];
    this.initGenericFormProps();
  }

  ngAfterViewInit(): void {
    this.serviceDataSource
      .getIndividualData(this.gridRowData)
      .subscribe(this.setFormData.bind(this));
  }

  initGenericFormProps() {
    const editFormModel = editFormModelMap[this.panelOnManagement];

    this.geoLocationService.clickEventsDispatcher =
      this.properties.clickEventsDispatcher;
    this.geoLocationService.clickInteractionDispatcher =
      this.properties.clickInteractionDispatcher;

    this.editFormProps = {
      formGroup: this.editFormGroup,
      formModel: [...(editFormModel as ParametricFormI[][])],
      serviceDataSource: this.serviceDataSource,
      geolocationDataSource: this.geoLocationService,
      actionDataForm: this.actionDataForm$,
      actionPanel$: this.panelAction$,
      panelOnManagement: this.panelOnManagement,
    };
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  handleSendForm() {
    const dataForm = remapObjectToOneLevel(
      this.editFormGroup.getRawValue()
    );
    this.serviceDataSource.submitEditForm(dataForm).subscribe({
      next: () => this.handleSuccess(),
      error: () => this.handleError(),
    });
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleSuccess(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: this.messageSuccess },
      },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
    this.serviceDataSource.init();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#FF5B5B',
    };

    this.modalService.confirmationModal(modal);
  }

  handleConfirmation(message?: string): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: {
          infoMessage: `¿Está seguro que desea ${message ?? 'cancelar'}?`,
        },
      },
      headerBackgroundClass: 'bg-color-background-2',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => { },
    };
    this.modalService.confirmationModal(modal);
  }

  setFormData(infoData: any) {
    if (infoData.id) this.titlePanel = this.titlePanel + "  ID: " + infoData.id;
    this.actionDataForm$.next(infoData);
  }

  validateFormGroup(): void {
    this.editFormGroup.markAllAsTouched();
  }
}
