import { Component, OnInit } from '@angular/core';
import { ModalNoticeService } from '@shared/services/modal-notice-service/modal-notice.service';

@Component({
  selector: 'citizen-modal-privacy-notice',
  templateUrl: './modal-privacy-notice.component.html',
  styleUrls: ['./modal-privacy-notice.component.scss'],
})
export class ModalPrivacyNoticeComponent implements OnInit {
  htmlSnippet = ``;

  constructor(private ModalNoticeService: ModalNoticeService) {}

  ngOnInit(): void {
    this.ModalNoticeService.getTextNoticePrivacy().subscribe((data: any) => {
      this.htmlSnippet = data.term;
    });
  }
}
