import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '@types-custom/models/ui/icon-model';

/*
 ** Icon Button
 ** Render an icon button.
 */
@Component({
  selector: 'sit-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent extends IconBaseComponent {
  @Input() sizeClass = 'icon-size-24';
  @Input() colorClass = '';
  @Input() btnType = '';
  @Input() tabIndex = 0;

  constructor() {
    super();
  }
}
