import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractTrafficDataSource } from '@types-custom/models/ui/modal-traffic-model';
import {BehaviorSubject, forkJoin, map, Observable, of, Subject} from 'rxjs';
import {any} from "@amcharts/amcharts4/.internal/core/utils/Array";
import {exodusAndReturnWazeMockData} from 'libs/sdk/src/lib/mock/exodus-and-return-geo-json';
import {wazeSpeedRangeGeoJsonMockData} from 'libs/sdk/src/lib/mock/waze-speed_range-data';

@Injectable({
  providedIn: 'root',
})
export class TrafficApiService implements AbstractTrafficDataSource {
  collectionMapTrafficSR: BehaviorSubject<any> = new BehaviorSubject<any>({});
  aCollectionMapTrafficSR = this.collectionMapTrafficSR.value;

  infoTraffic = {} as any;
  colorsSpeed = [
    { id: 0, color: '#FF3232' },
    { id: 10, color: '#FF9901' },
    { id: 20, color: '#00C479' },
  ];

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  public getLastMeasurements(geometryId: string, isCorridor : Boolean = false): Observable<any> {
    if(isCorridor) {
      return this.httpClient.get<any[]>(
          `${this.environment.trafficUrl}/corridor-history/last-measurements/${geometryId}`
      );
    }
    return this.httpClient.get<any[]>(
      `${this.environment.trafficUrl}/traffic-history/last-measurements/${geometryId}`
    );
  }

  public getLastMeasurementsWaze(geometryId: string, category : string): Observable<any> {
    return this.httpClient.get<any[]>(
        `${this.environment.WazeUrl}/api/v1/waze-history/last-measurements-group-hours?id=${geometryId}&category=${category}&numHours=24&validNumHours=true`
    );
  }

  public getGeoTraffic(type : boolean): Observable<any[]> {
    if(type != null){
      return this.httpClient.get<any[]>(
          `${this.environment.trafficUrl}/traffic-geom/format-geo-json?tidCorridorIsNull=${type}`
      );
    }
    return this.httpClient.get<any[]>(
        `${this.environment.trafficUrl}/traffic-geom/format-geo-json`
    );
  }

  public GetDataModal(): Observable<any[]> {
    return this.httpClient.get<any[]>(
        `${this.environment.trafficUrl}/traffic-geom/format-geo-json`
    );
  }

  public getRoadCorredors() : Observable<any[]> {
    return this.httpClient.get<any[]>(
        `${this.environment.trafficUrl}/corridor-geom/format-geo-json`
    );
  }

  public getTrafficJamWaze() : Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.WazeUrl}/api/v1/waze/jams/format-geo-json`
    )
  }

  public getWazeAlerts() : Observable<any[]> {
    return this.httpClient.get<any[]>(
        `${this.environment.WazeUrl}/api/v1/waze/alerts/format-geo-json?recordsPerPage=0&page=0`
    )
  }

  public getDataTrafficWaze(Layer: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
        `${this.environment.WazeUrl}/api/v1/waze/format-geo-json?recordsPerPage=0&page=0&layer=${Layer}`
    );
  }

  public getTrafficSpeedRange(tid: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.environment.trafficUrl}/traffic-history/speed-range/?tid=${tid}`
    );
  }

  public getTrafficSpeedRangeWaze(geometryId: string, category : string): Observable<any[]> {

        return this.httpClient.get<any[]>(
            `${this.environment.WazeUrl}/api/v1/waze-history/speed-range?id=${geometryId}&category=${category}`
         );

    //return of(wazeSpeedRangeGeoJsonMockData);
  }

  public getExodusAndReturnWaze() : Observable<any> {
    // return this.httpClient.get<any>('assets/mock/exodus-and-return-waze-geojson.json');
    // return this.httpClient.get<any[]>(
    //     `${this.environment.trafficJamWaze}`
    // )
    return of(exodusAndReturnWazeMockData);
  }

  public getTrafficHistory(): Observable<any[]> {
    return this.httpClient.get<any[]>('assets/mock/traffic-history.json');
  }

  public getTravelTimesAvg(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      'assets/mock/travell-times-avg-traffic.json'
    );
  }

  public getDataGraphicHistory(): Observable<any[]> {
    return (
      this.httpClient
        // .get<any>(url)
        .get<any[]>('/assets/mock/traveltime-history.json')
    );
  }

  public getTrafficMapper(apiObj: any) {
    const { geoTraffic, speedRange } = apiObj;
    geoTraffic['features'].map((section: any) => {
      section['properties']['concordanceIndex'] =
          section['properties']['concordanceIndex'] > 100
            ? 100 - section['properties']['concordanceIndex']
            : section['properties']['concordanceIndex'];
      // if (speedRange.length > 0) {
      //   const speedRangeFilter = speedRange.filter(
      //     (e: any) => e.tid == section['properties'].id
      //   );

      //   section.speedRange = speedRangeFilter;

      //   section['properties']['concordanceIndex'] =
      //     section['properties']['concordanceIndex'] > 100
      //       ? 100 - section['properties']['concordanceIndex']
      //       : section['properties']['concordanceIndex'];
      // } else {
      //   section.speedRange = [];
      // }
    });

    return geoTraffic;
  }

  public getLastBikesMeasurements(ID : string){
    return this.httpClient.get<any[]>(
        `${this.environment.BikesUrl}bikes-history/graph?siteId=${ID}`
    );
  }

  public getLastBikesSensorsMeasurements(){
    return this.httpClient.get<any[]>(
        `${this.environment.BikesUrl}bikes/current-sensors`
    );
  }

  public getWazeAlertsDuplicates() : Observable<any[]> {
    return this.httpClient.get<any[]>(
        `${this.environment.WazeUrl}/api/v1/waze/alerts/duplicates/format-geo-json`
    )
  }


  // public getTrafficMapper2(apiObj: any) {

  //   const {geoTraffic, historyTraffic, travelTime, historyTravel} = apiObj;
  //   const speedRangeTraffic = apiObj.speedRange;

  //   // const cosas = await this.getInfoTraffic();

  //   // return new Promise((res, rej) => {
  //   const redisEmpty = (speedRangeTraffic.length == 0);
  //   const geojson = {'type': 'FeatureCollection', redisEmpty: redisEmpty, 'features': [] as Array<any> };

  //   geojson['features'] = geoTraffic.map((section: any) => {
  //     const items_needed = speedRangeTraffic.filter((e: any) => e.tid == section.tid);
  //     let item_selected = {} as any;

  //     if (items_needed.length > 0) {
  //       item_selected = items_needed[0];

  //       if (item_selected['levelofservice'] == null) {
  //         item_selected = {"levelofservice": "#804000", "speed": 0, "elapsed_time": 0, "concordance": 0, "speedExpected":0, "travelTimes": [], "travelHistory": []};
  //       } else {
  //         const speedRange = Math.floor(item_selected['speed']/10)*10;

  //         item_selected['levelofservice'] = this.colorsSpeed.find(element => element.id == ((speedRange > 20)? 20 : speedRange)).color;

  //         if(travelTime) {
  //           item_selected['travelTimes'] = travelTime.filter((e: any) => e.tid == section.tid);
  //         }

  //         if (historyTravel) {
  //           item_selected["travelHistory"] = historyTravel;
  //         }

  //         if (historyTraffic) {
  //           const itemsConcordance = historyTraffic.filter((e: any) => e.tid == section.tid);
  //           let itemConcordance = null;

  //           if (itemsConcordance.length > 0) {
  //             itemConcordance = itemsConcordance[0];
  //             item_selected['concordance'] = ((itemConcordance['speed'] == 0 || itemConcordance['speed'] == null)? 0 : Math.round((item_selected['speed'] * 100) / itemConcordance['speed']));
  //             item_selected['speedExpected'] = itemConcordance['speed'];

  //           } else {
  //             item_selected['concordance'] = 0;
  //             item_selected['speedExpected'] = 0;
  //           }
  //         } else {
  //           item_selected['concordance'] = 0;
  //           item_selected['speedExpected'] = 0;
  //         }

  //       }
  //     } else {
  //       item_selected = {"levelofservice": "white", "speed": 0, "elapsed_time": 0, "concordance": 0, "speedExpected":0};
  //     }

  //     section["levelofservice"] = item_selected["levelofservice"],
  //     section["speed"] = Math.round(item_selected["speed"]),
  //     section["speedRound"]  =  Math.floor(item_selected["speed"]/10)*10;
  //     section["elapsed_time"] = item_selected["elapsed_time"];
  //     const concordance = ((item_selected["concordance"] == 0)? item_selected["concordance"] : (item_selected["concordance"] - 100));
  //     section["concordance"] = concordance;
  //     const concordanceRound = ((Math.floor(item_selected["concordance"]/10)*10 == 0 ) ? (Math.floor(item_selected["concordance"]/10)*10) : ((Math.floor(item_selected["concordance"]/10)*10) - 100)  )
  //     section["concordanceRound"] = concordanceRound;
  //     section["speedExpected"] = item_selected["speedExpected"];
  //     section["travelTimes"] = item_selected['travelTimes'];
  //     section["travelHistory"] = item_selected["travelHistory"];
  //     return {"properties": section, "geometry": section["json_geom"], "type": "Feature", "id": section["tid"]}
  //   });

  //   return {data: geojson}
  // }
}
