import { Component, Input } from '@angular/core';
import { IModalTrafficLightModel } from '@types-custom/models/ui/modal-traffic-light-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal Traffic Light
 ** Render a modal that displays information about Traffic Light
 */

@Component({
  selector: 'citizen-modal-traffic-light',
  templateUrl: './modal-traffic-light.component.html',
  styleUrls: ['./modal-traffic-light.component.scss'],
})
export class ModalTrafficLightComponent {
  @Input() properties: IModalTrafficLightModel | undefined;
  Icon = Icon;
}
