import { Component } from "@angular/core";
import { GroundResourceManageService } from "@official/services/data-grid-service/ground-resource-manage/ground-resource-manage.service";
import { CommonService } from "@shared/services/common-service/common-service.service";
import { ButtonClassesEnum, IButtonModel, IClassesAttributes, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalSuccesUserComponent } from "@ui-core/components/modal-succes-user/modal-succes-user.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormControlName, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { atLeastOneRoleValidator, validateEquals } from "@official/pages/user-management/user-edit/register.validator";
import { ServiceUserManagmentService } from "@official/services/data-grid-service/user-managment.service";
import { IGroundResourceEditModel } from "@shared/models/ground-resource.model";
import { IRoleModelCheckBox, IUserEditModel, IUserManagmentModel, userStateList } from "@shared/models/user.model";
import { ILocationOption } from "@types-custom/models/business/location.model";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { BehaviorSubject, Observable, Subscribable, Subscriber, isObservable, map, of, startWith } from "rxjs";
import { validate } from "uuid";

@Component({
  selector: "cdm-manizales-add-physical-resources",
  templateUrl: "./add-physical-resources.component.html",
  styleUrls: ["./add-physical-resources.component.scss"],
})
export class AddPhysicalResourcesComponent implements OnInit {

  Icon = Icon;
  buttonSaveAttributes: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_create,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonEditAttributes: IButtonModel = {
    label: 'AGREGAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_create,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonCancelAttributes: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_cancel,
      spanClass: 'text-weight-600 text-size-16'
    },
  };

  dropdownAttributes: IClassesAttributes = {
    iconClass: IconClassesEnum.blue_icon,
  };

  @Input() groundResourceEdit: any = {};

  registerFormGroup: UntypedFormGroup;
  fb = new UntypedFormBuilder();   

  physicalResourceCode = new FormControl('');
  options: any[]=[];
  filteredOptions: Observable<any[]>;

  
  typeResource: any[] = []
  listassigned: any[] = []
  objsNotAssigne: { id: number, feature: string, code: string, conditionName:string }[] = [];
  listdata: { id: number, feature: string,code: string ,conditionName:string }[] = [];
  

  constructor(
    private groundResourceManageService: GroundResourceManageService,
    private modalService: ModalService,
    private commonService:CommonService
  ) { }

  ngOnInit(): void {
    this.init();

    this.filteredOptions = this.physicalResourceCode.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.name;

        return name
          ? this._filter(name as string)
          : this.options.slice();
      })
    );
  }

  
  
  init(): void {
    this.initializeForm();
    this.setData()
  }
  

  setData() {

    this.groundResourceManageService.getDataPhysicalResourceById(this.groundResourceEdit.id).subscribe((res: any) => {
        res.forEach((nuevoDato: { id: any; feature: any; code: any; conditionName: any; }) => {
    
          this.listdata.push ({
            id: nuevoDato.id,
            feature: nuevoDato.feature,
            code:nuevoDato.code,
            conditionName:nuevoDato.conditionName,
          })
        })
    }); 
  
    this.commonService.getListHumanResources('TipoRecurso').subscribe((res: any) => {
      this.typeResource = res;
    });
    
      
    this.groundResourceManageService.PhysicalResourcenSearchNotAssign(' ').subscribe((res: any) => {
      this.options = res;
    });
   
    
  }
  
  private initializeForm(): void {
    const formControls: { [key: string]: any } = {
      
      typeResource: [
        { value: '', disabled: false },
        [
          Validators.required,
        ]
      ],
    };
    this.physicalResourceCode.setValidators([Validators.required])
      
    
    this.registerFormGroup = this.fb.group(formControls);
  }
  
  
  get typeResourceErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('typeResource') as FormControl;
    return form.hasError('required')
    ? 'Campo obligatorio'
    : '';
  }

  get codeResourceErrorMessage(): string {
    const form: FormControl = this.physicalResourceCode as FormControl;
    return form.hasError('required')
    ? 'Campo obligatorio'
    : '';
  }
  
  private handlingError(error: any): void {
    console.error({ error });
  }



  onSubmit(): void {
    const listIdResource = this.listdata.map(objeto => objeto.id );
    
    if(listIdResource.length <= 0 ){
      
      this.groundResourceManageService.PhysicalResourceAddHuman(this.groundResourceEdit.id,listIdResource)
      .subscribe((res)=> {
        this.modalService.cleanModals$.next([]);
        setTimeout(() => {
          this.showModalSuccess('Se eliminaron los recursos asignados a este usuario');
          this.groundResourceManageService.init();
          this.init();
        }, 200);
      });

    }else{
      this.groundResourceManageService.PhysicalResourceAddHuman(this.groundResourceEdit.id,listIdResource)
      .subscribe((res)=> {
        this.modalService.cleanModals$.next([]);
        setTimeout(() => {
          this.showModalSuccess('Agregado Recursos');
          this.groundResourceManageService.init();
          this.init();
        }, 200);
      });
    }
  }
 

  onCancel(): void {
    this.modalCanelar();
  }

  eliminarObjeto(obj: any) {
    this.listdata = this.listdata.filter(item => item.id !== obj.id);
  }

  AddPhysicalResource(){

    const type = this.registerFormGroup.controls.typeResource.value
    const code :any = this.physicalResourceCode.value

   this.groundResourceManageService.PhysicalResourcenSearchNotAssign(code.code,type)
    .subscribe((datos: any[]) => {
      datos.forEach(nuevoDato => {
  
        this.listdata.push ({
          id: nuevoDato.id,
          feature: nuevoDato.feature,
          code:nuevoDato.code,
          conditionName:nuevoDato.conditionName,
        })
      })

    }); 

    this.registerFormGroup.controls.typeResource.setValidators([Validators.required]);
    this.physicalResourceCode.setValidators([Validators.required]);
    
    this.registerFormGroup.updateValueAndValidity();
    this.physicalResourceCode.updateValueAndValidity();

}

// input text 

displayFn(agent: any): string {
  return agent && agent.id ? agent.id : '';
}

private _filter(value: string): string[] {
    return this.options.filter((option) =>
    option.feature.toLowerCase().includes(value.toLowerCase())
  );
}

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }
  showModalError(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();

  }



  modalCanelar(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccesUserComponent,
        data: {
          infoMessage: 'Está seguro que desea cancelar ?',
          icon: Icon.questions_w,
        },
      },
      headerBackgroundClass: 'bg-color-background-3',
      confirm: () => {
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        this.modalService.closeConfirmationModal();
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }

  validateFormGroup(): void {
    this.registerFormGroup.markAllAsTouched();
  }
}
