<div class="full-width full-height">
  <ng-container *ngFor=" let item of chartData">

      <div class="bar-chart"
        matTooltipPosition="above" matTooltipClass="custom-tooltip"
        [matTooltip]="item?.name + ' ' + item?.value + '%'"
        [style.background]="item?.color" [style.width.%]="item?.value">

      </div>
  </ng-container>
</div>
