export const vehiclesSnapsJsonMockData = [
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.2666666039660336,
        "gap": 1.67,
        "length": 350.76666666666665,
        "laneid": "E4_SN_C1",
        "carcount": 6,
        "speed": 13.333333333333334
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.039999999883887694,
        "gap": 5.575,
        "length": 18.05,
        "laneid": "E4_SN_C2",
        "carcount": 2,
        "speed": 2
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.15333329728046932,
        "gap": 38.25,
        "length": 35.56666666666667,
        "laneid": "E4_SN_C3",
        "carcount": 6,
        "speed": 7.666666666666667
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.1866665853542185,
        "gap": 10.861666666666666,
        "length": 24.483333333333334,
        "laneid": "E4_NS_C4",
        "carcount": 7,
        "speed": 9.333333333333334
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.19333328787537435,
        "gap": 10.51,
        "length": 336.1666666666667,
        "laneid": "E4_NS_C3",
        "carcount": 6,
        "speed": 9.666666666666666
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.2899973364715542,
        "gap": 6.85,
        "length": 74.175,
        "laneid": "E4_NS_C1",
        "carcount": 15,
        "speed": 14.5
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.2036355975513308,
        "gap": 9.253636363636364,
        "length": 63.54545454545455,
        "laneid": "E4_NS_C2",
        "carcount": 12,
        "speed": 10.181818181818182
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.37521926917856063,
        "gap": 3.442711864406781,
        "length": 29.20677966101695,
        "laneid": "C2_OE_01",
        "carcount": 71,
        "speed": 18.847457627118644
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.3865044441203439,
        "gap": 5.164444444444444,
        "length": 38.74444444444444,
        "laneid": "C2_OE_02",
        "carcount": 39,
        "speed": 19.333333333333332
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.3099998649632557,
        "gap": 11.58,
        "length": 48.9,
        "laneid": "C2_OE_03",
        "carcount": 7,
        "speed": 15.5
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5272534332681902,
        "gap": 5.876829268292683,
        "length": 24.546341463414635,
        "laneid": "E5_EO_C2",
        "carcount": 49,
        "speed": 26.390243902439025
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.4774966720194015,
        "gap": 9.215,
        "length": 34.1875,
        "laneid": "E5_EO_C1",
        "carcount": 14,
        "speed": 23.875
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0,
        "gap": 0,
        "length": 0,
        "laneid": "D1_OE_CL_01",
        "carcount": 55,
        "speed": 0
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.07981233929839032,
        "gap": 2.41,
        "length": 2.1,
        "laneid": "D1_OE_CL_02",
        "carcount": 60,
        "speed": 4
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 1.0106751879535456,
        "gap": 3.442499999999999,
        "length": 5.155882352941175,
        "laneid": "D1_OE_CR_01",
        "carcount": 73,
        "speed": 50.794117647058826
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 1.0319554206332293,
        "gap": 2.6172619047619046,
        "length": 5.390476190476189,
        "laneid": "D1_OE_CR_02",
        "carcount": 96,
        "speed": 52.392857142857146
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0,
        "gap": 0,
        "length": 0,
        "laneid": "D1_OE_CL_03",
        "carcount": 6,
        "speed": 0
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.4119902590874217,
        "gap": 6.718999999999999,
        "length": 25.15,
        "laneid": "E1_OE_C2",
        "carcount": 19,
        "speed": 20.6
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.4562339023297428,
        "gap": 9.500000000000002,
        "length": 81.8875,
        "laneid": "E4_EO_C2",
        "carcount": 21,
        "speed": 22.8125
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0,
        "gap": 0,
        "length": 0,
        "laneid": "E2_OE_C2",
        "carcount": 3,
        "speed": 0
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.19194010251853866,
        "gap": 3.121923076923077,
        "length": 17.219230769230773,
        "laneid": "E2_EO_C1",
        "carcount": 57,
        "speed": 9.615384615384615
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.06665232599927015,
        "gap": 1.86,
        "length": 14.766666666666666,
        "laneid": "E2_OE_C1",
        "carcount": 33,
        "speed": 3.3333333333333335
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5313942394062913,
        "gap": 6.893055555555554,
        "length": 20.097222222222225,
        "laneid": "E2_EO_C3",
        "carcount": 41,
        "speed": 26.583333333333332
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.674278332746891,
        "gap": 4.480263157894737,
        "length": 28.071052631578947,
        "laneid": "E2_EO_C2",
        "carcount": 44,
        "speed": 33.73684210526316
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.11467226796565962,
        "gap": 2.0675,
        "length": 5.4,
        "laneid": "D2_EO_CR_02",
        "carcount": 63,
        "speed": 5.75
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.12971483515250667,
        "gap": 4.2425,
        "length": 2.7,
        "laneid": "D2_EO_CL_01",
        "carcount": 59,
        "speed": 6.5
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.11187314600105917,
        "gap": 1.8379999999999999,
        "length": 2.42,
        "laneid": "D2_EO_CR_01",
        "carcount": 50,
        "speed": 5.6
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.08496433900921355,
        "gap": 2.075,
        "length": 2.475,
        "laneid": "D2_EO_CL_02",
        "carcount": 39,
        "speed": 4.25
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5960804770267732,
        "gap": 2.7396666666666674,
        "length": 19.073333333333327,
        "laneid": "E2_NS_C3",
        "carcount": 95,
        "speed": 30.244444444444444
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.45117602737665,
        "gap": 1.7414159292035403,
        "length": 22.106194690265486,
        "laneid": "E2_SN_C2",
        "carcount": 123,
        "speed": 23.495575221238937
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5873778844770949,
        "gap": 4.237924528301887,
        "length": 18.116981132075466,
        "laneid": "E2_NS_C1",
        "carcount": 63,
        "speed": 29.452830188679247
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.4767515437855548,
        "gap": 2.637439024390244,
        "length": 23.75487804878049,
        "laneid": "E2_SN_C3",
        "carcount": 91,
        "speed": 24.134146341463413
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.3714281293055567,
        "gap": 18.265714285714285,
        "length": 30.7,
        "laneid": "E1_NS_C1",
        "carcount": 9,
        "speed": 18.571428571428573
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5303220667098654,
        "gap": 9.214000000000002,
        "length": 21.991999999999997,
        "laneid": "C3_SN_01",
        "carcount": 30,
        "speed": 26.52
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5928643684455935,
        "gap": 2.3782499999999995,
        "length": 21.14499999999999,
        "laneid": "C3_SN_02",
        "carcount": 96,
        "speed": 30.1
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.5412371702349534,
        "gap": 2.0706930693069308,
        "length": 19.687128712871292,
        "laneid": "C3_SN_03",
        "carcount": 114,
        "speed": 27.89108910891089
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.28333299607282847,
        "gap": 7.233333333333333,
        "length": 21.083333333333336,
        "laneid": "E4_OE_C2",
        "carcount": 9,
        "speed": 14.166666666666666
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.23999994356943022,
        "gap": 8.395,
        "length": 140.35,
        "laneid": "E4_OE_C1",
        "carcount": 6,
        "speed": 12
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.07999228738831017,
        "gap": 2.29,
        "length": 2.9,
        "laneid": "C6_SN_CL_02",
        "carcount": 27,
        "speed": 4
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.0574643572938584,
        "gap": 2.61375,
        "length": 2.8375000000000004,
        "laneid": "C6_SN_CL_03",
        "carcount": 43,
        "speed": 2.875
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0,
        "gap": 0,
        "length": 0,
        "laneid": "C6_SN_CL_01",
        "carcount": 21,
        "speed": 0
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.0885342692196847,
        "gap": 1.8614285714285714,
        "length": 4.1000000000000005,
        "laneid": "C6_SN_CL_04",
        "carcount": 39,
        "speed": 4.428571428571429
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.07989401950593207,
        "gap": 2.41,
        "length": 5,
        "laneid": "C7_NS_CL_01",
        "carcount": 52,
        "speed": 4
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.05999984062539133,
        "gap": 1.57,
        "length": 2.5,
        "laneid": "C7_NS_CL_03",
        "carcount": 11,
        "speed": 3
    },
    {
        "timestamp": "2023-08-01 15:10:00",
        "level_of_service": 0.07989401950593207,
        "gap": 2.51,
        "length": 2.75,
        "laneid": "C7_NS_CL_02",
        "carcount": 52,
        "speed": 4
    }
]