import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '@types-custom/models/ui/icon-model';

/*
 ** Icon
 ** Render an icon.
 */
@Component({
  selector: 'sit-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent extends IconBaseComponent {
  @Input() sizeClass = 'icon-size-24';
  @Input() tooltip = '';

  constructor() {
    super();
  }
}
