import { BehaviorSubject } from 'rxjs';
import { Component, Input } from '@angular/core';

/*
** List Buttons
** Render a dynamic list with buttons.
*/

@Component({
  selector: 'sit-list-buttons',
  templateUrl: './list-buttons.component.html',
  styleUrls: ['./list-buttons.component.scss']
})
export class ListButtonsComponent {

  @Input() dataList$: BehaviorSubject<any> | undefined;
}
