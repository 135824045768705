import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ICardTrafficSpeedRangeModel } from '@types-custom/models/ui/card-traffic-speed-range-model';
import { ICardTrafficConcordanceModel } from '@types-custom/models/ui/card-traffic-concordance-model';
import {
  adjustConcordanceRotation,
  adjustSpeedometerRotation,
} from '@ui-core/utils/functions/get-indicator-rotation';

/*
 ** Card Traffic
 ** Render a card that displays traffic information.
 */
@Component({
  selector: 'sit-card-traffic',
  templateUrl: './card-traffic.component.html',
  styleUrls: ['./card-traffic.component.scss'],
})
export class CardTrafficComponent implements OnInit {
  @Input() card:
    | ICardTrafficSpeedRangeModel
    | ICardTrafficConcordanceModel
    | any
    | undefined;
    dataHistory: [] = [];
  statusIndicator: any = {};

  actMinTimeIndicator = '-';
  actSecTimeIndicator = '-';
  expMinTimeIndicator = '-';
  expSecTimeIndicator = '-';
  concordanceRotation = 0;
  speedometerRotation = 0;
  infoIndicatorMap: { [key: number]: any } = {
    1: {
      now: 'Lento',
      classNow: 'icon-filter-red',
    },
    2: {
      now: 'Medio',
      classNow: 'icon-filter-yellow',
    },
    3: {
      now: 'Bueno',
      classNow: 'icon-filter-green',
    },
  };

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.setIndicator(this.card);
    this.setTimeIndicator(this.card);
    setTimeout(() => {
      this.dataHistory = this.card.speedRange;
    });
  }

  openModal(): void {
    this.drawerPanelService.collapseDrawer(true);
    this.card?.cardMapLink?.markerDispatcher.next(
      this.card.cardMapLink.markerModel
    );
  }

  setIndicator(
    card: ICardTrafficSpeedRangeModel | ICardTrafficConcordanceModel
  ): void {
    const keyIndicator = this.validationSpeed(+card.speed);
    this.statusIndicator = this.infoIndicatorMap[keyIndicator];

    const concordance = Math.round(+this.card.concordance);
    const speed = (+this.card.speed).toFixed(2);

    this.card.speed =
        Number.parseInt(speed) > 100 ? (100 - Number.parseInt(speed)).toString() : speed.toString();

    this.card.concordance = concordance.toString();

    this.concordanceRotation = adjustConcordanceRotation(
      +this.card.concordance,
      8
    );

    this.speedometerRotation = adjustSpeedometerRotation(+this.card.speed);
  }

  setTimeIndicator(
    card: ICardTrafficSpeedRangeModel | ICardTrafficConcordanceModel
  ): void {
    const timeAct = this.convertTime(card.elapsedTime);
    const timeExp = this.convertTime(card.expectedValue);

    this.actMinTimeIndicator = timeAct.minute;
    this.actSecTimeIndicator = timeAct.second;
    this.expMinTimeIndicator = timeExp.minute;
    this.expSecTimeIndicator = timeExp.second;
  }

  validationSpeed(speed: number): number {
    if (speed < 10) {
      return 1;
    }

    return speed < 20 ? 2 : 3;
  }

  convertTime(seconds: number): any {
    const hour = Math.floor(seconds / 3600);
    const outHour = hour < 10 ? '0' + hour : hour;

    const minute = Math.floor((seconds / 60) % 60);

    const outMinute = minute < 10 ? '0' + minute : minute;

    const second = Math.floor(seconds % 60);
    const outSecond = second < 10 ? '0' + second : second;

    return {
      hour: outHour,
      minute: outMinute,
      second: outSecond,
    };
  }
}
