import { valueToRelative } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { Component, OnInit, Type } from "@angular/core"
import { ExportExcelService } from "@official/services/data-grid-service/export-excel-service/export-excel.service";
import { VehicleNoveltyService } from "@official/services/data-grid-service/vehicle-novelty-service/vehicle-novelty.service";
import { GridTypeEnum, gridTableheadersMap } from "@official/utils/mappers/grid-mapper";
import { SdkDynamicFiltersService } from "@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service";
import { CommonService } from "@shared/services/common-service/common-service.service";
import { ILocationOption } from "@types-custom/models/business/location.model";
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { IDynamicFilterModel } from "@types-custom/models/ui/dynamic-filter";
import { GridPageActions, IGridPageModel } from "@types-custom/models/ui/grid-page-model";
import { IGridTableCellDispatcher } from "@types-custom/models/ui/grid-table-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalConfirmationComponent } from "@ui-core/components/modal-confirmation/modal-confirmation.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { Subject, BehaviorSubject } from "rxjs";
import { ModalDetailNoveltyVehicleComponent } from "../modal-detail-novelty-vehicle/modal-detail-novelty-vehicle.component";
import { IRendererModel } from "@types-custom/models/ui/renderer.model";
import { ModalEditNoveltyVehicleComponent } from "../modal-edit-novelty-vehicle/modal-edit-novelty-vehicle.component";
import { Router } from "@angular/router";
import { RouteNamesEnum } from "@official/routes/routes";


@Component({
  selector: "sit-novelty-vehicle",
  templateUrl: "./novelty-vehicle.component.html",
  styleUrls: ["./novelty-vehicle.component.scss"],

  providers: [
    VehicleNoveltyService,
    SdkDynamicFiltersService,
    ExportExcelService,
    CommonService,
  ]
})
export class NoveltyVehicleComponent implements OnInit {

  constructor(
    private vehicleService: VehicleNoveltyService,
    private modalService: ModalService,
    private ExportExcelService: ExportExcelService,
    public commonService: CommonService,
    private router: Router,

  ) {
    vehicleService.init();
  }


  dispatcherVer = new Subject<IGridTableCellDispatcher>();
  dispatcherEditar = new Subject<IGridTableCellDispatcher>();
  dispatcherGrupo = new Subject<IGridTableCellDispatcher>();

  ngOnInit(): void {
    this.commonService.getListNoveltyVehicle('').subscribe({
      next: (data: any[]) => {
        const state = data
          .filter((item: any) => item)
          .map(
            (item: any) =>
            ({
              label: item.name,
              value: item.id,
            } as ILocationOption)
          );
        this.vehicleService.setStates(state);
      },
    });

    this.dispatcherEditar.subscribe(
      row => {
        this.handleCreate(row.row.id, ModalEditNoveltyVehicleComponent, 'Describir gestión','modalVer');
      }
    );
    this.dispatcherGrupo.subscribe(
      row => {
        this.router.navigate([RouteNamesEnum.ManageGroundResource])
        // this.router.navigateByUrl('', extras);
        // this.handleCreate(row.row.id, ModalEditNoveltyVehicleComponent) // el otro modal componente
      }
    );
    this.dispatcherVer.subscribe(
      row => {
        this.handleCreate(row.row.id, ModalDetailNoveltyVehicleComponent, 'Ver detalle de la novedad');
      }
    );
  }


  get icon() {
    return Icon;
  }

  dummyMethod() {

  }

  dynamicFiltersModel: IDynamicFilterModel = this.vehicleService.generateDynamicFiltersModel();



  handleCreate(id: number, component: Type<unknown>, title: string, classCustom?:string) {  /// Este en realidad es el llamado al get y al modal
    this.vehicleService.getNoveltyValue(id)//dato quemado por el momento
      .subscribe(
        data => {
          const modal: IUniqueModalModel = {
            icon: Icon.visibility_on,
            title: title,
            toRender: {
              component: component,
              data: {
                onComplete: () => console.log('This will happen when complete'),
                properties: data
              },
            },
            headerBackgroundClass: 'bg-color-background-1',
             modalClass: '#6B84FF',
          };
          this.modalService.confirmationModal(modal);
        }
      )
  }

  gridPageProps: IGridPageModel = {
    headers: gridTableheadersMap([this.dispatcherEditar, this.dispatcherGrupo, this.dispatcherVer,])[GridTypeEnum.VehicleNovelty],
    dataSource: this.vehicleService,
    // dispatcher: new Subject<IGridTableCellDispatcher>(),
    isAllowDownload: true,
  };

  handleDownloadGridAction(action: GridPageActions): void {

    const fileProps = {
      url: this.vehicleService.buildExcelPdfUrl(`${action}`),
      // filters: this.vehicleService?.formFiltersValue,
      filters: {}
    };
    // delete fileProps.filters.beginDate;
    // const dynamicFilters = this.sdkDynamicFiltersService.getModel();

    // Object.keys(dynamicFilters).forEach(k => {
    //   if (dynamicFilters[k])
    //     fileProps.filters[k] = dynamicFilters[k];
    // })

    this.ExportExcelService.downloadFile(
      fileProps.url,
      'inconsistencias_novedades_vehículos',
      // GridTypeEnum.ManageIncidentParameters,
      `${action}`,
      fileProps.filters
    );

  }
}
