import { HttpClient, } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IDatexCategory, IDatexCategoryWithSubcategories, IDatexSubcategory } from "@shared/models/datex-category.model";



@Injectable()
export class DatexSubcategoriesService {
  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) { }

  fetchCategories(): Observable<IDatexCategory[]> {
    const URL = `${this.environment.incidentUrl}/incident-categories-datex/`;
    return this.httpClient.get<IDatexCategory[]>(URL);
  }

  fetchSubcategories(categoryId: number): Observable<IDatexSubcategory[]> {
    const url = `${this.environment.incidentUrl}/incident-categories-datex/${categoryId}/subcategories`;
    return this.httpClient.get<IDatexSubcategory[]>(url);
  }

  addCategory(category: IDatexCategoryWithSubcategories) {
    const URL = `${this.environment.incidentUrl}/incident-categories-datex/active`;
    return this.httpClient.post(URL, category);
  }
}
