<div class="container-modal d-flex fd-col">
  <div class="ind-modal">
    <div class="card bd-r-white">
        <sit-icon
        [icon]="Icon.coi_yellow"
        alt="Icono coi amarilla"
        sizeClass="icon-size-40"
        class="icon-bg"
        >
        </sit-icon>
        <span class="">Horario Cierre</span>
        <span class="">{{ properties?.hcie }}</span>
    </div>
    <div class="card mid">
      <sit-icon
      [icon]="Icon.zoom_blue"
      alt="Icono coi amarilla"
      sizeClass="icon-size-40"
      class="icon-bg"
      >
      </sit-icon>
      <span>Localidad:</span>
      <span>{{ properties?.location.toLowerCase()}}</span>
    </div>
    <div class="card bd-l-white">
      <sit-icon
      [icon]="Icon.coos_orange"
      alt="Icono coi amarilla"
      sizeClass="icon-size-40"
      class="icon-bg"
      >
      </sit-icon>
      <span>Horario Trabajo</span>
      <span> {{ properties?.htra }}</span>
    </div>
  </div>
  <div class="titles-modal">
    <div class="tit-1">
      <sit-icon
      [icon]="Icon.text_box"
      alt="Icono de caja con texto"
      [sizeClass]="'icon-size-32'"
      class="icon-footer"
      >
      </sit-icon>
      <span>Contratista: </span>
      <span class="text-white">{{ properties?.contratista }}</span>
    </div>
    <div class="tit-2">
      <sit-icon
      [icon]="Icon.text_box"
      alt="Icono de caja con texto"
      [sizeClass]="'icon-size-32'"
      class="icon-footer"
      >
      </sit-icon>
      <span>Descripcion:</span>
    </div>
  </div>
  <div class="text-modal">
    <div class="text-cont">
     <p>
      {{ properties?.observaciones }}
    </p>
    </div>
  </div>
  <div class="footer-modal d-flex ">
     <div class="card-footer">
      <label class="card-tit">
        <sit-icon
        alt="icon calendario"
        [icon]="Icon.calendar_w"
        [sizeClass]="'icon-size-24'"
        class="icon-footer"
        >
        </sit-icon>
        <span>Fecha Inicio</span>
      </label>
      <span class="text-white"><strong>{{ properties?.fini | date: 'dd/MM/yyy'}} </strong> </span>
    </div>
    <div class="card-footer">
      <label class="card-tit">
        <sit-icon
        alt="icon calendario"
        [icon]="Icon.calendar_w"
        [sizeClass]="'icon-size-24'"
        class="icon-footer"
        >
        </sit-icon>
        <span>Fecha Fin</span>
      </label>
      <span class="text-white"><strong> {{ properties?.ffin | date: 'dd/MM/yyy' }} </strong> </span>
    </div>
  </div>
</div>