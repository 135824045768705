import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { url } from 'inspector';
import { BehaviorSubject, Subject, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IncidentPanelService {

  private dataSubject = new BehaviorSubject<string>('');
  public data$ = this.dataSubject.asObservable();

  clickModalLDispatcher$ = new Subject<any>();

  constructor(private httpClient: HttpClient,
    @Inject('environment') private environment: any

  ) { }

  setData(id: string) {
    this.dataSubject.next(id);
  }


 public setIncidentAddHumanResource(humanId: string) {
    let incidentId!: string;
    
    this.data$.subscribe(
      (valor) => {
        incidentId = valor
      }
    )
      
    if (!incidentId || !humanId) {
      return null
    }

    const url = `${this.environment.incidentUrl}/incident-assign-user/human-resource`
    const body = {
      incidentId: incidentId,
      humanResourceId: humanId
    }

    return this.httpClient.post(url, body).subscribe(
      {
        next:(_)=> this.clickModalLDispatcher$.next(true),
        error:(_)=> this.clickModalLDispatcher$.next(false),

      }
    )

  }

}
