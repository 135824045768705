<div
  class="modal-traffic w-100 h-auto d-flex fd-col text-blue text-weight-600 pt-30"
>
  <div class="wrapper-speed pb-20">
    <div class="container-info separator-line-info indicator-container">
      
      <div
        class="container-graphic-speed d-flex fd-col justify-center align-items-center mt-1-em"
      >
        <sit-speedometer
          [properties]="{
            chartType: 4,
            value: +properties.speed,
            handClockRotation: speedometerRotation,
            unitLabel: 'Km/h',
            divId: 'id',
            labelsClass: 'mt-0',
            unitLabelClass: 'text-size-16'
          }"
        ></sit-speedometer>
      </div>
      <div
        class="text-size-12 text-weight-400 text-white text-center text-md-size-14"
      >
        <span>Velocidad promedio</span>
      </div>
    </div>

    <div class="container-info separator-line-info indicator-container">
      <div
        class="d-flex fd-col justify-center text-white time-gap-10 mt-1-em ml-1"
      >
        <div class="d-flex fd-col">
            <label class="text-size-18 text-weight-500 text-md-size-14 text-blue"
            >{{ expMinTimeIndicator }}m {{ expSecTimeIndicator }}s</label
            >
            <label class="text-size-12 text-weight-400">Tiempo Esperado</label>
        </div>
        <div class="d-flex fd-col">
            <label class="text-size-18 text-weight-500 text-md-size-14 text-blue"
            >{{ actMinTimeIndicator }}m {{ actSecTimeIndicator }}s</label
            >
            <label class="text-size-12 text-weight-400">Tiempo Real</label>
        </div>
      </div>
    </div>

    <div class="container-info separator-line-info indicator-container">
      
      <div class="d-flex fd-col justify-center">
        <div class="height-line-bar" *ngIf="this.dataHistory.length != 0">
            <sit-bar-chart
              [properties]="{
                tid: +properties?.id,
                dataAll: this.dataHistory,
                type: 2
              }"
            ></sit-bar-chart>
        </div>
        <div
          class="d-flex justify-center font-size-18 text-white text-weight-700 align-items-center mt-1-em"
        >
          <label class="text-size-18">{{ statusIndicator.now }}</label>
        </div>

        <div
          class="text-size-12 text-white text-weight-400 text-center text-md-size-14 mt-05-em"
        >
          <span>Rango</span>
        </div>
      </div>
    </div>
  </div>  

  <div class="modal-body d-flex fd-col mb-1 justify-space-evenly">
    <div class="text-size-16 text-white text-weight-600 mb-25 text-md-size-14">
      <span>Rango de Velocidad</span>
    </div>

    <div class="d-flex full-width speed-range-chart">
      <sit-chart-line
        [properties]="dataGraphicList"
        [colorScale]="colorScale"
        style="width: 100%"
      >
      </sit-chart-line>
    </div>
  </div>
</div>
