import { GridTypeEnum } from '@official/utils/mappers/grid-mapper';
import { Icon } from '@types-custom/models/ui/icon-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';

export const NameManagePanelMap: { [key: string]: string } = {
  [MapEventTypeEnum.SENSORS]: 'SENSORES',
  [MapEventTypeEnum.CAMERAS]: 'CÁMARAS',
  [MapEventTypeEnum.INCIDENTS]: 'INCIDENTES',
  [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: 'INCIDENTS_SOCIAL_MEDIA_TEXTS',
  [MapEventTypeEnum.PMV]: 'PANEL DE MENSAJERÍA',
  [MapEventTypeEnum.PMVconsulta]: 'PANEL DE CONSULTA',
  [MapEventTypeEnum.PMVEditar]: 'PANEL EDITAR',
};

export const IconManagePanelMap: {
  [key: string]: { icon: Icon; alt: string };
} = {
  [MapEventTypeEnum.SENSORS]: { icon: Icon.sensor_w, alt: 'icono sensores' },
  [MapEventTypeEnum.CAMERAS]: { icon: Icon.camera_play_w, alt: 'icono de camara ' },
  [MapEventTypeEnum.PMV]: { icon: Icon.check_w, alt: 'icono pmv' },
  [MapEventTypeEnum.INCIDENTS]: {
    icon: Icon.alert_w,
    alt: 'icono alerta incidentes',
  },
  [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: {
    icon: Icon.alert_w,
    alt: 'icono alerta incidentes',
  },
};

export const GridTypePanelManageMap: { [key: string]: GridTypeEnum } = {
  [MapEventTypeEnum.SENSORS]: GridTypeEnum.ManageSensors,
  [MapEventTypeEnum.CAMERAS]: GridTypeEnum.ManageCameras,
  [MapEventTypeEnum.PMV]: GridTypeEnum.ManageVariableMessaging,
  [MapEventTypeEnum.INCIDENTS]: GridTypeEnum.ManageIncidents,
  [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: GridTypeEnum.ManageIncidentsSocialMediaText,
  [MapEventTypeEnum.PMVconsulta]: GridTypeEnum.PMVconsulta,
};

export const TitlePanelGridMap: { [key: string]: string } = {
  [MapEventTypeEnum.PMV]: 'REGISTRO PANELES DE MENSAJERÍA',
};

export const TitleButtonCreateEditMap: { [key: string]: string } = {
  [MapEventTypeEnum.PMV]: 'PANEL',
};

export const TitlePanelFilterMap: { [key: string]: string } = {
  [MapEventTypeEnum.PMV]: 'FILTRO DE BÚSQUEDA',
};

export const TextManagePanelMap: { [key: string]: string } = {
  [MapEventTypeEnum.SENSORS]: `Permite consultar la cantidad de puntos sensorizados en la ciudad, su ubicación y su actividad en las últimas 24 horas. Haga clic en un punto sobre el mapa para consultar la información.`,
  [MapEventTypeEnum.CAMERAS]: `Permite agregar, editar y consultar la información de las cámaras de actores viales, LPR y CCTV registradas en el sistema.Haga clic en un punto sobre el mapa para consultar la información.`,
  [MapEventTypeEnum.PMV]: `Permite agregar, editar y consultar la información del panel de mensajería en todos sus componentes. Haga clic en un punto sobre el mapa para agregar la localización.`,
  [MapEventTypeEnum.INCIDENTS]: `Permite agregar, editar y consultar la información relacionada con todas las novedades que se presentan en torno a la movilidad de la ciudad. Haga clic en un punto sobre el mapa para consultar la información.`,
  [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: `Permite agregar, editar y consultar la información relacionada con todas las novedades que se presentan en torno a la movilidad de la ciudad. Haga clic en un punto sobre el mapa para consultar la información.`,
};

export const MessageCreateSuccess: { [key: string]: string } = {
  [MapEventTypeEnum.SENSORS]: 'El sensor se ha registrado con éxito',
  [MapEventTypeEnum.CAMERAS]: 'La camara se ha registrado con éxito',
  [MapEventTypeEnum.PMV]: 'El panel se ha registrado con éxito',
  [MapEventTypeEnum.INCIDENTS]:
    'La Novedad Bitácora se ha registrado con éxito',
  [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]:
    'La Novedad Bitácora se ha registrado con éxito',
};

export const MessageEditSuccess: { [key: string]: string } = {
  [MapEventTypeEnum.SENSORS]: 'El sensor se ha editado con éxito',
  [MapEventTypeEnum.CAMERAS]: 'La camara se ha editado con éxito',
  [MapEventTypeEnum.PMV]: 'El panel se ha editado con éxito',
  [MapEventTypeEnum.INCIDENTS]: 'La Novedad Bitácora se ha editado con éxito',

};

export const MessageUnassignSucces: { [key: string]: string } = {
  [MapEventTypeEnum.INCIDENTS]: 'El recurso fue desasignado exitosamente',
}

export const fileNameDownloaded: { [key: string]: string } = {
  [MapEventTypeEnum.SENSORS]: 'Lista de Sensores',
  [MapEventTypeEnum.CAMERAS]: 'Lista de Camaras',
  [MapEventTypeEnum.PMV]: 'Lista de PMV',
  [MapEventTypeEnum.INCIDENTS]: 'Lista de Novedades Bitácora',
};
