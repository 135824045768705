<div class="w-auto h-auto d-flex fd-col gap-15">
  <div class="d-flex fd-row justify-space-evenly container-indicators">
    <div class="chart-container">
      <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
    </div>
    <div class="chart-container">
      <div class="d-flex fd-col text-white align-items-center">
        <sit-icon
          [icon]="Icon.car_blue"
          [sizeClass]="'icon-size-64'"
          alt="automóvil icono indicador flujo"
        ></sit-icon>
        <label class="text-weight-600 text-size-24 text-md-size-14">{{
          carsFlowIndicator
        }}</label>
        <label class="text-md-size-14">Flujo</label>
      </div>
    </div>
  </div>

  <div class="d-flex fd-row f-wrap justify-center gap-8">
    <ng-container *ngFor="let indicator of btnTypeIndicator">
      <div
        class="btn-type-indicator d-flex fd-row align-items-center gap-8"
        (click)="selectedVehicleType(indicator)"
        [ngClass]="
          indicator.active ? 'bg-color-secondary-3' : 'bg-color-complementary-2'
        "
      >
        <sit-icon
          [icon]="indicator.icon"
          [alt]="indicator.alt"
          [sizeClass]="'icon-size-32 icon-aforo-res'"
          [tooltip]="indicator.tooltip"
        ></sit-icon>
        <label
          class="text-color-secondary-1 text-weight-700 text-size-20 text-aforo-res"
          >{{ indicator.value }}</label
        >
      </div>
    </ng-container>
  </div>

  <div>
    <mat-button-toggle-group
      class="d-flex fd-row"
      (change)="optionTypeSelected($event)"
      [(value)]="defaultOption"
    >
      <ng-container *ngFor="let option of typeOptions">
        <mat-button-toggle
          class="full-width text-weight-600 text-size-16 montserrat-font"
          [value]="option"
          >{{ option }}</mat-button-toggle
        >
      </ng-container>
    </mat-button-toggle-group>
  </div>

  <div class="chart-line-container d-flex fd-row full-width">
    <!-- <label>Velocidad Promedio Carros Últimas 8 Horas</label> -->

    <sit-chart-line
      [properties]="graphicDataGraphicList"
      [colorScale]="graphicColorScale"
      class="full-width"
    >
    </sit-chart-line>
  </div>
</div>
