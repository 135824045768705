import { Component, Input, OnInit } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { Subject } from "rxjs";
import { IGridTableCellDispatcher } from "@types-custom/models/ui/grid-table-model";
import { CardSocialMediaActionEnum } from "@types-custom/models/ui/panel-manage-model";
import { IncidentPanelService } from "@shared/services/incident-panel/incident-panel.service";
import { IncidentConfigurationService } from "@official/services/data-grid-service/incident-configuration-service/incident-configuration.service";

@Component({
  selector: "sit-actions-incidents-social-media-text",
  templateUrl: "./actions-incidents-social-media-text.component.html",
  styleUrls: ["./actions-incidents-social-media-text.component.scss"],
  providers: [
    IncidentConfigurationService
  ],
})
export class ActionsIncidentsSocialMediaTextComponent implements OnInit {
  @Input() dispatcher: Subject<IGridTableCellDispatcher>;
  @Input() props: any;
  @Input() id: any;
  Icon = Icon;

  wazeMessage!:any;
  whatsAppMessage!:any;
  twitterMessage!:any;

  actions = CardSocialMediaActionEnum;
  constructor(
     private servicePanel: IncidentPanelService,
     private incidentService: IncidentConfigurationService
    ){}


  ngOnInit(): void {
    console.log(this.id);
    this.patchTextsSocialMedia();
  }

  dispatchAction(action: CardSocialMediaActionEnum) {
    var toDispatch: any = {}

    if(action == "wazeText"){
      toDispatch = {
        index: 0,
        row: this.props.row,
        action: action,
        message: this.wazeMessage
      }
    }else if(action == "twitterText"){
      toDispatch = {
        index: 0,
        row: this.props.row,
        action: action,
        message: this.twitterMessage
      }
    }else{
      toDispatch = {
        index: 0,
        row: this.props.row,
        action: action,
        message: this.whatsAppMessage
      }
    }

    this.dispatcher.next(toDispatch);
  }

  patchTextsSocialMedia(){
    this.incidentService.getTextSocialMediaById(this.id,'WAZE').subscribe({
      next: (data: any) => {
        this.wazeMessage = data ? data.message : 'No asignado';
      },
      error: (error: any) => {
        this.wazeMessage ='No asignado';
        this.handlingError(error)
      }
    });

    this.incidentService.getTextSocialMediaById(this.id,'WHATSAPP').subscribe({
      next: (data: any) => {
        this.whatsAppMessage = data ? data.message : 'No asignado';
      },
      error: (error: any) => {
        this.whatsAppMessage ='No asignado';
        this.handlingError(error)
      }
    });

    this.incidentService.getTextSocialMediaById(this.id,'TWITTER').subscribe({
      next: (data: any) => {
        this.twitterMessage = data ? data.message : 'No asignado';
      },
      error: (error: any) => {
        this.twitterMessage ='No asignado';
        this.handlingError(error)
      }
    });
    
  }

  private handlingError(error: any): void {
    console.error({ error });
  }

}
