import { Component } from "@angular/core";
import {ICardRoadCorridorsModel} from "@types-custom/models/ui/card-road-corridors.model";
import { Icon } from '@types-custom/models/ui/icon-model';
import {BehaviorSubject} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsModel} from "@types-custom/models/ui/incidents-model";
import * as am4core from "@amcharts/amcharts4/core";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {ICardTrafficDataWazeModel} from "@types-custom/models/ui/card-traffic-data-waze-model";

@Component({
  selector: "sit-panel-traffic-data-waze-component",
  templateUrl: "./panel-traffic-data-waze-component.component.html",
  styleUrls: ["./panel-traffic-data-waze-component.component.scss"],
})
export class PanelTrafficDataWazeComponentComponent {
  Icon = Icon;
  cards: ICardTrafficDataWazeModel[] = [];
  avSpeedIndicator = 0;
  avFlowIndicator = 0;
  listspeed: Array<any> = [];
  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;
  //avgSpeedValue: 0;
  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  colorsSpeed = [
    { id: 60, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 50, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 40, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 30, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 20, color: am4core.color('rgba(0,196,121,0.7)') },
    { id: 10, color: am4core.color('rgba(255,153,1,0.7)') },
    { id: 0, color: am4core.color('rgba(255,50,50,0.71)') },
  ];

  bordersSpeed = [
    { id: 60, color: am4core.color('rgb(0,196,121)') },
    { id: 50, color: am4core.color('rgb(0,196,121)') },
    { id: 40, color: am4core.color('rgb(0,196,121)') },
    { id: 30, color: am4core.color('rgb(0,196,121)') },
    { id: 20, color: am4core.color('rgb(0,196,121)') },
    { id: 10, color: am4core.color('rgb(255,153,1)') },
    { id: 0, color: am4core.color('rgb(255,50,50)') },
  ];

  constructor() {
    //
  }

  ngOnInit(): void {
    this.setIndicators(this.cards);
    this.setGraphic(this.cards);
  }

  setIndicators(cards: ICardTrafficDataWazeModel[]) {
    let sumspeedAvg = 0;
    let totDistance = 0;
    let totIncidents = 0;

    cards.map((cards: ICardTrafficDataWazeModel) => {
      if (!isNaN(+cards.speed)) {
        sumspeedAvg = sumspeedAvg + +cards.speed * (+cards.distance / 1000);
        if (+cards.speed > 0) {
          totDistance = totDistance + +cards.distance / 1000;
        }
      }
    });

    this.avgSpeedProps.value = Math.round(sumspeedAvg / totDistance);

    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );
  }

  setGraphic(cards: ICardTrafficDataWazeModel[]) {
    const lookup = {} as any;
    const lookupD = {} as any;

    cards.map((card: ICardTrafficDataWazeModel) => {
      const speed = Math.floor(+card.speed / 10) * 10;
      if (speed in lookup) {
        lookup[speed] = lookup[speed] + 1;
        lookupD[speed] = lookupD[speed] + (+card.speed);
      } else {
        lookup[speed] = 1;
        lookupD[speed] = +card.speed;
        const object: any = new Object();
        object['id'] = speed;
        object['label'] = speed;
        (object['dta'] = 1);
        //console.log(speed);
        (object['qty'] = 1),
            (object['color'] = this.colorsSpeed.find(
                (element) => element.id == (speed > 60 ? 60 : speed)
            ).color),
            (object['border'] = this.bordersSpeed.find(
                (element) => element.id == (speed > 60 ? 60 : speed)
            ).color);
        object['orderLabelY'] = true;    

        this.listspeed.push(object);
        //console.log(this.listspeed);
      }
    });

    this.listspeed.forEach((item) => {
      item.qty = lookup[item.id];
      item.dta = lookupD[item.id];
    });
    //console.log(this.listspeed);
  }
}
