<div
        class="modal-traffic w-100 h-auto d-flex fd-col text-blue text-weight-600 pt-30"
>
    <div class="wrapper-speed pb-20">
        <div class="container-info separator-line-info indicator-container">
            <div class="element">
                <span class="title">
                {{this.accidentType}}
            </span>
                <span class="subject">
                Tipo
            </span>
            </div>
        </div>

        <div class="container-info separator-line-info indicator-container">
            <div class="element">
                <span class="title">
                {{this.accidentSubType}}
            </span>
                <span class="subject">
                Subtipo
            </span>
            </div>
        </div>

        <div class="container-info separator-line-info indicator-container">
            <div class="element">
                <span class="title">
                {{properties.reliability}}
            </span>
                <span class="subject">
                Fiabilidad
            </span>
            </div>
        </div>

        <div class="container-info separator-line-info indicator-container">
            <div class="element">
                <span class="title">
                {{properties.locality}}
            </span>
                <span class="subject">
                Localidad
            </span>
            </div>
        </div>
    </div>

    <div class="modal-body d-flex fd-col mb-1 justify-space-evenly">
        <div class="text-size-16 text-white text-weight-600 mb-25 text-md-size-14 description-box">
            <span>Descripción:</span>
            <span>{{properties.description}}</span>
        </div>

        <div class="d-flex footer-box">
            <span class="d-flex"> <sit-icon [icon]="Icon.calendar_outlined_white"
                             [sizeClass]="'icon-size-28'"
                             alt="icono persona"></sit-icon> Reporte : {{this.date}}</span>

            <span class="d-flex"> <sit-icon [icon]="Icon.time"
                                            [sizeClass]="'icon-size-28'"
                                            alt="icono persona"></sit-icon> Hora : {{this.time}}</span>
            <span class="d-flex">Fuente: SDM WAZE</span>                                
            <!-- <span class="d-flex"> minutos :{{ properties?.timeAlerts }}</span>   -->
          </div>
    </div>
</div>
