import { AbstractPlaybackControl } from '@types-custom/models/ui/modal-camera.modal';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: null,
})
export class CameraVideoService implements AbstractPlaybackControl {
  streamUrl = '';
  videoUrl = '';

  constructor(@Inject('environment') private environment: any) {
    this.streamUrl = this.environment.streamingCCTVUrl;
  }
}
