<div class="container">
    <div *ngFor="let item of properties" [ngClass]="properties.length > 1 ? 'list-grid' : ''" class="h-100">
        <div class="content" [ngClass]="properties.length > 1 ? 'd-flex' : 'd-grid'">
            <div class="centered-content">
                <div class="icon {{size}}">
                    <sit-icon [icon]="item.icon" [sizeClass]="size"></sit-icon>
                </div>
                <div class="label" *ngIf="item.label != item.alt">
                    <span>{{item.label}}</span>
                </div>
            </div>
            <div class="alt">
                <span>{{item.alt}}</span>
            </div>
        </div>
    </div>
</div>
