import {Component, Input, OnInit} from "@angular/core";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IcardConvention} from "@types-custom/models/ui/map-conventions-model";
import {Icon} from "@types-custom/models/ui/icon-model";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {ICardBikesModel} from "@shared/models/Bikes.model";

@Component({
  selector: "sit-list-bikes",
  templateUrl: "./list-bikes.component.html",
  styleUrls: ["./list-bikes.component.scss"],
})
export class ListBikesComponent implements OnInit{
  @Input() card:
      | ICardBikesModel
      | undefined;

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px',
    value: 0,
  };

  flow : IcardConvention[] = [
    {
      icon : Icon.bicycles_w,
      label : "108",
      alt : ""
    }
  ]

  levelOfService : IcardConvention[] = [{
    icon : Icon.serva,
    label : "Nivel de Servicio",
    alt : "Nivel de Servicio"
  }]

  ngOnInit() {
    this.avgSpeedProps.value = this.card.speed;
    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );
    this.levelOfService[0].icon = this.card.levelOfService === "A" ? Icon.serva : this.card.levelOfService === "B" ? Icon.servb :
        this.card.levelOfService === "C" ? Icon.servc : this.card.levelOfService === "D" ? Icon.servd : Icon.serve

    this.flow[0].label = this.card?.flow.toString();
  }

  openModal(): void {
    this.card?.cardMapLink?.markerDispatcher.next(
        this.card.cardMapLink.markerModel
    );
  }
}
