import { ILocationOption } from '@types-custom/models/business/location.model';

export interface IUserModel {
  isDefaultUser?: boolean;
  names?: string;
  isTermsAccepted?: boolean;
  token: string;
  tokenType?: string;
  email?: string;
  username?: string;
  rol?: IRole;
}

export interface IUserAuthModel {
  names?: string;
  token: string;
  tokenType?: string;
  email?: string;
  username?: string;
  loginType: string;
  roles: string[];
}

export interface IUserManagmentModel {
  id?: number;
  documentType?: number;
  identification?: number;
  name?: string;
  lastName?: string;
  email?: string;
  phone?: number;
  enabled?: boolean;
  fullName?: string;
  rolNames?: any;
}

export interface IRoleModel {
  id: number;
  name: string;
  description?: string;
  state?: string;
}

export interface IRoleModelCheckBox extends IRoleModel {
  isChecked?: boolean;
}

export interface IUserEditModel {
  id?: number;
  documentTypeId?: number;
  name?: string;
  lastName?: string;
  email?: string;
  phone?: number;
  enabled?: boolean;
  fullName?: string;
  rolNames?: string[];
  identification?: number;
  password: string;
}

export interface IDocumentTypeModel {
  id: number;
  name: string;
}

export enum IRole {
  ADMIN = 'admin',
  ANONYMOUS = 'anonymous',
  AUTENTICATED = 'autenticated',
  PROGRAMADORPMV = 'programadorpmv',
  VISUALIZADOR = 'visualizador',
  CONFIGURACION = 'configuracion',
}

export enum IUserState {
  // PENDING = 'Pendiente',
  ACTIVE = 'Activo',
  // APPROVED = 'Aprobado',
  INACTIVE = 'Inactivo',
}

export const userStateList = [
  {
    label: IUserState.ACTIVE,
    value: 'true',
  } as ILocationOption,

  {
    label: IUserState.INACTIVE,
    value: 'false',
  } as ILocationOption,
];
