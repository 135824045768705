import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';


export interface IPropsAccidentModel {
  id: string;
  code: string;
  address: string;
  accidentType: string;
  severity: string;
  sector: string;
  titlePopup: string;
  markerPopupText: string;
  title: string;
}

export class AccidentModel extends MarkerModelBase {
  static className = 'AccidentModel';
  classProperties: IPropsAccidentModel[];

  constructor(props: IPropsAccidentModel[], geometry: IGeometryModel) {
    super(geometry, AccidentModel.className);
    this.classProperties = props;
  }
}
