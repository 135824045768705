import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardVehiclesModel } from '@types-custom/models/ui/card-vehicles-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { VehiclesService } from '@shared/services/layer-service/vehicles-service/vehicles.service';
import { finalize } from 'rxjs/operators';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalVehiculesComponent } from '../modal-vehicle/modal-vehicle.component';
import * as e from 'express';
import { IModalTrafficModel } from '@types-custom/models/ui/modal-traffic-model';
import { IModalTrafficDataWazeModel } from '@types-custom/models/ui/modal-trafficdatawaze-model';
import { adjustSpeedometerRotation } from '@ui-core/utils/functions/get-indicator-rotation';

@Component({
  selector: 'panel-vehicles',
  templateUrl: './panel-vehicles.component.html',
  styleUrls: ['./panel-vehicles.component.scss'],
})
export class PanelVehiclesComponent implements OnInit {
  Icon = Icon;
  cards: ICardVehiclesModel[] = [];
  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];
  dataGraphicListSpeed: ITimeLineGraphicModel;
  colorScaleSpeed: any = [{}];
  totalAgents = 0;
  totalPoliceAgents = 0;
  totalTransitAgents = 0;
  @Input() serviceDataSource: any;

  isLoading: boolean;
  lists : any[];

  levelOfService: any;
  totFlow: any;
  speedAvg:any;
  icon:any;
  color:any;

  colorFlow = "#6B84FF";

  speedometerRotation = 0;
  statusIndicator: any = {};

  infoIndicatorMap: { [key: number]: any } = {
    1: {
      now: 'Lento',
      classNow: 'icon-filter-red',
    },
    2: {
      now: 'Medio',
      classNow: 'icon-filter-yellow',
    },
    3: {
      now: 'Bueno',
      classNow: 'icon-filter-green',
    },
  };

  constructor(private drawerPanelService: DrawerPanelService,private vehicleService:VehiclesService,
    private modalService: ModalService) {}

  ngOnInit(): void {
    this.totalAgents = this.cards.length ?? 0;
    this.lists = []; 

    this.vehicleService.getGraphicFlow().subscribe((res: any) => {
      this.setGraphicFlow(res);
    });

    this.vehicleService.getGraphicSpeed().subscribe((res: any) => {
      this.setGraphicSpeed(res);
    });

    this.vehicleService.getListSensors().subscribe((res: any) => {
      this.lists = res.propertiesLocation;
      this.setData();
    });

    this.setIndicator()
    this.speedometerRotation = 41;
  }

  setIndicator(): void {
    const keyIndicator = this.validationSpeed(this.speedAvg);
    this.statusIndicator = this.infoIndicatorMap[keyIndicator];

    const speed = Math.round(this.speedAvg);

    this.speedAvg =
      speed > 100 ? (100 - speed).toString() : speed.toString();

    this.speedometerRotation = adjustSpeedometerRotation(
      this.speedAvg
    );
  }

  validationSpeed(speed: number): number {
    if (speed < 10) {
      return 1;
    }

    return speed < 20 ? 2 : 3;
  }

  setData(){
    this.vehicleService.getNivel().pipe(finalize(() => (this.isLoading = false))).subscribe((levels:any) =>{
            
      let sumVFlow = 0, speedVAVG = 0, level_of_serviceVAVG = 0, qty = 0;
      // this.lists = []; 
      this.lists.forEach((item) => {
      

        let d: any = new Object();
        d['adress'] = item.name;
        d['id'] = item.idSite;
        
        let sumVFlowSite = 0;
        let speedVAVGSite = 0;
        let level_of_serviceVSiteAVG = 0;

        if (item.speedAverage){
          // item.sensores.forEach((sensor:any) => {
            
            sumVFlow += item.flowSum;
            sumVFlowSite += item.flowSum;
            speedVAVG += item.speedAverage;
            speedVAVGSite += item.speedAverage;
            level_of_serviceVAVG += item.serviceLevelNum
            level_of_serviceVSiteAVG += item.serviceLevelNum
            qty = this.lists.length; 

          // });

          level_of_serviceVSiteAVG = level_of_serviceVSiteAVG/this.lists.length;


          d["flow"] = sumVFlowSite;
          d["speed"] = +((speedVAVGSite/this.lists.length).toFixed(2));
          
          let site_nivel = levels.filter((e:any) => e.start_range <  level_of_serviceVSiteAVG && e.end_range >  level_of_serviceVSiteAVG)
          if(site_nivel.length > 0){
            d["level_of_service"] = site_nivel[0].letter;
          }
          else {
            d["level_of_service"] = levels[0].letter;
            
          }

          d["properties"] = item;
          // this.lists.push(d);
        }
        else 
        {
          d["flow"] = 0;
          d["speed"] = 0;
          d["level_of_service"] = 'A';
          d["properties"] = item
          // this.lists.push(d);
        }

      });


      
      level_of_serviceVAVG = level_of_serviceVAVG/qty;
      
      let items_nivel = levels.filter((e:any) => e.start_range <  level_of_serviceVAVG && e.end_range >  level_of_serviceVAVG)
  
      if(items_nivel.length > 0){
        this.levelOfService = items_nivel[0].letter; 
        this.icon = this.getIcon(items_nivel[0].letter); 
        this.color = items_nivel[0].color; 
      }
      else {
        this.levelOfService = levels[0].letter;
        this.icon = this.getIcon(levels[0].letter);
        this.color = levels[0].color;
      }

      this.totFlow = sumVFlow;
      this.speedAvg = Math.round(speedVAVG/qty);
      
    });
  }

  openModal(card: any): void {

    card.customClass = 'modalVehicles'
    card.title = card.name;
    card.id = card.idSite;
    card.icon= Icon.car_blue;
    const toRender: any = {
      component: ModalVehiculesComponent,
      data: {properties:card},
      
    };



    this.modalService.addModal(toRender);
    
    this.drawerPanelService.collapseDrawer(true);
    // card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }

  setGraphicFlow(data: any): void {
    const sortedData = data
      // .map((e: any) => {
      //   return { time: ('0' + e.date).slice(12,17), value: e.count };
      // }).sort((a: any, b: any) => ('0' + a.date).slice(12,17).localeCompare(('0' + b.date).slice(12,17)));
      .reportVehicle.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.countTotal };
      })
  

    this.dataGraphicList = {
      data: sortedData,
      label: 'vehiculos',
      labelX: 'Horas',
      labelY: 'Vehiculos',
    };

    this.colorScale = [{ minValue: 0, maxValue: 900000, color: '#FF9901' }];
  }


  setGraphicSpeed(data: any): void {
    const sortedData = data
      // .map((e: any) => {
      //   return { time: ('0' + e.date).slice(12,17), value: e.count };
      // }).sort((a: any, b: any) => ('0' + a.date).slice(12,17).localeCompare(('0' + b.date).slice(12,17)));
      .reportVehicle.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.avgSpeed };
      })

    this.dataGraphicListSpeed = {
      data: sortedData,
      label: 'velocidad',
      labelX: 'Horas',
      labelY: 'Velocidad',
    };

    this.colorScaleSpeed = [{ minValue: 0, maxValue: 100000, color: '#FF9901' }];
  }

  getIcon(nivel: any){

    let strIcon = "";
    switch(nivel){
      case 'A':
        strIcon = 'icon-serva-panel';
        break;
      case 'B':
        strIcon = 'icon-servb-panel';
        break;
      case 'C':
        strIcon = 'icon-servc-panel';
        break;
      case 'D':
        strIcon = 'icon-servd-panel';
        break;
      case 'E':
        strIcon = 'icon-serve-panel';
        break;
      case 'F':
        strIcon = 'icon-servf-panel';
        break;

    }

    return strIcon;

  }
}
