<ng-container *ngIf="dataSource">
  <table class="full-width">
    <thead class="bg-gray text-white text-size-16 text-weight-600">
      <ng-container *ngFor="let header of headers">
        <ng-container *ngIf="!header.span || header.span > -1">
          <th class="p-0 text-weight-600" [attr.colspan]="header.span" [ngClass]="header.headerClass ?? ''">
            {{ header.label }}
          </th>
        </ng-container>
      </ng-container>
    </thead>
    <tbody class="text-gray text-size-14">
      <ng-container *ngFor="let item of dataSource | async; let index = index">
        <tr class="table-bg" (click)="handleClickRow(dispatcherRow, item, index)">
          <ng-container *ngFor="let header of headers">
            <td [attr.colspan]="header.span" class="p-0 text-weight-500 text-size-16 text-white"
              [ngClass]="header.cellClass ?? ''"
              (click)="handleClick(header.dispatcher, item, header.key, index, header)">
              <ng-container *ngIf="header.toRender; else plain">
                <sit-renderer [toRender]="
                    resolveRenderData(
                      header.toRender,
                      item[header.key + ''],
                      header.key + '',
                      item
                    )
                  ">
                </sit-renderer>
              </ng-container>
              <ng-template #plain>
                <ng-container *ngIf="header.key">
                  <ng-container *ngIf="header.valueFormatter">
                    {{ header.valueFormatter(item[header.key]) }}
                  </ng-container>
                  <ng-container *ngIf="!header.valueFormatter">
                    <ng-container *ngIf="header.label === 'Fecha'">
                      {{ item[header.key] | date: 'dd/MM/YYYY' }}
                    </ng-container>
                    <ng-container *ngIf="header.label === 'Hora'">
                      {{ item[header.key] | date: 'hh:mm a' }}
                    </ng-container>
                    <ng-container *ngIf="dataCellType.isNumber(item[header.key])">
                      <ng-container *ngIf="header.cellInteger; else cellDecimal">
                        {{ item[header.key] }}
                      </ng-container>
                      <ng-template #cellDecimal>
                        {{ item[header.key] | number: '1.2-2' }}
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="header.subKey">
                      {{ item[header.key][header.subKey] }}
                    </ng-container>

                    <ng-container *ngIf="
                      !(
                        header.label === 'Fecha' ||
                        header.label === 'Hora' ||
                        dataCellType.isNumber(item[header.key]) ||
                        header.subKey ||
                        header.label === 'Icono'
                      )
                    ">
                      <ng-container *ngIf="header.addIcon">
                        <sit-icon [icon]="item['icon']" [sizeClass]="'icon-size-28'"></sit-icon>
                        {{ item[header.key] }}
                      </ng-container>
                      <ng-container *ngIf="!header.addIcon">
                        {{ item[header.key] }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>