<!-- <ng-template> -->
<div style="width:550px">
  <!-- <mat-tab-group class="text-size-16 text-white text-weight-500 mb-25 text-md-size-16 test-label custom-tab-group"> -->

  <div class="d-flex full-width">
    <mat-tab-group>
      <mat-tab label="Programadas">
        <div class="custom-scrollbar" style="overflow-y: scroll; max-height:500px; width: 550px;">
          <div class="p-20-px" *ngFor="let item of listScheduledActivities">
            <div class="d-flex justify-start align-items-center mb-8">
              <sit-icon [icon]="Icon.calendar_outlined_white" [sizeClass]="'icon-size-28'">
              </sit-icon>
              <span class="ml-8 text-white">{{item.beginDate | date:'dd/MM/YYYY'}}</span>
            </div>
            <div class="card-container">
              <span class="mb-12 text-color-primary-1-dark text-weight-600"> {{item.objective}}</span>
              <span class="mb-12 text-blue"> {{item.corridorName}} - {{item.spot}}</span>
              <div class="container-insed-card mb-12">
                <span class="ml-8 text-white">{{item.activityName}}</span>
              </div>
              <div class="d-flex justify-start align-items-center text-white">
                <sit-icon [icon]="Icon.time_blue" [sizeClass]="'icon-size-28'">
                </sit-icon>
                <span class="ml-8 text-weight-600">{{item.beginDate | date:'hh:mm'}} ————— {{item.endDate | date:'hh:mm'}}</span>
                <span class="ml-8 index">+1</span>

              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      
      <mat-tab label="No programadas">
        <div class="custom-scrollbar" style="overflow-y: scroll; max-height:500px; width: 550px;">
          <div class="p-20-px" *ngFor="let item of listUnScheduledActivities">
            <div class="d-flex justify-start align-items-center mb-8 text-white">
              <sit-icon [icon]="Icon.calendar_outlined_white" [sizeClass]="'icon-size-28'">
              </sit-icon>
              <span class="ml-8"> {{item.incidentTime | date:'dd/MM/YYYY'}}</span>
              <sit-icon class="ml-32" [icon]="Icon.time" [sizeClass]="'icon-size-28'">
              </sit-icon>
              <span class="ml-8">{{item.incidentTime | date:'hh:mm a'}}</span>
            </div>
            <div class="card-container">
              <div class="d-flex fd-row justify-space-between">
                <span class="mb-12 text-blue"> {{item.address}} </span>
                <span class="text-color-primary-1-dark">{{item.statusName}}</span>
              </div>
              <div class="d-flex fd-row">
                <div class="insed-card-black mb-12">
                  <span class="text-white">{{item.className}}</span>
                  <span class="text-button-clean-filter">Clase</span>
                </div>
                <div class="insed-card-black mb-12 ml-16">
                  <span class="text-white">{{item.typeName}}</span>
                  <span class="text-button-clean-filter">Tipo</span>
                </div>
                <div class="insed-card-black mb-12 ml-16">
                  <span class="text-white">{{item.gravityName}}</span>
                  <span class="text-button-clean-filter">Prioridad</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- </mat-tab-group> -->
</div>
<!-- </ng-template> -->

<div class="btn-asign">
  <sit-button (click)="onClick()" [buttonProperties]="actionButtonProps" class="mb-05-em"></sit-button>
</div>