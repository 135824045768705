import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { searchFormModelMap } from '@official/pages/map-viewer/models/managament-panel/search-panel.model';
import { nameManagePanel } from '@official/pages/map-viewer/models/managament-panel/name-panel';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { BehaviorSubject, skip, filter, Subject, Subscription } from 'rxjs';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import { IGenericFormModel } from '@types-custom/models/ui/generic-form.model';
import { AbstractPanelManagementDataSource, filterStateDataSource } from '@types-custom/models/ui/paginator-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { TitlePanelFilterMap } from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { remapObjectToOneLevel } from '@ui-core/utils/functions/remap-object';

@Component({
  selector: 'search-manage-panel',
  templateUrl: './search-manage-panel.component.html',
  styleUrls: ['./search-manage-panel.component.scss'],
})
export class SearchManagePanelComponent implements OnInit, OnDestroy {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  @Input() filterDataSource: filterStateDataSource;

  subscription: Subscription;

  Icon = Icon;
  titlePanel = '';
  fb = new UntypedFormBuilder();
  searchFormGroup = this.fb.group({});
  searchFormProps: IGenericFormModel;

  actionDataForm$ = new Subject<any>();

  constructor(private modalService: ModalService) { }

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  sendButtonProps: IButtonModel = {
    label: 'BUSCAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'LIMPIAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  ngOnInit(): void {

    this.subscription = this.panelAction$
      .pipe(
        filter((panelAction) => panelAction === PanelManageActionsEnum.SEARCH)
      )
      .subscribe(this.loadFormFilter.bind(this));

    this.titlePanel =
      TitlePanelFilterMap[this.panelOnManagement] ??
      `FILTROS DE BÚSQUEDA DE ${nameManagePanel[this.panelOnManagement]}`;
    this.initGenericFormProps();
    this.initResultGridSubcription();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initGenericFormProps() {
    this.searchFormProps = {
      formGroup: this.searchFormGroup,
      formModel: [...searchFormModelMap[this.panelOnManagement]],
      serviceDataSource: this.serviceDataSource,
      actionDataForm: this.actionDataForm$,
      actionPanel$: this.panelAction$,
      panelOnManagement: this.panelOnManagement,
    };
  }

  initResultGridSubcription() {
    this.serviceDataSource.data$.pipe(skip(1)).subscribe((data: any[]) => {
      if (data.length === 0) this.handleEmptyResultSearch();
      this.onPanelManageAction(this.panelManageActionsEnum.GRID);
    });
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  handleSendForm() {
    const dataForm = remapObjectToOneLevel(this.searchFormGroup.getRawValue());
    this.filterDataSource.saveStateFilter(dataForm);
    this.serviceDataSource.searchFromFilters(dataForm);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleEmptyResultSearch() {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: {
          infoMessage: 'No se han encontrado registros para la busqueda.',
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
  }

  handleSuccess(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: 'El sensor se ha registrado con éxito' },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
  }

  handleConfirmation(message?: string): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: {
          infoMessage: `¿Está seguro que desea ${message ?? 'cancelar'}?`,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.modalService.confirmationModal(modal);
  }

  validateFormGroup(): void {
    this.searchFormGroup.markAllAsTouched();
  }

  handleClearFilter() {
    this.searchFormGroup.reset();
    this.filterDataSource.saveStateFilter(null);
    this.handleSendForm();
  }

  loadFormFilter() {
    this.searchFormProps?.formGroup.reset();
    if (this.filterDataSource.filterValue) {
      setTimeout(() => {
        this.actionDataForm$.next(this.filterDataSource.filterValue);
      });
    }
  }
}
