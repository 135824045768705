<div class="w-auto p-1">
    <div class="d-flex justify-space-between align-items-center h-32">
        <span class="subtitle-card text-color-white">
        </span>
        <a class="serch subtitle-card cursor-pointer clean-button" (click)="resetFilter()">
            Limpiar
        </a>
    </div>

    <div class="d-flex fd-col gap-16-px mb-24" style="width: 400px;">
    <span class="subtitle-section text-color-white text-size-18 text-weight-500 lh-20-px">
        Estado
    </span>

        <div class="d-flex fd-col">
            <ng-container
                    *ngTemplateOutlet="
            checkButtonFilter;
            context: {
            controlName: stateId,
            dataList: estadosList,
            controlId: 'stateId'
            }
        "></ng-container>
        </div>

    </div>

    <br>

    <div class=" mt-18 pr-16">
        <mat-form-field appearance="outline">
            <mat-label>Fecha y hora inicio*</mat-label>
            <input  matInput [(ngModel)]="defaultDateStart" [ngxMatDatetimePicker]="pickerDateStart" />
            <mat-datepicker-toggle matSuffix [for]="$any(pickerDateStart)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerDateStart [showSeconds]="true">
            </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fecha y hora final*</mat-label>
            <input  matInput [(ngModel)]="defaultDateEnd" [ngxMatDatetimePicker]="pickerDateEnd" />
            <mat-datepicker-toggle matSuffix [for]="$any(pickerDateEnd)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerDateEnd [showSeconds]="true">
            </ngx-mat-datetime-picker>
        </mat-form-field>
    </div>

    <div class="footer-buttons">
        <button class="button-apply" (click)="applyFilter()">APLICAR</button>
    </div>
</div>
<ng-template
    #checkButtonFilter
    let-control="controlName"
    let-dataList="dataList"
    let-controlId="controlId">


    <select multiple class="hidden-control" [formControl]="control">
        <ng-container *ngFor="let item of dataList; let i = index">
            <option [value]="item.value">{{ item.name }}</option>
        </ng-container>
    </select>

    <div class="d-inline-flex gap-10-px">

        <ng-container *ngFor="let item of dataList; let i = index">
            <div class="button-checkbox">
                <input id="btn-{{controlId}}-{{i}}" class="hidden-checkbox" type="checkbox" [value]="item.value" (change)="updateSelection(control, $event, item)"
                       [checked]="selectedCheck(control, item)" />
                <label for="btn-{{controlId}}-{{i}}" class="button-label text-size-16">{{ item.name }}</label>
            </div>
        </ng-container>
    </div>
</ng-template>
