import { AbstractMapviewerDataSource } from '@types-custom/models/ui/map-viewer-model';
import { BehaviorSubject, Observable, skip, Subject } from 'rxjs';
import { GeoApiService } from '@shared/services/geo-api-service/geo-api.service';
import { Inject, Injectable } from '@angular/core';
import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import { mapViewerRightPanelMap } from '@official/pages/map-viewer/models/right-panel-maps';
import { urlBuilder } from '@sdk/utils/url';
import {
  AbstractSdkDynamicFilterDataSource,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DynamicFiltersApiService
  implements AbstractMapviewerDataSource, AbstractSdkDynamicFilterDataSource
{
  readonly controlURLMap: { [key: string]: { [key: number]: string } } = {
    [MapLayers.Situations]: { 4: this.environment.situationsReportUrl },
  };

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private geoApiService: GeoApiService
  ) {
    this.geoApiService.setUrlParser(this.urlParser.bind(this));
    this.initWatchers();
  }

  private currentLayer = new BehaviorSubject<MapLayers | undefined>(undefined);
  currentLayer$ = this.currentLayer.asObservable();

  refreshSubject$ = new Subject<any>();
  selectedData = new BehaviorSubject<any>(undefined);

  getAPIList = this.geoApiService.getAPIList.bind(this.geoApiService);

  get filterList(): ISdkDynamicFilterModel<any, any>[] {
    const layer = this.getLayer();
    if (layer) {
      return mapViewerRightPanelMap[layer]?.filters ?? [];
    }
    return [];
  }

  get endpoints(): { [key: number]: any } {
    const layer = this.getLayer();
    if (layer) {
      return mapViewerRightPanelMap[layer]?.endpoints ?? {};
    }
    return {};
  }

  setLayer(layer: MapLayers): void {
    this.currentLayer.next(layer);
  }

  private getLayer(): MapLayers | undefined {
    return this.currentLayer.value;
  }

  fetchData(
    value: any,
    next: ISdkDynamicFilterModel<any, any>,
    index: number
  ): Observable<any> {
    const endpoints = this.endpoints;
    const basePath = this.getBasePath(index);
    const path = endpoints[index](basePath, value);
    return this.httpClient.get(path);
  }

  clearData(): void {
    // throw new Error('Method not implemented.');
  }

  private getBasePath(index: number): string {
    const basePath =
      this.controlURLMap[this.getLayer() ?? '']?.[index] ??
      this.environment.locationUrl;
    return basePath;
  }

  private initWatchers(): void {
    this.selectedData.pipe(skip(1)).subscribe((data) => {
      this.refreshSubject$.next(data);
    });
  }

  private urlParser(url: string): string {
    const layer = this.getLayer();
    if (layer) {
      const finalUrl = urlBuilder(this.selectedData)(url);
      return finalUrl;
    }
    return url;
  }
}
