<div class="modal-container">
  <!-- Modales que se muestran varios a la vez -->
  <ng-container *ngFor="let modal of modals; let i = index">
    <mat-card [ngClass]="{ 'active-modal': i === activeModalIndex }" class="modal-box p-0 montserrat-font border-radius-5 {{
        modal.render.data.properties.customClass
      }}" cdkDrag [cdkDragFreeDragPosition]="modal">
      <mat-card-title BottomSheetAR classContainer="modal-box"
        class="d-flex full-width bg-color-background-3 justify-end modal-header-height mb-0 border-radius-t-5"
        (mousedown)="modalSelect(i)">
        <section class="d-flex full-width full-height align-items-center cursor-move">
          <div cdkDragHandle class="flex-grow full-height d-flex fd-row">
            <div class="left-title-block bg-color-primary-1 border-radius-tl-5"></div>
            <ng-container *ngIf="modal.render.data.properties.icon">
              <div class="pl-1">
                <sit-icon [icon]="modal.render.data.properties.icon" alt="modal.render.data.properties.alt"
                  [sizeClass]="'icon-size-34'"></sit-icon>
              </div>
            </ng-container>
            <div
              class="d-flex align-items-center justify-center  flex-grow text-weight-400 text-md-size-22 text-white">
              <span class="text-size-22 lh-22">
                {{
                modal.render.data.properties.titleModal
                ? modal.render.data.properties.titleModal
                : modal.render.data.properties.title
                }}
              </span>
            </div>
          </div>
          <mat-icon (click)="deleteModal(i)" class="text-color-primary-1" alt="icono cerrar modal">close</mat-icon>
        </section>
      </mat-card-title>
      <mat-card-content class=" bg-color-background-1 border-radius-b-5">
        <section>
          <sit-renderer [toRender]="modal.render"></sit-renderer>
        </section>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <!-- Modal que se muestra en una instancia única y es de fondo blanco -->
  <ng-container *ngIf="uniqueModal$ | async as modal">
    <div *ngIf="modal" class="modal-overlay">
      <div class="modal" style="--banner-color: {{modal.modalClass}}">

        <!-- Modal Header -->
        <ng-container *ngIf="!modal.isLogginUserModal">
          <div
            class="modal-header active-modal d-flex full-width justify-end modal-header-height mb-0"
            [ngClass]="modal.headerBackgroundClass || 'bg-white'">
            <div class="d-flex full-width full-height align-items-center">
              <div class="flex-grow full-height d-flex fd-row">
                <!-- <div class="left-title-block bg-color-primary-1 border-radius-tl-5" [ngClass]="modal.modalClass"></div> -->
                <ng-container *ngIf="modal.icon">
                  <div class="pl-1">
                    <sit-icon [icon]="modal.icon" alt="modal-alt" [sizeClass]="'icon-size-34'"></sit-icon>
                  </div>
                </ng-container>
                <div class="d-flex align-items-center flex-grow text-weight-600 text-size-16 ml-16 text-md-size-18"
                  [ngClass]="modal.headerBackgroundClass ? 'text-color-white' : 'text-color-background-2'">
                  <h2>{{ modal.title }}</h2>
                </div>
              </div>
              <sit-icon-button (click)="closeUniqueModal()" [icon]="Icon.close_w" alt="Cerrar"
                [ngClass]="modal.headerBackgroundClass ? 'text-color-white' : 'text-color-background-2'">
              </sit-icon-button>
            </div>
          </div>
        </ng-container>

        <!-- Modal Body -->
        <div class="modal-body">
          <sit-renderer [toRender]="modal.toRender"></sit-renderer>
        </div>

        <!-- Modal Actions -->
        <div *ngIf="modal.confirm || modal.cancel" [ngClass]="modal.headerBackgroundClass || 'bg-white'" class="modal-actions align-self-center">
          <ng-container *ngIf="!modal?.hideCancelButton && modal.cancel">
            <sit-button (click)="dialogAction(modal.cancel)" [buttonProperties]="cancelButtonProps"></sit-button>
          </ng-container>
          <ng-container *ngIf="modal.confirm">
            <sit-button (click)="dialogAction(modal.confirm)" [buttonProperties]="confirmButtonProps"></sit-button>
          </ng-container>
        </div>

      </div>
    </div>
  </ng-container>

</div>