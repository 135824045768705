<mat-form-field class="textWhite" appearance="outline">
  <mat-select
    [placeholder]="label"
    name ="matSelect"
    [(ngModel)]="matSelectItem"
    (selectionChange)="onSelectionChange($event)"
  >
    <mat-option
      *ngFor="let item of _dataSource$ | async as items"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
