<ng-container *ngIf="type === 0">
    <div class="card special">
        <div class="title">
            {{data.title}}{{barLoader ? '%' : ''}}
        </div>
        <div class="percentage pb-3" *ngIf="barLoader">
            <div class="loader">
                <div class="bar" id="progressBar" [style.width.%]="this.data.title * 100"></div>
            </div>
        </div>
        <div class="subtitle">
            {{data.subtitle}}
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 1">
    <div class="card">
        <div class="title border-special">
            ID: {{data.id}}
        </div>
        <div class="content d-flex gap-10-px align-items-center">
            <sit-icon
                    [icon]="data.resourceTypeName === 'Moto' ? Icon.BikeModal :
                            data.resourceTypeName === 'PDA' ? Icon.PDAModal :
                            data.resourceTypeName === 'Patrulla' ? Icon.PatrolModal :
                            data.resourceTypeName === 'Agentes' ? Icon.PredictivePanelAgents :
                            data.resourceTypeName === 'Grúas' ? Icon.PredictivePanelCranes :
                            Icon.StationModal"
                    [sizeClass]="'icon-size-32'"
                    alt="icono identificador"
            ></sit-icon>
            <span class="resourceTypeName">{{data.resourceTypeName}}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type === 3">
    <div class="card KPI-Predictive">
        <div class="content d-grid align-items-center">
            <div class="d-flex gap-8-px content-KPI">
                <sit-icon
                        [icon]="
                            data.resourceTypeName === 'Agentes' ? Icon.PredictivePanelAgents :
                            data.resourceTypeName === 'Grúas' ? Icon.PredictivePanelCranes :
                            Icon.StationModal"
                        [sizeClass]="'icon-size-64'"
                        alt="icono identificador"
                ></sit-icon>
                <span class="resourceTypeName">{{data.TotalCount}}</span>
            </div>
        </div>
        <div class="subtitle">
            {{data.resourceTypeName}}
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type === 4">
    <div class="card KPI-PredictiveList">
        <div class="title">
            {{data.resourceTypeName}}
        </div>
        <div class="content d-grid align-items-center">
            <div class="d-flex gap-8-px content-KPI">
                <sit-icon
                        [icon]="
                            data.resourceTypeName === 'Agentes' ? Icon.PredictivePanelAgents :
                            data.resourceTypeName === 'Grúas' ? Icon.PredictivePanelCranes :
                            Icon.StationModal"
                        [sizeClass]="'icon-size-50'"
                        alt="icono identificador"
                ></sit-icon>
                <span class="resourceTypeName">{{data.TotalCount}}</span>
            </div>
        </div>
    </div>
</ng-container>
