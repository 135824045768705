import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import {
  IModalModel,
  IUniqueModalModel,
} from '@types-custom/models/ui/modal.model';

@Injectable()
export class ModalService {
  currentModal$ = new BehaviorSubject<IRendererModel | undefined>(undefined);
  uniqueModal$ = new BehaviorSubject<IUniqueModalModel | undefined>(undefined);
  cleanModals$ = new BehaviorSubject<IModalModel[]>([]);
  currentModals: IModalModel[] = [];

  addModal(toRender: IRendererModel) {
    this.currentModal$.next(toRender);
  }

  confirmationModal(data: IUniqueModalModel): void {
    this.uniqueModal$.next(data);
  }

  closeConfirmationModal(): void {
    this.uniqueModal$.next(undefined);
  }
  getCurrentConfirmationModal() {
    return this.uniqueModal$.getValue();
  }
}
