import {GeoAPIType, GeoAPITypeEnum} from '@shared/models/geo-api-type.model';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {filter, map, tap} from 'rxjs/operators';
import {forkJoin, MonoTypeOperatorFunction, Observable, of} from 'rxjs';

import {
  cameraFromAPIMapper,
  situationsFromAPIMapper,
  trafficFromAPIMapper,
  co2FromAPIMapper,
  trafficSpeedRangeFromAPIMapper,
  exodusAndReturnWazeFromAPIMapper,
  trafficConcordanceFromAPIMapper,
  bicyclePathFromAPIMapper,
  CoosFromAPIMapper,
  trafficLightFromAPIMapper,
  aforoFromAPIMapper,
  agentsFromAPIMapper,
  agentsHistoricalFromAPIMapper,
  trafficManagmentPlansFromAPIMapper,
  StopBusFromAPIMapper,
  ZonesFromAPIMapper,
  RoadRunFromAPIMapper,
  accidentFromApiMapper,
  agentsNumberFromAPIMapper,
  FieldResourcesAPIMapper,
  agentsNumbersFromAPIMapper,
  runtFromAPIMapper,
  RoadCorridorsFromAPIMapper,
  vehiclesFromAPIMapper,
  CoosLineFromAPIMapper,
  TrafficJamWazeFromAPIMapper,
  CoiFromAPIMapper,
  CoilineFromAPIMapper,
  WazeTrafficDataFromAPIMapper,
  AlertsWazeFromAPIMapper,
  IncidentsFromAPIMapper,
  cranesFromAPIMapper, IncidentsFieldResourcesAPIMapper,
  GrouendResourceHistoryAPIMapper,
  groundNumberFromAPIMapper,
  BikeFromAPIMapper,
  pmvAPIMapper,
  ResourcePredictiveModelAPIMapper,
  HumanResourceListAPIMapper,
  AlertsDuplicatesWazeFromAPIMapper, AirQualityPointsAPIMapper,

} from '@shared/utils/mappers/geo-api-map';
import {LayerPath} from '@shared/models/layer-path.model';
import {AbstractMapviewerDataSource} from '@types-custom/models/ui/map-viewer-model';
import {CameraModel} from '@shared/models/camera.model';
import {TrafficApiService} from '@shared/services/layer-service/traffic-api-service/traffic-api.service';
import {Co2Service} from '@shared/services/layer-service/co2-service/co2.service';
import {AforoService} from '@shared/services/layer-service/aforo-service/aforo.service';
import {AgentsService} from '@shared/services/layer-service/agents-service/agents.service';
import {IncidentsService} from '@shared/services/layer-service/incidents-service/incidents.service';
import {AccidentService} from '../layer-service/accident-service/accident.service';
import {separateCoordinates} from '@shared/utils/functions/separate-coordinates';
import {VehiclesService} from '@shared/services/layer-service/vehicles-service/vehicles.service';
import {IncidentsLocationsModel} from "@shared/models/LocationsIncidents.model";
import {CranesService} from '../layer-service/cranes-service/cranes.service';
import {FieldResourcesService} from "@shared/services/layer-service/field-resources/field-resources.service";
import {PredictiveService} from "@shared/services/predictive-service/predictive.service";


@Injectable()
export class GeoApiService implements AbstractMapviewerDataSource {
  private apiInfoTypeMap: {
    [key: string]: (
      listType: GeoAPITypeEnum,
      isAllDataLoaded?: boolean
    ) => Observable<any[]>;
  } = {
    [GeoAPITypeEnum.Camera]                             : this.getCameraList.bind(this),
    [GeoAPITypeEnum.Situation]                          : this.getSituationsList.bind(this),
    [GeoAPITypeEnum.Traffic]                            : this.getTrafficList.bind(this),
    [GeoAPITypeEnum.Blank]                              : this.getBlankList.bind(this),
    [GeoAPITypeEnum.Co2]                                : this.getCo2List.bind(this),
    [GeoAPITypeEnum.Aforo]                              : this.getAforoList.bind(this),
    [GeoAPITypeEnum.TrafficSpeedRange]                  : this.getTrafficSpeedRangeList.bind(this),
    [GeoAPITypeEnum.ExodusAndReturnWaze]                : this.getWazeData.bind(this),
    [GeoAPITypeEnum.TrafficConcordance]                 : this.getTrafficConcordanceList.bind(this),
    [GeoAPITypeEnum.TrafficConcordanceCorridors]        : this.getRoadCorridorsList.bind(this),
    [GeoAPITypeEnum.TrafficSpeedRangeCorridors]         : this.getTrafficSpeedRangeList.bind(this),
    [GeoAPITypeEnum.RoadCorridors]                      : this.getRoadCorridorsList.bind(this),
    [GeoAPITypeEnum.WazeDataCorridors]                  : this.getWazeData.bind(this),
    [GeoAPITypeEnum.WazeDataSpeedRange]                 : this.getWazeData.bind(this),
    [GeoAPITypeEnum.TrafficJamWaze]                     : this.getTrafficJamWaze.bind(this),
    [GeoAPITypeEnum.AlertsWaze]                         : this.getWazeAlerts.bind(this),
    [GeoAPITypeEnum.BicyclePath]                        : this.getBicyclePathList.bind(this),
    [GeoAPITypeEnum.CoosLine]                           : this.getCoosline.bind(this),
    [GeoAPITypeEnum.CoosPoint]                          : this.getCoosList.bind(this),
    [GeoAPITypeEnum.TrafficLight]                       : this.getTrafficLightList.bind(this),
    [GeoAPITypeEnum.Agents]                             : this.getAgentsList.bind(this),
    [GeoAPITypeEnum.FieldResourcesLocations]            : this.getFieldResources.bind(this),
    [GeoAPITypeEnum.AgentsHistorical]                   : this.getAgentsHistoricalList.bind(this),
    [GeoAPITypeEnum.CranesHistorical]                   : this.getCranesHistoricalList.bind(this),
    [GeoAPITypeEnum.AgentsNumber]                       : this.getAgentsNumberList.bind(this),
    [GeoAPITypeEnum.CranesNumber]                       : this.getCranesNumberList.bind(this),
    [GeoAPITypeEnum.Cranes]                             : this.getCranesList.bind(this),
    [GeoAPITypeEnum.TrafficManagmentPlans]              : this.getTrafficManagmentPathList.bind(this),
    [GeoAPITypeEnum.StopBus]                            : this.getStopBusPathList.bind(this),
    [GeoAPITypeEnum.Zones]                              : this.getZonesPathList.bind(this),
    [GeoAPITypeEnum.RoadRun]                            : this.getRoadRunPathList.bind(this),
    [GeoAPITypeEnum.Accident]                           : this.getAccidentsList.bind(this),
    [GeoAPITypeEnum.IncidentsLocations]                 : this.getLocationsIncidents.bind(this),
    [GeoAPITypeEnum.lastHours]                          : this.getLocationsIncidents.bind(this),
    [GeoAPITypeEnum.PmvConsulta]                        : this.getPmvList.bind(this),
    [GeoAPITypeEnum.PmvEditar]                          : this.getPmvList.bind(this),
    [GeoAPITypeEnum.RuntDatex]                          : this.getRuntDatexList.bind(this),
    [GeoAPITypeEnum.Coi]                                : this.getCoi.bind(this),
    [GeoAPITypeEnum.Coiline]                            : this.getCoiLine.bind(this),
    [GeoAPITypeEnum.Vehicles]                           : this.getVehiclesList.bind(this),
    [GeoAPITypeEnum.IncidentsHeatMap]                   : this.getIncidentsHeatMapList.bind(this),
    [GeoAPITypeEnum.IncidentsFieldResources]            : this.getIncidentsFieldResources.bind(this),
    [GeoAPITypeEnum.ResourcesPredictiveModelLocations]  : this.getPredictiveResourcesLocationsModel.bind(this),
    [GeoAPITypeEnum.Bikes]                              : this.getBikesPoints.bind(this),
    [GeoAPITypeEnum.GroundResourceLasthours]            : this.getResourceHistoricalList.bind(this),
    [GeoAPITypeEnum.GroundResourceNumber]               : this.getResourceHistoricalList.bind(this),
    [GeoAPITypeEnum.incidentAddResources]               : this.getResourceHumanLis.bind(this),
    [GeoAPITypeEnum.AlertsDuplicatesWaze]               : this.getWazeAlertsDuplicates.bind(this),

    // Air Quality
    [GeoAPITypeEnum.ResourcesAirQualityPoints]          : this.getAirQualityPoints.bind(this),
    };

  private cacheMap: { [key: string]: any[] } = {
    [GeoAPITypeEnum.Situation]                          : [],
    [GeoAPITypeEnum.Traffic]                            : [],
    [GeoAPITypeEnum.Camera]                             : [],
    [GeoAPITypeEnum.Blank]                              : [],
    [GeoAPITypeEnum.Co2]                                : [],
    [GeoAPITypeEnum.Aforo]                              : [],
    [GeoAPITypeEnum.TrafficSpeedRange]                  : [],
    [GeoAPITypeEnum.ExodusAndReturnWaze]                : [],
    [GeoAPITypeEnum.AlertsWaze]                         : [],
    [GeoAPITypeEnum.RoadCorridors]                      : [],
    [GeoAPITypeEnum.TrafficJamWaze]                     : [],
    [GeoAPITypeEnum.TrafficConcordance]                 : [],
    [GeoAPITypeEnum.TrafficConcordanceCorridors]        : [],
    [GeoAPITypeEnum.TrafficSpeedRangeCorridors]         : [],
    [GeoAPITypeEnum.BicyclePath]                        : [],
    [GeoAPITypeEnum.CoosLine]                           : [],
    [GeoAPITypeEnum.CoosPoint]                          : [],
    [GeoAPITypeEnum.TrafficLight]                       : [],
    [GeoAPITypeEnum.Agents]                             : [],
    [GeoAPITypeEnum.AgentsHistorical]                   : [],
    [GeoAPITypeEnum.CranesHistorical]                   : [],
    [GeoAPITypeEnum.AgentsNumber]                       : [],
    [GeoAPITypeEnum.Cranes]                             : [],
    [GeoAPITypeEnum.CranesNumber]                       : [],
    [GeoAPITypeEnum.TrafficManagmentPlans]              : [],
    [GeoAPITypeEnum.StopBus]                            : [],
    [GeoAPITypeEnum.Zones]                              : [],
    [GeoAPITypeEnum.RoadRun]                            : [],
    [GeoAPITypeEnum.Accident]                           : [],
    [GeoAPITypeEnum.IncidentsLocations]                 : [],
    [GeoAPITypeEnum.Pmv]                                : [],
    [GeoAPITypeEnum.PmvConsulta]                        : [],
    [GeoAPITypeEnum.PmvEditar]                          : [],
    [GeoAPITypeEnum.RuntDatex]                          : [],
    [GeoAPITypeEnum.Coi]                                : [],
    [GeoAPITypeEnum.Coiline]                            : [],
    [GeoAPITypeEnum.Vehicles]                           : [],
    [GeoAPITypeEnum.lastHours]                          : [],
    [GeoAPITypeEnum.IncidentsHeatMap]                   : [],
    [GeoAPITypeEnum.FieldResourcesLocations]            : [],
    [GeoAPITypeEnum.IncidentsFieldResources]            : [],
    [GeoAPITypeEnum.ResourcesPredictiveModelLocations]  : [],
    [GeoAPITypeEnum.Bikes]                              : [],
    [GeoAPITypeEnum.GroundResourceLasthours]            : [],
    [GeoAPITypeEnum.GroundResourceNumber]               : [],
    [GeoAPITypeEnum.incidentAddResources]               : [],
    [GeoAPITypeEnum.AlertsDuplicatesWaze]               : [],

    // Air Quality
    [GeoAPITypeEnum.ResourcesAirQualityPoints]          : [],
  };

  private cacheDataMap: {
    [key: string]: { totalPages: number; totalRecords: number } | undefined;
  } = {
    [GeoAPITypeEnum.Situation]                          : undefined,
    [GeoAPITypeEnum.Traffic]                            : undefined,
    [GeoAPITypeEnum.Camera]                             : undefined,
    [GeoAPITypeEnum.Co2]                                : undefined,
    [GeoAPITypeEnum.Aforo]                              : undefined,
    [GeoAPITypeEnum.TrafficSpeedRange]                  : undefined,
    [GeoAPITypeEnum.WazeDataCorridors]                  : undefined,
    [GeoAPITypeEnum.WazeDataSpeedRange]                 : undefined,
    [GeoAPITypeEnum.ExodusAndReturnWaze]                : undefined,
    [GeoAPITypeEnum.AlertsWaze]                         : undefined,
    [GeoAPITypeEnum.TrafficConcordance]                 : undefined,
    [GeoAPITypeEnum.TrafficConcordanceCorridors]        : undefined,
    [GeoAPITypeEnum.TrafficSpeedRangeCorridors]         : undefined,
    [GeoAPITypeEnum.RoadCorridors]                      : undefined,
    [GeoAPITypeEnum.TrafficJamWaze]                     : undefined,
    [GeoAPITypeEnum.BicyclePath]                        : undefined,
    [GeoAPITypeEnum.CoosLine]                           : undefined,
    [GeoAPITypeEnum.CoosPoint]                          : undefined,
    [GeoAPITypeEnum.TrafficLight]                       : undefined,
    [GeoAPITypeEnum.Agents]                             : undefined,
    [GeoAPITypeEnum.AgentsHistorical]                   : undefined,
    [GeoAPITypeEnum.CranesHistorical]                   : undefined,
    [GeoAPITypeEnum.AgentsNumber]                       : undefined,
    [GeoAPITypeEnum.Cranes]                             : undefined,
    [GeoAPITypeEnum.CranesNumber]                       : undefined,
    [GeoAPITypeEnum.TrafficManagmentPlans]              : undefined,
    [GeoAPITypeEnum.StopBus]                            : undefined,
    [GeoAPITypeEnum.Zones]                              : undefined,
    [GeoAPITypeEnum.RoadRun]                            : undefined,
    [GeoAPITypeEnum.Accident]                           : undefined,
    [GeoAPITypeEnum.Pmv]                                : undefined,
    [GeoAPITypeEnum.PmvConsulta]                        : undefined,
    [GeoAPITypeEnum.PmvEditar]                          : undefined,
    [GeoAPITypeEnum.RuntDatex]                          : undefined,
    [GeoAPITypeEnum.Coi]                                : undefined,
    [GeoAPITypeEnum.Coiline]                            : undefined,
    [GeoAPITypeEnum.IncidentsLocations]                 : undefined,
    [GeoAPITypeEnum.lastHours]                          : undefined,
    [GeoAPITypeEnum.IncidentsHeatMap]                   : undefined,
    [GeoAPITypeEnum.IncidentsFieldResources]            : undefined,
    [GeoAPITypeEnum.ResourcesPredictiveModelLocations]  : undefined,
    [GeoAPITypeEnum.Bikes]                              : undefined,
    [GeoAPITypeEnum.GroundResourceLasthours]            : undefined,
    [GeoAPITypeEnum.GroundResourceNumber]               : undefined,
    [GeoAPITypeEnum.incidentAddResources]               : undefined,
    [GeoAPITypeEnum.AlertsDuplicatesWaze]               : undefined,

    // Air Quality
    [GeoAPITypeEnum.ResourcesAirQualityPoints]          : undefined,
  };

  private cecheExcludedListsMap: { [key: string]: GeoAPITypeEnum } = {
    [GeoAPITypeEnum.TrafficSpeedRange]                  : GeoAPITypeEnum.TrafficSpeedRange,
    [GeoAPITypeEnum.WazeDataSpeedRange]                 : GeoAPITypeEnum.ExodusAndReturnWaze,
    [GeoAPITypeEnum.WazeDataCorridors]                  : GeoAPITypeEnum.ExodusAndReturnWaze,
    [GeoAPITypeEnum.ExodusAndReturnWaze]                : GeoAPITypeEnum.ExodusAndReturnWaze,
    [GeoAPITypeEnum.TrafficConcordance]                 : GeoAPITypeEnum.TrafficConcordance,
    [GeoAPITypeEnum.TrafficConcordanceCorridors]        : GeoAPITypeEnum.TrafficConcordanceCorridors,
    [GeoAPITypeEnum.TrafficSpeedRangeCorridors]         : GeoAPITypeEnum.TrafficSpeedRangeCorridors,
    [GeoAPITypeEnum.RoadCorridors]                      : GeoAPITypeEnum.RoadCorridors,
    [GeoAPITypeEnum.TrafficJamWaze]                     : GeoAPITypeEnum.TrafficJamWaze,
    [GeoAPITypeEnum.BicyclePath]                        : GeoAPITypeEnum.BicyclePath,
    [GeoAPITypeEnum.CoosLine]                           : GeoAPITypeEnum.CoosLine,
    [GeoAPITypeEnum.Co2]                                : GeoAPITypeEnum.Co2,
    [GeoAPITypeEnum.Aforo]                              : GeoAPITypeEnum.Aforo,
    [GeoAPITypeEnum.CoosPoint]                          : GeoAPITypeEnum.CoosPoint,
    [GeoAPITypeEnum.TrafficLight]                       : GeoAPITypeEnum.TrafficLight,
    [GeoAPITypeEnum.Agents]                             : GeoAPITypeEnum.Agents,
    [GeoAPITypeEnum.Cranes]                             : GeoAPITypeEnum.Cranes,
    [GeoAPITypeEnum.TrafficManagmentPlans]              : GeoAPITypeEnum.TrafficManagmentPlans,
    [GeoAPITypeEnum.StopBus]                            : GeoAPITypeEnum.StopBus,
    [GeoAPITypeEnum.AgentsHistorical]                   : GeoAPITypeEnum.AgentsHistorical,
    [GeoAPITypeEnum.CranesHistorical]                   : GeoAPITypeEnum.CranesHistorical,
    [GeoAPITypeEnum.AgentsNumber]                       : GeoAPITypeEnum.AgentsNumber,
    [GeoAPITypeEnum.CranesNumber]                       : GeoAPITypeEnum.AgentsNumber,
    [GeoAPITypeEnum.Zones]                              : GeoAPITypeEnum.Zones,
    [GeoAPITypeEnum.RoadRun]                            : GeoAPITypeEnum.RoadRun,
    [GeoAPITypeEnum.Accident]                           : GeoAPITypeEnum.Accident,
    [GeoAPITypeEnum.Pmv]                                : GeoAPITypeEnum.Pmv,
    [GeoAPITypeEnum.PmvConsulta]                        : GeoAPITypeEnum.PmvConsulta,
    [GeoAPITypeEnum.PmvEditar]                          : GeoAPITypeEnum.PmvEditar,
    [GeoAPITypeEnum.Situation]                          : GeoAPITypeEnum.Situation,
    [GeoAPITypeEnum.Coi]                                : GeoAPITypeEnum.Coi,
    [GeoAPITypeEnum.Coiline]                            : GeoAPITypeEnum.Coiline,
    [GeoAPITypeEnum.IncidentsLocations]                 : GeoAPITypeEnum.IncidentsLocations,
    [GeoAPITypeEnum.lastHours]                          : GeoAPITypeEnum.lastHours,
    [GeoAPITypeEnum.IncidentsHeatMap]                   : GeoAPITypeEnum.IncidentsHeatMap,
    [GeoAPITypeEnum.IncidentsFieldResources]            : GeoAPITypeEnum.IncidentsFieldResources,
    [GeoAPITypeEnum.ResourcesPredictiveModelLocations]  : GeoAPITypeEnum.ResourcesPredictiveModelLocations,
    [GeoAPITypeEnum.Bikes]                              : GeoAPITypeEnum.Bikes,
    [GeoAPITypeEnum.GroundResourceLasthours]            : GeoAPITypeEnum.GroundResourceLasthours,
    [GeoAPITypeEnum.GroundResourceNumber]               : GeoAPITypeEnum.GroundResourceNumber,
    [GeoAPITypeEnum.incidentAddResources]               : GeoAPITypeEnum.incidentAddResources,

    // Air Quality
    [GeoAPITypeEnum.ResourcesAirQualityPoints]          : GeoAPITypeEnum.ResourcesAirQualityPoints,
  };

  private urlParser!: any;

  private listFilter!: any;

  private listCranesFilter!: any;



  constructor(
    private trafficApiService   : TrafficApiService,
    private co2Service          : Co2Service,
    private aforoService        : AforoService,
    private agentsService       : AgentsService,
    private resourcesService    : FieldResourcesService,
    private cranesService       : CranesService,
    private httpClient          : HttpClient,
    private incidentsService    : IncidentsService,
    private accidentService     : AccidentService,
    private vehiclesService     : VehiclesService,
    private predictiveService   : PredictiveService,
    @Inject('environment') private environment: any
  ) {
    // It is added to validate the heat map, since due to endpoint processing time, it is not shown when starting the application
    this.getAgentsNumberComplete();
    this.getCranesNumberComplete();
  }

  private isCacheExcludedListType(listType: GeoAPITypeEnum): boolean {
    const isExcluded = !!this.cecheExcludedListsMap[listType];
    return isExcluded;
  }

  private get pageSize(): number {
    return this.environment.geoApiPageSize;
  }

  private parseUrl(url: string): string {
    const parser = this.urlParser;
    const parsedURL = parser?.(url) || url;
    return parsedURL;
  }

  private parseCaheUrl(url: string, listType: GeoAPITypeEnum): string {
    let parsedURL = `${url}`;
    const pageSize = this.pageSize;
    const loadedData = this.cacheMap[listType];
    const loadedDataSize = loadedData.length;
    const page = Math.trunc(loadedDataSize / pageSize);
    parsedURL = parsedURL.concat(
      `${url.includes('?') ? '&' : '?'}recordsPerPage=${pageSize}&page=${page}`
    );
    return parsedURL;
  }

  private setCachePipe(
    obsevable: Observable<any>,
    listType: GeoAPITypeEnum
  ): Observable<any> {
    return obsevable.pipe(
      map((response: any[]) => {
        const cadhedData = this.cacheMap[listType];
        this.cacheMap[listType] = [...cadhedData, ...response];
        return this.cacheMap[listType];
      })
    );
  }

  private setCacheData(
    listType: GeoAPITypeEnum,
    isAllDataLoaded: boolean
  ): MonoTypeOperatorFunction<unknown> {
    if (isAllDataLoaded) {
      return tap();
    }
    return tap((response: any) => {
      this.cacheDataMap[listType] = {
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
      };
    });
  }

  private isNewDataAvailable(listType: GeoAPITypeEnum): boolean {
    const dataSize = this.cacheMap[listType].length;
    const pageSize = this.pageSize;
    if (dataSize) {
      const page = Math.trunc(dataSize / pageSize);
      const totalPages = this.cacheDataMap[listType]?.totalPages;

      if (totalPages && page > 0 && page < totalPages) {
        const limitPerPage = pageSize * page;
        if (limitPerPage - dataSize < 0) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  }

  getSituationsList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
    bodyPayload?: any
  ): Observable<any[]> {
    return this.incidentsService.postIncidentssGeoJSON(bodyPayload).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return response.features.map((el: any) => situationsFromAPIMapper(el));
      })
    );
  }

  private getTrafficList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    // TODO: Implement mock for traffic and mapper
    // const path = LayerPath.TRAFFIC;
    // let url = `${this.environment.traffic}`;
    // let url = this.parseUrl(`${this.environment.datexUrl}/${path}/`);
    let url = this.parseUrl(`${this.environment.trafficUrl}/`);
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return (
      this.httpClient
        .get<any>(url)
        // .get<any>('assets/mock/traffic_2.json')
        .pipe(
          this.setCacheData(listType, isAllDataLoaded),
          map((response: any) => {
            return (
              response?.features
                // ?.filter((item: any, index: number) => index < 200)
                .map((el: any) => trafficFromAPIMapper(el))
            );
          })
        )
    );
  }

  private getCameraList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<CameraModel[]> {
    const path = LayerPath.CAMERAS;
    let url = this.parseUrl(
      `${this.environment.camerasUrl}/camera/format-geo-json?cameraType=CCTV&state=ACTIVO`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    // return this.httpClient.get<any>('assets/mock/cameras2.json').pipe(
    return this.httpClient.get<any>(url).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return (
          response?.features
            // ?.filter((item: any, index: number) => index < 200)
            .map((el: any) => cameraFromAPIMapper(el))
        );
      })
    );
  }

  private getCo2List(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    const path = LayerPath.CO2;
    let url = this.parseUrl(`${this.environment.layersUrl}/${path}/`);
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return forkJoin({
      geoCo2: this.co2Service.getCo2GeoJson(),
      measurementsCo2: this.co2Service.getMeasurementCo2(),
    }).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        response = this.co2Service.getCo2Mapper(response);
        return response.map((el: any) => co2FromAPIMapper(el));
      })
    );
  }

  private getAforoList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return forkJoin({
      geoAforo: this.aforoService.getLayerAforoGeoJson(),
      measurementsAforo: this.aforoService.getPanelAforoMeasurements(),
    }).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        response = this.aforoService.getAforoMapper(response);
        return response.features.map((el: any) => aforoFromAPIMapper(el));
      })
    );
  }

  private getTrafficSpeedRangeList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let type = !(listType.includes("SpeedRangeCorridors")) ? null : false;
    let url = this.parseUrl(
      `${this.environment.trafficUrl}/traffic-geom/format-geo-json?tidCorridorIsNull=${type}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return forkJoin({
      geoTraffic: this.trafficApiService.getGeoTraffic(type),
    }).pipe(
      //return this.httpClient.get<any>(url)
      //.pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        response = this.trafficApiService.getTrafficMapper(response);
        return (
          response?.features
            // ?.filter((item: any, index: number) => index < 200)
            .map((el: any) => trafficSpeedRangeFromAPIMapper(el))
        );
      })
    );
  }

  private getRoadCorridorsList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.trafficUrl}/traffic-geom/format-geo-json`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return this.trafficApiService.getRoadCorredors().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return (
          response?.features
            ?.filter((item: any) => item?.properties?.speed !== null)
            .map((el: any) => RoadCorridorsFromAPIMapper(el, listType))
        );
      })
    )

  }

  private getWazeData(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
  ): Observable<any[]> {
    let layer = listType.includes('WazeDataCorridors') ? 'Corridor'
      : listType.includes('WazeDataSpeedRange') ? 'SpeedRange' : 'ExodusReturn'
    let url = this.parseUrl(
      `${this.environment.WazeUrl}/api/v1/waze/format-geo-json?recordsPerPage=0&page=0&layer=${layer}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return this.trafficApiService.getDataTrafficWaze(layer).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return (
          response?.features
            // ?.filter((item: any, index: number) => index < 200)
            .map((el: any) => WazeTrafficDataFromAPIMapper(el))
        );
      })
    )
  }

  private getTrafficJamWaze(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.trafficJamWaze}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return this.trafficApiService.getTrafficJamWaze().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        response.features = response.features.filter((e: any) => {
          return e.properties.level > 2;
        })
        return (
          response?.features
            // ?.filter((item: any, index: number) => index < 200)
            .map((el: any) => TrafficJamWazeFromAPIMapper(el))
        );
      })
    )
  }

  private getWazeAlerts(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.WazeUrl}/api/v1/waze/alerts/format-geo-json?recordsPerPage=0&page=0`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return this.trafficApiService.getWazeAlerts().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return (
          response?.features
            // ?.filter((item: any, index: number) => index < 200)
            .map((el: any) => AlertsWazeFromAPIMapper(el))
        );
      })
    )
  }

  private getTrafficConcordanceList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let type = !(listType.includes("TrafficConcordanceCorridors")) ? null : false;
    let url = this.parseUrl(
      `${this.environment.trafficUrl}/traffic-geom/format-geo-json?tidCorridorIsNull=${type}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return forkJoin({
      geoTraffic: this.trafficApiService.getGeoTraffic(type)
    }).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        response = this.trafficApiService.getTrafficMapper(response);
        if(type === false){
          return (
              response?.features
                  // ?.filter((item: any, index: number) => index < 200)
                  .map((el: any) => RoadCorridorsFromAPIMapper(el,listType))
          );
        }else {
          return (
              response?.features
                  // ?.filter((item: any, index: number) => index < 200)
                  .map((el: any) => trafficFromAPIMapper(el))
          );
        }
      })
    );
  }

  private getBicyclePathList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.bicicletasUrl}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      //.get<any>('assets/mock/bicyclepath.json')
      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => bicyclePathFromAPIMapper(el))
        )
      );
  }
  private getCoosline(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.coosLineUrl}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      //.get<any>('assets/mock/bicyclepath.json')
      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => CoosLineFromAPIMapper(el))
        )
      );
  }

  private getCoosList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.coosUrl}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      //.get<any>('assets/mock/coosP.json')
      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => CoosFromAPIMapper(el))
        )
      );
  }

  private getTrafficLightList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.semaforos}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      //.get<any>('assets/mock/trafficlight.json')
      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => trafficLightFromAPIMapper(el))
        )
      );
  }

  private getCoi(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.coiPointUrl}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      //.get<any>('assets/mock/coi.json')
      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => CoiFromAPIMapper(el))
        )
      );
  }

  private getCoiLine(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.coiLineUrl}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      //.get<any>('assets/mock/coiline.json')

      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => CoilineFromAPIMapper(el))
        )
      );
  }

  private getAgentsList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return this.agentsService.getAgentsGeoJSON().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return response.features.map((el: any) => agentsFromAPIMapper(el));
      })
    );
  }

  getIncidentsFieldResources(
      listType: GeoAPITypeEnum,
      isAllDataLoaded = false,
      queryParams? :string
  ): Observable<any[]> {
    return this.resourcesService.getIncidentsResourcesFromGeoJSON(new Date(),queryParams).pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) => {
          return response.features.map((el: any) => IncidentsFieldResourcesAPIMapper(el));
        })
    );
  }

  private getBikesPoints(
      listType: GeoAPITypeEnum,
      isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
        `${this.environment.BikesUrl}bikes/format-geo-json`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
        .get<any>(url)
        //.get<any>('assets/mock/coosP.json')
        .pipe(
            this.setCacheData(listType, isAllDataLoaded),
            map((response: any) =>
                response?.features.map((el: any) => BikeFromAPIMapper(el))
            )
        );
  }


  getPredictiveResourcesLocationsModel(
      listType: GeoAPITypeEnum,
      isAllDataLoaded = false,
      queryParams? :string
  ): Observable<any[]> {
    return this.predictiveService.getResourcesPredictiveLocations(queryParams).pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) => {
          //console.log('response predic geo' , response)
          return response.features.map((el: any) => ResourcePredictiveModelAPIMapper(el));
        })
    );
  }

  private getFieldResources(
      listType: GeoAPITypeEnum,
      isAllDataLoaded = false
  ): Observable<any[]> {
    localStorage.removeItem('centipede')
    localStorage.removeItem('guide-group')
    localStorage.removeItem('cycle-school')
    localStorage.setItem('all-resources','true')
    localStorage.removeItem('filter-resources')
    return this.resourcesService.getResourcesFromGeoJSON().pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) => {
          return response.features.map((el: any) => FieldResourcesAPIMapper(el));
        })
    );
  }


  getResourceHistoricalList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
    queryFilters?: any
  ): Observable<any[]> {
    return this.resourcesService.getHistoryResourcesFromGeoJSON(queryFilters).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.resourcesService.getHistoryGroundMapper(response);
      })
    );
  }

  getResourceHumanLis(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
    queryFilters?:string
  ): Observable<any[]> {
    return this.resourcesService.getHistoryResourcesFromGeoJSON(queryFilters).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return response.features.map((el: any) => HumanResourceListAPIMapper(el));
      })
    );
  }


  getResourceNumberList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
    queryFilters?: {[key: string]: any}
  ): Observable<any[]> {
    return this.resourcesService.getNumberResourcesFromGeoJSON(queryFilters).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.resourcesService.getNumberGroundMapper(response);
      })
    );
  }



  private getVehiclesList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return this.vehiclesService.getVehiclesGeoJSON().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return response.features.map((el: any) => vehiclesFromAPIMapper(el));
        // return response.features;
      })
    );
  }

  private getCranesList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return this.cranesService.getCranesGeoJSON().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return response.features.map((el: any) => cranesFromAPIMapper(el));
      })
    );
  }


  private getStopBusPathList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.paradaBusUrl}`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient
      .get<any>(url)
      // get<any>('assets/mock/stopBus.json')
      .pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) =>
          response?.features.map((el: any) => StopBusFromAPIMapper(el))
        )
      );
  }



  getIncidentsHeatMapList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
    queryFilters?: string
  ): Observable<any[]> {
    return this.incidentsService.getIncidentsHeatMap(queryFilters).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.incidentsService.getIncidentsMapper(response);
      })
    );
  }

  private getAgentsHistoricalList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return this.agentsService.getAgentsHistoricalGeoJSON().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.agentsService.getAgentsMapper(response);
      })
    );
  }

  getAgentsNumberComplete(): Observable<any> {
    this.agentsService.getAgentsNumberGeoJSON().pipe().subscribe((agents: any) => {
      this.agentsService.getAgentsLocation().pipe().subscribe((list: any) => {
        list.features.forEach((item: any) => {
          item.properties.Qty = (agents.filter((e: any) => {
            return e["locCode"] == item.properties['LocCodigo'];
          })).length;
          item.properties.density = Number(item.properties.Qty * 1000000 / item.properties.LocArea);
        });

        this.listFilter = list;
      })
    });

    return of(this.listFilter);
  }

  getCranesNumberComplete(): Observable<any> {
    this.cranesService.getCranesNumberGeoJSON().pipe().subscribe((cranes:any)=>{
      this.cranesService.getAgentsLocation().pipe().subscribe((list:any)=>{
        
        list.features.forEach((item:any) => {
          item.properties.Qty = (cranes.features.filter((e:any) => {
            return e.properties["locality"] == item.properties['LocNombre'];
          })).length  ;
          item.properties.density = Number(item.properties.Qty*1000000 / item.properties.LocArea );
        });

        this.listCranesFilter = list;
      })
    });

    return of(this.listCranesFilter);
  }

  private getCranesHistoricalList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return this.cranesService.getCranesHistoricalGeoJSON().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.cranesService.getCranesMapper(response);
      })
    );
  }

  private getAgentsNumberList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): any {
    // setTimeout(() => {this.getAgentsNumberComplete()},4000);
    // return this.agentsService.getAgentsGeoJSON().pipe(
    //   this.setCacheData(listType, isAllDataLoaded),
    //   map((response: any) => {
    //     return response.features.map((el: any) => agentsNumberFromAPIMapper(el));
    //   })
    // );
    // return this.agentsService.getAgentsNumberGeoJSON().pipe(
    //   this.setCacheData(listType, isAllDataLoaded),
    //   map((response: any) => {
    //     return this.agentsService.getAgentsNumberMapper(response);
    //   })
    // );
    // return this.agentsService.getAgentsHistoricalGeoJSON().pipe(
    //   this.setCacheData(listType, isAllDataLoaded),
    //   map((response: any) => {
    //     console.log(this.agentsService.getAgentsNumberMapper(response));
    //     return this.agentsService.getAgentsMapper(response);
    //   })
    // );

    // return listFilter;
    // return this.listFilter.features.map((el: any) => agentsNumbersFromAPIMapper(el));
    // return this.agentsService.getAgentsNumberMapper(this.listFilter);
    // return this.listFilter.features.map((el: any) => agentsNumberFromAPIMapper(el));

    return this.getAgentsNumberComplete().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.agentsService.getAgentsNumberMapper(response);
        // return response.features.map((el: any) => agentsNumberFromAPIMapper(el));
      })
    );
  }

  private getCranesNumberList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): any {

    return this.getCranesNumberComplete().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return this.cranesService.getCranesNumberMapper(response);
        // return response.features.map((el: any) => agentsNumberFromAPIMapper(el));
      })
    );
  }

  private getZonesPathList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.baseStaticLayerUrl}/23/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient.get<any>(url).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) =>
        response?.features.map((el: any) => ZonesFromAPIMapper(el))
      )
    );
  }

  private getRoadRunPathList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.baseStaticLayerUrl}/19/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient.get<any>(url).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) =>
        response?.features.map((el: any) => RoadRunFromAPIMapper(el))
      )
    );
  }

  private getAccidentsList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    return this.accidentService.getAccidentGeoJson().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => this.accidentService.getAccidentMapper(response))
    );
  }

  public getLocationsIncidents(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false,
    queryParams? :string
  ): Observable<any[]> {
    return this.incidentsService.getIncidentsLocationsGeoJSON(new Date(),queryParams).pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) => {
          return response.features.map((el: any) => IncidentsFromAPIMapper(el));
        })
  );
}


  private getBlankList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<CameraModel[]> {
    return this.httpClient
      .get<any>('assets/mock/blank.json')
      .pipe(
        map((response: any) =>
          response?.features.map((el: any) => cameraFromAPIMapper(el))
        )
      );
  }

  private clearCache(listType: GeoAPITypeEnum): void {
    this.cacheMap[listType] = [];
  }

  setUrlParser(fn: any): void {
    this.urlParser = fn;
  }

  getAPIList(
    listType: GeoAPITypeEnum,
    isClearingCache: boolean,
    isAllDataLoaded: boolean
  ): Observable<GeoAPIType[]> {

    if (this.isCacheExcludedListType(listType)) {
      // TODO: SINITT-800: Photo detection loads two endpoints, that's why it's necessary to set isClearingCache as true.
      isClearingCache = true;
    }
    const fn = this.apiInfoTypeMap[listType];
    if (isClearingCache) {
      this.clearCache(listType);
      return fn(listType, true);
    }

    const isNewData = this.isNewDataAvailable(listType);
    if (isNewData) {
      return this.setCachePipe(fn(listType, isAllDataLoaded), listType);
    } else {
      return of(this.cacheMap[listType]);
    }
  }

  private getRuntDatexList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    const path = LayerPath.CAMERAS;
    let url = this.parseUrl(
      `${this.environment.camerasUrl}/camera/format-geo-json?cameraType=LPR&state=ACTIVO`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }

    return this.httpClient.get<any>(url).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        const filteredGeoJson = separateCoordinates(response);
        return filteredGeoJson.features.map((el: any) => runtFromAPIMapper(el));
      })
    );
  }

  private getPmvList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    const path = LayerPath.PMV;
    const url = `${this.environment.vmsUrl}/vms/format-geo-json?status=1`;
    return (
      this.httpClient
        //.get<any>(url)
        .get<any>(url)
        .pipe(
          this.setCacheData(listType, isAllDataLoaded),
          map((response: any) =>
            response?.features.map((el: any) => pmvAPIMapper(el))
          )
        )
    );
  }

  private getTrafficManagmentPathList(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.baseStaticLayerUrl}/21/query?where=1%3D1+And+VIGENCIA+%3D+%27VIGENTE%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.httpClient.get<any>(url).pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) =>
        response?.features.map((el: any) =>
          trafficManagmentPlansFromAPIMapper(el)
        )
      )
    );
  }

  private getWazeAlertsDuplicates(
    listType: GeoAPITypeEnum,
    isAllDataLoaded = false
  ): Observable<any[]> {
    let url = this.parseUrl(
      `${this.environment.WazeUrl}/api/v1/waze/alerts/duplicates/format-geo-json`
    );
    if (!isAllDataLoaded) {
      url = this.parseCaheUrl(url, listType);
    }
    return this.trafficApiService.getWazeAlertsDuplicates().pipe(
      this.setCacheData(listType, isAllDataLoaded),
      map((response: any) => {
        return (
          response?.features
            .map((el: any) => AlertsDuplicatesWazeFromAPIMapper(el))
        );
      })
    )
  }


  // Air Quality
  private getAirQualityPoints(
      listType: GeoAPITypeEnum,
      isAllDataLoaded = false
  ): Observable<any[]> {
    // let url = this.parseUrl(
    //     `${this.environment.CO2Url}/api/v1/waze/alerts/duplicates/format-geo-json`
    // );
    // if (!isAllDataLoaded) {
    //   url = this.parseCaheUrl(url, listType);
    // }
    return this.co2Service.getAirQualityGeoJson().pipe(
        this.setCacheData(listType, isAllDataLoaded),
        map((response: any) => {
          return (
              response?.features
                  .map((el: any) => AirQualityPointsAPIMapper(el))
          );
        })
    )
  }
}
