import { BehaviorSubject } from 'rxjs';

/**
 * Return a parse function that will build an URL from a Behavior Subject Value
 * @param filters Behavior subject that contains the filters object
 * @returns URL parse function that inject query params if the filter object contains values to be included
 * eg:
 *  filtersObj => { a: 'first', b: 2, c: undefined, d: null }
 *  inputURL => 'http://myUrl.com'
 *  final output => 'http://myUrl.com?a=first&b=2'
 */
export const urlBuilder =
  (filters: BehaviorSubject<any>) =>
  (url: string): string => {
    const _filters = filters.value;
    if (_filters) {
      const keys = Object.keys(_filters);
      const queryParams = keys.reduce((pre: string[], key: string) => {
        const value = _filters[key];
        if (value !== null && value !== undefined) {
          pre.push(`${key}=${value}`);
        }
        return pre;
      }, []);
      const queryParamsStr = queryParams.length
        ? `?${queryParams.join('&')}`
        : '';
      const newUrl = `${url}${queryParamsStr}`;
      return newUrl;
    }
    return url;
  };
