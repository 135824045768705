import { Component, Input, OnInit } from '@angular/core';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { IModalTrafficModel } from '@types-custom/models/ui/modal-traffic-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import {
  adjustConcordanceRotation,
  adjustSpeedometerRotation,
} from '@ui-core/utils/functions/get-indicator-rotation';
import {Icon} from "@types-custom/models/ui/icon-model";

/*
 ** Modal Traffic
 ** Render a modal that displays information about traffic.
 */
@Component({
  selector: 'modal-traffic',
  templateUrl: './modal-traffic.component.html',
  styleUrls: ['./modal-traffic.component.scss'],
})
export class ModalTrafficComponent implements OnInit {
  @Input() properties: IModalTrafficModel | undefined;
  dataHistory: [] = [];
  dataHistoryTravel: [] = [];

  dataGraphicList: ITimeLineGraphicModel;
  colorScale = [
    { minValue: 0, maxValue: 10, color: 'red' },
    { minValue: 10, maxValue: 20, color: 'yellow' },
    { minValue: 20, maxValue: 1000, color: 'green' },
  ];

  statusIndicator: any = {};
  measure : string = '';

  actMinTimeIndicator = '-';
  actSecTimeIndicator = '-';
  expMinTimeIndicator = '-';
  expSecTimeIndicator = '-';
  concordanceRotation = 0;
  speedometerRotation = 0;
  infoIndicatorMap: { [key: number]: any } = {
    1: {
      now: 'Lento',
      classNow: 'icon-filter-red',
    },
    2: {
      now: 'Medio',
      classNow: 'icon-filter-yellow',
    },
    3: {
      now: 'Bueno',
      classNow: 'icon-filter-green',
    },
  };

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer(true);
    setTimeout(() => {
      if ("trafficDataSource" in this.properties) {
        this.properties.trafficDataSource
            .getTrafficSpeedRange(this.properties.id)
            .subscribe((res) => {
              this.dataHistory = res;
            });
      }
    });
    this.measure = (Number.parseInt(this.properties.distance) < 1000) ? 'm' : 'Km';
    this.properties.distance = (Number.parseInt(this.properties.distance) < 1000) ?
        this.properties.distance :
        ((Number.parseInt(this.properties.distance) / 1000).toFixed(2)).toString();
    this.setIndicator(this.properties);
    this.setTimeIndicator(this.properties);
    this.properties.trafficDataSource
      .getLastMeasurements(this.properties.id)
      .subscribe((res) => {
        this.setGraphicData(res);
      });
  }

  setIndicator(prop: IModalTrafficModel): void {
    const keyIndicator = this.validationSpeed(+prop.speed);
    this.statusIndicator = this.infoIndicatorMap[keyIndicator];

    const concordance = Math.round(+this.properties.concordance);
    const speed = (+this.properties.speed).toFixed(2);

    this.properties.speed =
        Number.parseInt(speed) > 100 ? (100 - Number.parseInt(speed)).toString() : speed.toString();

    this.properties.concordance =
      concordance > 100
        ? (100 - concordance).toString()
        : concordance.toString();

    this.concordanceRotation = adjustConcordanceRotation(
      +this.properties.concordance
    );

    this.speedometerRotation = adjustSpeedometerRotation(
      +this.properties.speed
    );
  }

  setTimeIndicator(prop: IModalTrafficModel): void {
    const timeAct = this.convertTime(prop.elapsedTime);
    const timeExp = this.convertTime(prop.expectedValue);

    this.actMinTimeIndicator = timeAct.minute;
    this.actSecTimeIndicator = timeAct.second;
    this.expMinTimeIndicator = timeExp.minute;
    this.expSecTimeIndicator = timeExp.second;
  }

  validationSpeed(speed: number): number {
    if (speed < 10) {
      return 1;
    }

    return speed < 20 ? 2 : 3;
  }

  convertTime(seconds: number): any {
    const hour = Math.floor(seconds / 3600);
    const outHour = hour < 10 ? '0' + hour : hour;

    const minute = Math.floor((seconds / 60) % 60);

    const outMinute = minute < 10 ? '0' + minute : minute;

    const second = Math.floor(seconds % 60);
    const outSecond = second < 10 ? '0' + second : second;

    return {
      hour: outHour,
      minute: outMinute,
      second: outSecond,
    };
  }

  setGraphicData(info: any): void {
    const dataGraphic = info
      .map((graph: any) => {
        return {
          time: graph.time,
          value: graph.speed,
        };
      })
      .sort((x: any, y: any) => x.time - y.time);

    this.dataGraphicList = {
      data: dataGraphic,
      label: 'Velocidad',
    };
  }

  protected readonly Icon = Icon;
}
