<div class="form-container pr-16 pl-16 mb-32 mt-32">

    <h2 class="text-white"> Crear parámetro</h2>
    <form [formGroup]="createFormGroup" class="login-form">
        <mat-form-field class="textWhite height-auto" appearance="outline">
            <mat-label class="text-white main-label">Parámetro</mat-label>
            <select matNativeControl formControlName="incidentTypeCode">
                <option *ngFor="let item of parameters; let i=index" [value]="item.value">{{item.label}}</option>
            </select>
            <mat-error>{{ 'Campo obligatorio' }}</mat-error>
            <!-- <mat-option *ngFor="let item of items" [value]="item.value">{{ item.label }}</mat-option> -->
        </mat-form-field>
        <mat-form-field class="textWhite height-auto" appearance="outline">
            <mat-label class="text-white main-label">Nombre del parámetro</mat-label>
            <input matInput formControlName="name" />
            <mat-error>{{ 'Campo obligatorio' }}</mat-error>
        </mat-form-field>
    </form>
    <div class="d-flex fd-row justify-end">
        <sit-button (click)="handleCancel()" [buttonProperties]="cancelButtonProps" class="width-49"></sit-button>
        <sit-button (click)="createFormGroup.valid ? handleSendForm() : validateFormGroup()"
            [disabled]="!createFormGroup.valid" [buttonProperties]="sendButtonProps"
            class="width-49 ml-16"></sit-button>
    </div>
</div>