import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsCranesModel {
  id: string;
  speed: string;
  location: string;
  time: string;
  status: string;
  inactive: string;
  typeField: string;
  title: string;
  markerPopupText: string;
  icon: unknown;
}

export class CranesModel extends MarkerModelBase {
  static className = 'CranesModel';
  classProperties: IPropsCranesModel;

  constructor(props: IPropsCranesModel, geometry: IGeometryModel) {
    super(geometry, CranesModel.className);
    this.classProperties = props;
  }
}
