import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsAgentsHistoricalModel {
  id: string;
  name: string;
  updatedDate: string;
  agentType: string;
  title: string;
  markerPopupText: string;
  time: string;
  icon: unknown;
  localidad?: string;
  dev_id?: string;
}

export class AgentsHistoricalModel extends MarkerModelBase {
  static className = 'AgentsHistoricalModel';
  classProperties: IPropsAgentsHistoricalModel;

  constructor(props: IPropsAgentsHistoricalModel, geometry: IGeometryModel) {
    super(geometry, AgentsHistoricalModel.className);
    this.classProperties = props;
  }
}
