<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
    <br>
    <div
      class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
      <div class="left-title-block bg-color-primary-1"></div>
      <sit-icon [icon]="Icon.whistle_agent_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
        alt="silbato agente icono título indicadores"></sit-icon>
      <h3 class="text-white m-0 text-size-18 text-weight-500">
        General
      </h3>
    </div>
    <div class="d-flex fd-row justify-space-around align-items-center">
      <div class="d-flex fd-col justify-center align-items-center text-white">
        <sit-icon [icon]="Icon.agents_panel" [sizeClass]="'icon-size-78'" class="mr-1 ml-16"
          alt="silbato agente icono para total agentes"></sit-icon>
        <span style="color: #6B84FF;">{{ totalAgents }}</span>
        <label>Total Agentes</label>
      </div>
      <div class="d-flex fd-col justify-center align-items-center text-white">
        <sit-icon [icon]="Icon.active_agents" [sizeClass]="'icon-size-78'" class="mr-1 ml-16"
          alt="gorra agente icono para total policías de tránsito"></sit-icon>
        <span style="color: #6B84FF;">{{ totalAgentsActive }}</span>
        <label>Activos</label>
      </div>
      <div class="d-flex fd-col justify-center align-items-center text-white">
        <sit-icon [icon]="Icon.tacometro" [sizeClass]="'icon-size-78'" class="mr-1 ml-16"
          alt="gorra agente icono para total agentes de tránsito"></sit-icon>
        <span style="color: #6B84FF;">{{ ((totalAgentsActive*100)/totalAgents) | number : '1.2-2' }} %</span>
        <label>Cumplimiento</label>
      </div>
    </div>
  
    <div
      class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
      <div class="left-title-block bg-color-primary-1"></div>
      <sit-icon [icon]="Icon.agents_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
        alt="reloj icono título gráfica historico de agentes"></sit-icon>
      <h3 class="text-white m-0 text-size-18 text-weight-500">
        Agentes
      </h3>
    </div>
    <div class="d-flex fd-row chart-line-container">
      <sit-chart-line [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width">
      </sit-chart-line>
    </div>
    <div
      class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
      <div class="left-title-block bg-color-primary-1"></div>
      <sit-icon [icon]="Icon.layer_traffic_green" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
        alt="gorra agentes icono título lista de agentes"></sit-icon>
      <h3 class="text-white m-0 text-size-18 text-weight-500">
        Localidades
      </h3>
    </div>
    <div>
      <table class="table-agents">
        <thead class="text-white text-size-12">
          <th>NOMBRE</th>
          <th>AGENTES</th>
          <th>ELEMENTOS KM2</th>
        </thead>
        <tbody class="p-0 text-weight-400 work-sans-font text-size-16 text-white">
          <ng-container *ngFor="let item of locations">
            <tr (click)="openModal(item.properties)">
              <td>{{ item.properties.LocNombre }}</td>
              <td>{{ item.properties.Qty }}</td>
              <td>{{ item.properties.density | number : '1.2-2'}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  