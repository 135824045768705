<div class="mat-input mb-1 mt-1 borders">
  <!-- <div class="cleanButton">
    <a class="mb-1 text-button-clean-filter cursor-pointer"
    (click)="resetFilter()">Limpiar</a>
  </div> -->
    <mat-form-field class="text-white" appearance="outline">
      <mat-label class="text-white">Grúas</mat-label>
  
      <input
        type="text"
        matInput
        [formControl]="crane"
        [matAutocomplete]="auto"
      />
      <sit-icon-button
        matSuffix
        *ngIf="crane.value"
        (click)="crane.reset(''); filterCrane('')"
        [icon]="Icon.close_w"
        [colorClass]="'text-white'"
        [sizeClass]="'icon-size-20'"
        alt="Icono cerrar para limpiar campo"
      ></sit-icon-button>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="filterCrane($event.option.value)"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  