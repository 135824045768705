import { Injectable } from '@angular/core';
import { MapboxInstanceService } from '@sdk/services/mapbox-instance/mapbox-instance.service';
import { Map } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MapboxLayoutService {
  private mapboxStyleLayout = new BehaviorSubject<string>('');
  mapboxStyleLayout$ = this.mapboxStyleLayout.asObservable();

  private mapboxZoom = new BehaviorSubject<number>(Number.NaN);
  mapboxZoom$ = this.mapboxZoom.asObservable();

  private mapboxCenterLayout = new BehaviorSubject<any>(undefined);
  mapboxCenterLayout$ = this.mapboxCenterLayout.asObservable();

  private get map(): Map {
    if (this.mapboxInstanceService.mapInstance) {
      return this.mapboxInstanceService.mapInstance;
    }
    throw new Error(`Mapbox instance hasn't been created.`);
  }

  constructor(private mapboxInstanceService: MapboxInstanceService) {
    this.addListeners();
  }

  private addListeners(): void {
    this.mapboxInstanceService.map$.subscribe(
      this.handleMapInstance.bind(this)
    );
  }

  private handleMapInstance(mapInstance: Map | undefined): void {
    if (mapInstance) {
      this.mapboxCenterLayout.next(mapInstance.getStyle());
      this.mapboxZoom.next(mapInstance.getZoom());
      this.mapboxCenterLayout.next(mapInstance.getCenter());
    }
  }

  setStyleLayout(style: string): void {
    this.map.setStyle(style);
    this.mapboxStyleLayout.next(style);
  }

  setZoomLayout(zoom: number): void {
    this.map.setZoom(zoom);
    this.mapboxZoom.next(zoom);
  }

  setCenterLayout(lng: number, lat: number): void {
    const center = { lng, lat };
    this.map.setCenter(center);
    this.mapboxCenterLayout.next(center);
  }
}
