import { Component, Input } from '@angular/core';
import { ICardCamerasModel } from '@types-custom/models/ui/card-cameras-model';

/*
** Card Cameras
** Render a title modal that displays a camera title.
*/
@Component({
  selector: 'sit-card-cameras',
  templateUrl: './card-cameras.component.html',
  styleUrls: ['./card-cameras.component.scss'],
})
export class CardCamerasComponent {
  @Input() card: ICardCamerasModel | undefined;

  openModal(): void {
    this.card?.cardMapLink?.markerDispatcher.next(
      this.card.cardMapLink.markerModel
    );
  }
}
