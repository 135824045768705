import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
** Modal no-info
** Render a No Information Modal that displays a message when no information found.
*/
@Component({
  selector: 'citizen-modal-no-info',
  templateUrl: './modal-no-info.component.html',
  styleUrls: ['./modal-no-info.component.scss'],
})
export class ModalNoInfoComponent implements OnInit {
  @Input() infoMessage = 'No se ha encontrado la información solicitada';
  infoIcon = Icon.info;

  maxWidth = 640;
  modalWidth = "auto";
  bottom = "70px";
  left = "10px";
  right = "10px";

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    if (this.maxWidth < document.documentElement.clientWidth) {
      return;
    }

    const elementStyle = (<HTMLStyleElement>document.querySelector(".modal-box")).style;

    elementStyle.width = this.modalWidth;
    elementStyle.bottom = this.bottom;
    elementStyle.left = this.left;
    elementStyle.right = this.right;
  }

}
