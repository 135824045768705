<div
  class="modal-box-msg d-flex fd-row f-wrap flex-sm-nowrap align-items-center justify-center justify-md-content-start bg-color-background-3"
>
  <div class="text-modal-succsess d-flex fd-row gap-8-px align-items-center">
    <sit-icon
      [icon]="icon"
      alt="icono confirmación exitosa"
      [sizeClass]="'icon-size-58 icon-sm-size-46'"
      class="text-green icon-user-check d-flex"
    ></sit-icon>
    <section class="d-flex fd-col gap-8-px">
      <span class="text-white text-size-20 text-weight-600">
        {{ titleMessage }}
      </span>
      <span class="text-white text-size-14 text-sm-size-14 text-weight-500">{{
        infoMessage
      }}</span>
    </section>
  </div>
</div>
