<div class="card">
    <div class="title">
        {{card.localityName}}
    </div>
    <div class="d-flex gap-10-px justify-center">
        <sit-kpiresources [KPIData]="this.card.Centipede"   [CardWith]="'dcrem2'"></sit-kpiresources>
        <sit-kpiresources [KPIData]="this.card.cycleSchool" [CardWith]="'dcrem2'"></sit-kpiresources>
        <sit-kpiresources [KPIData]="this.card.GroupGuide"  [CardWith]="'dcrem2'"></sit-kpiresources>
    </div>
</div>
