<ng-container>
    <div class="full-width full-height icon-alert">
        <img [attr.src]="'/assets/icons/alert-red.svg'"
             alt="Incidentes" class="icon-incidents" [ngClass]="
        properties.labelsClass !== undefined ? 'title-icon' : ''"/></div>
        <div class="incidents-labels text-white" [ngClass]="
        properties.labelsClass !== undefined ? properties.labelsClass : ''
      ">
      <span class="label-unit text-md-size-14">
          {{ properties.value }}
      </span>
            <ng-container *ngIf="
          properties.descriptionLabel !== '' &&
          properties.descriptionLabel !== undefined
        ">
                <label class="label-description text-md-size-14">{{
                    properties.descriptionLabel
                    }}</label>
            </ng-container>
        </div>
</ng-container>
