import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsTrafficLightModel {
  direccion: string;
  localidad: string;
  operacion: string;
  tipo_inter: string;
  infra_cicl: string;
  codigo: string;
  title: string;
  markerPopupText: string;
  icon: Icon;
}

export class TrafficLightModel extends MarkerModelBase {
  static className = 'TrafficLightModel';
  classProperties: IPropsTrafficLightModel;

  constructor(
    props: IPropsTrafficLightModel,
    geometry: IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, TrafficLightModel.className, lat, lng);
    this.classProperties = props;
  }
}
