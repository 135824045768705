import { Component, Input, OnInit } from '@angular/core';
import { IncidentsService } from '@shared/services/layer-service/incidents-service/incidents.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
  IClassesAttributes,
} from '@types-custom/models/ui/button-model';
import { GeoApiService } from '@shared/services/geo-api-service/geo-api.service';
import { GeoAPITypeEnum } from '@shared/models/geo-api-type.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { SituationTypeFilterEnum,
  SituationPriorityFilterEnum,
} from '@shared/models/situations.model';
// import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';
import { GeometryTypeEnum, MarkerModelBase } from '@types-custom/models/business/marker.model';
import { IncidentConfigurationService } from '@official/services/data-grid-service/incident-configuration-service/incident-configuration.service';
import { ILocationOption } from '@types-custom/models/business/location.model';

@Component({
  selector: "sit-map-filter-location-incidents",
  templateUrl: "./map-filter-location-incidents.component.html",
  styleUrls: ["./map-filter-location-incidents.component.scss"],
  providers: [
    IncidentConfigurationService,
  ],
})
export class MapFilterLocationIncidentsComponent implements OnInit {
  @Input() properties: any;
  situationTypeFilterEnum = SituationTypeFilterEnum;
  situationPriorityFilterEnum = SituationPriorityFilterEnum;

  incidentTypeControl = new FormControl('');
  priorityControl = new FormControl('');
  stateId = new FormControl('');
  locationNameControl = new FormControl('');

  locations: any[] = [];

  estadosList: any[] = [];

  filterCategoryOptions:string[];
  defaultDateStart = (new Date(new Date().setHours(0,0,0,0))).toISOString();
  defaultDateEnd = (new Date().toISOString());

  Icon=Icon;
  incidentsTypes: [] = [];
  incidentForm: FormGroup;

  constructor(
      private incidentsService: IncidentsService,
      private geoApiService: GeoApiService,
      private datePipe:DatePipe,
      public incidentConfigurationService: IncidentConfigurationService
  ) {}

  ngOnInit() {
    this.init();
  }

  init(){
    // LISTA DE ESTADOS
    // this.incidentConfigurationService.getListTypes("Estado").subscribe({
    //   next: (data: any[]) => {
    //     this.estadosList = data;
    //   },
    // });

    this.incidentConfigurationService.getListTypes("Estado").subscribe({
      next: (data: any[]) => {
        this.estadosList = data
            .filter((item: any) => item)
            .map(
                (item: any) =>
                    ({
                      name: item.name,
                      value: item.id,
                    } as ILocationOption)
            );
      },
    });
  }

  get startDate() {
    return this.incidentForm.get('dateInit');
  }

  get endDate() {
    return this.incidentForm.get('dateEnd');
  }

  displayFn(alert: any): string {
    return alert && alert.LocNombre ? alert.LocNombre : '';
  }

  reloadData(response: any) {
    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const IncidentsLocationsLayer = response;

    IncidentsLocationsLayer.forEach((mark: any) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type:  (mark as any).geometry.type,
          coordinates: (mark as any).geometry.coordinates,
        },
        className : (mark as any).className,

      };
      points.markers.push(mark);
      points.dataFeatures.push(feature as never);
    });

    console.log(this.properties.layer);
    console.log(points.dataFeatures);

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer,
      filter: undefined,
      source: points.dataFeatures
    });

    document.querySelector<HTMLElement>('#closeFilter').click();
    setTimeout(() => {
      document.querySelector<HTMLElement>('#closeFilter').parentElement.classList.add('applied-filter');
      document.querySelector('.marker-active-situations-layer')?.classList.remove('marker-active-situations-layer');
    }, 10);
  }

  applyFilter() {
    const initialFilterValue: {[key: string]: any} = {};
    const url = this.generateURL();
    this.geoApiService.getLocationsIncidents(GeoAPITypeEnum.IncidentsLocations, true, url)
        .subscribe({
          next: this.reloadData.bind(this),
    });
  }

  private generateURL() {
    let url = this.defaultDateStart ? `beginDate=${new Date(this.defaultDateStart).toISOString().replace('Z','')}` : '';
    url += this.defaultDateEnd? `&endDate=${new Date(this.defaultDateEnd).toISOString().replace('Z','')}` : ''
    // @ts-ignore
    // url += this.locationNameControl.value ? `&incidentLocationName=${this.locationNameControl.value['LocNombre']}` : '';
    if(this.stateId.value) {
      // @ts-ignore
      let data : any[] = this.stateId.value
      data.forEach((item : any) => {
        url += `&stateId=${item}`
      } )
    }
    // url += '&hours=1'

    return url;
  }

  updateSelection(control: AbstractControl, event: any, item: any) {
    const selectedItemsControl = control;
    const selectedItems = selectedItemsControl?.value || [];
    if (event.target.checked) {
      selectedItems.push(item.value);
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: AbstractControl, item: any): boolean {
    return control.value.includes(item.value);
  }

  resetFilter(){
    this.geoApiService.getLocationsIncidents(GeoAPITypeEnum.IncidentsLocations, true)
        .subscribe({
          next: this.reloadData.bind(this),
    });
  }
}
