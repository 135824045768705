<div class="modal-co2 w-auto h-auto d-flex fd-col text-weight-600">
  <div class="grid-container content-indicadores fd-row row">
    <div class="d-flex justify-center text-weight-400 direccion">
      <span class="text-color-white">{{ properties?.direccion }}</span>
    </div>
  </div>
  <div class="content-indicadores d-flex fd-row row center">
    <div
      class="width-obsr-cont content-indicator d-flex ustify-content-center fd-col p-05 text-color-white bg-color-background-1 center"
    >
      <div>
        <span>TIPO DE CIERRE</span>
      </div>
      <div class="content-icon icon-width">
        <sit-icon
          [icon]="Icon.closures"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de tipo"
        ></sit-icon>
      </div>
      <div class="d-flex justify-center text-weight-400">
        <span>{{ properties?.type }}</span>
      </div>
    </div>
    <div
      class="width-obsr-cont content-indicator d-flex fd-col p-05 text-color-white bg-color-background-1 center"
    >
      <div>
        <span>EMPRESA</span>
      </div>
      <div class="content-icon icon-width">
        <sit-icon
          [icon]="Icon.check_w"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de empresa"
        ></sit-icon>
      </div>
      <div class="d-flex justify-center text-weight-400">
        <span>{{ properties?.empresacon }}</span>
      </div>
    </div>
    <div
      class="width-obsr-cont content-indicator d-flex fd-col p-05 text-color-white bg-color-background-1 center"
    >
      <div>
        <span>CONTRATISTA</span>
      </div>
      <div class="content-icon icon-width">
        <sit-icon
          [icon]="Icon.check_w"
          [sizeClass]="'icon-size-46 icon-sm-size-28'"
          alt="icono de contratista"
        ></sit-icon>
      </div>
      <div class="d-flex justify-center text-weight-400">
        <span>{{ properties?.contratista }}</span>
      </div>
    </div>
  </div>
  <div class="grid-container content-indicadores fd-row row">
    <div class="width-obsr bg-color-background-observ">
      <div>
        <span class="color-observaciones">OBSERVACIONES:</span>
      </div>
      <div class="cont-obs">
        <span class="text-color-white text-res">{{
          properties?.observacion
        }}</span>
      </div>
    </div>
  </div>
</div>
