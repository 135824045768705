import { Component, Input, OnInit } from '@angular/core';
import { IModalVehicleModel } from '@types-custom/models/ui/modal-vehicle-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { VehiclesService } from '@shared/services/layer-service/vehicles-service/vehicles.service';
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from '@types-custom/models/ui/button-model';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { AgentsService } from '@shared/services/layer-service/agents-service/agents.service';

/*
 ** Modal Vehicles
 ** Render a modal that displays information about Vehicles.
 */

@Component({
  selector: 'modal-vehicle',
  templateUrl: './modal-vehicle.component.html',
  styleUrls: ['./modal-vehicle.component.scss'],
})
export class ModalVehiculesComponent implements OnInit{
  @Input() properties: IModalVehicleModel | undefined;
  Icon = Icon;

  _divid: string; 
  _top: number;
  _left: number;
  id: string;
  adress: string;
  flow: number;
  speed: string;
  orientations: any[];
  lanes: any[] = [];
  orientation: string = '';
  strFilter = "";
  strFilterType = "vehiculosFlow";
  strTitle = "Conteo de vehiculos";
  strIcon = "icon-serva";
  strcolor = "#CCFFCE";

  isGraphicFlow!:boolean;
  isGraphicSpeed!:boolean;

  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];

  dataGraphicListSpeed: ITimeLineGraphicModel;
  colorScaleSpeed: any = [{}];

  color = "#6B84FF";

  constructor(public vehicleService: VehiclesService,private serviceDataSource: AgentsService
  ) { }

  ngOnInit(): void {
    //console.log(this.properties);

    this.vehicleService.getInfoBySiteId(this.properties["id"]).subscribe((res: any) => {
      this.properties = res;
      //console.log(this.properties);

      this.setDataToLocalEnviroment();
      this.isGraphicFlow = true;
      this.setGraphicFlowVehicles(this.properties);
      this.setGraphicSpeedVehicles(this.properties);
    });
  }

  ngAfterViewInit(){
    
  }

  fluxButtonProps: IButtonModel = {
    label: 'Flujo',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  speedButtonProps: IButtonModel = {
    label: 'Velocidad',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white_primary,
    },
  };

  OnFilter(FilterType:any){
    this.strFilterType = FilterType;
    if (FilterType == "vehiculosFlow"){
      this.strTitle = "Conteo de vehiculos ";
      this.isGraphicFlow = true;
      this.isGraphicSpeed = false;
    }
    else{
      this.strTitle = "Velocidad Media ";
      this.isGraphicSpeed = true;
      this.isGraphicFlow = false;
    }
  }

  getIcon(nivel:any){

    let strIcon = "";
    switch(nivel){
      case 'A':
        strIcon = 'icon-serva';
        break;
      case 'B':
        strIcon = 'icon-servb';
        break;
      case 'C':
        strIcon = 'icon-servc';
        break;
      case 'D':
        strIcon = 'icon-servd';
        break;
      case 'E':
        strIcon = 'icon-serve';
        break;
      case 'F':
        strIcon = 'icon-servf';
        break;

    }

    return strIcon;

  }

  ChangeVista(data:any, orientation:any){
    //console.log(this.properties);
    if(data){
      this.flow = data.flow;
      this.speed = (data.speed).toFixed(2);
      this.orientation = data.laneId; 
      this.strFilter = "lane/" + this.id +"/"+ data.laneId;
      this.strIcon = this.getIcon(data.serviceLevel);
      this.strcolor = this.setColorLevel(data.serviceLevel);
    
      this.setGraphicFlowVehiclesLane(this.properties,data.laneId);
      this.setGraphicSpeedVehiclesLane(this.properties,data.laneId);
    }
    else {

      if (orientation == "" ){
        //console.log(this.orientation);
        //console.log(this.lanes);
        let objFlow = 0;
        this.lanes.forEach(function(a){objFlow +=  a.flow;});
        this.flow = objFlow;
        
        let objSpeed = 0;
        this.lanes.forEach(function(a){objSpeed += a.speed;});
        this.speed = (objSpeed/this.lanes.length).toFixed(2);
        this.orientation = '';
        this.strFilter = this.id 
        this.strIcon = this.getIcon(this.properties["serviceLevel"]);
        this.strcolor = this.setColorLevel(this.properties["serviceLevel"]);

        this.setGraphicFlowVehicles(this.properties);
        this.setGraphicSpeedVehicles(this.properties);
      }
      else {
        let objFlow = 0;
        this.lanes.forEach(function(a){objFlow += ((a.orientation == orientation)? a.flow: 0 );});
        this.flow = objFlow;
        
        let qty = 0;
        let objSpeed = 0;
        this.lanes.forEach(function(a){objSpeed += ((a.orientation == orientation)? a.speed: 0); qty += ((a.orientation == orientation)? 1: 0);});
        this.speed = (objSpeed/qty).toFixed(2);
        this.orientation = orientation;
        this.strFilter = this.id+"/"+ orientation;

        let level_of_serviceVAVG = 0;
        qty = 0;
        this.lanes.forEach(function(a){level_of_serviceVAVG += ((a.orientation == orientation)? a.level_of_serviceVal: 0); qty += ((a.orientation == orientation)? 1: 0);});
        level_of_serviceVAVG = level_of_serviceVAVG/qty;

        // this.strIcon = this.getIcon(items_nivel[0].letter);
        // this.strcolor = items_nivel[0].color;

        this.vehicleService.getNivel().pipe().subscribe((levels:any) =>{
            
          let items_nivel = levels.filter((e:any) => e.start_range <  level_of_serviceVAVG && e.end_range >  level_of_serviceVAVG)
          //console.log(items_nivel);
          if(items_nivel.length > 0){
            this.strIcon = this.getIcon(items_nivel[0].letter);
            this.strcolor = items_nivel[0].color;             
          }
          else {
            this.strIcon = this.getIcon(levels[0].letter);
            this.strcolor = levels[0].color;            
          }

        });

        this.setGraphicFlowVehiclesOrientation(this.properties,orientation);
        this.setGraphicSpeedVehiclesOrientation(this.properties,orientation);
         
      }
      
    }
    
    
  }

  setColorLevel(level?:string){
    let color;
    if(level=="A"){
      color = "#CCFFCE";
    }else if(level=="B"){
      color = "#00C479";
    }else if(level=="C"){
      color = "#FDFF9E";
    }else if(level=="D"){
      color = "#FECC9B";
    }else if(level=="E"){
      color = "#D78313";
    }else{
      color = "#E45024";
    }
    return color;
  }

  setDataToLocalEnviroment(){
    //console.log(this.properties);
    this.id = this.properties["siteId"]
    this.adress = this.properties["address"]
    this.strFilter = this.id ;

    this.strIcon = this.getIcon(this.properties["serviceLevel"]);
    this.strcolor = this.setColorLevel(this.properties["serviceLevel"]);

    // this.adress = this.data["name"]
    // this.adress = this.data["name"]
    if(this.properties["detailSite"]){

      this.lanes = ((typeof(this.properties["detailSite"]) == "string")? JSON.parse(this.properties["detailSite"]):this.properties["detailSite"]);
      this.orientations =  [...new Set( this.lanes.map(x => x.orientation) )]; 

      let objFlow = 0;
      this.lanes.forEach(function(a){objFlow += a.flow;});
      this.flow = objFlow;
      
      let objSpeed = 0;
      this.lanes.forEach(function(a){objSpeed += a.speed;});
      this.speed = (objSpeed/this.lanes.length).toFixed(2);
    }
    else {
      this.flow = 0;
      this.speed = "0";
    }    

  }

  setGraphicFlowVehicles(data: any): void {
    //console.log(data);
    const sortedData = data.reportVehicle.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.countTotal };
      })
    
    //console.log(sortedData);

    this.dataGraphicList = {
      data: sortedData,
      label: 'vehiculos',
      labelX: 'Horas',
      labelY: 'Vehiculos',
    };

    this.colorScale = [{ minValue: 0, maxValue: 900000, color: '#FF9901' }];
  }

  setGraphicSpeedVehicles(data: any): void {
    const sortedData = data.reportVehicle.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.avgSpeed };
      })

    this.dataGraphicListSpeed = {
      data: sortedData,
      label: 'velocidad',
      labelX: 'Horas',
      labelY: 'Velocidad',
    };

    this.colorScaleSpeed = [{ minValue: 0, maxValue: 100000, color: '#FF9901' }];
  }

  setGraphicFlowVehiclesLane(data: any,laneId:any): void {
    //console.log(data.reportVehicleAll);
    //console.log(laneId);
    let dataNew = data.reportVehicleAll.filter((item:any)=> item.laneId == laneId);
    //console.log(dataNew);
    const sortedData = dataNew.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.countTotal };
    })
    
    //console.log(sortedData);

    this.dataGraphicList = {
      data: sortedData,
      label: 'vehiculos',
      labelX: 'Horas',
      labelY: 'Vehiculos',
    };

    this.colorScale = [{ minValue: 0, maxValue: 900000, color: '#FF9901' }];
  }

  setGraphicSpeedVehiclesLane(data: any, laneId:any): void {
    //console.log(data.reportVehicleAll);
    //console.log(laneId);
    let dataNew = data.reportVehicleAll.filter((item:any)=> item.laneId == laneId);
    //console.log(dataNew);
    const sortedData = dataNew.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.avgSpeed };
      })

    this.dataGraphicListSpeed = {
      data: sortedData,
      label: 'velocidad',
      labelX: 'Horas',
      labelY: 'Velocidad',
    };

    this.colorScaleSpeed = [{ minValue: 0, maxValue: 100000, color: '#FF9901' }];
  }

  setGraphicFlowVehiclesOrientation(data: any,orientationId:any): void {
    //console.log(data.reportVehicleAll);
    //console.log(orientationId);
    let dataNew = data.reportVehicleAll.filter((item:any)=> item.orientation == orientationId);
    //console.log(dataNew);
    const sortedData = dataNew.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.countTotal };
    })
    
    //console.log(sortedData);

    this.dataGraphicList = {
      data: sortedData,
      label: 'vehiculos',
      labelX: 'Horas',
      labelY: 'Vehiculos',
    };

    this.colorScale = [{ minValue: 0, maxValue: 900000, color: '#FF9901' }];
  }

  setGraphicSpeedVehiclesOrientation(data: any, orientationId:any): void {
    console.log(data.reportVehicleAll);
    console.log(orientationId);
    let dataNew = data.reportVehicleAll.filter((item:any)=> item.orientation == orientationId);
    console.log(dataNew);
    const sortedData = dataNew.map((e: any) => {
        return { time: (e.hour + ':00'), value: e.avgSpeed };
      })

    this.dataGraphicListSpeed = {
      data: sortedData,
      label: 'velocidad',
      labelX: 'Horas',
      labelY: 'Velocidad',
    };

    this.colorScaleSpeed = [{ minValue: 0, maxValue: 100000, color: '#FF9901' }];
  }
}
