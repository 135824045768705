export const adjustConcordanceRotation = (
  rotationPercentage: number,
  minDegree: number = 0
): number => {
  const ranges = [
    { min: -51, max: -41, start: minDegree, end: 8 },
    { min: -40, max: -31, start: 9, end: 22 },
    { min: -30, max: -21, start: 23, end: 39 },
    { min: -20, max: -11, start: 57, end: 73 },
    { min: -10, max: 10, start: 74, end: 109 },
    { min: 11, max: 20, start: 110, end: 123 },
    { min: 21, max: 30, start: 124, end: 139 },
    { min: 31, max: 40, start: 140, end: 155 },
    { min: 41, max: 50, start: 156, end: 171 },
    { min: 51, max: Number.MAX_SAFE_INTEGER, start: 172, end: 186 },
  ];

  for (const range of ranges) {
    if (rotationPercentage >= range.min && rotationPercentage <= range.max) {
      return (
        ((rotationPercentage - range.min) / (range.max - range.min)) *
          (range.end - range.start) +
        range.start
      );
    }
  }
  return minDegree;
};

/*
* Blue: 0 - 35
* Light Green : 36 - 70
* Green : 71 - 95
* Yellow: 96 - 123
* Red : 124 - 150
* */

export const adjustSpeedometerRotation = (
  rotationPercentage: number
): number => {
  const ranges = [
    { min: 0, max: 20, degrees: 1.75 * rotationPercentage },
    { min: 21, max: 30, degrees: 3.4 * rotationPercentage - 32 },
    { min: 31, max: 40, degrees: 2.5 * rotationPercentage - 1 },
    { min: 41, max: 50, degrees: 3 * rotationPercentage - 27 },
    { min: 51, max: 60, degrees: 2.48 * rotationPercentage },
    { min: 61, max: Number.MAX_SAFE_INTEGER, degrees: 150 },
  ];

  const range = ranges.find(
    (r) => rotationPercentage >= r.min && rotationPercentage <= r.max
  );
  return range ? range.degrees : 0;
};
