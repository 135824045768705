import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "sit-listpredictive-resources",
  templateUrl: "./listpredictive-resources.component.html",
  styleUrls: ["./listpredictive-resources.component.scss"],
})
export class ListpredictiveResourcesComponent implements OnInit{
  @Input() Data : any

  KPIAgents = {
    resourceTypeName  : "Agentes",
    TotalCount        : 0
  }

  KPICranes = {
    resourceTypeName  : "Grúas",
    TotalCount        : 0
  }

  ngOnInit() {
    this.KPIAgents.TotalCount = this.Data.policeCount
    this.KPICranes.TotalCount = this.Data.craneCount
  }

}
