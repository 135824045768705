<div class="grid-container text-white">
    <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <sit-icon
        [icon]="Icon.whistle_agent_w"
        alt="gorra agente"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-64'"
      ></sit-icon>
      <span class="text-size-16 text-weight-600">{{properties?.Qty}}</span>
      <span class="text-size-16 mt-1">Activos</span>
    </div>
    <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <sit-icon
        [icon]="Icon.density_w"
        alt="gorra agente"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-64'"
      ></sit-icon>
      <span class="text-size-16 text-weight-600">{{properties?.density | number : '1.2-2'}}</span>
      <span class="text-size-16 mt-1">Elementos km<sup>2</sup></span>
    </div>
</div>

<div class="d-flex fd-row chart-line-container">
    <sit-chart-line [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width">
    </sit-chart-line>
</div>

  
  
  