import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import {
  ActionType,
  IMenuEvent,
  IMenuModel,
} from '@types-custom/models/ui/menu.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ActionService } from '@shared/services/action-service/action.service';

/*
 ** Menu
 ** Render a mat menu that can be nested.
 */
@Component({
  selector: 'sit-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() properties: IMenuModel<any> | undefined = undefined;
  Icon = Icon;
  showMenu = true;



  constructor(private actionService: ActionService) { }

  callDispatcher(
    action: any,
    data: any,
    dispatcher: BehaviorSubject<IMenuEvent<any> | undefined> | undefined,
    url: string,
    actionType: ActionType,
    returnPanel?: boolean,
    actionPanel?: ActionType
  ): void {
    if (url) {
      window.open(url, '_blank');
      return;
    }
    if (dispatcher) {
      if (!returnPanel) {
        const event: IMenuEvent<any> = { action, actionType, data };
        this.actionService.dispatch(event, dispatcher);
        
      } else {
        const event: IMenuEvent<any> = { action, actionType, data, returnMap: returnPanel, returnAction: actionPanel };
        this.actionService.dispatch(event, dispatcher);

      }

    }

    this.resetMenu();
  }

  resetMenu() {
    this.showMenu = false;
    setTimeout(() => {
      this.showMenu = true;
    }, 500);
  }
}
