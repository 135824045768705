import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import {
  GridPageActions,
  IGridPageModel,
} from '@types-custom/models/ui/grid-page-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import {IScheduleActivitiesModel} from "@shared/models/resources-model";
import {IUniqueModalModel} from "@types-custom/models/ui/modal.model";
import {ModalNoInfoComponent} from "@ui-core/components/modal-no-info/modal-no-info.component";
import {ModalService} from "@ui-core/services/modal/modal.service";

/*
 ** Grid Page
 ** Render a sit-grid-table with pagination and export options (PDF, Excel)
 */
@Component({
  selector: 'grid-page',
  templateUrl: './grid-page.component.html',
  styleUrls: ['./grid-page.component.scss'],
})
export class GridPageComponent implements OnInit {
  readonly GridPageActions = GridPageActions;

  icon = Icon;

  @Input() properties!: IGridPageModel;
  @Output() gridAction = new EventEmitter<GridPageActions>();
  exportExcelButtonProps: IButtonModel | undefined = undefined;
  exportPDFButtonProps: IButtonModel | undefined = undefined;
  publishAllButtonProps: IButtonModel | undefined = undefined;

  constructor(
      public modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    this.initializePanel();
  }
  private initializePanel(): void {
    this.exportExcelButtonProps = {
      label: 'EXPORTAR A EXCEL',
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.exportPDFButtonProps = {
      label: 'EXPORTAR A PDF',
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.publishAllButtonProps = {
      label: 'Publicar todo',
      classAttributes: {
        buttonClass: ButtonClassesEnum.primary_1_button,
        spanClass: IconClassesEnum.text_complementary_2,
      },
    };
  }
  dispatchAction(action: GridPageActions): void {
    this.gridAction.emit(action);
  }

  handlePublishAll() {
    this.doPublishAll();
  }

  private doPublishAll() {
    this.gridAction.emit();
  }
}
