import { Component, Input } from '@angular/core';

/*
** Img Loader
** Render an image.
*/
@Component({
  selector: 'img-loader',
  templateUrl: './img-loader.component.html',
  styleUrls: ['./img-loader.component.scss'],
})
export class ImgLoaderComponent {
  @Input() source: string | undefined;
  @Input() alt = 'imagen';
}
