import { Icon } from '@types-custom/models/ui/icon-model';
import { ModalChangePasswordComponent } from '@ui-core/components/modal-change-password/modal-change-password.component';
import { ModalType } from '@shared/models/modal-type';

export const modals = {
  [ModalType.ChangePassword]: {
    icon: Icon.lock_reset_w,
    title: 'Cambiar Contraseña',
    headerBackgroundClass: 'bg-color-background-1',
    toRender: {
      component: ModalChangePasswordComponent,
      data: {
        properties: {
          close: '',
        },
      },
    },
  },
};
