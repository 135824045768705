import {
    IGeometryLineModel,
    IGeometryModel,
    MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export interface IPropsRoadCorridorsModel {
    id: string;
    tid: string;
    composed_name: string;
    name_from: string;
    nameFrom?: string;
    nameTo?: string;
    levelOfService?: string;
    concordance?: string;
    elapsedTime?: string;
    expectedValue? : string;
    readings? : string;
    readTime? : string;
    speedRange? :[]
    name_to: string;
    orient: string;
    distance: string;
    read_time: number;
    speed: number;
    time: string;
    total_incidents : number,
    icon: Icon;
    isCorridor?: Boolean;
    geometry?: unknown;
    apiObj?: unknown;
}

export class RoadCorridorsModel extends MarkerModelBase {
    static className = 'RoadCorridorsModel';
    classProperties: IPropsRoadCorridorsModel;

    constructor(
        props: IPropsRoadCorridorsModel,
        geometry: IGeometryLineModel | IGeometryModel,
        lat?: number,
        lng?: number
    ) {
        super(geometry, RoadCorridorsModel.className, lat, lng);
        this.classProperties = props;
        this.geometry = geometry;
    }
}
