export const exodusAndReturnWazeMockData = {
    "type": "FeatureCollection",
    "properties": {
        "exceededTransferLimit": true
    },
    "features": [
        {
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        -74.03309099999996,
                        4.7128470000000675
                    ],
                    [
                        -74.03249199999993,
                        4.712688000000071
                    ],
                    [
                        -74.03180499999996,
                        4.71247900000003
                    ],
                    [
                        -74.03169699999995,
                        4.712442000000067
                    ],
                    [
                        -74.03137699999996,
                        4.712333000000058
                    ],
                    [
                        -74.03058299999998,
                        4.712064000000055
                    ],
                    [
                        -74.03028099999995,
                        4.711960000000033
                    ],
                    [
                        -74.02925199999999,
                        4.711608000000069
                    ]
                ]
            },
            "id": 143867609,
            "type": "Feature",
            "properties": {
                "tid": 1000729,
                "cartodb_id": 1000729,
                "update_at": "2021-06-17 21:00:00.000",
                "nameFrom": "KR86",
                "nameTo": "DG49SUR;CL57BSUR ",
                "levelOfService":"yellow",
                "distance": 1032,
                "id": 1000003,
                "concordanceIndex": -34.06093852714069,
                "elapsedTime":538.6282619798973,
                "expectedValue": 401.7786746068854,
                "readings": 10,
                "readTime": "2023-08-14T15:18:00",
                "time": "2023-08-14T15:15:00",
                "speed": 11.81,
                "type": 1
            }
        },
        {
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        -74.21963999999997,
                        4.603317000000061
                    ],
                    [
                        -74.21940399999994,
                        4.603462000000036
                    ],
                    [
                        -74.21912299999997,
                        4.603593000000046
                    ],
                    [
                        -74.21835599999997,
                        4.603803000000028
                    ],
                    [
                        -74.21825499999994,
                        4.603831000000071
                    ],
                    [
                        -74.21785699999998,
                        4.603942000000075
                    ],
                    [
                        -74.21676299999996,
                        4.604246000000046
                    ],
                    [
                        -74.21647199999995,
                        4.604329000000064
                    ]
                ]
            },
            "id": 143867611,
            "type": "Feature",
            "properties": {
                "tid": 1000729,
                "cartodb_id": 1000729,
                "update_at": "2021-06-17 21:00:00.000",
                "nameFrom": "KR86",
                "nameTo": "DG49SUR;CL57BSUR ",
                "levelOfService":"red",
                "distance": 1032,
                "id": 1000004,
                "concordanceIndex": -34.06093852714069,
                "elapsedTime":538.6282619798973,
                "expectedValue": 401.7786746068854,
                "speed": 31.81,
                "readings": 10,
                "readTime": "2023-08-14T15:18:00",
                "time": "2023-08-14T15:15:00",
                "type": 1
            }
        },
        {
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        -74.07449299999996,
                        4.664432000000033
                    ],
                    [
                        -74.07396999999997,
                        4.665063000000032
                    ],
                    [
                        -74.07340999999997,
                        4.665745000000072
                    ]
                ]
            },
            "id": 143867613,
            "type": "Feature",
            "properties": {
                "tid": 1000730,
                "cartodb_id": 1000730,
                "update_at": "2021-06-17 21:00:00.000",
                "nameFrom": "KR86",
                "nameTo": "CL57BSUR;DG49SUR ",
                "levelOfService":"yellow",
                "distance": 1029,
                "type": 1,
                "id": 1000007,
                "concordanceIndex": -34.06093852714069,
                "elapsedTime":538.6282619798973,
                "expectedValue": 401.7786746068854,
                "speed": 21.81,
                "readings": 10,
                "readTime": "2023-08-14T15:18:00",
                "time": "2023-08-14T15:15:00"
            }
        }    
    ]
}
