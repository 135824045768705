import { Component, Input } from '@angular/core';
import { IStateIndicatorModel } from '@types-custom/models/ui/state-indicator-model';

/*
**  State Indicator
** Render a label with a state indicator.
*/
@Component({
  selector: 'state-indicator',
  templateUrl: './state-indicator.component.html',
  styleUrls: ['./state-indicator.component.scss'],
})
export class StateIndicatorComponent {
  @Input() stateIndicatorProperties: IStateIndicatorModel | undefined =
    undefined;
}
