import { Component, Input, OnInit } from "@angular/core";
import { ManageWazeAlertsService } from "@official/services/data-grid-service/manage-waze-alerts-service/manage-waze-alerts.service";
import { IAlertsDuplicatesWazeModel, IModalAlertsDuplicatesWazeModel } from "@types-custom/models/ui/modal.alerts.waze.model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { BehaviorSubject } from 'rxjs';
import { ScrollHandlingEventsEnum } from '@types-custom/models/ui/scroll-model';
import { ButtonClassesEnum, IButtonModel, IconClassesEnum } from '@types-custom/models/ui/button-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalConfirmationComponent } from "@ui-core/components/modal-confirmation/modal-confirmation.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
@Component({
  selector: "waze-alerts-duplicates-modal",
  templateUrl: "./waze-alerts-duplicates-modal.component.html",
  styleUrls: ["./waze-alerts-duplicates-modal.component.scss"],
})
export class WazeAlertsDuplicatesModalComponent  implements OnInit{
  @Input() properties: IModalAlertsDuplicatesWazeModel;

  accidentTypes = {
    'ACCIDENT' : 'Accidente',
    'WEATHERHAZARD': 'Riesgo Clímatico',
    'ROAD_CLOSED' : 'Vía Cerrada'
  }
  accidentSubTypes = {
    'ROAD_CLOSED_CONSTRUCTION' : 'Vía en obra',
    'HAZARD_WEATHER_FLOOD': 'Inundación',
    'HAZARD_ON_ROAD_CAR_STOPPED' : 'Coche Detenido',
    'HAZARD_ON_SHOULDER_CAR_STOPPED' : 'Coche Detenido',
    'HAZARD_ON_ROAD_POT_HOLE' : 'Agujero en vía',
    'ACCIDENT_MINOR' : 'Accidente Menor',
    'ACCIDENT_MAJOR' : 'Accidente Mayor',
    'HAZARD_ON_ROAD_ROAD_KILL' : 'Muerte en vía',
    'ROAD_CLOSED_EVENT' : 'Evento en vía',
    'HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT' : 'Semáforo dañado',
  }

  accidentType: string = '';
  accidentSubType: string = '';
  date : string = '';
  time : string = '';
  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;

  saveButtonProps: IButtonModel = {
    label: 'Guardar',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'Cancelar',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  selectedAll: boolean = false;
  protected readonly Icon = Icon;

  constructor(private service: ManageWazeAlertsService
    , private modalService: ModalService) {}
  ngOnInit(): void {
    // @ts-ignore
    this.accidentType = this.accidentTypes[this.properties.type] || undefined;
    // @ts-ignore
    this.accidentSubType = this.accidentSubTypes[this.properties.subType] || this.accidentType;
    this.date = this.convertDate(new Date(this.properties.pubTime));
    this.time = this.convertTime(new Date(this.properties.pubTime));
  }

  get disabledSave(): boolean {
    return this.properties?.duplicates.filter(p => p.selected).length == 0;
  }

  convertDate(date : Date) : any {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }

  convertTime(date : Date): any {
    const hour = date.getHours();
    const minutes = date.getMinutes();

    const sub = hour >= 12 ? 'PM' : 'AM';

    const hour12 = hour % 12 === 0 ? 12 : hour % 12;

    return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${sub}`;
  }

  setAll() {
    this.properties.duplicates.forEach(d => (d.selected = this.selectedAll));
  }

  selectSingle(card: IModalAlertsDuplicatesWazeModel) {
    this.selectedAll = this.properties.duplicates.filter(p => p.selected).length == this.properties.duplicates.length;
  }

  save() {
    if(this.properties.duplicates.filter(p => p.selected).length > 0) {
      // Datos a guardar
      const body: IAlertsDuplicatesWazeModel = {
        wazeAlertReferenceId: this.properties.id,
        wazeAlertDuplicatesIds: this.properties.duplicates.filter(p => p.selected).map(data => data.id)
      };

      this.service.saveDuplicatesAlerts(body).subscribe((res) => {
        console.log(res);
        // Cerrar el modal
        this.modalService.cleanModals$.next([]);
        setTimeout(() => {
          this.showModalSuccess('El resgitro se ha guardado con éxito');
        }, 200);
      });
    }
  }

  cancel() {
    this.modalCanelar();
  }

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };
    this.modalService.confirmationModal(modal);
  }

  modalCanelar(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalConfirmationComponent,
        data: {
          titleMessage: '¿Está seguro que desea cancelar?',
          infoMessage: 'Los cambios realizados serán descartados',
          icon: Icon.help_blue
        },
      },
      headerBackgroundClass: 'bg-color-background-3',
      leftLineClass: 'bg-color-state-2',
      sheetBottom: true,
      confirm: () => {
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        return;
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }
  
}
