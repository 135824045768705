<div class="form-container pr-38 pl-38 mb-32 mt-32 text-weight-500">
  <form [formGroup]="loginFormGroup" class="login-form">
    <div class="d-flex fd-row align-items-center mb-16 ml-32">
      <mat-error class="error-field"
        *ngIf="mensaje">
        {{mensaje}}
      </mat-error>
    </div>
    <div class="mat-input d-flex fd-row align-items-center mb-16">

      <div class="mr-8">
        <sit-icon
          [icon]="Icon.user_w"
          [sizeClass]="'icon-size-28'"
        ></sit-icon>
      </div>
      <mat-form-field
        class="textWhite"
        appearance="outline"
        [ngClass]="{
          'error-field':
            loginFormGroup.controls.username.hasError('required') &&
            loginFormGroup.controls.username.touched
        }"
      >
        <mat-label class="text-white">Usuario</mat-label>
        <input matInput maximum-scale=1 formControlName="username" />
        <mat-error
          *ngIf="loginFormGroup.controls.username.hasError('required')"
        >
          Campo obligatorio
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mat-input d-flex fd-row align-items-center mb-16">
      <div class="mr-8">
        <sit-icon [icon]="Icon.lock_w" [sizeClass]="'icon-size-28'"></sit-icon>
      </div>
      <mat-form-field
        class="textWhite"
        appearance="outline"
        [ngClass]="{
          'error-field':
            loginFormGroup.controls.password.hasError('required') &&
            loginFormGroup.controls.password.touched
        }"
      >
        <mat-label class="text-white">Contraseña</mat-label>
        <input
          matInput
          formControlName="password"
          [type]="showPass ? 'text' : 'password'" />
        <sit-icon
          matSuffix
          (click)="showPass = !showPass"
          class="cursor-pointer"
          [icon]="showPass ? Icon.visibility_off : Icon.visibility_on"
          [sizeClass]="'icon-size-28'"></sit-icon>
        <mat-error
          *ngIf="loginFormGroup.controls.password.hasError('required')">
          Campo obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="d-flex fd-col text-align-end text-size-12 mb-16 text-white">
    <span class="letter-spacing-2 mt-16">
      <a (click)="showModalForgot()" class="cursor-pointer link" tabindex="0">Olvidó su contraseña</a></span>
    <span class="letter-spacing-2 mt-16 mb-16">Al ingresar acepta nuestros
      <a (click)="showModalTerms('privacyNotice')" class="cursor-pointer link" tabindex="0">términos y condiciones</a>
      y nuestra
      <a (click)="showModalTerms('dataTreatment')" class="cursor-pointer link" tabindex="0">política de protección de datos
        personales.</a></span>
  </div>

  <div class="d-flex fd-row justify-end">
    <sit-button
      (click)="handleLogin()"
      [buttonProperties]="signinButtonProps"
      class="width-49"></sit-button>

  </div>
</div>
