import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  GridPageActions,
  IGridPageModel,
} from '@types-custom/models/ui/grid-page-model';
import {
  gridTableheadersMap,
  GridTypeEnum,
} from '@official/utils/mappers/grid-mapper';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { ExportExcelService } from '@official/services/data-grid-service/export-excel-service/export-excel.service';
import { CardActionEnum, PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { IGridTableCellDispatcher } from '@types-custom/models/ui/grid-table-model';
import { PanelServiceProxyService } from '@official/services/panel-service-proxy/panel-service-proxy.service';
import { IncidentPanelService } from '@shared/services/incident-panel/incident-panel.service';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import {
  fileNameDownloaded,
  GridTypePanelManageMap,
  IconManagePanelMap,
  NameManagePanelMap,
  TextManagePanelMap,
  TitleButtonCreateEditMap,
  TitlePanelGridMap,
} from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { AbstractPanelManagementDataSource, filterStateDataSource } from '@types-custom/models/ui/paginator-model';
import { animate, style, transition, trigger } from '@angular/animations';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { IModalModel } from '@types-custom/models/ui/modal.model';
import { MapboxInstanceService } from '@sdk/services/mapbox-instance/mapbox-instance.service';
import { IncidentStateStatModel } from '@types-custom/models/ui/incidents-model';
import { FilterManageService } from '@official/services/data-grid-service/filter-manage-service/filter-manage.service';
import { ActionType, IMenuEvent } from '@types-custom/models/ui/menu.model';
import { MapLayers } from '@shared/utils/mappers/layers-map.model';

@Component({
  selector: 'official-panel-management',
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100px)' }),
        animate(300, style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(0, style({ opacity: 0 }))]),
    ]),
  ],
  templateUrl: './panel-management.component.html',
  styleUrls: ['./panel-management.component.scss'],
})
export class PanelManagementComponent implements OnInit {
  @Input() properties!: {
    clickInteractionDispatcher: BehaviorSubject<any>;
    clickEventsDispatcher: BehaviorSubject<any>;
  };
  @Input() layerDispatcher$: Subject<IMenuEvent | undefined>;
  @Input() data: any;

  panelOnManagement: MapEventTypeEnum = '' as MapEventTypeEnum;

  filterManageDataSource: filterStateDataSource;

  get MapEventTypeEnum() {
    return MapEventTypeEnum;
  }

  servicePanelDataSource: AbstractPanelManagementDataSource;

  Icon = Icon;
  layerName = '' as GridTypeEnum;
  isAllowDownload = true;
  createButtonProps: IButtonModel | undefined = undefined;
  searchButtonProps: IButtonModel | undefined = undefined;

  modals: IModalModel[] = [];

  gridColumnActions = new Subject<IGridTableCellDispatcher>();

  gridPageProps: IGridPageModel;
  gridRowData: any;

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  titlePanel = '';
  textPanel = '';
  buttonCreateEditPanel = '';
  titleGridPanel = '';

  statsMapper: { [key: string]: { icon: Icon, name: string, count: number } };

  iconTitlePanel: { icon: Icon; alt: string };

  constructor(
    private panelServiceProxyService: PanelServiceProxyService,
    private exportExcelService: ExportExcelService,
    private modalService: ModalService,
    private mapboxInstanceService: MapboxInstanceService,
    private filterManageService: FilterManageService,
    private incidentPanelService: IncidentPanelService,
  ) { }

  ngOnInit(): void {
    if (!!this.data?.value) {
      this.gridRowData = this.data?.value;
      this.panelAction$ = new BehaviorSubject<PanelManageActionsEnum>(this.panelManageActionsEnum.CREATE)
      this.data.next(undefined);
    } else
      this.panelAction$ = new BehaviorSubject<PanelManageActionsEnum>(this.panelManageActionsEnum.GRID)


    this.incidentPanelService.clickModalLDispatcher$.subscribe((value: boolean) => {
      if (value) {
        this.cleanModals();
        this.onPanelManageAction(this.panelManageActionsEnum.GRID);
        setTimeout(() => {
          this.onPanelManageAction(this.panelManageActionsEnum.DETAIL);
        }, 100);
      }
    })
    this.init();
  }

  init(): void {
    this.cleanModals();
    this.mapboxInstanceService.setDefaultFocus();
    this.initializePanel();
    this.initServiceDataSource();

  }

  private initializePanel(): void {
    this.panelOnManagement = this.properties.clickInteractionDispatcher
      .value as MapEventTypeEnum;

    this.panelAction$?.subscribe(
      (value) => {
        if (value === PanelManageActionsEnum.DETAIL) {
          this.incidentPanelService.setData(this.gridRowData?.id)
        }
      }
    )

    this.titlePanel = NameManagePanelMap[this.panelOnManagement];
    this.layerName = GridTypePanelManageMap[this.panelOnManagement];
    this.textPanel = TextManagePanelMap[this.panelOnManagement];
    this.iconTitlePanel = IconManagePanelMap[this.panelOnManagement];
    this.buttonCreateEditPanel =
      TitleButtonCreateEditMap[this.panelOnManagement] ?? this.titlePanel;
    this.titleGridPanel =
      TitlePanelGridMap[this.panelOnManagement] ??
      `REGISTROS DE ${this.titlePanel}`;

    this.filterManageDataSource = this.filterManageService;

    this.createButtonProps = {
      label: `CREAR ${this.buttonCreateEditPanel}`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.searchButtonProps = {
      label: `BUSCAR ${this.buttonCreateEditPanel}`,
      classAttributes: {
        buttonClass: ButtonClassesEnum.tertiary_button,
        spanClass: IconClassesEnum.text_white,
      },
    };

    this.servicePanelDataSource =
      this.panelServiceProxyService.mapDataSourceService(
        this.panelOnManagement
      );

    this.gridPageProps = {
      headers: gridTableheadersMap([this.gridColumnActions])[this.layerName],
      dataSource: this.servicePanelDataSource,
      isAllowDownload: this.isAllowDownload,
    };

    this.servicePanelDataSource.init();

    this.gridColumnActions.subscribe((columnAction) =>
      this.handleItemAction(columnAction)
    );

    if (this.panelOnManagement === MapEventTypeEnum.INCIDENTS) {
      this.servicePanelDataSource.data$.subscribe(() =>
        this.setStats()
      )
    }

  }

  setStats() {
    this.servicePanelDataSource.getStateCounter().subscribe(
      (resp: IncidentStateStatModel[]) => {
        this.statsMapper = resp.reduce((acc, item) => {
          if (item.stateName.includes('apoyo') || item.stateName === 'Verificado') {
            // acc.Verificado = (acc.Verificado || 0) + item.count
            acc._2verificado.count += item.count
          } else if (item.stateName === 'Duplicado') {
            acc._3duplicado.count += item.count
          } else if (item.stateName === 'Por verificar') {
            acc._1por_verificar.count += item.count
          } else {
            acc._4cerrado.count += item.count
          }
          return acc;
        }, {
          _1por_verificar: {
            icon: Icon.eye_blue,
            name: 'Por verificar',
            count: 0
          },
          _3duplicado: {
            icon: Icon.layers_blue,
            name: 'Duplicado',
            count: 0
          },
          _2verificado: {
            icon: Icon.check_blue,
            name: 'Verificado',
            count: 0
          },
          _4cerrado: {
            icon: Icon.close_blue,
            name: 'Cerrado',
            count: 0
          }
        });
      }
    );
  }

  handleDownloadGridAction(fileType: GridPageActions): void {
    const fileProps = {
      url: this.servicePanelDataSource.buildExcelPdfUrl(
        fileType as unknown as string
      ),
      filters: this.servicePanelDataSource?.formFiltersValue,
    };

    if (this.panelOnManagement === MapEventTypeEnum.INCIDENTS) {
      this.exportExcelService.downloadIncidentsFile(
        fileProps.url,
        fileProps.filters,
        fileNameDownloaded[this.panelOnManagement],
        fileType as unknown as string
      );
    } else {
      this.exportExcelService.downloadFile(
        fileProps.url,
        fileNameDownloaded[this.panelOnManagement],
        fileType as unknown as string
      );
    }
  }

  handleItemAction(cardAction: IGridTableCellDispatcher) {
    let { action } = cardAction;
    action = action || CardActionEnum.EDIT;
    switch (action) {
      case CardActionEnum.DETAIL:
        const newLayer: IMenuEvent = {
          action: MapLayers.incidentAddResources,
          actionType: ActionType.Map
        }
        this.layerDispatcher$?.next(newLayer);
        this.gridRowData = cardAction.row;
        this.onPanelManageAction(this.panelManageActionsEnum.DETAIL);
        break;
      case CardActionEnum.EDIT:
        this.gridRowData = cardAction.row;
        this.onPanelManageAction(this.panelManageActionsEnum.EDIT);
        break;
      default:
        this.onPanelManageAction(this.panelManageActionsEnum.GRID);
        break;
    }
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  cleanModals() {
    this.modalService.cleanModals$.next([]);
  }

  initServiceDataSource() {
    this.servicePanelDataSource.searchFromFilters(undefined);
  }
}
