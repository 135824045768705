<div
  class="modal-change-password bg-color-background-3 w-auto h-auto pt-20 pr-24 pb-20"
>
  <form [formGroup]="changePasswordFormGroup" class="form-container">
    <sit-icon
      [icon]="Icon.lock_w"
      alt="candado"
      [sizeClass]="'icon-size-32'"
    ></sit-icon>
    <mat-form-field
      class="color-field"
      appearance="outline"
      [ngClass]="{
        'error-field':
          changePasswordFormGroup.controls.currentPassword.invalid &&
          changePasswordFormGroup.controls.currentPassword.touched
      }"
    >
      <mat-label class="text-white">Contraseña actual</mat-label>
      <input
        matInput
        formControlName="currentPassword"
        autocomplete="current-password"
        matTooltipPosition="below"
        matTooltipClass="custom-tooltip"
        matTooltip="Debe contener mínimo una letra mayúscula, un número y un carácter especial + *"
        [type]="showCurrentPassword ? 'text' : 'password'"
      />
      <sit-icon
        matSuffix
        (click)="showCurrentPassword = !showCurrentPassword"
        class="cursor-pointer"
        [icon]="showCurrentPassword ? Icon.visibility_off : Icon.visibility_on"
        alt="ojo"
        [sizeClass]="'icon-size-22 mr-05-em'"
      ></sit-icon>
      <mat-error >{{ currentPasswordErrorMessage }}</mat-error>
    </mat-form-field>
    <sit-icon
      [icon]="Icon.lock_reset_w"
      alt="candado"
      [sizeClass]="'icon-size-32'"
    ></sit-icon>
    <mat-form-field
      class="color-field"
      appearance="outline"
      [ngClass]="{
        'error-field':
          changePasswordFormGroup.controls.newPassword.invalid &&
          changePasswordFormGroup.controls.newPassword.touched
      }"
    >
      <mat-label class="text-white">Contraseña nueva</mat-label>
      <input
        matInput
        formControlName="newPassword"
        matTooltipPosition="below"
        matTooltipClass="custom-tooltip"
        matTooltip="Debe contener mínimo una letra mayúscula, un número y un carácter especial + *"
        [type]="showNewPassword ? 'text' : 'password'"
      />
      <sit-icon
        matSuffix
        (click)="showNewPassword = !showNewPassword"
        class="cursor-pointer"
        [icon]="showNewPassword ? Icon.visibility_off : Icon.visibility_on"
        alt="ojo"
        [sizeClass]="'icon-size-22 mr-05-em'"
      ></sit-icon>
      <mat-error>{{ newPasswordErrorMessage }}</mat-error>
    </mat-form-field>
    <sit-icon
      [icon]="Icon.lock_reset_w"
      alt="candado"
      [sizeClass]="'icon-size-32'"
    ></sit-icon>
    <mat-form-field
      class="color-field"
      appearance="outline"
      [ngClass]="{
        'error-field':
          changePasswordFormGroup.controls.newPasswordConfirm.invalid &&
          changePasswordFormGroup.controls.newPasswordConfirm.touched
      }"
    >
      <mat-label class="text-white">Confirmar nueva contraseña</mat-label>
      <input
        matInput
        matTooltipPosition="below"
        matTooltipClass="custom-tooltip"
        matTooltip="Debe contener mínimo una letra mayúscula, un número y un carácter especial + *"
        formControlName="newPasswordConfirm"
        autocomplete="new-password-confirm"
        [type]="showNewPasswordConfirm ? 'text' : 'password'"
      />
      <sit-icon
        matSuffix
        (click)="showNewPasswordConfirm = !showNewPasswordConfirm"
        class="cursor-pointer"
        [icon]="
          showNewPasswordConfirm ? Icon.visibility_off : Icon.visibility_on
        "
        alt="ojo"
        [sizeClass]="'icon-size-22 mr-05-em '"
      ></sit-icon>
      <mat-error
        *ngIf="
          changePasswordFormGroup.controls.newPasswordConfirm.hasError(
            'required'
          )
        "
      >
        Campo obligatorio
      </mat-error>
      <mat-error
        *ngIf="
          changePasswordFormGroup.controls.newPasswordConfirm.hasError(
            'minlength'
          )
        "
      >
        Longitud mínima requerida (8)
      </mat-error>
      <mat-error
        *ngIf="
          changePasswordFormGroup.controls.newPasswordConfirm.hasError(
            'notMatch'
          )
        "
      >
        Las contraseñas no coinciden
      </mat-error>
    </mat-form-field>
  </form>

  <div class="d-flex fd-row justify-end mt-10 mb-16">
    <div class="d-flex fd-row">
      <sit-button
        (click)="handleClose()"
        [buttonProperties]="cancelButtonProps"
        class="width-49 mr-16"
      ></sit-button>
    </div>
    <div class="d-flex fd-row">
      <sit-button
        (click)="
          changePasswordFormGroup.valid
            ? handleSendChangePassword()
            : validateFormGroup()
        "
        [disabled]="!changePasswordFormGroup.valid"
        [buttonProperties]="submitButtonProps"
        class="width-49"
      ></sit-button>
    </div>
  </div>
</div>
