import { InputTypeEnum } from "../ui/generic-form.model";

export enum IncidentStatusCodeEnum {
  ABIERTO = '150',
  CERRADO = '151',
}

export interface IObservation {
  id: number;
  incidentId: number;
  observation: string | null;
  authorId: string | null;
  authorName: string | null;
  date: string | null;
}

export interface IInvolvedVehicle {
  id: number;
  vehicleTypeId: number | null;
  amountInjuredAdult: number | null;
  amountInjuredChildren: number | null;
  deadCount: number | null;
}

export interface IDynamicFieldAnswers {
  incidentId: string,
  answers: IDynamicFieldAnswer[],
}

export interface IDynamicFieldAnswer {
  fieldId: string,
  answer: string,
  fieldType?: InputTypeEnum
}

// export interface IIncidentDataBody {
//   id: number | null;
//   sourceId: number | null;
//   sourceName: string | null;
//   typeId: number | null;
//   typeName: string | null;
//   stateId: number | null;
//   stateName: string | null;
//   priorityId: number | null;
//   priorityName: string | null;
//   gravityId: number | null;
//   gravityName: string | null;
//   classesId: number | null;
//   classesName: string | null;
//   objectId: number | null;
//   objectName: string | null;
//   actorId: number | null;
//   actorName: string | null;
//   visible: boolean | null;
//   locationName: string | null;
//   brokerId: number | null;
//   brokerName: string | null;
//   implicatedId: number | null;
//   implicatedName: string | null;
//   address: string | null;
//   zoneId: number | null;
//   zoneName: string | null;
//   orientId: number | null;
//   orientName: string | null;
//   requireSupportId: number | null;
//   requireSupportName: string | null;
//   incidentTime: string | null;
//   typeOthers: string | null;
//   ipat: string | null;
//   latitude: number | null;
//   longitude: number | null;
//   observations: IObservation[] | null;
//   involvedVehicles: IInvolvedVehicle[] | null;
//   userAssignId: string | null;
//   userAssignName: string | null;
//   [key: string]: any;
// }

export interface IIncidentDataBody {
  id: number | null;
  actorId?: number | null;
  address: string | null;
  beginDate?: string | null;
  brokerId: number | null;
  classesId: number | null;
  creatorUserId?: string;
  creatorUserName?: string;
  endDate?: string | null;
  gravityId: number | null;
  implicatedId?: number | null;
  incidentTime: string | null;
  involvedVehicles: unknown;
  ipat?: string | null;
  latitude: number | null;
  longitude: number | null;
  objectId: number | null;
  observations?: IObservation[] | null;
  orientId: number | null;
  priorityId: number | null;
  requireSupportId: number | null;
  sourceId: number | null;
  stateId: number | null;
  typeId: number | null;
  typeOthers?: string | null;
  userAssignId?: string | null;
  visible?: boolean | null;
  zoneId: number | null;
  [key: string]: any;
}
