import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ILocationPoint } from '@types-custom/models/business/location.model';

import {
  AbstractPaginatorDataSource,
  AbstractPanelManagementDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
import { IManageCameraDataBody } from '@types-custom/models/business/manage-camera-data-form';
import { SensorOptionTypeEnum } from '@types-custom/models/ui/sensor-option-type-model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';

@Injectable({ providedIn: 'root' })
export class ManageCamerasService
  implements AbstractPanelManagementDataSource<any>
{
  private apiSelectOptionsMap: {
    [key: string]: () => Observable<any[]>;
  } = {
      [SensorOptionTypeEnum.TYPE]: this.getCameraType.bind(this),
      [SensorOptionTypeEnum.LOCALITY]: this.getListLocality.bind(this),
      [IncidentsListEnum.LOCALIDAD]: this.getListLocality.bind(this),
      [SensorOptionTypeEnum.STATUS]: this.getListStatus.bind(this),
    };

  private dataSubject = new BehaviorSubject<any[]>([]);
  data$ = this.dataSubject.asObservable();
  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<any | ILocationPoint<string> | undefined>;
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  formFiltersValue: any | undefined = undefined;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) { }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  buildUrl(dataObject: IPaginatorModel): string {
    const filterObject = this.selected$.value;

    let url = `${this.environment.camerasUrl}/camera/pagination/data?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}`;
    // let url = `assets/mock/cameras2.json`; //TODO mocked data for cammeras

    if (this.formFiltersValue) {
      Object.keys(this.formFiltersValue).map((filterKey: string) => {
        if (filterKey !== undefined && filterKey !== null && filterKey !== '') {
          if (
            this.formFiltersValue[filterKey] !== undefined &&
            this.formFiltersValue[filterKey] !== null &&
            this.formFiltersValue[filterKey] !== ''
          ) {
            url = url + `&${filterKey}=${this.formFiltersValue[filterKey]}`;
          }
        }
      });
    }

    return url;
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value;
    const url = this.buildUrl(pageInfo);
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.currentPage,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalRecords,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        const data: any[] = response.records;
        this.dataSubject.next(data ?? []);
      })
    );
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
  }

  buildExcelPdfUrl(fileType: string) {
    let urlBase = '';
    if (fileType === fileTypes.EXCEL) {
      urlBase = `${this.environment.camerasUrl}/camera/export/excel?`;
    } else if (fileType === fileTypes.PDF) {
      urlBase = `${this.environment.camerasUrl}/camera/export/pdf?`;
    }

    if (this.formFiltersValue) {
      Object.keys(this.formFiltersValue).forEach(
        (filterKey: string, index: number) => {
          if (
            filterKey !== undefined &&
            filterKey !== null &&
            filterKey !== ''
          ) {
            if (
              this.formFiltersValue[filterKey] !== undefined &&
              this.formFiltersValue[filterKey] !== null &&
              this.formFiltersValue[filterKey] !== ''
            ) {
              urlBase =
                urlBase + `&${filterKey}=${this.formFiltersValue[filterKey]}`;
            }
          }
        }
      );
    }

    return urlBase;
  }

  // nuevo codigo
  getSelectOptions(optionType: string) {
    const fn = this.apiSelectOptionsMap[optionType];
    return fn ? fn() : of([]);
  }

  getIndividualData(rowData: any) {
    return this.httpClient
      .get<IManageCameraDataBody>(`${this.environment.camerasUrl}/camera/${rowData.id}`)
      .pipe(
        map((response) => ({
          ...response,
          // id: response.id,
          // address: response.address,
          // cameraTypeId: response.cameraTypeId,
          // description: response.description,
          // latitude: response.latitude,
          // locationId: response.locationId,
          // longitude: response.longitude,
          // model: response.model,
          // name: response.name,
          // password: response.password,
          point: [response.latitude, response.longitude],
          // protocol: response.protocol,
          // serial: response.serial,
          statusId: `${response.statusId}`,
          // url: response.url,
          // userName: response.userName,
          // versionFw: response.versionFw,
        }))
      );
  }

  searchFromFilters(formFiltersValue: any) {
    this.formFiltersValue = formFiltersValue;
    this.selected$.next(formFiltersValue);
  }

  submitCreateForm(formValue: any) {
    const url = `${this.environment.camerasUrl}/camera/`;

    const data: IManageCameraDataBody = {
      id: null,
      ip: '1.0.0',
      latitude: formValue.point?.[0],
      locationId: formValue.Localidad,
      longitude: formValue.point?.[1],
      manufacturer: '-',
      protocol: formValue.protocolo,
      versionFw: formValue.versionFw,
      ...formValue
      // address: formValue.address,
      // cameraTypeId: formValue.cameraTypeId,
      // description: formValue.description,
      // model: formValue.model,
      // name: formValue.name,
      // password: formValue.password,
      // serial: formValue.serial,
      // statusId: formValue.statusId,
      // url: formValue.url,
      // userName: formValue.userName,
    };

    return this.httpClient.post(url, data);
  }

  submitEditForm(formValue: any) {
    const url = `${this.environment.camerasUrl}/camera/`;

    const data: IManageCameraDataBody = {
      id: formValue.id,
      name: formValue.name,
      address: formValue.address,
      latitude:
        formValue.latlng2.length > 0
          ? formValue.latlng2[0]
          : formValue.point.substring(0, formValue.point.indexOf(',')),
      longitude:
        formValue.latlng2.length > 0
          ? formValue.latlng2[1]
          : formValue.point.substring(formValue.point.indexOf(',') + 1),
      locationId: formValue.locationId,
      manufacturer: formValue.manufacturer,
      protocol: formValue.protocol,
      cameraTypeId: formValue.cameraTypeId,
      statusId: formValue.statusId,
      versionFw: formValue.versionFw,
      model: formValue.model,
      serial: formValue.serial,
      userName: formValue.userName,
      password: formValue.password,
      url: formValue.url,
      description: formValue.description,
    };

    return this.httpClient.put(url, data);
  }

  getListLocality(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        `${this.environment.incidentUrl}/incident-categories/by-type-or-parent`,
        { params: { incidentTypeCode: 'Localidad', } }
      )
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: item.id,
            name: item.name,
          }))
        )
      );
  }

  getListStatus(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.environment.camerasUrl}/status/`)
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.status,
          }))
        )
      );
  }

  getCameraType(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.environment.camerasUrl}/camera-type/`)
      // .get<any[]>(`assets/mock/camera-type.json`) //TODO mocked data for cammera types
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.type,
          }))
        )
      );
  }
}
