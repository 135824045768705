import { Component, Input, OnInit } from "@angular/core";
import { IGridTableCellDispatcher } from "@types-custom/models/ui/grid-table-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalNoInfoComponent } from "@ui-core/components/modal-no-info/modal-no-info.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { EnableDatexSubcategoryComponent } from "../enable-datex-subcategory/enable-datex-subcategory.component";
import { DatexCategoriesService } from "@official/services/data-grid-service/datex-categories-service/datex-categories.service";

@Component({
  selector: "sit-action-datex-category",
  templateUrl: "./action-datex-category.component.html",
  styleUrls: ["./action-datex-category.component.scss"],
})
export class ActionDatexCategoryComponent {

  @Input() dispatcher: IGridTableCellDispatcher;
  @Input() dataSource: DatexCategoriesService;
  @Input() category: any;

  constructor(
    private modalService: ModalService
  ) { }

  icon = Icon;

  handleClick() {
    const modal: IUniqueModalModel = {
      toRender: {
        component: EnableDatexSubcategoryComponent,
        data: {
          category: this.category,
          onComplete: () => this.dataSource?.init(),
        }
      },
      headerBackgroundClass: 'bg-color-background-1',
      icon: Icon.add_blue,
      title: 'Agregar subcategoria'
    };
    this.modalService.confirmationModal(modal);
  }
}
