<div class="button__container">
  <button
    [disabled]="disabled"
    class="d-flex align-items-center {{
      buttonProperties?.classAttributes?.buttonClass
    }}"
    [ngClass]="{
      'no-pre-icon': !buttonProperties?.preIcon,
      'no-post-icon': !buttonProperties?.postIcon,
      'disable-bttn': disabled
    }"
    mat-button
  >
    <ng-container *ngIf="buttonProperties?.preIcon as preIcon">
      <sit-icon
        class="postIcon {{ buttonProperties?.classAttributes?.iconClass }}"
        [icon]="preIcon"
      ></sit-icon>
    </ng-container>
    <ng-container *ngIf="buttonProperties?.label as label">
      <span class="{{ buttonProperties?.classAttributes?.spanClass }}">{{
        label
      }}</span>
    </ng-container>
    <ng-container *ngIf="buttonProperties?.postIcon as postIcon">
      <sit-icon
        class="postIcon {{ buttonProperties?.classAttributes?.iconClass }}"
        [icon]="postIcon"
      ></sit-icon>
    </ng-container>
  </button>
</div>
