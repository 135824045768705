<ng-container *ngTemplateOutlet="content; context: { activeIcon: (activeIcon$ | async) }"></ng-container>
<ng-template #content let-activeIcon="activeIcon">
  <section class="side-menu-content border-r-8 border-color-gray flex-sm-column {{
      isExpanded ? 'expanded d-md-block d-sm-flex' : 'd-md-none'
    }}">
    <div class="d-flex flex-row justify-content-between mb-md-5 d-none d-sm-flex d-md-flex">
      <div class="side-menu-info">
        <!--  Logo section  -->
        <div class="card-image mb-1" *ngIf="iconInfo[0]?.logo">
          <img [src]="iconInfo[0]?.logo" alt="Logo centro de gestión de bogota"
            title="Logo centro de gestión de bogota" />
        </div>
        <ng-container *ngIf="logoText">
          <span class="logo-text text-md-size-35 d-sm-block d-md-block d-none">{{ logoText }}</span>
        </ng-container>

        <ng-container>
          <div class="d-flex flex-sm-row gap-sm-column-1 d-sm-flex d-md-flex d-none">
            <div class="d-flex date date-icon">
              <sit-icon [icon]="Icon.calendar_w" [alt]="'Icono de Calendario'"
                [tooltip]="'Icono de Calendario'" [sizeClass]="'icon-size-16'"></sit-icon>
              <span class="calendar ml-8">{{ dateBoard }}</span>
            </div>
            <div class="d-flex date date-icon">
              <sit-icon [icon]="Icon.schedule_w" [alt]="'Icono de Hora'" [tooltip]="'Icono de Hora'"
                [sizeClass]="'icon-size-16'"></sit-icon>
              <span class="hora ml-8">{{ hourBoard }} </span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="userName">
          <div class="d-sm-flex date date-icon pt-sm-1 d-none">
            <sit-icon [icon]="Icon.account_w" [alt]="'Icono de usuario'" [tooltip]="'Icono de usuario'"
              [sizeClass]="'icon-size-16'"></sit-icon>
            <span class="calendar ml-8">{{ userName }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- left menu  -->
    <div class="d-flex px-1 flex-column collapse full-sm-height">
      <ng-container *ngFor="let item of iconInfo">
        <ng-container *ngIf="
            item.isVisible !== false &&
            item.isSubmenu === false &&
            item.isInfoMenu === false
          ">
          <div class="{{ item.classMenu }} item-content" [ngClass]="{'activelayer': act(item.group) }">
            <div class="side-menu-item" (click)="dispatchEvent(item.dispatcher$, item.event)"
              (mouseover)="item.isHover = true" (mouseout)="item.isHover = false"
              (keydown.enter)="item.isTriggered = true" (keydown.space)="item.isTriggered = true">
              <mat-accordion class="d-none d-md-block d-sm-block" (click)="dispatchEvent(item.dispatcher$, item.event)">
                <mat-expansion-panel (click)="
                    item.isSubmenu === false && item.event?.action
                      ? dispatchEvent(item.dispatcher$, item.event)
                      : $event.stopPropagation()
                  " [hideToggle]="item.isSubmenu === false && item.event?.action" (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false">
                  <mat-expansion-panel-header [ngClass]="{
                      active: item.event?.action === activeIcon || item.isHover
                    }">
                    <mat-panel-title>
                      <sit-icon [icon]="
                          item.event?.action === activeIcon || item.isHover || item.isActive
                            ? item.tagSelected
                            : item.tag
                        " alt="{{ item.alt }}" [tooltip]="item.tooltipTag"></sit-icon>
                      <span [ngClass]="{active: item.isHover}" class="addText">{{ item.tooltipTag }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-container *ngFor="let submenu of iconInfo">
                    <ng-container *ngIf="
                        submenu.isSubmenu === true &&
                        submenu.group === item.group
                      ">
                      <div [ngClass]="{
                          active:
                            submenu.event?.action === activeIcon ||
                            submenu.isHover
                        }" (click)="
                          dispatchEvent(submenu.dispatcher$, submenu.event);" class="container-sub">
                        <button class="submenu" matTooltipPosition="above" matTooltipClass="custom-tooltip"
                          matTooltip="{{ submenu.tooltipTag }}">
                          <sit-icon [icon]="
                              submenu.event?.action === activeIcon ||
                              submenu.isHover
                                ? submenu.tagSelected
                                : submenu.tag
                            " alt="{{ submenu.alt }}" [tabIndex]="-1"></sit-icon>
                          <span class="addText text-submenu">{{ submenu.tooltipTag }}
                          </span>
                        </button>
                      </div>
                      <mat-divider class="full-widht {{ item.classDivider }}" color="primary"></mat-divider>
                    </ng-container>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>

              <button class="side-menu-item-icon d-sm-none d-md-none">
                <sit-icon [icon]="
                    item.event?.action === activeIcon || item.isHover
                      ? item.tagSelected
                      : item.tag
                  " alt="{{ item.alt }}" [tabIndex]="-1" sizeClass="icon-size-32"></sit-icon>
                <span [ngClass]="{
                    active: item.event?.action === activeIcon || item.isHover
                  }" class="addText">{{ item.tooltipTag }}</span>
              </button>

              <ul [ngClass]="{ 'd-flex': item.isTriggered }">
                <ng-container *ngFor="let submenu of iconInfo" class="">
                  <ng-container *ngIf="
                      submenu.isSubmenu === true && submenu.group === item.group
                    ">
                    <div
                    [ngClass]="{
                      'isLastItemA': submenu.isLastItemSubMenu && submenu.event?.action === activeIcon,
                      'active': submenu.event?.action === activeIcon || submenu.isHover
                    }"
                      class="d-sm-none d-md-none"
                      (click)="dispatchEvent(submenu.dispatcher$, submenu.event); isActive(submenu)"
                      (mouseover)="submenu.isHover = true" (mouseout)="submenu.isHover = false" 
                      (focusout)="submenu.isLastItemSubMenu && (item.isTriggered = false)"
                      >
                      <button class="submenu " matTooltipPosition="above" matTooltipClass="custom-tooltip"
                        matTooltip="{{ submenu.tooltipTag }}"
                         [ngClass]="{
                            isLastItem:submenu.isLastItemSubMenu 
                          }"
                        >
                        <sit-icon [icon]="
                            submenu.event?.action === activeIcon ||
                            submenu.isHover
                              ? submenu.tagSelected
                              : submenu.tag
                          " alt="{{ submenu.alt }}" [tabIndex]="-1"></sit-icon>
                      </button>
                    </div> 
                  </ng-container>
                </ng-container>
              </ul>
            </div>
            <mat-divider class="full-widht {{ item.classDivider }}" color="primary"></mat-divider>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <!-- menu inferior responsive -->
    <div class="d-flex flex-row justify-content-between mb-md-5 d-none d-sm-block d-md-flex info-menu">
      <ng-container *ngFor="let item of iconInfo">
        <ng-container *ngIf="
            item.isVisible !== false &&
            item.isSubmenu === false &&
            item.isInfoMenu === true
          ">
          <div class="{{ item.classMenu }}">
            <div class="side-menu-item" (click)="dispatchEvent(item.dispatcher$, item.event)"
              (mouseover)="item.isHover = true" (mouseout)="item.isHover = false">
              <mat-accordion class="d-none d-md-block d-sm-block">
                <mat-expansion-panel  (click)="
                item.isSubmenu === false && item.event?.actionType
                  ? dispatchEvent(item.dispatcher$, item.event)
                  : $event.stopPropagation()
              "  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false" [hideToggle]="item.isSubmenu === false && item.event?.actionType">
                  <mat-expansion-panel-header [ngClass]="{
                      active: item.event?.action === activeIcon || item.isHover
                    }">
                    <mat-panel-title>
                      <sit-icon [icon]="
                          item.event?.action === activeIcon || item.isHover
                            ? item.tagSelected
                            : item.tag
                        " alt="{{ item.alt }}"></sit-icon>
                      <span *ngIf="item?.textInline" class="addText">{{ item.textInline }}
                      </span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-divider class="full-widht {{ item.classDivider }}" color="primary"></mat-divider>
                  <ng-container *ngFor="let submenu of iconInfo">
                    <ng-container *ngIf="
                        submenu.isSubmenu === true &&
                        submenu.group === item.group
                      ">
                      <div [ngClass]="{
                          active:
                            submenu.event?.action === activeIcon ||
                            submenu.isHover
                        }" class="container-sub" (click)="
                          dispatchEvent(submenu.dispatcher$, submenu.event)
                        ">
                        <button   class="submenu" matTooltipPosition="above" matTooltipClass="custom-tooltip"
                          matTooltip="{{ submenu.tooltipTag }}" [ngClass]="{
                            'd-none': !submenu.isVisible, 'ml-0': !submenu.tag
                          }">
                          <sit-icon *ngIf="submenu.tag" [icon]="
                              submenu.event?.action === activeIcon ||
                              submenu.isHover
                                ? submenu.tagSelected
                                : submenu.tag
                            " alt="{{ submenu.alt }}" [tabIndex]="-1"></sit-icon>
                          <span class="addText text-submenu">{{ submenu.tooltipTag }}
                          </span>
                        </button>
                      </div>
                      <mat-divider class="full-widht {{ item.classDivider }}" color="primary"></mat-divider>
                    </ng-container>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <mat-divider class="full-width {{ item.classDivider }} divider-info-foot" color="primary"></mat-divider>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </section>
</ng-template>

<div class="side-menu-backdrop {{ isExpanded ? 'd-md-block' : 'd-md-none' }}"></div>
