<div class="form-container pr-24 pl-24 mb-32 mt-32">
  
    <form [formGroup]="registerFormGroup" class="login-form">
      <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
        <mat-form-field
                class="textWhite"
                appearance="outline"
                [ngClass]="{
            'error-field':
              registerFormGroup.controls.businessAreaId.invalid &&
              registerFormGroup.controls.businessAreaId.touched
          }"
        >
          <mat-label class="text-white">Área del negocio</mat-label>
          <select matNativeControl formControlName="businessAreaId">
            <option *ngFor="let item of bussines; let i=index" [value]="item.id">{{item.name}}</option>
          </select>
          <mat-error>
            {{ businessErrorMessage }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
        <mat-form-field
                class="textWhite"
                appearance="outline"
                [ngClass]="{
            'error-field':
              registerFormGroup.controls.code.invalid &&
              registerFormGroup.controls.code.touched
          }"
        >
          <mat-label class="text-white">Código Único de Identificación (serial,placa,número de inventario)</mat-label>
          <input matInput formControlName="code" />
          <mat-error>
            {{ codeErrorMessage }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mat-input d-flex fd-row align-items-center mb-5 mt-7 ml-7">
          <mat-label class="text-white" >Tipo de recurso 
            <span *ngIf="registerFormGroup.controls.resourceTypeId.invalid  &&
            registerFormGroup.controls.resourceTypeId.touched" class="
            text-red">*</span> 
            <span *ngIf="registerFormGroup.controls.resourceTypeId.valid ||
            registerFormGroup.controls.resourceTypeId.untouched">*</span>
          </mat-label>
      </div>

      <div class="d-flex fd-row align-items-center mb-5">
        <mat-chip-listbox [formControlName]="'resourceTypeId'">
          <mat-chip-option *ngFor="let item of typeResources; let i=index" [value]="item.id" [selected]="item.id == this.physicalResourceEdited.resourceTypeId">{{item.name}}</mat-chip-option>
        </mat-chip-listbox>
      </div>

      <mat-error *ngIf="registerFormGroup.controls.resourceTypeId.invalid  &&
       registerFormGroup.controls.resourceTypeId.touched" style="margin-left: 1.2rem;font-size: 12.5px;"
       appearance="outline">
          <span>{{ typeResourceErrorMessage }}</span>
      </mat-error>
        <!-- </mat-form-field> -->

      <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
        <mat-form-field
                class="textWhite"
                appearance="outline"
                [ngClass]="{
            'error-field':
              registerFormGroup.controls.feature.invalid &&
              registerFormGroup.controls.feature.touched
          }"
        >
          <mat-label class="text-white">Característica</mat-label>
          <input matInput formControlName="feature" />
          <mat-error>
            {{ featureErrorMessage }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mat-input d-flex fd-row align-items-center mb-5 mt-10 ml-7">
          <mat-label class="text-white">Condición
            <span *ngIf="registerFormGroup.controls.conditionId.invalid  &&
            registerFormGroup.controls.conditionId.touched" class="
            text-red">*</span> 
            <span *ngIf="registerFormGroup.controls.conditionId.valid ||
            registerFormGroup.controls.conditionId.untouched">*</span>
          </mat-label>
      </div>

      <div class="d-flex fd-row align-items-center mb-7">
        <mat-chip-listbox [formControlName]="'conditionId'">
          <mat-chip-option *ngFor="let item of conditions; let i=index" [value]="item.id" [selected]="item.id == this.physicalResourceEdited.conditionId">{{item.name}}</mat-chip-option>
        </mat-chip-listbox>
      </div>

      <mat-error *ngIf="registerFormGroup.controls.conditionId.invalid  &&
       registerFormGroup.controls.conditionId.touched" style="margin-left: 1.2rem;font-size: 12.5px;">
        {{ conditionErrorMessage }}
      </mat-error>

      <div class="mat-input d-flex fd-row align-items-center mb-16 mt-15">
        <mat-form-field
                class="textWhite"
                appearance="outline"
                [ngClass]="{
            'error-field':
              registerFormGroup.controls.feature.invalid &&
              registerFormGroup.controls.feature.touched
          }"
        >
          <mat-label class="text-white">Observaciones adicionales</mat-label>
          <textarea matInput formControlName="observations"></textarea>
          <mat-error>
            {{ observationsErrorMessage }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-3-em"></div>

      <drop-files-physical-resources [extensionAllowedEnum]="{'JPEG':'.jpeg','JPG':'.jpg','PNG':'.png'}" [fileSubmited]="false" [filesEdited]="filesEdit" (filesDropped)="loadFiles($event)"></drop-files-physical-resources>

      <div class="mb-1-em"></div>
      
    </form>
  
    <!-- <ng-container *ngIf="!userEdited.id"> -->
    <div class="modal-actions d-flex align-items-end justify-end pt-20">
      <ng-container>
        <sit-button
                (click)="onCancel()"
                [buttonProperties]="buttonCancelAttributes"
        ></sit-button>
      </ng-container>
      <ng-container>
        <sit-button
                  (click)="
                  registerFormGroup.valid ? validateUserSubmit() : validateFormGroup()
                  "
                [buttonProperties]="buttonSaveAttributes"
        ></sit-button>
      </ng-container>
    </div>
    <!-- </ng-container> -->
  </div>
  