import { Icon } from '@types-custom/models/ui/icon-model';
import { Observable } from 'rxjs';

export interface IModalAforoModel {
  icon: Icon;
  alt: string;
  id: string;
  address: string;
  title: string;
  avgSpeed: number;
  flowCount: number;
  lightVehicles: number;
  heaveyVehicles: number;
  largeHeaveyVehicles: number;
  trucks: number;
  buses: number;
  bikes: number;
  aforoDataSource?: AbstractAforoSensorDataSource;
}

export abstract class AbstractAforoSensorDataSource {
  abstract getLayerAforoGeoJson(): Observable<any>;
  abstract getModalAforoMeasurements(sensorId: string): Observable<any>;
  abstract getPanelAforoMeasurements(): Observable<any>;
}

export enum vehicleTypeEnum {
  BUS = 'bus',
  CARS = 'cars',
  MOTORBIKES = 'motorbikes',
  TRUCKS = 'trucks',
  VANS = 'vans',
  UNKNOWN = 'unknown',
}

export interface IOptionTypeVehicle {
  type: vehicleTypeEnum;
  icon: Icon;
  alt: string;
  active: boolean;
  value: number;
  tooltip:string;
}
