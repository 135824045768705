<div class="full-height d-flex fd-col">
    <div class="d-flex fd-col full-height overflow-y-auto custom-scrollbar" tabindex="0">
      <ng-container *ngIf="panelOnManagement === MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS">
        <div class="d-flex fd-row flex-md-column f-wrap justify-space-evenly px-24-px py-05 gap-10-px mt-24">
  
          <div *ngFor="let stat of statsMapper | keyvalue"
            class="stat-info-card flex-grow border-r-8 text-color-primary-1-dark bg-color-background-2 py-05">
            <sit-icon [icon]="stat.value.icon" class="card-icon" [sizeClass]="'icon-size-48'"></sit-icon>
            <span class="card-title text-size-20 text-weight-600"> {{ stat.value.count }} </span>
            <span class="text-size-14"> {{ stat.value.name }} </span>
          </div>
        </div>
      </ng-container>
  
      <ng-container *ngIf="panelAction$ | async as panelAction">
        <div class="d-flex fd-row f-wrap justify-space-evenly pt-16 px-24-px gap-10-px">
          <sit-button [buttonProperties]="searchButtonProps" class="width-49 flex-grow"
            (click)="onPanelManageAction(panelManageActionsEnum.SEARCH)" [ngClass]="{
              'active-action': panelAction === panelManageActionsEnum.SEARCH
            }"></sit-button>
        </div>
  
        <!-- RESULTS GRID -->
        <ng-container *ngIf="panelAction === panelManageActionsEnum.GRID">
          <div class="d-flex fd-col" [@fadeInOut]>
            <div class="bg-color-background-1 d-flex mt-1 mx-12-px border-r-8  ml-16 left-line">
              <div class="left-subtitle-block bg-color-primary-1"></div>
              <div class="d-flex full-width py-05">
                <sit-icon [icon]="iconTitlePanel.icon" class="text-blue-1 ml-28" [sizeClass]="'icon-size-24'"
                  [alt]="iconTitlePanel.alt"></sit-icon>
                <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-white align-self-center m-0">
                  {{ titleGridPanel }}
                </h3>
              </div>
            </div>
            <list-card-page [properties]="gridPageProps" (gridAction)="handleDownloadGridAction($event)"></list-card-page>
          </div>
        </ng-container>
  
  
        <!-- DETAIL -->
        <ng-container *ngIf="panelAction === panelManageActionsEnum.DETAIL">
          <div class="d-flex fd-col" [@fadeSlideInOut]>
            <details-incidents-panel [serviceDataSource]="servicePanelDataSource" [panelOnManagement]="panelOnManagement"
              [panelAction$]="panelAction$" [gridRowData]="gridRowData" [properties]="properties"
              class="d-flex fd-col"></details-incidents-panel>
          </div>
        </ng-container>
  
        <!-- SEARCH -->
        <ng-container *ngIf="panelAction === panelManageActionsEnum.SEARCH">
          <div [@fadeSlideInOut]>
            <search-manage-panel [serviceDataSource]="servicePanelDataSource" [panelOnManagement]="panelOnManagement"
              [panelAction$]="panelAction$" [filterDataSource]="filterManageDataSource"></search-manage-panel>
          </div>
        </ng-container>
  
      </ng-container>
    </div>
  </div> 