<div class="grid-container text-white">
  <div class="card">
    <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
    <h5 class="text-button-clean-filter">Id Incidente</h5>
    <span class="text-size-16 text-weight-600 mt-1-em">
      {{properties.incidentById?.id}}</span>
  </div>
  <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Categoria</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.classesName}}</span>
  </div>
  <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Subcategoria</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.typeName}}</span>
  </div>
</div>

<div class="grid-container text-white">
  <div class="card">
    <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
    <h5 class="text-button-clean-filter">Zona</h5>
    <span class="text-size-16 text-weight-600 mt-1-em">
      {{properties.incidentById?.zoneName ? properties.incidentById?.zoneName : 'No registrado'}}</span>
  </div>
  <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Corredor</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.brokerName ? properties.incidentById?.brokerName : 'No registrado'}}</span>
  </div>
  <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Fuente</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.sourceName ? properties.incidentById?.sourceName : 'No registrado'}}</span>
  </div>
</div>

<div class="grid-container text-white mb-2-em">
  <div class="card">
    <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
    <h5 class="text-button-clean-filter">Gravedad</h5>
    <span class="text-size-16 text-weight-600 mt-1-em">
      {{properties.incidentById?.gravityName ? properties.incidentById?.gravityName : 'No registrado'}}</span>
  </div>
  <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <h5 class="text-button-clean-filter">Prioridad</h5>
      <span class="text-size-16 text-weight-600 mt-1-em">
        {{properties.incidentById?.priorityName ? properties.incidentById?.priorityName : 'No registrado'}}</span>
  </div>
</div>

<div class="table-responsive w-table rounded-top">

  <table mat-table [@.disabled]="true" [dataSource]="dataSource"
   class="instance-table table table-borderless wi-100"
   style="overflow-x: none;">

   <ng-container matColumnDef="names">
       <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-0 text-weight-600 colorColumn text-white text-center">
         Nombre Completo
       </th>
       <td class="border-end colorRow" mat-cell *matCellDef="let item">{{item.names + " " + item.lastNames}}</td>
   </ng-container>

   <ng-container matColumnDef="phone">
       <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-0 text-weight-600 colorColumn text-white text-center">Celular</th>
       <td class="border-end colorRow" mat-cell *matCellDef="let item">{{item?.phone}}</td>
   </ng-container>

   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 </table>  
 </div>

<div class="modal-actions d-flex align-items-center justify-center pt-20">
  <ng-container>
    <sit-button
            (click)="onCancel()"
            [buttonProperties]="buttonCancelAttributes"
    ></sit-button>
  </ng-container>
</div>
  