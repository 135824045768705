<div
        class="modal-traffic w-auto h-auto d-flex fd-col text-blue text-weight-600 pt-30"
>
    <div class="wrapper-speed pb-20">
        <div class="d-flex justify-space-around full-width py-container-speed sit-card">
            <div class="container-graphic-speed pt-10">
                <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
            </div>
        </div>
        <div class="d-flex justify-space-around full-width py-container-speed sit-card">
            <div class="container-graphic-speed pt-10">
                <sit-incidents [properties]="avgIncidentsData"></sit-incidents>
            </div>
        </div>
    </div>

    <div class="d-flex fd-row border-b-s-1 border-color-separator mb-16"></div>

    <div class="modal-body d-flex fd-col mb-1 justify-space-evenly">
        <mat-tab-group class="text-size-16 text-white text-weight-500 mb-25 text-md-size-16 test-label custom-tab-group">
            <mat-tab label="Rango de Velocidad">
                <div class="d-flex full-width speed-range-chart">
                    <sit-chart-line
                            [properties]="dataGraphicList"
                            [colorScale]="colorScale"
                            style="width: 100%"
                    >
                    </sit-chart-line>
                </div>
            </mat-tab>
            <mat-tab label="Incidentes Verificados">
                <div class="IncidentsContainer">
                    <div class="sit-card" *ngFor="let item of dataHistoryIncidents">
                        <div class="title text-color-primary-1">ID {{item.incidentId}}</div>
                        <div class="content">{{item.className}} <br> {{item.typeName}}</div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
