<div class="d-flex fd-col pt-16">

    <div class="d-flex fd-col gap-16-px">
        <div class="d-flex lh-20 justify-end">
            <span class="text-color-primary-1 text-weight-600 text-size-18">
                {{ properties.incidentStatusName }}
            </span>
        </div>
        <div class="container-indicators">

            <div class="card-indicator">
                <span class="card-title">{{ properties?.incidentClassName }}</span>
                <span class="card-subtitle">Clase</span>
            </div>
            <div class="card-indicator">
                <span class="card-title">{{ properties?.incidentTypeName }}</span>
                <span class="card-subtitle">Tipo</span>
            </div>
            <div class="card-indicator">
                <span class="card-title">{{ properties?.incidentPriorityName }}</span>
                <span class="card-subtitle">Prioridad</span>
            </div>
            <div class="card-indicator">
                <span class="card-title">{{ properties?.incidentLocalityName }}</span>
                <span class="card-subtitle">Localidad</span>
            </div>
            
        </div>
        <div class="footer-indicators">
            <div class="d-flex fd-row gap-8-px">
                <sit-icon [icon]="Icon.calendar_outlined_white"
                    [sizeClass]="'icon-size-20'"
                    alt="icono calendario">
                </sit-icon>
                <span class="text-size-16">                
                    {{ properties?.incidentTime | date:'dd/MM/yyyy' }}
                </span>
            </div>
            <div class="d-flex fd-row gap-8-px">
                <sit-icon [icon]="Icon.time"
                    [sizeClass]="'icon-size-20'"
                    alt="icono reloj">
                    </sit-icon>
                <span class="text-size-16">Hora</span>
                <span class="text-size-16">
                    {{ properties?.incidentTime | date:'hh:mm a' }}
                </span>
            </div>
            
            <span>
                Fuente
            </span>
        </div>
    </div>

    <div class="section-search">
        <div class="container-title">
            <sit-icon [icon]="Icon.assigment_white"
                [sizeClass]="'icon-size-32'"
                class="d-flex"
                alt="icono asignar recursos">
            </sit-icon>
            <span class="title-search">
                Asignar recursos
            </span>
        </div>
        <div class="container-input-search">
            <mat-form-field class="field-search" appearance="outline" >
                    <!-- <mat-label class="text-white">Recurso a asignar</mat-label> -->
                    <input type="text"
                        matInput
                        placeholder="Recurso a asignar"
                        #trigger="matAutocompleteTrigger"
                        [formControl]="resourceControl"
                        [matAutocomplete]="autoComplete">
                    <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                    <mat-option *ngFor="let option of listResourceHuman | async" [value]="option.id" [disabled]="true">

                        <span class="text-option">
                            <span class="text-color-primary-1">{{option?.userEntityId}} -</span> {{ option?.firstName }} {{option?.lastName}}
                        </span>

                        <div class="icon-add">
                            <sit-icon [icon]="Icon.add_w"
                            [sizeClass]="'icon-size-26'"
                            alt="icono agregar"
                            class="action-add"
                            (click)="btnAddResource(option, trigger)">
                            </sit-icon>
                        </div>
                        
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
        </div>
    </div>
    

    <div class="section-resources">
        <div class="resource-header">
            <span class="title-resource">
                Recursos asignados
            </span>
        </div>

        <div class="container-resources custom-scrollbar">
            <ng-container *ngFor="let item of resourcesAssignedList">
    
                <div class="container-resource">
                    <div class="icon-info">
                        <sit-icon
                        [icon]="iconResourceAssignedMap[item?.groupName]"
                         alt="icono grupo"
                         sizeClass="icon-size-40"
                         ngClass="icon-bg">
                        </sit-icon>
                    </div>
                    <div class="info-resource">
                        <span class="title-resource text-color-white">
                            <span class="text-color-primary-1">{{ item?.indicative }} - </span> {{ item?.names }} {{ item?.lastNames }}
                        </span>                
                        <span class="subtitle-resource">
                            {{ item?.groupName }}
                        </span>
                    </div>
                    <div class="icon-action">
                        <sit-icon-button
                            [alt]="'icono botón desasignar'"
                            [icon]="Icon.icon_delete_w"
                            sizeClass="icon-size-16"
                            class="action-delete"
                            (click)="btnRemoveResource(item)"
                        ></sit-icon-button>
                    </div>
                </div>
        
            </ng-container>
        </div>
    </div>

    

</div>
