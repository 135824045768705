<ng-container *ngIf="iconPath; else materialIcon">
  <img
    matTooltipPosition="above"
    matTooltipClass="custom-tooltip"
    matTooltip="{{ tooltip }}"
    src="{{ iconPath }}"
    [ngClass]="sizeClass"
    alt="{{ alt }}"
    tabIndex="{{ tabIndex ?? 0 }}"
  />
</ng-container>
<ng-template #materialIcon>
  <mat-icon [ngClass]="sizeClass" [attr.alt]="alt"
    >{{ icon }} tabIndex="{{ tabIndex ?? 0 }}"</mat-icon
  >
  <span class="cdk-visually-hidden" title="{{ alt }}"></span>
</ng-template>
