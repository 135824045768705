import {Component, Input, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ScrollHandlingEventsEnum} from "@types-custom/models/ui/scroll-model";
import {Icon} from "@types-custom/models/ui/icon-model";
import {ICardFieldResourceModel, KPIFieldResources} from "@types-custom/models/ui/card-field-resources";
import {FieldResourcesService} from "@shared/services/layer-service/field-resources/field-resources.service";
import {ICardWazeAlertsModel} from "@types-custom/models/ui/card-alert-waze.model";
import * as am4core from "@amcharts/amcharts4/core";
import {ITimeSeriesGraphicModel} from "@types-custom/models/ui/chart-line-color-model";
import {string, time} from "@amcharts/amcharts4/core";

import { MapViewerPageComponent } from '@official/pages/map-viewer/map-viewer-page.component';
import { ActionType, IMenuEvent } from "@types-custom/models/ui/menu.model";
import { MapLayers } from '@shared/utils/mappers/layers-map.model';
@Component({
  selector: "sit-panel-field-resources",
  templateUrl: "./panel-field-resources.component.html",
  styleUrls: ["./panel-field-resources.component.scss"],
})
export class PanelFieldResourcesComponent implements OnInit {
  constructor(private mapViewerPageComponent: MapViewerPageComponent){

  }
  Icon = Icon;
  cards: ICardFieldResourceModel[] = [];

  @Input() serviceDataSource: FieldResourcesService;

  dataGraphicList:ITimeSeriesGraphicModel ;
  colorScale: any = [{}];
  fecha:any;

  GroupGuideGeneral : KPIFieldResources = {
    icon : Icon.GuideGroup_Blue,
    name : 'Grupo Guia',
    enabled : 0,
    disabled : 0,
    executionPercentage : 0
  };
  CentipedeGeneral  : KPIFieldResources = {
    icon : Icon.Centipede_Blue,
    name : 'Ciempiés',
    enabled : 0,
    disabled : 0,
    executionPercentage : 0
  };
  CycleGuideGeneral : KPIFieldResources = {
    icon : Icon.SchoolCycle_Blue,
    name : 'Al Colegio en Bici',
    enabled : 0,
    disabled : 0,
    executionPercentage : 0
  };

  cardsLocality : any[] = [];

  panelActionsDispatcher!: BehaviorSubject<
      ScrollHandlingEventsEnum | undefined
  >;

  ngOnInit() {
    this.getGeneralStacks();
    this.getLocalityStacks();
    this.getGraphs();
  }

  private getGeneralStacks() {
    this.serviceDataSource.getIndicatorsByGroup().subscribe((res : any) => {
      res.forEach((item : any) => {
        if(item.id === 17) {
          this.GroupGuideGeneral.disabled             = item.humanWithActivities;
          this.GroupGuideGeneral.enabled              = item.humanWithActivityComplete;
          this.GroupGuideGeneral.executionPercentage  = item.executionPercentage
        }
        else if(item.id === 19){
          this.CycleGuideGeneral.disabled             = item.humanWithActivities;
          this.CycleGuideGeneral.enabled              = item.humanWithActivityComplete;
          this.CycleGuideGeneral.executionPercentage  = item.executionPercentage
        }
        else {
          this.CentipedeGeneral.disabled              = item.humanWithActivities;
          this.CentipedeGeneral.enabled               = item.humanWithActivityComplete;
          this.CentipedeGeneral.executionPercentage   = item.executionPercentage
        }
      })
    });
  }

  private getLocalityStacks() {
    this.serviceDataSource.getIndicatorsByLocality().subscribe((res : any) => {
      this.setListLocality(res)
    });
  }

  private getGraphs() {
    this.serviceDataSource.getResourcesGraph().subscribe((res : any) => {
      let data : any = {};
      res.forEach((obj : any) => {
        const hour = new Date(obj.time).getHours();

        if (!data[hour]) {
          data[hour] = {};
        }

        if (!data[hour][obj.resourceType]) {
          data[hour][obj.resourceType] = 0;
        }

        data[hour][obj.resourceType] += obj.count;
      });

      const arrayResponse = Object.keys(data).map(hour => {
        return { hour: parseInt(hour), ...data[hour] };
      });
      this.setGraphicAlert(arrayResponse);
    });
  }

  private setGraphicAlert(data: any): void {

    this.dataGraphicList = {
      data: data,
      label: ['Ciempiés','Al Colegio en Bici','Grupo Guía'],
      labelX: 'Hora',
      labelY: 'Recursos',
      colorLine:['#FF5722','#9271C5','#FF8A8E']
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#92FDF6' }];
  }

  private setListLocality(res: any) {
    res.forEach((item : any) => {
      const DataCycleSchool   = item.groups.find((item : any) => item.id === 19);
      const DataCentipede     = item.groups.find((item : any) => item.id === 20);
      const DataGuideGroup    = item.groups.find((item : any) => item.id === 17);
      this.cardsLocality.push({
        localityName : item.name,
        cycleSchool : {
          icon : Icon.SchoolCycle_Blue,
          name : 'Al Colegio en Bici',
          enabled : DataCycleSchool?.humanWithActivityComplete,
          disabled : DataCycleSchool?.humanWithActivities,
          executionPercentage : DataCycleSchool?.executionPercentage
        },
        GroupGuide : {
          icon : Icon.GuideGroup_Blue,
          name : 'Grupo Guia',
          enabled : DataGuideGroup?.humanWithActivityComplete,
          disabled : DataGuideGroup?.humanWithActivities,
          executionPercentage : DataGuideGroup?.executionPercentage
        },
        Centipede : {
          icon : Icon.Centipede_Blue,
          name : 'Ciempiés',
          enabled : DataCentipede?.humanWithActivityComplete,
          disabled : DataCentipede?.humanWithActivities,
          executionPercentage : DataCentipede?.executionPercentage
        }
      })
    })
  }
  
}
