import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private aesEncryptKey: any;
  private keySeparator = 'skgtecnologia*';

  encryptToken(token: string) {
    this.aesEncryptKey = uuidv4();
    const encryptedAESToken = AES.encrypt(token, this.aesEncryptKey).toString();
    return encryptedAESToken + this.keySeparator + this.aesEncryptKey;
  }

  decryptToken(encryptedToken: string) {
    this.aesEncryptKey = encryptedToken
      .substring(encryptedToken.indexOf(this.keySeparator))
      .replace(this.keySeparator, '');

    const decryptedToken = AES.decrypt(
      encryptedToken.substring(0, encryptedToken.indexOf(this.keySeparator)),
      this.aesEncryptKey
    ).toString(enc.Utf8);
    return decryptedToken;
  }
}
