import { Icon } from '@types-custom/models/ui/icon-model';
import { BehaviorSubject } from 'rxjs';

export enum ActionType {
  Modal = 'modal',
  Map = 'map',
  Panel = 'panel',
  Route = 'route',
}

export interface IBaseMenu {
  requiredRole?: string[];
}
export interface IMenuModel<T> extends IBaseMenu {
  icon?: Icon;
  text?: string;
  textInline?: string;
  action?: T;
  subMenu?: IMenuModel<T>[];
  data?: any;
  dispatcher?: BehaviorSubject<IMenuEvent<T> | undefined>;
  isVisible?: BehaviorSubject<boolean> | boolean;
  alt?: string;
  classMenu?: string;
  url?: string;
  actionType?: ActionType;
  returnPanel?: boolean;
  actionPanel?: T;
  iconsubmenu?:string;
}

export interface IMenuEvent<A = any, D = any, C = any> {
  action: A;
  data?: D;
  actionType?: ActionType;
  returnMap?: boolean;
  returnAction?: C;
}
