<div>
    <ng-container *ngIf="dataSource">
        <div class="full-width menu-grid-setting">
            <ng-container *ngFor="let item of dataSource | async; let index = index">
                <button mat-menu-item 
                    class="btn-grid-setting"
                    (click)="handleClick(headers[2].dispatcher, item, headers[2].key, index)"
                >
                    <div class="setting-icon">
                        <ng-container>
                            <sit-icon [icon]="icons[(index <= (icons.length - 1) ? index : icons.length - 1)]" class="set-grid-menu-class"
                            [sizeClass]="'icon-size-96'"></sit-icon>
                        </ng-container>
                        <span> {{ item[headers[0].key] }} </span>
                    </div>
                </button>

            </ng-container>
        </div>
    </ng-container>

</div>