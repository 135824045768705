<div class="d-flex fd-col full-height overflow-y-auto custom-scrollbar" tabindex="0">
  <label class="d-flex mb-8 text-size-14 text-gray-3 pt-md-15 text-justify lh-150" style="padding: 1em">
    Permite consultar la cantidad de puntos sensorizados en la ciudad, su ubicación y su actividad en las últimas 8 horas. Haga clic en un punto sobre el mapa para consultar la información.
  </label>

  <div class="bg-color-secondary-4 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-secondary-2 align-self-center">
        DATOS REPORTADOS
      </h3>
    </div>
  </div>

  <div class="d-flex fd-col p-1-em content-indicator-wrapper">
    <div class="content-indicadores d-flex fd-row">
      <div class="content-indicator d-flex fd-col p-05 text-color-secondary-2 bg-color-background-1">
        <div>
          <label class="">CO2</label>
        </div>
        <div class="content-icon">
          <sit-icon [icon]="Icon.co2_w" [sizeClass]="'icon-size-46 icon-sm-size-28'"
            alt="nube co2 icono indicador de concentración"></sit-icon>
          <span class="content-text">{{ avgCo2 }}</span>
          <label>ppm</label>
        </div>
      </div>
      <div class="content-indicator d-flex fd-col p-05 text-color-secondary-2 bg-color-background-1">
        <div>
          <label class="">Temperatura</label>
        </div>
        <div class="content-icon">
          <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-46 icon-sm-size-28'"
            alt="termómetro icono indicador de temperatura"></sit-icon>
          <span class="content-text">{{ avgTemperature }}°C</span>
        </div>
      </div>
    </div>
    <div class="content-indicadores d-flex fd-row">
      <div class="content-indicator d-flex fd-col p-05 text-color-secondary-2 bg-color-background-1">
        <div>
          <label class="">Humedad</label>
        </div>
        <div class="content-icon">
          <sit-icon [icon]="Icon.gota" [sizeClass]="'icon-size-46 icon-sm-size-28'"
            alt="gota icono indicador de humedad"></sit-icon>
          <span class="content-text">{{ avgHumidity }}%</span>
        </div>
      </div>
      <div class="content-indicator d-flex fd-col p-05 text-color-secondary-2 bg-color-background-1">
        <div>
          <label class="">Presión</label>
        </div>
        <div class="content-icon-pressure">
          <sit-icon [icon]="Icon.check_w" [sizeClass]="'icon-size-46 icon-sm-size-28'"
            alt="flecha apuntando  hacia abajo icono indicador de presión"></sit-icon>
          <span class="content-text">{{ avgPressure }}</span>
          <label>hPa</label>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-color-secondary-4 d-flex">
    <div class="left-subtitle-block bg-color-primary-1"></div>
    <div class="d-flex full-width py-05">
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-secondary-2 align-self-center">
        UBICACIÓN
      </h3>
    </div>
  </div>

  <div>
    <table class="table-co2">
      <thead class="text-color-secondary-2 text-size-12">
        <th [style.width.%]="45">Dirección</th>
        <th>Co2</th>
        <th>Temperatura</th>
        <th>Humedad</th>
        <th>Presión</th>
      </thead>
      <tbody class="text-color-secondary-2 text-size-12">
        <ng-container *ngFor="let item of cards">
          <tr (click)="openModal(item)">
            <td>{{ item.location }}</td>
            <td>{{ item.carbonDioxide }}</td>
            <td>{{ item.temperature }}</td>
            <td>{{ item.humidity }}</td>
            <td>{{ item.pressure }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
