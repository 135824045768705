import { Component, Input } from "@angular/core";
import { IIncidentParameter } from "@shared/models/incident-parameter.model";

import {
  IGridTableCellDispatcher,
} from '@types-custom/models/ui/grid-table-model';
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { ModalService } from "@ui-core/services/modal/modal.service";
import { IncidentParametersComponent } from "../../incident-parameters/incident-parameters.component";
import { ModalNoInfoComponent } from "@ui-core/components/modal-no-info/modal-no-info.component";


@Component({
  selector: "sit-actions-incident-parameters",
  templateUrl: "./actions-incident-parameters.component.html",
  styleUrls: ["./actions-incident-parameters.component.scss"],
})
export class ActionsIncidentParametersComponent {
  @Input() dispatcher: IGridTableCellDispatcher;
  @Input() parameter: IIncidentParameter;

  constructor(
    public modalService: ModalService,
    private service: IncidentParametersComponent
  ) { }

  get Icon() {
    return Icon;
  }

  toggleActivate(param: IIncidentParameter) {
    if (!param.state) {
      const modal: IUniqueModalModel = {
        toRender: {
          component: ModalNoInfoComponent,
          data: {
            infoMessage: `¿Está seguro que desea desactivar ${param.name}?`,
            icon: Icon.questions_w,
          },
        },
        headerBackgroundClass: 'bg-color-background-1',
        confirm: () => {
          //console.log(param);
          this.service.setParam(param);
          this.modalService.closeConfirmationModal();
        },
        cancel: () => {
          param.state = !param.state;
          this.modalService.closeConfirmationModal();
        },
      };
      setTimeout(() => { this.modalService.confirmationModal(modal); });
    } else {
      this.service.setParam(param);
    }
  }

}
