import { Component, Input } from "@angular/core";
import { Icon } from "@types-custom/models/ui/icon-model";
import { Subject } from "rxjs";
import { IGridTableCellDispatcher } from "@types-custom/models/ui/grid-table-model";
import { CardActionEnum } from "@types-custom/models/ui/panel-manage-model";
import { IncidentPanelService } from "@shared/services/incident-panel/incident-panel.service";

@Component({
  selector: "sit-actions-incidents",
  templateUrl: "./actions-incidents.component.html",
  styleUrls: ["./actions-incidents.component.scss"],
})
export class ActionsIncidentsComponent {
  @Input() dispatcher: Subject<IGridTableCellDispatcher>;
  @Input() props: any;
  Icon = Icon

  actions = CardActionEnum;
  constructor(
     private servicePanel: IncidentPanelService,
    ){}


  dispatchAction(action: CardActionEnum) {
    const toDispatch: IGridTableCellDispatcher = {
      index: 0,
      row: this.props.row,
      action: action,
    }

    this.dispatcher.next(toDispatch);
  }

}
