import { Marker } from 'mapbox-gl';
import { BehaviorSubject, Subject } from 'rxjs';

export interface IMarkerBaseModel<T = any> {
  className: string;
  data: any;
  dispatcher?: BehaviorSubject<T>;
  unsubscribier?: Subject<void>;
}

export interface ILayerModel {
  marker: MarkerModelBase[];
  feature: any[];
  geometryType: GeometryTypeEnum;
  visibility: boolean;
}

export function isILayerModel(array: any[]): array is ILayerModel[] {
  return 'geometryType' in array[0]
}

export interface IMarkerModel extends IMarkerBaseModel {
  lat?: number;
  lng?: number;
}

export class MarkerModelBase {
  className: string;
  geometry: Partial<IGeometryModel | IGeometryLineModel>;
  lat?: number;
  lng?: number;

  constructor(
    geometry: Partial<IGeometryModel | IGeometryLineModel>,
    className: string,
    lat?: number,
    lng?: number
  ) {
    this.geometry = geometry;
    this.className = className;
    this.lat = lat;
    this.lng = lng;
  }
}

export enum GeometryTypeEnum {
  Point = 'Point',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  HeatMap = 'HeatMap',
  HeatMapWeight = 'HeatMapWeight',
  MultiPolygon = 'MultiPolygon'
}

export interface IGeometryModel {
  type: GeometryTypeEnum;
  coordinates: any[];
}

export interface IGeometryLineModel
  extends Omit<IGeometryModel, 'lat' | 'lng'> {
  coordinates: number[] | number[][];
}

export interface CardMapLinkModel {
  markerInstance: Marker;
  instanceDispatcher: BehaviorSubject<Marker>;
  markerDispatcher: BehaviorSubject<MarkerModelBase | undefined>;
  markerModel: MarkerModelBase;
}
