import { CameraModel } from '@shared/models/camera.model';
import { SituationsModel } from '@shared/models/situations.model';
import { TrafficModel } from '@shared/models/traffic.model';
import { Co2Model } from '@shared/models/co2.model';
import { iconClassSituationsMap } from '@shared/utils/mappers/geo-marker-map';
import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import { ICardRoadSituationsModel } from '@types-custom/models/ui/card-road-situations-model';
import { ICardCamerasModel } from '@types-custom/models/ui/card-cameras-model';
import { ICardTrafficModel } from '@types-custom/models/ui/card-traffic-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { PanelCo2Component } from '@ui-core/components/panel-co2/panel-co2.component';
import { PanelRoadSituationsComponent } from '@ui-core/components/panel-road-situations/panel-road-situations.component';
import { PanelTrafficComponent } from '@ui-core/components/panel-traffic/panel-traffic.component';
import { PanelTrafficConcordanceComponent } from '@ui-core/components/panel-traffic-concordance/panel-traffic-concordance.component';
import { PanelTrafficSpeedrangeComponent } from '@ui-core/components/panel-traffic-speedrange/panel-traffic-speedrange.component';
import { TrafficSpeedRangeModel } from '@shared/models/traffic-speed-range.model';
import { ICardTrafficSpeedRangeModel } from '@types-custom/models/ui/card-traffic-speed-range-model';
import { ICardTrafficConcordanceModel } from '@types-custom/models/ui/card-traffic-concordance-model';
import { ICardCo2Model } from '@types-custom/models/ui/card-co2-model';
import { ICardAforoModel } from '@types-custom/models/ui/card-aforo-model';
import { ICardVehiclesModel } from '@types-custom/models/ui/card-vehicles-model';
import { ICardCranesModel } from '@types-custom/models/ui/card-cranes-model';
import { BehaviorSubject } from 'rxjs';
import {
  IRightPanelData,
  RightPanelMapType,
  RightPanelEvents,
  RightPanelModel,
} from '@types-custom/models/ui/right-panel-model';
import { TrafficConcordanceModel } from '@shared/models/traffic-concordance.model';
import { AforoModel } from '@shared/models/aforo.model';
import { PanelAforoComponent } from '@ui-core/components/panel-aforo/panel-aforo.component';
import { AgentsModel } from '@shared/models/agents.model';
import { ICardAgentsModel } from '@types-custom/models/ui/card-agents-model';
import { PanelAgentsComponent } from '@ui-core/components/panel-agents/panel-agents.component';
import { PanelAgentsHistoricalComponent } from '@ui-core/components/panel-agents-historical/panel-agents-historical.component';
import { PanelAgentsNumberComponent } from '@ui-core/components/panel-agents-number/panel-agents-number.component';
import { PanelCranesLocationComponent } from '@ui-core/components/panel-cranes-location/panel-cranes-location.component';
import { PanelCranesNumberComponent } from '@ui-core/components/panel-cranes-number/panel-cranes-number.component';
import { IPmvModel, PmvModel } from '@shared/models/pmv.model';
import { AccidentModel } from '@shared/models/accident.model';
import { ICardAccidentModel } from '@types-custom/models/ui/card-accident.model';
import { PanelRuntDatexComponent } from '@ui-core/components/panel-runt-datex/panel-runt-datex.component';
import { RuntDatexModel } from '@shared/models/runt-datex.model';
import { ICardRuntDatexModel } from '@types-custom/models/ui/card-runt-datex.model';
import { RoadCorridorsModel } from "@shared/models/road-corridors.model";
import { ICardRoadCorridorsModel } from "@types-custom/models/ui/card-road-corridors.model";
import { TrafficJamWazeModel } from "@shared/models/traffic-jams-waze.model";
import { ICardTrafficJamWazeModel } from "@types-custom/models/ui/card-traffic-jams-waze.model";
import { CardMapLinkModel } from "@types-custom/models/business/marker.model";
import { AgentsHistoricalModel } from '@shared/models/agents-historical.model';
import { VehiclesModel } from '@shared/models/vehicles.model';
import { WazeDataTrafficModel } from "@shared/models/WazeDataTrafficModel";
import { ICardTrafficDataWazeModel } from "@types-custom/models/ui/card-traffic-data-waze-model";
import { AlertsDuplicatesWazeModel, AlertsWazeModel, IPropsTAlertsWazeModel } from "@shared/models/alerts-waze-model";
import { ICardWazeAlertsModel } from "@types-custom/models/ui/card-alert-waze.model";
import { CranesModel } from '@shared/models/cranes.model';
import { CranesHistoricalModel } from '@shared/models/cranes-historical.model';
import { PanelIncidentsLocationsComponent } from '@ui-core/components/panel-incidents-locations/panel-incidents-locations.component';
import { ICardIncidentsLocationsModel } from '@types-custom/models/ui/cards-incidents-locations';
import { IncidentsLocationsModel } from '@shared/models/LocationsIncidents.model';
import { FieldResourcesLocationsModel } from "@shared/models/field-resources-model";
import { ICardFieldResourceModel } from "@types-custom/models/ui/card-field-resources";
import { IIncidentsCardFieldResourceModel } from "@types-custom/models/ui/card-incidents-field-resources";
import { IncidentsFieldResourcesModel } from "@shared/models/incidents-field-resources-model";
import { ResourcesPredictiveModelLocationsModel } from "@shared/models/ResourcesPredictiveModelLocations.model";
import { ICardPredictiveResourcesModel } from "@types-custom/models/ui/card-predictiveresources.model";
import { BikesModel, ICardBikesModel } from "@shared/models/Bikes.model";

export const ICardIncidentsLocationsModelMapper = (serviceDataSource: any) => {
  return (
    data: IncidentsLocationsModel[][]
  ): IRightPanelData<ICardIncidentsLocationsModel[]> => {

    const cards = data[0].map((ele: any): any => {
      return {
        //icon: iconClassSituationsMap[ele.classProperties.situationType],
        id: ele.properties?.id,
        location_name: ele.properties?.location_name,
        type: ele.properties?.type_name,
        street: ele.properties?.address,
        priority: ele.properties?.priority_name,
        class: ele.properties?.class_name,
        pubTime: ele.properties?.incident_time,
        state_name: ele.properties?.state_name
      };
    }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardTrafficModelMapper = (
  data: TrafficModel[][]
): IRightPanelData<ICardTrafficModel[]> => {
  const cards = data[0].map((ele: TrafficModel): ICardTrafficModel => {
    return {
      iconLocation: Icon.street_w,
      iconAverageSpeed: Icon.chronometer_w,
      iconVehicleCount: Icon.traffic_jams_w,
      iconTravelTime: Icon.schedule_w,
      location: ele.classProperties.id,
      averageSpeed: ele.classProperties.speed,
      travelTime: ele.classProperties.distance,
    };
  });

  const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
    undefined
  );

  return { cards, panelActionsDispatcher };
};

export const ICardTrafficSpeedRangeModelMapper = (serviceDataSource: any) => {
  return (
    data: TrafficSpeedRangeModel[][]
  ): IRightPanelData<ICardTrafficSpeedRangeModel[]> => {
    const cards = data[0].map(
      (ele: TrafficSpeedRangeModel): ICardTrafficSpeedRangeModel => {
        return {
          id: ele.classProperties.id,
          iconRoad: Icon.street_w,
          iconSpeed: Icon.chronometer_w,
          speed: ele.classProperties.speed,
          distance: ele.classProperties.distance,
          concordance: ele.classProperties.concordance,
          location: `${ele.classProperties.nameFrom} - ${ele.classProperties.nameTo}`,
          expectedValue: ele.classProperties.expectedValue,
          elapsedTime: ele.classProperties.elapsedTime
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardRoadCorridorsModelMapper = (serviceDataSource: any) => {
  return (
    data: RoadCorridorsModel[][]
  ): IRightPanelData<ICardRoadCorridorsModel[]> => {
    const cards = data[0].map(
      (ele: RoadCorridorsModel): ICardRoadCorridorsModel => {
        var first = new Date(ele.classProperties.time).getTime();
        var second = new Date(ele.classProperties.read_time).getTime();
        return {
          id: ele.classProperties.id,
          iconRoad: Icon.road_w,
          iconSpeed: Icon.chronometer_w,
          speed: ele.classProperties.speed,
          distance: ele.classProperties.distance,
          location: `${ele.classProperties.name_from} - ${ele.classProperties.name_to}`,
          composed_name: ele.classProperties.composed_name,
          orient: ele.classProperties.orient,
          total_incidents: ele.classProperties.total_incidents,
          levelOfService: (ele.classProperties.speed <= 10) ? 'blue' : (ele.classProperties.speed <= 40) ? 'green' : 'red',
          time: `${new Date(first - second).getMinutes()}.${new Date(first - second).getSeconds()}`
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardAlertsWazeModelMapper = (serviceDataSource: any) => {
  return (
    data: AlertsWazeModel[][]
  ): IRightPanelData<ICardWazeAlertsModel[]> => {
    const cards = data[0].map(
      (ele: AlertsWazeModel): ICardWazeAlertsModel => {
        return {
          reliability: ele.classProperties.reliability,
          type: ele.classProperties.type,
          subType: ele.classProperties.subType,
          pubTime: ele.classProperties.pubTime,
          receptionTime: ele.classProperties.receptionTime,
          description: ele.classProperties.description,
          street: ele.classProperties.street,
          locality: ele.classProperties.locality,
          localityId: ele.classProperties.localityId,
          sensores: ele.classProperties.localityId,
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardTrafficJamWazeModelMapper = (serviceDataSource: any) => {
  return (
    data: TrafficJamWazeModel[][]
  ): IRightPanelData<ICardTrafficJamWazeModel[]> => {
    const cards = data[0].map(
      (ele: TrafficJamWazeModel): ICardTrafficJamWazeModel => {
        return {
          uuid: ele.classProperties.uuid,
          iconSpeed: Icon.street_w,
          iconRoad: Icon.chronometer_w,
          street: ele.classProperties.street,
          speedkmh: ele.classProperties.speedkmh,
          length0: ele.classProperties.length0,
          delay: ele.classProperties.delay,
          level: ele.classProperties.level,
          receptiontime: ele.classProperties.receptiontime,
          pubtime: ele.classProperties.pubtime,
          updpubmilis: ele.classProperties.updpubmilis,
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardTrafficDataWazeModelMapper = (serviceDataSource: any) => {
  return (
    data: WazeDataTrafficModel[][]
  ): IRightPanelData<ICardTrafficDataWazeModel[]> => {
    const cards = data[0].map(
      (ele: WazeDataTrafficModel): ICardTrafficDataWazeModel => {
        return {
          category: ele.classProperties.category,
          expectedValue: ele.classProperties.historyTimeMinutes,
          id: ele.classProperties.id.toString(),
          uuid: ele.classProperties.id.toString(),
          distance: ele.classProperties.length.toString(),
          iconSpeed: Icon.speed_range_w,
          name: ele.classProperties.name,
          orientation: ele.classProperties.orientation,
          publicationDate: ele.classProperties.publicationDate,
          title: ele.classProperties.name,
          corridor: ele.classProperties.corridor,
          receptionDate: ele.classProperties.receptionDate,
          elapsedTime: ele.classProperties.timeMinutes,
          updateDate: ele.classProperties.updateDate,
          timeMinutes: ele.classProperties.timeMinutes,
          speed: ele.classProperties.velocity.toString(),
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardTrafficConcordanceModelMapper = (serviceDataSource: any) => {
  return (
    data: TrafficConcordanceModel[][]
  ): IRightPanelData<ICardTrafficConcordanceModel[]> => {
    const cards = data[0].map(
      (ele: TrafficConcordanceModel): ICardTrafficConcordanceModel => {
        return {
          id: ele.classProperties.id,
          iconRoad: Icon.road_w,
          iconSpeed: Icon.chronometer_w,
          speed: ele.classProperties.speed,
          composedName: ele.classProperties.composed_name,
          distance: ele.classProperties.distance,
          concordance: ele.classProperties.concordance,
          location: `${ele.classProperties.nameFrom} - ${ele.classProperties.nameTo}`,
          expectedValue: ele.classProperties.expectedValue || ele.classProperties.expectValue,
          elapsedTime: ele.classProperties.elapsedTime
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardCameraModelMapper = (
  data: CameraModel[][]
): IRightPanelData<ICardCamerasModel[]> => {
  const cards = data[0].map((ele: CameraModel): ICardCamerasModel => {
    return {
      icon: Icon.camera_play_w,
      title: ele.classProperties.id,
    };
  });

  const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
    undefined
  );

  return { cards, panelActionsDispatcher };
};

export const ICardCo2ModelMapper = (
  data: Co2Model[][]
): IRightPanelData<ICardCo2Model[]> => {
  const cards = data[0].map((ele: Co2Model): ICardCo2Model => {
    return {
      id: ele.classProperties.id,
      location: ele.classProperties.location,
      carbonDioxide: ele.classProperties.carbonDioxide,
      humidity: ele.classProperties.humidity,
      pressure: ele.classProperties.pressure,
      temperature: ele.classProperties.temperature,
    };
  });

  const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
    undefined
  );

  return { cards, panelActionsDispatcher };
};

export const ICardAforoModelMapper = (serviceDataSource: any) => {
  return (data: AforoModel[][]): IRightPanelData<ICardAforoModel[]> => {
    const cards = data[0].map((ele: AforoModel): ICardAforoModel => {
      return {
        id: ele.classProperties.id,
        address: ele.classProperties.address,
        avgSpeed: 0, //TODO: Temporarily set to zero.
        flowCount: 0, //TODO: Temporarily set to zero.
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardAgentsModelMapper = (serviceDataSource: any) => {
  return (data: AgentsModel[][]): IRightPanelData<ICardAgentsModel[]> => {
    const cards = data[0].map((ele: AgentsModel): ICardAgentsModel => {
      return {
        id: ele.classProperties?.id,
        name: ele.classProperties?.name,
        agentType: ele.classProperties?.agentType,
        updatedDate: ele.classProperties?.updatedDate,
        locName: ele.classProperties?.locName,
        speed: ele.classProperties?.speed,
        devId: ele.classProperties?.devId,
        grpName: ele.classProperties?.grpName
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardAgentsHistoricalModelMapper = (serviceDataSource: any) => {
  return (data: AgentsHistoricalModel[][]): IRightPanelData<ICardAgentsModel[]> => {
    const cards = data[0].map((ele: AgentsHistoricalModel): ICardAgentsModel => {
      return {
        id: ele.classProperties?.id,
        name: ele.classProperties?.name,
        agentType: ele.classProperties?.agentType,
        updatedDate: ele.classProperties?.updatedDate,
        localidad: ele.classProperties?.localidad,
        dev_id: ele.classProperties?.dev_id,
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardCranesHistoricalModelMapper = (serviceDataSource: any) => {
  return (data: CranesHistoricalModel[][]): IRightPanelData<ICardCranesModel[]> => {
    const cards = data[0].map((ele: CranesHistoricalModel): ICardCranesModel => {
      return {
        id: ele.classProperties?.id,
        location: ele.classProperties?.localidad
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardVehiclesModelMapper = (serviceDataSource: any) => {
  return (data: VehiclesModel[][]): IRightPanelData<ICardVehiclesModel[]> => {
    const cards = data[0].map((ele: VehiclesModel): ICardVehiclesModel => {
      return {
        id: ele.classProperties.siteId,
        address: ele.classProperties.address,
        // color: ele.classProperties.color,
        // level_of_service: ele.classProperties.level_of_service,
        // location: ele.classProperties.location,
        // sensores: ele.classProperties.sensores,
        name: ele.classProperties.address,
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const IPredictiveResourcesPanelMapper = (serviceDataSource: any) => {
  return (data: ResourcesPredictiveModelLocationsModel[][]): IRightPanelData<ICardPredictiveResourcesModel[]> => {

    const cards = data[0].map((ele: any): ICardPredictiveResourcesModel => {
      return {
        id: ele.classProperties?.id || ele.properties?.id,
        agentId: ele.classProperties?.agentId || ele.properties?.agentId,
        groupName: ele.classProperties?.groupName || ele.properties?.groupName,
        day: ele.classProperties?.day || ele.properties?.day,
        fileName: ele.classProperties?.fileName || ele.properties?.fileName,
        groupId: ele.classProperties?.groupId || ele.properties?.groupId,
        hash: ele.classProperties?.hash || ele.properties?.hash,
        hour: ele.classProperties?.hour || ele.properties?.hour,
        processDate: ele.classProperties?.processDate || ele.properties?.processDate,
        localityId: ele.classProperties?.localityId || ele.properties?.localityId,
        localityName: ele.classProperties?.localityName || ele.properties?.localityName,
        type: ele.classProperties?.type || ele.properties?.type,
      };
    })

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  }
}

export const ICardBikesModelMapper = (serviceDataSource: any) => {
  return (
    data: BikesModel[][]
  ): IRightPanelData<ICardBikesModel[]> => {
    const cards = data[0].map(
      (ele: BikesModel): ICardBikesModel => {
        return {
          id: ele.classProperties.id,
          name: ele.classProperties.name,
          locality: ele.classProperties.locality,
          flow: ele.classProperties.flow,
          orientation: ele.classProperties.orientation,
          icon: ele.classProperties.icon,
        };
      }
    );

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};


export const IIncidentsFieldResourcesPanelMapper = (serviceDataSource: any) => {
  return (data: IncidentsFieldResourcesModel[][]): IRightPanelData<IIncidentsCardFieldResourceModel[]> => {
    const cards = data[0].map((ele: IncidentsFieldResourcesModel | any): IIncidentsCardFieldResourceModel => {
      return {
        type: ele.classProperties?.type || ele.properties?.type,
        incidentId: ele.classProperties?.incidentId || ele.properties?.incidentId,
        incidentClassId: ele.classProperties?.incidentClassId || ele.properties?.incidentClassId,
        incidentClassName: ele.classProperties?.incidentClassName || ele.properties?.incidentClassName,
        incidentTypeId: ele.classProperties?.incidentTypeId || ele.properties?.incidentTypeId,
        incidentTypeName: ele.classProperties?.incidentTypeName || ele.properties?.incidentTypeName,
        incidentPriorityId: ele.classProperties?.incidentPriorityId || ele.properties?.incidentPriorityId,
        incidentPriorityName: ele.classProperties?.incidentPriorityName || ele.properties?.incidentPriorityName,
        incidentAddress: ele.classProperties?.incidentAddress || ele.properties?.incidentAddress,
        incidentStatusId: ele.classProperties?.incidentStatusId || ele.properties?.incidentStatusId,
        incidentStatusName: ele.classProperties?.incidentStatusName || ele.properties?.incidentStatusName,
        incidentTime: ele.classProperties?.incidentTime || ele.properties?.incidentTime,
        incidentSourceId: ele.classProperties?.incidentSourceId || ele.properties?.incidentSourceId,
        incidentSourceName: ele.classProperties?.incidentSourceName || ele.properties?.incidentSourceName,
        incidentLocalityName: ele.classProperties?.incidentLocalityName || ele.properties?.incidentLocalityName,
        incidentCountsGroup: ele.classProperties?.incidentCountsGroup || ele.properties?.incidentCountsGroup,
        resourceId: ele.classProperties?.resourceId || ele.properties?.resourceId,
        resourceFirstName: ele.classProperties?.resourceFirstName || ele.properties?.resourceFirstName,
        resourceLastName: ele.classProperties?.resourceLastName || ele.properties?.resourceLastName,
        resourceDocumentTypeId: ele.classProperties?.resourceDocumentTypeId || ele.properties?.resourceDocumentTypeId,
        resourceDocumentTypeName: ele.classProperties?.resourceDocumentTypeName || ele.properties?.resourceDocumentTypeName,
        resourceDocument: ele.classProperties?.resourceDocument || ele.properties?.resourceDocument,
        resourceEmail: ele.classProperties?.resourceEmail || ele.properties?.resourceEmail,
        resourcePhone: ele.classProperties?.resourcePhone || ele.properties?.resourcePhone,
        resourceStateId: ele.classProperties?.resourceStateId || ele.properties?.resourceStateId,
        resourceStateName: ele.classProperties?.resourceStateName || ele.properties?.resourceStateName,
        resourceGroupId: ele.classProperties?.resourceGroupId || ele.properties?.resourceGroupId,
        resourceGroupName: ele.classProperties?.resourceGroupName || ele.properties?.resourceGroupName,
        resourceIndicative: ele.classProperties?.resourceIndicative || ele.properties?.resourceIndicative,
        resourceJobId: ele.classProperties?.resourceJobId || ele.properties?.resourceJobId,
        resourceJobName: ele.classProperties?.resourceJobName || ele.properties?.resourceJobName,
        resourceUpdateLocationDate: ele.classProperties?.resourceUpdateLocationDate || ele.properties?.resourceUpdateLocationDate,
        resourceLocalityName: ele.classProperties?.resourceLocalityName || ele.properties?.resourceLocalityName,
      };
    })

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  }
}

export const IFieldResourcesPanelMapper = (serviceDataSource: any) => {
  return (data: FieldResourcesLocationsModel[][]): IRightPanelData<ICardFieldResourceModel[]> => {
    const cards = data[0].map((ele: FieldResourcesLocationsModel): ICardFieldResourceModel => {
      return {
        id: ele.classProperties?.id,
        groupId: ele.classProperties?.groupId.toString(),
        groupName: ele.classProperties?.groupName,
        stateId: ele.classProperties?.stateId.toString(),
        stateName: ele.classProperties?.stateName,
        localityName: ele.classProperties?.localityName,
      };
    })

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  }
}

export const ICardCranesModelMapper = (serviceDataSource: any) => {
  return (data: CranesModel[][]): IRightPanelData<ICardCranesModel[]> => {
    const cards = data[0].map((ele: CranesModel): ICardCranesModel => {
      return {
        id: ele.classProperties?.id,
        speed: ele.classProperties?.speed,
        location: ele.classProperties?.location,
        time: ele.classProperties?.time,
        status: ele.classProperties?.status,
        inactive: ele.classProperties?.inactive,
        typeField: ele.classProperties?.typeField
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const IPmvModelMapper = (serviceDataSource: any) => {
  return (data: PmvModel[][]): IRightPanelData<IPmvModel[]> => {
    const cards: IPmvModel[] = [(data as unknown as PmvModel).classProperties];

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardAccidentModelMapper = (serviceDataSource: any) => {
  return (data: AccidentModel[][]): IRightPanelData<ICardAccidentModel[]> => {
    const cards: ICardAccidentModel[] = [];

    for (const [key, value] of Object.entries((data as any).classProperties)) {
      cards.push(JSON.parse(value as any));
    }

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardRuntDatexModelMapper = (serviceDataSource: any) => {
  return (data: RuntDatexModel[][]): IRightPanelData<ICardRuntDatexModel[]> => {
    const cards = data[0].map((ele: RuntDatexModel): ICardRuntDatexModel => {
      return {
        id: ele.classProperties.id,
        address: ele.classProperties.address
      };
    });

    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
      undefined
    );

    return {
      cards,
      panelActionsDispatcher,
      serviceDataSource,
    };
  };
};

export const ICardAlertsDuplicatesWazeModelMapper = (serviceDataSource: any) => {
  return (
    data: AlertsDuplicatesWazeModel[][]
  ): IRightPanelData<IPropsTAlertsWazeModel[]> => {
    const cards = data[0].map(
      (ele: AlertsDuplicatesWazeModel): IPropsTAlertsWazeModel => {
        return {
          id: ele.classProperties.id,
          reliability: ele.classProperties.reliability,
          type: ele.classProperties.type,
          subType: ele.classProperties.subType,
          pubTime: ele.classProperties.pubTime,
          receptionTime: ele.classProperties.receptionTime,
          description: ele.classProperties.description,
          street: ele.classProperties.street,
          locality: ele.classProperties.locality,
          localityId: ele.classProperties.localityId,
          icon: ele.classProperties.icon,
          timeAlerts: ele.classProperties.timeAlerts,
          markerPopupText: null,
          duplicates: JSON.stringify(ele.classProperties.duplicates),
          dispatcher$: serviceDataSource,
          // panelDispatcher: panelDispatcher,
          latitude: ele.classProperties.latitude,
          longitude: ele.classProperties.longitude,
        };
      }
    );

    // const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(
    //     undefined
    // );
    const panelActionsDispatcher = new BehaviorSubject<RightPanelEvents>(undefined);

    return {
      cards,
      panelActionsDispatcher,
      // panelDispatcher,
      serviceDataSource,
    };
  };
};

export const menuRightPanelMap = (
  dataSources: any
): { [key: string]: RightPanelModel } => (
  {
    [MapLayers.Situations]: {
      component: PanelRoadSituationsComponent,
      title: 'Novedades Bitácora',
      subtitle: '(Índice de concordancia)',
      alt: 'Novedades Bitácora',
      titleIcon: Icon.alert_w,
      /*     mapperFn: ICardRoadSituationsModelMapper(dataSources.incidentsService), */
    },
    [MapLayers.Traffic]: {
      component: PanelTrafficComponent,
      title: 'Tráfico',
      subtitle: '(Índice de concordancia)',
      alt: 'Tráfico',
      titleIcon: Icon.traffic_arrows_w,
      mapperFn: ICardTrafficModelMapper,
    },
    [MapLayers.Co2]: {
      component: PanelCo2Component,
      title: 'Puntos Sensorizados',
      subtitle: '(Índice de concordancia)',
      alt: 'Puntos Sensorizados',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardCo2ModelMapper,
    },
    [MapLayers.Aforo]: {
      component: PanelAforoComponent,
      title: 'Aforo',
      subtitle: '(Índice de concordancia)',
      alt: 'Aforo',
      titleIcon: Icon.density_w,
      mapperFn: ICardAforoModelMapper(dataSources.aforoService),
    },
    [MapLayers.TrafficConcordance]: {
      component: PanelTrafficConcordanceComponent,
      title: 'Tráfico',
      subtitle: '(Índice de concordancia)',
      alt: 'Tráfico',
      titleIcon: Icon.road_speed_w,
      mapperFn: ICardTrafficConcordanceModelMapper(dataSources.trafficApiService),
    },
    [MapLayers.TrafficSpeedRange]: {
      component: PanelTrafficSpeedrangeComponent,
      title: 'Tráfico',
      subtitle: '(Índice de concordancia)',
      alt: 'Tráfico',
      titleIcon: Icon.traffic_arrows_w,
      mapperFn: ICardTrafficSpeedRangeModelMapper(dataSources.trafficApiService),
    },
    [MapLayers.Agents]: {
      component: PanelAgentsComponent,
      title: 'Agentes',
      subtitle: '(Índice de concordancia)',
      alt: 'Agentes',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardAgentsModelMapper(dataSources.agentsService),
    },
    [MapLayers.AgentsCluster]: {
      component: PanelAgentsComponent,
      title: 'Agentes',
      subtitle: '(Índice de concordancia Cluster)',
      alt: 'Agentes',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardAgentsModelMapper(dataSources.agentsService),
    },
    [MapLayers.AgentsHistorical]: {
      component: PanelAgentsHistoricalComponent,
      title: 'Agentes (Últimas Horas)',
      subtitle: '(Índice de concordancia)',
      alt: 'Agentes',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardAgentsHistoricalModelMapper(dataSources.agentsService),
    },
    [MapLayers.AgentsNumber]: {
      component: PanelAgentsNumberComponent,
      title: 'Agentes (Número)',
      subtitle: '(Índice de concordancia)',
      alt: 'Agentes',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardAgentsHistoricalModelMapper(dataSources.agentsService),
    },
    [MapLayers.CranesHistorical]: {
      component: PanelCranesLocationComponent,
      title: 'Grúas (Últimas Horas)',
      subtitle: '(Índice de concordancia)',
      alt: 'Gruás',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardCranesHistoricalModelMapper(dataSources.cranesService),
    },
    [MapLayers.CranesNumber]: {
      component: PanelCranesNumberComponent,
      title: 'Grúas (Número)',
      subtitle: '(Índice de concordancia)',
      alt: 'Gruás',
      titleIcon: Icon.sensor_w,
      mapperFn: ICardCranesHistoricalModelMapper(dataSources.cranesService),
    },
    [MapLayers.RuntDatex]: {
      component: PanelRuntDatexComponent,
      title: 'Runt - Datex',
      subtitle: '(Índice de concordancia)',
      alt: 'Runt - Datex',
      titleIcon: Icon.camera_play_blue,
      mapperFn: ICardRuntDatexModelMapper(undefined),
    },
    [MapLayers.IncidentsLocations]: {
      component: PanelIncidentsLocationsComponent,
      title: 'Incidentes',
      subtitle: '(Localización)',
      alt: 'Incidentes (Localización)',
      titleIcon: Icon.incidentsPanelIcon,
      mapperFn: ICardIncidentsLocationsModelMapper(dataSources.incidentsService),
    },
    [MapLayers.LastHour]: {
      component: PanelIncidentsLocationsComponent,
      title: 'Incidentes ',
      subtitle: '(Últimas horas)',
      alt: 'Incidentes (Últimas horas)',
      titleIcon: Icon.incidentsPanelIcon,
      mapperFn: ICardIncidentsLocationsModelMapper(dataSources.incidentsService),
    },
  });
