import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject, OnInit } from '@angular/core';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import { IIncidentParameter, IParamListTypeOption, parameterListOptions, parameterListTypeOptions } from '@shared/models/incident-parameter.model';
import { INoveltyVehicle } from '@shared/models/novelty-vehicle.model';
import { ILocationOption, ILocationPoint } from '@types-custom/models/business/location.model';
import { AbstractSdkDynamicFilterDataSource, DynamicFilterTypeEnum, IDynamicFilterModel, ISdkDynamicFilterModel } from '@types-custom/models/ui/dynamic-filter';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
import { AbstractPaginatorDataSource, AbstractPanelManagementDataSource, IPaginatorModel } from '@types-custom/models/ui/paginator-model';
import { BehaviorSubject, Observable, of, tap, pipe } from 'rxjs';

@Injectable()
export class VehicleNoveltyService implements
    AbstractPaginatorDataSource<any>
    , AbstractSdkDynamicFilterDataSource {
    constructor(
        @Inject('environment') private environment: any,
        private http: HttpClient,
        public sdkDynamicFiltersService: SdkDynamicFiltersService,
    ) { }
    eventType$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    vehicleType$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    resourceType$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    timeRange$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);


    filterList: ISdkDynamicFilterModel<any, any>[] = [
        {
            key: 'searchValue',
            type: DynamicFilterTypeEnum.TEXT,
            label: 'Búsqueda',
            value: new BehaviorSubject(undefined),
            searchChange: true
        },
        {
            key: 'eventType',
            type: DynamicFilterTypeEnum.SELECTION_BUTTON,
            label: 'Tipo de Evento',
            value: new BehaviorSubject(undefined),
            options: this.eventType$,
            optionsMapper: undefined,
            searchChange: true
        },
        {
            key: 'vehicleType',
            type: DynamicFilterTypeEnum.SELECTION_BUTTON,
            label: 'Tipo de Vehículo',
            value: new BehaviorSubject(undefined),
            options: this.vehicleType$,
            optionsMapper: undefined,
            searchChange: true
        },
        {
            key: 'resourceType',
            type: DynamicFilterTypeEnum.SELECTION_BUTTON,
            label: 'Tipo de Recurso',
            value: new BehaviorSubject(undefined),
            options: this.resourceType$,
            optionsMapper: undefined,
            searchChange: true
        },
        {
            key: 'timeRange',
            type: DynamicFilterTypeEnum.SELECTION_BUTTON,
            label: 'Rango de Tiempo',
            value: new BehaviorSubject(undefined),
            options: this.timeRange$,
            optionsMapper: undefined,
            searchChange: true
        },
    ];

    buildExcelPdfUrl(fileType: string) {
        let urlBase = '';
        if (fileType === fileTypes.EXCEL) {
            urlBase = `${this.environment.resource}/novelty-vehicle/export/excel?recordsPerPage=20&page=0`;
        } else if (fileType === fileTypes.PDF) {
            urlBase = `${this.environment.resource}/novelty-vehicle/export/pdf?recordsPerPage=20&page=0`;
        }

        // if (this.formFiltersValue) {
        //   Object.keys(this.formFiltersValue).forEach(
        //     (filterKey: string, index: number) => {
        //       if (
        //         filterKey !== undefined &&
        //         filterKey !== null &&
        //         filterKey !== ''
        //       ) {
        //         if (
        //           this.formFiltersValue[filterKey] !== undefined &&
        //           this.formFiltersValue[filterKey] !== null &&
        //           this.formFiltersValue[filterKey] !== ''
        //         ) {
        //           urlBase =
        //             urlBase + `&${filterKey}=${this.formFiltersValue[filterKey]}`;
        //         }
        //       }
        //     }
        //   );
        // }

        return urlBase;
    }

    generateDynamicFiltersModel(): IDynamicFilterModel {
        const dynamicFiltersModel: IDynamicFilterModel = {
            sdkDynamicFiltersService: this.sdkDynamicFiltersService,
            dataSource: this,
            filtersDispatcher: this.selected$,
            showBttnConventions: false,
            hiddenButtonsFilters: true
        };
        return dynamicFiltersModel;
    }


    private dataSubject = new BehaviorSubject<IIncidentParameter[]>([]);
    data$: Observable<any> = this.dataSubject.asObservable();


    selected$ = new BehaviorSubject<any | undefined>(undefined);
    selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;

    pageInfo: BehaviorSubject<IPaginatorModel> = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10, totalCount: 0 });

    BASE_URL = this.environment.vehicleNovelty;

    getUrl(endPoint: string) {
        return `${this.BASE_URL}/${endPoint}`;
    }
    getUrlParams(pageInfo: IPaginatorModel): HttpParams {
        const { pageSize, data } = pageInfo;
        let params: { [key: string]: any }
            = { ...pageInfo, recordsPerPage: pageSize };
        if (data) {
            params = { ...params, ...data };
        }

        let paramsRet = new HttpParams();
        Object.keys(params).forEach(key => {
            if (params[key] !== undefined) { // Solo añadir parámetros que no son `undefined`
                paramsRet = paramsRet.append(key, params[key]);
            }
        });
        return paramsRet;
    }

    fetchPageData(pageInfo: IPaginatorModel<any>): Observable<IPaginatorModel<any>> {
        if (this.selectedData.value) {
            pageInfo.data = this.selectedData.value; //TODO: review approach, copied from user-management
        }
        return this.http.get<any>(
            this.getUrl('pagination/data'),
            { params: this.getUrlParams(pageInfo) }
        )
            .pipe(tap((resp: any) => {
                const _pageInfo: IPaginatorModel = {
                    page: resp.currentPage ?? 0,
                    pageSize: pageInfo.pageSize ?? 0,
                    totalCount: resp.totalRecords,
                };
                this.pageInfo.next(_pageInfo);
                this.dataSubject.next(resp.records ?? []);
            }),
            );
    }

    fetchData(value: any, next: ISdkDynamicFilterModel<any, any>, index: number): Observable<any> {
        throw new Error('Method not implemented.');
    }
    clearData(): void {
        throw new Error('Method not implemented.');
    }

    setSelectedDataSubject(
        selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
    ): void {
        this.selectedData = selectedData;
        this.selectedData.subscribe((selectedData) =>
            this.fetchPageDataDistpacher(selectedData)
        );
    }

    fetchPageDataDistpacher(selectedData: any) {
        const dataObject: IPaginatorModel = {
            page: 0,
            pageSize: 10,
            data: selectedData,
        };
        this.fetchPageData(dataObject).subscribe();
    }

    // getNoveltyValue(id: any): Observable<INoveltyVehicle> {
    getNoveltyValue(id: any): Observable<INoveltyVehicle> {
        const URL = this.getUrl(id)
        return this.http.get<INoveltyVehicle>(URL)
    }

    public init() {
        this.setSelectedDataSubject(this.selected$);
        // this.options.state.next(parameterListOptions);
        // this.options.type.next(parameterListTypeOptions);
    }

    public setStates(states: ILocationOption[]) {
        this.vehicleType$.next(states);
    }

    public postComment (data : any) {
        return this.http.post(this.environment.vehicleNovelty,data)
    }
}   