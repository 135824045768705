<div>
  <sit-list-cards
    [headers]="properties.headers"
    [dataSource]="properties.dataSource.data$"
  ></sit-list-cards>

  <div class="footer-table d-flex fd-col justify-space-between mt-16">
    <div class="d-flex justify-end mb-16">
      <sit-paginator
        [dataSource]="properties.dataSource"
        class="d-flex"
      ></sit-paginator>
    </div>
    <div class="d-flex fd-row pl-16 pr-16 text-color-white">
      <div
        class="d-flex align-items-center cursor-pointer fx-bg-hover-gray fx-cell-hover"
        *ngIf="properties.isAllowDownload"
        (click)="dispatchAction(GridPageActions.ExportExcel)"
        tabindex="0"
      >
        <sit-icon
          [icon]="icon.download_document"
          [sizeClass]="'icon-size-28'"
          class="mr-8"
          alt="documento excel icono descarga de archivo excel"
        ></sit-icon>
        <label class="text-size-14 text-weight-600 mr-8 cursor-pointer"
          >Exportar a Excel</label
        >
      </div>

      <div
        class="d-flex align-items-center ml-16 cursor-pointer fx-bg-hover-gray fx-cell-hover"
        *ngIf="properties.isAllowDownload"
        (click)="dispatchAction(GridPageActions.ExportPdf)"
        tabindex="0"
      >
        <sit-icon
          [icon]="icon.download_pdf"
          [sizeClass]="'icon-size-28'"
          class="mr-8"
          alt="documento pdf icono descarga de archivo pdf"
        ></sit-icon>
        <label class="text-size-14 text-weight-600 mr-8 cursor-pointer"
          >Exportar a Pdf</label
        >
      </div>
    </div>
  </div>
</div>
