import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: 'ui-modal-succes-rol',
  templateUrl: './modal-succes-rol.component.html',
  styleUrls: ['./modal-succes-rol.component.scss'],
})
export class ModalSuccesRolComponent implements OnInit {
  @Input() infoMessage = 'No se ha encontrado la información solicitada';
  @Input() icon = Icon.check_w;
  infoIcon = Icon.info;
  checkCircle = this.icon;

  maxWidth = 640;
  modalWidth = 'auto';
  bottom = '70px';
  left = '10px';
  right = '10px';

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    if (this.maxWidth < document.documentElement.clientWidth) {
      return;
    }

    const elementStyle = (<HTMLStyleElement>(
      document.querySelector('.modal-box')
    )).style;

    elementStyle.width = this.modalWidth;
    elementStyle.bottom = this.bottom;
    elementStyle.left = this.left;
    elementStyle.right = this.right;
  }
}
