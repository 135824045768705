import {
  OnInit,
  AfterViewInit,
  OnDestroy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap, of, throwError, catchError
} from 'rxjs';

import { dynamicFormMapper } from '@shared/utils/mappers/incident-model';
import {
  CodeValidatorNameEnum,
  FormGroupNamesEnum,
  IDynamicFormModel,
  IGenericFormModel,
  InputTypeEnum,
  IValidatorModel,
  ParametricFormI,
  validarValorAutocomplete,

} from '@types-custom/models/ui/generic-form.model';
import { ILocationControlModel } from '@types-custom/models/ui/location-control.model';
import { remapObjectToOneLevel } from '@ui-core/utils/functions/remap-object';
import { MapboxDataService } from '@sdk/services/map-box-data/mapbox-data.service';
import { MapMouseEvent } from 'mapbox-gl';
import { formatDate, isoFormatDate } from '@shared/utils/functions/format-date';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { IncidentStatusCodeEnum } from '@types-custom/models/business/manage-incident-databody.model';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';
import { Icon } from '@types-custom/models/ui/icon-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { Anotation, IncidentFormEnum, RoleIcon, RoleMapper } from '@types-custom/models/ui/incidents-model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';
import { word_distance } from '@ui-core/utils/functions/string_manipulation';

@Component({
  selector: 'generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
  providers: [FormGroupDirective],
})
export class GenericFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() properties: IGenericFormModel;

  @ViewChild('addresstext') locationControlViewChild: any;

  locationControlModel: ILocationControlModel;

  constructor(
    protected fgDirective: FormGroupDirective,
    private formBuilder: FormBuilder,
    private localStorageManagementService: LocalStorageManagementService,
    private mapboxDataService: MapboxDataService,
  ) { }

  private subscriptions = new Subscription();
  private destroy$ = new Subject<void>();

  get Icon() {
    return Icon;
  }

  get object() {
    return Object;
  }

  get inputTypeEnum() {
    return InputTypeEnum;
  }

  get mapEventTypeEnum() {
    return MapEventTypeEnum;
  }

  get roleIconMapper() {
    return RoleIcon;
  }

  formatDate(date: string) {
    const dateD = new Date(date);
    return new Intl.DateTimeFormat('es-CO', {
      day: '2-digit',
      month: 'numeric',
      year: 'numeric'
    }).format(dateD);
  }
  formatTime(time: string) {
    if (!time)
      return '--:--:--';
    const timeD = new Date(time);
    return new Intl.DateTimeFormat('es-CO', {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false
    }).format(timeD);
  }

  _isAValidComponent(formName: string = FormGroupNamesEnum.MAIN, formKey: string) {
    return this.properties.formGroup.get(formName).get(formKey).status === 'INVALID'
      && this.properties.formGroup.get(formName).get(formKey).touched
  }

  formModelObject: { [key: string]: ParametricFormI[][] } = {};

  dynamicFields$: BehaviorSubject<ParametricFormI[]> = new BehaviorSubject([]);
  dataForm: any;


  private currentDateAttr: string;
  get currentDate() {
    return this.currentDateAttr;
  }

  get formModelArray(): ParametricFormI[][] {
    const arr: ParametricFormI[][] = [];
    Object.entries(this.formModelObject).map((formModel) => {
      (formModel[1] as any[]).forEach((e) => {
        arr.push(e);
      });
    });
    return arr;
  }

  observationsList: Anotation[] = [];
  filteredObservationsList: Anotation[] = [];

  filterFormGroup = this.formBuilder.group({});
  filterRoleOption: any[] = [];

  cachedListMap: Map<string, { value: string, name: string }[]> = new Map()

  // cachedLists: { [key: string]: { value: string, name: string }[] } = {}


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.observationsList = [];
    this.currentDateAttr = isoFormatDate().fullDate;

    this.filterRoleOption = Object.keys(RoleMapper).map((k) => {
      return { 
        value: RoleMapper[k as keyof typeof RoleMapper], 
        icon: RoleIcon(RoleMapper[k as keyof typeof RoleMapper]).icon,
        checked: false 
      }
    })

    this.resetInSelfControlGroup();

    if (this.properties.actionPanel$.value !== PanelManageActionsEnum.SEARCH
      && this.properties.serviceDataSource.getDynamicFields)
      this.getDynamicFields().subscribe({
        next: (dynamicFields: ParametricFormI[]) => {
          this.dynamicFields$.next(dynamicFields);
        },
        complete: () => {
          this.initFormControls();
          this.properties.actionDataForm?.subscribe(this.setFormControl.bind(this));
        }
      });
    else {
      this.initFormControls();
      this.properties.actionDataForm?.subscribe(this.setFormControl.bind(this));
    }

    this.properties.actionPanel$
      .pipe(
        filter((panelAction) => panelAction === PanelManageActionsEnum.SEARCH)
      )
      .subscribe(() => this.validateDatesOnSearchIncidents());
  }

  private reasignViewChildObservable() {
    if (this.locationControlViewChild) {
      this.locationControlModel.locationControlViewChild$.next(
        this.locationControlViewChild
      );
    }
  }

  ngAfterViewInit() {
    this.reasignViewChildObservable();
    //   this.validateDatesOnSearchIncidents();
    // this.properties.actionPanel$
    //   .pipe(
    //     filter((panelAction) => panelAction === PanelManageActionsEnum.CREATE)
    //   )
    //   .subscribe(() => this.setDateTimeOnCreateOnly());
  }

  getOriginalFormModel(originalFormModel: ParametricFormI[][]) {
    return [...originalFormModel];
  }

  handleHideFromParent(control: ParametricFormI): boolean {
    if (!control.parentFormKey)
      return false;
    return (!!this.properties.formGroup.get(control.parentFormName ?? control.formName).get(control.parentFormKey)?.value)
      && (control.list && control.list.length > 0);
  }

  initializeMainFormGroup(mainFormGroup: FormGroup) {
    this.dynamicFields$.subscribe(
      dynamicFields => {
        this.formModelObject[FormGroupNamesEnum.MAIN] = this.getOriginalFormModel([
          ...this.properties.formModel.filter(cg => !cg[0].multipleFormControl), 
          ...dynamicFields.map(f => [f]),
          ...this.properties.formModel.filter(cg => cg[0].multipleFormControl)
        ]);
        this.properties.formGroup.removeControl(FormGroupNamesEnum.MAIN);
        this.properties.formGroup.addControl(FormGroupNamesEnum.MAIN, mainFormGroup);
      }
    );
  }

  getDynamicFields(): Observable<ParametricFormI[]> {
    return this.properties.serviceDataSource.getDynamicFields?.()
      .pipe(
        map((fields: IDynamicFormModel[]) =>
          fields.map(field => dynamicFormMapper(field))
        )
      )
  }

  addControlToMainFormGroup(control: ParametricFormI, mainFormGroup: FormGroup) {
    control.formName = FormGroupNamesEnum.MAIN;
    const validatorsArray = (control.formControl.validators?.map((ele: IValidatorModel) => ele.validator) ?? []);

    mainFormGroup.addControl(
      control.formKey,
      new FormControl(control.formControl.value, {
        ...control.formControl.options,
        validators: validatorsArray,
      })
    );
  }

  handleDynamicControlBasedOnType(control: ParametricFormI) {
    switch (control.type) {
      case InputTypeEnum.INPUT:
      case InputTypeEnum.BINARY:
      case InputTypeEnum.LIST:
        break;
      default:
        break;
    }
  }

  
  handleSelectControlDependant(ctrl: AbstractControl, control: ParametricFormI): Subscription {
    let subscription = null;
    if (control.parentFormKey) {
        const parentFormControl = this.getParentOrFormControl(control, control.parentFormKey);
        const parentControlChanges$ = parentFormControl.valueChanges;
        subscription =
            parentControlChanges$.pipe(
                startWith(parentFormControl.value),
                switchMap(parentValue => {
                    if (ctrl.value && control.list && !!control.list.find(e => e.value === ctrl.value))
                        ctrl.patchValue('')
                    if (control.autocompleteParentList
                        && !this.cachedListMap.get(control.autocompleteParentList)?.find(el => el.value === parentValue)
                    )
                        return of([]); 
                    return this.getSelectControlOption(control, parentValue);
                }),
                takeUntil(this.destroy$)
            ).subscribe(lists => this.setListFn(ctrl, control, lists));
    } else {
        subscription =
            this.getSelectControlOption(control).subscribe(lists => this.setListFn(ctrl, control, lists));
    }
    return subscription;
}

setListFn(ctrl: AbstractControl, control: ParametricFormI, list: { value: number; name: string; }[]) {
    control.list = list.map(el => {
        return { value: `${el.value}`, name: el.name };
    });
    if (control.type === InputTypeEnum.AUTOCOMPLETE_INPUT) {
        control.subList = [...control.list];
        const newValidator: IValidatorModel = {
            validator: validarValorAutocomplete(list ?? []),
            errorMessage: 'Selecciona una opcion válida',
            validatorCodeName: CodeValidatorNameEnum.OPTION_AUTOCOPLETE
        };

        const validators = control.formControl.validators ?? [];
        const index = validators.findIndex(v => v.validatorCodeName === newValidator.validatorCodeName);
        if (index !== -1) {
            validators.splice(index, 1);
        }
        validators.push(newValidator);

        const validatorFns = validators.map(v => v.validator) as ValidatorFn[];
        ctrl.setValidators(validatorFns);
        ctrl.updateValueAndValidity();

    }
    if (this.properties.actionPanel$.value === PanelManageActionsEnum.CREATE) {
        if (control.formControl.value) { 
            ctrl.patchValue(control.formControl.value);
        }
    }
};

  handleControlBasedOnType(control: ParametricFormI) {
    let ctrl = this.getControl(control);
    if (control.formControl.options?.disabled) {
      ctrl.disable();
    }

    let subscription = null;
    switch (control.type) {
      case this.inputTypeEnum.INPUT:
        if (control.formKey === this.inputTypeEnum.LOCATION_INPUT || control.formKey === this.inputTypeEnum.LOCATION_INPUT_KEY) {
          this.handleLocationInputTypeControl(control);
        } else if (control.formKey === 'creatorUserName' && this.properties.actionPanel$.value === PanelManageActionsEnum.CREATE) {
          subscription =
            this.localStorageManagementService.userInfo$.subscribe((v) => ctrl.patchValue(v.names))
        }
        break;
      case this.inputTypeEnum.TESTING_RADIO:
      case this.inputTypeEnum.BUTTON_CHECKBOX:
      case this.inputTypeEnum.BUTTON_RADIO:
      case this.inputTypeEnum.SELECT:
        subscription = this.handleSelectControlDependant(ctrl, control);
        break;
        case this.inputTypeEnum.AUTOCOMPLETE_INPUT:
          control.autoCompleteDisplayFn = (option) => {
              return this.cachedListMap?.get(control.listOptionsType)?.find(element => element.value === option)?.name ?? option;
          };
          subscription = this.handleSelectControlDependant(ctrl, control);
          break;
      case this.inputTypeEnum.DATE:
          if (control?.changeValueFn) {
              subscription =
                  ctrl.valueChanges.subscribe(control?.changeValueFn(ctrl).bind(this));
          }
          break;
      case this.inputTypeEnum.DATETIME:
          if (this.properties.actionPanel$.value === PanelManageActionsEnum.CREATE) {
              ctrl.patchValue(isoFormatDate().fullDate);
          }
          if (control?.changeValueFn) {
              subscription =
                  ctrl.valueChanges.subscribe(control?.changeValueFn(ctrl).bind(this));
          }
          break;
      default:
          break;
      }
      subscription && this.subscriptions.add(subscription);


    if (!!!control.ancestorValueDependant) return; 
      const { key, type } = control.ancestorValueDependant;
      const parentValueControl = this.getParentOrFormControl(control, key);
      const subcategoryId = this.getParentOrFormControl(control, IncidentFormEnum.subcategoria);
      const broker = this.getMainFormField(IncidentFormEnum.brokerId);
        try {
            parentValueControl.valueChanges.subscribe(val => {
                if (!!subcategoryId.value && !!val) {
                    this.properties.serviceDataSource.getValueById(subcategoryId.value, {
                        featureType: type,
                        featureId: val,
                        incidentInCorridor: (!!broker.value)
                    }).subscribe({
                        next: state => {
                            if (state?.id)
                                ctrl.patchValue(`${state?.id}`);
                        },
                        error(err) {
                            switch (err.status) {
                                case 404:
                                    console.warn('No value for:', {
                                        featureType: type,
                                        featureId: val,
                                        incidentInCorridor: (!!broker.value)
                                    });
                                    break;
                                case 401:
                                    console.warn('Unauthorized');
                                    break;
                                case 500:
                                    console.warn('fallo en el servidor');
                                    break;
                                default:
                                    console.error(err);
                            }
                        },
                    })
                }
            });
        }
        catch (error: any) {
            if (error.status === 404) {
                console.warn('Recurso no encontrado.');
            } else {
                console.error('An error occurred at fetch:', error.error.message);
                const err = new Error(error); throwError(() => err);
            }
        }

    }
  getValueByCategoryGravity() { }

  
  updateSublist(value: string, control: ParametricFormI) {
    const nfd = (wrd: string) => wrd?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() ?? '';
    const nfdValue = nfd(value);
    if (control && control.list) {

        const startsWith = control.list.filter(sl =>
            nfd(sl.name).startsWith(nfd(value))
        );
        const contains = control.list.filter(sl =>
            !nfd(sl.name).startsWith(nfdValue) &&
            nfd(sl.name).includes(nfdValue)
        );

        let filteredList = startsWith.concat(contains);

        if (filteredList.length === 0) {
            const scoredList = control.list.map(sl => ({
                item: sl,
                distance: word_distance(nfdValue, nfd(sl.name?.split(' ')[0]))
            }));

            filteredList = scoredList
                .filter(sl => sl.distance < 6)
                .sort((a, b) => a.distance - b.distance)
                .map(sl => sl.item);
        }

        control.subList = filteredList.length ? filteredList : control.list;
    }
  }

  getControl(control: ParametricFormI): AbstractControl {
    return this.properties.formGroup.get(control.formName).get(control.formKey);
  }

  getParentOrFormControl(control: ParametricFormI, attr: string): AbstractControl {
    return this.properties.formGroup.get(control.parentFormName ?? control.formName).get(attr);
}


  handleLocationInputTypeControl(control: any) {
    this.locationControlModel = {
      panelAction: this.properties.actionPanel$.value,
      formGroup: this.properties.formGroup,
      controlModel: control,
      formName: control.formName,
      geoLocationService: this.properties.geolocationDataSource,
      locationControlViewChild$: new BehaviorSubject(undefined),
      cachedLocationList: this.cachedListMap
  };
  }


  handleMultipleFormControl(control: any) {
    if (control.multipleFormControl) {
      this.getControl(control).valueChanges.subscribe((value) => {
        if (value) {
          this.handleChangeValueMultipleControl(value, control);
        }
      });
    }
  }

  initFormControls(starterControl: number = 0) {
    const mainFormGroup = this.formBuilder.group({});

    if (starterControl === 0) {
      this.initializeMainFormGroup(mainFormGroup);
    }

    this.formModelArray.slice(starterControl).forEach((controlGroup) => {
      controlGroup.forEach((control) => {
        if (starterControl === 0) {
          this.addControlToMainFormGroup(control, mainFormGroup)
        }
        if (control.isDynamicControl)
          this.handleDynamicControlBasedOnType(control);
        else
          this.handleControlBasedOnType(control);
        this.handleMultipleFormControl(control);
      });
    });
  }

  updateFilterSelection() {
    if (this.filterRoleOption.every(o => !o.checked)) {
      this.filteredObservationsList = [...this.observationsList];
      return;
    }
    this.filteredObservationsList = this.observationsList.filter(o => {
      return !!this.filterRoleOption.find(f => (f.value === o.type))?.checked;
    });
  }

  updateSelection(control: ParametricFormI, event: any, item: any) {
    const selectedItemsControl = this.getControl(control);
    const selectedItems = selectedItemsControl?.value || [];
    if (event.target.checked) {
      selectedItems.push(item.value);
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }


    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: ParametricFormI, item: any): boolean {
    return this.getControl(control).value.includes(item.value);
  }

  handleChangeValueMultipleControl(controlValue: any, controlModel: ParametricFormI) {
    if (!controlModel.multipleFormControlModel || typeof +controlValue !== 'number')
      return;
    this.clearPreviousFormControl(controlModel);
    const arrayFormMultiple = this.createMultipleFormControls(+controlValue, controlModel);
    if (arrayFormMultiple && arrayFormMultiple.length) {
      this.addMultipleControlsToFormGroup(arrayFormMultiple, controlModel);
      this.updateFormModelArray(controlModel, arrayFormMultiple);
    }
    this.initFormControls(this.formModelArray.length);
  }

  clearPreviousFormControl(controlModel: ParametricFormI) {
    if (this.properties.formGroup.get(controlModel.formKey)) {
      this.formModelObject[controlModel.formKey] = [];
    }
  }

  createMultipleFormControls(controlValue: number, controlModel: ParametricFormI): ParametricFormI[][] {
    const arrayFormMultiple: ParametricFormI[][] = [];

    for (let i = 0; i < controlValue; i++) {
      const newControls = controlModel.multipleFormControlModel.map(multipleControlModel => {
        return multipleControlModel.map(controlElement => ({ ...controlElement }));
      });
      arrayFormMultiple.push(...newControls);
    }

    arrayFormMultiple.forEach((controls, i) => {
      controls.forEach(control => {
        control.label += `(${(i + 1).toString().padStart(2, '0')})`;
        control.formKey += `${i + 1}`;
      });
    });
    return arrayFormMultiple;
  }

  addMultipleControlsToFormGroup(arrayFormMultiple: ParametricFormI[][], controlModel: ParametricFormI) {
    const insideFormGroup = this.formBuilder.group({});
    arrayFormMultiple.forEach(insideControlGroup => {

      let subCache: { [key: string]: Observable<any> } = {};
      insideControlGroup.forEach(control => {
        control.formName = `${controlModel.formKey}`;
        insideFormGroup.addControl(
          `${control.formKey}`,
          new FormControl(control.formControl.value, {
            ...control.formControl.options,
            validators: (control.formControl.validators?.map((ele: IValidatorModel) => ele.validator) ?? []),
          })
        );
        if (control.type === InputTypeEnum.SELECT) {
          if (!(control.listOptionsType in subCache))
            subCache[control.listOptionsType] = this.getSelectControlOption(control)
          subCache[control.listOptionsType].subscribe(
            (lists: any) => {
              delete control['list'];
              control.list = lists;
              if (this.properties.actionPanel$.value === PanelManageActionsEnum.CREATE) {
                if (control.formControl.value) {
                  insideFormGroup.get(control.formKey).patchValue(control.formControl?.value);
                }
              }
            }
          )
        }
        if (this.properties.actionPanel$.value === PanelManageActionsEnum.EDIT) {
          if (this.dataForm?.[control.formKey] && (control.formKey.includes('vehicleTypeId') || control.formKey.includes('injuredAmount') || control.formKey.includes('deadAmount'))) {
            insideFormGroup.get(control.formKey).patchValue(`${this.dataForm[control.formKey]}`)
          }
        }
      });
    });

    this.properties.formGroup.removeControl(`${arrayFormMultiple[0][0].formName}`);
    this.properties.formGroup.addControl(`${arrayFormMultiple[0][0].formName}`, insideFormGroup);
  }

  updateFormModelArray(control: ParametricFormI, arrayFormMultiple: ParametricFormI[][]) {
    if (control.isOptionalFormInSelfGroup) {
      this.formModelArray.map(controlGroup => {
        controlGroup.map(control => {
          if (control.formKey === control.formKey) {
            controlGroup.push(...arrayFormMultiple[0]);
          }
        });
      });
    } else {
      this.formModelObject[`${control.formKey}`] = arrayFormMultiple;
    }
  }


  getSelectControlOption(control: ParametricFormI, parentValue?: number): Observable<any> {
    if (control.parentFormKey && (!parentValue || !this.getMainFormField(control.parentFormKey)?.value)) {
      return of([]);
    }

    return this.properties.serviceDataSource
      .getSelectOptions(control.listOptionsType, control.parentFormKey && parentValue)
      .pipe(
        map(list => {
          return control.turnLabelAsValue
            ? list.map(({ name }: { value: string, name: string }) => ({ value: name, name }))
            : list;
        }),
        tap(list => {
          if (control.sortLabels) {
            list.sort((a: { value: string, name: string }, b: {
                value: string,
                name: string
            }) => (isNaN(+a.name) || isNaN(+b.name)) ? a.name?.localeCompare(b.name) : +a.name - +b.name)
        }
        if (control.cacheList
            || control.type === InputTypeEnum.AUTOCOMPLETE_INPUT) { //Autocomplete requires the chached list to handle the list uptade on value
            this.cachedListMap.set(control.listOptionsType, list);
        }
        }),
      );
  }

  setFormControl(dataForm: any) {
    if (!dataForm) return;
    const remappedDataForm = remapObjectToOneLevel(dataForm);
    this.dataForm = remappedDataForm;

    this.formModelArray.forEach((formModelRow: ParametricFormI[]) => {
      formModelRow.forEach((control: ParametricFormI) => {
        this.handleFormModel(remappedDataForm, control);
      });
    });

    if (remappedDataForm.latitude && remappedDataForm.longitude)
      this.setLatLongMarkerPoint(
        remappedDataForm.latitude,
        remappedDataForm.longitude,
        remappedDataForm.statusId
      );
  }

  private handleFormModel(remappedDataForm: any, control: ParametricFormI) {
    if (!control) return;
    if (control.formKey === 'observations') {
        this.observationsList = remappedDataForm[control.formKey];
        this.filteredObservationsList = [...this.observationsList];
        return;
    }

    const formControl = this.getControl(control);

    if (remappedDataForm.statusId === parseInt(IncidentStatusCodeEnum.CERRADO)) {
        formControl.disable();
    }
    formControl?.patchValue(`${remappedDataForm[control.formKey] ?? ''}`);
    if (control.formKey.includes('dynamic')) {
        if (control.type === InputTypeEnum.INPUT) {
            const value = remappedDataForm[control.formKey];
            formControl?.patchValue((value === 0 || value === '0') ? '' : value);
        }
        if (control.type === InputTypeEnum.BINARY)
            formControl?.patchValue(remappedDataForm[control.formKey] ? remappedDataForm[control.formKey] === 'true' : false);
    }
    if (control.formKey.includes('Date'))
        formControl?.patchValue(`${remappedDataForm[control.formKey]?.replace(':00.000z', '') ?? ''}`);
  }

  onFocus(event: any, formControl: ParametricFormI) {
    const ctrl = this.getControl(formControl);

    const startPhrase = (this.properties.actionPanel$.value === 'edit') ? 'Se realiza la modificación del incidente creado el: ' + formatDate(this.getMainFormField('beginDate').value) : formatDate(isoFormatDate().fullDate) + ' Incidente: ' + formatDate(this.getMainFormField('incidentTime').value);
    ctrl.patchValue(`${startPhrase} con la siguiente información.
Se presenta ${this.getLabelFromCachedList(IncidentsListEnum.CATEGORIA, 'classesId')} ${this.getLabelFromCachedList(IncidentsListEnum.SUBCATEGORIA, 'typeId')} en ${this.getMainFormField(InputTypeEnum.LOCATION_INPUT).value} ${this.getMainFormField('orientId').value}
Se asigna unidad de tránsito.
Fuente: ${this.getLabelFromCachedList(IncidentsListEnum.FUENTE, 'sourceId')}. *Localidad de: ${this.getMainFormField(InputTypeEnum.LOCALITY).value}`);

  }

  getLabelFromCachedList(type: string, field: string,): string {
    const value = this.getMainFormField(field).value;
    return this.cachedListMap.get(type)?.find(element => element.value === value)?.name ?? value;
  }

  setLatLongMarkerPoint(latitude: number, longitude: number, statusId: number) {
    this.locationControlModel.panelAction = this.properties.actionPanel$.value;
    this.locationControlModel.statusId = statusId;

    // this.properties.formGroup.get('latlng2').patchValue([latitude, longitude]);
    this.mapboxDataService.handleMapClick({
      lngLat: { lat: latitude, lng: longitude },
    } as MapMouseEvent);
  }

  getMainFormField(field: string) {
    return this.properties.formGroup
      .get(FormGroupNamesEnum.MAIN)
      .get(field);
  }

  resetInSelfControlGroup() {
    const deletedControls = this.formModelObject[
      `${FormGroupNamesEnum.INCIDENT_TYPE}${FormGroupNamesEnum.OPTIONAL}`
    ]?.map((controlGroup) => {
      return {
        controlGroup: controlGroup.splice(1),
        parentControlKey: controlGroup[0].formKey,
      };
    });

    deletedControls?.forEach((controlGroup) => {
      controlGroup.controlGroup.forEach((control) => {
        (
          this.properties.formGroup.get(
            `${FormGroupNamesEnum.INCIDENT_TYPE}${FormGroupNamesEnum.OPTIONAL}`
          ) as FormGroup
        ).removeControl(control.formKey);

        this.properties.formGroup.removeControl(
          `${controlGroup.parentControlKey}${FormGroupNamesEnum.OPTIONAL}`
        );
      });
    });

    // delete controlObjects on formModelObjet other than mainForm and incidentTypeIdOptionalForm (for changing incident type from accidentes conciliados)
    Object.keys(this.formModelObject).forEach((nameForm) => {
      if (
        nameForm !==
        `${FormGroupNamesEnum.INCIDENT_TYPE}${FormGroupNamesEnum.OPTIONAL}` &&
        nameForm !== `${FormGroupNamesEnum.MAIN}`
      ) {
        delete this.formModelObject[nameForm];
        this.properties.formGroup.removeControl(nameForm);
      }
    });
  }

  validateDatesOnSearchIncidents() {
    if (this.properties.panelOnManagement !== MapEventTypeEnum.INCIDENTS)
      return;
    const dateInfo = isoFormatDate();
    const todayDate: string = dateInfo.fullDate;

    if (this.getMainFormField('beginDate')) {
      const incBeginDate = document.getElementById('incidentForm-beginDate')
      if (incBeginDate) {
        (incBeginDate as HTMLInputElement).max = todayDate;
      }

      this.getMainFormField('beginDate')
        .valueChanges.subscribe((beginDate) => {
          if (incBeginDate) {
            (incBeginDate as HTMLInputElement).max = todayDate;
            const incEndDate = document.getElementById('incidentForm-endDate');
            if (incEndDate) {
              (incEndDate as HTMLInputElement).min = beginDate;
              (incEndDate as HTMLInputElement).max = todayDate;
            }
          }
        });
    }

    const endDate = this.getMainFormField('endDate');
    if (endDate) {
      const incEndDate = document.getElementById('incidentForm-endDate');
      if (incEndDate) {
        (incEndDate as HTMLInputElement).max = todayDate;
      }
      endDate
        .valueChanges.subscribe((endDate) => {
          if (incEndDate) {
            (incEndDate as HTMLInputElement).max = todayDate;
            const incBeginDate = (document.getElementById('incidentForm-beginDate') as HTMLInputElement);
            if (incBeginDate)
              incBeginDate.max = endDate;
          }
        });
    }
    if (this.getMainFormField('incidentDate')) {
      const incDate = document.getElementById('incidentForm-incidentDate');
      incDate && ((incDate as HTMLInputElement).max = todayDate);
    }

  }
}
