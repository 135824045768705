export const incidentData = [
  {
      "id": 248095,
      "address": "CALLE 138 CON CARRERA 52",
      "latitude": 4.724867343902588,
      "longitude": -74.05565643310547,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T00:20:39.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:17:29> Incidente: 2023-09-25 00:12 \"Unidad de tránsito MOVIL1-11\tSI\tPATIÑO CAMARGO ESTEBAN IGNASIO\t88229\tGCW960\n\t\tPT\tARIAS AMAYA JULIETH YAMILE\t94237 (MOV/MOVIL11) atiende la novedad y retira los vehículos.\"              ",
      "incident_time": "2023-09-25T00:12:00.000+00:00"
  },
  {
      "id": 248096,
      "address": "CALLE 59 SUR  CON CARRERA 82G",
      "latitude": 4.616987228393555,
      "longitude": -74.1854019165039,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T00:21:46.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:17:45> Incidente: 2023-09-25 00:20 \"Unidad de tránsito MOVIL 8\tSI\tBOTERO FLOREZ HELBERT AUGUSTO \t62622\tGCW965\t612\t3138515965\tN/A\n\t\tPT\tVILLAMIL CASTELLANOS SANDY LORENA\t181741\t\t\t\tN/A (MOV/MOVIL8) atiende la novedad y retira los vehículos.\"              ",
      "incident_time": "2023-09-25T00:20:00.000+00:00"
  },
  {
      "id": 248097,
      "address": "CALLE 68 CON AV. BOYACÁ",
      "latitude": 4.68337345123291,
      "longitude": -74.09801483154297,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T00:31:03.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:18:10> Incidente: 2023-09-25 00:21 \"Unidad de tránsito MOVIL 9-10\tPT\tHERRERA PUENTES EDISSON HERNANDO\t88965\tGCW973\t2729/678\n\t\tPT\tYUDI DISNEY  MAHECHA SALAZAR \t94202\t\t705 (MOV/MOVIL10) atiende la novedad y retira los vehículos.\"",
      "incident_time": "2023-09-25T00:21:00.000+00:00"
  },
  {
      "id": 248098,
      "address": "CARRERA 68  CON CALLE 68",
      "latitude": 4.677438259124756,
      "longitude": -74.0860366821289,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "3",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T00:34:26.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Carrera 68 NS",
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:18:58> Incidente: 2023-09-25 00:31 \"Unidad de tránsito : APOYO 7-8 SI JHON JIMENEZ  88361 \nPT ALEXANDER PALMA  092462 (MOV/APOYO8) atiende la novedad y retira los vehículos.\"              ",
      "incident_time": "2023-09-25T00:31:00.000+00:00"
  },
  {
      "id": 248099,
      "address": "CARRERA 68 CON CALLE 33A SUR",
      "latitude": 4.6059699058532715,
      "longitude": -74.13082885742188,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T01:21:53.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Carrera 68 SN",
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:19:24> Incidente: 2023-09-25 01:13 \"Unidad de tránsito : APOYO 7-8 SI JHON JIMENEZ  88361 \nPT ALEXANDER PALMA  092462 (MOV/APOYO8) atiende la novedad y retira los vehículos.\"              ",
      "incident_time": "2023-09-25T01:13:00.000+00:00"
  },
  {
      "id": 248100,
      "address": "CALLE 26 (AV. DORADO)  CON CARRERA 98",
      "latitude": 4.687547206878662,
      "longitude": -74.12779998779297,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T01:24:31.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Calle 26 EW",
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:19:41> Incidente: 2023-09-25 01:21 \"Unidad de tránsito DELTA 8\tPT\tPACHON GUTIERRES FRANCISCO JAVIER \t89022\tDDZ44E\t650 (MOV/DELTA8) atiende la novedad y retira los vehículos.\"              ",
      "incident_time": "2023-09-25T01:21:00.000+00:00"
  },
  {
      "id": 248101,
      "address": "CARRERA 81A CON CALLE 58 SUR",
      "latitude": 4.613955020904541,
      "longitude": -74.18182373046875,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Volcamiento",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T01:28:22.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Yuly Castro cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 03:20:00> Incidente: 2023-09-25 01:24 \"Unidad de tránsito MOVIL 7-8\tSI\tMOLINA GUZMAN ADOLFO\t56725\tGCW974\t\t3007651220\tN/A\t\n\t\tPT\tBARRERA MORALES LINA TATIANA\t187285\t\t1761\t\tN/A (MOV/MOVIL7) atiende la novedad y retira los vehículos.\"              ",
      "incident_time": "2023-09-25T01:24:00.000+00:00"
  },
  {
      "id": 248102,
      "address": "AV. CALI  CON CALLE 25C",
      "latitude": 4.675972938537598,
      "longitude": -74.12232971191406,
      "location_name": "Fontibon",
      "class_name": "Manifestación",
      "type_name": "Otros",
      "priority_name": "0",
      "source_name": "Gerente de vía",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T04:22:48.000+00:00",
      "gravity_name": "Plantón con afectación",
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:21:26> Incidente: 2023-09-25 03:20 AV. CALI  CON CALLE 25C Sin orientación : Se presenta Manifestación Otros trabajadores Plantón con afectación. Se asigna unidad de tránsito. Fuente: Gerente de vía. Localidad: Fontibon.\n\nUnidad de tránsito ALMA 9-16 PT\tHERNANDEZ MENDEZ ANGIE  atiende la novedad y retira los vehículos.",
      "incident_time": "2023-09-25T03:20:00.000+00:00"
  },
  {
      "id": 248103,
      "address": "CARRERA 2ESTE CON CALLE 75D SUR",
      "latitude": 4.512659072875977,
      "longitude": -74.10633087158203,
      "location_name": "Usme",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T04:34:37.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:21:57> Incidente: 2023-09-25 04:22 CARRERA 2ESTE CON CALLE 75D SUR Sin orientación : Se presenta Siniestro Choque contra objeto fijo Separador Simple. Implicados: 1: Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Usme.\n\nUnidad de tránsito APOYO SUR SI RODRIGUEZ PATARROYO JHON FREDY\t125441\tLBU55F PT\tVASQUEZ BERMUDEZ DIEGO ARMANDO\t42038 atiende la novedad y retira los vehículos.",
      "incident_time": "2023-09-25T04:22:00.000+00:00"
  },
  {
      "id": 248104,
      "address": "CALLE 72 CON AV. CALI",
      "latitude": 4.695898532867432,
      "longitude": -74.1058349609375,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T04:55:36.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:22:28> Incidente: 2023-09-25 04:34 CALLE 72 CON AV. CALI Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Bicicleta 1 lesionados. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Engativa.\n\nUnidad de tránsito MOVIL 9-10 SI\tLUGO CARMONA WEIMAR atiende la novedad y retira los vehículos.\n              ",
      "incident_time": "2023-09-25T04:34:00.000+00:00"
  },
  {
      "id": 248105,
      "address": "CALLE 10 CON CALLE 2A",
      "latitude": 4.594543933868408,
      "longitude": -74.07142639160156,
      "location_name": "Candelaria",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T05:23:24.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:22:58> Incidente: 2023-09-25 04:57 CALLE 10 CON CALLE 2A Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta 1 lesionados; Motocicleta 1 lesionados. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Candelaria.\n\nUnidad de tránsito MOVIL 15-CENTRO SI MONTOYA CHALA A atiende la novedad y retira los vehículos.\n         ",
      "incident_time": "2023-09-25T04:57:00.000+00:00"
  },
  {
      "id": 248106,
      "address": "CARRERA 89B CON CALLE 50 SUR",
      "latitude": 4.634102821350098,
      "longitude": -74.18185424804688,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T05:24:42.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:23:56> Incidente: 2023-09-25 05:23 CARRERA 89B CON CALLE 50 SUR Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta 1 lesionados; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Bosa.\n\nUnidad de tránsito MOVIL8\tIT\tMORATO LAITON OMAR ADELMO\t141934\tGCW973\t506\t3138502105\tN/A PT\tHURTADO RAMOS  INGRID LORENA \t94219\t\t577\t\tN/A (MOV/MOVIL8) atiende la novedad y retira los vehículos.              ",
      "incident_time": "2023-09-25T05:23:00.000+00:00"
  },
  {
      "id": 248107,
      "address": "CALLE 30 A SUR  CON CARRERA 3 A ESTE",
      "latitude": 4.5617194175720215,
      "longitude": -74.09030151367188,
      "location_name": "San Cristobal",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T05:25:46.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:24:18> Incidente: 2023-09-25 05:24 CALLE 30 A SUR  CON CARRERA 3 A ESTE Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Camioneta; Motocicleta 1 lesionados. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL SUR PT BAUTISTA PEÑA DEINIS atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: San Cristobal.\n              ",
      "incident_time": "2023-09-25T05:24:00.000+00:00"
  },
  {
      "id": 248108,
      "address": "CARRERA 33 CON CALLE 42 F",
      "latitude": 4.587278842926025,
      "longitude": -74.12723541259766,
      "location_name": "Rafael Uribe Uribe",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "3",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T05:33:07.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:25:03> Incidente: 2023-09-25 05:25 CARRERA 33 CON CALLE 42 F Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito :\tALMA 8-14\tPT\tPARRA FLOREZ JULIO CESAR \t75477\tLBN71F\t619\t3015714955\tB (MOV/A0814) atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Rafael Uribe Uribe.\n              ",
      "incident_time": "2023-09-25T05:25:00.000+00:00"
  },
  {
      "id": 248109,
      "address": "AV. CARACAS  CON AV. VILLAVICENCIO",
      "latitude": 4.568799018859863,
      "longitude": -74.12577819824219,
      "location_name": "Tunjuelito",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T05:36:39.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Caracas SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:27:43> Incidente: 2023-09-25 05:33 AV. CARACAS  CON AV. VILLAVICENCIO Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Bicicleta 1 lesionados. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 6-9\tPT\tPT NARANJO MUÑOZ EDWIN ALE atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Tunjuelito.\n              ",
      "incident_time": "2023-09-25T05:33:00.000+00:00"
  },
  {
      "id": 248110,
      "address": "CARRERA 88 CON CALLE 6A",
      "latitude": 4.645260810852051,
      "longitude": -74.15746307373047,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T05:38:35.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:28:24> Incidente: 2023-09-25 05:36 CARRERA 88 CON CALLE 6A Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta 1 lesionados. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-16 PT\tCATOLICO BECERRA RICHARD DANIEL\t186983\t821\t3505302591\tB (MOV/A0816) atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Kennedy.\n              ",
      "incident_time": "2023-09-25T05:36:00.000+00:00"
  },
  {
      "id": 248111,
      "address": "Av. Américas  Carrera 50",
      "latitude": 4.627880096435547,
      "longitude": -74.11469268798828,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:11:54.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Américas WE",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:28:55> Incidente: 2023-09-25 06:10 Av. Américas  Carrera 50 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 15 CENTRO atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Puente Aranda.\n              ",
      "incident_time": "2023-09-25T06:10:00.000+00:00"
  },
  {
      "id": 248112,
      "address": "Diagonal 127 Carrera 64",
      "latitude": 4.711052894592285,
      "longitude": -74.07286834716797,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:13:47.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Suba NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:29:19> Incidente: 2023-09-25 06:11 Diagonal 127 Carrera 64 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11\tSI\tCALDERON CARDENAS JHON JAIRO atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Suba.\n              ",
      "incident_time": "2023-09-25T06:11:00.000+00:00"
  },
  {
      "id": 248113,
      "address": "Av. Boyacá  Calle 26 (Av. Dorado) ",
      "latitude": 4.666924953460693,
      "longitude": -74.11145782470703,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:15:16.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:29:47> Incidente: 2023-09-25 06:13 Av. Boyacá  Calle 26 (Av. Dorado)  Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-7\tSI\tRODRIGUEZ RAMIREZ JAIME ALBERTO\t59682\tOFS18E\t1156 (MOV/A0907) atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Engativa.\n              ",
      "incident_time": "2023-09-25T06:13:00.000+00:00"
  },
  {
      "id": 248114,
      "address": "Carrera 68  Calle 20 SUR",
      "latitude": 4.612443923950195,
      "longitude": -74.12800598144531,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:16:16.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Carrera 68 SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:30:08> Incidente: 2023-09-25 06:15 Carrera 68  Calle 20 SUR Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-1\tIT \tTELLEZ RUIZ JUAN VLADIMIR\t103884 (MOV/A1501) atiende la novedad y retira los vehículos.\nFuente: NUSE. Localidad: Puente Aranda\n              ",
      "incident_time": "2023-09-25T06:15:00.000+00:00"
  },
  {
      "id": 248115,
      "address": "Calle 5 Carrera 26A",
      "latitude": 4.602350234985352,
      "longitude": -74.09632873535156,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:17:26.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:30:35> Incidente: 2023-09-25 06:16 Calle 5 Carrera 26A Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Camión. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 14-13\tPT\tBUITRAGO CADENA YERSI YESENIA\t94284 (MOV/A1413) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Los Martires*.\n              ",
      "incident_time": "2023-09-25T06:16:00.000+00:00"
  },
  {
      "id": 248116,
      "address": "Calle 37 SUR Carrera 3 ESTE",
      "latitude": 4.555621147155762,
      "longitude": -74.09390258789062,
      "location_name": "San Cristóbal",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:19:55.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:30:54> Incidente: 2023-09-25 06:17 Calle 37 SUR Carrera 3 ESTE Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 4-5\tSI\tLOPEZ RAMIREZ DEYBID ALBERTO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: San Cristóbal*.\n              ",
      "incident_time": "2023-09-25T06:17:00.000+00:00"
  },
  {
      "id": 248117,
      "address": "Calle 48I BIS SUR Carrera 9",
      "latitude": 4.5589985847473145,
      "longitude": -74.11338806152344,
      "location_name": "Rafael Uribe Uribe",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:20:46.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:31:18> Incidente: 2023-09-25 06:19 Calle 48I BIS SUR Carrera 9 Occidente - Oriente : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 4-14\tPT\tRUIZ RUIZ ANGELA MARIA \t atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Rafael Uribe Uribe*.\"\n              ",
      "incident_time": "2023-09-25T06:19:00.000+00:00"
  },
  {
      "id": 248118,
      "address": "Av. Primero de Mayo  Av. Caracas",
      "latitude": 4.581236839294434,
      "longitude": -74.10225677490234,
      "location_name": "Antonio Nariño",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:27:19.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:31:40> Incidente: 2023-09-25 06:20 Av. Primero de Mayo  Av. Caracas Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Camioneta; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-10 \tPT \tGERENA SALAS HEINER ARLEY\t179734\tOGC75E\t809 (MOV/A1510) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Antonio Nariño*.\n              ",
      "incident_time": "2023-09-25T06:20:00.000+00:00"
  },
  {
      "id": 248119,
      "address": "Carrera 72B Calle 1",
      "latitude": 4.623124599456787,
      "longitude": -74.13994598388672,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:28:21.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:32:06> Incidente: 2023-09-25 06:27 Carrera 72B Calle 1 Sur - Norte : Se presenta Siniestro Choque contra objeto fijo Vivienda Con lesionado. Implicados: 1: Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-14 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.",
      "incident_time": "2023-09-25T06:27:00.000+00:00"
  },
  {
      "id": 248120,
      "address": "Carrera 103F Calle 139",
      "latitude": 4.741330146789551,
      "longitude": -74.0975112915039,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:29:03.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:32:20> Incidente: 2023-09-25 06:28 Carrera 103F Calle 139 Occidente - Oriente : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T06:28:00.000+00:00"
  },
  {
      "id": 248121,
      "address": "Av. Primero de Mayo  Carrera 10",
      "latitude": 4.575760841369629,
      "longitude": -74.09446716308594,
      "location_name": "Antonio Nariño",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:31:07.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:32:38> Incidente: 2023-09-25 06:29 Av. Primero de Mayo  Carrera 10 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL SUR\tPT \tBAUTISTA PEÑA DEINIS atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Antonio Nariño*.\n              ",
      "incident_time": "2023-09-25T06:29:00.000+00:00"
  },
  {
      "id": 248122,
      "address": "Av. Boyacá  Calle 129",
      "latitude": 4.720405101776123,
      "longitude": -74.07530212402344,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Waze",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:31:59.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:32:55> Incidente: 2023-09-25 06:31 Av. Boyacá  Calle 129 Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11\tSI\tCALDERON CARDENAS JHON JAIRO \t93998\tGCW972 atiende la novedad y retira los vehículos.\nFuente: Waze. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T06:31:00.000+00:00"
  },
  {
      "id": 248123,
      "address": "Av. Boyacá  Calle 13",
      "latitude": 4.651397228240967,
      "longitude": -74.12625122070312,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:36:24.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:33:17> Incidente: 2023-09-25 06:32 Av. Boyacá  Calle 13 Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Camión. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-7\tSI\tRODRIGUEZ RAMIREZ JAIME ALBERTO\t59682 (MOV/A0907) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Fontibon*.\n              ",
      "incident_time": "2023-09-25T06:32:00.000+00:00"
  },
  {
      "id": 248124,
      "address": "Av. Villavicencio  Carrera 79",
      "latitude": 4.618036270141602,
      "longitude": -74.16436004638672,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:39:41.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:33:34> Incidente: 2023-09-25 06:36 Av. Villavicencio  Carrera 79 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T06:36:00.000+00:00"
  },
  {
      "id": 248125,
      "address": "Calle 91A SUR Carrera 14 BIS A",
      "latitude": 4.506128787994385,
      "longitude": -74.11431121826172,
      "location_name": "Usme",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T06:41:15.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:33:52> Incidente: 2023-09-25 06:39 Calle 91A SUR Carrera 14 BIS A Sin orientación : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 5-2\tSI\tGARCIA HERNANDEZ JHON FREDY\t atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usme\n              ",
      "incident_time": "2023-09-25T06:39:00.000+00:00"
  },
  {
      "id": 248126,
      "address": "Av. Circunvalar  Calle 59",
      "latitude": 4.642649173736572,
      "longitude": -74.05860900878906,
      "location_name": "Chapinero",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:05:38.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:34:16> Incidente: 2023-09-25 06:41 Av. Circunvalar  Calle 59 Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12\tPT \tMARTINEZ VEGA NAIDER MANUEL\t35452 PT \tCAMACHO VELOSA EVELIN ADRIANA\t188531 (MOV/MOVIL2) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Chapinero*.\n              ",
      "incident_time": "2023-09-25T06:41:00.000+00:00"
  },
  {
      "id": 248127,
      "address": "Calle 71A Carrera 78A",
      "latitude": 4.691184043884277,
      "longitude": -74.10173034667969,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:06:59.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:34:46> Incidente: 2023-09-25 07:05 Calle 71A Carrera 78A Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-15\tPT\tDURAN RUIZ DIEGO ANDRES atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T07:05:00.000+00:00"
  },
  {
      "id": 248128,
      "address": "Calle 45 SUR Carrera 80",
      "latitude": 4.619933128356934,
      "longitude": -74.16799926757812,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:10:07.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:35:07> Incidente: 2023-09-25 07:06 Calle 45 SUR Carrera 80 Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito.\nUnidad de tránsito MOVIL 7- 8\tIT\tMORATO LAITON OMAR ADELMO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T07:06:00.000+00:00"
  },
  {
      "id": 248129,
      "address": "Carrera 65B Calle 12A",
      "latitude": 4.621614456176758,
      "longitude": -74.10262298583984,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:11:00.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:35:36> Incidente: 2023-09-25 07:10 Carrera 65B Calle 12A Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-10         PT         GERENA SALAS HEINER ARLEY        179734        OGC75E        809 (MOV/A1510) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T07:10:00.000+00:00"
  },
  {
      "id": 248130,
      "address": "Av. Cali  Calle 10B",
      "latitude": 4.650156021118164,
      "longitude": -74.14595031738281,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:11:49.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:35:54> Incidente: 2023-09-25 07:11 Av. Cali  Calle 10B Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Tracto camión; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-16\tPT\tCATOLICO BECERRA RICHARD atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\"\n              ",
      "incident_time": "2023-09-25T07:11:00.000+00:00"
  },
  {
      "id": 248131,
      "address": "Carrera 4 BIS Calle 83 SUR",
      "latitude": 4.5071892738342285,
      "longitude": -74.10685729980469,
      "location_name": "Usme",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:15:35.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:36:18> Incidente: 2023-09-25 07:11 Carrera 4 BIS Calle 83 SUR Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Usme*.\n              ",
      "incident_time": "2023-09-25T07:11:00.000+00:00"
  },
  {
      "id": 248132,
      "address": "Av. Boyacá  Calle 19A",
      "latitude": 4.652568340301514,
      "longitude": -74.12393188476562,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:16:45.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:36:34> Incidente: 2023-09-25 07:15 Av. Boyacá  Calle 19A Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-4 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Fontibon*.\n              ",
      "incident_time": "2023-09-25T07:15:00.000+00:00"
  },
  {
      "id": 248133,
      "address": "KR 18B X CL 53 S Y DG 54 S",
      "latitude": 4.567238807678223,
      "longitude": -74.13005828857422,
      "location_name": "Tunjuelito",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento correctivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Verificado",
      "create_date": "2023-09-25T07:17:20.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 07:17:19> Incidente: 2023-09-25 06:56 KR 18B X CL 53 S Y DG 54 S Sin orientación : Se presenta Semaforización Mantenimiento correctivo. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Tunjuelito*.",
      "incident_time": "2023-09-25T06:56:00.000+00:00"
  },
  {
      "id": 248134,
      "address": "Carrera 11 Calle 1",
      "latitude": 4.589819431304932,
      "longitude": -74.08566284179688,
      "location_name": "Santa Fe",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:39:49.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:37:26> Incidente: 2023-09-25 07:16 Carrera 11 Calle 1 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Camión; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 3-12\tPT\tSERENO MOYA LAURA MARCELA \t181808\tOGD10E\t1070 (MOV/A0312) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Santa Fe*.\n              ",
      "incident_time": "2023-09-25T07:16:00.000+00:00"
  },
  {
      "id": 248135,
      "address": "Calle 26 (Av. Dorado)  Carrera 68 ",
      "latitude": 4.65397310256958,
      "longitude": -74.1031723022461,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:45:09.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Calle 26 EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:37:49> Incidente: 2023-09-25 07:39 Calle 26 (Av. Dorado)  Carrera 68  Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Engativa*.\n\n              ",
      "incident_time": "2023-09-25T07:39:00.000+00:00"
  },
  {
      "id": 248136,
      "address": "Carrera 16C Calle 63 SUR",
      "latitude": 4.556220054626465,
      "longitude": -74.1355972290039,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:46:10.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:38:11> Incidente: 2023-09-25 07:45 Carrera 16C Calle 63 SUR Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Ambulancia; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 6-16 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Ciudad Bolivar*.\n              ",
      "incident_time": "2023-09-25T07:45:00.000+00:00"
  },
  {
      "id": 248137,
      "address": "Calle 45C BIS Carrera 22",
      "latitude": 4.636647701263428,
      "longitude": -74.07343292236328,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:46:53.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:38:31> Incidente: 2023-09-25 07:46 Calle 45C BIS Carrera 22 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12\tPT \tMARTINEZ VEGA NAIDER MANUEL atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Teusaquillo*.\n              ",
      "incident_time": "2023-09-25T07:46:00.000+00:00"
  },
  {
      "id": 248138,
      "address": "Carrera 59 Calle 152B",
      "latitude": 4.739439964294434,
      "longitude": -74.064453125,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:47:53.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:38:56> Incidente: 2023-09-25 07:46 Carrera 59 Calle 152B Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11\tSI\tCALDERON CARDENAS JHON atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\n\n              ",
      "incident_time": "2023-09-25T07:46:00.000+00:00"
  },
  {
      "id": 248139,
      "address": "AK 97 X DG 16",
      "latitude": 4.666947841644287,
      "longitude": -74.1488037109375,
      "location_name": "Fontibon",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento correctivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:48:43.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <CAMILO GARAVITO cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 10:40:36> Incidente: 2023-09-25 07:37  Intersección  Semaforica ak 97 x dg 16 Habilitada A las 10:34",
      "incident_time": "2023-09-25T07:37:00.000+00:00"
  },
  {
      "id": 248140,
      "address": "Carrera 40 Calle 1F",
      "latitude": 4.608114242553711,
      "longitude": -74.1136474609375,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:49:56.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:39:39> Incidente: 2023-09-25 07:47 Carrera 40 Calle 1F Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Patineta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-10 \tPT \tGERENA SALAS HEINER ARLEY\t179734\tOGC75E\t809 (MOV/A1510) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T07:47:00.000+00:00"
  },
  {
      "id": 248141,
      "address": "Av. Cali  Calle 20",
      "latitude": 4.663995265960693,
      "longitude": -74.13191986083984,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:51:01.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Ciudad de Cali SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:40:10> Incidente: 2023-09-25 07:49 Av. Cali  Calle 20 Norte - sur : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-4\tSI \tBUITRAGO MOLINA OSCAR RICARDO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Fontibon*.\n              ",
      "incident_time": "2023-09-25T07:49:00.000+00:00"
  },
  {
      "id": 248142,
      "address": "Carrera 14A Calle 69 BIS",
      "latitude": 4.65621280670166,
      "longitude": -74.06340789794922,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:52:34.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:40:35> Incidente: 2023-09-25 07:51 Carrera 14A Calle 69 BIS Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12\tPT \tMARTINEZ VEGA NAIDER MANUE atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Barrios Unidos*.\n              ",
      "incident_time": "2023-09-25T07:51:00.000+00:00"
  },
  {
      "id": 248143,
      "address": "Calle 26 (Av. Dorado)  Av. Rojas ",
      "latitude": 4.662964820861816,
      "longitude": -74.10900115966797,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:53:34.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Calle 26 EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:40:57> Incidente: 2023-09-25 07:52 Calle 26 (Av. Dorado)  Av. Rojas  Sin orientación : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Patineta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 9-10\tSI\tLUGO CARMONA WEIMAR atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T07:52:00.000+00:00"
  },
  {
      "id": 248144,
      "address": "Autonorte  Calle 192",
      "latitude": 4.769723892211914,
      "longitude": -74.04305267333984,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:54:27.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:41:17> Incidente: 2023-09-25 07:53 Autonorte  Calle 192 Norte - sur : Se presenta Siniestro Choque contra objeto fijo Separador Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 14-6\tPT\tPRADA LOZANO JAIR ALFREDO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.",
      "incident_time": "2023-09-25T07:53:00.000+00:00"
  },
  {
      "id": 248145,
      "address": "Calle 3 Carrera 40 BIS",
      "latitude": 4.603585720062256,
      "longitude": -74.11821746826172,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:55:13.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:41:44> Incidente: 2023-09-25 07:54 Calle 3 Carrera 40 BIS Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 15-CENTRO\tSI\tMONTOYA CHALA ANDERSON \t105582\tGCW977\t775 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T07:54:00.000+00:00"
  },
  {
      "id": 248146,
      "address": "Av. Cali  Calle 80 ",
      "latitude": 4.702549934387207,
      "longitude": -74.10033416748047,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:57:18.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Calle 80 EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:43:11> Incidente: 2023-09-25 07:55 Av. Cali  Calle 80  Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-15\tPT\tDURAN RUIZ DIEGO ANDRES \t40907\tLBN-75F (MOV/A1015) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\"\n              ",
      "incident_time": "2023-09-25T07:55:00.000+00:00"
  },
  {
      "id": 248147,
      "address": "Calle 27 SUR Carrera 19",
      "latitude": 4.580074787139893,
      "longitude": -74.10737609863281,
      "location_name": "Rafael Uribe Uribe",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:58:31.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:43:33> Incidente: 2023-09-25 07:57 Calle 27 SUR Carrera 19 Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 4-14 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Rafael Uribe Uribe*.\n              ",
      "incident_time": "2023-09-25T07:57:00.000+00:00"
  },
  {
      "id": 248148,
      "address": "Carrera 80 Calle 42C SUR",
      "latitude": 4.6218156814575195,
      "longitude": -74.16421508789062,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:59:18.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:43:55> Incidente: 2023-09-25 07:58 Carrera 80 Calle 42C SUR Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito PETICIONES 8\tSI\tARIAS ARIAS CARLOS ANDRES atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T07:58:00.000+00:00"
  },
  {
      "id": 248149,
      "address": "NQS  Calle 80 ",
      "latitude": 4.694759368896484,
      "longitude": -74.08782958984375,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T07:59:50.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Calle 80 EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:44:21> Incidente: 2023-09-25 07:59 NQS  Calle 80  Norte - sur : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito QUITO 3\tSI\tCARDOZO MANCIPE JOHN WILLINTO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T07:59:00.000+00:00"
  },
  {
      "id": 248150,
      "address": "Av. Caracas  Calle 65 SUR",
      "latitude": 4.533685207366943,
      "longitude": -74.12000274658203,
      "location_name": "Usme",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:00:44.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:44:42> Incidente: 2023-09-25 07:59 Av. Caracas  Calle 65 SUR Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 5-2\tSI\tGARCIA HERNANDEZ JHON FREDY\t110463\tDDZ-53E (MOV/A0502) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usme*.\n              ",
      "incident_time": "2023-09-25T07:59:00.000+00:00"
  },
  {
      "id": 248151,
      "address": "Calle 80  Carrera 107",
      "latitude": 4.71543550491333,
      "longitude": -74.1169204711914,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Gogev",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:01:32.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Calle 80 WE",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:45:09> Incidente: 2023-09-25 08:00 Calle 80  Carrera 107 Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Bus. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-04\tSI \tQUEVEDO JAIMES EDUIN ALEXAN atiende la novedad y retira los vehículos.\nFuente: Gogev. *Localidad: Engativa*.\"\n              ",
      "incident_time": "2023-09-25T08:00:00.000+00:00"
  },
  {
      "id": 248152,
      "address": "Av. Boyacá  Calle 4 SUR",
      "latitude": 4.619826316833496,
      "longitude": -74.1390380859375,
      "location_name": "Kennedy",
      "class_name": "Varado",
      "type_name": null,
      "priority_name": "1",
      "source_name": "TMSA",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:02:43.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Boyacá SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:45:32> Incidente: 2023-09-25 08:01 Av. Boyacá  Calle 4 SUR Sur - Norte : Se presenta Varado . Implicados: 1: Tracto camión. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: TMSA. *Localidad: Kennedy*.",
      "incident_time": "2023-09-25T08:01:00.000+00:00"
  },
  {
      "id": 248153,
      "address": "Carrera 13B ESTE  Calle 41B SUR",
      "latitude": 4.546346187591553,
      "longitude": -74.08358764648438,
      "location_name": "San Cristóbal",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:08:30.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:45:52> Incidente: 2023-09-25 08:02 Carrera 13B ESTE  Calle 41B SUR Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL SUR\tPT \tBAUTISTA PEÑA DEINIS\t94125\tGCW968 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: San Cristóbal*.\n              ",
      "incident_time": "2023-09-25T08:02:00.000+00:00"
  },
  {
      "id": 248154,
      "address": "Calle 17 Carrera 96H",
      "latitude": 4.669888019561768,
      "longitude": -74.14356231689453,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:09:19.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:46:15> Incidente: 2023-09-25 08:08 Calle 17 Carrera 96H Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-4\tSI \tBUITRAGO MOLINA OSCAR RICARDO\t atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Fontibon*.\n              ",
      "incident_time": "2023-09-25T08:08:00.000+00:00"
  },
  {
      "id": 248155,
      "address": "Av. Primero de Mayo  Carrera 79",
      "latitude": 4.613611221313477,
      "longitude": -74.17082977294922,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:15:46.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:04:53> Incidente: 2023-09-25 08:09 Av. Primero de Mayo  Carrera 79 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-16\tPT\tCATOLICO BECERRA RICHARD  atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T08:09:00.000+00:00"
  },
  {
      "id": 248156,
      "address": "Calle 34 SUR Carrera 7",
      "latitude": 4.5643696784973145,
      "longitude": -74.100341796875,
      "location_name": "San Cristóbal",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:16:26.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:46:39> Incidente: 2023-09-25 08:15 Calle 34 SUR Carrera 7 Oriente - Occidente : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito :\tALMA 4-5\tSI\tLOPEZ RAMIREZ DEYBID ALBERTO\t90428\tLBO32F (MOV/A0405) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: San Cristóbal*.\n              ",
      "incident_time": "2023-09-25T08:15:00.000+00:00"
  },
  {
      "id": 248157,
      "address": "Autonorte  Calle 80 .",
      "latitude": 4.668035984039307,
      "longitude": -74.06010437011719,
      "location_name": "Chapinero",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:17:16.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Autopista norte SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:47:01> Incidente: 2023-09-25 08:16 Autonorte  Calle 80 . Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito :\tALMA 4-5\tSI\tLOPEZ RAMIREZ DEYBID ALBERTO\t90428\tLBO32F (MOV/A0405) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Chapinero*.\n              ",
      "incident_time": "2023-09-25T08:16:00.000+00:00"
  },
  {
      "id": 248158,
      "address": "Calle 8A Carrera 92",
      "latitude": 4.654577255249023,
      "longitude": -74.15596008300781,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:17:57.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:47:28> Incidente: 2023-09-25 08:17 Calle 8A Carrera 92 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bicitaxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito PETICIONES 8-1\tSI\tARIAS ARIAS CARLOS ANDRES atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T08:17:00.000+00:00"
  },
  {
      "id": 248159,
      "address": "Carrera 21 Calle 154",
      "latitude": 4.737985134124756,
      "longitude": -74.04637145996094,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:18:36.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:28:31> Incidente: 2023-09-25 08:17 Carrera 21 Calle 154 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 1-8\tPT\tARIZA DIAZ FOLKENVER atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T08:17:00.000+00:00"
  },
  {
      "id": 248160,
      "address": "Calle 80  Carrera 114",
      "latitude": 4.721347808837891,
      "longitude": -74.12110900878906,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Waze",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:19:31.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Calle 80 WE",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:37:10> Incidente: 2023-09-25 08:18 Calle 80  Carrera 114 Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: Waze. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T08:18:00.000+00:00"
  },
  {
      "id": 248161,
      "address": "KR 89 X AC 59C SUR",
      "latitude": 4.628052234649658,
      "longitude": -74.19221496582031,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:30:30.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:10:39> Incidente: 2023-09-25 08:00  Intersección  Semaforica kr 89 x ac 59c sur Habilitada A las 12:01",
      "incident_time": "2023-09-25T08:00:00.000+00:00"
  },
  {
      "id": 248162,
      "address": "KR 8 X CL 1C S",
      "latitude": 4.585609436035156,
      "longitude": -74.08441162109375,
      "location_name": "San Cristóbal",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:31:19.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <CAMILO GARAVITO cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 10:40:56> Incidente: 2023-09-25 08:14  Intersección  Semaforica kr 8 x cl 1c s Habilitada A las 10:34",
      "incident_time": "2023-09-25T08:14:00.000+00:00"
  },
  {
      "id": 248163,
      "address": "Cl. 41 Sur #78k-5",
      "latitude": 4.61986780166626,
      "longitude": -74.15660858154297,
      "location_name": "Kennedy",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:33:14.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <CAMILO GARAVITO cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 10:41:15> Incidente: 2023-09-25 08:15  Intersección  Semaforica kr 78 k x cl 40 sur  Habilitada A las 10:00",
      "incident_time": "2023-09-25T08:15:00.000+00:00"
  },
  {
      "id": 248164,
      "address": "AK 30 X AC 3",
      "latitude": 4.603426456451416,
      "longitude": -74.10067749023438,
      "location_name": "Puente Aranda",
      "class_name": "Semaforización",
      "type_name": "Posteria semáforos",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:34:10.000+00:00",
      "gravity_name": null,
      "corredor_name": "NQS NS",
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:14:44> Incidente: 2023-09-25 08:15  Intersección  Semaforica ak 30 x ac 3 Habilitada A las 10:55",
      "incident_time": "2023-09-25T08:15:00.000+00:00"
  },
  {
      "id": 248165,
      "address": "KR 28A X CL 3",
      "latitude": 4.602331161499023,
      "longitude": -74.09911346435547,
      "location_name": "Los Martires",
      "class_name": "Semaforización",
      "type_name": "Posteria semáforos",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:35:06.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:14:55> Incidente: 2023-09-25 08:15  Intersección  Semaforica kr 28a x cl 3 Habilitada A las 10:55",
      "incident_time": "2023-09-25T08:15:00.000+00:00"
  },
  {
      "id": 248166,
      "address": "KR 9 X AC 51 S",
      "latitude": 4.5577521324157715,
      "longitude": -74.12220001220703,
      "location_name": "Tunjuelito",
      "class_name": "Semaforización",
      "type_name": "Reprogramación de equipo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:36:37.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <CAMILO GARAVITO cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 10:41:33> Incidente: 2023-09-25 08:27  Intersección  Semaforica kr 9 x ac 51 s Habilitada A las 10:00",
      "incident_time": "2023-09-25T08:27:00.000+00:00"
  },
  {
      "id": 248167,
      "address": "Calle 80  Av. Boyacá ",
      "latitude": 4.680356502532959,
      "longitude": -74.10017395019531,
      "location_name": "Engativa",
      "class_name": "Varado",
      "type_name": null,
      "priority_name": "1",
      "source_name": "Waze",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:42:31.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 08:42:49> Incidente: 2023-09-25 08:42 Calle 80  Av. Boyacá  Occidente - Oriente : Se presenta Varado . Implicados: 1: Automóvil. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: Waze. *Localidad: Engativa*.",
      "incident_time": "2023-09-25T08:42:00.000+00:00"
  },
  {
      "id": 248168,
      "address": "Carrera 3 Calle 1B",
      "latitude": 4.583807468414307,
      "longitude": -74.07786560058594,
      "location_name": "Santa Fe",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:48:55.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:28:07> Incidente: 2023-09-25 08:47 Carrera 3 Calle 1B Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 3-14\tPT\tBERDUGO NIEBLES BREINE atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Santa Fe*.\"\n              ",
      "incident_time": "2023-09-25T08:47:00.000+00:00"
  },
  {
      "id": 248169,
      "address": "NQS  Calle 87A",
      "latitude": 4.639186859130859,
      "longitude": -74.07928466796875,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:49:55.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:04:12> Incidente: 2023-09-25 08:48 NQS  Calle 87A Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito QUITO 3\tSI\tCARDOZO MANCIPE JOHN WILLINTON\t76595 (MOV/QUITO3) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Teusaquillo*.\n              ",
      "incident_time": "2023-09-25T08:48:00.000+00:00"
  },
  {
      "id": 248170,
      "address": "Calle 36F SUR Carrera 1",
      "latitude": 4.557999134063721,
      "longitude": -74.09532165527344,
      "location_name": "San Cristóbal",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:51:06.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:03:44> Incidente: 2023-09-25 08:49 Calle 36F SUR Carrera 1 Sin orientación : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 4-14\tPT\tRUIZ RUIZ ANGELA MARIA \t94249\tOGD33E\t1274 (MOV/A0414) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: San Cristóbal*.\n              ",
      "incident_time": "2023-09-25T08:49:00.000+00:00"
  },
  {
      "id": 248171,
      "address": "Autosur  Av. Bosa ",
      "latitude": 4.596768379211426,
      "longitude": -74.17742156982422,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Cámaras",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:51:57.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Autopista sur SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:03:23> Incidente: 2023-09-25 08:51 Autosur  Av. Bosa  Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Tracto camión; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 7-4 atiende la novedad y retira los vehículos.\nFuente: Cámaras. *Localidad: Ciudad Bolivar*.\n              ",
      "incident_time": "2023-09-25T08:51:00.000+00:00"
  },
  {
      "id": 248172,
      "address": "Calle 47 Carrera 8",
      "latitude": 4.63383150100708,
      "longitude": -74.06458282470703,
      "location_name": "Chapinero",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:52:36.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:03:03> Incidente: 2023-09-25 08:51 Calle 47 Carrera 8 Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Chapinero*.\n              ",
      "incident_time": "2023-09-25T08:51:00.000+00:00"
  },
  {
      "id": 248173,
      "address": "Av. Villavicencio  Carrera 77 ",
      "latitude": 4.611605167388916,
      "longitude": -74.16122436523438,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:57:27.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:29:03> Incidente: 2023-09-25 08:52 Av. Villavicencio  Carrera 77  Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Camión; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito PETICIONES 8-1\tSI\tARIAS ARIAS CARLOS ANDRES atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T08:52:00.000+00:00"
  },
  {
      "id": 248174,
      "address": "Carrera 69 Calle 98",
      "latitude": 4.690556526184082,
      "longitude": -74.07591247558594,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:58:16.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:02:42> Incidente: 2023-09-25 08:57 Carrera 69 Calle 98 Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\n              ",
      "incident_time": "2023-09-25T08:57:00.000+00:00"
  },
  {
      "id": 248175,
      "address": "Calle 23I BIS Carrera 102",
      "latitude": 4.682102203369141,
      "longitude": -74.13623046875,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T08:58:51.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:15:19> Incidente: 2023-09-25 08:58 Calle 23I BIS Carrera 102 Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-4\tSI \tBUITRAGO MOLINA OSCAR RICARDO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Fontibon*.\n              ",
      "incident_time": "2023-09-25T08:58:00.000+00:00"
  },
  {
      "id": 248176,
      "address": "Calle 72 Av. Boyacá ",
      "latitude": 4.687219619750977,
      "longitude": -74.09507751464844,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:00:51.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:15:44> Incidente: 2023-09-25 08:58 Calle 72 Av. Boyacá  Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-15\tPT\tDURAN RUIZ DIEGO ANDRES  atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T08:58:00.000+00:00"
  },
  {
      "id": 248177,
      "address": "NQS  Calle 53B BIS",
      "latitude": 4.644865989685059,
      "longitude": -74.07860565185547,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:00:54.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "NQS SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:24:59> Incidente: 2023-09-25 08:58 NQS  Calle 53B BIS Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito QUITO 10 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Teusaquillo*.\"\n              ",
      "incident_time": "2023-09-25T08:58:00.000+00:00"
  },
  {
      "id": 248178,
      "address": "Carrera 73 Calle 70 SUR",
      "latitude": 4.578996658325195,
      "longitude": -74.16643524169922,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:01:50.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:16:23> Incidente: 2023-09-25 09:00 Carrera 73 Calle 70 SUR Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 6-9\tPT\t     NARANJO MUÑOZ EDWIN ALEJANDRO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Ciudad Bolivar*.\n              ",
      "incident_time": "2023-09-25T09:00:00.000+00:00"
  },
  {
      "id": 248179,
      "address": "Av. Primero de Mayo  Carrera 50",
      "latitude": 4.604137897491455,
      "longitude": -74.12389373779297,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:11:48.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:22:22> Incidente: 2023-09-25 09:10 Av. Primero de Mayo  Carrera 50 Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 4-14\tPT\tRUIZ RUIZ ANGELA MARIA \t94249\tOGD33E\t1274 (MOV/A0414) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T09:10:00.000+00:00"
  },
  {
      "id": 248180,
      "address": "Carrera 22 Calle 71",
      "latitude": 4.660693168640137,
      "longitude": -74.06671905517578,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:12:37.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:21:57> Incidente: 2023-09-25 09:11 Carrera 22 Calle 71 Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 12-12\tPT\tSANCHEZ CORDOBA MINDRED YISELA\t94311 (MOV/A1212) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Barrios Unidos*.\"\n              ",
      "incident_time": "2023-09-25T09:11:00.000+00:00"
  },
  {
      "id": 248181,
      "address": "Calle 52A Carrera 85",
      "latitude": 4.623402118682861,
      "longitude": -74.17745971679688,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:13:23.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:17:03> Incidente: 2023-09-25 09:12 Calle 52A Carrera 85 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-15\tPT\tDURAN RUIZ DIEGO ANDRES  atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T09:12:00.000+00:00"
  },
  {
      "id": 248182,
      "address": "Calle 20 Carrera 49",
      "latitude": 4.627474308013916,
      "longitude": -74.10521697998047,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:17:52.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Américas EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:17:27> Incidente: 2023-09-25 09:13 Calle 20 Carrera 49 Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-10 \tPT \tGERENA SALAS HEINER ARLEY atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T09:13:00.000+00:00"
  },
  {
      "id": 248183,
      "address": "Carrera 7  Calle 118",
      "latitude": 4.6953229904174805,
      "longitude": -74.03226470947266,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:19:41.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Carrera 7 NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:21:39> Incidente: 2023-09-25 09:17 Carrera 7  Calle 118 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 1-9\tPT\tORDIZ DIAZ LEYDI KATERINE\t37806\tOGD19D (MOV/A0109) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T09:17:00.000+00:00"
  },
  {
      "id": 248184,
      "address": "Calle 106 Carrera 13A",
      "latitude": 4.689281463623047,
      "longitude": -74.0435562133789,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:30:40.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 09:30:58> Incidente: 2023-09-25 09:29 Calle 106 Carrera 13A Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Patineta; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11\tSI\tCALDERON CARDENAS JHON JAIRO \t93998\tGCW972 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T09:29:00.000+00:00"
  },
  {
      "id": 248185,
      "address": "Carrera 28 Calle 52B SUR",
      "latitude": 4.579685688018799,
      "longitude": -74.13739776611328,
      "location_name": "Tunjuelito",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:36:36.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:23:51> Incidente: 2023-09-25 09:35 Carrera 28 Calle 52B SUR Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Camión. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 6-16 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Tunjuelito*.\n              ",
      "incident_time": "2023-09-25T09:35:00.000+00:00"
  },
  {
      "id": 248186,
      "address": "Carrera 28 Calle 53B",
      "latitude": 4.643734931945801,
      "longitude": -74.07710266113281,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:38:46.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:23:27> Incidente: 2023-09-25 09:36 Carrera 28 Calle 53B Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12\tPT \tMARTINEZ VEGA NAIDER MANUEL\t35452 PT \tCAMACHO VELOSA EVELIN ADRIANA\t188531 (MOV/MOVIL12) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Teusaquillo*.\n              ",
      "incident_time": "2023-09-25T09:36:00.000+00:00"
  },
  {
      "id": 248187,
      "address": "Carrera 68  Av. Mutis ",
      "latitude": 4.666107654571533,
      "longitude": -74.09326171875,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:40:24.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Carrera 68 SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:24:11> Incidente: 2023-09-25 09:38 Carrera 68  Av. Mutis  Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Unidad de tránsito ALMA 12-5\tSI\tGALINDO ACOSTA JUAN CARLOS \t93343 (MOV/A1205) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Barrios Unidos*.\n              ",
      "incident_time": "2023-09-25T09:38:00.000+00:00"
  },
  {
      "id": 248188,
      "address": "Carrera 53 Calle 4F",
      "latitude": 4.619476795196533,
      "longitude": -74.11307525634766,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:41:23.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:24:36> Incidente: 2023-09-25 09:40 Carrera 53 Calle 4F Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-7\tSI\tCARDENAS TRIANA CARLOS ANDRES \t59573\tOGD-73E\t772 (MOV/A1507) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T09:40:00.000+00:00"
  },
  {
      "id": 248189,
      "address": "Carrera 89 BIS A Calle 56 SUR",
      "latitude": 4.6305742263793945,
      "longitude": -74.18797302246094,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T09:43:06.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:25:10> Incidente: 2023-09-25 09:41 Carrera 89 BIS A Calle 56 SUR Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito PETICIONES 7\tPT\tBARRIOS MEDINA NIRA JOHANNA atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Bosa*.\n              ",
      "incident_time": "2023-09-25T09:41:00.000+00:00"
  },
  {
      "id": 248190,
      "address": "KR 87H X AC 59C SUR",
      "latitude": 4.623866081237793,
      "longitude": -74.18994903564453,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:37:36.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:15:06> Incidente: 2023-09-25 09:29  Intersección  Semaforica kr 87h x ac 59c sur Habilitada A las 10:52",
      "incident_time": "2023-09-25T09:29:00.000+00:00"
  },
  {
      "id": 248191,
      "address": "KR 1 X CL 87C S",
      "latitude": 4.504612445831299,
      "longitude": -74.10438537597656,
      "location_name": "Usme",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:38:25.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:10:55> Incidente: 2023-09-25 09:56  Intersección  Semaforica kr 1 x cl 87c s Habilitada A las 12:03",
      "incident_time": "2023-09-25T09:56:00.000+00:00"
  },
  {
      "id": 248192,
      "address": "KR 81 X CL 71A SUR",
      "latitude": 4.609917163848877,
      "longitude": -74.19446563720703,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:39:14.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:11:12> Incidente: 2023-09-25 09:59  Intersección  Semaforica kr 81 x cl 71a sur Habilitada A las 11:20",
      "incident_time": "2023-09-25T09:59:00.000+00:00"
  },
  {
      "id": 248193,
      "address": "AK 14 X CL 8",
      "latitude": 4.599473476409912,
      "longitude": -74.08294677734375,
      "location_name": "Los Martires",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:40:02.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Caracas NS",
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:11:29> Incidente: 2023-09-25 10:33  Intersección  Semaforica ak 14 x cl 8 Habilitada A las 11:58",
      "incident_time": "2023-09-25T10:33:00.000+00:00"
  },
  {
      "id": 248194,
      "address": "Av. Villavicencio  Carrera 22B",
      "latitude": 4.567723751068115,
      "longitude": -74.14482116699219,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "TMSA",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:42:29.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:25:35> Incidente: 2023-09-25 09:43 Av. Villavicencio  Carrera 22B Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Bus. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 6-9 atiende la novedad y retira los vehículos.\nFuente: TMSA. *Localidad: Ciudad Bolivar*.\n              ",
      "incident_time": "2023-09-25T09:43:00.000+00:00"
  },
  {
      "id": 248195,
      "address": "Carrera 104A Calle 148",
      "latitude": 4.74721622467041,
      "longitude": -74.0929946899414,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:43:16.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:25:53> Incidente: 2023-09-25 10:42 Carrera 104A Calle 148 Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 11-1 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T10:42:00.000+00:00"
  },
  {
      "id": 248196,
      "address": "Carrera 72 Autosur ",
      "latitude": 4.595642566680908,
      "longitude": -74.16728210449219,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:43:59.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Autopista sur NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:26:15> Incidente: 2023-09-25 10:43 Carrera 72 Autosur  Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 7-4\tSI \tGAMBOA RUIZ JEYSON DAVID atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Ciudad Bolivar*.\"\n              ",
      "incident_time": "2023-09-25T10:43:00.000+00:00"
  },
  {
      "id": 248197,
      "address": "Carrera 4 Calle 22",
      "latitude": 4.606182098388672,
      "longitude": -74.0678482055664,
      "location_name": "Santa Fe",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:45:15.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:26:51> Incidente: 2023-09-25 10:43 Carrera 4 Calle 22 Norte - sur : Se presenta Siniestro Choque contra objeto fijo Separador Simple. Implicados: 1: Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 3-5\tSI\tHERRERA ZABALA JOHN ANDRES\t atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Santa Fe*.",
      "incident_time": "2023-09-25T10:43:00.000+00:00"
  },
  {
      "id": 248198,
      "address": "Av. Cali  Calle 72 BIS",
      "latitude": 4.697201728820801,
      "longitude": -74.10530090332031,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:46:39.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:27:23> Incidente: 2023-09-25 10:45 Av. Cali  Calle 72 BIS Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-15\tPT\tDURAN RUIZ DIEGO ANDRES \t40907\tLBN-75F (MOV/A1015) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T10:45:00.000+00:00"
  },
  {
      "id": 248199,
      "address": "Carrera 56 Calle 3",
      "latitude": 4.6274566650390625,
      "longitude": -74.11290740966797,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:47:27.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Américas WE",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:27:46> Incidente: 2023-09-25 10:46 Carrera 56 Calle 3 Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-10 \tPT \tGERENA SALAS HEINER ARLEY atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T10:46:00.000+00:00"
  },
  {
      "id": 248200,
      "address": "Calle 128A  BIS Carrera 91",
      "latitude": 4.720856666564941,
      "longitude": -74.0899887084961,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:48:00.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:28:10> Incidente: 2023-09-25 10:47 Calle 128A  BIS Carrera 91 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 11-3\tSI URBINA NARVAEZ DIEGO FRANCISCO\t89868 OFS-43E 666 (MOV/A1103) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\n              ",
      "incident_time": "2023-09-25T10:47:00.000+00:00"
  },
  {
      "id": 248201,
      "address": "Av. Primero de Mayo  Carrera 52",
      "latitude": 4.605900287628174,
      "longitude": -74.12556457519531,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:51:14.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:28:39> Incidente: 2023-09-25 10:48 Av. Primero de Mayo  Carrera 52 Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-7\tSI\tCARDENAS TRIANA CARLOS  atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\"\n              ",
      "incident_time": "2023-09-25T10:48:00.000+00:00"
  },
  {
      "id": 248202,
      "address": "Carrera 97 Calle 24",
      "latitude": 4.679988384246826,
      "longitude": -74.13260650634766,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:52:06.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:29:00> Incidente: 2023-09-25 10:51 Carrera 97 Calle 24 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-16\tPT\tHERNANDEZ MENDEZ  atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Fontibon*.\n              ",
      "incident_time": "2023-09-25T10:51:00.000+00:00"
  },
  {
      "id": 248203,
      "address": "Calle 23 Carrera 32",
      "latitude": 4.6246209144592285,
      "longitude": -74.08582305908203,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:53:03.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:29:24> Incidente: 2023-09-25 10:52 Calle 23 Carrera 32 Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-1\tIT \tTELLEZ RUIZ JUAN VLADIMIR atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T10:52:00.000+00:00"
  },
  {
      "id": 248204,
      "address": "Calle 75 Carrera 27",
      "latitude": 4.666464805603027,
      "longitude": -74.06669616699219,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:54:18.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:29:45> Incidente: 2023-09-25 10:53 Calle 75 Carrera 27 Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 12-12\tPT\tSANCHEZ CORDOBA MINDRED YISELA\t94311 (MOV/A1212) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Barrios Unidos*.\n              ",
      "incident_time": "2023-09-25T10:53:00.000+00:00"
  },
  {
      "id": 248205,
      "address": "Carrera 81 Calle 68C SUR",
      "latitude": 4.6097636222839355,
      "longitude": -74.19218444824219,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:55:25.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:30:07> Incidente: 2023-09-25 10:54 Carrera 81 Calle 68C SUR Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Bosa*.\n              ",
      "incident_time": "2023-09-25T10:54:00.000+00:00"
  },
  {
      "id": 248206,
      "address": "Carrera 7C Calle 182",
      "latitude": 4.758787155151367,
      "longitude": -74.02820587158203,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:56:21.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:30:25> Incidente: 2023-09-25 10:55 Carrera 7C Calle 182 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 1-8 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T10:55:00.000+00:00"
  },
  {
      "id": 248207,
      "address": "Calle 132 Carrera 153",
      "latitude": 4.741978645324707,
      "longitude": -74.1233139038086,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T10:57:56.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:41:07> Incidente: 2023-09-25 10:56 Calle 132 Carrera 153 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 4: Motocicleta; Motocicleta; Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\n              ",
      "incident_time": "2023-09-25T10:56:00.000+00:00"
  },
  {
      "id": 248208,
      "address": "Calle 61 BIS Carrera 13B",
      "latitude": 4.647940635681152,
      "longitude": -74.06385040283203,
      "location_name": "Chapinero",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:00:06.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:41:42> Incidente: 2023-09-25 10:57 Calle 61 BIS Carrera 13B Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Bicitaxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 2-8 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Chapinero*.\n              ",
      "incident_time": "2023-09-25T10:57:00.000+00:00"
  },
  {
      "id": 248209,
      "address": "Diagonal 76 BIS  Carrera 51",
      "latitude": 4.672408580780029,
      "longitude": -74.0711669921875,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:00:45.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:42:04> Incidente: 2023-09-25 11:00 Diagonal 76 BIS  Carrera 51 Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 3: Automóvil; Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Barrios Unidos*.\n              ",
      "incident_time": "2023-09-25T11:00:00.000+00:00"
  },
  {
      "id": 248210,
      "address": "Av. Cali  Calle 13A",
      "latitude": 4.653951644897461,
      "longitude": -74.1419677734375,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:01:37.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:42:23> Incidente: 2023-09-25 11:00 Av. Cali  Calle 13A Norte - sur : Se presenta Siniestro Choque contra objeto fijo Separador Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito PTICIONES 8 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.",
      "incident_time": "2023-09-25T11:00:00.000+00:00"
  },
  {
      "id": 248211,
      "address": "Calle 1 Carrera 12A",
      "latitude": 4.590917587280273,
      "longitude": -74.08739471435547,
      "location_name": "Santa Fe",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:02:31.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:42:48> Incidente: 2023-09-25 11:01 Calle 1 Carrera 12A Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 3-14 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Santa Fe*.\n              ",
      "incident_time": "2023-09-25T11:01:00.000+00:00"
  },
  {
      "id": 248212,
      "address": "KR 69B X AC 26 S",
      "latitude": 4.614116668701172,
      "longitude": -74.13549041748047,
      "location_name": "Kennedy",
      "class_name": "Semaforización",
      "type_name": "Posteria semáforos",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:03:11.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 14:33:47> Incidente: 2023-09-25 10:43 REPROGRAMACION EQUIPO DE CONTROL",
      "incident_time": "2023-09-25T10:43:00.000+00:00"
  },
  {
      "id": 248213,
      "address": "Av. Boyacá  Calle 26 (Av. Dorado) ",
      "latitude": 4.6664228439331055,
      "longitude": -74.11154174804688,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:03:28.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá SN",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:43:05> Incidente: 2023-09-25 11:02 Av. Boyacá  Calle 26 (Av. Dorado)  Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Tracto camión; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-4\tSI BUITRAGO MOLINA OSCAR RICARDO 87721 (MOV/A0904) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T11:02:00.000+00:00"
  },
  {
      "id": 248214,
      "address": "TV 85 X CL 57B S",
      "latitude": 4.619287490844727,
      "longitude": -74.18346405029297,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:04:14.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 14:35:04> Incidente: 2023-09-25 10:55 REPROGRAMACION EQUIPO DE CONTROL",
      "incident_time": "2023-09-25T10:55:00.000+00:00"
  },
  {
      "id": 248215,
      "address": "Autonorte  Calle 94",
      "latitude": 4.681795597076416,
      "longitude": -74.05818176269531,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:04:35.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:44:37> Incidente: 2023-09-25 11:03 Autonorte  Calle 94 Norte - sur : Se presenta Siniestro Choque contra objeto fijo Puente peatonal o vehicular Simple. Implicados: 1: Vehiculo de servicio especial. Se asigna unidad de tránsito. \nUnidad de tránsito PETICIONES 2\tSI\tPEREZ BRAUCIN DIEGO AND atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Barrios Unidos*.",
      "incident_time": "2023-09-25T11:03:00.000+00:00"
  },
  {
      "id": 248216,
      "address": "AK 27 X CL 1B",
      "latitude": 4.596660614013672,
      "longitude": -74.1015625,
      "location_name": "Los Martires",
      "class_name": "Semaforización",
      "type_name": "Posteria semáforos",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Verificado",
      "create_date": "2023-09-25T11:05:24.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 11:05:18> Incidente: 2023-09-25 11:00 AK 27 X CL 1B Sin orientación : Se presenta Semaforización Posteria semáforos. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Los Martires*.",
      "incident_time": "2023-09-25T11:00:00.000+00:00"
  },
  {
      "id": 248217,
      "address": "Av. Cali  Calle 92",
      "latitude": 4.711675643920898,
      "longitude": -74.0933609008789,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:12:14.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:43:35> Incidente: 2023-09-25 11:04 Av. Cali  Calle 92 Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 11-3 SI URBINA NARVAEZ DIEGO FRANCISCO 89868 OFS-43E\t666 (MOV/A1103) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T11:04:00.000+00:00"
  },
  {
      "id": 248218,
      "address": "Carrera 7  Calle 90",
      "latitude": 4.670012474060059,
      "longitude": -74.04457092285156,
      "location_name": "Chapinero",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:13:19.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Carrera 7 NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:44:12> Incidente: 2023-09-25 11:12 Carrera 7  Calle 90 Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Camioneta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 2-12\tPT \tMARTINEZ VEGA NAIDER MANUEL 35452 PT CAMACHO VELOSA EVELIN ADRIANA\t188531 (MOV/MOVIL2) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Chapinero*.\n              ",
      "incident_time": "2023-09-25T11:12:00.000+00:00"
  },
  {
      "id": 248219,
      "address": "Carrera 102 Av. Cali ",
      "latitude": 4.727391242980957,
      "longitude": -74.10017395019531,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:14:52.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:44:43> Incidente: 2023-09-25 11:13 Carrera 102 Av. Cali  Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 11-2\tIT ROMERO JULIAN DAVID  94004 LBO-26F 778 (MOV/A1102) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T11:13:00.000+00:00"
  },
  {
      "id": 248220,
      "address": "Av. Caracas  Calle 35",
      "latitude": 4.623746395111084,
      "longitude": -74.06937408447266,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Waze",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:20:03.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Caracas NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:45:22> Incidente: 2023-09-25 11:18 Av. Caracas  Calle 35 Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 3-14 PT\tBERDUGO NIEBLES BREINER YESID 41664 LBO-64F 1342 (MOV/A0314) atiende la novedad y retira los vehículos.\nFuente: Waze. *Localidad: Teusaquillo*.\n              ",
      "incident_time": "2023-09-25T11:18:00.000+00:00"
  },
  {
      "id": 248221,
      "address": "Kilómetro 5 Calle VIA AL GUAVIO",
      "latitude": 4.823797702789307,
      "longitude": -74.03466033935547,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:48:36.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:44:59> Incidente: 2023-09-25 11:46 Kilómetro 5 Calle VIA AL GUAVIO Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Taxi; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito PETICIONES 3\tSI\tCRUZ OCHOA SERG atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T11:46:00.000+00:00"
  },
  {
      "id": 248222,
      "address": "Calle 164 Carrera 21",
      "latitude": 4.744756698608398,
      "longitude": -74.04483032226562,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:49:15.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:45:21> Incidente: 2023-09-25 11:48 Calle 164 Carrera 21 Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T11:48:00.000+00:00"
  },
  {
      "id": 248223,
      "address": "Av. Primero de Mayo  Carrera 15",
      "latitude": 4.581284523010254,
      "longitude": -74.10233306884766,
      "location_name": "Antonio Nariño",
      "class_name": "Varado",
      "type_name": null,
      "priority_name": "1",
      "source_name": "Cámaras",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:50:45.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:45:40> Incidente: 2023-09-25 11:49 Av. Primero de Mayo  Carrera 15 Oriente - Occidente : Se presenta Varado . Implicados: 1: Bus. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: Cámaras. *Localidad: Antonio Nariño*.",
      "incident_time": "2023-09-25T11:49:00.000+00:00"
  },
  {
      "id": 248224,
      "address": "Calle 71 Carrera 11",
      "latitude": 4.656395435333252,
      "longitude": -74.0593032836914,
      "location_name": "Chapinero",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:53:30.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:46:00> Incidente: 2023-09-25 11:50 Calle 71 Carrera 11 Occidente - Oriente : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 2-8\tPT\tMARULANDA CALVO OSCAR JA atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Chapinero*.\n              ",
      "incident_time": "2023-09-25T11:50:00.000+00:00"
  },
  {
      "id": 248225,
      "address": "Calle 6A Carrera 88D",
      "latitude": 4.646028518676758,
      "longitude": -74.15805053710938,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:54:15.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:57:28> Incidente: 2023-09-25 11:53 Calle 6A Carrera 88D Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Bus. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-14 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T11:53:00.000+00:00"
  },
  {
      "id": 248226,
      "address": "Carrera 88F Calle 40 SUR",
      "latitude": 4.637823104858398,
      "longitude": -74.16817474365234,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:54:55.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:57:47> Incidente: 2023-09-25 11:54 Carrera 88F Calle 40 SUR Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 11-2\tIT\tROMERO JULIAN DAVID \t94004\tLBO-26F\t778 (MOV/A1102) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T11:54:00.000+00:00"
  },
  {
      "id": 248227,
      "address": "Calle 37 SUR Carrera 78F",
      "latitude": 4.622677326202393,
      "longitude": -74.15188598632812,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:55:33.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:58:08> Incidente: 2023-09-25 11:54 Calle 37 SUR Carrera 78F Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito \tPETICIONES 8-1\tSI\tARIAS ARIAS CARLOS ANDRES\t79273 (MOV/PETICIONES8) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\"\n              ",
      "incident_time": "2023-09-25T11:54:00.000+00:00"
  },
  {
      "id": 248228,
      "address": "Calle 153 Carrera 8",
      "latitude": 4.730204105377197,
      "longitude": -74.02994537353516,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:56:17.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 11:58:29> Incidente: 2023-09-25 11:55 Calle 153 Carrera 8 Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11 SI CALDERON CARDENAS JHON JAIRO  93998 GCW972 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T11:55:00.000+00:00"
  },
  {
      "id": 248229,
      "address": "Carrera 87I Calle 74B SUR",
      "latitude": 4.619587421417236,
      "longitude": -74.20147705078125,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T11:57:00.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:46:30> Incidente: 2023-09-25 11:56 Carrera 87I Calle 74B SUR Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Camión. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 7-16\tPT\tMILDRED STEFANIE QUINA atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Bosa*.\n              ",
      "incident_time": "2023-09-25T11:56:00.000+00:00"
  },
  {
      "id": 248230,
      "address": "Av. Villavicencio  Av. Cali ",
      "latitude": 4.628448963165283,
      "longitude": -74.1708755493164,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:01:34.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Ciudad de Cali NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:47:00> Incidente: 2023-09-25 11:59 Av. Villavicencio  Av. Cali  Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito  atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T11:59:00.000+00:00"
  },
  {
      "id": 248231,
      "address": "Av Centenario Carrera 65",
      "latitude": 4.6344075202941895,
      "longitude": -74.11502075195312,
      "location_name": "Puente Aranda",
      "class_name": "Varado",
      "type_name": null,
      "priority_name": "1",
      "source_name": "Gogev",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:04:40.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Centenario (Calle 13) WE",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:47:16> Incidente: 2023-09-25 12:01 Av Centenario Carrera 65 Occidente - Oriente : Se presenta Varado . Implicados: 1: Bus. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: Gogev. *Localidad: Puente Aranda*.",
      "incident_time": "2023-09-25T12:01:00.000+00:00"
  },
  {
      "id": 248232,
      "address": "TV 87 BIS A X CL 59 C S",
      "latitude": 4.622957229614258,
      "longitude": -74.18914031982422,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Verificado",
      "create_date": "2023-09-25T12:08:03.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 12:08:02> Incidente: 2023-09-25 11:22 TV 87 BIS A X CL 59 C S Sin orientación : Se presenta Semaforización Mantenimiento preventivo. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Bosa*.",
      "incident_time": "2023-09-25T11:22:00.000+00:00"
  },
  {
      "id": 248233,
      "address": "KR 86 BIS X AC 59C SUR",
      "latitude": 4.6215057373046875,
      "longitude": -74.18804931640625,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:09:27.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:54:20> Incidente: 2023-09-25 11:56  Intersección  Semaforica kr 86 bis x ac 59c sur Habilitada A las 13:49",
      "incident_time": "2023-09-25T11:56:00.000+00:00"
  },
  {
      "id": 248234,
      "address": "Av Centenario Carrera 128",
      "latitude": 4.690070629119873,
      "longitude": -74.16361999511719,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "Cámaras",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:15:16.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:47:43> Incidente: 2023-09-25 12:04 Av Centenario Carrera 128 Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Camión; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 9-21\tPT \tRODRIGUEZ CACHAYA ANDRY VANESSA\t37842 (MOV/A0921) atiende la novedad y retira los vehículos.\nFuente: Cámaras. *Localidad: Fontibon*.\"\n              ",
      "incident_time": "2023-09-25T12:04:00.000+00:00"
  },
  {
      "id": 248235,
      "address": "Av. Boyacá  Calle 10",
      "latitude": 4.640377998352051,
      "longitude": -74.13538360595703,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Cámaras",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:17:29.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:07:32> Incidente: 2023-09-25 12:15 Av. Boyacá  Calle 10 Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-24\tPT \tCAMACHO OBANDO JULIUETH ANDREA\t39092\t\t726\t3217874147\tA (MOV/A0824) atiende la novedad y retira los vehículos.\nFuente: Cámaras. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T12:15:00.000+00:00"
  },
  {
      "id": 248236,
      "address": "Calle 1 Carrera 19D",
      "latitude": 4.593585968017578,
      "longitude": -74.09431457519531,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:18:06.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:48:08> Incidente: 2023-09-25 12:17 Calle 1 Carrera 19D Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL-CENTRO\tIT \tJIMENEZ PALACIOS JOHN MAURO atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Los Martires*.\"\n              ",
      "incident_time": "2023-09-25T12:17:00.000+00:00"
  },
  {
      "id": 248237,
      "address": "Carrera 73 Calle 38A SUR ",
      "latitude": 4.616338729858398,
      "longitude": -74.14795684814453,
      "location_name": "Kennedy",
      "class_name": "Incidente en vía",
      "type_name": "Caída de árbol",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:18:41.000+00:00",
      "gravity_name": "Afectación media",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:48:33> Incidente: 2023-09-25 12:18 Carrera 73 Calle 38A SUR  Norte - sur : Se presenta Incidente en vía: Caída de árbol:  Con Afectación media. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Kennedy*.",
      "incident_time": "2023-09-25T12:18:00.000+00:00"
  },
  {
      "id": 248238,
      "address": "Calle 72 Carrera 90",
      "latitude": 4.697703838348389,
      "longitude": -74.10859680175781,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:23:51.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:48:54> Incidente: 2023-09-25 12:23 Calle 72 Carrera 90 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Bicicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-06 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T12:23:00.000+00:00"
  },
  {
      "id": 248239,
      "address": "Av. Boyacá  Calle 127",
      "latitude": 4.7098517417907715,
      "longitude": -74.08016967773438,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:24:42.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:49:14> Incidente: 2023-09-25 12:23 Av. Boyacá  Calle 127 Norte - sur : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 11-3\tSI\tURBINA NARVAEZ DIEGO FRA atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T12:23:00.000+00:00"
  },
  {
      "id": 248240,
      "address": "Carrera 18 Calle 11",
      "latitude": 4.604569435119629,
      "longitude": -74.08484649658203,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:36:16.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:09:47> Incidente: 2023-09-25 12:35 Carrera 18 Calle 11 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Peatón. Se asigna unidad de tránsito. \nUnidad de tránsito  atiende la novedad.\nFuente: NUSE. *Localidad: Los Martires*.\n              ",
      "incident_time": "2023-09-25T12:35:00.000+00:00"
  },
  {
      "id": 248241,
      "address": "Carrera 42 Calle 2D",
      "latitude": 4.6132121086120605,
      "longitude": -74.1148452758789,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:39:43.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:49:42> Incidente: 2023-09-25 12:36 Carrera 42 Calle 2D Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-11 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\"\n              ",
      "incident_time": "2023-09-25T12:36:00.000+00:00"
  },
  {
      "id": 248242,
      "address": "Av. Boyacá  Calle 61 SUR",
      "latitude": 4.560246467590332,
      "longitude": -74.13935089111328,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:41:04.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 12:49:58> Incidente: 2023-09-25 12:39 Av. Boyacá  Calle 61 SUR Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 3: Automóvil; Automóvil; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 6-16\tPT\tCADENA GUERRERO YENNY PAOLA \t38178\tLBN-73F (MOV/A0616) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Ciudad Bolivar*.\n              ",
      "incident_time": "2023-09-25T12:39:00.000+00:00"
  },
  {
      "id": 248243,
      "address": "Carrera 25A Calle 4B",
      "latitude": 4.600716590881348,
      "longitude": -74.0955810546875,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:41:49.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:08:50> Incidente: 2023-09-25 12:41 Carrera 25A Calle 4B Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito PT ROJAS BARRETO HECTOR PLACA POLICIAL 163211 (MOV/APOYO14) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Los Martires*.\n              ",
      "incident_time": "2023-09-25T12:41:00.000+00:00"
  },
  {
      "id": 248244,
      "address": "KR 87C Y TV 87C X CL 59C S",
      "latitude": 4.6229352951049805,
      "longitude": -74.18917846679688,
      "location_name": "Bosa",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:44:54.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:35:44> Incidente: 2023-09-25 12:29  Intersección  Semaforica kr 87c y tv 87c x cl 59c s Habilitada A las 13:33",
      "incident_time": "2023-09-25T12:29:00.000+00:00"
  },
  {
      "id": 248245,
      "address": "KR 72J X CL 45 S",
      "latitude": 4.603426933288574,
      "longitude": -74.15088653564453,
      "location_name": "Kennedy",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento correctivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:45:43.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <SERGIO PEREZ cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:36:05> Incidente: 2023-09-25 12:42  Intersección  Semaforica kr 72j x cl 45 s Habilitada A las 13:23",
      "incident_time": "2023-09-25T12:42:00.000+00:00"
  },
  {
      "id": 248246,
      "address": "NQS  Av. Américas ",
      "latitude": 4.62464714050293,
      "longitude": -74.08370971679688,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T12:56:59.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "NQS NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:32:37> Incidente: 2023-09-25 12:50 NQS  Av. Américas  Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Bus. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T12:50:00.000+00:00"
  },
  {
      "id": 248247,
      "address": "Carrera 81G Calle 47A SUR",
      "latitude": 4.623392105102539,
      "longitude": -74.1716079711914,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:00:23.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:10:17> Incidente: 2023-09-25 12:57 Carrera 81G Calle 47A SUR Norte - sur : Se presenta Siniestro Choque contra objeto fijo Pared Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-24\tPT \tCAMACHO OBANDO JULIETH ANDREA\t39092 (MOV/A0824) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.",
      "incident_time": "2023-09-25T12:57:00.000+00:00"
  },
  {
      "id": 248248,
      "address": "Av. Américas  Carrera 43A",
      "latitude": 4.626841068267822,
      "longitude": -74.09949493408203,
      "location_name": "Puente Aranda",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:03:01.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Américas EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:10:46> Incidente: 2023-09-25 13:00 Av. Américas  Carrera 43A Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Taxi. Se asigna unidad de tránsito. \nUnidad de tránsito  ALMA 15-9        PT        CAMACHO LOZADA WILLIAM ANTONIO        172475        AWT03D        1090 (MOV/A1509) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Puente Aranda*.\n              ",
      "incident_time": "2023-09-25T13:00:00.000+00:00"
  },
  {
      "id": 248249,
      "address": "Av. Cali  Calle 139",
      "latitude": 4.741598606109619,
      "longitude": -74.09828186035156,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:04:02.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:11:05> Incidente: 2023-09-25 13:03 Av. Cali  Calle 139 Sur - Norte : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 1-11 atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\"\n              ",
      "incident_time": "2023-09-25T13:03:00.000+00:00"
  },
  {
      "id": 248250,
      "address": "Autonorte  Calle 176",
      "latitude": 4.7566118240356445,
      "longitude": -74.04605102539062,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:06:06.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Autopista norte NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:11:34> Incidente: 2023-09-25 13:04 Autonorte  Calle 176 Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 1-4\tPT\tOSPINA OLIVERA BRAYAN RODRIGO\t184211\tLBN-38F\t748 (MOV/A0104) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Suba*.\n              ",
      "incident_time": "2023-09-25T13:04:00.000+00:00"
  },
  {
      "id": 248251,
      "address": "Carrera 7  Calle 151",
      "latitude": 4.729825019836426,
      "longitude": -74.03570556640625,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:06:59.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:11:52> Incidente: 2023-09-25 13:06 Carrera 7  Calle 151 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 1-5\tPT \tGONZALEZ PIRE DIEGO LEONARDO \t187082\tLBN94F\t583 (MOV/A0105) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Usaquen*.\n              ",
      "incident_time": "2023-09-25T13:06:00.000+00:00"
  },
  {
      "id": 248252,
      "address": "Av. Caracas  Calle 53",
      "latitude": 4.640430450439453,
      "longitude": -74.06640625,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Cámaras",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:12:54.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Caracas NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:32:57> Incidente: 2023-09-25 13:12 Av. Caracas  Calle 53 Norte - sur : Se presenta Siniestro Choque Simple. Implicados: 2: Camioneta; Motocicleta. Se asigna unidad de tránsito. \nNo se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.\nFuente: Cámaras. *Localidad: Teusaquillo*.\n              ",
      "incident_time": "2023-09-25T13:12:00.000+00:00"
  },
  {
      "id": 248253,
      "address": "Carrera 105C Calle 65B",
      "latitude": 4.698910713195801,
      "longitude": -74.12516021728516,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:15:54.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:33:18> Incidente: 2023-09-25 13:12 Carrera 105C Calle 65B Norte - sur : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito MOVIL 9-10\tPT\tMARTIN ROA MARYI CATERYNE atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T13:12:00.000+00:00"
  },
  {
      "id": 248254,
      "address": "Carrera 97A Calle 26 SUR",
      "latitude": 4.650874137878418,
      "longitude": -74.17046356201172,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:25:05.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:33:43> Incidente: 2023-09-25 13:22 Carrera 97A Calle 26 SUR Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 8-24\tPT \tCAMACHO OBANDO JULIETH atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Kennedy*.\n              ",
      "incident_time": "2023-09-25T13:22:00.000+00:00"
  },
  {
      "id": 248255,
      "address": "Carrera 38A Calle 25",
      "latitude": 4.631297588348389,
      "longitude": -74.08909606933594,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque contra objeto fijo",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:26:28.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:34:03> Incidente: 2023-09-25 13:25 Carrera 38A Calle 25 Sur - Norte : Se presenta Siniestro Choque contra objeto fijo Poste Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 12-10\tPT\tCORRALES VARGAS ELIANA \t94156\tOGD11E (MOV/A1210) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Teusaquillo*.",
      "incident_time": "2023-09-25T13:25:00.000+00:00"
  },
  {
      "id": 248256,
      "address": "Calle 75 Carrera 70F",
      "latitude": 4.689172744750977,
      "longitude": -74.09028625488281,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:31:56.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:51:07> Incidente: 2023-09-25 13:31 Calle 75 Carrera 70F Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 12-10\tPT\tCORRALES VARGAS ELIANA \t94156\tOGD11E (MOV/A1210) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T13:31:00.000+00:00"
  },
  {
      "id": 248257,
      "address": "KR 1A ESTE X AC 11 SUR",
      "latitude": 4.5749287605285645,
      "longitude": -74.08201599121094,
      "location_name": "San Cristóbal",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento correctivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:34:42.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 14:37:31> Incidente: 2023-09-25 13:19 MANTENIMIENTO CORRECTIVO",
      "incident_time": "2023-09-25T13:19:00.000+00:00"
  },
  {
      "id": 248258,
      "address": "AK 72 X AC 138",
      "latitude": 4.729710102081299,
      "longitude": -74.06865692138672,
      "location_name": "Suba",
      "class_name": "Semaforización",
      "type_name": "Reprogramación de equipo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:35:21.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Boyacá NS",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 14:34:26> Incidente: 2023-09-25 13:27 REPROGRAMACION EQUIPO DE CONTROL",
      "incident_time": "2023-09-25T13:27:00.000+00:00"
  },
  {
      "id": 248259,
      "address": "Av. Gaitán Cortes  Av. Boyacá ",
      "latitude": 4.591428756713867,
      "longitude": -74.14543151855469,
      "location_name": "Tunjuelito",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Cámaras",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:36:36.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá NS",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:56:42> Incidente: 2023-09-25 13:34 No se realiza asignación de unidad de tránsito, se verifica por otros medios que no se presenta afectación.              ",
      "incident_time": "2023-09-25T13:34:00.000+00:00"
  },
  {
      "id": 248260,
      "address": "Calle 128 Av. Cali ",
      "latitude": 4.728714466094971,
      "longitude": -74.10120391845703,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:38:10.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:51:35> Incidente: 2023-09-25 13:36 Unidad de tránsito ALMA 11-17\tPT \tPEÑA REYES EMMA FLOREYDY\t181753\tDDZ-28E (MOV/A1117) atiende la novedad y retira los vehículos.              ",
      "incident_time": "2023-09-25T13:36:00.000+00:00"
  },
  {
      "id": 248261,
      "address": "Calle 80  Carrera 77",
      "latitude": 4.697939872741699,
      "longitude": -74.09446716308594,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:38:47.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Calle 80 WE",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:51:49> Incidente: 2023-09-25 13:38 Calle 80  Carrera 77 Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Camioneta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 10-06\tSI \tSALAZAR DUCUARA MANUEL FERNANDO\t92337\tOGC91E\t714\t3212546681\tA\t1** (MOV/A1006) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Engativa*.\n              ",
      "incident_time": "2023-09-25T13:38:00.000+00:00"
  },
  {
      "id": 248262,
      "address": "Carrera 87H Calle 26 SUR",
      "latitude": 4.640286922454834,
      "longitude": -74.16204071044922,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:40:57.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:57:05> Incidente: 2023-09-25 13:38 Unidad de tránsito ALMA 8-9 SI HERNADEZ LAVERDE FABIONEL atiende la novedad y retira los vehículos.              ",
      "incident_time": "2023-09-25T13:38:00.000+00:00"
  },
  {
      "id": 248263,
      "address": "Av. Primero de Mayo  Carrera 24",
      "latitude": 4.584458827972412,
      "longitude": -74.10509490966797,
      "location_name": "Antonio Nariño",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:41:36.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <Alejandra Molina cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 13:52:55> Incidente: 2023-09-25 13:40 Av. Primero de Mayo  Carrera 24 Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. \nUnidad de tránsito ALMA 15-11\tPT\tSALAS SANCHEZ LUISA FERNANDA\t94354\tLBO-71F (MOV/A1511) atiende la novedad y retira los vehículos.\nFuente: NUSE. *Localidad: Antonio Nariño*.\n              ",
      "incident_time": "2023-09-25T13:40:00.000+00:00"
  },
  {
      "id": 248264,
      "address": "Carrera 30 Calle 17A SUR",
      "latitude": 4.596202850341797,
      "longitude": -74.11021423339844,
      "location_name": "Antonio Nariño",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T13:50:47.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": " <CRISTIAN URIBE cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 15:19:10> Incidente: 2023-09-25 13:49 Unidad de tránsito  atiende la novedad y retira los vehículos.",
      "incident_time": "2023-09-25T13:49:00.000+00:00"
  },
  {
      "id": 248265,
      "address": "Carrera 18 Calle 11A",
      "latitude": 4.60496711730957,
      "longitude": -74.08462524414062,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T14:22:53.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": " <CRISTIAN URIBE cambió el estado del incidente a Cerrado el  Fecha: 2023-09-25 y hora: 15:19:57> Incidente: 2023-09-25 14:16 Unidad de tránsito ALMA 14-2 atiende la novedad y retira los vehículos.",
      "incident_time": "2023-09-25T14:16:00.000+00:00"
  },
  {
      "id": 248266,
      "address": "Carrera 87J Calle 55 Sur",
      "latitude": 4.627016544342041,
      "longitude": -74.1854476928711,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:24:49.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:24:39> Incidente: 2023-09-25 14:18 Carrera 87J Calle 55 Sur Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Peatón. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Bosa.\n              ",
      "incident_time": "2023-09-25T14:18:00.000+00:00"
  },
  {
      "id": 248267,
      "address": "KR 107 X AC 80",
      "latitude": 4.715654373168945,
      "longitude": -74.11676025390625,
      "location_name": "Engativa",
      "class_name": "Semaforización",
      "type_name": "Reprogramación de equipo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T14:27:02.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av Calle 80 EW",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 15:51:21> Incidente: 2023-09-25 14:11 Cra. 107 #79b-35 Sin orientación : Se presenta Semaforización Reprogramación de equipo. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Engativa*.",
      "incident_time": "2023-09-25T14:11:00.000+00:00"
  },
  {
      "id": 248268,
      "address": "Carrera 129 Calle 22C",
      "latitude": 4.695493698120117,
      "longitude": -74.15808868408203,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:27:57.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:27:45> Incidente: 2023-09-25 14:21 Carrera 129 Calle 22C Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Fontibón.\n              ",
      "incident_time": "2023-09-25T14:21:00.000+00:00"
  },
  {
      "id": 248269,
      "address": "Carrera 100 Calle 22J",
      "latitude": 4.678242206573486,
      "longitude": -74.13915252685547,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:29:44.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:29:32> Incidente: 2023-09-25 14:26 Carrera 100 Calle 22J Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Peatón. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Fontibón.\n              ",
      "incident_time": "2023-09-25T14:26:00.000+00:00"
  },
  {
      "id": 248270,
      "address": "AK 7 X AC 82",
      "latitude": 4.663210391998291,
      "longitude": -74.04864501953125,
      "location_name": "Chapinero",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T14:29:50.000+00:00",
      "gravity_name": null,
      "corredor_name": "Carrera 7 NS",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 15:39:29> Incidente: 2023-09-25 14:27 MANTENIMIENTO PREVENTIVO",
      "incident_time": "2023-09-25T14:27:00.000+00:00"
  },
  {
      "id": 248271,
      "address": "Calle 23 Carrera 33",
      "latitude": 4.625593662261963,
      "longitude": -74.08680725097656,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:32:19.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:31:59> Incidente: 2023-09-25 14:14 Calle 23 Carrera 33 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Teusaquillo.\n              ",
      "incident_time": "2023-09-25T14:14:00.000+00:00"
  },
  {
      "id": 248272,
      "address": "AK 7 X CL 81",
      "latitude": 4.662454128265381,
      "longitude": -74.04943084716797,
      "location_name": "Chapinero",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T14:32:48.000+00:00",
      "gravity_name": null,
      "corredor_name": "Carrera 7 NS",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 15:39:56> Incidente: 2023-09-25 14:27 MANTENIMIENTO PREVENTIVO",
      "incident_time": "2023-09-25T14:27:00.000+00:00"
  },
  {
      "id": 248273,
      "address": "Calle 75 Carrera 111A",
      "latitude": 4.714300632476807,
      "longitude": -74.12444305419922,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:33:53.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:33:37> Incidente: 2023-09-25 14:15 Calle 75 Carrera 111A Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Engativa.\n              ",
      "incident_time": "2023-09-25T14:15:00.000+00:00"
  },
  {
      "id": 248274,
      "address": "Calle 67C Carrera 105F",
      "latitude": 4.700613021850586,
      "longitude": -74.12474060058594,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:36:50.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:36:28> Incidente: 2023-09-25 14:20 Calle 67C Carrera 105F Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Engativa.\n              ",
      "incident_time": "2023-09-25T14:20:00.000+00:00"
  },
  {
      "id": 248275,
      "address": "Cl. 33 #17-80",
      "latitude": 4.621851921081543,
      "longitude": -74.0732421875,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:38:24.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:38:10> Incidente: 2023-09-25 14:27 Cl. 33 #17-80 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Camioneta; Ambulancia. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Teusaquillo.\n              ",
      "incident_time": "2023-09-25T14:27:00.000+00:00"
  },
  {
      "id": 248276,
      "address": "Av. Suba  Calle 132A",
      "latitude": 4.730823516845703,
      "longitude": -74.08702850341797,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "Waze",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:41:06.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:40:48> Incidente: 2023-09-25 14:23 Av. Suba  Calle 132A Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: Waze. *Localidad: Suba.\n              ",
      "incident_time": "2023-09-25T14:23:00.000+00:00"
  },
  {
      "id": 248277,
      "address": "Av Centenario Carrera 119",
      "latitude": 4.6850175857543945,
      "longitude": -74.15725708007812,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "Gogev",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:41:35.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:41:18> Incidente: 2023-09-25 14:30 Av. Centenario Carrera 119 Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Volqueta; Camión. Se asigna unidad de tránsito. Fuente: Gogev. Localidad: Fontibón.\n              ",
      "incident_time": "2023-09-25T14:30:00.000+00:00"
  },
  {
      "id": 248278,
      "address": "Calle 139 Carrera 98A",
      "latitude": 4.739102840423584,
      "longitude": -74.09121704101562,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:42:40.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:42:25> Incidente: 2023-09-25 14:23 Calle 139 Carrera 98A Oriente - Occidente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Bicicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Suba.\n              ",
      "incident_time": "2023-09-25T14:23:00.000+00:00"
  },
  {
      "id": 248279,
      "address": "Calle 70C Sur Carrera 17C",
      "latitude": 4.546813488006592,
      "longitude": -74.1386489868164,
      "location_name": "Ciudad Bolivar",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:43:05.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:42:54> Incidente: 2023-09-25 14:37 Calle 70C Sur Carrera 17C Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Taxi. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Ciudad Bolívar.\n              ",
      "incident_time": "2023-09-25T14:37:00.000+00:00"
  },
  {
      "id": 248280,
      "address": "NQS  Calle 65",
      "latitude": 4.66090202331543,
      "longitude": -74.07746124267578,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:44:32.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "NQS NS",
      "description": "  Fecha: 2023-09-25 y hora: 14:44:14> Incidente: 2023-09-25 14:24 NQS  Calle 65 Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Barrios Unidos.\n              ",
      "incident_time": "2023-09-25T14:24:00.000+00:00"
  },
  {
      "id": 248281,
      "address": "Calle 1 Carrera 19",
      "latitude": 4.593055248260498,
      "longitude": -74.09234619140625,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:46:52.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:46:41> Incidente: 2023-09-25 14:43 Calle 1 Carrera 19 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Motocicleta; Peatón. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Los Mártires.\n              ",
      "incident_time": "2023-09-25T14:43:00.000+00:00"
  },
  {
      "id": 248282,
      "address": "Carrera 71 Calle 127 bis",
      "latitude": 4.710011959075928,
      "longitude": -74.07943725585938,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "Waze",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:49:35.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Av Boyacá SN",
      "description": "  Fecha: 2023-09-25 y hora: 14:49:18> Incidente: 2023-09-25 14:32 Carrera 71 Calle 127 bis Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. Fuente: Waze. *Localidad: Suba.\n              ",
      "incident_time": "2023-09-25T14:32:00.000+00:00"
  },
  {
      "id": 248283,
      "address": "Calle 48P Sur Carrera 5",
      "latitude": 4.552679061889648,
      "longitude": -74.1082534790039,
      "location_name": "Rafael Uribe Uribe",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:50:48.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:50:40> Incidente: 2023-09-25 14:47 Calle 48P Sur Carrera 5 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Rafael Uribe Uribe.\n              ",
      "incident_time": "2023-09-25T14:47:00.000+00:00"
  },
  {
      "id": 248284,
      "address": "Calle 72 Carrera 27",
      "latitude": 4.664051055908203,
      "longitude": -74.06842803955078,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T14:51:24.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 14:51:08> Incidente: 2023-09-25 14:38 Calle 72 Carrera 27 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Barrios Unidos.\n              ",
      "incident_time": "2023-09-25T14:38:00.000+00:00"
  },
  {
      "id": 248285,
      "address": "KR 36 X AC 26 S",
      "latitude": 4.59779167175293,
      "longitude": -74.11480712890625,
      "location_name": "Puente Aranda",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "1",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T14:54:34.000+00:00",
      "gravity_name": null,
      "corredor_name": "Av 1ra de Mayo EW",
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 15:02:00> Incidente: 2023-09-25 14:52 MANTENIMIENTO PREVENTIVO",
      "incident_time": "2023-09-25T14:52:00.000+00:00"
  },
  {
      "id": 248286,
      "address": "Calle 1 Carrera 69",
      "latitude": 4.621959686279297,
      "longitude": -74.12903594970703,
      "location_name": "Kennedy",
      "class_name": "Incidente en vía",
      "type_name": "Materiales peligrosos",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:02:46.000+00:00",
      "gravity_name": "Afectación media",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:02:35> Incidente: 2023-09-25 14:52 Calle 1 Carrera 69 Sin orientación : Se presenta Incidente en vía: Materiales peligrosos:  Con Afectación media. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Kennedy.",
      "incident_time": "2023-09-25T14:52:00.000+00:00"
  },
  {
      "id": 248287,
      "address": "AK 10 X AC 11 S",
      "latitude": 4.581643104553223,
      "longitude": -74.09043884277344,
      "location_name": "Antonio Nariño",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento preventivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Cerrado",
      "create_date": "2023-09-25T15:03:09.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": " <YECNI GUECHA Editó el incidente el  Fecha: 2023-09-25 y hora: 15:50:53> Incidente: 2023-09-25 14:56 REPROGRAMACION EQUIPO DE CONTROL",
      "incident_time": "2023-09-25T14:56:00.000+00:00"
  },
  {
      "id": 248288,
      "address": "Av. Villavicencio  Carrera 78K",
      "latitude": 4.616715431213379,
      "longitude": -74.16357421875,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:05:52.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:05:42> Incidente: 2023-09-25 14:55 Av. Villavicencio  Carrera 78K Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Kennedy.\n              ",
      "incident_time": "2023-09-25T14:55:00.000+00:00"
  },
  {
      "id": 248289,
      "address": "Av. Boyacá  Calle 13",
      "latitude": 4.651397228240967,
      "longitude": -74.12625122070312,
      "location_name": "Fontibon",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:08:55.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:08:43> Incidente: 2023-09-25 14:58 Av. Boyacá  Calle 13 Sur - Norte : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Camioneta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Fontibón.\n              ",
      "incident_time": "2023-09-25T14:58:00.000+00:00"
  },
  {
      "id": 248290,
      "address": "Calle 161 Carrera 12",
      "latitude": 4.7382659912109375,
      "longitude": -74.03251647949219,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:14:10.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:13:54> Incidente: 2023-09-25 15:10 Calle 161 Carrera 12 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Usaquen.\n              ",
      "incident_time": "2023-09-25T15:10:00.000+00:00"
  },
  {
      "id": 248291,
      "address": "Carrera 77A Calle 64H",
      "latitude": 4.682947158813477,
      "longitude": -74.10617065429688,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:15:31.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:15:18> Incidente: 2023-09-25 15:11 Carrera 77A Calle 64H Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Engativa.\n              ",
      "incident_time": "2023-09-25T15:11:00.000+00:00"
  },
  {
      "id": 248292,
      "address": "Cl. 57r Sur #71b-2",
      "latitude": 4.596078872680664,
      "longitude": -74.16264343261719,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:16:08.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": "Autopista sur NS",
      "description": "  Fecha: 2023-09-25 y hora: 15:15:57> Incidente: 2023-09-25 14:57 Cl. 57r Sur #71b-2 Oriente - Occidente : Se presenta Siniestro Choque Simple. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Bosa.\n              ",
      "incident_time": "2023-09-25T14:57:00.000+00:00"
  },
  {
      "id": 248293,
      "address": "Cra. 8c #0 Sur-34",
      "latitude": 4.5875372886657715,
      "longitude": -74.08382415771484,
      "location_name": "San Cristobal",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "TMSA",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:28:03.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:27:38> Incidente: 2023-09-25 15:15 Carrera 30 Diagonal 17 SUR Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Camión. Se asigna unidad de tránsito. Fuente: TMSA. Localidad: Puente Aranda.\n              ",
      "incident_time": "2023-09-25T15:15:00.000+00:00"
  },
  {
      "id": 248294,
      "address": "Calle 72 Carrera 74A",
      "latitude": 4.689240455627441,
      "longitude": -74.09747314453125,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:28:51.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:28:30> Incidente: 2023-09-25 15:21 Calle 72 Carrera 74A Occidente - Oriente : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Engativa.\n              ",
      "incident_time": "2023-09-25T15:21:00.000+00:00"
  },
  {
      "id": 248295,
      "address": "Calle 45 Carrera 21A",
      "latitude": 4.633688926696777,
      "longitude": -74.07372283935547,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:31:08.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:30:55> Incidente: 2023-09-25 15:21 Calle 45 Carrera 21A Sin orientación : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Teusaquillo.\n              ",
      "incident_time": "2023-09-25T15:21:00.000+00:00"
  },
  {
      "id": 248296,
      "address": "Calle 166 Carrera 15",
      "latitude": 4.744981288909912,
      "longitude": -74.03694152832031,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:33:07.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:32:46> Incidente: 2023-09-25 15:22 Calle 166 Carrera 15 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bicicleta; Bicicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Usaquen.\n              ",
      "incident_time": "2023-09-25T15:22:00.000+00:00"
  },
  {
      "id": 248297,
      "address": "KR 17 X AC 116",
      "latitude": 4.697661876678467,
      "longitude": -74.04624938964844,
      "location_name": "Usaquen",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento correctivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:35:32.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:35:31> Incidente: 2023-09-25 15:34 KR 17 X AC 116 Sin orientación : Se presenta Semaforización Mantenimiento correctivo. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Usaquen*.",
      "incident_time": "2023-09-25T15:34:00.000+00:00"
  },
  {
      "id": 248298,
      "address": "Carrera 30 Calle 8 Sur",
      "latitude": 4.570276260375977,
      "longitude": -74.0712661743164,
      "location_name": "Antonio Nariño",
      "class_name": "Transporte masivo",
      "type_name": "Caída de ocupante",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:35:42.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:34:57> Incidente: 2023-09-25 03:15 Cl. 8 Sur #2069 Sin orientación : Se presenta Transporte masivo Caída de ocupante Con lesionado. Implicados: 1: Bus. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: San Cristóbal.\n              ",
      "incident_time": "2023-09-25T03:15:00.000+00:00"
  },
  {
      "id": 248299,
      "address": "Calle 72 Av. Caracas ",
      "latitude": 4.659509658813477,
      "longitude": -74.06192016601562,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:35:56.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Caracas NS",
      "description": "  Fecha: 2023-09-25 y hora: 15:35:40> Incidente: 2023-09-25 15:24 Calle 72 Av. Caracas  Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Bus; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Barrios Unidos.\n              ",
      "incident_time": "2023-09-25T15:24:00.000+00:00"
  },
  {
      "id": 248300,
      "address": "KR 18 X AC 116",
      "latitude": 4.698058605194092,
      "longitude": -74.04753875732422,
      "location_name": "Usaquen",
      "class_name": "Semaforización",
      "type_name": "Mantenimiento correctivo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:36:51.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:36:50> Incidente: 2023-09-25 15:34 KR 18 X AC 116 Sin orientación : Se presenta Semaforización Mantenimiento correctivo. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Usaquen*.",
      "incident_time": "2023-09-25T15:34:00.000+00:00"
  },
  {
      "id": 248301,
      "address": "Carrera 91 Calle 139",
      "latitude": 4.736650466918945,
      "longitude": -74.08509063720703,
      "location_name": "Suba",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:39:09.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:38:44> Incidente: 2023-09-25 15:28 Carrera 91 Calle 139 Norte - sur : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Peatón. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Suba.\n              ",
      "incident_time": "2023-09-25T15:28:00.000+00:00"
  },
  {
      "id": 248302,
      "address": "Calle 161A Carrera 8C",
      "latitude": 4.737888813018799,
      "longitude": -74.02845764160156,
      "location_name": "Usaquen",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:40:41.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:40:22> Incidente: 2023-09-25 15:29 Calle 161A Carrera 8C Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Usaquen.\n              ",
      "incident_time": "2023-09-25T15:29:00.000+00:00"
  },
  {
      "id": 248303,
      "address": "Calle 75C Carrera 104",
      "latitude": 4.708729267120361,
      "longitude": -74.117431640625,
      "location_name": "Engativa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:42:24.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:42:06> Incidente: 2023-09-25 15:33 Calle 75C Carrera 104 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. *Localidad: Engativa.\n              ",
      "incident_time": "2023-09-25T15:33:00.000+00:00"
  },
  {
      "id": 248304,
      "address": "KR 86F X CL 33 S",
      "latitude": 4.635797023773193,
      "longitude": -74.16399383544922,
      "location_name": "Kennedy",
      "class_name": "Semaforización",
      "type_name": "Reprogramación de equipo",
      "priority_name": "2",
      "source_name": "Semaforización",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:44:09.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:43:41> Incidente: 2023-09-25 15:40 KR 86F X CL 33 S Sin orientación : Se presenta Semaforización Reprogramación de equipo. Se asigna unidad de tránsito. Fuente: Semaforización. *Localidad: Kennedy*.",
      "incident_time": "2023-09-25T15:40:00.000+00:00"
  },
  {
      "id": 248305,
      "address": "Carrera 29 Calle 24",
      "latitude": 4.623093128204346,
      "longitude": -74.0814437866211,
      "location_name": "Los Martires",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:45:36.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:45:24> Incidente: 2023-09-25 15:17 Carrera 29 Calle 24 Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Camión; Automóvil. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Los Mártires.\n              ",
      "incident_time": "2023-09-25T15:17:00.000+00:00"
  },
  {
      "id": 248306,
      "address": "Calle 26 (Av. Dorado)  Carrera 85D",
      "latitude": 4.676678657531738,
      "longitude": -74.11775207519531,
      "location_name": "Engativa",
      "class_name": "Varado",
      "type_name": null,
      "priority_name": "2",
      "source_name": "Waze",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:47:33.000+00:00",
      "gravity_name": null,
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:47:19> Incidente: 2023-09-25 15:44 Calle 26 (Av. Dorado)  Carrera 85D Occidente - Oriente : Se presenta Varado . Implicados: 1: Tracto camión. Se asigna unidad de tránsito. Fuente: Waze. *Localidad: Engativa.",
      "incident_time": "2023-09-25T15:44:00.000+00:00"
  },
  {
      "id": 248307,
      "address": "Carrera 80G Calle 2",
      "latitude": 4.634439945220947,
      "longitude": -74.15473175048828,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:48:24.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:48:16> Incidente: 2023-09-25 15:18 Carrera 80G Calle 2 Sur - Norte : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Bicicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Kennedy.\n              ",
      "incident_time": "2023-09-25T15:18:00.000+00:00"
  },
  {
      "id": 248308,
      "address": "Av. Caracas  Calle 78 Sur",
      "latitude": 4.510509014129639,
      "longitude": -74.11460876464844,
      "location_name": "Usme",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:50:41.000+00:00",
      "gravity_name": "Simple",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:50:29> Incidente: 2023-09-25 15:30 Av. Caracas  Calle 78 Sur Sin orientación : Se presenta Siniestro Choque Simple. Implicados: 2: Vehículo de servicio especial; Taxi. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Usme.\n              ",
      "incident_time": "2023-09-25T15:30:00.000+00:00"
  },
  {
      "id": 248309,
      "address": "Transversal 77J Calle 71B Bis Sur",
      "latitude": 4.599630832672119,
      "longitude": -74.19043731689453,
      "location_name": "Bosa",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:52:58.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:52:46> Incidente: 2023-09-25 15:32 Transversal 77J Calle 71B Bis Sur Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Motocicleta; Peatón. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Bosa.\n              ",
      "incident_time": "2023-09-25T15:32:00.000+00:00"
  },
  {
      "id": 248310,
      "address": "Carrera 68D Calle 38C Bis Sur",
      "latitude": 4.602879524230957,
      "longitude": -74.13678741455078,
      "location_name": "Kennedy",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:55:29.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:55:20> Incidente: 2023-09-25 15:52 Carrera 68D Calle 38C Bis Sur Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Kennedy.\n              ",
      "incident_time": "2023-09-25T15:52:00.000+00:00"
  },
  {
      "id": 248311,
      "address": "Calle 30 Sur Carrera 22",
      "latitude": 4.581607341766357,
      "longitude": -74.11170959472656,
      "location_name": "Rafael Uribe Uribe",
      "class_name": "Siniestro",
      "type_name": "Autolesiones",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T15:56:52.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 15:56:43> Incidente: 2023-09-25 15:53 Calle 30 Sur Carrera 22 Sin orientación : Se presenta Siniestro Autolesiones Con lesionado. Implicados: 1: Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Rafael Uribe Uribe.\n              ",
      "incident_time": "2023-09-25T15:53:00.000+00:00"
  },
  {
      "id": 248312,
      "address": "Calle 26 (Av. Dorado)  Carrera 51",
      "latitude": 4.641533851623535,
      "longitude": -74.09518432617188,
      "location_name": "Teusaquillo",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "1",
      "source_name": "NUSE",
      "state_name": "Por verificar",
      "create_date": "2023-09-25T15:59:39.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": "Av Calle 26 EW",
      "description": "  Fecha: 2023-09-25 y hora: 15:59:23> Incidente: 2023-09-25 15:56 Calle 26 (Av. Dorado)  Carrera 51 Occidente - Oriente : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Taxi; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Teusaquillo.\n              ",
      "incident_time": "2023-09-25T15:54:00.000+00:00"
  },
  {
      "id": 248313,
      "address": "Calle 63 Carrera 24",
      "latitude": 4.651046276092529,
      "longitude": -74.07251739501953,
      "location_name": "Barrios Unidos",
      "class_name": "Siniestro",
      "type_name": "Choque",
      "priority_name": "2",
      "source_name": "NUSE",
      "state_name": "Verificado",
      "create_date": "2023-09-25T16:00:48.000+00:00",
      "gravity_name": "Con lesionado",
      "corredor_name": null,
      "description": "  Fecha: 2023-09-25 y hora: 16:00:40> Incidente: 2023-09-25 15:55 Calle 63 Carrera 24 Sin orientación : Se presenta Siniestro Choque Con lesionado. Implicados: 2: Automóvil; Motocicleta. Se asigna unidad de tránsito. Fuente: NUSE. Localidad: Barrios Unidos.\n              ",
      "incident_time": "2023-09-25T15:55:00.000+00:00"
  }
]