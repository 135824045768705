<div class="flex-container text-white text-weight-600">
  <div class="card">
    <sit-icon
      class="icon"
      [icon]="Icon.bus_stop_blue"
      alt="icono indicador paradero"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-color-primary-1">Paradero: <strong class="text-white">{{ properties?.paradaCodigo }}</strong></span>
  </div>

  <div class="card mid">

    <sit-icon
      class="icon"
      [icon]="Icon.general_road_blue"
      alt="icono carretera verde"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 ">{{ properties?.paradaDireccion }}</span>
    <span class="text-size-16 text-color-primary-1 ">Direccion</span>
  </div>

  <div class="card">

    <sit-icon
      class="icon"
      [icon]="Icon.peaton_yellow"
      alt="icono persona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16  "><strong>{{ properties?.paradaTipo }}</strong></span>
    <span class="text-size-16 text-color-primary-1 ">Modo De Transporte</span>
  </div>
</div>