
<div class="menu-button d-md-block d-none">
  <sit-icon-button
    [alt]="'layer'"
    [icon]="Icon.layers_w"
    (click)="this.isHidden = !this.isHidden"
    sizeClass="icon-size-40"
  ></sit-icon-button>
</div>

<footer class="menu d-flex d-md-flex full-md-width cont-items flex-md-column" *ngIf="isHidden">

  <div>
    <div class="handle-container">
      <div class="handle d-none d-md-block"></div>
    </div>

    <section class="title d-none d-md-flex pt-md-15" SheetBottom classContainer="menu">
      <sit-icon [icon]="Icon.layers_w" class="text-blue-1 ml-16 align-self-center" [sizeClass]="'icon-size-32'"
          [alt]="'capas'"></sit-icon>
        <h2
          class="flex-grow text-weight-600 text-size-24 ml-16 text-md-size-18 text-color-white align-self-center">
          {{ 'Capas Estáticas' | uppercase }}
        </h2>
        <sit-icon-button [alt]="'Cerrar'"
          [icon]="Icon.close_w" [colorClass]="'text-white'" class="text-blue" [sizeClass]="'icon-size-16'" (click)="this.isHidden = !this.isHidden">
        </sit-icon-button>
    </section>
  </div>

  <section class="d-md-block d-flex overflow-auto">
    <ng-container *ngFor="let props of footerMenus; let isLast = last">
      <horizontal-menu [properties]="props"></horizontal-menu>
      <div *ngIf="!isLast" class="vl d-none"></div>
    </ng-container>
  </section>
</footer>
