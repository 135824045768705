<ng-container *ngIf="properties as menu">
  <!-- Element with submenu -->
  <ng-container *ngIf="menu.subMenu as innerMenus; else inLineItem">
    <ng-container *ngIf="properties?.textInline">
      <span
        class="d-md-none text-weight-700 montserrat-font text-md-size-14 text-size-max-12"
        >{{ properties.textInline }}</span
      >
    </ng-container>
    <sit-icon-button
      alt="{{ properties?.alt }}"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="submenu"
      [icon]="properties?.icon"
      [sizeClass]="'icon-size-24'"
      class="{{ properties?.classMenu }} d-md-none"
    ></sit-icon-button>

    <mat-menu #submenu
    class="{{ menu.action === 'settings' ? 'menu-settings' : null }}"
    >

      <ng-container *ngIf="showMenu">
        <ng-container *ngFor="let innerMenu of innerMenus">
          
          <ng-container
            *ngIf="
              innerMenu.subMenu && innerMenu.subMenu.length;
              else noSubMenu
            "
          >
            <div
              mat-menu-item
              class="expand"
              (click)="$event.stopPropagation()"
            >
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title class="menu-item-bordered d-flex">
                    <ng-container *ngIf="innerMenu?.icon as icon">
                      <sit-icon
                        class="icon-item"
                        [icon]="icon"
                        [alt]="innerMenu.alt"
                      ></sit-icon>
                    </ng-container>
                    {{ innerMenu.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <ng-container *ngFor="let subItem of innerMenu.subMenu">
                    <button
                      mat-menu-item
                      class="menu-item-bordered p-0"
                      (click)="
                        callDispatcher(
                          subItem.action,
                          subItem.data,
                          subItem.dispatcher,
                          subItem.url,
                          subItem.actionType,
                          subItem.returnPanel,
                          subItem.actionPanel
                        );
                        menuTrigger.closeMenu()
                      "
                    >
                      {{ subItem.text }}
                    </button>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </div>
          </ng-container>

          <ng-template #noSubMenu>
            <button
              mat-menu-item *ngIf="menu.action !== 'settings'" 
              (click)="
                callDispatcher(
                  innerMenu.action,
                  innerMenu.data,
                  innerMenu.dispatcher,
                  innerMenu.url,
                  innerMenu.actionType,
                  innerMenu.returnPanel,
                  innerMenu.actionPanel
                )
              "
              [ngClass]="{
                'd-none': !innerMenu.isVisible
              }"
            >
              <div class="menu-item-bordered d-flex">
                <ng-container *ngIf="innerMenu?.icon as icon">
                  <sit-icon
                    class="icon-item"
                    [icon]="icon"
                    [alt]="innerMenu.alt"
                  ></sit-icon>
                </ng-container>
                <span>{{ innerMenu.text }}</span>
              </div>
            </button>
            
            <button   mat-menu-item *ngIf="menu.action === 'settings'" 
            (click)="
              callDispatcher(
                innerMenu.action,
                innerMenu.data,
                innerMenu.dispatcher,
                innerMenu.url,
                innerMenu.actionType,
                innerMenu.returnPanel,
                innerMenu.actionPanel
              )
            "
            [ngClass]="{
              'd-none': !innerMenu.isVisible
            }"
            class="btn-setting"
          >
            <div class="setting-icon">
                <ng-container >
                  <div>
                    <img src="../../../../assets/icons/{{innerMenu.iconsubmenu}}" class="icon-size-28 icon-sub" alt="">
                  </div>
                </ng-container>
              <span> {{ innerMenu.text }} </span>
            </div>
          </button>

          </ng-template>
        </ng-container>
      </ng-container>
    </mat-menu>
  </ng-container>
  <!-- Inline menu element -->
  <ng-template #inLineItem>
    <ng-container *ngIf="properties?.textInline">
      <span class="d-md-none text-weight-700 montserrat-font">{{
        properties.textInline
      }}</span>
    </ng-container>
    <sit-icon-button
      [hidden]="!properties?.isVisible"
      [alt]="properties?.alt"
      class="d-md-none {{ properties?.classMenu }}"
      (click)="
        callDispatcher(menu.action, menu.data, menu.dispatcher, menu.url, menu.actionType)
      "
      [icon]="properties?.icon"
    ></sit-icon-button>
  </ng-template>
</ng-container>
