import {IGeometryLineModel, IGeometryModel, MarkerModelBase} from "@types-custom/models/business/marker.model";
import {Icon} from "@types-custom/models/ui/icon-model";

export interface IPropsLocationsModel {
    id: string,
    address: string,
    latitude: number,
    longitude: number,
    location_name: string,
    class_name: string,
    type_name: string,
    priority_name: string,
    source_name: string,
    state_name: string,
    create_date: string,
    gravity_name: string,
    corredor_name: string,
    description: string,
    incident_time: string,
    category_name?: string,
    count_incidents?: string,
    markerPopupText? : string,
    icon? : Icon
}

export enum incidentsTypeEnum{
  default = <any>'default',
  siniestro0 = <any>'Siniestro0',
  siniestro1 = <any>'Siniestro1',
  siniestro2 = <any>'Siniestro2',
  siniestro3 = <any>'Siniestro3',
  varado0 = <any>'Varado0',
  varado1 = <any>'Varado1',
  varado2 = <any>'Varado2',
  varado3 = <any>'Varado3',
  manifestacion0 = <any>'Manifestación0',
  manifestacion1 = <any>'Manifestación1',
  manifestacion2 = <any>'Manifestación2',
  manifestacion3 = <any>'Manifestación3',
  accidentes0 = <any>'Incidente en vía0',
  accidentes1 = <any>'Incidente en vía1',
  accidentes2 = <any>'Incidente en vía2',
  accidentes3 = <any>'Incidente en vía2',
  masivo0 = <any>'Masivo0',
  masivo1 = <any>'Masivo1',
  masivo2 = <any>'Masivo2',
  masivo3 = <any>'Masivo3',
  semaforizacion0 = <any>'Semaforización0',
  semaforizacion1 = <any>'Semaforización1',
  semaforizacion2 = <any>'Semaforización2',
  semaforizacion3 = <any>'Semaforización3',
  accidente = <any>'Accidente',
  obstruccionVehicular = <any>'Obstrucción vehicular',
  obstruccionAmbiental = <any>'Obstrucción ambiental',
  infraestructuraDanada = <any>'Obstrucción de infraestructura dañada',
  obstruccionAnimal = <any>'Obstrucción por presencia animal', 
  mantenimiento = <any>'Situación Obras Mantenimiento',
  obstruccionGeneral = <any>'Obstrucción general',
  traficoAnormal = <any>'Tráfico anormal',
  carreteraNoClima = <any>'Condiciones de la carretera no relacionadas con el clima',
  condicionAmbiental = <any>'Condiciones Ambientales',
  situacionGestionVelocidad = <any>'Situación Gestión de la Velocidad',
  condicionesSituacion = <any>'Condiciones de la situación',
  situacionCarretera = <any>'Situación en Carretera',
  situacionMensajeCiudadano = <any>'Situación mensaje a ciudadano',
  situacionGestionRed = <any>'Situación Gestión de red',
  situacionObrasConstruccion = <any>'Situación obras en construcción',
}

export class IncidentsLocationsModel extends MarkerModelBase {
    static className = 'IncidentsLocationsModel';
    classProperties: IPropsLocationsModel;
    properties : IPropsLocationsModel

    constructor(
      props: IPropsLocationsModel,
      geometry: IGeometryLineModel | IGeometryModel,
      lat?: number,
      lng?: number
  ) {
      super(geometry, IncidentsLocationsModel.className, lat, lng);
      this.properties = props
      this.classProperties = props;
      this.geometry = geometry;
  }
}