import { CardMapLinkModel } from '../business/marker.model';
export const accidentType: { [id: string]: string } = {
  0: 'NULO',
  1: 'CHOQUE',
  2: 'ATROPELLO',
  3: 'VOLCAMIENTO',
  4: 'CAIDA OCUPANTE',
  5: 'INCENDIO',
  6: 'VIAJE REMOLQUE',
  7: 'OTRO',
};

export const accidentSeverity: { [id: string]: string } = {
  1: 'DAÑOS',
  2: 'LESIONADO',
  3: 'VICTIMA',
};

export const accidentSector: { [id: string]: string } = {
  0: 'NULO',
  1: 'URBANO',
  2: 'RURAL',
};
export interface ICardAccidentModel {
  CROQUIS?: string;
  RADICADO?: string;
  FECHA?: string;
  DIRECCION?: string;
  SECTOR?: string;
  TIPO_ACCIDENTE?: string;
  GRAVEDAD?: string;
  GlobalID?: string;
  created_user?: string;
  last_edited_user?: string;
  last_edited_date?: string;
  OBJECTID?: string;
  cardMapLink?: CardMapLinkModel;
}
