<div class="full-height d-flex fd-col overflow-y-auto custom-scrollbar" tabindex="0">
 <br>
  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.last_hours_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16"
      alt="tipos de novedades (últimas 8 horas)"></sit-icon>
    <h3 class="text-color-white m-0 text-size-18 text-weight-500">
      Tipo de Novedades (últimas 8 Horas)
    </h3>
  </div>
  <div class="d-flex fd-row chart-line-container">
    <sit-chart-series [properties]="dataGraphicList" [colorScale]="colorScale" class="full-width">
    </sit-chart-series>
  </div>
  <div
    class="d-flex fd-row bg-color-background-1 align-items-center border-md-top-right-25 border-md-top-left-25 pt-md-15 mt-1 mb-12">
    <div class="left-title-block bg-color-primary-1"></div>
    <sit-icon [icon]="Icon.alert_w" [sizeClass]="'icon-size-24'" class="mr-1 ml-16" alt="registro novedades"></sit-icon>
    <h3 class="text-color-white m-0 text-size-18 text-weight-500">
       Registro Novedades
    </h3>
  </div>
  <div>
    <table class="table-situations">
      <thead class="text-white text-size-12">
        <th>ID</th>
        <th>Tipo novedad</th>
        <th>Fecha y Hora</th>
        <th>Estado</th>
      </thead>
      <tbody class="p-0 text-weight-400 work-sans-font text-size-16 text-white">
        <ng-container *ngFor="let item of cards">
          <tr (click)="openModal(item)">
            <td>{{ item.id }}</td>
            <td>{{ item.situationType }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>