<ng-container *ngIf="pageInfoSubject | async as info">
  <div class="page-selector mr-16">
    <mat-form-field class="textWhite" appearance="outline">
      <mat-select
        [(ngModel)]="pageSizeSelector"
        (ngModelChange)="pageSizeSelectorHandler($event)"
      >
        <mat-option *ngFor="let item of pageSizesList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex indicadores">
    <div class="d-flex labels-space">
      <span class="text-white">
        <ng-container
          *ngTemplateOutlet="
            labels;
            context: {
              currentCount: (info.page + 1) * info.pageSize,
              totalCount: info.totalCount,
              pageSize: dataSource.pageInfo.value.pageSize,
              currentPage: dataSource.pageInfo.value.page
            }
          "
        ></ng-container>
      </span>
    </div>
    <div class="d-flex">
      <sit-icon-button
        [icon]="Icon.first_page_arrow"
        (click)="setPage(0)"
        [colorClass]="'text-white'"
        [alt]="'Ir a la primera página'"
        class="hover-arrow"
      >
      </sit-icon-button>
      <sit-icon-button
        [icon]="Icon.backward_w"
        (click)="setPage(info.page - 1)"
        [colorClass]="'text-white'"
        [alt]="'Ir a la anterior página'"
        class="hover-arrow"
      >
      </sit-icon-button>

      <sit-icon-button
        [icon]="Icon.nextward_w"
        (click)="setPage(info.page + 1)"
        [colorClass]="'text-white'"
        [alt]="'Ir a la siguiente página'"
        class="hover-arrow"
      >
      </sit-icon-button>
      <sit-icon-button
        [icon]="Icon.last_page_arrow"
        (click)="setPage(getLatestPage())"
        [colorClass]="'text-white'"
        [alt]="'Ir a la última página'"
        class="hover-arrow"
      >
      </sit-icon-button>
    </div>
  </div>
</ng-container>
<ng-template
  #labels
  let-currentCount="currentCount"
  let-totalCount="totalCount"
  let-pageSize="pageSize"
  let-currentPage="currentPage"
>
  {{ currentPage === 0 ? 1 : pageSize * currentPage }} -
  {{ pageSize * (currentPage + 1) }} de {{ totalCount }} resultados
</ng-template>
