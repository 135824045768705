import { Component, OnInit } from '@angular/core';
import { IncidentsService } from '@shared/services/layer-service/incidents-service/incidents.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { GeoApiService } from '@shared/services/geo-api-service/geo-api.service';
import { GeoAPIType, GeoAPITypeEnum } from '@shared/models/geo-api-type.model';
import { Icon } from '@types-custom/models/ui/icon-model';

@Component({
  selector: 'app-map-filter-incidents',
  templateUrl: './map-filter-incidents.component.html',
  styleUrls: ['./map-filter-incidents.component.scss'],
})
export class MapFilterIncidentsComponent implements OnInit {
  incidentsList: any = [];
  incidentsTypes: [] = [];
  formBuilder = new FormBuilder();
  incidentForm: FormGroup;

  currentDate = new Date();
  minStartDate = new Date();
  createButtonProps: IButtonModel = {
    label: 'APLICAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  constructor(
    private incidentsService: IncidentsService,
    private geoApiService: GeoApiService
  ) {}

  ngOnInit() {
    this.minStartDate.setDate(this.currentDate.getDate() - 30);
    this.getIncidentsList();
  }
  get startDate() {
    return this.incidentForm.get('dateInit');
  }

  get endDate() {
    return this.incidentForm.get('dateEnd');
  }

  public clearFilter() {
    this.initForm(this.incidentsList);
  }

  getIncidentsList() {
    this.incidentsService.getfiltersIncidents().subscribe((response) => {
      this.incidentsTypes = response;
      this.incidentsList = response.map((item: any) => item.name);
      this.initForm(this.incidentsList);
    });
  }

  initForm(requiredFilters: string[]): void {
    const formControls: { [key: string]: any } = {
      dateInit: [
        this.currentDate.toLocaleDateString('fr-CA'),
        [Validators.required],
      ],
      timeInit: ['00:00', [Validators.required]],
      dateEnd: [
        this.currentDate.toLocaleDateString('fr-CA'),
        [Validators.required],
      ],
      timeEnd: ['23:59', [Validators.required]],
    };

    requiredFilters.forEach((filter: string) => {
      formControls[filter] = [''];
    });

    this.incidentForm = this.formBuilder.group(formControls, {
      validators: [this.dateValidator],
    });
  }

  private dateValidator: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const endDate = new Date(group.get('dateEnd').value);
    const startDate = new Date(group.get('dateInit').value);
    let errors: any;

    if (startDate > this.currentDate) {
      errors = { greaterThanCurrentDate: true };
      group.get('dateInit').setErrors(errors);
      return errors;
    }

    if (endDate > this.currentDate) {
      errors = { greaterThanCurrentDate: true };
      group.get('dateEnd').setErrors(errors);
      return errors;
    }

    if (endDate < startDate) {
      errors = { lessThanStartDate: true };
      group.get('dateEnd').setErrors(errors);
      return errors;
    }

    return null;
  };

  getInformation() {
    const bodyPayload: any = this.formatDateFilters();
    const incidentTypeIds = this.formatCheckboxFilters();

    if (incidentTypeIds.length > 0) {
      bodyPayload.incidentTypeIds = incidentTypeIds;
    }

    this.geoApiService
      .getSituationsList(GeoAPITypeEnum.Situation, true, bodyPayload)
      .subscribe({
        next: this.reloadData.bind(this),
      });
  }

  reloadData(response: any) {
    this.incidentsService.incidentFilterNotifier$.next(response);
  }

  formatCheckboxFilters() {
    const checkedFields = Object.keys(this.incidentForm.value).filter(
      (fieldName) => {
        return this.incidentForm.value[fieldName] === true;
      }
    );
    return checkedFields.map((fieldName) => {
      const typeFounded: any = this.incidentsTypes.find(
        (incitentType: any) => incitentType.name === fieldName
      );
      return typeFounded['id'];
    });
  }

  formatDateFilters() {
    const endDate = `${this.incidentForm.value.dateEnd}T${this.incidentForm.value.timeEnd}:00.402Z`;
    const beginDate = `${this.incidentForm.value.dateInit}T${this.incidentForm.value.timeInit}:00.402Z`;
    return { endDate, beginDate };
  }
}
