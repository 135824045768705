import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { alertsWazeGraph } from '@sdk/mock/waze-alert-data';
import { url } from 'inspector';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WazeAlertGraphicService {

  constructor( private http: HttpClient,
    @Inject('environment') private environment: any ) { }

//get data mock 
/*   getDataAlertMock(): Observable<any> {
    return this.http.get<any>('assets/mock/alertsWazeGraph.json').pipe(
      map(response => 
        this.formatData(response)))
  } */
//get data Url
getDataAlertUrl(): Observable<any> {
  return this.http.get<any>(`${this.environment.aletsGrapph}`).pipe(
    map(response => 
      this.formatData(response))
  );
}

formatData(data: any[]) {
  const dataFormatted: any = {};
  const colors=["#9271C5","#FF5722","#01BCD5"]

  const mapper: {[key: string]: string} = {
    'ACCIDENT':'Accidente',
    'ROAD_CLOSED':'Vía cerrada',
    'WEATHERHAZARD':'Climáticas'
  }
  data.forEach((item: any,index: number) => {
    const formattedTime = item.hour.toString();

    if (!dataFormatted[formattedTime]) {
      dataFormatted[formattedTime] = { hour: formattedTime };
    }


    dataFormatted[formattedTime][mapper[item.type]] = item.count;
    dataFormatted[formattedTime]['color'] = colors[index % colors.length];
  });

  return Object.values(dataFormatted).sort(
    (hourDetailA: any, hourDetailB: any) => {
      const hourA = parseInt(hourDetailA.hour);
      const hourB = parseInt(hourDetailB.hour);
      return hourA - hourB;
    }
  );
}

}