export enum WazeTrasnslate {
   ROAD_CLOSED_CONSTRUCTION = 'Vía en obra',
   HAZARD_WEATHER_FLOOD = 'Inundación',
   HAZARD_ON_ROAD_CAR_STOPPED = 'Coche Detenido',
   HAZARD_ON_SHOULDER_CAR_STOPPED = 'Coche Detenido',
   HAZARD_ON_ROAD_POT_HOLE = 'Agujero en vía',
   ACCIDENT_MINOR = 'Accidente Menor',
   ACCIDENT_MAJOR = 'Accidente Mayor',
   HAZARD_ON_ROAD_ROAD_KILL = 'Muerte en vía',
   ROAD_CLOSED_EVENT = 'Evento en vía',
   HAZARD_ON_ROAD_TRAFFIC_LIGHT_FAULT = 'Semáforo dañado',
}