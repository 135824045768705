import {GeoAPITypeEnum} from '@shared/models/geo-api-type.model';


export enum MapLayers {
  Cameras                                                   = 'Cameras',
  Situations                                                = 'Situations',
  Incidents                                                 = 'Incidents',
  Traffic                                                   = 'Traffic',
  TrafficSpeedRange                                         = 'TrafficSpeedRange',
  TrafficSpeedRangeCorridors                                = 'TrafficSpeedRangeCorridors',
  TrafficConcordance                                        = 'TrafficConcordance',
  TrafficConcordanceCorridors                               = 'TrafficConcordanceCorridors',
  RoadCorridors                                             = 'RoadCorridors',
  WazeDataCorridors                                         = 'WazeDataCorridors',
  WazeDataSpeedRange                                        = 'WazeDataSpeedRange',
  TrafficJamWaze                                            = 'TrafficJamWaze',
  ExodusAndReturnWaze                                       = 'ExodusAndReturnWaze',
  AlertsWaze                                                = 'AlertsWaze',
  PmvEditar                                                 = 'PmvEditar',
  PmvConsulta                                               = 'PmvConsulta',
  Pmv                                                       = 'Pmv',
  Blank                                                     = 'Blank',
  close_session                                             = 'close_session',
  Co2                                                       = 'Co2',
  Aforo                                                     = 'Aforo',
  BicyclePath                                               = 'BicyclePath',
  CoosLine                                                  = 'CoosLine',
  CoosPoint                                                 = 'CoosPoint',
  TrafficLight                                              = 'TrafficLight',
  Agents                                                    = 'Agents',
  AgentsCluster                                             = 'AgentsCluster',
  Cranes                                                    = 'Cranes',
  CranesCluster                                             = 'CranesCluster',
  AgentsHistorical                                          = 'AgentsHistorical',
  CranesHistorical                                          = 'CranesHistorical',
  AgentsNumber                                              = 'AgentsNumber',
  CranesNumber                                              = 'CranesNumber',
  TrafficManagmentPlans                                     = 'TrafficManagmentPlans',
  StopBus                                                   = 'StopBus',
  Zones                                                     = 'Zones',
  RoadRun                                                   = 'RoadRun',
  Accident                                                  = 'Accident',
  Locations                                                 = 'Locations',
  change_pass                                               = 'change_pass',
  RuntDatex                                                 = 'RuntDatex',
  Coi                                                       = 'Coi',
  Coiline                                                   = 'Coiline',
  Vehicles                                                  = "Vehicles",
  LastHour                                                  = "LastHour",
  IncidentsLocations                                        = "IncidentsLocations",
  IncidentsHeatMap                                          = "IncidentsHeatMap",
  FieldResourcesLocations                                   = "FieldResourcesLocations",
  IncidentsFieldResources                                   = "IncidentsFieldResources",
  ResourcesPredictiveModelLocations                         = "ResourcesPredictiveModelLocations",
  GroundResourceLasthours                                   = "GroundResourceLasthours",
  GroundResourceNumber = 'GroundResourceNumber',
  Bikes                                                     = "Bikes+",
  incidentAddResources = 'incidentAddResources',
  AlertsDuplicatesWaze                                      = 'AlertsDuplicatesWaze',

  //Air Quality
  ResourcesAirQualityPoints                                 = "ResourcesAirQualityPoints",
}

export const LayersDataMap: { [key: string]: GeoAPITypeEnum[] } = {
  [MapLayers.Situations]                                    : [GeoAPITypeEnum.Situation],
  [MapLayers.Incidents]                                     : [GeoAPITypeEnum.Incident],
  [MapLayers.Traffic]                                       : [GeoAPITypeEnum.Traffic],
  [MapLayers.TrafficConcordance]                            : [GeoAPITypeEnum.TrafficConcordance],
  [MapLayers.TrafficConcordanceCorridors]                   : [GeoAPITypeEnum.TrafficConcordanceCorridors],
  [MapLayers.TrafficSpeedRangeCorridors]                    : [GeoAPITypeEnum.TrafficSpeedRangeCorridors],
  [MapLayers.RoadCorridors]                                 : [GeoAPITypeEnum.RoadCorridors],
  [MapLayers.TrafficJamWaze]                                : [GeoAPITypeEnum.TrafficJamWaze],
  [MapLayers.ExodusAndReturnWaze]                           : [GeoAPITypeEnum.ExodusAndReturnWaze],
  [MapLayers.WazeDataCorridors]                             : [GeoAPITypeEnum.WazeDataCorridors],
  [MapLayers.WazeDataSpeedRange]                            : [GeoAPITypeEnum.WazeDataSpeedRange],
  [MapLayers.AlertsWaze]                                    : [GeoAPITypeEnum.AlertsWaze],
  [MapLayers.TrafficSpeedRange]                             : [GeoAPITypeEnum.TrafficSpeedRange],
  [MapLayers.Cameras]                                       : [GeoAPITypeEnum.Camera],
  [MapLayers.Blank]                                         : [GeoAPITypeEnum.Blank],
  [MapLayers.Co2]                                           : [GeoAPITypeEnum.Co2],
  [MapLayers.Aforo]                                         : [GeoAPITypeEnum.Aforo],
  [MapLayers.BicyclePath]                                   : [GeoAPITypeEnum.BicyclePath],
  [MapLayers.CoosLine]                                      : [GeoAPITypeEnum.CoosLine],
  [MapLayers.CoosPoint]                                     : [GeoAPITypeEnum.CoosPoint],
  [MapLayers.TrafficLight]                                  : [GeoAPITypeEnum.TrafficLight],
  [MapLayers.Agents]                                        : [GeoAPITypeEnum.Agents],
  [MapLayers.AgentsCluster]                                 : [GeoAPITypeEnum.Agents],
  [MapLayers.Cranes]                                        : [GeoAPITypeEnum.Cranes],
  [MapLayers.CranesCluster]                                 : [GeoAPITypeEnum.Cranes],
  [MapLayers.AgentsHistorical]                              : [GeoAPITypeEnum.AgentsHistorical],
  [MapLayers.CranesHistorical]                              : [GeoAPITypeEnum.CranesHistorical],
  [MapLayers.AgentsNumber]                                  : [GeoAPITypeEnum.AgentsNumber],
  [MapLayers.CranesNumber]                                  : [GeoAPITypeEnum.CranesNumber],
  [MapLayers.TrafficManagmentPlans]                         : [GeoAPITypeEnum.TrafficManagmentPlans],
  [MapLayers.StopBus]                                       : [GeoAPITypeEnum.StopBus],
  [MapLayers.Zones]                                         : [GeoAPITypeEnum.Zones],
  [MapLayers.RoadRun]                                       : [GeoAPITypeEnum.RoadRun],
  [MapLayers.Accident]                                      : [GeoAPITypeEnum.Accident],
  [MapLayers.IncidentsLocations]                            : [GeoAPITypeEnum.IncidentsLocations],
  [MapLayers.Pmv]                                           : [GeoAPITypeEnum.Pmv],
  [MapLayers.PmvConsulta]                                   : [GeoAPITypeEnum.PmvConsulta],
  [MapLayers.PmvEditar]                                     : [GeoAPITypeEnum.PmvEditar],
  [MapLayers.RuntDatex]                                     : [GeoAPITypeEnum.RuntDatex],
  [MapLayers.Coi]                                           : [GeoAPITypeEnum.Coi],
  [MapLayers.Coiline]                                       : [GeoAPITypeEnum.Coiline],
  [MapLayers.Vehicles]                                      : [GeoAPITypeEnum.Vehicles],
  [MapLayers.LastHour]                                      : [GeoAPITypeEnum.lastHours],
  [MapLayers.IncidentsHeatMap]                              : [GeoAPITypeEnum.IncidentsHeatMap],
  [MapLayers.FieldResourcesLocations]                       : [GeoAPITypeEnum.FieldResourcesLocations],
  [MapLayers.IncidentsFieldResources]                       : [GeoAPITypeEnum.IncidentsFieldResources],
  [MapLayers.ResourcesPredictiveModelLocations]             : [GeoAPITypeEnum.ResourcesPredictiveModelLocations],
  [MapLayers.GroundResourceLasthours]                       : [GeoAPITypeEnum.GroundResourceLasthours],
  [MapLayers.GroundResourceNumber]                          : [GeoAPITypeEnum.GroundResourceNumber],
  [MapLayers.Bikes]                                         : [GeoAPITypeEnum.Bikes],
  [MapLayers.incidentAddResources]                          : [GeoAPITypeEnum.incidentAddResources],
  [MapLayers.AlertsDuplicatesWaze]                          : [GeoAPITypeEnum.AlertsDuplicatesWaze],

  // Air Quality
  [MapLayers.ResourcesAirQualityPoints]                     : [GeoAPITypeEnum.ResourcesAirQualityPoints],
};
