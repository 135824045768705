import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { BehaviorSubject, lastValueFrom, tap } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PmvService } from '@shared/services/layer-service/pmv-service/pmv.service';
import { remapObjectToOneLevel } from '@ui-core/utils/functions/remap-object';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { PmvEditarService } from '@shared/services/layer-service/pmv-service/pmveditar.service';
import { notificationCodeMessage } from '@types-custom/models/ui/panel-edit-pmv.model';

@Component({
  selector: 'create-message-pmv',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.scss'],
})
export class CreateMessageComponent implements OnInit {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  @Input() dataselect: any;
  @Input() ip: any;

  editflab = false;

  messageSuccess = 'El mensaje se ha editado con éxito';

  pages: any[] = [];
  pagesforsave: any[] = [];

  selecteddefault: any;
  selectultimo: any;

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  Icon = Icon;

  pageForm: FormGroup;

  redbackground: number;
  greenbackground: number;
  bluebackground: number;
  selectValuePage: number;
  myFlagForSlideToggle = true;
  valueinputbackgroundcolor = '#000000';
  valueinputtextcolor = '#000000';
  selectedValAlignHorizontal = 'center';

  dataFonts: any;
  dataGraphics: any;
  dataMessage: any;

  sendButtonProps: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private pmvService: PmvService,
    private flagCreate: PmvEditarService
  ) {
    this.pageForm = this.fb.group({
      pageTime: ['', Validators.compose([Validators.required])],
      pageBackgroundColorHeigth: [
        '',
        Validators.compose([Validators.required]),
      ],
      pageBackgroundColorWidth: ['', Validators.compose([Validators.required])],
      pageBackgroundColorX: ['', Validators.compose([Validators.required])],
      pageBackgroundColorY: ['', Validators.compose([Validators.required])],
      spacingCharacter: ['', Validators.compose([Validators.required])],
      BackgroundColorR: [0, Validators.compose([Validators.required])],
      BackgroundColorG: [0, Validators.compose([Validators.required])],
      BackgroundColorB: [0, Validators.compose([Validators.required])],
      TextColorR: [0, Validators.compose([Validators.required])],
      TextColorG: [0, Validators.compose([Validators.required])],
      TextColorB: [0, Validators.compose([Validators.required])],
      justificationPage: ['center', Validators.compose([Validators.required])],
      font: ['', Validators.compose([Validators.required])],
      graphic: [0, Validators.compose([])],
      graphicEjeX: [0, Validators.compose([Validators.required])],
      graphicEjeY: [0, Validators.compose([Validators.required])],
      lines: this.fb.array([this.createline()], Validators.required),
    });
  }

  async ngOnInit() {
    this.panelOnManagement = MapEventTypeEnum.PMVEditar;
    await this.GetDataCombos();
    await this.setDataFormat();
  }

  createline(): FormGroup {
    return this.fb.group({
      newLine: [1, Validators.required],
      text: ['', Validators.required],
      justificationLine: ['center', Validators.required],
    });
  }

  addTicket() {
    this.lines.push(this.createline());
  }

  get lines(): FormArray {
    return <FormArray>this.pageForm.get('lines');
  }

  async setDataFormat() {
    let cont = 1;
    if (this.dataMessage.dynamicMessage != null) {
      this.dataMessage.dynamicMessage.pages.forEach((data: any) => {
        const datalist = {
          value: cont,
          name: cont,
          data: {
            pageTime: data.pageTime == 0 ? '' : data.pageTime,
            BackgroundColorR:
              data.pageBackgroundColor == null
                ? '0'
                : data.pageBackgroundColor[0],
            BackgroundColorG:
              data.pageBackgroundColor == null
                ? '0'
                : data.pageBackgroundColor[1],
            BackgroundColorB:
              data.pageBackgroundColor == null
                ? '0'
                : data.pageBackgroundColor[2],
            spacingCharacter:
              data.spacingCharacter == 0 ? '0' : data.spacingCharacter,
            font: data.font == 0 ? '' : data.font,
            pageBackgroundColorHeigth:
              data.textRectangle == null ? '0' : data.textRectangle[3],
            pageBackgroundColorWidth:
              data.textRectangle == null ? '0' : data.textRectangle[2],
            pageBackgroundColorX:
              data.textRectangle == null ? '0' : data.textRectangle[0],
            pageBackgroundColorY:
              data.textRectangle == null ? '0' : data.textRectangle[1],
            TextColorR:
              data.colorForeground == null ? '0' : data.colorForeground[0],
            TextColorG:
              data.colorForeground == null ? '0' : data.colorForeground[1],
            TextColorB:
              data.colorForeground == null ? '0' : data.colorForeground[2],
            justificationPage:
              data.justificationPage == 3
                ? 'center'
                : data.justificationPage == 2
                ? 'top'
                : data.justificationPage == 4
                ? 'bottom'
                : 'center',
            graphic: data.graphic == null ? 0 : data.graphic[0],
            graphicEjeX: data.graphic == null ? 0 : data.graphic[1],
            graphicEjeY: data.graphic == null ? 0 : data.graphic[2],
            lines: data.lines,
          },
        };
        this.pages.push(datalist);
        cont++;
      });
      this.selecteddefault = this.pages[0].value;
      this.selectultimo = this.selecteddefault;
      this.pageForm
        .get('BackgroundColorR')
        .setValue(this.pages[0].data.BackgroundColorR);
      this.pageForm
        .get('BackgroundColorG')
        .setValue(this.pages[0].data.BackgroundColorG);
      this.pageForm
        .get('BackgroundColorB')
        .setValue(this.pages[0].data.BackgroundColorB);
      this.pageForm
        .get('spacingCharacter')
        .setValue(this.pages[0].data.spacingCharacter);
      this.pageForm.get('font').setValue(this.pages[0].data.font);
      this.pageForm
        .get('pageBackgroundColorHeigth')
        .setValue(this.pages[0].data.pageBackgroundColorHeigth);
      this.pageForm
        .get('pageBackgroundColorWidth')
        .setValue(this.pages[0].data.pageBackgroundColorWidth);
      this.pageForm
        .get('pageBackgroundColorX')
        .setValue(this.pages[0].data.pageBackgroundColorX);
      this.pageForm
        .get('pageBackgroundColorY')
        .setValue(this.pages[0].data.pageBackgroundColorY);
      this.pageForm.get('TextColorR').setValue(this.pages[0].data.TextColorR);
      this.pageForm.get('TextColorG').setValue(this.pages[0].data.TextColorG);
      this.pageForm.get('TextColorB').setValue(this.pages[0].data.TextColorB);
      this.pageForm
        .get('justificationPage')
        .setValue(this.pages[0].data.justificationPage);
      this.pageForm.get('graphic').setValue(this.pages[0].data.graphic);
      this.pageForm.get('graphicEjeX').setValue(this.pages[0].data.graphicEjeX);
      this.pageForm.get('graphicEjeY').setValue(this.pages[0].data.graphicEjeY);
      this.pageForm.get('pageTime').setValue(this.pages[0].data.pageTime);
      this.addlinesforpage(this.pages[0].data.lines);
      this.valueinputbackgroundcolor = this.ConvertRGBtoHex(
        this.pages[0].data.BackgroundColorR,
        this.pages[0].data.BackgroundColorG,
        this.pages[0].data.BackgroundColorB
      );
      this.valueinputtextcolor = this.ConvertRGBtoHex(
        this.pages[0].data.TextColorR,
        this.pages[0].data.TextColorG,
        this.pages[0].data.TextColorB
      );
    } else {
      const datalist = {
        value: cont,
        name: cont,
        data: '',
      };
      this.pages.push(datalist);
      this.selecteddefault = this.pages[0].value;
    }
    // console.log("Originall "+JSON.stringify(this.pages))
  }

  async GetDataCombos() {
    [this.dataFonts, this.dataGraphics, this.dataMessage] = await Promise.all([
      lastValueFrom(this.pmvService.getAllFonts(this.ip.ip)),
      lastValueFrom(this.pmvService.getAllGraphic(this.ip.ip)),
      lastValueFrom(
        this.pmvService.getMessagebyNumber(
          this.ip.ip,
          this.dataselect.messageNumber
        )
      ),
    ]);
    this.dataFonts = this.dataFonts.data;
    this.dataGraphics = this.dataGraphics.data;
  }

  async CreateMessage(): Promise<void> {
    this.pagesforsave = [];
    this.pages.forEach((data) => {
      if (data.value == this.selecteddefault) {
        // if(this.pageForm.value.graphic == null){
        //   this.pageForm.value.graphic = 0;
        // }

        const datos = {
          pageTime: this.pageForm.value.pageTime,
          pageBackgroundColor: [
            parseInt(this.pageForm.value.BackgroundColorR),
            parseInt(this.pageForm.value.BackgroundColorG),
            parseInt(this.pageForm.value.BackgroundColorB),
          ],
          spacingCharacter: parseInt(this.pageForm.value.spacingCharacter),
          justificationPage:
            this.pageForm.value.justificationPage == 'center'
              ? 3
              : this.pageForm.value.justificationPage == 'top'
              ? 2
              : this.pageForm.value.justificationPage == 'bottom'
              ? 4
              : 1,
          font: this.pageForm.value.font,
          textRectangle: [
            parseInt(this.pageForm.value.pageBackgroundColorX),
            parseInt(this.pageForm.value.pageBackgroundColorY),
            parseInt(this.pageForm.value.pageBackgroundColorWidth),
            parseInt(this.pageForm.value.pageBackgroundColorHeigth),
          ],
          colorForeground: [
            parseInt(this.pageForm.value.TextColorR),
            parseInt(this.pageForm.value.TextColorG),
            parseInt(this.pageForm.value.TextColorB),
          ],
          graphic: [
            parseInt(this.pageForm.value.graphic),
            parseInt(this.pageForm.value.graphicEjeX),
            parseInt(this.pageForm.value.graphicEjeY),
          ],
          lines: this.pageForm.value.lines,
        };
        this.pagesforsave.push(datos);
      } else {
        // if(data.data.graphic == null){
        //   data.data.graphic = 0;
        // }
        const datos = {
          pageTime: data.data.pageTime,
          pageBackgroundColor: [
            parseInt(data.data.BackgroundColorR),
            parseInt(data.data.BackgroundColorG),
            parseInt(data.data.BackgroundColorB),
          ],
          spacingCharacter: parseInt(data.data.spacingCharacter),
          justificationPage:
            data.data.justificationPage == 'center'
              ? 3
              : data.data.justificationPage == 'top'
              ? 2
              : data.data.justificationPage == 'bottom'
              ? 4
              : data.data.justificationPage == 4
              ? 4
              : data.data.justificationPage == 3
              ? 3
              : data.data.justificationPage == 2
              ? 2
              : 3,
          font: data.data.font,
          textRectangle: [
            parseInt(data.data.pageBackgroundColorX),
            parseInt(data.data.pageBackgroundColorY),
            parseInt(data.data.pageBackgroundColorWidth),
            parseInt(data.data.pageBackgroundColorHeigth),
          ],
          colorForeground: [
            parseInt(data.data.TextColorR),
            parseInt(data.data.TextColorG),
            parseInt(data.data.TextColorB),
          ],
          graphic: [
            parseInt(data.data.graphic),
            parseInt(data.data.graphicEjeX),
            parseInt(data.data.graphicEjeY),
          ],
          lines: data.data.lines,
        };
        this.pagesforsave.push(datos);
      }
    });

    const data = {
      messageNumber: this.dataMessage.messageNumber,
      messageOwner: this.dataMessage.messageNumber.toString(),
      activateMessage: false,
      multiString: '',
      pages: this.pagesforsave,
    };
    this.editformforsave(data);

    this.pmvService.saveMessagesforcameraip(this.ip.ip, data).subscribe({
      next: () => this.handleSuccess(),
      error: (error) => this.handleNotification(error.error.message),
    });
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  editformforsave(data: any) {
    data.pages.forEach((lines: any) => {
      lines.lines.forEach((data: any) => {
        data.justificationLine =
          data.justificationLine == 'center'
            ? 3
            : data.justificationLine == 'left'
            ? 2
            : data.justificationLine == 'right'
            ? 4
            : 3;
      });
    });
  }

  onSelectEvent(value: any) {
    if (!this.editflab) {
      // console.log("changeeg "+value+ " anterio "+this.selectultimo.toString())
      // console.log("linessssss asfas  "+JSON.stringify(this.pages))

      (this.pages[this.selectultimo - 1].data.pageTime =
        this.pageForm.value.pageTime),
        (this.pages[this.selectultimo - 1].data.BackgroundColorR =
          this.pageForm.value.BackgroundColorR),
        (this.pages[this.selectultimo - 1].data.BackgroundColorG =
          this.pageForm.value.BackgroundColorG),
        (this.pages[this.selectultimo - 1].data.BackgroundColorB =
          this.pageForm.value.BackgroundColorB),
        (this.pages[this.selectultimo - 1].data.spacingCharacter =
          this.pageForm.value.spacingCharacter),
        (this.pages[this.selectultimo - 1].data.font =
          this.pageForm.value.font),
        (this.pages[this.selectultimo - 1].data.pageBackgroundColorHeigth =
          this.pageForm.value.pageBackgroundColorHeigth),
        (this.pages[this.selectultimo - 1].data.pageBackgroundColorWidth =
          this.pageForm.value.pageBackgroundColorWidth),
        (this.pages[this.selectultimo - 1].data.pageBackgroundColorX =
          this.pageForm.value.pageBackgroundColorX),
        (this.pages[this.selectultimo - 1].data.pageBackgroundColorY =
          this.pageForm.value.pageBackgroundColorY),
        (this.pages[this.selectultimo - 1].data.TextColorR =
          this.pageForm.value.TextColorR),
        (this.pages[this.selectultimo - 1].data.TextColorG =
          this.pageForm.value.TextColorG),
        (this.pages[this.selectultimo - 1].data.TextColorB =
          this.pageForm.value.TextColorB),
        (this.pages[this.selectultimo - 1].data.justificationPage =
          this.pageForm.value.justificationPage),
        (this.pages[this.selectultimo - 1].data.graphic =
          this.pageForm.value.graphic),
        (this.pages[this.selectultimo - 1].data.graphicEjeX =
          this.pageForm.value.graphicEjeX),
        (this.pages[this.selectultimo - 1].data.graphicEjeY =
          this.pageForm.value.graphicEjeY),
        (this.pages[this.selectultimo - 1].data.lines =
          this.pageForm.value.lines),
        this.pageForm
          .get('pageTime')
          .setValue(this.pages[value - 1].data.pageTime);
      this.pageForm
        .get('BackgroundColorR')
        .setValue(this.pages[value - 1].data.BackgroundColorR);
      this.pageForm
        .get('BackgroundColorG')
        .setValue(this.pages[value - 1].data.BackgroundColorG);
      this.pageForm
        .get('BackgroundColorB')
        .setValue(this.pages[value - 1].data.BackgroundColorB);
      this.pageForm
        .get('spacingCharacter')
        .setValue(this.pages[value - 1].data.spacingCharacter);
      this.pageForm.get('font').setValue(this.pages[value - 1].data.font);
      this.pageForm
        .get('pageBackgroundColorHeigth')
        .setValue(this.pages[value - 1].data.pageBackgroundColorHeigth);
      this.pageForm
        .get('pageBackgroundColorWidth')
        .setValue(this.pages[value - 1].data.pageBackgroundColorWidth);
      this.pageForm
        .get('pageBackgroundColorX')
        .setValue(this.pages[value - 1].data.pageBackgroundColorX);
      this.pageForm
        .get('pageBackgroundColorY')
        .setValue(this.pages[value - 1].data.pageBackgroundColorY);
      this.pageForm
        .get('TextColorR')
        .setValue(this.pages[value - 1].data.TextColorR);
      this.pageForm
        .get('TextColorG')
        .setValue(this.pages[value - 1].data.TextColorG);
      this.pageForm
        .get('TextColorB')
        .setValue(this.pages[value - 1].data.TextColorB);
      this.pageForm
        .get('justificationPage')
        .setValue(this.pages[value - 1].data.justificationPage);
      this.pageForm.get('graphic').setValue(this.pages[value - 1].data.graphic);
      this.pageForm
        .get('graphicEjeX')
        .setValue(this.pages[value - 1].data.graphicEjeX);
      this.pageForm
        .get('graphicEjeY')
        .setValue(this.pages[value - 1].data.graphicEjeY);
      this.addlinesforpage(this.pages[value - 1].data.lines);
      this.valueinputbackgroundcolor = this.ConvertRGBtoHex(
        this.pages[value - 1].data.BackgroundColorR,
        this.pages[value - 1].data.BackgroundColorG,
        this.pages[value - 1].data.BackgroundColorB
      );
      this.valueinputtextcolor = this.ConvertRGBtoHex(
        this.pages[value - 1].data.TextColorR,
        this.pages[value - 1].data.TextColorG,
        this.pages[value - 1].data.TextColorB
      );
      this.selectValuePage = value;
      this.selectultimo = value;
    } else {
      //console.log('TextColorR ', this.pages[value - 1].data.TextColorR);
      //console.log('TextColorG ', this.pages[value - 1].data.TextColorG);
      //console.log('TextColorB ', this.pages[value - 1].data.TextColorB);
      this.pageForm
        .get('pageTime')
        .setValue(this.pages[value - 1].data.pageTime);
      this.pageForm
        .get('BackgroundColorR')
        .setValue(this.pages[value - 1].data.BackgroundColorR);
      this.pageForm
        .get('BackgroundColorG')
        .setValue(this.pages[value - 1].data.BackgroundColorG);
      this.pageForm
        .get('BackgroundColorB')
        .setValue(this.pages[value - 1].data.BackgroundColorB);
      this.pageForm
        .get('spacingCharacter')
        .setValue(this.pages[value - 1].data.spacingCharacter);
      this.pageForm.get('font').setValue(this.pages[value - 1].data.font);
      this.pageForm
        .get('pageBackgroundColorHeigth')
        .setValue(this.pages[value - 1].data.pageBackgroundColorHeigth);
      this.pageForm
        .get('pageBackgroundColorWidth')
        .setValue(this.pages[value - 1].data.pageBackgroundColorWidth);
      this.pageForm
        .get('pageBackgroundColorX')
        .setValue(this.pages[value - 1].data.pageBackgroundColorX);
      this.pageForm
        .get('pageBackgroundColorY')
        .setValue(this.pages[value - 1].data.pageBackgroundColorY);
      this.pageForm
        .get('TextColorR')
        .setValue(this.pages[value - 1].data.TextColorR);
      this.pageForm
        .get('TextColorG')
        .setValue(this.pages[value - 1].data.TextColorG);
      this.pageForm
        .get('TextColorB')
        .setValue(this.pages[value - 1].data.TextColorB);
      this.pageForm
        .get('justificationPage')
        .setValue(this.pages[value - 1].data.justificationPage);
      this.pageForm.get('graphic').setValue(this.pages[value - 1].data.graphic);
      this.pageForm
        .get('graphicEjeX')
        .setValue(this.pages[value - 1].data.graphicEjeX);
      this.pageForm
        .get('graphicEjeY')
        .setValue(this.pages[value - 1].data.graphicEjeY);
      this.addlinesforpage(this.pages[value - 1].data.lines);
      this.valueinputbackgroundcolor = this.ConvertRGBtoHex(
        this.pages[value - 1].data.BackgroundColorR,
        this.pages[value - 1].data.BackgroundColorG,
        this.pages[value - 1].data.BackgroundColorB
      );
      this.valueinputtextcolor = this.ConvertRGBtoHex(
        this.pages[value - 1].data.TextColorR,
        this.pages[value - 1].data.TextColorG,
        this.pages[value - 1].data.TextColorB
      );
      this.selectValuePage = value;
      this.selectultimo = value;
    }
  }

  addlinesforpage(data: any) {
    this.lines.clear();
    data.forEach((data: any) => {
      const justification =
        data.justificationLine == 3
          ? 'center'
          : data.justificationLine == 2
          ? 'left'
          : data.justificationLine == 4
          ? 'rigth'
          : 'center';
      this.lines.push(
        this.fb.group({
          newLine: [1, Validators.required],
          text: [data.text, Validators.required],
          justificationLine: [justification, Validators.required],
        })
      );
    });
  }

  deletepage() {
    //console.log('this.pages.length ', this.pages.length);
    //console.log('this.selectValuePage ', this.selectValuePage);
    let select = -1;
    if (this.pages.length > 1) {
      this.pages.forEach((data) => {
        if (data.value.toString() == this.selectValuePage.toString()) {
          this.pages.splice(data.value - 1, 1);
          select = data.value - 1;
        }
      });
      this.selecteddefault = this.pages[select - 1].value;
      this.selectValuePage = this.selecteddefault;
    } else {
      this.selecteddefault = this.pages[0].value;
      this.selectValuePage = this.selecteddefault;
    }
  }

  deleteLine(data: any) {
    if (this.lines.length > 1) {
      this.lines.removeAt(data);
    }
  }

  addPage() {
    this.editflab = true;
    const datapage = {
      pageTime: this.pageForm.value.pageTime,
      pageBackgroundColorHeigth: this.pageForm.value.pageBackgroundColorHeigth,
      pageBackgroundColorWidth: this.pageForm.value.pageBackgroundColorWidth,
      pageBackgroundColorX: this.pageForm.value.pageBackgroundColorX,
      pageBackgroundColorY: this.pageForm.value.pageBackgroundColorY,
      spacingCharacter: this.pageForm.value.spacingCharacter,
      TextColorR: this.pageForm.value.TextColorR,
      TextColorG: this.pageForm.value.TextColorG,
      TextColorB: this.pageForm.value.TextColorB,
      justificationPage: this.pageForm.value.justificationPage,
      justificationLine: this.pageForm.value.justificationLine,
      font: this.pageForm.value.font,
      textRectangle: this.pageForm.value.textRectangle,
      colorForeground: this.pageForm.value.colorForeground,
      graphic: this.pageForm.value.graphic,
      graphicEjeX: this.pageForm.value.graphicEjeX,
      graphicEjeY: this.pageForm.value.graphicEjeY,
      BackgroundColorR: this.pageForm.value.BackgroundColorR,
      BackgroundColorG: this.pageForm.value.BackgroundColorG,
      BackgroundColorB: this.pageForm.value.BackgroundColorB,
      lines: this.pageForm.value.lines,
    };
    const datapagenew = {
      pageTime: '',
      pageBackgroundColorHeigth: '',
      pageBackgroundColorWidth: '',
      pageBackgroundColorX: '',
      pageBackgroundColorY: '',
      spacingCharacter: '',
      TextColorR: 0,
      TextColorG: 0,
      TextColorB: 0,
      justificationPage: '',
      justificationLine: '',
      font: '',
      textRectangle: '',
      colorForeground: '',
      graphic: '',
      graphicEjeX: '',
      graphicEjeY: '',
      BackgroundColorR: 0,
      BackgroundColorG: 0,
      BackgroundColorB: 0,
      lines: '',
    };
    if (this.pages.length == 1) {
      this.pages[0].data = datapage;
      const datalist = {
        value: this.pages.length + 1,
        name: this.pages.length + 1,
        data: datapagenew,
      };
      this.pages.push(datalist);
    } else {
      const datalistnew = {
        value: this.pages.length,
        name: this.pages.length,
        data: datapage,
      };
      this.pages[this.pages.length - 1] = datalistnew;

      const datalistnew2 = {
        value: this.pages.length + 1,
        name: this.pages.length + 1,
        data: datapagenew,
      };
      this.pages.push(datalistnew2);
    }
    console.log('this.pages ', this.pages);
    this.selecteddefault = this.pages[this.pages.length - 1].value;
    this.selectValuePage = this.selecteddefault;
    this.selectultimo = this.pages.length;
    this.resetform();
  }

  resetform() {
    this.pageForm.get('pageTime').setValue('');
    this.pageForm.get('BackgroundColorR').setValue('');
    this.pageForm.get('BackgroundColorG').setValue('');
    this.pageForm.get('BackgroundColorB').setValue('');
    this.pageForm.get('spacingCharacter').setValue('');
    this.pageForm.get('font').setValue('');
    this.pageForm.get('pageBackgroundColorHeigth').setValue('');
    this.pageForm.get('pageBackgroundColorWidth').setValue('');
    this.pageForm.get('pageBackgroundColorX').setValue('');
    this.pageForm.get('pageBackgroundColorY').setValue('');
    this.pageForm.get('TextColorR').setValue('');
    this.pageForm.get('TextColorG').setValue('');
    this.pageForm.get('TextColorB').setValue('');
    this.pageForm.get('justificationPage').setValue('center');
    this.pageForm.get('graphicEjeX').setValue('');
    this.pageForm.get('graphicEjeY').setValue('');
    this.valueinputbackgroundcolor = '#000000';
    this.valueinputtextcolor = '#000000';
    this.lines.clear();
    this.lines.push(
      this.fb.group({
        newLine: [1, Validators.required],
        text: ['', Validators.required],
        justificationLine: ['', Validators.required],
      })
    );
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  changebackground(event: any) {
    const hex = event.target.value;
    this.pageForm
      .get('BackgroundColorR')
      .setValue(parseInt(hex[1] + hex[2], 16));
    this.pageForm
      .get('BackgroundColorG')
      .setValue(parseInt(hex[3] + hex[4], 16));
    this.pageForm
      .get('BackgroundColorB')
      .setValue(parseInt(hex[5] + hex[6], 16));
  }

  changetextcolor(event: any) {
    const hex = event.target.value;
    this.pageForm.get('TextColorR').setValue(parseInt(hex[1] + hex[2], 16));
    this.pageForm.get('TextColorG').setValue(parseInt(hex[3] + hex[4], 16));
    this.pageForm.get('TextColorB').setValue(parseInt(hex[5] + hex[6], 16));
  }

  ColorToHex(color: any) {
    const hexadecimal = color.toString(16);
    return hexadecimal.length == 1 ? '0' + hexadecimal : hexadecimal;
  }

  ConvertRGBtoHex(red: any, green: any, blue: any) {
    return (
      '#' +
      this.ColorToHex(red) +
      this.ColorToHex(green) +
      this.ColorToHex(blue)
    );
  }

  handleSendForm() {
    const dataForm = remapObjectToOneLevel(this.pageForm.value, {});
    this.CreateMessage();
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleSuccess(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: this.messageSuccess },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
    this.serviceDataSource.init();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-1',
    };
    // this.modalService.confirmationModal(modal);
  }

  handleConfirmation(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: { infoMessage: '¿Está seguro que desea cancelar?' },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.modalService.confirmationModal(modal);
  }

  handleNotification(notificationCode: notificationCodeMessage) {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: `${(notificationCodeMessage as any)[notificationCode]}`,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
  }
}
