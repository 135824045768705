export const separateCoordinates = (featureCollection: any): any => {
  const usedCoordinates: { [key: string]: boolean } = {};
  const coordinatesMap: { [key: string]: any[] } = {};

  featureCollection.features.forEach((feature: any) => {
    const { coordinates } = feature.geometry;
    const coordinateKey = coordinates.join(',');
    if (!coordinatesMap[coordinateKey]) {
      coordinatesMap[coordinateKey] = [coordinates];
    } else {
      const newCoordinates = findNewCoordinates(coordinates, coordinatesMap);
      coordinatesMap[newCoordinates.join(',')] = newCoordinates;
      feature.geometry.coordinates = newCoordinates;
    }
    usedCoordinates[coordinateKey] = true;
  });

  return featureCollection;
};

function findNewCoordinates(
  coordinates: any,
  coordinatesMap: { [key: string]: any[] }
): any {
  const newCoordinates = coordinates.slice();
  while (coordinatesMap[newCoordinates.join(',')]) {
    newCoordinates[0] += 0.0001;
    newCoordinates[1] += 0.0001;
  }
  return newCoordinates;
}
