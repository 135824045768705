import { BehaviorSubject, Observable } from 'rxjs';
import { SensorOptionTypeEnum } from './sensor-option-type-model';
import { IDynamicFormModel } from './generic-form.model';

export interface IPaginatorModel<T = any> {
  page: number;
  pageSize: number;
  totalCount?: number;
  data?: T;
}

export abstract class AbstractPaginatorDataSource<T = any> {
  abstract data$: Observable<T>;
  abstract pageInfo: BehaviorSubject<IPaginatorModel>;
  abstract fetchPageData(
    pageInfo: IPaginatorModel<T>
  ): Observable<IPaginatorModel>;
}

export abstract class AbstractPanelManagementDataSource<T = any> {
  abstract data$: Observable<T>;
  abstract pageInfo: BehaviorSubject<IPaginatorModel>;
  abstract formFiltersValue?: any;
  abstract init(): void;
  abstract fetchPageData(
    pageInfo: IPaginatorModel<T>
  ): Observable<IPaginatorModel>;
  abstract submitCreateForm: (formValue: any) => Observable<any>;
  abstract submitEditForm: (formValue: any) => Observable<any>;
  abstract searchFromFilters: (formFiltersValue: any) => void;
  abstract getValueById?: (id: string, params?: { [key: string]: any }) => Observable<any>;
  abstract getSelectOptions: (optionType: string, value?: number) => Observable<any>;
  abstract getRadioOptions?: (optionType: string) => Observable<any>;
  abstract getDynamicFields?: () => Observable<IDynamicFormModel[]>;
  abstract buildExcelPdfUrl: (formValue: any) => string;
  abstract getIndividualData?: (rowData: any, dinamicData?: boolean) => Observable<any>;
  abstract getStateCounter?: () => Observable<any[]>;
}

export abstract class filterStateDataSource<T = any> {
  abstract filterObservable: Observable<T>;
  abstract saveStateFilter: (formFilter: any) => void;
  abstract filterValue: () => T;
}