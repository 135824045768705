<ng-container *ngIf="dataSource">
    <table class="full-width">
      <thead class="bg-gray text-white text-size-16 text-weight-600">
      <ng-container *ngFor="let header of headers">
        <ng-container *ngIf="!header.span || header.span > -1">
          <th
                  class="p-0 text-weight-600"
                  [attr.colspan]="header.span"
                  [ngClass]="header.headerClass ?? ''"
          >
            {{ header.label }}
          </th>
        </ng-container>
      </ng-container>
        <th>Acciones</th>
      </thead>
      <tbody class="text-gray text-size-14">
      <ng-container *ngFor="let item of dataSource | async; let index = index">
        <tr class="table-bg" (click)="handleClickRow(dispatcherRow, item, index, headers)">
          <ng-container *ngFor="let header of headers">
            <td [attr.colspan]="header.span"
                class="p-0 text-weight-500 text-size-16 text-white"
                [ngClass]="header.cellClass ?? ''"
                (click)="handleClick(header.dispatcher, item, header.key, index, header)"
            >
              <ng-container *ngIf="header.toRender; else plain">
                <sit-renderer
                        [toRender]="
                      resolveRenderData(
                        header.toRender,
                        item[header.key + ''],
                        header.key + ''
                      )
                    "
                >
                </sit-renderer>
              </ng-container>
              <ng-template #plain>
                <ng-container *ngIf="header.key">
                  <!-- <ng-container *ngIf="header.label === 'Fecha'">
                    {{ item[header.key] | date: 'dd/MM/YYYY' }}
                  </ng-container>
                  <ng-container *ngIf="header.label === 'Hora'">
                    {{ item[header.key] | date: 'hh:mm a' }}
                  </ng-container>
                  <ng-container *ngIf="dataCellType.isNumber(item[header.key])">
                    <ng-container *ngIf="header.cellInteger; else cellDecimal">
                      {{ item[header.key] }}
                    </ng-container>
                    <ng-template #cellDecimal>
                      {{ item[header.key] | number: '1.2-2' }}
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="header.subKey">
                    {{ item[header.key][header.subKey] }}
                  </ng-container> -->
                  <ng-container
                          *ngIf="
                        (
                          header.label === 'Estado'
                        )
                      "
                  >
                  <div class="d-flex fd-row">
                    <sit-icon
                    class="col-sm d-flex mr-05-em"
                    [icon]="getIcon(item[header.key])"
                    [sizeClass]="'icon-size-18'"
                  ></sit-icon>
                    {{ item[header.key] }}
                  </div>
                  
                  </ng-container>

                  <ng-container
                          *ngIf="
                        (
                          header.label === 'Tipo de recurso'
                        )
                      "
                  >
                  <div class="d-flex fd-row">
                    <sit-icon
                    class="col-sm d-flex mr-05-em"
                    [icon]="getIconTipoRecurso(item[header.key],item['conditionName'])"
                    [sizeClass]="'icon-size-30'"
                    tooltip="{{item['conditionName']}}"
                  ></sit-icon>
                    <span class="" style="margin-top: 0.3rem;">
                      {{ item[header.key] }}
                    </span>
                  </div>
                  
                  </ng-container>
                  <ng-container
                          *ngIf="
                        !(
                          header.label === 'Fecha' ||
                          header.label === 'Hora' ||
                          header.label === 'Estado' ||
                          header.label === 'Tipo de recurso' ||
                          dataCellType.isNumber(item[header.key]) ||
                          header.subKey
                        )
                      "
                  >
                    {{ item[header.key] }}
                  </ng-container>
                </ng-container>
              </ng-template>
            </td>
          </ng-container>
  
          <ng-container>
            <td class="actionsColumn">
              
              <a (click)="handleEdit(item?.id)" *ngIf="item?.stateCode !='DadoBaja'">
                <sit-icon [icon]="Icon.edit_w" [sizeClass]="'icon-size-24'" *ngIf="item?.stateCode !='DadoBaja'"
                ></sit-icon>
              </a>
        
              <a (click)="historicalStatusPhysicalResources(item?.id)" *ngIf="item?.stateCode =='DadoBaja'">
                  <sit-icon [icon]="Icon.last_hours_w" [sizeClass]="'icon-size-24'" *ngIf="item?.stateCode =='DadoBaja'"
                  ></sit-icon>
              </a>

              <mat-slide-toggle
                class="example-margin"
                (change)="changeStatusRole($event, item?.id)"
                [checked]="item?.stateCode == 'Activo'"
                [title]="item?.stateCode == 'Activo' ? 'Activo':'Inactivo'"
                [disabled]="item?.stateCode =='DadoBaja'">
              </mat-slide-toggle>

              <!-- <input style="width: 43px; height: 18px;" autofocusclass="form-check-input" type="checkbox" role="switch"
                                          id="flexSwitchCheckDefault" 
                                          (change)="changeStatusRole($event, item?.id)"
                                          [checked]="item?.stateCode == 'Activo'"
                                          [title]="item?.stateCode == 'Activo' ? 'Activo':'Inactivo'"
                                          [disabled]="item?.stateCode =='DadoBaja'"> -->
            
              <a (click)="unsuscribePhysicalResources(item?.id)" [ngClass]="item?.stateCode =='DadoBaja' ? 'disabled' : ''">
                <sit-icon [icon]="Icon.delete_w" [sizeClass]="'icon-size-24'"
                ></sit-icon>
              </a>
            </td>
          </ng-container>
          
        </tr>
        
      </ng-container>
      
      </tbody>
    </table>
  </ng-container>
  
  