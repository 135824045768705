import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import Hls from 'hls.js';
import { IModalCamerasModel } from '@types-custom/models/ui/modal-camera.modal';
import { fromEvent, Subscription, timeout } from 'rxjs';
import { LocalStorageManagementService } from '@shared/services/local-storage-management/local-storage-management.service';

/*
 ** Modal Camera
 ** Render a Camera modal that displays a video player with record options.
 */
@Component({
  selector: 'modal-camera',
  templateUrl: './modal-camera.component.html',
  styleUrls: ['./modal-camera.component.scss'],
})
export class ModalCameraComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() properties: IModalCamerasModel | undefined;
  @ViewChild('videoPlayer', { static: false }) playerContainer!: ElementRef<HTMLElement>;

  urlSource = '';
  errorMessage = '';
  isStreamingPlayer = true;
  subscription = new Subscription();
  hls: Hls = null;

  constructor(private localStorageManagementService: LocalStorageManagementService) { }

  ngOnInit() {
    this.initializeStreamingPlayer();
  }

  ngAfterViewInit() {
    this.initializeVideoPlayer();
    this.subscribeToVideoEvents();
  }

  ngOnDestroy() {
    this.cleanupResources();
  }

  private initializeStreamingPlayer() {
    this.isStreamingPlayer = this.properties?.streamPlayer ?? false;
    const playbackController = this.properties?.playBackControl;
    if (playbackController) {
      this.urlSource = this.isStreamingPlayer
        ? `${playbackController.streamUrl}${this.properties.title}.m3u8`
        : playbackController.videoUrl;
    }
  }

  private initializeVideoPlayer() {
    const video = this.getVideoElement();
    if (!video) return;

    if (Hls.isSupported()) {
      this.setupHls(video);
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = this.urlSource;
    } else {
      alert("No se puede reproducir el contenido en tu navegador");
    }
  }

  private setupHls(video: HTMLVideoElement) {
    this.hls = new Hls({
      xhrSetup: (xhr : any) => {
        xhr.setRequestHeader("Authorization", `Bearer ${this.localStorageManagementService.getUserLocalStorage()}`);
      }
    });

    this.hls.on(Hls.Events.ERROR, (_ev: any, data: any)=>{
      if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
        const status = data?.response;
        if (status && status.code) {
          this.errorMessage = this.getErrorMessageForStatusCode(status.code);
          // status.code === 400 && setTimeout(() => this.hls.loadSource(this.urlSource), 5000);
        }
      }
    });

    this.hls.loadSource(this.urlSource);
    this.hls.attachMedia(video);
  }

  private getErrorMessageForStatusCode(statusCode: number): string {
    switch (statusCode) {
      case 400: return 'Hubo un problema con la petición.';
      case 401: return 'No tienes permisos para acceder a este contenido.';
      case 403: return 'No tienes permisos para acceder a este contenido.';
      case 404: return 'No se ha encontrado el contenido solicitado.';
      case 500: return 'Ha ocurrido un error en el servidor.';
      default: return 'La conexión ha fallado, reintenta conectar...';
    }
  }

  private subscribeToVideoEvents() {
    const video = this.getVideoElement();
    if (!video) return;

    this.subscription.add(fromEvent(video, 'loadstart').subscribe(() => this.errorMessage = 'Conectando, por favor espere...'));
    this.subscription.add(fromEvent(video, 'error').subscribe(() => this.errorMessage = 'La conexión ha fallado, reintenta conectar...'));
    this.subscription.add(fromEvent(video, 'stalled').subscribe(() => this.errorMessage = 'La conexión se encuentra estancada, reintentando...'));
    this.subscription.add(fromEvent(video, 'waiting').subscribe(() => {
      this.errorMessage = 'Conexión inestable, espere un momento...';
      video.load();
    }));
    this.subscription.add(fromEvent(video, 'playing').subscribe(() => this.errorMessage = ''));
  }

  private getVideoElement(): HTMLVideoElement | null {
    return this.playerContainer.nativeElement.querySelector('video');
  }

  private cleanupResources() {
    this.subscription.unsubscribe();
    this.closeStreamingConnection();

    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
  }

  closeStreamingConnection() {
    const video = this.getVideoElement();
    if (!video) return;

    video.pause();
    video.removeAttribute('src');
    video.load();
    video.parentElement.removeChild(video);
  }
}
