import { Component, Input } from '@angular/core';
import { IModalBicyclePathModel } from '@types-custom/models/ui/modal-bicycle-path-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
 ** Modal BicyclePath
 ** Render a modal that displays information about Bicycle Path.
 */

@Component({
  selector: 'citizen-modal-bicycle-path',
  templateUrl: './modal-bicycle-path.component.html',
  styleUrls: ['./modal-bicycle-path.component.scss'],
})
export class ModalBicyclePathComponent {
  @Input() properties: IModalBicyclePathModel | undefined;
  Icon = Icon;
}
