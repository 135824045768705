<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar" tabindex="0">
    <div class="pb-1-rem pt-20">
        <div class="d-flex gap-10-px justify-center mt-1-em">
            <sit-incidents-kpi [KPIData]="stacks.totalIncidents"></sit-incidents-kpi>
            <sit-incidents-kpi [KPIData]="stacks.totalAssignedIncidents"></sit-incidents-kpi>
            <sit-incidents-kpi [KPIData]="stacks.totalPendingIncidents"></sit-incidents-kpi>
        </div>
    </div>

    <div class=" d-flex px-1">
        <div class="d-flex full-width py-05 bg-color-background-1 title-card">
            <sit-icon [icon]="Icon.alert_w" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
                      alt="velocímetro icono título rango de velocidad"></sit-icon>
            <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
                Incidentes
            </h3>
        </div>
    </div>

    <div class="list-cards px-1 gap-15" [scrollHandler]="panelActionsDispatcher" *ngIf="incidents.length > 0">
        <br>
        <sit-incidents-kpi *ngFor="let item of this.incidents" [DataItem]="item"></sit-incidents-kpi>
    </div>

</div>
