<div class="d-flex fd-col vh-100-130-px overflow-y-auto custom-scrollbar" tabindex="0">

  <div class="pb-1-rem pt-20">
    <div class="d-flex px-1 count-box">
      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
            <sit-icon [icon]="Icon.AccidentWazeM" [sizeClass]="'icon-size-46'" alt="icono">
            </sit-icon>
            <span class="text-size-32 mt-4">
              {{ this.totalAccidents }}
            </span>
          </span>
          <span class="subject">
            Accidentes
          </span>
        </div>
      </div>

      <div class="container-info separator-line-info indicator-container">
        <div class="element">
          <span class="title">
            <sit-icon [icon]="Icon.ClimateWazeM" [sizeClass]="'icon-size-46'" alt="icono">
            </sit-icon>
            <span class="text-size-32 mt-4">
              {{ this.totalClimate }}
            </span>
          </span>
          <span class="subject">
            Climática
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex px-1 mb-1-em">
    <div class="d-flex full-width py-05 bg-color-background-1 title-card">
      <sit-icon [icon]="Icon.waze" class="text-blue-1 ml-28" [sizeClass]="'icon-size-32'"
        alt="camino icono título rutas principales"></sit-icon>
      <h3 class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center">
        Registro de Alertas
      </h3>
    </div>
  </div>

  <!-- LISTA DE CARDS -->
  <div class="list-cards px-1 gap-15">
    <ng-container *ngFor="let card of cards; let i = index" class="pb-1-rem">

      <div class="text-blue-1 text-weight-700 sit-card">
        <span class="location-title text-color-primary-1 text-size-16 text-weight-500">{{ card?.street }}</span>
        <sit-icon [icon]="Icon.add_w" [sizeClass]="'icon-size-18 cursor-pointer'" alt="icono" (click)="handleCreateIncident(card)"></sit-icon>
        <sit-icon [icon]="Icon.duplicate" [sizeClass]="'icon-size-18 cursor-pointer'" alt="icono" (click)="handleManaheDuplicates(card)"></sit-icon>

        <div class="indicators d-flex fd-row text-weight-600 text-blue-1 justify-space-evenly">
          <div class="container-info separator-line-info indicator-container">
            <div class="element">
              <span class="title">
                {{ card.type }}
              </span>
              <span class="subject">
                Tipo
              </span>
            </div>
          </div>

          <div class="container-info separator-line-info indicator-container">
            <div class="element">
              <span class="title">
                {{ card.subType }}
              </span>
              <span class="subject">
                Subtipo
              </span>
            </div>
          </div>

          <div class="container-info separator-line-info indicator-container">
            <div class="element">
              <span class="title">
                {{card.reliability}}
              </span>
              <span class="subject">
                Fiabilidad
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex footer-box">
          <span class="d-flex"> <sit-icon [icon]="Icon.calendar_outlined_white" [sizeClass]="'icon-size-15'"
              alt="icono"></sit-icon>
            {{ card.pubTime ? card.pubTime : '31/10/2023' }}</span>

          <span class="d-flex"> <sit-icon [icon]="Icon.time" [sizeClass]="'icon-size-15'" alt="icono"></sit-icon> {{
            card.pubTime ? card.pubTime : '01:00 PM' }}</span>

        </div>
      </div>
    </ng-container>
  </div>

</div>