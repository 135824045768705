import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ScrollHandlingEventsEnum } from '@types-custom/models/ui/scroll-model';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[scrollHandler]',
})
export class ScrollHandlerDirective {
  @Input() scrollHandler!: BehaviorSubject<
    ScrollHandlingEventsEnum | undefined
  >;

  constructor(private elementRef: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll($event: Event) {
    if (this.elementRef) {
      const clientHeight = this.elementRef.nativeElement.clientHeight;
      const scrollHeight = this.elementRef.nativeElement.scrollHeight;
      const scrollTop = this.elementRef.nativeElement.scrollTop;

      const currentScrollValue = scrollHeight - clientHeight;
      const isAtBottom = Math.round(scrollTop) >= Math.trunc(currentScrollValue);

      if (isAtBottom) {
        this.scrollHandler.next(ScrollHandlingEventsEnum.AT_BOTTOM);
      }
    }
  }
}
