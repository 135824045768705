import { tap } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { IncidentsService } from '@shared/services/layer-service/incidents-service/incidents.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';

import { GeoApiService } from '@shared/services/geo-api-service/geo-api.service';
import { GeoAPITypeEnum } from '@shared/models/geo-api-type.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { SituationTypeFilterEnum,
        SituationPriorityFilterEnum,
} from '@shared/models/situations.model';
import { DatePipe } from '@angular/common';
import { GeometryTypeEnum, MarkerModelBase } from '@types-custom/models/business/marker.model';


@Component({
  selector: "map-filter-heat-map-incidents",
  templateUrl: "./map-filter-heat-map-incidents.component.html",
  styleUrls: ["./map-filter-heat-map-incidents.component.scss"],
})
export class MapFilterHeatMapIncidentsComponent implements OnInit {

  @Input() properties: any;

  situationTypeFilterEnum = SituationTypeFilterEnum;
  situationPriorityFilterEnum = SituationPriorityFilterEnum;
  incidentTypeControl = new FormControl('');
  priorityControl = new FormControl('');

  incidentTypeList: any[] = [
      {
        name: SituationTypeFilterEnum.Incidente_en_via,
        value: SituationTypeFilterEnum.Incidente_en_via
      },
      {
        name: SituationTypeFilterEnum.Manifestacion,
        value: SituationTypeFilterEnum.Manifestacion
      },
      {
        name: SituationTypeFilterEnum.Semaforización,
        value: SituationTypeFilterEnum.Semaforización
      },
      {
        name: SituationTypeFilterEnum.Siniestro,
        value: SituationTypeFilterEnum.Siniestro
      },
      {
        name: SituationTypeFilterEnum.Transporte_masivo,
        value: SituationTypeFilterEnum.Transporte_masivo
      },
      {
        name: SituationTypeFilterEnum.Varado,
        value: SituationTypeFilterEnum.Varado
      }
    ];

  incidentPriorityList: any[] = [
      {
        name: SituationPriorityFilterEnum.low,
        value: SituationPriorityFilterEnum.low
      },
      {
        name: SituationPriorityFilterEnum.medium,
        value: SituationPriorityFilterEnum.medium
      },
      {
        name: SituationPriorityFilterEnum.high,
        value: SituationPriorityFilterEnum.high
      },
      {
        name: SituationPriorityFilterEnum.highest,
        value: SituationPriorityFilterEnum.highest
      },
    ];

  filterCategoryOptions:string[];
  defaultDateStart = (new Date(new Date().setHours(0,0,0,0))).toISOString();
  defaultDateEnd = (new Date().toISOString());
  Icon=Icon;
  incidentsTypes: [] = [];
  incidentForm: FormGroup;

  constructor(
    private incidentsService: IncidentsService,
    private geoApiService: GeoApiService,
    private datePipe:DatePipe,
  ) {}

  public clearFilter() {
    this.incidentTypeControl.setValue('')
    this.priorityControl.setValue('')
    this.defaultDateStart = (new Date(new Date().setHours(0,0,0,0))).toISOString();
    this.defaultDateEnd = (new Date().toISOString());
  }

  ngOnInit() {
    this.incidentsService.getFilter().subscribe((data) => {
      if(data) {
        this.incidentTypeControl.setValue(data[0]);
        this.priorityControl.setValue(data[1]);
        this.defaultDateStart = (new Date(data[2])).toISOString();
        this.defaultDateEnd = (new Date(data[3])).toISOString();
      }
    });
  }

  get startDate() {
    return this.incidentForm.get('dateInit');
  }

  get endDate() {
    return this.incidentForm.get('dateEnd');
  }

  reloadData(response: any) {
    const points = {
      type: GeometryTypeEnum.Point,
      markers: [] as unknown as MarkerModelBase[],
      dataFeatures: [] as Array<any>,
    };
    const heatMapLayer = response[0];

    heatMapLayer.marker.forEach((mark: any) => {
      const feature = {
        properties: (mark as any).classProperties,
        geometry: {
          type:  (mark as any).geometry.type,
          coordinates: (mark as any).geometry.coordinates,
        },
        type: 'Feature',

      };
      points.markers.push(mark);
      points.dataFeatures.push(feature as never);
    });

    this.properties.mapFilterDispatcher$.next({
      layer: this.properties.layer,
      filter: undefined,
      source: points.dataFeatures,
      visibility:true
    });

    document.querySelector<HTMLElement>('#closeFilter').click();
    setTimeout(() => {
      document.querySelector<HTMLElement>('#closeFilter').parentElement.classList.add('applied-filter');
      document.querySelector('.marker-active-situations-layer')?.classList.remove('marker-active-situations-layer');
    }, 10);
  }

  applyFilter() {
    const initialFilterValue: {[key: string]: any} = {};
    let priorityFormattedFilter = ((this.priorityControl.value || []) as unknown as Array <string>).join('&priority=') ;
    priorityFormattedFilter = priorityFormattedFilter ? `&priority=${priorityFormattedFilter}`:priorityFormattedFilter;
    let incidentFormattedFilter = ((this.incidentTypeControl.value || []) as unknown as Array <string>).join('&category=') ;
    incidentFormattedFilter = incidentFormattedFilter ? `&category=${incidentFormattedFilter}`:incidentFormattedFilter;
    this.incidentsService.setFilter([this.incidentTypeControl.value,this.priorityControl.value,this.defaultDateStart,this.defaultDateEnd])

    const url =`dateStart=${this.datePipe.transform(this.defaultDateStart, 'yyyy-MM-dd HH:mm:ss')}&dateEnd=${this.datePipe.transform(this.defaultDateEnd, 'yyyy-MM-dd HH:mm:ss')}${priorityFormattedFilter}${incidentFormattedFilter}`;
    this.geoApiService.getIncidentsHeatMapList(GeoAPITypeEnum.IncidentsHeatMap, true, url)
    .subscribe({
      next: this.reloadData.bind(this),

    });
  }

  updateSelection(control: AbstractControl, event: any, item: any) {
    const selectedItemsControl = control;
    const selectedItems = selectedItemsControl?.value || [];
    if (event.target.checked) {
      selectedItems.push(item.value);
    } else {
      const index = selectedItems.indexOf(item.value);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    selectedItemsControl?.setValue(selectedItems, { emitEvent: false });
  }

  selectedCheck(control: AbstractControl, item: any): boolean {
    return control.value.includes(item.value);
  }
}
