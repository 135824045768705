import { Component, OnInit } from '@angular/core';
import { ModalNoticeService } from '@shared/services/modal-notice-service/modal-notice.service';

@Component({
  selector: 'citizen-modal-data-treatment',
  templateUrl: './modal-data-treatment.component.html',
  styleUrls: ['./modal-data-treatment.component.scss'],
})
export class ModalDataTreatmentComponent implements OnInit {
  htmlSnippet = ``;

  constructor(private ModalNoticeService: ModalNoticeService) {}

  ngOnInit(): void {
    this.ModalNoticeService.getTextNoticeTreatment().subscribe((data: any) => {
      this.htmlSnippet = data.politics;
    });
  }
}
