import { Component } from '@angular/core';
import { getFooterMenuModel, getFooterMenuModel2, } from '@official/models/constants/footer';
import { generateLeftMenuModel } from '@official/models/constants/left-menu';
import { mapIcons } from '@shared/utils/mappers/geo-marker-map';
import { IMapViewerPropsModel, MapEventTypeEnum, } from '@types-custom/models/ui/map-viewer-model';
import { ActionType, IMenuEvent } from '@types-custom/models/ui/menu.model';
import { MapboxDataService } from '@sdk/services/map-box-data/mapbox-data.service';
import { MapboxInstanceService } from '@sdk/services/mapbox-instance/mapbox-instance.service';
import { MapboxLayoutService } from '@sdk/services/map-box-layout/mapbox-layout.service';
import { markerModalMap } from '@shared/utils/mappers/marker-modal-map';
import { SdkLocationService } from '@sdk/services/sdk-location/sdk-location.service';
import { BehaviorSubject, skip, Subject, take } from 'rxjs';
import { LayersDataMap, MapLayers, } from '@shared/utils/mappers/layers-map.model';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { DynamicFiltersApiService } from './services/situations-api/dynamic-filters-api.service';
import { menuRightPanelMapOfficial } from './models/right-panels-mappers';
import { MenuService } from '@official/services/menu/menu.service';
import { CameraVideoService } from '@shared/services/camera-video/camera-video.service';
import { generateGeoModalMap } from '@shared/utils/mappers/geo-modal-map';
import { mapIconConventionsMap } from '@types-custom/models/ui/map-conventions-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IDynamicSideMenuModel } from '@types-custom/models/ui/dynamic-side-menu';
import { Co2Service } from '@shared/services/layer-service/co2-service/co2.service';
import {
  PanelManagementComponent
} from '@official/pages/map-viewer/components/panel-management/panel-management.component';
import { GeoLocationService } from '@shared/services/geo-location/geo-location.service';
import { AforoService } from '@shared/services/layer-service/aforo-service/aforo.service';
import { AgentsService } from '@shared/services/layer-service/agents-service/agents.service';
import { IncidentsService } from '@shared/services/layer-service/incidents-service/incidents.service';
import { mapFilter } from '@official/models/constants/map-filter';
import { PmvService } from '@shared/services/layer-service/pmv-service/pmv.service';

import { markerRightPanelMap } from '@shared/utils/mappers/marker-right-panel-map';
import { TrafficApiService } from '@shared/services/layer-service/traffic-api-service/traffic-api.service';
import { GeoAPITypeEnum } from '@shared/models/geo-api-type.model';
import {
  LocalStorageManagementService
} from '@shared/services/local-storage-management/local-storage-management.service';
import { RuntDatexService } from '@shared/services/layer-service/runt-datex-service/runt-datex.service';
import { ISideMenu } from '@types-custom/models/ui/side-menu.model';
import { FieldResourcesService } from "@shared/services/layer-service/field-resources/field-resources.service";
import { PanelSocialMediaTextComponent } from './components/panel-social-media-text/panel-social-media-text.component';

@Component({
  selector: 'map-viewer-page',
  templateUrl: './map-viewer-page.component.html',
  styleUrls: ['./map-viewer-page.component.scss'],
  providers: [
    MapboxInstanceService,
    MapboxDataService,
    MapboxLayoutService,
    GeoLocationService,
    SdkLocationService,
    ModalService,
    CameraVideoService,
  ],
})
export class MapViewerPageComponent {
  leftMenuEvents$ = new Subject<IMenuEvent | undefined>();
  rightMenuEvents$ = new Subject<IMenuEvent | undefined>();
  currentLayer$ = new BehaviorSubject<MapLayers | undefined>(undefined);
  footerDynamicEvents$ = new Subject<IMenuEvent | undefined>();
  footerStaticEvents$ = new Subject<IMenuEvent | undefined>();
  showPanelConventions$ = new BehaviorSubject(false);

  panelContentDisparcer$ = new BehaviorSubject<any>(undefined);
  roles: string[];
  mapViewerModel: IMapViewerPropsModel<IMenuEvent, MapLayers> = {
    containerId: 'map',
    dataSource: this.dynamicFiltersApiService,
    // Mappers
    layersDataMap: LayersDataMap,
    menuRightPanelMap: menuRightPanelMapOfficial({
      aforoService: this.aforoDataSource,
      agentsService: this.agentsDataSource,
      incidentsService: this.incidentsDataSource,
      pmvService: this.pmvDataSource,
      trafficApiService: this.trafficApiService,
      runtDatexDataSource: this.runtDatexDataSource,
      fieldResourcesDataSource: this.fieldResourcesDataSource,
      panelContentDisparcer: this.panelContentDisparcer$,
      panelDispatcher: this.menuService.panel$
    }),
    markerModalMap,
    geoModalMap: generateGeoModalMap(
      this.playBackControl,
      this.sensorDataSource,
      this.aforoDataSource,
      this.incidentsDataSource,
      this.trafficApiService,
      this.incidentsDataSource,
      this.trafficApiService,
      this.trafficApiService,
      this.trafficApiService,
      this.runtDatexDataSource,
      this.trafficApiService,
      this.fieldResourcesDataSource,
      this.sensorDataSource,
    ),
    // LeftMenu
    leftMenuEvents$: this.leftMenuEvents$,
    rightMenuEvents$: this.rightMenuEvents$,
    panelContentDispatcher$: this.panelContentDisparcer$,
    iconInfo: generateLeftMenuModel(this.leftMenuEvents$),
    // Footer
    currentLayer$: this.currentLayer$,
    footerDynamicEvents$: this.footerDynamicEvents$,
    footerStaticEvents$: this.footerStaticEvents$,
    footerModel: [
      getFooterMenuModel(this.footerDynamicEvents$, this.currentLayer$),
      getFooterMenuModel2(this.footerStaticEvents$, this.currentLayer$),
    ],
    // Conventions Component
    mapConventionsProps: {
      properties: mapIconConventionsMap.official,
      showPanel$: this.showPanelConventions$,
      showOwnButton: true,
    },
    //
    refreshDispatcher: this.dynamicFiltersApiService.refreshSubject$,
    isSideMenuVisible: this.menuService.isSideMenuVisible$,
    clickInteractionDispatcher: this.menuService.panel$,
    mapEventsMap: this.mapEventsMap,
    mapFilter: mapFilter,
    mapIcons: mapIcons,
    markerRightPanelMap: markerRightPanelMap,
    autoUpdatedLayers: autoUpdatedLayersOfficial,
  };

  constructor(
    private dynamicFiltersApiService: DynamicFiltersApiService,
    private menuService: MenuService,
    private playBackControl: CameraVideoService,
    private sensorDataSource: Co2Service,
    private aforoDataSource: AforoService,
    private agentsDataSource: AgentsService,
    private incidentsDataSource: IncidentsService,
    private pmvDataSource: PmvService,
    private trafficApiService: TrafficApiService,
    private localStorageManagementService: LocalStorageManagementService,
    private runtDatexDataSource: RuntDatexService,
    private fieldResourcesDataSource: FieldResourcesService,
  ) {
    this.setListeners();
    this.setInfoMenu();
  }

  private setInfoMenu(): void {
    const itemsToIgnore = ['visor-mapa', 'close_session'];
    this.menuService.headerMenu$.pipe(skip(1), take(1)).subscribe((e) => {
      if (e.menus) {
        this.mapViewerModel.iconInfo.push(
          ...this.mapHeaderToLateralMenu(
            e.menus.filter((e: any) => !itemsToIgnore.includes(e.action))
          )
        );
      }
    });
  }

  private mapHeaderToLateralMenu(headerMenu: any): any {
    const sideMenu: ISideMenu<any>[] = [];
    let sideMenuItem: ISideMenu<any>;

    for (const menuItem of headerMenu) {
      const group = menuItem.action;

      sideMenuItem = {
        isInfoMenu: true,
        isSubmenu: false,
        tag: menuItem.icon,
        group: group,
        tagSelected: menuItem.icon,
        textInline: menuItem.alt,
        isVisible: true,
        requiredRole: menuItem.requiredRole,
        dispatcher$: this.leftMenuEvents$,
        event: {
          action: menuItem.action,
          actionType: menuItem.actionType,
          returnMap: menuItem.returnPanel,
          returnAction: menuItem.actionPanel,
        },
      };

      sideMenu.push(sideMenuItem);

      if (menuItem.subMenu && menuItem.subMenu.length > 0) {
        for (const subMenuItem of menuItem.subMenu) {
          sideMenuItem = {
            isInfoMenu: true,
            isSubmenu: true,
            tag: subMenuItem.icon,
            group: group,
            tagSelected: subMenuItem.icon,
            textInline: subMenuItem.text ? subMenuItem.text : subMenuItem.alt,
            tooltipTag: subMenuItem.text ? subMenuItem.text : subMenuItem.alt,
            url: subMenuItem.url,
            isVisible: true,
            dispatcher$: this.leftMenuEvents$,
            event: {
              action: subMenuItem.action,
              actionType: subMenuItem.actionType,
              returnMap: subMenuItem.returnPanel,
              returnAction: subMenuItem.actionPanel,
            },
          };
          sideMenu.push(sideMenuItem);
        }
      }
    }
    return sideMenu;
  }

  private setListeners(): void {
    const itemSession = this.mapViewerModel.iconInfo?.find(
      (element) => element.url == 'session'
    );

    if (itemSession) {
      this.localStorageManagementService.userInfo$.subscribe((res) => {
        itemSession.textInline = res?.names;
        this.roles = res?.roles;
      });
    }

  }
  get mapEventsMap(): { [key: string]: IDynamicSideMenuModel } {
    return {
      // mapEventsMap: { [key: string]: IDynamicSideMenuModel } = {
      [MapEventTypeEnum.CAMERAS]: {
        title: 'Gestionar Cámaras',
        alt: 'Gestionar Cámaras',
        subtitle: '(Cámaras)',
        titleIcon: Icon.camera_play_w,
        content: {
          component: PanelManagementComponent,
          data: { properties: { action: MapEventTypeEnum.CAMERAS } },
        },
      },
      [MapEventTypeEnum.SENSORS]: {
        title: 'Gestionar Sensores',
        alt: 'Gestionar Sensores',
        subtitle: '(Sensores)',
        titleIcon: Icon.sensor_w,
        content: {
          component: PanelManagementComponent,
          data: { properties: { action: MapEventTypeEnum.SENSORS } },
        },
      },
      [MapEventTypeEnum.INCIDENTS]: {
        title: 'Gestionar incidentes',
        alt: 'Gestionar incidentes',
        // subtitle: '(Bitacoras)', 
        titleIcon: Icon.incidentsPanelIcon,
        content: {
          component: PanelManagementComponent,
          data: {
            layerDispatcher$: this.rightMenuEvents$,
            properties: { action: MapEventTypeEnum.INCIDENTS }
          },
        },
      },
      [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: {
        title: 'Copiar redes sociales',
        alt: 'Copiar redes sociales',
        // subtitle: '(Bitacoras)',
        titleIcon: Icon.incidentsSocialMediaPanelIcon,
        content: {
          component: PanelSocialMediaTextComponent,
          data: {
            layerDispatcher$: this.rightMenuEvents$,
            properties: { action: MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS}
          },
        },
      },
      [MapEventTypeEnum.PMV]: {
        title: 'Gestionar Panel de Mensajería',
        alt: 'Gestionar Panel de Mensajería',
        subtitle: '(mensajeria)',
        titleIcon: Icon.check_w,
        content: {
          component: PanelManagementComponent,
          data: {
            properties: { action: MapEventTypeEnum.PMV }
          },
        },
      },
      [MapEventTypeEnum.ALERT_WAZE]: {
        content: {
          component: undefined,
          data: {
            layerDispatcher$: this.leftMenuEvents$,
            event: {
              action: MapLayers.AlertsDuplicatesWaze,
              actionType: ActionType.Map
            },
          }
        }
      }
    };
  }
}
const autoUpdatedLayersOfficial = [
  GeoAPITypeEnum.ExodusAndReturnWaze,
  GeoAPITypeEnum.TrafficConcordance,
  GeoAPITypeEnum.TrafficSpeedRange,
  GeoAPITypeEnum.RoadCorridors,
  GeoAPITypeEnum.TrafficJamWaze,
  GeoAPITypeEnum.WazeDataSpeedRange,
  GeoAPITypeEnum.WazeDataCorridors,
  GeoAPITypeEnum.Accident,
  GeoAPITypeEnum.Situation,
  GeoAPITypeEnum.Agents,
  GeoAPITypeEnum.AgentsHistorical,
  GeoAPITypeEnum.Cranes,
  GeoAPITypeEnum.CranesHistorical,
  GeoAPITypeEnum.AlertsWaze,
  GeoAPITypeEnum.Co2,
  GeoAPITypeEnum.Aforo,
  GeoAPITypeEnum.IncidentsLocations,
  GeoAPITypeEnum.IncidentsHeatMap,
  GeoAPITypeEnum.GroundResourceLasthours,
  GeoAPITypeEnum.AlertsDuplicatesWaze,

  // Air Quality
  GeoAPITypeEnum.ResourcesAirQualityPoints,
];
