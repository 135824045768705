import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { createFormModelMap } from '@official/pages/map-viewer/models/managament-panel/create-panel.model';
import {
  MessageCreateSuccess,
  NameManagePanelMap,
} from '@official/pages/map-viewer/models/managament-panel/constant-names-panel';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { Icon } from '@types-custom/models/ui/icon-model';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import { IGenericFormModel, ParametricFormI } from '@types-custom/models/ui/generic-form.model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { MapboxInstanceService } from '@sdk/services/mapbox-instance/mapbox-instance.service';
import { GeoLocationService } from '@shared/services/geo-location/geo-location.service';
import { remapObjectToOneLevel } from '@ui-core/utils/functions/remap-object';

@Component({
  selector: 'create-manage-panel',
  templateUrl: './create-manage-panel.component.html',
  styleUrls: ['./create-manage-panel.component.scss'],
  providers: [MapboxInstanceService],
})
export class CreateManagePanelComponent implements OnInit, AfterViewInit {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  @Input() gridRowData: any;
  @Input() properties: any;

  actionDataForm$ = new BehaviorSubject<any>(undefined);

  Icon = Icon;
  titlePanel = '';
  messageSuccess = '';
  fb = new UntypedFormBuilder();
  createFormGroup = this.fb.group({});
  createFormProps: IGenericFormModel;

  constructor(
    private modalService: ModalService,
    private geoLocationService: GeoLocationService
  ) { }
  ngAfterViewInit(): void {
    this.setFormData(this.gridRowData);
  }

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  sendButtonProps: IButtonModel = {
    label: 'Crear',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'Cancelar',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  ngOnInit(): void {
    this.titlePanel = NameManagePanelMap[this.panelOnManagement];
    this.messageSuccess = MessageCreateSuccess[this.panelOnManagement];
    this.initGenericFormProps();
  }

  initGenericFormProps() {
    const createFormModel = createFormModelMap[this.panelOnManagement];

    this.geoLocationService.clickEventsDispatcher =
      this.properties.clickEventsDispatcher;
    this.geoLocationService.clickInteractionDispatcher =
      this.properties.clickInteractionDispatcher;

    this.createFormProps = {
      formGroup: this.createFormGroup,
      formModel: [...(createFormModel as ParametricFormI[][])],
      serviceDataSource: this.serviceDataSource,
      geolocationDataSource: this.geoLocationService,
      actionDataForm: this.actionDataForm$,
      actionPanel$: this.panelAction$,
      panelOnManagement: this.panelOnManagement,
    };
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  handleSendForm() {
    const dataForm = remapObjectToOneLevel(
      this.createFormGroup.getRawValue(),
      {}
    );
    console.log(dataForm)
    this.serviceDataSource.submitCreateForm(dataForm).subscribe({
      next: (v) => this.handleSuccess(v.id),
      error: () => this.handleError(),
    });
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleSuccess(id?: string): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: `${this.messageSuccess}${(id) ? 'ID: ' + id : ''}` },
      },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#2AC527',
    };
    this.modalService.confirmationModal(modal);
    this.serviceDataSource.init();
    this.onPanelManageAction(this.panelManageActionsEnum.GRID);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-2',
      modalClass: '#FF5B5B',
    };
    this.modalService.confirmationModal(modal);
  }

  handleConfirmation(message?: string): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: {
          infoMessage: `¿Está seguro que desea ${message ?? 'cancelar'}?`,
        },
      },
      blockAutoclose: true,
      headerBackgroundClass: 'bg-color-background-2',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.modalService.confirmationModal(modal);
  }

  validateFormGroup(): void {
    this.createFormGroup.markAllAsTouched();
  }

  setFormData(infoData: any) {
    if (infoData)
      this.actionDataForm$.next(infoData);
  }

}
