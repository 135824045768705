import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ILocationOption } from "@types-custom/models/business/location.model";
import { IClassesAttributes } from "@types-custom/models/ui/button-model";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Component({
  selector: "sit-dropdown-button",
  templateUrl: "./dropdown-button.component.html",
  styleUrls: ["./dropdown-button.component.scss"],
})
export class DropdownButtonComponent implements OnInit {

  @Input() dataSource$:
    | BehaviorSubject<ILocationOption[] | undefined>
    | undefined;
  @Input() label: string | undefined;

  @Input() selection$: BehaviorSubject<any> | undefined;
  @Input() classesAttributes: IClassesAttributes | undefined;
  @Input() filterPosition!: number;
  @Input() filterOnChange!: BehaviorSubject<number>;
  
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();

  _dataSource$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  subscription$ = new Subject();
  matSelectItem = '';

  showDropdown = false;

  selectedItem : string;

  ngOnInit(): void {
    if (this.dataSource$) {
      this.dataSource$
        .pipe(takeUntil(this.subscription$))
        .subscribe((data) => this._dataSource$.next(data));
    }

    if (this.selection$) {
      this.selection$
        .pipe(takeUntil(this.subscription$))
        .subscribe((value) => (this.matSelectItem = value ?? ''));
    }
  }

  ngOnDestroy(): void {
    this.subscription$.next(undefined);
    this.subscription$.complete();
  }


  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  // selectItem(item: any) {
  //   console.log('Has seleccionado:', item);
  //   this.showDropdown = false;
  // }

  onSelectionChange($event: any) {
    this.selection$?.next($event.value);
    this.filterOnChange?.next(this.filterPosition);
    this.changeValue.emit($event.value)
    //this.toggleDropdown()
  }

  setLabel(label: string) {
    this.selectedItem = (this.selectedItem === label) ? null : label;
  }
}
