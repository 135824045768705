import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import { ILocationOption } from '@types-custom/models/business/location.model';
import {
  DynamicFilterTypeEnum,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import { BehaviorSubject } from 'rxjs';

export interface IRightPanelInfoMap {
  endpoints: { [key: number]: any };
  filters: ISdkDynamicFilterModel<any, any>[];
}

const defaultOptionsMapper = (response: any[]): ILocationOption[] => {
  const result: ILocationOption[] = response.map((item) => ({
    label: item.description,
    value: item.cd,
  }));
  return result;
};

const cdNameOptionsMapper = (response: any[]): ILocationOption[] => {
  const result: ILocationOption[] = response.map((item) => ({
    label: item.name,
    value: item.cd,
  }));
  return result;
};

const idNameOptionsMapper = (response: any[]): ILocationOption[] => {
  const result: ILocationOption[] = response.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  return result;
};

const situationsRightPanelData: IRightPanelInfoMap = {
  endpoints: {
    0: (path: string, value?: string): string => `${path}/departments/`,
    1: (path: string, value?: string): string =>
      `${path}/municipalities/department/${value}`,

    2: (path: string, value?: string): string => `${path}/roads/${value}`,
    3: (path: string, value?: string): string => `${path}/agencies/`,
    4: (path: string, value?: string): string => `${path}/situation-type/`,
  },

  filters: [
    {
      key: 'departmentCode',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Departamento',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: defaultOptionsMapper,
    },
    {
      key: 'municipalityCode',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Municipio',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: defaultOptionsMapper,
    },
    {
      key: 'roadCode',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Via',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: idNameOptionsMapper,
    },
    {
      key: 'agencyId',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Agencia',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: idNameOptionsMapper,
    },
    {
      key: 'situationType',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Clase de situación',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: idNameOptionsMapper,
    },
  ],
};

const trafficRightPanelFilters: IRightPanelInfoMap = {
  endpoints: {
    0: (path: string, value?: string): string => `${path}/departments/`,
    1: (path: string, value?: string): string =>
      `${path}/municipalities/department/${value}`,
    2: (path: string, value?: string): string => `${path}/roads/${value}`,
    3: (path: string, value?: string): string => `${path}/agencies/`,
  },
  filters: [
    {
      key: 'departmentCode',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Departamento',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: defaultOptionsMapper,
    },
    {
      key: 'municipalityCode',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Municipio',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: defaultOptionsMapper,
    },
    {
      key: 'roadCode',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Via',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: cdNameOptionsMapper,
    },
    {
      key: 'agencyId',
      type: DynamicFilterTypeEnum.SELECTION,
      label: 'Agencia',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: cdNameOptionsMapper,
    },
  ],
};

const camerasRightPanelData: IRightPanelInfoMap = {
  endpoints: {
    0: (path: string, value?: string): string => `${path}/departments/`,
    1: (path: string, value?: string): string =>
      `${path}/municipalities/department/${value}`,
    2: (path: string, value?: string): string => `${path}/roads/${value}`,
    3: (path: string, value?: string): string => `${path}/agencies/`,
  },
  filters: [
    {
      key: 'cameraId',
      type: DynamicFilterTypeEnum.TEXT,
      label: 'ID Cámara',
      value: new BehaviorSubject(undefined),
      options: new BehaviorSubject(undefined),
      optionsMapper: undefined,
    },
  ],
};

export const mapViewerRightPanelMap: { [key: string]: IRightPanelInfoMap } = {
  [MapLayers.Cameras]: camerasRightPanelData,
  [MapLayers.Situations]: situationsRightPanelData,
  [MapLayers.Traffic]: trafficRightPanelFilters,
};
