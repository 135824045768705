<div class="d-flex fd-row ml-05">
    <div class="d-flex fd-row align-items-center justify-center mr-16">
      <sit-icon
        [icon]="Icon.calendar_w"
        [sizeClass]="'icon-size-24'"
        alt="Fecha icono calendario"
      ></sit-icon>
      <span class="ml-05">{{
        historicalDate || currentDate | date: 'YYYY-MM-dd'
      }}</span>
    </div>
    <div class="d-flex fd-row align-items-center justify-center">
      <sit-icon
        [icon]="Icon.schedule_w"
        [sizeClass]="'icon-size-24'"
        alt="Hora icono reloj"
      ></sit-icon>
      <span class="ml-05">{{
        historicalDate || currentDate | date: 'HH:mm'
      }}</span>
    </div>
  </div>
  <div class="d-flex fd-row align-items-center">
    <sit-icon-button
      (click)="autoPlay()"
      [icon]="autoPlaySlider ? Icon.pause_w : Icon.play_w"
      [colorClass]="'text-white'"
      [sizeClass]="'icon-size-28'"
      alt="Boton reproducir recorrido grúa"
    ></sit-icon-button>
    <mat-slider
      [min]="0"
      [max]="sliderMax"
      [(ngModel)]="sliderValue"
      (ngModelChange)="filter($event === -1 ? 0 : $event - 1)"
    ></mat-slider>
  </div>
  