import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IDocumentTypeModel,
  IUserEditModel,
  IUserManagmentModel,
  userStateList,
} from '@shared/models/user.model';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import {
  ILocationOption,
  ILocationPoint,
} from '@types-custom/models/business/location.model';
import {
  AbstractSdkDynamicFilterDataSource,
  DynamicFilterTypeEnum,
  IDynamicFilterModel,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import {
  AbstractPaginatorDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { StateIndicatorsClassesEnum } from '@types-custom/models/ui/state-indicator-model';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import { IPhysicalResourcesManagmentModel } from '@shared/models/physicalResources.model';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
import { CommonService } from '@shared/services/common-service/common-service.service';
@Injectable({
  providedIn: 'root'
})
export class ManageIncidentsCoordinatorService implements
AbstractPaginatorDataSource<any>,
AbstractSdkDynamicFilterDataSource {
  category$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  categorySelected$ = new BehaviorSubject<string | undefined>(undefined);
  zone$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  zoneSelected$ = new BehaviorSubject<string | undefined>(undefined);
  source$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  sourceSelected$ = new BehaviorSubject<string | undefined>(undefined);
  priority$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  prioritySelected$ = new BehaviorSubject<string | undefined>(undefined);
  subCategory$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  subCategorySelected$ = new BehaviorSubject<string | undefined>(undefined);
  corridor$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  corridorSelected$ = new BehaviorSubject<string | undefined>(undefined);
  gravity$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  gravitySelected$ = new BehaviorSubject<string | undefined>(undefined);

  documentType$ = new BehaviorSubject<IDocumentTypeModel[] | undefined>(
    undefined
  );
  documentTypeSelected$ = new BehaviorSubject<string | undefined>(undefined);
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  private dataSubject = new BehaviorSubject<IPhysicalResourcesManagmentModel[]>([]);
  data$ = this.dataSubject.asObservable();
  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;

  formFiltersValue: any | undefined = undefined;

  urlShared!:string;
  pageShared!:number;
  recordPageShared!:number;

  subCategorys!: ILocationOption[];

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    public commonService: CommonService,
    private sdkDynamicFiltersService: SdkDynamicFiltersService
  ) {}

  // Dynamic filters
  filterList: ISdkDynamicFilterModel<any, any>[] = [

    {
      key: 'id',
      type: DynamicFilterTypeEnum.TEXT,
      label: 'Id incidente',
      value: new BehaviorSubject(undefined),
      searchChange: true
    },
    {
      key: 'classId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Categoria',
      value: new BehaviorSubject(undefined),
      options: this.category$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'zoneId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Zona',
      value: new BehaviorSubject(undefined),
      options: this.zone$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'sourceId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Fuente',
      value: new BehaviorSubject(undefined),
      options: this.source$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'priorityId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Prioridad',
      value: new BehaviorSubject(undefined),
      options: this.priority$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'typeId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Subcategoria',
      value: new BehaviorSubject(undefined),
      options: this.subCategory$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'brokerId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Corredor',
      value: new BehaviorSubject(undefined),
      options: this.corridor$,
      optionsMapper: undefined,
      searchChange: true
    },
    {
      key: 'gravityId',
      type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      label: 'Gravedad',
      value: new BehaviorSubject(undefined),
      options: this.gravity$,
      optionsMapper: undefined,
      searchChange: true
    },
  ];

  dataMap: { [key: number]: any } = {
    0: (): string => `pathState`,
    3: (): string => `pathAgencias`,
  };

  fetchData(
    value: any,
    next: ISdkDynamicFilterModel<any, any>,
    index: number
  ): Observable<any> {
    const path = this.dataMap[index]?.();
    return this.httpClient.get(path);
  }

  // Dynamic filters

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  buildExcelPdfUrl(fileType: string) {
    let urlBase = '';
    if (fileType === fileTypes.EXCEL) {
      if(this.urlShared != '' && this.urlShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/excel?${this.urlShared}`;
      }else if(this.recordPageShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/excel?recordsPerPage=${this.recordPageShared}&page=${this.pageShared}`;
      }else{
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/excel?recordsPerPage=10&page=0`;
      }
    } else if (fileType === fileTypes.PDF) {
      if(this.urlShared != '' && this.urlShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/pdf?${this.urlShared}`;
      }else if(this.recordPageShared != undefined){
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/pdf?recordsPerPage=${this.recordPageShared}&page=${this.pageShared}`;
      }else{
        urlBase = `${this.environment.resourcesUrl}/physical-resources/export/pdf?recordsPerPage=10&page=0`;
      }
    }

    return urlBase;
  }

  buildUrl(dataObject: IPaginatorModel): string {
    const data = dataObject.data;
    let url: string;
    if (data) {
      
      // if(data['classId']){
      //   this.commonService.getSubListCategoriesIncident('Subcategoria',data['classId']).subscribe({
      //     next: (data: any[]) => {
      //       this.subCategorys = data
      //           .filter((item: any) => item)
      //           .map(
      //               (item: any) =>
      //                   ({
      //                     label: item.name,
      //                     value: item.id,
      //                   } as ILocationOption)
      //           );
      //       this.setSubCategory(this.subCategorys);
      //     },
          
      //   });
      // }else{
      //   this.commonService.getListCategoriesIncident('Subcategoria').subscribe({
      //     next: (data: any[]) => {
      //       this.subCategorys = data
      //           .filter((item: any) => item)
      //           .map(
      //               (item: any) =>
      //                   ({
      //                     label: item.name,
      //                     value: item.id,
      //                   } as ILocationOption)
      //           );
      //       this.setSubCategory(this.subCategorys);
      //     },
      //   });
      // }
      
      const id = data['id'];
      const classId = data['classId'];
      const typeId = data['typeId'];
      const zoneId = data['zoneId'];
      const brokerId = data['brokerId'];
      const sourceId = data['sourceId'];
      const gravityId = data['gravityId'];
      const priorityId = data['priorityId'];

      let statesList = [];
      const enabled = data['enabled'];


      this.recordPageShared = dataObject.pageSize;
      this.pageShared = dataObject.page;

      url = `${this.environment.incidentUrl}/incidents/pagination/coordinator?recordsPerPage=${
        dataObject.pageSize
      }&page=${dataObject.page}${id ? '&id=' + id : ''}${
        classId ? '&classId=' + classId  : ''
      }${typeId ? '&typeId=' + typeId  : ''}
       ${zoneId ? '&zoneId=' + zoneId  : ''}
       ${brokerId ? '&brokerId=' + brokerId  : ''}
       ${sourceId ? '&sourceId=' + sourceId  : ''}
       ${priorityId ? '&priorityId=' + priorityId  : ''}
       ${gravityId ? '&gravityId=' + gravityId  : ''}`
       ;
       
      this.urlShared = ''
    } else {
      url = `${this.environment.incidentUrl}/incidents/pagination/coordinator?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}`;
    }
    return url;
  }

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    pageInfo.data = this.selectedData.value; //TODO: review approach
    const url = this.buildUrl(pageInfo);
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.currentPage,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalRecords,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        const data: IPhysicalResourcesManagmentModel[] = response.records.map(
          (item: IPhysicalResourcesManagmentModel) => {
            item.idStateName = {id: item.id,
                               state: item.stateCode};
            return item;
          }
        );

        this.dataSubject.next(data ?? []);
      }),
      tap((response: any) => {
        const data: any[] = this.mapDataToGrid(response.records);
        this.dataSubject.next(data ?? []);
      })
    );
  }

  private mapDataToGrid(data: any[]): any[] {
    return data.map((row) => ({
      ...row,
      stateIndicatorProperties: {
        label: row.enabled == true ? 'Activo' : 'Inactivo',
        stateClass:
          row.enabled == true
            ? StateIndicatorsClassesEnum.active_state
            : StateIndicatorsClassesEnum.inactive_state,
      },
    }));
  }

  generateDynamicFIltersModel(): IDynamicFilterModel {
    const dynamicFiltersModel: IDynamicFilterModel = {
      sdkDynamicFiltersService: this.sdkDynamicFiltersService,
      dataSource: this,
      filtersDispatcher: this.selected$,
      showBttnConventions: false,
      hiddenButtonsFilters: true
    };
    return dynamicFiltersModel;
  }

  public init() {
    this.setSelectedDataSubject(this.selected$);
  }

  public setCategory(categorys: ILocationOption[]) {
    this.category$.next(categorys);
  }
  public setZone(zones: ILocationOption[]) {
    this.zone$.next(zones);
  }
  public setSource(sources: ILocationOption[]) {
    this.source$.next(sources);
  }
  public setPriority(prioritys: ILocationOption[]) {
    this.priority$.next(prioritys);
  }
  public setSubCategory(suCategorys: ILocationOption[]) {
    this.subCategory$.next(suCategorys);
  }
  public setCorridor(corridors: ILocationOption[]) {
    this.corridor$.next(corridors);
  }
  public setGravity(gravitys: ILocationOption[]) {
    this.gravity$.next(gravitys);
  }

  public setDocumentType(documentType: IDocumentTypeModel[]) {
    this.documentType$.next(documentType);
  }

  public getIncidentById(params: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incidents/`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getHumanResourceById(params: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incident-assign-user/human-resources`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getPhysicalResourceById(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getImageResourceById(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/files`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public getHistoricalPhysicalResource(params: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/log`;
    return this.httpClient.get<any>(`${url}/${params}`);
  }

  public updatePhysicalResource(physicalResource: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/`;
    return this.httpClient.put<any>(url, physicalResource);
  }
  
  public updateStateUser(params:any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/change-state`;
    return this.httpClient.put<any>(`${url}?id=${params.id}&stateCode=${params.stateCode}&motive=${params.motive}`,params);
  }

  public createManageIncident(data: any): Observable<any> {
    const url = `${this.environment.incidentUrl}/incidents/coordinator/management`;
    return this.httpClient.post<any>(url, data);
  }

  public bulkLoadPhysicalResources(file: any): Observable<any> {
    const url = `${this.environment.resourcesUrl}/physical-resources/bulk-load/import`;
    return this.httpClient.post<any>(url, file);
  }

  public deleteImagePhysicialResource(id:any) {
    return this.httpClient.delete(`${this.environment.resourcesUrl}/physical-resources/files/${id}`);
  }

  public searhAfterSave(): void {
    this.setSelectedDataSubject(this.selected$);
  }

  public clearData(): void {
    this.filterList.forEach((filter) => {
      filter.value.next(undefined);
    });
  }

  public getUserInfo(params: any): Observable<any> {
    const url = `${this.environment.userUrl}/user/info`;
    return this.httpClient.get<any>(`${url}?email=${params}`);
  }
}
