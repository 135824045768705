import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import { AbstractPanelManagementDataSource } from '@types-custom/models/ui/paginator-model';
import { PanelManageActionsEnum } from '@types-custom/models/ui/panel-manage-model';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PmvService } from '@shared/services/layer-service/pmv-service/pmv.service';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { ModalNoInfoComponent } from '@ui-core/components/modal-no-info/modal-no-info.component';
import { ModalErrorComponent } from '@ui-core/components/modal-error/modal-error.component';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { ModalSuccessComponent } from '@ui-core/components/modal-success/modal-success.component';
import { remapObjectToOneLevel } from '@ui-core/utils/functions/remap-object';
import {
  ButtonClassesEnum,
  IButtonModel,
  IconClassesEnum,
} from '@types-custom/models/ui/button-model';
import { notificationCodeMessage } from '@types-custom/models/ui/panel-edit-pmv.model';

@Component({
  selector: 'edit-program-message-pmv',
  templateUrl: './edit-program-message.component.html',
  styleUrls: ['./edit-program-message.component.scss'],
})
export class EditProgramMessageComponent implements OnInit {
  @Input() serviceDataSource: AbstractPanelManagementDataSource;
  @Input() panelOnManagement: MapEventTypeEnum;
  @Input() panelAction$: BehaviorSubject<PanelManageActionsEnum>;
  @Input() dataselect: any;
  @Input() ip: any;

  messages: any;

  messageSuccess = 'El mensaje se ha editado con éxito';

  get panelManageActionsEnum() {
    return PanelManageActionsEnum;
  }

  Icon = Icon;

  pageForm: FormGroup;
  sendButtonProps: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.primary_1_button,
      spanClass: IconClassesEnum.text_complementary_2,
    },
  };

  cancelButtonProps: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.tertiary_button,
      spanClass: IconClassesEnum.text_white,
    },
  };

  alignline: any = 'center';
  constructor(
    private fb: FormBuilder,
    private pmvService: PmvService,
    private modalService: ModalService
  ) {
    this.pageForm = this.fb.group({
      date: ['', Validators.compose([Validators.required])],
      time: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
    });
  }

  async ngOnInit() {
    this.panelOnManagement = MapEventTypeEnum.PMVEditar;
    await this.GetDataCombos();
    await this.setdata();
  }

  async setdata() {
    const date = new Date(this.pmvService.getdata().date * 1000);

    const day = date.getDate(),
      month = date.getMonth() + 1,
      year = date.getFullYear(),
      hour = date.getHours(),
      min = date.getMinutes();

    this.pageForm
      .get('date')
      .setValue(
        year + '-' + this.padTo2Digits(month) + '-' + this.padTo2Digits(day)
      );
    this.pageForm
      .get('time')
      .setValue(this.padTo2Digits(hour) + ':' + this.padTo2Digits(min));
    this.pageForm
      .get('message')
      .setValue(this.pmvService.getdata().messageObject.messageNumber);
  }

  padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }

  async GetDataCombos() {
    [this.messages] = await Promise.all([
      lastValueFrom(
        this.pmvService.getAllMessagesforcameraip(this.ip.ip, 0, 50)
      ),
    ]);
  }

  onPanelManageAction(action: PanelManageActionsEnum) {
    this.panelAction$.next(action);
  }

  handleSendForm() {
    const dataForm = remapObjectToOneLevel(this.pageForm.value, {});

    const data = this.editdataforsend(dataForm);

    this.pmvService
      .editMessagesScheduleforcameraip(this.ip.ip, data)
      .subscribe({
        next: () => this.handleSuccess(),
        error: (error) => this.handleNotification(error.message),
      });
  }

  editdataforsend(data: any) {
    let timeZone;
    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    const send = data.date + 'T' + data.time;
    const date = new Date(send);
    const tzDate = new Date(
      date.toLocaleString('en-US', { timeZone: timeZone })
    );
    const latest_date = tzDate.getTime();

    const datasend = {
      id: this.pmvService.getdata().id,
      date: latest_date / 1000,
      messageNumber: data.message,
    };

    return datasend;
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();
    this.onPanelManageAction(this.panelManageActionsEnum.SEARCH);
  }

  handleSuccess(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccessComponent,
        data: { message: this.messageSuccess },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
    this.serviceDataSource.init();
    this.onPanelManageAction(this.panelManageActionsEnum.SEARCH);
  }

  handleError(): void {
    const modal: IUniqueModalModel = {
      toRender: { component: ModalErrorComponent },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
  }

  handleConfirmation(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalNoInfoComponent,
        data: { infoMessage: '¿Está seguro que desea cancelar?' },
      },
      headerBackgroundClass: 'bg-color-background-1',
      confirm: this.handleCancelSend.bind(this),
      cancel: () => {
        undefined;
      },
    };
    this.modalService.confirmationModal(modal);
  }

  validateFormGroup(): void {
    this.pageForm.markAllAsTouched();
  }

  handleNotification(notificationCode: notificationCodeMessage) {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: `${(notificationCodeMessage as any)[notificationCode]}`,
        },
      },
      headerBackgroundClass: 'bg-color-background-1',
    };
    this.modalService.confirmationModal(modal);
  }
}
