export enum IncidentsListEnum {
  AGENTES_TRANSITO = '148',
  AGENTES_POLICIA = '149',
  ACTOR = 'Actor',
  APOYO = 'Apoyo',
  CATEGORIA = 'Categoria',
  CANTIDAD_IMPLICADO = 'CantidadImplicado',
  CLASE_ACCIDENTE = 'ClaseAccidente',
  CODIGO_INFRACCION = 'CodigoInfraccion',
  COMUNAS = 'comunas',
  CORREDOR = 'Corredor',
  ENCARGADO_OPERATIVO = 'EncargadoOperativo',
  ESTADO_CANCELACION_SERVICIO = 'EstadoCancelacionServicio',
  ESTADO_ACUERDO_INCIDENTE = 'EstadoAcuerdoIncidente',
  ESTADO_GESTION = 'EstadoGestion',
  ESTADO_INCIDENTE = 'Estado',
  ESTADO_QUEJA = 'EstadoQueja',
  FUENTE = 'Fuente',
  GRAVEDAD = 'Gravedad',
  IMPACTO = 'Impacto',
  IMPLICADO = 'Implicado',
  IMPLICADO_NO = 'ImplicadoNo',
  LOCALIDAD = 'Localidad',
  MEDIO = 'Medio',
  MEDIO_REQUERIMIENTO = 'MedioRequerimiento',
  MOTIVO_CANCELACION_SERVICIO = 'MotivoCancelacionServicio',
  MOVIL_GRUA = 'MovilGrua',
  NUM_LESIONADOS = 'NumLesionados',
  NUM_FALLECIDOS = 'NumFallecidos',
  NUM_VEHICULO_INPLICADO = 'NumVehiculoImplicado',
  OBJETO = 'Objeto',
  ORIENTACION = 'Orientacion',
  PRIORIDAD = 'Prioridad',
  REGISTRO_TURNOS = 'RegistroTurnos',
  REPORTE_REALIZAR = 'ReporteRealizar',
  SEVERIDAD = 'Severidad',
  SITUACION = 'Situacion',
  SUBCATEGORIA = 'Subcategoria',
  TIPO_AUTORIDAD = 'TipoAutoridad',
  TIPO_DANO_SEMAFORO = 'TipoDanoSemaforo',
  TIPO_DANO_SENAL = 'TipoDanoSenal',
  TIPO_GRAVEDAD_ACCIDENTE = 'TipoGravedadAccidente',
  TIPO_INCIDENTE = 'TipoIncidente',
  TIPO_INVOLUCRADOS = 'TipoInvolucrados',
  TIPO_REPORTE_VIA = 'TipoReporteVia',
  TIPO_SEMAFORO = 'TipoSemaforo',
  TIPO_SENAL_REPORTE = 'TipoSenalReporte',
  TIPO_SERVICIO = 'TipoServicio',
  TIPO_SOLICITUD = 'TipoSolicitud',
  ZONA = 'Zona',
}
