<div class="container">
    <div class="d-flex gap-1-rem">
        <div class="stage start">
        <span class="title">
            {{this.properties.location}}
        </span>
            <span class="description">
            Dirección
        </span>
        </div>

        <div class="stage end">
        <span class="title">
            {{this.convertDateFormat(this.properties.keepAlive)}}
        </span>
            <span class="description">
            Última actualización
        </span>
        </div>
    </div>

    <div class="data-space">

        <div class="container">

            <div class="stack-info">
                <sit-icon
                        [icon]="Icon.Temperature"
                        [sizeClass]="'icon-size-40'"
                        alt="Icono Temperatura"
                ></sit-icon>
                <div class="text">
                <span class="title">
                    {{this.properties.temperature}}°C
                </span>
                    <span class="description">
                    Temperatura
                </span>
                </div>
            </div>

            <div class="stack-info">
                <sit-icon
                        [icon]="Icon.Humidity"
                        [sizeClass]="'icon-size-40'"
                        alt="Icono Temperatura"
                ></sit-icon>
                <div class="text">
                <span class="title">
                    {{this.properties.humidity}}%
                </span>
                    <span class="description">
                    Humedad relativa
                </span>
                </div>
            </div>

            <div class="stack-info">
                <sit-icon
                        [icon]="Icon.Pressure"
                        [sizeClass]="'icon-size-40'"
                        alt="Icono Temperatura"
                ></sit-icon>
                <div class="text">
                <span class="title">
                    {{this.properties.pressure}}
                    <span class="mini-text">
                        bar
                    </span>
                </span>
                    <span class="description">
                    Presión atmosférica
                </span>
                </div>
            </div>

        </div>

    </div>

    <div class="indicators-container" *ngIf="Indicators.length > 0">
        <sit-air-quality-indicators
            *ngFor="let item of Indicators"
            [properties]            = "item"
            [active]                = "item.name === ActiveIndicator.name"
            (IndicatorDispatcher)   = "ChangeIndicator($event)"
        >
        </sit-air-quality-indicators>
    </div>

    <div class="graph" *ngIf="Indicators.length > 0">
        <span class="title">
            {{this.ActiveIndicator.name}}
        </span>
        <span class="label">
            Medida
        </span>
        <div class="d-flex full-width speed-range-chart">
            <sit-chart-line
                    [properties]="dataGraphicList"
                    [colorScale]="colorScale"
                    style="width: 100%; height: 13rem"
            >
            </sit-chart-line>
        </div>
    </div>

    <div class="ranges">
        <span class="title">
            Rangos
        </span>
        <div class="content">
            <div class="list">
                <div class="item">
                    <div class="color" style="background-color: #00FFFF"></div>
                    <span> Buena </span>
                </div>
                <div class="item">
                    <div class="color" style="background-color: #00B050"></div>
                    <span> Razonablemente buena </span>
                </div>
                <div class="item">
                    <div class="color" style="background-color: #FFCC00"></div>
                    <span> Regular </span>
                </div>
                <div class="item">
                    <div class="color" style="background-color: #FF3300"></div>
                    <span> Desfavorable </span>
                </div>
                <div class="item">
                    <div class="color" style="background-color: #9A0000"></div>
                    <span> Muy desfavorable </span>
                </div>
                <div class="item">
                    <div class="color" style="background-color: #9A00FF"></div>
                    <span> Extremadamente desfavorable </span>
                </div>
            </div>

            <div class="values">
                <span>
                    1 - 10
                </span>
                <span>
                    11 - 20
                </span>
                <span>
                    21 - 30
                </span>
                <span>
                    31 - 40
                </span>
                <span>
                    41 - 50
                </span>
                <span>
                    51 - 60
                </span>
            </div>
        </div>
    </div>
</div>




