import { Validators } from '@angular/forms';
import { MapEventTypeEnum } from '@types-custom/models/ui/map-viewer-model';
import {
  CodeValidatorNameEnum,
  InputTypeEnum,
  IValidatorModel,
  onlyNumbersValidator,
  ParametricFormI,
  specialCharactersValidator,
} from '@types-custom/models/ui/generic-form.model';
import { SensorOptionTypeEnum } from '@types-custom/models/ui/sensor-option-type-model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';

const sensorsFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
          {
            validator: onlyNumbersValidator,
            validatorCodeName: CodeValidatorNameEnum.ONLY_NUMBERS,
            errorMessage: 'Este campo sólo acepta números enteros.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Nombre sensor',
      formKey: 'name',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Localidad',
      formKey: 'commune',
      listOptionsType: SensorOptionTypeEnum.LOCALITY,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Tipo de sensor',
      formKey: 'sensorType',
      listOptionsType: SensorOptionTypeEnum.TYPE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'status',
      listOptionsType: SensorOptionTypeEnum.STATUS,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
];

const cameraFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
          {
            validator: onlyNumbersValidator,
            validatorCodeName: CodeValidatorNameEnum.ONLY_NUMBERS,
            errorMessage: 'Este campo sólo acepta números enteros.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Nombre camara',
      formKey: 'name',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Localidad',
      formKey: 'commune',
      // listOptionsType: SensorOptionTypeEnum.LOCALITY,
      listOptionsType: IncidentsListEnum.LOCALIDAD,

      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Tipo de camara',
      formKey: 'cameraType',
      listOptionsType: SensorOptionTypeEnum.TYPE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'state',
      listOptionsType: SensorOptionTypeEnum.STATUS,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
];

const variableMessagingFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
          {
            validator: onlyNumbersValidator,
            validatorCodeName: CodeValidatorNameEnum.ONLY_NUMBERS,
            errorMessage: 'Este campo sólo acepta números enteros.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Nombre',
      formKey: 'name',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Localidad',
      formKey: 'commune',
      listOptionsType: SensorOptionTypeEnum.LOCALITY,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Protocolo',
      formKey: 'protocol',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'status',
      listOptionsType: SensorOptionTypeEnum.STATUS,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ],
];

const incidentSearchFormModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.DATETIME,
      label: 'Fecha Inicial',
      formKey: 'beginDate',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
    {
      type: InputTypeEnum.DATETIME,
      label: 'Fecha Final',
      formKey: 'endDate',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
          validators: <IValidatorModel[]>[
          ]
        },
      },
    },
  ],
  [
    {
      type: InputTypeEnum.INPUT,
      label: 'Id',
      formKey: 'id',
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
          validators: [],
        },
        validators: [
          {
            validator: Validators.maxLength(33),
            validatorCodeName: CodeValidatorNameEnum.MAX_LENGTH_VALIDATOR,
            errorMessage: 'Este campo debe tener una longitud máxima de 33.',
          },
          {
            validator: specialCharactersValidator,
            validatorCodeName: CodeValidatorNameEnum.SPECIAL_CHARACTERS,
            errorMessage: 'Los caracteres especiales no estan permitidos.',
          },
          {
            validator: onlyNumbersValidator,
            validatorCodeName: CodeValidatorNameEnum.ONLY_NUMBERS,
            errorMessage: 'Este campo sólo acepta números enteros.',
          },
        ],
      },
    },
  ],
  // [
  //   {
  //     type: InputTypeEnum.SELECT,
  //     label: 'Tipo de Novedad Bitácora',
  //     formKey: 'incidentTypeId',
  //     listOptionsType: IncidentsListEnum.TIPO_INCIDENTE,
  //     conditionalFormMap: searchIncidentTypeMap,
  //     formControl: {
  //       value: '',
  //       options: {
  //         disabled: false,
  //         nonNullable: true,
  //         validators: [],
  //       },
  //     },
  //   },
  // ],
  // [
  //   {
  //     type: InputTypeEnum.SELECT,
  //     label: 'Tipo de Autoridad',
  //     formKey: 'authorityTypeId',
  //     listOptionsType: IncidentsListEnum.TIPO_AUTORIDAD,
  //     conditionalFormMap: searchAuthorityTypeMap,
  //     dynamicInsideControlList: 'agentId',
  //     isOptionalFormInSelfGroup: true,
  //     formControl: {
  //       value: '',
  //       options: {
  //         disabled: false,
  //         nonNullable: true,
  //         validators: [],
  //       },
  //     },
  //   },
  // ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Estado',
      formKey: 'stateId',
      listOptionsType: IncidentsListEnum.ESTADO_INCIDENTE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
          validators: [],
        },
        validators: [
        ],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Categoría',
      formKey: 'classId',
      listOptionsType: IncidentsListEnum.CATEGORIA,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
          validators: [],
        },
        validators: [
        ],
      },
    },
    {
      type: InputTypeEnum.SELECT,
      label: 'Sub-categoría',
      formKey: 'typeId',
      // hiddenControl: true,
      // parentFormKey: "classesId",
      // parentFormName: FormGroupNamesEnum.MAIN,
      listOptionsType: IncidentsListEnum.SUBCATEGORIA,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    }
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Fuente',
      formKey: 'sourceId',
      listOptionsType: IncidentsListEnum.FUENTE,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[],
      },
    },
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Zona',
      formKey: 'zoneId',
      listOptionsType: IncidentsListEnum.ZONA,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
    {
      type: InputTypeEnum.SELECT,
      label: 'Localidad',
      formKey: 'locationName',
      listOptionsType: IncidentsListEnum.LOCALIDAD,
      turnLabelAsValue: true,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    }
  ],
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Prioridad',
      formKey: 'priorityId',
      listOptionsType: IncidentsListEnum.PRIORIDAD,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
      },
    },
  ]
];

export const searchFormModelMap: { [key: string]: ParametricFormI[][] } = {
  [MapEventTypeEnum.SENSORS]: sensorsFormModel,
  [MapEventTypeEnum.CAMERAS]: cameraFormModel,
  [MapEventTypeEnum.INCIDENTS]: incidentSearchFormModel,
  [MapEventTypeEnum.INCIDENTS_SOCIAL_MEDIA_TEXTS]: incidentSearchFormModel,
  [MapEventTypeEnum.PMV]: variableMessagingFormModel,
};
