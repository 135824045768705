import {
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';

/**
 * Renderer
 * Render a container that can be used for render components.
 * Data is mapped 1-1 to de rendered component
 */
@Component({
  selector: 'sit-renderer',
  templateUrl: './renderer.component.html',
  styleUrls: ['./renderer.component.scss'],
})
export class RendererComponent implements OnInit, OnDestroy {
  @Input() toRender: IRendererModel | undefined;

  @ViewChild('containerRef', { read: ViewContainerRef })
  private containerRef?: ViewContainerRef;

  private subscription?: Subscription;

  ngOnInit(): void {
    if (this.toRender) {
      this.subscription = of(this.toRender)
        .pipe(delay(10))
        .subscribe(this.renderElement.bind(this));
    }
  }

  ngOnDestroy(): void {
    this.containerRef?.clear();
    this.subscription?.unsubscribe();
  }

  private renderElement(toRender?: IRendererModel): void {
    if (!toRender?.component || !this.containerRef) return;

    const cmpRef: ComponentRef<unknown> = this.containerRef.createComponent(toRender.component);
    this.mapData(cmpRef, toRender.data);
  }

  private mapData(cmpRef: ComponentRef<any>, data: any): void {
    if (!data || !cmpRef?.instance) return;
    Object.getOwnPropertyNames(data).forEach(
      (attr) => (cmpRef.instance[attr] = data[attr])
    );
  }
}
