import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AbstractSdkDynamicFilterDataSource,
  DynamicFilterTypeEnum,
  IDynamicFilterModel,
  ISdkDynamicFilterModel,
} from '@types-custom/models/ui/dynamic-filter';
import {
  ILocationOption,
  ILocationPoint,
} from '@types-custom/models/business/location.model';
import { SdkDynamicFiltersService } from '@sdk/services/sdk-dynamic-filters/sdk-dynamic-filters.service';
import { IDocumentTypeModel, IUserEditModel, IUserManagmentModel, userStateList } from '@shared/models/user.model';
import {
  AbstractPaginatorDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { IGroundResourceEditModel, IGroundResourceManagmentModel } from './../../../../../../../libs/shared/src/lib/models/ground-resource.model';
import { StateIndicatorsClassesEnum } from '@types-custom/models/ui/state-indicator-model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { fileTypes } from '@types-custom/models/ui/file-types.model';
@Injectable()
export class GroundResourceManageService 
  implements
  AbstractPaginatorDataSource<any>,
  AbstractSdkDynamicFilterDataSource
{
  states$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  statesSelected$ = new BehaviorSubject<string | undefined>(undefined);
  state$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  stateSelected$ = new BehaviorSubject<string | undefined>(undefined);
  typeResource$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  typeResourceSelected$ = new BehaviorSubject<string | undefined>(undefined);
  condition$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  conditionSelected$ = new BehaviorSubject<string | undefined>(undefined);
  asigned$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  asignedSelected$ = new BehaviorSubject<string | undefined>(undefined);
  documentType$ = new BehaviorSubject<IDocumentTypeModel[] | undefined>(undefined);
  documentTypeSelected$ = new BehaviorSubject<string | undefined>(undefined);
  selected$ = new BehaviorSubject<any | undefined>(undefined);
    private dataSubject = new BehaviorSubject<IGroundResourceManagmentModel[]>([]);
    data$ = this.dataSubject.asObservable();
    pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
    selectedData!: BehaviorSubject<ILocationPoint<string> | undefined>;
    groupName$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    jobName$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    statusName$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    indicativoName$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
    formFiltersValue: any | undefined = undefined;


    constructor(
      @Inject('environment') private environment: any,
      private httpClient: HttpClient, 
      private sdkDynamicFiltersService: SdkDynamicFiltersService
    ) {}
    
    
    filterList: ISdkDynamicFilterModel<any, any>[] = [
      {
        key: 'searchValue',
        type: DynamicFilterTypeEnum.TEXT,
        label: 'Buscar',
        value: new BehaviorSubject(undefined),
        searchChange: true
      },
      {
        key: 'statusName',
        type: DynamicFilterTypeEnum.SELECTION_BUTTON,
        label: 'Estado',
        value: new BehaviorSubject(undefined),
        options:this.statusName$,
        optionsMapper:undefined,
        searchChange: true
      },
      {
        key: 'groupName',
        type: DynamicFilterTypeEnum.SELECTION_BUTTON,
        label: 'Grupo',
        value: new BehaviorSubject(undefined),
        options:this.groupName$,
        optionsMapper:undefined,
        searchChange: true
      },
      // {
      //   key: 'jobName',
      //   type: DynamicFilterTypeEnum.SELECTION_BUTTON,
      //   label: 'Cargo',
      //   value: new BehaviorSubject(undefined),
      //   options:this.jobName$,
      //   optionsMapper:undefined,
      //   searchChange: true
      // },
    ];

    dataMap: { [key: number]: any } = {
    };

    fetchData(
      value: any,
      next: ISdkDynamicFilterModel<any, any>,
      index: number
    ): Observable<any> {
      const path = this.dataMap[index]?.();
      return this.httpClient.get(path);
    }

    fetchPageDataDistpacher(selectedData: any) {
      const dataObject: IPaginatorModel = {
        page: 0,
        pageSize: 20,
        data: selectedData,
      };
      this.fetchPageData(dataObject).subscribe();
      
      if(selectedData){
        this.CargarCargosPorGrupos(selectedData)
      }
    }

      setSelectedDataSubject(
      selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
    ): void {
      this.selectedData = selectedData;
      this.selectedData.subscribe((selectedData) =>
        this.fetchPageDataDistpacher(selectedData)
      );
    
    }

    buildUrl(dataObject: IPaginatorModel): string {
      const data = dataObject.data;
      let url: string;
      if (data) {
        const searchValue = data['searchValue'];
        const group = data['groupName'];
        const job = data['jobName'];
        const statud= data['statusName'];
  
        url = `${this.environment.resource}/human-resources/pagination/data?recordsPerPage=${
          dataObject.pageSize
        }&page=${dataObject.page}${searchValue ? '&searchValue=' + searchValue : ''}${
          group ? '&groupIds=' + group : ''
        }${job ? '&jobIds=' + job : ''}${statud ? '&stateIds=' + statud : ''}`;
      } else {
        url = `${this.environment.resource}/human-resources/pagination/data?recordsPerPage=${dataObject.pageSize}&page=${dataObject.page}`;
      }
      return url;
    }

    fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
      pageInfo.data = this.selectedData.value;
      const url = this.buildUrl(pageInfo);
      // const url = 'assets/mock/resource-group-v2.json'
      return this.httpClient.get<any>(url).pipe(
        tap((response: any) => {
          const _pageInfo: IPaginatorModel = {
            page: response.currentPage,
            pageSize: pageInfo.pageSize,
            totalCount: response.totalRecords,
          };
          this.pageInfo.next(_pageInfo);
        }),

        tap((response: any) => {
          const data: IGroundResourceManagmentModel[] = response.records.map(
            (item: IGroundResourceManagmentModel) => {
              item.fullName = `${item.firstName} ${item.lastName}`;
              item.idEditresource = item.id;
              return item;
            }
          );
        
          this.dataSubject.next(data ?? []);
        }),

        tap((response: any) => {
          const data: any[] = this.mapDataToGrid(response.records);
          this.dataSubject.next(data ?? []);
        })
      );
    }

    private mapDataToGrid(data: any[]): any[] {
      return data.map((row) => ({
        ...row,
        stateIndicatorProperties: {
          label: row.statusName == true ? 'Activo' : 'Inactivo',
          stateClass:
            row.enabled == true
              ? StateIndicatorsClassesEnum.active_state
              : StateIndicatorsClassesEnum.inactive_state,
        },
      }));
    }


    
    buildExcelPdfUrl(fileType: string) {
      let urlBase = '';
      if (fileType === fileTypes.EXCEL) {
        urlBase = `${this.environment.resource}/human-resources/export/excel?recordsPerPage=20&page=0`;
      } else if (fileType === fileTypes.PDF) {
        urlBase = `${this.environment.resource}/human-resources/export/pdf?recordsPerPage=20&page=0`;
      }

      if (this.formFiltersValue) {
        Object.keys(this.formFiltersValue).forEach(
          (filterKey: string, index: number) => {
            if (
              filterKey !== undefined &&
              filterKey !== null &&
              filterKey !== ''
            ) {
              if (
                this.formFiltersValue[filterKey] !== undefined &&
                this.formFiltersValue[filterKey] !== null &&
                this.formFiltersValue[filterKey] !== ''
              ) {
                urlBase =
                  urlBase + `&${filterKey}=${this.formFiltersValue[filterKey]}`;
              }
            }
          }
        );
      }

      return urlBase;
    }

    generateDynamicFIltersModel(): IDynamicFilterModel {
      const dynamicFiltersModel: IDynamicFilterModel = {
        sdkDynamicFiltersService: this.sdkDynamicFiltersService,
        dataSource: this,
        filtersDispatcher: this.selected$,
        showBttnConventions: false,
        hiddenButtonsFilters: true,
      };

      return dynamicFiltersModel;
    }

    CargarCargosPorGrupos(group:any):void{

      let cargos1: BehaviorSubject<any[]>;
      const grupo = group.groupName
      
      const filterJobname :ISdkDynamicFilterModel={
        key: 'jobName',
        type: DynamicFilterTypeEnum.SELECTION_BUTTON,
        label: 'Cargo',
        value: new BehaviorSubject(undefined),
        options:this.jobName$,
        optionsMapper: undefined,
        searchChange: true
      }

      if(!this.filterList.some(e => {return e.key === filterJobname.key})) {
        this.filterList.push(filterJobname)
      }
      

      this.getCargosPorGrupo(grupo).subscribe({
        next: (data: any[]) => {
          const jobs = data
              .filter((item: any) => item)
              .map(
                  (item: any) =>
                      ({
                        label: item.name,
                        value: item.id,
                      } as ILocationOption)
              );
          this.setJobs(jobs);
        },
      });   
    
    }

    public init() {
      this.setSelectedDataSubject(this.selected$);
    }
    
    public setStatus(status: ILocationOption[]) {
      this.statusName$.next(status);
    }

    public setGroups(group: ILocationOption[]) {
      this.groupName$.next(group);
    }

    public setJobs(group: ILocationOption[]) {
      this.jobName$.next(group);
    }

    public setDocumentType(documentType: IDocumentTypeModel[]) {
      this.documentType$.next(documentType);
    }

    public getEmail(params: any): Observable<any> {
      const url = `${this.environment.resource}/human-resources/`;
      return this.httpClient.get<any>(`${url}?email=${params}`);
    }
    
    public getgroundResourceById(params: any): Observable<any> {
      const url = `${this.environment.resource}/human-resources/`;
      return this.httpClient.get<any>(`${url}/${params}`);
    }

    
    public updateGrounResource(item:any): Observable<any> {
      const url = `${this.environment.resource}/human-resources/`;
      return this.httpClient.put<IGroundResourceEditModel>(url, item);
    }
    
    public createGroundResource(user: any): Observable<any> {
      const url = `${this.environment.resource}/human-resources/`;
      return this.httpClient.post<any>(url, user);
    }
    
    public findKeycloack(params: any): Observable<any> {
      const url = `${this.environment.authUrl}/user/info?email=`;
      return this.httpClient.get<any>(`${url}${params}`);
    }
    
    public createUserKeycloak(params: any): Observable<any> {
      const url = `${this.environment.authUrl}/user/`;
      return this.httpClient.post<any>(url, params);
    }
    
    public getDataPhysicalResourceById(params: any): Observable<any> {
      const url = `${this.environment.resource}/physical-resources/get-assigned?humanResourceId=`;
      return this.httpClient.get<any>(`${url}${params}`);
    }

    public PhysicalResourceAddHuman(idHuman: any ,idPhysical:any ): Observable<any> {
      const url = `${this.environment.resource}/physical-resources/assign-human-resource?humanResourceId=${idHuman}&physicalResourceId=${idPhysical?idPhysical:''}`;
      const body = ''
      return this.httpClient.patch<any>(url,body );
    }

    public PhysicalResourcenSearchNotAssign(code:any ,idPhysical:any=''): Observable<any> {
      const url = `${this.environment.resource}/physical-resources/search-not-assigned?searchValue=${code}&resourceTypeId=${idPhysical}`;
      return this.httpClient.get<any>(url);
    }
    
    public getCargosPorGrupo(code:any ): Observable<any> {
      const url = `${this.environment.resource}/categories/?typeCode=Cargo&parentId=${code}`;
      return this.httpClient.get<any>(url);
    }

    public getTypeDocument(): Observable<any> {
      const url = `${this.environment.authUrl}/document-type/`;
      return this.httpClient.get<any>(url);
    }

    public searhAfterSave(): void {
      this.setSelectedDataSubject(this.selected$);
    }

    public clearData(): void {
      this.filterList.forEach((filter) => {
        filter.value.next(undefined);
      });
    }

    public findUser(params: any): Observable<any> {
      const url = `${this.environment.resource}/human-resources/`;
      return this.httpClient.get<any>(url, { params: params });
    }
}

function res(value: any): void {
  throw new Error('Function not implemented.');
}
