
import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import { IHorizontalMenuModel } from '@types-custom/models/ui/horizontal-menu.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ActionType } from '@types-custom/models/ui/menu.model';
import { BehaviorSubject, Subject } from 'rxjs';

export const getFooterMenuModel = (
  dispatcherStaticLayers$: Subject<any | undefined>,
  hiddenItem$: BehaviorSubject<any>
): IHorizontalMenuModel => {
  const horizontalStaticLayersMenu: IHorizontalMenuModel = {
    hiddenItem: hiddenItem$,
    menuIcon: Icon.layers_w,
    menuIconSelect: Icon.layers_black,
    styleClass: 'static-layers',
    buttonTooltip: 'Capas Estáticas',
    items: [
      {
        id: MapLayers.BicyclePath,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.bicycles_w,
        itemIconSelected: Icon.bicycles_black,
        alt: 'Ciclorrutas',
        event: {
          action: MapLayers.BicyclePath,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.Cameras,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.camera_play_w,
        itemIconSelected: Icon.camera_play_black,
        alt: 'Cámaras',
        event: {
          action: MapLayers.Cameras,
          actionType: ActionType.Map,
        },
      },
      // {
      //   id: MapLayers.TrafficLight,
      //   dispatcher: dispatcherStaticLayers$,
      //   itemIcon: Icon.traffic_light_w,
      //   itemIconSelected: Icon.traffic_light_black,
      //   alt: 'Semáforos',
      //   event: {
      //     action: MapLayers.TrafficLight,
      //     actionType: ActionType.Map,
      //   },
      // },
  //     {
  //       id: MapLayers.Coi,
  //       dispatcher: dispatcherStaticLayers$,
  //       itemIcon: Icon.coi,
  //       itemIconSelected: Icon.coi_black,
  //       alt: 'COI',
  //       event: {
  //         action: MapLayers.Coi,
  //         actionType: ActionType.Map,
          
  //       },
  //   },
  //   {
  //     id: MapLayers.Coiline,
  //     dispatcher: dispatcherStaticLayers$,
  //     itemIcon: Icon.coi,
  //     itemIconSelected: Icon.coi_black,
  //     alt: 'COI Línea',
  //     event: {
  //       action: MapLayers.Coiline,
  //       actionType: ActionType.Map,
        
  //     },
  // },
/*       {
        id: MapLayers.TrafficManagmentPlans,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.pmt,
        itemIconSelected: Icon.pmv_green,
        alt: 'Planes de Manejo de tránsito',
        event: {
          action: MapLayers.TrafficManagmentPlans,
          actionType: ActionType.Map,
        },
      }, */
      {
        id: MapLayers.StopBus,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.bus_w,
        itemIconSelected: Icon.bus_black,
        alt: 'Estaciones',
        event: {
          action: MapLayers.StopBus,
          actionType: ActionType.Map,
        },
      },
      // {
      //   id: MapLayers.CoosPoint,
      //   dispatcher: dispatcherStaticLayers$,
      //   itemIcon: Icon.coos_w,
      //   itemIconSelected: Icon.coos_black,
      //   alt: 'Coos',
      //   event: {
      //     action: MapLayers.CoosPoint,
      //     actionType: ActionType.Map,
      //   },
      // },
      // {
      //   id: MapLayers.CoosLine,
      //   dispatcher: dispatcherStaticLayers$,
      //   itemIcon: Icon.coos_w,
      //   itemIconSelected: Icon.coos_black,
      //   alt: 'Coos Línea',
      //   event: {
      //     action: MapLayers.CoosLine,
      //     actionType: ActionType.Map,
      //   },
      // },
/*       {
        id: MapLayers.TrafficManagmentPlans,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.pmt,
        itemIconSelected: Icon.pmt_blue,
        alt: 'Planes de Manejo de tránsito',
        event: {
          action: MapLayers.TrafficManagmentPlans,
          actionType: ActionType.Map,
        },
      }, 
      {
        id: MapLayers.Zones,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.check_w,
        itemIconSelected: Icon.zones_blue,
        alt: 'Zonas',
        event: {
          action: MapLayers.Zones,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.RoadRun,
        dispatcher: dispatcherStaticLayers$,
        itemIcon: Icon.street_w,
        itemIconSelected: Icon.roads_blue,
        alt: 'Corredor Vial',
        event: {
          action: MapLayers.RoadRun,
          actionType: ActionType.Map,
        },
      },*/
      
      
    ],
  };

  return horizontalStaticLayersMenu;
};

export const getFooterMenuModel2 = (
  dispatcherDynamicLayers$: Subject<any | undefined>,
  hiddenItem$: BehaviorSubject<any>
): IHorizontalMenuModel => {
  const horizontalDynamicLayersMenu: IHorizontalMenuModel = {
    hiddenItem: hiddenItem$,
    menuIcon: Icon.layers_w,
    menuIconSelect: Icon.layers_black,
    styleClass: 'dynamic-layers',
    buttonTooltip: 'Capas Dinámicas',
    items: [
      {
        id: MapLayers.RoadCorridors,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.general_road_w,
        itemIconSelected: Icon.general_road_black,
        alt: 'Trafico General',
        event: {
          action: MapLayers.RoadCorridors,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.TrafficSpeedRange,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.traffic_arrows_w,
        itemIconSelected: Icon.traffic_arrows_black,
        alt: 'Trafico',
        event: {
          action: MapLayers.TrafficSpeedRange,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.Agents,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.agents_w,
        itemIconSelected: Icon.agents_black,
        alt: 'Agentes localización',
        event: {
          action: MapLayers.Agents,
          actionType: ActionType.Map,
        },
      },

      {
        id: MapLayers.IncidentsLocations,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.alert_w,
        itemIconSelected: Icon.alert_black,
        alt: 'localización Incidentes',
        event: {
          action: MapLayers.IncidentsLocations,
          actionType: ActionType.Map,
        },
      }, 
      {
        id: MapLayers.AlertsWaze,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.waze,
        itemIconSelected: Icon.waze_black,
        alt: 'Waze Alertas',
        event: {
          action: MapLayers.AlertsWaze,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.Vehicles,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.car_w,
        itemIconSelected: Icon.car_black,
        alt: 'localización Vehiculos',
        event: {
          action: MapLayers.Vehicles,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.Cranes,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.crane_w,
        itemIconSelected: Icon.crane_black,
        alt: 'localización Gruas',
        event: {
          action: MapLayers.Cranes,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.Bikes,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.bicycles_w,
        itemIconSelected: Icon.bicycles_black,
        alt: 'Bicicletas',
        event: {
          action: MapLayers.Bikes,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.FieldResourcesLocations,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.ResourcesWhite,
        itemIconSelected: Icon.ResourcesBlack,
        alt: 'Recursos en campo (Localización)',
        event: {
          action: MapLayers.FieldResourcesLocations,
          actionType: ActionType.Map,
        },
      },
      {
        id: MapLayers.ResourcesPredictiveModelLocations,
        dispatcher: dispatcherDynamicLayers$,
        itemIcon: Icon.ClockWhite,
        itemIconSelected: Icon.ClockBlack,
        alt: 'Modelo predictivo (Localización)',
        event: {
          action: MapLayers.ResourcesPredictiveModelLocations,
          actionType: ActionType.Map,
        },
      }

    //   {
    //     id: MapLayers.Situations,
    //     dispatcher: dispatcherDynamicLayers$,
    //     itemIcon: Icon.crane_w,
    //     itemIconSelected: Icon.crane_black,
    //     alt: 'Grúas',
    //     event: {
    //       action: MapLayers.Situations,
    //       actionType: ActionType.Map,
    //     },
    //   },
    //  {
    //     id: MapLayers.Co2,
    //     dispatcher: dispatcherDynamicLayers$,
    //     itemIcon: Icon.sensor_w,
    //     itemIconSelected: Icon.sensor_blue,
    //     alt: 'Sensores Co2',
    //     event: {
    //       action: MapLayers.Co2,
    //       actionType: ActionType.Map,
    //     },
    //   },
    //   {
    //     id: MapLayers.Aforo,
    //     dispatcher: dispatcherDynamicLayers$,
    //     itemIcon: Icon.density_w,
    //     itemIconSelected: Icon.density_blue,
    //     alt: 'Aforo',
    //     event: {
    //       action: MapLayers.Aforo,
    //       actionType: ActionType.Map,
    //     },
    //   },
      // {
      //   id: MapLayers.RuntDatex,
      //   dispatcher: dispatcherDynamicLayers$,
      //   itemIcon: Icon.camera_play_w,
      //   itemIconSelected: Icon.camera_runt_green,
      //   alt: 'Runt - Datex',
      //   event: {
      //     action: MapLayers.RuntDatex,
      //     actionType: ActionType.Map,
      //   },
      // }, 
    ],
  };

  return horizontalDynamicLayersMenu;
};
