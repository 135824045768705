<section>
  <sit-icon-button
    [icon]="icon.point_options_w"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="submenu"
    sizeClass="icon-size-24"
    [alt]="'menú opciones'"
    ></sit-icon-button>

  <mat-menu #submenu>
    <ng-container *ngFor="let option of options">
      <button
        mat-menu-item
        (click)="handleClick(option.dispatcher, props.row, props.key, props.index)"
      >
      {{ option?.text }}
      </button>
    </ng-container>
  </mat-menu>
</section>


