import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AllmessagesforcameraipDTO } from '@shared/models/pmv/DTO/allmessagesforcameraipDTO.model';
import { MessageschedulesforcameraDTO } from '@shared/models/pmv/DTO/messageschedulesforcameraDTO.model';
import { StatusforcameraipDTO } from '@shared/models/pmv/DTO/statusforcameraipDTO.model';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import {
  AbstractPanelManagementDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { ILocationPoint } from '@types-custom/models/business/location.model';
import { IManageVariableMenssagingDataBody } from '@types-custom/models/business/manage-varaible-messaging-form';
import { SensorOptionTypeEnum } from '@types-custom/models/ui/sensor-option-type-model';
import { fileTypes } from '@types-custom/models/ui/file-types.model';

@Injectable({
  providedIn: 'root',
})
export class PmvConsultaService
  implements AbstractPanelManagementDataSource<any>
{
  ExpIp2 = '';

  // ApiBase:string = "https://pmv.movilidad-manizales.com/api/v1/"

  //  ApiBase = "http://localhost:5000/api/v1/"
  ApiBase = 'https://pmv.movilidad-manizales.com/pmv/api/v1/';

  ApiGetStatus = 'status/';
  ApiGetMessagesSchedules = 'schedules?ip=';
  ApiGetAllMessages = 'messages?ip=';
  ApiSaveMessages = 'messages/';
  DeleteSchedules = 'schedules/';
  ipFromecomponent: string;

  private apiSelectOptionsMap: {
    [key: string]: () => Observable<any[]>;
  } = {
    [SensorOptionTypeEnum.LOCALITY]: this.getListLocality.bind(this),
    [SensorOptionTypeEnum.STATUS]: this.getListStatus.bind(this),
  };

  private dataSubject = new BehaviorSubject<any[]>([]);
  data$ = this.dataSubject.asObservable();

  pageInfo = new BehaviorSubject<IPaginatorModel>({ page: 0, pageSize: 10 });
  selectedData!: BehaviorSubject<any | ILocationPoint<string> | undefined>;
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  formFiltersValue: any | undefined = undefined;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  setIp(ip: string) {
    this.ipFromecomponent = ip;
  }

  init(): void {
    this.setSelectedDataSubject(this.selected$);
  }

  fetchPageDataDistpacher(selectedData: any) {
    const dataObject: IPaginatorModel = {
      page: 0,
      pageSize: 10,
      data: selectedData,
    };
    this.fetchPageData(dataObject).subscribe();
  }

  setSelectedDataSubject(
    selectedData: BehaviorSubject<ILocationPoint<string> | undefined>
  ): void {
    this.selectedData = selectedData;
    this.selectedData.subscribe((selectedData) =>
      this.fetchPageDataDistpacher(selectedData)
    );
  }

  // fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
  //   pageInfo.data = this.selectedData.value;

  //   var pagination = `&pageNumber=${pageInfo.page}&pageSize=${pageInfo.pageSize}`
  //   var ip = this.ipFromecomponent

  //   return this.httpClient.get<any>('assets/mock/pmvResponse/messagesScheduleslist.json').pipe(
  //     tap((response: any) => {
  //       const _pageInfo: IPaginatorModel = {
  //         page: response.page,
  //         pageSize: pageInfo.pageSize,
  //         totalCount: response.totalCount,
  //       };
  //       this.pageInfo.next(_pageInfo);
  //     }),
  //     tap((response: any) => {

  //       const data: any[] = response.data;
  //       this.dataSubject.next(data ?? []);
  //     })
  //   );
  // }

  //***********descomentar */

  fetchPageData(pageInfo: IPaginatorModel): Observable<IPaginatorModel> {
    let uri: string;
    const ip = this.ipFromecomponent;
    // const ip = '10.152.225.30';
    if (this.environment.production) {
      const pagination = `&pageNumber=${pageInfo.page}&pageSize=${pageInfo.pageSize}`;
      uri = `${this.ApiBase}${this.ApiGetMessagesSchedules}${ip}${pagination}`;
    } else {
      uri = 'assets/mock/pmvResponse/messagesScheduleslist.json';
    }

    pageInfo.data = this.selectedData.value;

    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        const _pageInfo: IPaginatorModel = {
          page: response.page,
          pageSize: pageInfo.pageSize,
          totalCount: response.totalCount,
        };
        this.pageInfo.next(_pageInfo);
      }),
      tap((response: any) => {
        // response.data.pipe(map((val:any) => { return val.date * 1000}))
        //  response.data.forEach((element:any) => {
        //   element.date *=  1000
        //  });

        const data: any[] = response.data;
        this.dataSubject.next(data ?? []);
      })
    );
  }

  submitCreateForm(formValue: any): Observable<any> {
    const url = `${this.environment.vmsUrl}/vms/`;

    const data: IManageVariableMenssagingDataBody = {
      id: null,
      name: formValue.name,
      address: formValue.address,
      latitude: formValue.point?.[0],
      longitude: formValue.point?.[1],
      communeId: formValue.communeId,
      protocol: formValue.protocol,
      serial: formValue.serial,
      manufacturer: formValue.manufacturer,
      ip: formValue.ip,
      port: formValue.port,
      stateId: formValue.stateId,
      automaticProgramming: formValue.automaticProgramming,
    };

    return this.httpClient.post(url, data);
  }
  submitEditForm(formValue: any): Observable<any> {
    const url = `${this.environment.vmsUrl}/vms/`;

    const data: IManageVariableMenssagingDataBody = {
      id: formValue.id,
      name: formValue.name,
      address: formValue.address,
      latitude: formValue.point?.[0],
      longitude: formValue.point?.[1],
      communeId: formValue.communeId,
      protocol: formValue.protocol,
      serial: formValue.serial,
      manufacturer: formValue.manufacturer,
      ip: formValue.ip,
      port: formValue.port,
      stateId: formValue.stateId,
      automaticProgramming: formValue.automaticProgramming,
    };

    return this.httpClient.put(url, data);
  }

  searchFromFilters(formFiltersValue: any): void {
    this.formFiltersValue = formFiltersValue;
    this.selected$.next(formFiltersValue);
  }

  getSelectOptions(optionType: string) {
    const fn = this.apiSelectOptionsMap[optionType];
    return fn ? fn() : of([]);
  }

  buildExcelPdfUrl(fileType: any): string {
    let urlBase = '';
    if (fileType === fileTypes.EXCEL) {
      urlBase = `${this.ApiBase}schedules/export/excel/${this.ExpIp2}`;
    } else if (fileType === fileTypes.PDF) {
      urlBase = `${this.ApiBase}schedules/export/pdf/${this.ExpIp2}`;
    }

    return urlBase;
  }

  getListStatus(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.environment.camerasUrl}/status/`)
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.status,
          }))
        )
      );
  }

  getListLocality(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        `${this.environment.incidentUrl}/incident-categories/category-code/comunas`
      )
      .pipe(
        map((response) =>
          response.map((item) => ({
            value: `${item.id}`,
            name: item.name,
          }))
        )
      );
  }

  // getStatusforcameraip(ip:string): Observable<StatusforcameraipDTO>{
  //   return this.httpClient.get<StatusforcameraipDTO>('assets/mock/pmvResponse/status.json').pipe(
  //     map((res: StatusforcameraipDTO) => {
  //       return res;
  //     })
  //   )
  // }

  ///***descomentar */
  getStatusforcameraip(ip: string): Observable<StatusforcameraipDTO> {
    let uri: string;
    if (this.environment.production) {
      uri = `${this.ApiBase}${this.ApiGetStatus}${ip}`;
    } else {
      uri = 'assets/mock/pmvResponse/status.json';
    }
    return this.httpClient.get<StatusforcameraipDTO>(uri).pipe(
      map((res: StatusforcameraipDTO) => {
        return res;
      })
    );
  }

  deleteMessagesSchedlue(ip: string, data: any): Observable<any> {
    return this.httpClient.delete(
      `${this.ApiBase}${this.DeleteSchedules}${ip}/${data}`
    );
  }
}
