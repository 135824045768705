import { Component, Input, OnInit } from '@angular/core';
import { EditPhysicalResourceComponent } from '@official/pages/physical-resources-management/edit-physical-resource/edit-physical-resource.component';
import { ModalChangeStatusPhysicalResourceComponent } from '@official/pages/physical-resources-management/modal-change-status-physical-resource/modal-change-status-physical-resource.component';
import { ModalHistoricalStatusPhysicalResourcesComponent } from '@official/pages/physical-resources-management/modal-historical-status-physical-resources/modal-historical-status-physical-resources.component';
import { ModalUnsuscribePhysicalResourcesComponent } from '@official/pages/physical-resources-management/modal-unsuscribe-physical-resources/modal-unsuscribe-physical-resources.component';
import { ManagePhysicalResourcesService } from '@official/services/data-grid-service/manage-physical-resource-service/manage-physical-resources.service';
import {
  IGridTableCellDispatcher,
  IGridTableHeaderModel,
} from '@types-custom/models/ui/grid-table-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { IUniqueModalModel } from '@types-custom/models/ui/modal.model';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: "grid-table-physical-resources",
  templateUrl: "./grid-table-physical-resources.component.html",
  styleUrls: ["./grid-table-physical-resources.component.scss"],
})
export class GridTablePhysicalResourcesComponent implements OnInit{
  @Input() headers: IGridTableHeaderModel[] = [];
  @Input() dataSource: Observable<any> | undefined;
  @Input() dispatcherRow?: Subject<IGridTableCellDispatcher> | undefined;

  Icon = Icon;
  color = "#00C500";

  dataCellType = {
    isNumber: (data: any) => typeof data === 'number',
  };
  
  constructor(private modalService: ModalService,public pyshicalResourcesManagementService: ManagePhysicalResourcesService,){
    
  }
  ngOnInit(): void {
    //console.log(this.headers);
  }

  handleClick(
      dispatcher: Subject<IGridTableCellDispatcher> | undefined,
      row: any,
      key: string | undefined,
      index: number,
      todo?: any
  ): void { console.log(index, key, todo);
    if (dispatcher) {
      const data = {
        row,
        index,
        key,
      };

      dispatcher.next(data);
    }
  }


  getIcon(state:any){
    if(state == 'Activo'){
      return Icon.icon_Activo
    }else if(state == 'Inactivo'){
        return Icon.icon_Inactivo 
    }else if(state == 'Dado de baja'){
        return Icon.icon_Dadodebaja       
    }else{
      return null;
    }
  }

  getIconTipoRecurso(type:any,state:any){
    if(type== 'Moto' && state == 'Excelente'){
      return Icon.e_casco
    }else if(type== 'Moto' && state == 'Bueno'){
        return Icon.g_casco
    }else if(type== 'Moto' && state == 'Regular'){
        return Icon.r_casco     
    }else if(type== 'Moto' && state == 'Malo'){
        return Icon.b_casco       
    }else if(type== 'Radio' && state == 'Excelente'){
        return Icon.e_radio
    }else if(type== 'Radio' && state == 'Bueno'){
        return Icon.g_radio
    }else if(type== 'Radio' && state == 'Regular'){
        return Icon.r_radio
    }else if(type== 'Radio' && state == 'Malo'){
        return Icon.b_radio
    }else if(type== 'Comparendera' && state == 'Excelente'){
        return Icon.e_pda
    }else if(type== 'Comparendera' && state == 'Bueno'){
        return Icon.g_pda
    }else if(type== 'Comparendera' && state == 'Regular'){
        return Icon.r_pda
    }else if(type== 'Comparendera' && state == 'Malo'){
        return Icon.b_pda
    }else if(type== 'Casco' && state == 'Excelente'){
        return Icon.e_casco
    }else if(type== 'Casco' && state == 'Bueno'){
        return Icon.g_casco
    }else if(type== 'Casco' && state == 'Regular'){
        return Icon.r_casco
    }else if(type== 'Casco' && state == 'Malo'){
        return Icon.b_casco 
    }else if(type== 'Patrulla' && state == 'Excelente'){
        return Icon.e_patrulla
    }else if(type== 'Patrulla' && state == 'Bueno'){
        return Icon.g_patrulla
    }else if(type== 'Patrulla' && state == 'Regular'){
        return Icon.r_patrulla
    }else if(type== 'Patrulla' && state == 'Malo'){
        return Icon.b_patrulla 
    }else{
      return null;
    }
  }

  handleClickRow(
      dispatcher: Subject<IGridTableCellDispatcher> | undefined,
      row: any,
      index: number,
      todo?: any
  ): void { console.log(index, todo);
    if (dispatcher) {
      const data = {
        row,
        index
      };

      dispatcher.next(data);
    }
  }

  resolveRenderData(
      toRender: IRendererModel,
      cellData: any,
      key: string
  ): IRendererModel {
    const { component, data } = toRender;
    const renderModel = {
      component,
      data: data ? { ...data, [key]: cellData } : { [key]: cellData },
    };
    return renderModel;
  }

  changeStatusRole(event: any, id: any) {
    
    var card: any = {};

    console.log(event);

    if (event.checked) {
      card.id = id;
      card.icon = "agents";
      card.state = "Activo"
      card.title = "activar"
      card.stateMessage = "activado"
      card.message = "activado"
    } else {
      card.id = id;
      card.icon = "agents";
      card.state = "Inactivo"
      card.title = "inactivar"
      card.stateMessage = "inactivado"
      card.message = "inactivado"
    }

    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-filter',
      footerBackgroundClass: 'bg-color-filter',
      toRender: {
        component: ModalChangeStatusPhysicalResourceComponent,
        data: {
          properties: {
            card,
            icon: Icon.help_w,
            title: card.title,
          },
        },
      },
      hideCancelButton: true,
      leftLineClass: 'bg-color-state-2',
      cancel: () => {
        this.pyshicalResourcesManagementService.init();
        this.modalService.closeConfirmationModal();
      },
    }  

    this.modalService.confirmationModal(modal);

  }

  closeModal() {
    this.pyshicalResourcesManagementService.init();
    this.modalService.closeConfirmationModal();
  }

  unsuscribePhysicalResources(id: any) {
    
    var card: any = {};

    card.id = id;
    card.icon = "agents";
    card.title = "dar de baja"
    card.state = "DadoBaja"
    card.stateMessage = "dado de baja"

    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-filter',
      footerBackgroundClass: 'bg-color-filter',
      toRender: {
        component: ModalUnsuscribePhysicalResourcesComponent,
        data: {
          properties: {
            card,
            icon: Icon.help_w,
            title: card.title,
          },
        },
      },
      hideCancelButton: true,
      leftLineClass: 'bg-color-state-2',
      cancel: () => {
        // this.pyshicalResourcesManagementService.init();
        this.modalService.closeConfirmationModal();
      },
    }  

    this.modalService.confirmationModal(modal);
  }

  historicalStatusPhysicalResources(id: any) {
    
    var card: any = {};

    card.id = id;
    card.icon = "agents";
    card.title = "Histórico del recurso " + id

    this.modalService.addModal({
      component: ModalHistoricalStatusPhysicalResourcesComponent,
      data: {
        properties: {
          card,
          icon: Icon.last_hours_w,
          title: card.title,
          customClass: 'modalHistoricPhysicalResources',
        },
      },
    });
  }

  loadInfoModal(){
    this.modalService.addModal({
      component: ModalChangeStatusPhysicalResourceComponent,
      data: {
        properties: {
          icon: Icon.add_w,
          title: 'modalPhysicalResources'
        },
      },
    });
  }

  public handleEdit(id:any): void {
    var physicalResourceEdited;
    if(id){
      this.pyshicalResourcesManagementService.getPhysicalResourceById(id).subscribe((res:any)=>{
        physicalResourceEdited = res;

        this.modalService.addModal({
          component: EditPhysicalResourceComponent,
          data: {
            properties: {
              icon: Icon.edit_w,
              title: 'EDITAR RECURSO FÍSICO',
              customClass: 'modalPhysicalResourceCreate',
            },
            physicalResourceEdited
          },
        });
      })
    }
  }
}
