import {
    IGeometryModel,
    MarkerModelBase,
  } from '@types-custom/models/business/marker.model';

  export interface IPropsIncidentsHeatMapModel {
    totalIncidents: number;
    relativeWeight: number;
    totalIncidentsOnHexagon: string;

  }
  
  export class IncidentsHeatMapModel extends MarkerModelBase {
    static className = 'IncidentsHeatMapModel';
    classProperties: IPropsIncidentsHeatMapModel;
  
    constructor(props: IPropsIncidentsHeatMapModel, geometry: IGeometryModel) {
      super(geometry, IncidentsHeatMapModel.className);
      this.classProperties = props;
    }
  }