
<div class="sit-card text-blue-1 text-weight-700">
    <ng-container *ngIf="type === 1">
        <div class="d-grid">
            <div class="d-flex mb-1-em pr-5-rem">
                <sit-icon
                        [icon]="Icon.VelocimetroCard10"
                        [sizeClass]="'icon-size-50'"></sit-icon>
                <div class="d-grid text-speed-{{this.SpeedCeil}} text-card">
                    <span class="tittle">{{this.card.speedkmh}}</span>
                    <span class="subtitle">Km/h</span>
                </div>
            </div>
            <span class="description text-white"> Velocidad </span>
        </div>
    </ng-container>

    <ng-container *ngIf="type === 2">
        <div class="d-grid">
            <div class="d-flex mb-1-em pr-5-rem">
                <sit-icon
                        [icon]="Icon.chronometer_blue"
                        [sizeClass]="'icon-size-50'"></sit-icon>
                <div class="d-grid text-card">
                    <span class="tittle">{{this.card.delay}}</span>
                    <span class="subtitle">min</span>
                </div>
            </div>
            <span class="description text-white"> Tiempo de congestión </span>
        </div>
    </ng-container>

    <ng-container *ngIf="type === 3">
        <div class="d-grid">
            <div class="d-flex mt-1-em">
                <sit-icon
                        [icon]="this.icon"
                        [sizeClass]="'icon-size-50'"></sit-icon>
                <div class="d-grid text-level-{{this.card.level}}  text-card">
                    <span class="tittle">{{this.card.level}}</span>
                </div>
            </div>
            <span class="description text-white"> Nivel de congestión </span>
        </div>
    </ng-container>

    <div class="sp-padding">
        <ng-container *ngIf="type === 4">
            <div class="d-grid">
                <div class="d-flex mb-1-em mr-1-em">
                    <sit-icon
                            [icon]="Icon.distance_w"
                            [sizeClass]="'icon-size-50'"></sit-icon>
                    <div class="d-grid text-distance text-card">
                        <span class="tittle">{{this.length}}</span>
                        <span class="subtitle left-2">{{this.measure}}</span>
                    </div>
                </div>
                <span class="description text-white"> Longitud del congestión </span>
            </div>
        </ng-container>
    </div>
</div>