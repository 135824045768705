<button
  matTooltip="{{ alt }}"
  [ngClass]="btnType"
  [tabindex]="tabIndex"
>
  <ng-container *ngIf="iconPath; else materialIcon">
    <img src="{{ iconPath }}" [ngClass]="sizeClass" alt="{{ alt }}" />
  </ng-container>
  <ng-template #materialIcon>
    <mat-icon [ngClass]="[sizeClass, colorClass]" [attr.alt]="alt">{{
      icon
    }}</mat-icon>
    <span class="cdk-visually-hidden" title="{{ alt }}"></span>
  </ng-template>
</button>
