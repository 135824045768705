import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardAgentsModel } from '@types-custom/models/ui/card-agents-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { AgentsService } from '@shared/services/layer-service/agents-service/agents.service';
import { IRendererModel } from '@types-custom/models/ui/renderer.model';
import { ModalAgentsLocationsComponent } from '../modal-agents-locations/modal-agents-locations.component';
import { ModalService } from '@ui-core/services/modal/modal.service';
import { IMapViewerPropsModel } from '@types-custom/models/ui/map-viewer-model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { agentsFromAPIMapper } from '@shared/utils/mappers/geo-api-map';
@Component({
  selector: "panel-agents-number",
  templateUrl: "./panel-agents-number.component.html",
  styleUrls: ["./panel-agents-number.component.scss"],
})
export class PanelAgentsNumberComponent implements OnInit{
  Icon = Icon;
  dataGraphicList: ITimeLineGraphicModel;
  colorScale: any = [{}];
  totalAgents = 0;
  totalAgentsActive = 0;
  totalTransitAgents = 0;
  listAgents : any[] = [];
  @Input() serviceDataSource: any;
  locations: any[] = [];
  props: IMapViewerPropsModel<any, any> = {
    containerId: 'map',
    // Mappers
    layersDataMap: {},
    menuRightPanelMap: {},
    markerModalMap: {},
    geoModalMap: {},
    mapEventsMap: {},
    // DS
    dataSource: {
      getAPIList: (listType: any): Observable<any[]> => of([]),
    },
    // LeftMenu
    leftMenuEvents$: new Subject<any | undefined>(),
    iconInfo: [],
    logoText: undefined,
    // Conventions Component
    mapConventionsProps: undefined,
    // Footer
    currentLayer$: new BehaviorSubject<any | undefined>(undefined),
    footerDynamicEvents$: new Subject<any | undefined>(),
    footerStaticEvents$: new Subject<any | undefined>(),
    footerModel: [],
    refreshDispatcher: undefined,
    mapFilter: undefined,
    mapIcons: [],
    markerRightPanelMap: {},
    autoUpdatedLayers: [],
  };

  constructor(private drawerPanelService: DrawerPanelService,private agentsService: AgentsService,
    private modalService: ModalService) {}

  ngOnInit(): void {

    this.agentsService.getAllAgents().subscribe((res: any) => {
      console.log(res);
      this.totalAgents = res.count;
    });

    this.agentsService.getAgentsGeoJSON().forEach((response: any) => {
      this.listAgents = response.features.map((el: any) => agentsFromAPIMapper(el));
      this.totalAgentsActive = this.listAgents.length ?? 0;
    })

    this.insertDataLocations();

    this.serviceDataSource.getMeasurementAgents().subscribe((res: any) => {
      this.setGraphicAgents(res);
    });

  }

  insertDataLocations(): void{
    this.agentsService.getAgentsNumberGeoJSON().pipe().subscribe((agents:any)=>{
      this.agentsService.getAgentsLocation().pipe().subscribe((list:any)=>{
        
        list.features.forEach((item:any) => {
          item.properties.Qty = (agents.filter((e:any) => {
            return e["locCode"] == item.properties['LocCodigo'];
          })).length  ;
          item.properties.density = Number(item.properties.Qty*1000000 / item.properties.LocArea );
        });

        this.locations = list.features;
      })
    });
  }

  openModal(card: any): void {
    card.title = card.LocNombre;
    card.icon= "agents";
    const toRender: any = {
      component: ModalAgentsLocationsComponent,
      data: {properties:card}
    };

    this.modalService.addModal(toRender);
    
    
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }

  setGraphicAgents(data: any): void {
    const sortedData = data
      .map((e: any) => {
        return { time: ('0' + e.time).slice(-5), value: e.value };
      })
      .sort((a: any, b: any) => a.time.localeCompare(b.time));

      console.log(sortedData);  
      
    this.dataGraphicList = {
      data: sortedData,
      label: 'agentes',
      labelX: 'Horas',
      labelY: 'Agentes activos',
    };
    this.colorScale = [{ minValue: 0, maxValue: 10000, color: '#FF9901' }];
  }
}
