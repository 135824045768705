<div>
    <br>
    <span class="text-white text-weight-500 text-size-18 text-md-size-18 mb-1 ml-16">Grupos</span>
    <div class="buttons mb-1 mt-1-em ml-16">
        <sit-button [buttonProperties]="CentipedeButtonProps"  class="width-12 text-white buttons_align"
                    (click)="OnFilter('Centipede')"
                    [ngClass]="{
    'active-action': validateActiveCentipede}"></sit-button>
        <sit-button [buttonProperties]="HelmetButtonProps" class="width-12 text-white buttons_align"
                    (click)="OnFilter('GuideGroup')"
                    [ngClass]="{
    'active-action': validateActiveGuideGroup}"></sit-button>
        <sit-button [buttonProperties]="SchoolCycleButtonProps"  class="width-12 text-white buttons_align"
                    (click)="OnFilter('CycleSchool')"
                    [ngClass]="{
    'active-action': validateActiveCycleSchool}"></sit-button>
        <sit-button [buttonProperties]="AllButtonProps"  class="width-12 text-white buttons_align"
                    (click)="OnFilter('All')"
                    [ngClass]="{
    'active-action': validateAll}"></sit-button>
    </div>

    <br>

    <mat-form-field class="text-white" appearance="outline" *ngIf="this.locations.length > 0">
        <input
                type="text"
                matInput
                [formControl]="agent"
                [matAutocomplete]="auto"
                placeholder="Buscar"
        />
        <sit-icon-button
                matSuffix
                *ngIf="agent.value"
                (click)="resetFilterLocations()"
                [icon]="Icon.close_w"
                [colorClass]="'text-white'"
                [sizeClass]="'icon-size-20'"
                alt="Icono cerrar para limpiar campo"
        ></sit-icon-button>
        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="filterAlert($event.option.value)"
            [displayWith]="displayFn"
        >
            <mat-option
                    *ngFor="let option of locations"
                    [value]="option"
            >
                {{ option.id }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div class="cleanButton">
        <sit-button [buttonProperties]="CleanButtonProps"  class="width-20 text-white"
                    (click)="resetFilter()"></sit-button>
    </div>
</div>

