<div class="w-auto h-auto d-flex fd-col text-blue text-weight-600 pt-30">
  <div class="card mt-3">
    <span class="text-white">
      Para restablecer su contraseña por favor ingrese su correo electrónico
    </span>

    <div class="container-info d-flex fd-row align-items-center mt-16">
      <div class="mr-8">
        <sit-icon
          [icon]="Icon.email_w"
          alt="icono carta email"
          [sizeClass]="'icon-size-28'"
        ></sit-icon>
      </div>

      <mat-form-field class="textWhite" appearance="outline" [ngClass]="">
        <mat-label class="text-white">Correo electrónico</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Ingrese su correo"
        />
        <!--  <mat-error
          *ngIf="registerFormGroup.controls.email.hasError('required')">
          Campo obligatorio
        </mat-error> -->
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex fd-row justify-end mt-32 mb-16">
    <div class="d-flex fd-row">
      <sit-button
        (click)="(undefined)"
        [disabled]=""
        [buttonProperties]="buttonAttributes"
        class="width-49 mr-16 buttons_align"
      ></sit-button>
    </div>
    <div class="d-flex fd-row">
      <sit-button
        (click)="(undefined)"
        [disabled]=""
        [buttonProperties]="signinButtonProps"
        class="width-49 buttons_align"
      ></sit-button>
    </div>
  </div>
</div>
