import {
    IGeometryModel,
    MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsIncidentsFieldResourcesModel {
    type?                        : string,
    incidentId?                  : number,
    incidentClassId?             : number,
    incidentClassName?           : string,
    incidentTypeId?              : number,
    incidentTypeName?            : string,
    incidentPriorityId?          : number,
    incidentPriorityName?        : string,
    incidentAddress?             : string,
    incidentStatusId?            : number,
    incidentStatusName?          : string,
    incidentTime?                : string,
    incidentSourceId?            : number,
    incidentSourceName?          : string,
    incidentLocalityName?        : string,
    incidentCountsGroup?         : any[] | null,
    resourceId?                  : number,
    resourceFirstName?           : string,
    resourceLastName?            : string,
    resourceDocumentTypeId?      : number,
    resourceDocumentTypeName?    : string,
    resourceDocument?            : string,
    resourceEmail?               : string,
    resourcePhone?               : string,
    resourceStateId?             : number,
    resourceStateName?           : string,
    resourceGroupId?             : number,
    resourceGroupName?           : string,
    resourceIndicative?          : string,
    resourceJobId?               : number,
    resourceJobName?             : string,
    resourceUpdateLocationDate?  : string,
    resourceLocalityName?        : string,
    markerPopupText?             : string,
    icon?                        : unknown
}

export class IncidentsFieldResourcesModel extends MarkerModelBase {
    static className = 'IncidentsFieldResourcesModel';
    classProperties: IPropsIncidentsFieldResourcesModel;

    constructor(props: IPropsIncidentsFieldResourcesModel, geometry: IGeometryModel) {
        super(geometry, IncidentsFieldResourcesModel.className);
        this.classProperties = props;
    }
}