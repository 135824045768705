import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionType } from '@types-custom/models/ui/menu.model';
import { IAction, IActionHandler } from '../action.service';

export interface IMenuPanelActionHandler {
  handleMenuPanelActions(value: string): void;
}

@Injectable()
export class PanelActionHandler
  implements IActionHandler
{
  private menuService: any;

  constructor(
    private router: Router
  ) {}

  public setService(menuService: any): void {
    this.menuService = menuService;
  }

  canHandle(action: IAction): boolean {
    return action.event.actionType === ActionType.Panel;
  }
  handle(action: IAction): void {
    const fn = this.getDefaultActionHandler(action.event.returnAction);
    this.menuService.handleMenuPanelActions(action.event.action);
    fn();
  }

  private getDefaultActionHandler(route: string): any {
    return () => this.navigateToRoute(route);
  }

  navigateToRoute(route: string): void {
    this.router.navigate([route]);
  }
}
