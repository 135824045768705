import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {IModalTrafficModel} from "@types-custom/models/ui/modal-traffic-model";
import {ITimeLineGraphicModel} from "@types-custom/models/ui/chart-line-color-model";
import {DrawerPanelService} from "@ui-core/services/drawer-panel/drawer-panel.service";
import {adjustSpeedometerRotation} from "@ui-core/utils/functions/get-indicator-rotation";
import {IModalRoadCorridors} from "@types-custom/models/ui/modal-road-corridors-model";
import {ISpeedometerModel} from "@types-custom/models/ui/speedometer-model";
import {IncidentsCardModel, IncidentsModel} from "@types-custom/models/ui/incidents-model";

@Component({
  selector: "modal-road-corridors-component",
  templateUrl: "./modal-road-corridors-component.component.html",
  styleUrls: ["./modal-road-corridors-component.component.scss"],
  encapsulation : ViewEncapsulation.None
})
export class ModalRoadCorridorsComponentComponent implements OnInit {
  @Input() properties: IModalRoadCorridors | undefined;
  dataHistory: [] = [];

  avgSpeedProps: ISpeedometerModel = {
    chartType: 4,
    divId: 'avgSpeed',
    descriptionLabel: 'Velocidad promedio',
    unitLabel: 'Km/h',
    labelsClass: 'gap-row-10-px pt-1',
    unitLabelClass: 'text-size-14',
    value: 0,
  };

  avgIncidentsData : IncidentsModel = {
    divId : 'avgIncidents',
    value : 10,
    descriptionLabel : 'Incidentes verficiados',
    labelsClass: 'custom-title',
  }

  dataHistoryIncidents: IncidentsCardModel[] = [];

  dataGraphicList: ITimeLineGraphicModel;
  colorScale = [
    { minValue: 0, maxValue: 10, color: 'red' },
    { minValue: 10, maxValue: 20, color: 'yellow' },
    { minValue: 20, maxValue: 1000, color: 'green' },
  ];

  statusIndicator: any = {};

  actMinTimeIndicator = '-';
  actSecTimeIndicator = '-';
  expMinTimeIndicator = '-';
  expSecTimeIndicator = '-';
  concordanceRotation = 0;
  speedometerRotation = 0;
  infoIndicatorMap: { [key: number]: any } = {
    1: {
      now: 'Lento',
      classNow: 'icon-filter-red',
    },
    2: {
      now: 'Medio',
      classNow: 'icon-filter-yellow',
    },
    3: {
      now: 'Bueno',
      classNow: 'icon-filter-green',
    },
  };

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.drawerPanelService.collapseDrawer(true);
    setTimeout(() => {
      this.dataHistory =
          typeof this.properties.speedRange === 'string'
              ? JSON.parse(this.properties.speedRange as any)
              : this.properties.speedRange;
    });
    this.setIndicator(this.properties);
    this.properties.trafficDataSource
        .getLastMeasurements(this.properties.id,this.properties.isCorridors)
        .subscribe((res) => {
          this.setGraphicData(res);
        });
    this.properties.incidentsDataSource
        .getIncidentsModal(this.properties.id)
        .subscribe((res : any) => {
          this.avgIncidentsData.value = res.length
          this.dataHistoryIncidents = res
        });
  }

  setIndicator(prop: IModalTrafficModel | IModalRoadCorridors): void {
    let sumspeedAvg = 0;
    let totDistance = 0;

    if (!isNaN(+prop.speed)) {
      sumspeedAvg = sumspeedAvg + +prop.speed * (+prop.distance / 1000);
      if (+prop.speed > 0) {
        totDistance = totDistance + +prop.distance / 1000;
      }
    }

    const keyIndicator = this.validationSpeed(+prop.speed);
    this.statusIndicator = this.infoIndicatorMap[keyIndicator];

    const speed = (+this.properties.speed).toFixed(2);

    this.properties.speed =
        Number.parseInt(speed) > 100 ? (100 - Number.parseInt(speed)).toString() : speed.toString();


    this.speedometerRotation = adjustSpeedometerRotation(
        +this.properties.speed
    );

    this.avgSpeedProps.value = Number.parseFloat((sumspeedAvg / totDistance).toFixed(2));

    this.avgSpeedProps.handClockRotation = adjustSpeedometerRotation(
        this.avgSpeedProps.value
    );
  }

  setTimeIndicator(prop: IModalTrafficModel): void {
    const timeAct = this.convertTime(prop.elapsedTime);
    const timeExp = this.convertTime(prop.expectedValue);

    this.actMinTimeIndicator = timeAct.minute;
    this.actSecTimeIndicator = timeAct.second;
    this.expMinTimeIndicator = timeExp.minute;
    this.expSecTimeIndicator = timeExp.second;
  }

  validationSpeed(speed: number): number {
    if (speed < 10) {
      return 1;
    }

    return speed < 20 ? 2 : 3;
  }

  convertTime(seconds: number): any {
    const hour = Math.floor(seconds / 3600);
    const outHour = hour < 10 ? '0' + hour : hour;

    const minute = Math.floor((seconds / 60) % 60);

    const outMinute = minute < 10 ? '0' + minute : minute;

    const second = Math.floor(seconds % 60);
    const outSecond = second < 10 ? '0' + second : second;

    return {
      hour: outHour,
      minute: outMinute,
      second: outSecond,
    };
  }

  setGraphicData(info: any): void {
    const dataGraphic = info
        .map((graph: any) => {
          return {
            time: graph.time,
            value: graph.speed,
          };
        })
        .sort((x: any, y: any) => x.time - y.time);

    this.dataGraphicList = {
      data: dataGraphic,
      label: 'Velocidad',
      labelX: 'Horas',
      labelY: 'km/h',
      colorLine:'#f30000'
    };
  }
}
