import {
  IGeometryLineModel,
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsTrafficModel {
  id: string;
  nameFrom: string;
  nameTo: string;
  distance: string;
  speed: string;
  levelOfService: string;
  concordance: string;
  elapsedTime: number;
  expectedValue: number;
  readTime: string;
  readings: number;
  time: string;
  speedRange: [];
  geometry: unknown;
  apiObj: unknown;
}

export class TrafficModel extends MarkerModelBase {
  static className = 'TrafficModel';
  classProperties: IPropsTrafficModel;

  constructor(
    props: IPropsTrafficModel,
    geometry: IGeometryLineModel | IGeometryModel,
    lat?: number,
    lng?: number
  ) {
    super(geometry, TrafficModel.className, lat, lng);
    this.classProperties = props;
    this.geometry = geometry;
  }
}
