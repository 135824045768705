import {IGeometryModel, MarkerModelBase} from "@types-custom/models/business/marker.model";
import {Icon} from "@types-custom/models/ui/icon-model";

export interface IPropsHistoryGroundResourceModel {
  id: string;
  firstName: string;
  lastName: string;
  documentTypeId : number;
  documentTypeName: string;
  document: string;
  email: string;
  phone: string;
  stateId: number;
  stateName: string;
  groupId: number;
  groupName : string,
  indicative : string,
  jobId : number,
  jobName : string,
  updateLocationDate : string,
  localityName : string
  markerPopupText : string,
  time: string
  icon: unknown;
  updatedDate:string
}


export class HistoryGroundResourceModel extends MarkerModelBase {
    static className = 'HistoryGroundResourceModel';
    classProperties: IPropsHistoryGroundResourceModel;

    constructor(props: IPropsHistoryGroundResourceModel, geometry: IGeometryModel) {
        super(geometry, HistoryGroundResourceModel.className);
        this.classProperties = props;
    }
}