<div class="bg-color-background-1 d-flex mt-2">
  <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
  <div class="d-flex full-width py-05">
    <sit-icon
      alt="Volver"
      [icon]="Icon.arrow_backward_w"
      (click)="onPanelManageAction(panelManageActionsEnum.GRID)"
      class="text-blue-1 ml-28"
      [sizeClass]="'icon-size-24'"
    ></sit-icon>
    <h3
      class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0"
    >
      PMV Editar Mensajes Programados
    </h3>
  </div>
</div>

<div class="bodymessage">
  <grid-page
    [properties]="gridPageProps"
    (gridAction)="handleDownloadGridAction($event)"
  ></grid-page>
</div>
