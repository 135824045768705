import { Component, Input } from '@angular/core';
import { IButtonModel } from '@types-custom/models/ui/button-model';
import { Icon } from '@types-custom/models/ui/icon-model';

/*
** Button
** Render a button with an optional icon.
*/
@Component({
  selector: 'sit-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonProperties: IButtonModel | undefined = undefined;
  @Input() disabled = false;


  readonly Icon = Icon;
}
