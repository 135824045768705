<div class="grid-container text-white text-weight-600">
  <div class="card">
    <span class="text-size-16 text-res">TIPO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal tipo zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.tipo }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">ESTADO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal estado zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ statusZone }}</span>
  </div>

  <div class="card">
    <span class="text-size-16 text-res">CAPACIDAD</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal capacidad zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.capacidad }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">ANCHO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal ancho zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.ancho }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">LARGO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal longitud zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.largo }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">HORARIO INICIO</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal horario zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.horaInicio }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">HORARIO CIERRE</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal horario zona"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.horaFin }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">TARIFA MOTOS</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal zona motocicleta"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.tarifaMoto }}</span>
  </div>
  <div class="card">
    <span class="text-size-16 text-res">TARIFA AUTOS</span>
    <sit-icon
      class="icon-card"
      [icon]="Icon.check_w"
      alt="icono señal zona automóviles"
      [sizeClass]="'icon-size-64 icon-res-mod'"
    ></sit-icon>
    <span class="text-size-16 text-res">{{ properties?.tarifaAuto }}</span>
  </div>
</div>
