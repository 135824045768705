import { Component, Input } from '@angular/core';
import { ICardIncidentsLocationsModel } from '@types-custom/models/ui/cards-incidents-locations';
import { Icon } from '@types-custom/models/ui/icon-model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
import { BehaviorSubject } from 'rxjs';

/*
** Card Road Situations
** Render a modal that displays road situation information.
*/
@Component({
  selector: 'sit-card-road-situations',
  templateUrl: './card-road-situations.component.html',
  styleUrls: ['./card-road-situations.component.scss'],
})
export class CardRoadSituationsComponent {
  @Input() cards:
      | any[] = [];

  Icon=Icon

  constructor(private drawerPanelService: DrawerPanelService) {}

  openModal(card:any): void {

    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(
      card.cardMapLink.markerModel
    );
  }
}
