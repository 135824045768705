<ng-container *ngIf="dataSource | async as data; else loading">

  <div *ngFor="let item of data; let index = index" class="card-info" [class.cursor-pointer]="hasDispatcher()"
    [class.fx-cell-hover]="hasDispatcher()" (click)="handleCardAction(item, index)">

    <ng-container *ngFor="let header of headers">

      <ng-container *ngIf="header.toRender">
        <sit-renderer [toRender]="resolveRenderData(header.toRender, item[header.key], header.key, index, item)" />
      </ng-container>
      <div class="card-info-card">
        <span [classList]="header.headerClass || ''" *ngIf="header.showLabel">{{ header.label }}</span>
        <span class="text-weight-800 text-size-20" [classList]="header.cellClass || ''">
          {{ (header.valueFormatter ? header.valueFormatter(item[header.key]) : item[header.key])}}
        </span>
      </div>

    </ng-container>
  </div>

</ng-container>

<ng-template #loading>
  <sit-spinner />
</ng-template>