<div class="grid-container text-white">
    <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <sit-icon
        [icon]="Icon.crane_w"
        alt="icon grua"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-32'"
      ></sit-icon>
      <span class="text-size-16 text-weight-600">{{
        properties?.typeField }}</span>
      <span class="text-size-16 mt-1">Tipos</span>
    </div>
    <div class="card">
      <!-- <span class="text-size-16">NOMBRE AGENTE</span> -->
      <sit-icon
        [icon]="Icon.chronometer_w"
        alt="gorra agente"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-32'"
      ></sit-icon>
      <span class="text-size-16 text-weight-600">{{
        properties?.speed | number : '1.2-2'}} KM</span>
      <span class="text-size-16 mt-1">Velocidad</span>
    </div>
    <div class="card">
      <!-- <span class="text-size-16">ÚLTIMA LECTURA</span> -->
      <sit-icon
        [icon]="Icon.zoom_w"
        alt="reloj"
        class="mt-05 mb-05"
        [sizeClass]="'icon-size-32'"
      ></sit-icon>
      <!-- <span class="text-size-16">{{
        properties?.updatedDate | date: 'dd/MM/yyyy - HH:mm'
      }}</span> -->
      <span class="text-size-16 text-weight-600">{{
        properties?.location }}</span>
      <span class="text-size-16 mt-1">Ubicación</span>
    </div>
  </div>
  
  <div class="grid-info text-white text-weight-600">
    <div class="info">
      <span class="text-size-16">ID del dispositivo</span>
    </div>
    <div class="info">
      <span class="text-size-16">Última lectura</span>
    </div>
    <div class="detail">
      <span class="text-size-16">{{properties.id}}</span>
    </div>
    <div class="detail">
      <span class="text-size-16">{{
        properties?.time | date: 'dd/MM/yyyy - HH:mm'
      }}</span>
    </div>
  </div>
  