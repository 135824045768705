<div class="text-blue-1 text-weight-700 sit-card" (click)="openModal()">
    <div class="location-title d-flex fd-row pl-1 text-color-primary-1 text-size-16 text-weight-500">
        <span> {{ card?.name }}</span>
    </div>
    <div class="indicators d-grid fd-row text-weight-600 text-blue-1 justify-space-evenly">
        <div class="averageSpeed-indicator d-flex fd-col mt-1 mb-1 align-items-center">
            <div class="container-graphic-speed">
                <sit-speedometer [properties]="avgSpeedProps"></sit-speedometer>
            </div>
        </div>

        <div class="d-flex fd-col mt-1 mb-1 align-items-center">
            <div class="container-graphic-speed">
                <sit-general-card [properties]="this.levelOfService" [size]="'icon-size-34'"></sit-general-card>
            </div>
        </div>

        <div class="d-flex fd-col mt-1 mb-1 align-items-center">
            <div class="container-graphic-speed">
                <sit-general-card [properties]="this.flow" [size]="'icon-size-34'"></sit-general-card>
            </div>
        </div>
    </div>
</div>