import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { agentsHistoricalFromAPIMapper, agentsNumbersFromAPIMapper, cranesNumbersFromAPIMapper } from '@shared/utils/mappers/geo-api-map';
import {
  GeometryTypeEnum,
  ILayerModel,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import {agentGeoJsonMockData} from 'libs/sdk/src/lib/mock/agent-geo-json-data';
import {agentHistoricalGeoJsonMockData} from 'libs/sdk/src/lib/mock/agent-historical-geo-json';
import {agentLastMeasurementsGeoJsonMockData} from 'libs/sdk/src/lib/mock/agent-last-geo-json-data';
import {agentNumberGeoJsonMockData} from 'libs/sdk/src/lib/mock/agent-number-geo-json-data';
import {agentLocationMockData} from 'libs/sdk/src/lib/mock/agent-location-geo-json';
import { of } from 'rxjs';
import { AgentsNumberModel } from '@shared/models/agents-number.model';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  getAgentsGeoJSON(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.agentsUrl}/agents/format-geo-json`
    );
  }

  getMeasurementAgents(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.agentsUrl}/agents-history/last-measurements`
    );
  }

  getGraphicByLocality(locatityName:any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.environment.agentsUrl}/agents-history/graph-by-locality?localityName=${locatityName}`
    );
  }

  getAgentsHistoricalGeoJSON(): Observable<any> {
    // return this.httpClient.get<any>('assets/mock/agentsHistorical.json');
    return this.httpClient.get<any>(
      `${this.environment.agentsUrl}/agents-history/format-geo-json?recordsPerPage=1000&page=0&hours=24`
    );

    // let geojson2 = {'type':'FeatureCollection', 'features':[] as any[]}
    // geojson2['features'] = agentHistoricalGeoJsonMockData.map(function(agent){
    //     let geojsonAgent = {"geometry":{'coordinates':[agent['longitude'],agent['latitude']], 'type':'Point'},
    //                         "properties":agent,
    //                         "type": 'Feature'}
    //     return geojsonAgent
    // })

    // let geojson = new Object({'type': 'FeatureCollection',
    //               'features': agentHistoricalGeoJsonMockData.map( (agent) => {
    //                       let d = new Object();
    //                       d['properties'] = {
    //                         "id": agent.id,
    //                         "DevId": agent.dev_id,
    //                         "TimeStamp": new Date(agent.time_stamp),
    //                         "localidad": agent.localidad,
    //                         "time":  (new Date(agent.time_stamp)).getDate() + '-' + (new Date(agent.time_stamp)).getHours().toString().padStart(2,"0") + (new Date(agent.time_stamp).getMinutes()).toString().padStart(2,"0").substring(0, 1)+"0"
    //                       };
    //                       d['geometry'] = {'type': 'Point', 'coordinates': [agent.longitude, agent.latitude] };
    //                       d['type'] = 'Feature';
    //                       return d;
    //                       })
    //             });
    // return of(geojson2);
  }

  getAgentsNumberGeoJSON(): Observable<any>{
    return this.httpClient.get<any>(
      `${this.environment.agentsUrl}/agents/current`
    );
    // return of(agentNumberGeoJsonMockData);
  }

  getAgentsLocation() {
    return this.httpClient.get(`${this.environment.baseApiUrlPolygons}`)
    // return of(agentLocationMockData);
  }

  getAllAgents(){
    return this.httpClient.get(`${this.environment.agentsUrl}/parameters/?code=AgentTotal`)
  }

  getAgentsMapper(agentsHistorical: any): ILayerModel[] {
    const layer: ILayerModel[] = [];

    const sourceFeatures = agentsHistorical.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            id: String(e.properties.name),
            time: formatDate(e.properties.time, 'YYYY-MM-dd HH:mm:ss', 'en-US'),
            title: formatDate(e.properties.time, 'YYYY-MM-dd HH:mm:ss', 'en-US'),
            icon: Icon.dot_w,
          },
        };
      })
      .sort((a: any, b: any) =>
        a.properties.time.localeCompare(b.properties.time)
      );

    const source: any = { type: 'FeatureCollection', features: sourceFeatures };

    const agentsHistoryPoints = source.features.map((e: any) =>
      agentsHistoricalFromAPIMapper(e)
    );

    const agentsHistoryLines = this.getAgentsLinesHistorical(source).map(
      (e: any) => agentsHistoricalFromAPIMapper(e)
    );

    layer.push({
      marker: agentsHistoryPoints,
      geometryType: GeometryTypeEnum.HeatMap,
      feature: source,
      visibility: true,
    });

    layer.push({
      marker: agentsHistoryPoints,
      geometryType: GeometryTypeEnum.Point,
      feature: source,
      visibility: false,
    });

    layer.push({
      marker: agentsHistoryLines,
      geometryType: GeometryTypeEnum.LineString,
      feature: source,
      visibility: false,
    });

    return layer;
  }

  getAgentsNumberMapper(agentsNumber: any): ILayerModel[] {
    const layer: ILayerModel[] = [];
    

    const sourceFeatures = agentsNumber.features
      .map((e: any) => {
        return {
          ...e,
          properties: {
            ...e.properties,
            id: String(e.properties.LocCodigo),
            markerPopupText: `<img src="../../../../assets/icons/agents_green.svg" class="icon-size-48 mr-2 ml-1" alt=""><img src="../../../../assets/icons/density_blue.svg" class="icon-size-48 ml-05-em" alt=""><br>
            <span class="mr-3-em" style="margin-left:2.1rem">${e.properties.Qty}</span> <span style="margin-left:2.9rem">${e.properties.density.toFixed(2)}</span> <br> 
            <span class="mr-2-em ml-1-em">Activos</span> <span>Elementos km<sup>2</sup></span> <br> <br>
            <span style="display:flex;justify-content:center">${e?.properties?.LocNombre}</span>`,
            icon: Icon.dot_w,
          },
        };
      })

    const source: any = { type: 'FeatureCollection', features: sourceFeatures };


    const agentsHistoryPoints = source.features.map((e: any) =>
      agentsNumbersFromAPIMapper(e)
    );

    const agentsHistoryLines = this.getAgentsLinesNumber(source).map(
      (e: any) => agentsNumbersFromAPIMapper(e)
    );

    layer.push({
      marker: agentsHistoryPoints,
      geometryType: GeometryTypeEnum.MultiPolygon,
      feature: source,
      visibility: true
    });

    // layer.push({
    //   marker: agentsHistoryPoints,
    //   geometryType: GeometryTypeEnum.Point,
    //   feature: source,
    //   visibility: false,
    // });

    // layer.push({
    //   marker: agentsHistoryLines,
    //   geometryType: GeometryTypeEnum.LineString,
    //   feature: source,
    //   visibility: false,
    // });

    return layer;
  }

  getAgentsLinesHistorical(data: any): any[] {
    const agentsIds = [
      ...new Set(data.features.map((e: any) => e.properties.id)),
    ];

    const agentsLineFeatures = [];

    for (const item of agentsIds) {
      const coordinates = data.features
        .filter((e: any) => e.properties.id === item)
        .map((e: any) => e.geometry.coordinates);

      const properties = data.features.filter(
        (e: any) => e.properties.id === item
      )[0].properties;

      const agentsLineFeature = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: GeometryTypeEnum.LineString,
          coordinates: coordinates,
        },
      };
      agentsLineFeatures.push(agentsLineFeature);
    }

    return agentsLineFeatures;
  }

  getAgentsLinesNumber(data: any): any[] {
    const agentsIds = [
      ...new Set(data.features.map((e: any) => e.properties.LocCodigo)),
    ];

    const agentsLineFeatures = [];

    for (const item of agentsIds) {
      const coordinates = data.features
        .filter((e: any) => e.properties.LocCodigo === item)
        .map((e: any) => e.geometry.coordinates);

      const properties = data.features.filter(
        (e: any) => e.properties.LocCodigo === item
      )[0].properties;

      const agentsLineFeature = {
        type: 'Feature',
        properties: properties,
        geometry: {
          type: GeometryTypeEnum.LineString,
          coordinates: coordinates,
        },
      };
      agentsLineFeatures.push(agentsLineFeature);
    }

    return agentsLineFeatures;
  }
}
