import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  locationsA,
  locationsB,
  locationsC,
} from '@sdk/mock/sdk-location';
import {
  AbstractLocationDataSource,
  ILocationOption,
} from '@types-custom/models/business/location.model';
import { BehaviorSubject, take } from 'rxjs';

// TODO: Check for removal
@Injectable()
export class SdkLocationService {
  locationA$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  locationB$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);
  locationC$ = new BehaviorSubject<ILocationOption[] | undefined>(undefined);

  locationSelectedA$ = new BehaviorSubject<string | undefined>(undefined);
  locationSelectedB$ = new BehaviorSubject<string | undefined>(undefined);
  locationSelectedC$ = new BehaviorSubject<string | undefined>(undefined);

  selected$ = new BehaviorSubject<any | undefined>(undefined);
  dataSource!: AbstractLocationDataSource;

  constructor() {
    this.locationSelectedA$.subscribe((locationA) =>
      this.handleAselection(locationA)
    );
    this.locationSelectedB$.subscribe((locationB) =>
      this.handleBselection(locationB)
    );
    this.locationSelectedC$.subscribe((locationC) =>
      this.handleCselection(locationC)
    );
  }

  public fetchLocations(dataSource: AbstractLocationDataSource): void {
    this.dataSource = dataSource;
    this.dataSource.setSelectedDataSubject(this.selected$);
    this.fetchLocationsA();
  }

  private fetchLocationsA() {
    this.dataSource
      .fetchData()
      .pipe(take(1))
      .subscribe({
        next: (locationData) => this.locationA$.next(locationData),
        error: (error) => {
          // TODO: This is mock data in case the API fails, however our mock data doesn't match with the right data from BE.
          this.locationA$.next(locationsA);
          this.handlingError(error);
        },
      });
  }

  private fetchLocationsB(locationAValue: string | undefined): void {
    if (locationAValue) {
      this.dataSource
        ?.fetchData(locationAValue)
        .pipe(take(1))
        .subscribe({
          next: (locationData) => this.locationB$.next(locationData),
          error: (error) => {
            // TODO: This is mock data in case the API fails, however our mock data doesn't match with the right data from BE.
            const filteredLocations = locationsB[locationAValue];
            this.locationB$.next(filteredLocations);
            this.handlingError(error);
          },
        });
    } else {
      this.locationB$.next(undefined);
    }
  }

  private fetchLocationsC(locationBValue: string | undefined) {
    if (locationBValue) {
      // TODO: Analizar si LocationC tiene dependencias con A y B.
      this.dataSource
        ?.fetchData(this.locationA$.value, locationBValue)
        .pipe(take(1))
        .subscribe({
          next: (locationData) => this.locationC$.next(locationData),
          error: (error) => {
            // TODO: This is mock data in case the API fails, however our mock data doesn't match with the right data from BE.
            this.locationC$.next(locationsC(locationBValue));
            this.handlingError(error);
          },
        });
    } else {
      this.locationC$.next(undefined);
    }
  }

  private handleAselection(value: string | undefined): void {
    this.locationSelectedB$.next(undefined);
    this.locationC$.next(undefined);
    this.fetchLocationsB(value);
  }

  private handleBselection(value: string | undefined): void {
    this.locationSelectedC$.next(undefined);
    this.fetchLocationsC(value);
  }

  private handleCselection(value: string | undefined): void {
    // TODO: Clean code
    // const selectedPoint: ILocationPoint = {
    //   locationA: this.locationSelectedA$.value,
    //   locationB: this.locationSelectedB$.value,
    //   locationC: this.locationSelectedC$.value,
    // };
  }

  private handlingError(error: HttpErrorResponse): void {
    //console.log({ error });
  }
}
