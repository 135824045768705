import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { AfterContentInit, AfterViewInit, Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup, ValidationErrors,
  Validators
} from "@angular/forms";
import { atLeastOneRoleValidator, validateEquals } from "@official/pages/user-management/user-edit/register.validator";
import { GroundResourceManageService } from "@official/services/data-grid-service/ground-resource-manage/ground-resource-manage.service";
import { ServiceUserManagmentService } from "@official/services/data-grid-service/user-managment.service";
import { IGroundResourceEditModel } from "@shared/models/ground-resource.model";
import { IRoleModelCheckBox, IUserEditModel, IUserManagmentModel, userStateList } from "@shared/models/user.model";
import { CommonService } from '@shared/services/common-service/common-service.service';
import { ILocationOption } from "@types-custom/models/business/location.model";
import { ButtonClassesEnum, IButtonModel, IClassesAttributes, IconClassesEnum } from "@types-custom/models/ui/button-model";
import { Icon } from "@types-custom/models/ui/icon-model";
import { IUniqueModalModel } from "@types-custom/models/ui/modal.model";
import { ModalErrorComponent } from "@ui-core/components/modal-error/modal-error.component";
import { ModalSuccesUserComponent } from "@ui-core/components/modal-succes-user/modal-succes-user.component";
import { ModalSuccessComponent } from "@ui-core/components/modal-success/modal-success.component";
import { ModalService } from "@ui-core/services/modal/modal.service";
import {BehaviorSubject, Observable, Subscribable, isObservable, map, of, catchError, take, switchMap} from "rxjs";

@Component({
  selector: "cdm-manizales-edit-ground-resource",
  templateUrl: "./edit-ground-resource.component.html",
  styleUrls: ["./edit-ground-resource.component.scss"],
})
export class EditGroundResourceComponent implements OnInit, AfterViewInit   {

  TIME_SEC = 200;

  Icon = Icon;
  buttonSaveAttributes: IButtonModel = {
    label: 'GUARDAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_create,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonEditAttributes: IButtonModel = {
    label: 'EDITAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_create,
      spanClass: 'text-weight-600 text-complementary-2 text-size-16'
    },
  };
  buttonCancelAttributes: IButtonModel = {
    label: 'CANCELAR',
    classAttributes: {
      buttonClass: ButtonClassesEnum.button_cancel,
      spanClass: 'text-weight-600 text-size-16'
    },
  };
  dropdownAttributes: IClassesAttributes = {
    iconClass: IconClassesEnum.blue_icon,
  };

  @Input() groundResourceEdit: any = {};
  @Input() jobName: any = [];
  @Input() groupName: any = undefined;
  @Input() agenciesList: ILocationOption[] = [];


  listjobNames: any[] = [];
  listGroupName: any[] = [];
  listDataKeycloak: any[] = [];

  estado=1;

  registerFormGroup: UntypedFormGroup;
  fb = new UntypedFormBuilder();
  showPass = false;
  showPassConfirm = false;
  passwordEqual = true;
  
  typeDocument: any[] = [];

  getDataResourceKeycloak: any;
  getDataResource: any
  isUserKeycloak: boolean;
  isUserResources: boolean ;
  cargos: any;

  constructor(
      private groundResourceManageService: GroundResourceManageService,
      private modalService: ModalService,
      private commonService:CommonService
  ) {}

  ngOnInit(): void {
    this.init();
  }


  init(): void {
    this.initializeForm();
    this.setDataForm();
    this.setData()
  }

  ngAfterViewInit (){
    this.cargarCargos()
  }

  setData() {
    this.groundResourceManageService.getTypeDocument().subscribe((res: any) => {
      this.typeDocument = res;
    });

    this.commonService.getListHumanResources('Cargo').subscribe((res: any) => {
      this.listjobNames = res;
    });

    this.commonService.getListHumanResources('Grupo').subscribe((res: any) => {
      this.listGroupName = res;
    });

  }


  compareFn(c1: number, c2: number): boolean {
    return c1 && c2 && c1 == c2;
  }

  setDataForm() {
    this.estado = this.groundResourceEdit.id ? this.groundResourceEdit?.stateId : this.getDataResource?.stateId
    if (this.groundResourceEdit && this.groundResourceEdit.id) {
      
      this.registerFormGroup.get('email').clearValidators();
      this.registerFormGroup.get('email').updateValueAndValidity();

      this.registerFormGroup.patchValue({
        firstName: this.groundResourceEdit.firstName,
        lastName: this.groundResourceEdit.lastName,
        email: this.groundResourceEdit.email,
        phone: this.groundResourceEdit.phone,
        document: this.groundResourceEdit.document,
        documentTypeId: this.groundResourceEdit.documentTypeId,
        stateId: this.groundResourceEdit.stateId,
        indicative: this.groundResourceEdit.indicative,
        jobId: this.groundResourceEdit.jobId,
        groupId: this.groundResourceEdit.groupId,
      });

    }
  }

  private initializeForm(): void {
    if(this.groundResourceEdit.id){
      
      const formControls: { [key: string]: any } = {

        firstName: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
            Validators.pattern(
              /^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/
            ),
          ],
        ],
        lastName: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
            Validators.pattern(
              /^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/
            ),
          ],
        ],
        email: [
          { value: '', disabled: false },
        ],
        phone: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(7),
            Validators.pattern('^[0-9]*$'),
          ]
        ],
        document: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(3),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        documentTypeId: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
  
        indicative: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
  
        jobId: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
        groupId: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
      };
      this.registerFormGroup = this.fb.group(formControls);
      this.setDataForm();
    }
    else {
      const formControls: { [key: string]: any } = {

        firstName: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
            Validators.pattern(
              /^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/
            ),
          ],
        ],
        lastName: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
            Validators.pattern(
              /^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-']{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/
            ),
          ],
        ],
        email: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.pattern(/^\S+@\S+\.\S+$/),
            Validators.minLength(10),
            Validators.maxLength(80),
          ],
          [
            this.checkEmailValidatorKeycloak(),
            this.checkEmailValidatorResource()
          ]
        ],
        phone: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(7),
            Validators.pattern('^[0-9]*$'),
          ]
        ],
        document: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(3),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        documentTypeId: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
  
        indicative: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
  
        jobId: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
        groupId: [
          { value: '', disabled: false },
          [
            Validators.required,
          ],
        ],
      };
      this.registerFormGroup = this.fb.group(formControls);
    }
  }

  get phoneErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
      'phone'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 10'
        : form.hasError('minlength')
          ? 'Longitud mínima requerida (7)'
          : form.hasError('pattern')
            ? 'Este campo sólo acepta números enteros'
            : '';
  }

  get nameErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get('firstName') as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 200'
        : form.hasError('minlength')
          ? 'Longitud mínima requerida (7)'
          : form.hasError('pattern')
            ? 'Caracter no permitido'
            : '';
  }

  get lastNameErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
      'lastName'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 200'
        : form.hasError('minlength')
          ? 'Longitud mínima requerida (7)'
          : form.hasError('pattern')
            ? 'Caracter no permitido'
            : '';
  }

  get emailErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
      'email'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 50'
        : form.hasError('minlength')
          ? 'Longitud mínima requerida (7)'
          : form.hasError('pattern')
            ? 'Correo no valido'
            : '';
  }

  get documentErrorMessage(): string {
    const form: FormControl = this.registerFormGroup.get(
      'document'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 20'
        : form.hasError('minlength')
          ? 'Longitud mínima requerida (3)'
          : form.hasError('pattern')
            ? 'Este campo sólo acepta números enteros'
            : '';
  }

  get indicativeErrorMesage(): string {
    const form: FormControl = this.registerFormGroup.get(
      'indicative'
    ) as FormControl;
    return form.hasError('required')
      ? 'Campo obligatorio'
      : form.hasError('maxlength')
        ? 'Este campo debe tener una longitud máxima de 200'
        : form.hasError('minlength')
          ? 'Longitud mínima requerida (3)'
          : '';
  }


  private handlingError(error: any): void {
    this.showModalError('El correo electrónico registrado ya existe ');
    this.modalService.cleanModals$.next([]);
    console.error({ error });
  }

  public checkEmailValidatorKeycloak(): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return new Observable(observer => {
        this.groundResourceManageService.findKeycloack(control.value)
            .pipe(take(1))
            .subscribe(
                res => {
                  if (res.length > 0) {
                    this.showModalError('El correo electrónico registrado ya existe');
                    this.isUserKeycloak = true;
                    this.mapFielsKeyCloack(res);
       
                    observer.next(null);
                  } else {
                    observer.next(null);
                  }
                  observer.complete();
                },
                error => {
                  console.error(error);
                  observer.next(null);
                  observer.complete();
                }
            );
      });
    };
  }
  
  
  public checkEmailValidatorResource(): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {
   
      return new Observable(observer => {
        this.groundResourceManageService.getEmail(control.value)
            .pipe(take(1))
            .subscribe(
              res => {
             
                    if (res) {
                      this.showModalError('El correo electrónico registrado ya existe ');
                      this.isUserResources = true;
                      this.mapFieldsResource(res);
              
                      observer.next(null);
                    } else {
                      observer.next(null);
                    }
          
                  observer.complete();
                },
                error => {
                  console.error(error);
                  observer.next(null);
                  observer.complete();
                }
            );
      });
    };
  }

  mapFieldsResource(res:any){
    this.estado = res.stateId

    this.getDataResource={
      id:res.id,
      userEntityId:res.userEntityId,
      firstName: res.firstName,
      lastName: res.lastName,
      phone: res.phone,
      document: res.document,
      documentTypeId:res.documentTypeId,
      stateId:res.stateId,
      groupId: res.groupId,
      indicative: res.indicative,
      jobId: res.jobId,
    }

  
    this.registerFormGroup.patchValue({
      firstName: res.firstName,
        lastName: res.lastName,
        phone: res.phone,
        document: res.document,
        documentTypeId:res.documentTypeId,
        stateId:res.stateId,
        groupId: res.groupId,
        indicative: res.indicative,
        jobId: res.jobId,
    });
    

  }

  mapFielsKeyCloack(res:any){
  
    this.getDataResourceKeycloak = res.map((item: any) => {
        return {
          userEntityId: item.id,
          firstName: item.name,
          lastName: item.lastName,
          phone: item.phone,
          document: item.document,
          documentTypeId:item.documentTypeId,
        };
    });

    this.registerFormGroup.controls['firstName'].setValue(this.getDataResourceKeycloak[0].firstName);
    this.registerFormGroup.controls['lastName'].setValue(this.getDataResourceKeycloak[0].lastName);
    this.registerFormGroup.controls['phone'].setValue(this.getDataResourceKeycloak[0].phone);
    this.registerFormGroup.controls['documentTypeId'].setValue(this.getDataResourceKeycloak[0].documentTypeId);
    this.registerFormGroup.controls['document'].setValue(this.getDataResourceKeycloak[0].document);

  }

  // vaidateGroundResourceSubmit(): void {//este metodo es para revisar el correo en un nuevo registro
  //   if (!this.groundResourceEdit.id) {
  //     this.groundResourceManageService
  //       .getEmail({ email:this.registerFormGroup.value.email})
  //       .subscribe({
  //         next: (data: Array<unknown>) => {
  //           console.warn(data)
  //           if (data.length > 0) {
  //             this.showModalError('El correo electrónico registrado ya existe');
  //             return;
  //           }
  //
  //           this.onSubmit();
  //         },
  //       });
  //     return;
  //   }
  //
  //   this.onSubmit();
  // }


  onSubmit(): void {
 
    if(this.registerFormGroup){
      //validacion de editar 
      if( (this.isUserResources || this.isUserKeycloak) ||  this.groundResourceEdit.id ) {
    
        const userUpdate: IGroundResourceEditModel = {
          id:this.groundResourceEdit.id ? this.groundResourceEdit.id : this.getDataResource.id,
          userEntityId: this.groundResourceEdit.id ? this.groundResourceEdit.userEntityId : this.getDataResource.userEntityId, 
          email: this.registerFormGroup.controls.email.value,
          documentTypeId: this.registerFormGroup.controls.documentTypeId.value,
          document: this.registerFormGroup.controls.document.value,
          firstName: this.registerFormGroup.controls.firstName.value,
          lastName: this.registerFormGroup.controls.lastName.value,
          stateId: this.estado, 
          phone: this.registerFormGroup.controls.phone.value,
          groupId: this.registerFormGroup.controls.groupId.value,
          indicative: this.registerFormGroup.controls.indicative.value,
          jobId: this.registerFormGroup.controls.jobId.value,
        };
 
        this.groundResourceManageService.updateGrounResource(userUpdate).subscribe({

          next: (response: any) => {
            this.modalService.cleanModals$.next([]);
            setTimeout(() => {
              this.showModalSuccess('El recurso humano fue actualizado exitosamente');
              this.groundResourceManageService.init();
              this.init();
            }, this.TIME_SEC);

          },
          error: (error: any) => this.handlingError(error),

        });
      
      }else if ( !this.isUserResources &&  this.isUserKeycloak ) { //validacion de crear en recurso en campo 

        const registerBody:any  = {
          userEntityId: this.getDataResourceKeycloak[0].userEntityId,
          firstName: this.registerFormGroup.controls.firstName.value,
          lastName: this.registerFormGroup.controls.lastName.value,
          email: this.registerFormGroup.controls.email.value,
          documentTypeId: this.registerFormGroup.controls.documentTypeId.value,
          document: this.registerFormGroup.controls.document.value,
          phone: this.registerFormGroup.controls.phone.value,
          stateId: this.estado,
          groupId: this.registerFormGroup.controls.groupId.value,
          indicative: this.registerFormGroup.controls.indicative.value,
          jobId: this.registerFormGroup.controls.jobId.value,
        }
      
        this.groundResourceManageService.createGroundResource(registerBody).subscribe((res) => {
          this.modalService.cleanModals$.next([]);
          setTimeout(() => {
            this.showModalSuccess('El usuario se ha registrado con éxito');
            this.groundResourceManageService.init();
            this.init();
          }, this.TIME_SEC);
        });

        // way if not have in keycloak
      }else if(!this.isUserResources && !this.isUserKeycloak && !this.groundResourceEdit.id ) {

        const userCreateKeycloak : any = {
          name: this.registerFormGroup.controls.firstName.value,
          lastName: this.registerFormGroup.controls.lastName.value,
          email: this.registerFormGroup.controls.email.value,
          phone: this.registerFormGroup.controls.phone.value,
          password: 'NuevoUsuarioSIT3.0',
          rolNames: [
            'Recurso en campo'
          ],
          enabled: true,
        };

        this.groundResourceManageService.createUserKeycloak(userCreateKeycloak).pipe(

          switchMap((createUserResponse: any) => {
            // Lógica después de la creación del usuario en Keycloak
            this.modalService.cleanModals$.next([]);
            this.showModalSuccess('El recurso humano fue creado exitosamente. Recuerde que la contraseña de Ingreso al sistema es NuevoUsuarioSIT3.0 y deberá cambiarlo la primera vez que ingrese al sistema');
        
            // Retornar el resultado de la siguiente petición
            return  this.groundResourceManageService.findKeycloack(createUserResponse.email);
          }),
          switchMap((createGroundResourceResponse: any) => {
            // Lógica después de la creación del recurso 
            const registerBody:any  = {
              userEntityId: createGroundResourceResponse[0].id || '' ,
              firstName: this.registerFormGroup.controls.firstName.value,
              lastName: this.registerFormGroup.controls.lastName.value,
              email: this.registerFormGroup.controls.email.value,
              documentTypeId: this.registerFormGroup.controls.documentTypeId.value,
              document: this.registerFormGroup.controls.document.value,
              phone: this.registerFormGroup.controls.phone.value,
              stateId: this.estado,
              groupId: this.registerFormGroup.controls.groupId.value,
              indicative: this.registerFormGroup.controls.indicative.value,
              jobId: this.registerFormGroup.controls.jobId.value,
            }

            this.groundResourceManageService.createGroundResource(registerBody).subscribe()

            // Esperar un tiempo antes de continuar
            return this.delay(this.TIME_SEC);
          })

        ).subscribe(() => {
          // Lógica después de esperar el tiempo deseado
          this.groundResourceManageService.init();
          this.init();
        }, (error: any) => {
          // Manejar errores
          console.error('Error en la petición:', error);
        });
      
      }
      return console.warn('ningun caso ')
    } 
  }

  private delay(ms: number) {
    return new Observable(observer => {
      setTimeout(() => {
        observer.next();
        observer.complete();
      }, ms);
    });
  }
  
  changeValue() :void{
    if (this.estado === 1) {
      this.estado = 2
    } else {
      this.estado = 1
    }
  }

  
  cargarCargos() {
    if(this.groundResourceEdit.id){
      const selectedGroup = this.registerFormGroup.get('groupId').value;
      if (selectedGroup) {
  
        this.groundResourceManageService.getCargosPorGrupo(selectedGroup).subscribe((data: any) => {
          this.cargos = data;
        });
      }
    }else{

      this.registerFormGroup.get('groupId').valueChanges.subscribe((groupId) => {
        if (groupId) {
          this.groundResourceManageService.getCargosPorGrupo(groupId).subscribe((data: any) => {
            this.cargos = data;
          });
        } else  {
          this.cargos = [];
        }
  
      });
  
    }

  }
  onCancel(): void {
    this.modalCanelar();
  }

  showModalSuccess(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      toRender: {
        component: ModalSuccessComponent,
        data: {
          message: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }
  showModalError(message: string): void {
    const modal: IUniqueModalModel = {
      headerBackgroundClass: 'bg-color-background-3',
      toRender: {
        component: ModalErrorComponent,
        data: {
          errorMessage: message,
        },
      },
    };

    this.modalService.confirmationModal(modal);
  }

  handleCancelSend() {
    this.modalService.closeConfirmationModal();

  }

  checkPassword() {
    this.registerFormGroup.controls.confirmPassword.updateValueAndValidity();
  }

  get compareEquals(): boolean {
    return (
      !this.registerFormGroup.controls.confirmPassword.hasError('required') &&
      this.registerFormGroup.get('confirmPassword').hasError('noEquals') &&
      this.registerFormGroup.get('password').dirty &&
      this.registerFormGroup.get('confirmPassword').dirty
    );
  }

  modalCanelar(): void {
    const modal: IUniqueModalModel = {
      toRender: {
        component: ModalSuccesUserComponent,
        data: {
          infoMessage: '¿Está seguro que desea cancelar?',
          icon: Icon.questions_w,
        },
      },
      headerBackgroundClass: 'bg-color-background-3',
      confirm: () => {
        this.modalService.cleanModals$.next([]);
      },
      cancel: () => {
        this.modalService.closeConfirmationModal();
      },
    };
    setTimeout(() => {
      this.modalService.confirmationModal(modal);
    }, 200);
  }

  validateFormGroup(): void {
    this.registerFormGroup.markAllAsTouched();
  }
}