<div class="w-auto p-1">
  <div class="d-flex justify-space-between align-items-center h-32">
      <span class="subtitle-card text-color-white">
      </span>
      <a class="serch subtitle-card cursor-pointer clean-button" >
          Limpiar
      </a>
  </div>

  <div class="d-flex fd-col gap-16-px mb-24" style="width: 400px;">
  <span class="subtitle-section text-color-white text-size-18 text-weight-500 lh-20-px">
      Recurso
  </span>

      <div class="d-flex fd-col">
          <ng-container
                  *ngTemplateOutlet="
          checkButtonFilter;
          context: {
          controlName: resourceControl,
          dataList: ResourcesGroupTypeList,
          controlId: 'resourceControl'
          }
      "></ng-container>
      </div>

  </div>

  <br>

  <div class="d-flex fd-col gap-16-px mb-24">
  <span class="subtitle-section text-color-white text-size-18 text-weight-500 lh-20-px">
      Recursos
  </span>

      <mat-form-field class="text-white" appearance="outline">
          <!-- <mat-label class="text-white">Localidades</mat-label> -->

          <input
          type="text"
          class="borders"
          matInput
          [formControl]="physicalResourceCode"
          [matAutocomplete]="auto"
        />
          <sit-icon-button
                  matSuffix
                  *ngIf="physicalResourceCode.value"
                  (click)="physicalResourceCode.setValue(null)"
                  [icon]="Icon.close_w"
                  [colorClass]="'text-white'"
                  [sizeClass]="'icon-size-20'"
                  alt="Icono cerrar para limpiar campo"
          ></sit-icon-button>
          <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayFn"
          >
              <mat-option
              *ngFor="let option of filteredOptions  | async "
                      [value]="option"
              >
                  {{ option.value}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
  </div>


  <div class="footer-buttons">
      <button class="button-apply" (click)="applyFilter()">APLICAR</button>
  </div>
</div>

<ng-template
  #checkButtonFilter
  let-control="controlName"
  let-dataList="dataList"
  let-controlId="controlId">


  <select multiple class="hidden-control" [formControl]="control">
      <ng-container *ngFor="let item of dataList; let i = index">
          <option [value]="item.value">{{ item.name }}</option>
      </ng-container>
  </select>

  <div class="d-inline-flex gap-10-px">

      <ng-container *ngFor="let item of dataList; let i = index">
          <div class="button-checkbox">
              <input id="btn-{{controlId}}-{{i}}" class="hidden-checkbox" type="checkbox" [value]="item.value" (change)="updateSelection(control, $event, item)"
                     [checked]="selectedCheck(control, item)" />
              <label for="btn-{{controlId}}-{{i}}" class="button-label text-size-16">{{ item.name }}</label>
          </div>
      </ng-container>
  </div>
</ng-template>
