import { MapLayers } from '@shared/utils/mappers/layers-map.model';
import {
  ICardCameraModelMapper,
  ICardTrafficModelMapper,
  ICardTrafficSpeedRangeModelMapper,
  ICardTrafficConcordanceModelMapper,
  ICardCo2ModelMapper,
  ICardAforoModelMapper,
  ICardAgentsModelMapper,
  IPmvModelMapper,
  ICardAccidentModelMapper,
  ICardRuntDatexModelMapper,
  ICardTrafficJamWazeModelMapper,
  ICardRoadCorridorsModelMapper,
  ICardAgentsHistoricalModelMapper,
  ICardVehiclesModelMapper,
  ICardTrafficDataWazeModelMapper,
  ICardAlertsWazeModelMapper,
  ICardCranesModelMapper,
  // ICardCranesHistoricalModelMapper,
  ICardIncidentsLocationsModelMapper,
  IPredictiveResourcesPanelMapper,
  ICardBikesModelMapper,
  IFieldResourcesPanelMapper, IIncidentsFieldResourcesPanelMapper, ICardCranesHistoricalModelMapper, ICardAlertsDuplicatesWazeModelMapper
} from '@shared/utils/mappers/menu-right-panel-map';
import { Icon } from '@types-custom/models/ui/icon-model';
import { PanelRoadSituationsOfficialComponent } from '@official/pages/map-viewer/components/panel-road-situations-official/panel-road-situations-official.component';
import { PanelCo2Component } from '@ui-core/components/panel-co2/panel-co2.component';
import { RightPanelModel } from '@types-custom/models/ui/right-panel-model';
import { PanelTrafficSpeedrangeComponent } from '@ui-core/components/panel-traffic-speedrange/panel-traffic-speedrange.component';
import { PanelTrafficConcordanceComponent } from '@ui-core/components/panel-traffic-concordance/panel-traffic-concordance.component';
import { PanelAforoComponent } from '@ui-core/components/panel-aforo/panel-aforo.component';
import { PanelAgentsComponent } from '@ui-core/components/panel-agents/panel-agents.component';
import { PanelAgentsHistoricalComponent } from '@ui-core/components/panel-agents-historical/panel-agents-historical.component';
import { PanelEditarPmvComponent } from '@ui-core/components/panel-editar-pmv/panel-editar-pmv.component';
import { PanelConsultaPmvComponent } from '@ui-core/components/panel-consulta-pmv/panel-consulta-pmv.component';
import { PanelAccidentComponent } from '@ui-core/components/panel-accident/panel-accident.component';
import { PanelRuntDatexComponent } from '@ui-core/components/panel-runt-datex/panel-runt-datex.component';
import { PanelVehiclesComponent } from '@ui-core/components/panel-vehicles/panel-vehicles.component';
import {PanelRoadCorridorsComponent} from "@ui-core/components/panel-road-corridors/panel-road-corridors.component";
import {
  PanelTrafficDataWazeComponentComponent
} from "@ui-core/components/panel-traffic-data-waze-component/panel-traffic-data-waze-component.component";
import { PanelExodusReturnWazeComponent } from '@ui-core/components/panel-exodus-return-waze/panel-exodus-return-waze.component';
import {WazeAlertsPanelComponent} from "@ui-core/components/waze-alerts-panel/waze-alerts-panel.component";
import { PanelCranesComponent } from '@ui-core/components/panel-cranes/panel-cranes.component';
import { PanelCranesLocationComponent } from '@ui-core/components/panel-cranes-location/panel-cranes-location.component';
import { PanelAgentsNumberComponent } from '@ui-core/components/panel-agents-number/panel-agents-number.component';
import { PanelCranesNumberComponent } from '@ui-core/components/panel-cranes-number/panel-cranes-number.component';
import { PanelIncidentsLocationsComponent } from '@ui-core/components/panel-incidents-locations/panel-incidents-locations.component';
import {PanelFieldResourcesComponent} from "@ui-core/components/panel-field-resources/panel-field-resources.component";
import {
  PanelIncidentsFieldResourcesComponent
} from "@ui-core/components/panel-incidents-field-resources/panel-incidents-field-resources.component";
import {
  PredictiveResourcesPanelComponent
} from "@ui-core/components/predictive-resources-panel/predictive-resources-panel.component";
import {PanelBikesComponent} from "@ui-core/components/panel-bikes/panel-bikes.component";
import { PanelHistoryGroundResourceComponent } from '@ui-core/components/panel-history-ground-resource/panel-history-ground-resource.component';
import { WazeAlertsDuplicatesPanelComponent } from '@ui-core/components/waze-alerts-duplicates-panel/waze-alerts-duplicates-panel.component';

export const menuRightPanelMapOfficial = (
  dataSources: any
): { [key: string]: RightPanelModel } => ({
  [MapLayers.PmvEditar]: {
    component: PanelEditarPmvComponent,
    title: 'Programar PMV',
    subtitle: '(PVM)', 
    alt: 'Programar PMV',
    titleIcon: Icon.check_w,
    mapperFn: IPmvModelMapper(dataSources.pmvService),
    showPanelFromMarker: true,
  },
  [MapLayers.PmvConsulta]: {
    component: PanelConsultaPmvComponent,
    title: 'Consultar PMV',
    subtitle: '(PVM)', 
    alt: 'Consultar PMV',
    titleIcon: Icon.check_w,
    mapperFn: IPmvModelMapper(dataSources.pmvService),
    showPanelFromMarker: true,
  },
  [MapLayers.Situations]: {
    component: PanelRoadSituationsOfficialComponent,
    title: 'Novedades Bitácora',
    subtitle: '(BITACORAS)', 
    alt: 'Novedades Bitácora',
    titleIcon: Icon.alert_w,
  /*   mapperFn: ICardRoadSituationsModelMapper(dataSources.incidentsService), */
  },
  [MapLayers.TrafficSpeedRange]: {
    component: PanelTrafficSpeedrangeComponent,
    title: 'Tráfico',
    subtitle: ' (Rango de velocidad)', 
    alt: 'Rango de velocidad (Tráfico)',
    titleIcon: Icon.trafficpanelicon,
    mapperFn: ICardTrafficSpeedRangeModelMapper(undefined),
  },
  [MapLayers.RoadCorridors]: {
    component: PanelRoadCorridorsComponent,
    title: 'General ',
    subtitle: ' (Rango de velocidad)', 
    alt: 'Rango de velocidad (General) ',
    titleIcon: Icon.generalPanelIcon,
    mapperFn: ICardRoadCorridorsModelMapper(undefined),
  },
  [MapLayers.WazeDataSpeedRange]: {
    component: PanelTrafficDataWazeComponentComponent,
    title: 'Waze',
    subtitle: ' (Rango Velocidad)', 
    alt: 'Rango Velocidad (Waze)',
    titleIcon: Icon.wazePanelIcon,
    mapperFn: ICardTrafficDataWazeModelMapper(undefined),
  },
  [MapLayers.WazeDataCorridors]: {
    component: PanelTrafficDataWazeComponentComponent,
    title: 'Waze',
    subtitle: ' (Corredores)', 
    alt: 'Corredores (Waze)',
    titleIcon: Icon.wazePanelIcon,
    mapperFn: ICardTrafficDataWazeModelMapper(undefined),
  },
  [MapLayers.ExodusAndReturnWaze]: {
    component: PanelExodusReturnWazeComponent,
    title: 'Waze',
    subtitle: ' (Éxodo y retorno)', 
    alt: 'Corredores (Waze)',
    titleIcon: Icon.wazePanelIcon,
    mapperFn: ICardTrafficDataWazeModelMapper(undefined),
  },  
  [MapLayers.AlertsWaze]: {
    component: WazeAlertsPanelComponent,
    title: 'Waze',
    subtitle: ' (Alertas)', 
    alt: 'Waze (Alertas)',
    titleIcon: Icon.wazePanelIcon,
    mapperFn: ICardAlertsWazeModelMapper(undefined),
  },
  [MapLayers.TrafficConcordance]: {
    component: PanelTrafficConcordanceComponent,
    title: 'Tráfico',
    subtitle: ' (Índice de concordancia)', 
    alt: 'Tráfico (Índice de concordancia)',
    titleIcon: Icon.trafficpanelicon,
    mapperFn: ICardTrafficConcordanceModelMapper(undefined),
  },
  [MapLayers.TrafficConcordanceCorridors]: {
    component: PanelTrafficConcordanceComponent,
    title: 'General',
    subtitle: ' (Índice de concordancia)', 
    alt: 'General (Índice de concordancia)',
    titleIcon: Icon.generalPanelIcon,
    mapperFn: ICardTrafficConcordanceModelMapper(undefined),
  },
  [MapLayers.TrafficSpeedRangeCorridors]: {
    component: PanelTrafficSpeedrangeComponent,
    title: 'Tráfico',
    subtitle: ' (Corredores)', 
    alt: 'Tráfico (Corredores)',
    titleIcon: Icon.trafficpanelicon,
    mapperFn: ICardTrafficSpeedRangeModelMapper(undefined),
  },
  [MapLayers.Co2]: {
    component: PanelCo2Component,
    title: 'Puntos Sensorizados',
    subtitle: '(Sensores)', 
    alt: 'Puntos Sensorizados',
    titleIcon: Icon.sensor_w,
    mapperFn: ICardCo2ModelMapper,
  },
  [MapLayers.Aforo]: {
    component: PanelAforoComponent,
    title: 'Aforo',
    subtitle: '(Aforo)', 
    alt: 'Aforo',
    titleIcon: Icon.density_w,
    mapperFn: ICardAforoModelMapper(dataSources.aforoService),
  },
  [MapLayers.Agents]: {
    component: PanelAgentsComponent,
    title: 'Agentes',
    subtitle: ' (Localización)', 
    alt: 'Agentes',
    titleIcon: Icon.police,
    mapperFn: ICardAgentsModelMapper(dataSources.agentsService),
  },
  [MapLayers.AgentsCluster]: {
    component: PanelAgentsComponent,
    title: 'Agentes',
    subtitle: ' (Localización Cluster)',
    alt: 'Agentes',
    titleIcon: Icon.police,
    mapperFn: ICardAgentsModelMapper(dataSources.agentsService),
  },
  [MapLayers.AgentsHistorical]: {
    component: PanelAgentsHistoricalComponent,
    title: 'Agentes',
    subtitle: ' (Últimas Horas)', 
    alt: 'Agentes',
    titleIcon: Icon.police,
    mapperFn: ICardAgentsHistoricalModelMapper(dataSources.agentsService),
  },
  [MapLayers.AgentsNumber]: {
    component: PanelAgentsNumberComponent,
    title: 'Agentes',
    subtitle: ' (Número)', 
    alt: 'Agentes',
    titleIcon: Icon.police,
    mapperFn: ICardAgentsHistoricalModelMapper(dataSources.agentsService),
  },
  [MapLayers.CranesHistorical]: {
    component: PanelCranesLocationComponent,
    title: 'Grúas',
    subtitle: ' (Últimas Horas)', 
    alt: 'Agentes',
    titleIcon: Icon.crane_right_panel,
    mapperFn: ICardCranesHistoricalModelMapper(dataSources.cranesService),
  },
  [MapLayers.CranesCluster]: {
    component: PanelCranesLocationComponent,
    title: 'Grúas',
    subtitle: ' (Últimas Horas (Cluster))',
    alt: 'Agentes',
    titleIcon: Icon.crane_right_panel,
    mapperFn: ICardCranesHistoricalModelMapper(dataSources.cranesService),
  },
  [MapLayers.CranesNumber]: {
    component: PanelCranesNumberComponent,
    title: 'Grúas',
    subtitle: ' (Número)', 
    alt: 'Agentes',
    titleIcon: Icon.crane_right_panel,
    mapperFn: ICardCranesHistoricalModelMapper(dataSources.cranesService),
  },
  [MapLayers.Accident]: {
    component: PanelAccidentComponent,
    title: 'Accidentes',
    subtitle: '(Accidentes)', 
    alt: 'Accidentes',
    titleIcon: Icon.accident_w,
    mapperFn: ICardAccidentModelMapper(dataSources.accidentService),
    showPanelFromMarker: true,
  },
  [MapLayers.RuntDatex]: {
    component: PanelRuntDatexComponent,
    title: 'Runt',
    subtitle: '(Datex)', 
    alt: 'Runt - Datex',
    titleIcon: Icon.camera_play_w,
    mapperFn: ICardRuntDatexModelMapper(dataSources.runtDatexDataSource),
  },
  [MapLayers.Vehicles]: {
    component: PanelVehiclesComponent,
    title: 'Vehiculos',
    subtitle: ' (Localización)', 
    alt: 'Vehiculos',
    titleIcon: Icon.auto,
    mapperFn: ICardVehiclesModelMapper(dataSources.vehiclesService),
  },
  [MapLayers.Cranes]: {
    component: PanelCranesComponent,
    title: 'Grúas',
    subtitle: ' (Localización)', 
    alt: 'Grúas',
    titleIcon: Icon.crane_right_panel,
    mapperFn: ICardCranesModelMapper(dataSources.cranesService),
  },
  [MapLayers.IncidentsLocations]: {
    component: PanelIncidentsLocationsComponent,
    title: 'Incidentes',
    subtitle: ' (Localización)', 
    alt: 'Localización',
    titleIcon: Icon.incidentsPanelIcon,
    mapperFn: ICardIncidentsLocationsModelMapper (undefined),
  },
  [MapLayers.LastHour]: {
    component: PanelIncidentsLocationsComponent,
    title: 'Incidentes',
    subtitle: ' (Ultimas Horas)', 
    alt: 'ultimas horas',
    titleIcon: Icon.incidentsPanelIcon,
    mapperFn: ICardIncidentsLocationsModelMapper (undefined),
  },
  [MapLayers.FieldResourcesLocations]: {
    component: PanelFieldResourcesComponent,
    title: 'Recursos Humanos ',
    subtitle: ' (Localización)',
    alt: 'Recursos Humanos (Loclaización) ',
    titleIcon: Icon.FieldResourcesPanelIcon,
    mapperFn: IFieldResourcesPanelMapper(dataSources.fieldResourcesDataSource),
  },
  [MapLayers.IncidentsFieldResources]: {
    component: PanelIncidentsFieldResourcesComponent,
    title: 'Recursos en campo ',
    subtitle: ' (Asignación)',
    alt: 'Recursos Humanos (Asignación) ',
    titleIcon: Icon.BookIncidentsFieldResources,
    mapperFn: IIncidentsFieldResourcesPanelMapper(dataSources.fieldResourcesDataSource),
  },
  [MapLayers.GroundResourceLasthours]: {
    component: PanelFieldResourcesComponent,
    title: 'Recursos Humanos ',
    subtitle: '(Últimas horas)',
    alt: 'Recursos Humanos (Últimas horas)',
    titleIcon: Icon.FieldResourcesPanelIcon,
    mapperFn: IFieldResourcesPanelMapper(dataSources.fieldResourcesDataSource),
  },

  [MapLayers.GroundResourceNumber]: {
    component: PanelFieldResourcesComponent,
    title: 'Ejecución de Actividades por Tipo de Recurso',
    subtitle: '(En Campo)', 
    alt: 'Recursos Humanos (En Campo)',
    titleIcon: Icon.FieldResourcesPanelIcon,
    mapperFn: IFieldResourcesPanelMapper(dataSources.fieldResourcesDataSource),
  },


  [MapLayers.ResourcesPredictiveModelLocations]: {
    component                               : PredictiveResourcesPanelComponent,
    title                                   : 'Proyección por tipo de recurso',
    subtitle                                : '',
    alt                                     : 'Proyección por tipo de recurso',
    titleIcon                               : Icon.PredictivePanelIco,
    mapperFn                                : IPredictiveResourcesPanelMapper(dataSources.fieldResourcesDataSource),
  },
  [MapLayers.Bikes]: {
    component                               : PanelBikesComponent,
    title                                   : 'Bicicletas',
    subtitle                                : '(Localización)',
    alt                                     : 'Bicicletas (Localización)',
    titleIcon                               : Icon.bicycle_header,
    mapperFn                                : ICardBikesModelMapper(dataSources.fieldResourcesDataSource),
  },
  [MapLayers.AlertsDuplicatesWaze]: {
    component: WazeAlertsDuplicatesPanelComponent,
    title: 'Gestionar alertas waze',
    subtitle: '', 
    alt: 'Waze (Alertas Duplicadas)',
    titleIcon: Icon.wazePanelIcon,
    mapperFn: ICardAlertsDuplicatesWazeModelMapper(dataSources.panelContentDisparcer),
  },
});
