import { BehaviorSubject } from 'rxjs';
import { Icon } from './icon-model';

export interface IcardConvention {
  icon: Icon;
  label: string;
  alt: string;
}

export interface IConventionsProperties {
  layersConventions: IcardConvention[];
  situationsConventions: IcardConvention[];
}

export interface IMapConventionsPropertiesModel {
  properties: IConventionsProperties | undefined;
  showPanel$: BehaviorSubject<boolean>;
  showOwnButton: boolean;
}

const layersConv: { [key: string]: IcardConvention } = {
  traffic: {
    icon: Icon.traffic_arrows_black,
    label: 'Capa Tráfico',
    alt: 'Capa Tráfico',
  },
  photoSensor: {
    icon: Icon.speed_range_w,
    label: 'Capa Control de Velocidad',
    alt: 'Capa Control de Velocidad',
  },
  cameras: {
    icon: Icon.camera_play_w, 
    label: 'Capa Cámaras',
    alt: 'Capa Cámaras',
  },
  signalPhotoSensor: {
    icon: Icon.check_w,
    label: 'Señal cámara de fotodetección',
    alt: 'Señal cámara de fotodetección',
  },
};

const situationsConv: IcardConvention[] = [
  {
    icon: Icon.check_w,
    label: 'Estado Sémaforo',
    alt: 'Estado Sémaforo',
  },
  {
    icon: Icon.check_w,
    label: 'Horarios',
    alt: 'Horarios',
  },
  {
    icon: Icon.check_w,
    label: 'Estado de las Vías',
    alt: 'Estado de las Vías',
  },
  {
    icon: Icon.check_w,
    label: 'Señales de Tránsito',
    alt: 'Señales de Tránsito',
  },
  {
    icon: Icon.check_w,
    label: 'Reportes Fallidos de Grúas',
    alt: 'Reportes Fallidos de Grúas',
  },
  {
    icon: Icon.check_w,
    label: 'Accidentes Conciliados',
    alt: 'Accidentes Conciliados',
  },
  {
    icon: Icon.check_w,
    label: 'Inmovilizaciones',
    alt: 'Inmovilizaciones',
  },
  {
    icon: Icon.check_w,
    label: 'Atención de Quejas',
    alt: 'Atención de Quejas',
  },
];

export const mapIconConventionsMap: {
  official: IConventionsProperties;
  citizen: IConventionsProperties;
} = {
  official: {
    layersConventions: [
      layersConv.traffic,
      layersConv.photoSensor,
      layersConv.cameras,

      layersConv.signalPhotoSensor,
    ],
    situationsConventions: situationsConv,
  },

  citizen: {
    layersConventions: [
      layersConv.traffic,
      layersConv.photoSensor,
      layersConv.signalPhotoSensor,
    ],
    situationsConventions: situationsConv,
  },
};
