import { Component, Input, OnInit } from '@angular/core';
import { ITimeLineGraphicModel } from '@types-custom/models/ui/chart-line-color-model';
import { Icon } from '@types-custom/models/ui/icon-model';
import { ICardRuntDatexModel } from '@types-custom/models/ui/card-runt-datex.model';
import {
  getRuntIndicators,
  IRuntIndicator,
} from '@types-custom/models/ui/modal-runt-datex.model';
import { DrawerPanelService } from '@ui-core/services/drawer-panel/drawer-panel.service';
@Component({
  selector: 'ui-panel-runt-datex',
  templateUrl: './panel-runt-datex.component.html',
  styleUrls: ['./panel-runt-datex.component.scss'],
})
export class PanelRuntDatexComponent implements OnInit {
  cards: ICardRuntDatexModel[] = [];
  @Input() serviceDataSource: any;
  runtIndicators: IRuntIndicator[] = [];
  runtGrid: any = [];
  Icon = Icon;
  soatCountDataGraphicList: ITimeLineGraphicModel = {
    data: [
      { time: '00:00', value: 15 },
      { time: '01:00', value: 20 },
      { time: '02:00', value: 25 },
      { time: '03:00', value: 28 },
    ],
    label: 'ppm',
  };
  tecnoCountDataGraphicList: ITimeLineGraphicModel = {
    data: [
      { time: '00:00', value: 15 },
      { time: '01:00', value: 20 },
      { time: '02:00', value: 25 },
      { time: '03:00', value: 28 },
    ],
    label: 'ppm',
  };

  soatCountcolorScale = [{ minValue: 0, maxValue: 10000, color: '#D59104' }];

  tecnoCountcolorScale = [{ minValue: 0, maxValue: 10000, color: '#FF8B8B' }];

  constructor(private drawerPanelService: DrawerPanelService) {}

  ngOnInit(): void {
    this.serviceDataSource.getPanelRunt().subscribe((res: any) => {
      //console.log('res', res);

      this.runtIndicators = getRuntIndicators(res.indicators);

      this.setRuntGrid(res.grid);
    });
  }

  private setRuntGrid(data: any) {
    this.cards.map((card) => {
      data.map((item: any) => {
        if (card.id === item.cameraId) {
          card.soatExpired = item.soatExpired;
          card.technoExpired = item.technoExpired;
        }
      });
    });
  }

  openModal(card: ICardRuntDatexModel): void {
    this.drawerPanelService.collapseDrawer(true);
    card?.cardMapLink?.markerDispatcher.next(card.cardMapLink.markerModel);
  }
}
