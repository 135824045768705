import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';
import { Icon } from '@types-custom/models/ui/icon-model';

export enum SituationTypeEnum {
  complaints = <any>'Atención de Quejas',
  conciliated = <any>'Accidentes Conciliados',
  crane = <any>'Fallidos de Grúas',
  inmobilizations = <any>'Inmovilizaciones',
  road = <any>'Estados de las Vías',
  schedule = <any>'Horarios',
  signal = <any>'Señales de Tránsito',
  traffic = <any>'Estado Semáforo',
}

export interface IPropsSituationsModel {
  id: number;
  situationType: string;
  creationDate: string;
  eventDate: string;
  status: string;
  title: string;
  markerPopupText: string;
  icon: Icon;
}

export class SituationsModel extends MarkerModelBase {
  static className = 'SituationsModel';
  classProperties: IPropsSituationsModel;

  constructor(props: IPropsSituationsModel, geometry: IGeometryModel) {
    super(geometry, SituationsModel.className);
    this.classProperties = props;
  }
}

export enum SituationTypeFilterEnum{
  Incidente_en_via = 'Incidente en vía',
  Manifestacion = 'Manifestación',
  Semaforización = 'Semaforización',
  Siniestro = 'Siniestro',
  Transporte_masivo = 'Transporte masivo',
  Varado = 'Varado',
}

export enum SituationPriorityFilterEnum{
  low = '0',
  medium = '1',
  high = '2',
  highest = '3',
}