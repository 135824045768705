<div class="bg-color-background-1 d-flex mt-2  ml-16 left-line">
  <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
  <div class="d-flex full-width py-05">
    <sit-icon
      alt="Volver"
      [icon]="Icon.arrow_backward_w"
      (click)="handleConfirmation('regresar')"
      class="text-color-white ml-28"
      [sizeClass]="'icon-size-24'"
    ></sit-icon>
    <h3
      class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0"
    >
      EDITAR {{ titlePanel }}
    </h3>
  </div>
</div>

<div class="form-container mb-32 mt-32">
  <generic-form [properties]="editFormProps"></generic-form>
  <div class="d-flex fd-row justify-end mt-16">
    <sit-button
      (click)="handleConfirmation()"
      [buttonProperties]="cancelButtonProps"
      class="width-49"
    ></sit-button>
    <sit-button
      (click)="editFormGroup.valid ? handleSendForm() : validateFormGroup()"
      [disabled]="!editFormGroup.valid"
      [buttonProperties]="sendButtonProps"
      class="width-49 ml-16"
    ></sit-button>
  </div>
</div>
