import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AllmessagesforcameraipDTO } from '@shared/models/pmv/DTO/allmessagesforcameraipDTO.model';
import { MessageschedulesforcameraDTO } from '@shared/models/pmv/DTO/messageschedulesforcameraDTO.model';
import { StatusforcameraipDTO } from '@shared/models/pmv/DTO/statusforcameraipDTO.model';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import {
  AbstractPanelManagementDataSource,
  IPaginatorModel,
} from '@types-custom/models/ui/paginator-model';
import { ILocationPoint } from '@types-custom/models/business/location.model';
import { IManageVariableMenssagingDataBody } from '@types-custom/models/business/manage-varaible-messaging-form';
import { SensorOptionTypeEnum } from '@types-custom/models/ui/sensor-option-type-model';
import { fileTypes } from '@types-custom/models/ui/file-types.model';

@Injectable({
  providedIn: 'root',
})
export class PmvService {
  // ApiBase:string = "https://pmv.movilidad-manizales.com/api/v1/"

  //  ApiBase = "http://localhost:5000/api/v1/"
  ApiBase = 'https://pmv.movilidad-manizales.com/pmv/api/v1/';

  ApiGetStatus = 'status/';
  ApiGetMessagesSchedules = 'schedules?ip=';
  ApiGetAllMessages = 'messages?ip=';
  ApiSaveMessages = 'messages/';
  ApiGetAllGraphics = 'graphics/';
  ApiGetAllFonts = 'fonts/';
  ApiGetMessageBynumber = 'messages/';
  ApiSavetMessagesSchedules = 'schedules/';

  ipFromecomponent: string;
  dataFromecomponent: any;
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  setIp(ip: string) {
    this.ipFromecomponent = ip;
  }

  getPmvGeoJSON(): Observable<any> {
    const url = `${this.environment.vmsUrl}/vms/format-geo-json?status=1`;
    return this.httpClient.get<any>(url).pipe(
      tap((response: any) => {
        const data: any[] = response;
      })
    );
  }

  getStatusforcameraip(ip: string): Observable<StatusforcameraipDTO> {
    //console.log('ip pmv', ip);

    return this.httpClient
      .get<StatusforcameraipDTO>(`${this.ApiBase}${this.ApiGetStatus}${ip}`)
      .pipe(
        map((res: StatusforcameraipDTO) => {
          return res;
        })
      );
  }

  getMessagesSchedulesforcameraip(
    ip: string,
    pageNumber: number,
    pageSize: number
  ): Observable<MessageschedulesforcameraDTO[]> {
    const pagination = `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.httpClient
      .get<MessageschedulesforcameraDTO[]>(
        `${this.ApiBase}${this.ApiGetMessagesSchedules}${ip}${pagination}`
      )
      .pipe(
        map((res: MessageschedulesforcameraDTO[]) => {
          return res;
        })
      );
  }

  getAllMessagesforcameraip(
    ip: string,
    pageNumber: number,
    pageSize: number
  ): Observable<AllmessagesforcameraipDTO[]> {
    let uri: string;
    if (this.environment.production) {
      const pagination = `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      uri = `${this.ApiBase}${this.ApiGetAllMessages}${ip}${pagination}`;
    } else {
      uri = 'assets/mock/pmvResponse/messagesScheduleslist.json';
    }
    return this.httpClient.get<AllmessagesforcameraipDTO[]>(uri).pipe(
      map((res: AllmessagesforcameraipDTO[]) => {
        return res;
      })
    );
  }

  //++++descomentar

  // getAllMessagesforcameraip(ip:string,pageNumber:number,pageSize:number): Observable<AllmessagesforcameraipDTO[]>{
  //   var pagination = `&pageNumber=${pageNumber}&pageSize=${pageSize}`
  //   return this.httpClient.get<AllmessagesforcameraipDTO[]>('assets/mock/pmvResponse/messagesScheduleslist.json').pipe(
  //     map((res: AllmessagesforcameraipDTO[]) => {
  //       return res;
  //     })
  //   )
  // }

  //++++descomentar
  // getAllFonts(ip:any){
  //   return this.httpClient.get('assets/mock/pmvResponse/fontsList.json')
  // }

  // getAllGraphic(ip:any){
  //   return this.httpClient.get('assets/mock/pmvResponse/graphicsList.json')
  // }

  // getMessagebyNumber(ip:any,number:any){

  //   if(number.toString() == "2" || number.toString() == "5" ){
  //     return this.httpClient.get(`assets/mock/pmvResponse/messagesGetOne${number}.json`)

  //   }
  //   return this.httpClient.get(`assets/mock/pmvResponse/messagesGetOne4.json`)

  // }

  ///***********descomentar */

  getAllFonts(ip: any) {
    let uri: string;
    if (this.environment.production) {
      uri = `${this.ApiBase}${this.ApiGetAllFonts}?ip=${ip}&pageNumber=0&pageSize=-1`;
    } else {
      uri = 'assets/mock/pmvResponse/fontsList.json';
    }
    return this.httpClient.get(uri);
  }

  getAllGraphic(ip: any) {
    let uri: string;
    if (this.environment.production) {
      uri = `${this.ApiBase}${this.ApiGetAllGraphics}?ip=${ip}&pageNumber=0&pageSize=-1`;
    } else {
      uri = 'assets/mock/pmvResponse/graphicsList.json';
    }
    return this.httpClient.get(uri);
  }

  getMessagebyNumber(ip: any, number: any) {
    let uri: string;
    if (this.environment.production) {
      uri = `${this.ApiBase}${this.ApiGetMessageBynumber}${ip}/${number}`;
    } else {
      if (number.toString() == '2' || number.toString() == '5') {
        uri = `assets/mock/pmvResponse/messagesGetOne${number}.json`;
      } else {
        uri = `assets/mock/pmvResponse/messagesGetOne4.json`;
      }
    }
    return this.httpClient.get(uri);
  }

  ///***********descomentar */

  saveMessagesforcameraip(ip: string, data: any): Observable<any> {
    // console.log("esto tiene "+JSON.stringify(data))
    return this.httpClient.post(
      `${this.ApiBase}${this.ApiSaveMessages}${ip}`,
      data
    );
  }

  saveMessagesScheduleforcameraip(ip: string, data: any): Observable<any> {
    return this.httpClient.post(
      `${this.ApiBase}${this.ApiSavetMessagesSchedules}${ip}`,
      data
    );
  }

  editMessagesScheduleforcameraip(ip: string, data: any): Observable<any> {
    return this.httpClient.put(
      `${this.ApiBase}${this.ApiSavetMessagesSchedules}${ip}`,
      data
    );
  }

  setdata(data: any) {
    this.dataFromecomponent = data;
  }
  getdata() {
    return this.dataFromecomponent;
  }
}
