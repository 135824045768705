<div class="bg-color-background-1 d-flex mt-2  ml-16 left-line">
    <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
    <div class="d-flex full-width py-05">
        <sit-icon
            alt="Volver"
            [icon]="Icon.arrow_backward_w"
            (click)="handleConfirmation('regresar')"
            class="text-color-white ml-28"
            [sizeClass]="'icon-size-24'"
        ></sit-icon>
        <h3
            class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0"
        >
            {{ titlePanel }} ID {{gridRowData.id}}
        </h3>
        </div>
</div>

<div class="card-container fd-col">
    <span>Creacion {{gridRowData.creationDate | date:'dd/MM/YYYY HH:mm' }} </span>
    <span>Última modificación {{gridRowData.incidentTime | date:'dd/MM/YYYY HH:mm' }} </span>
    <div>
        <sit-icon
        alt="Volver"
        [icon]="Icon.location_w"
        class="text-color-white mr-8 "
        [sizeClass]="'icon-size-24'">
        </sit-icon>
        <span>{{gridRowData.address}}</span>
    </div>
    <div class="ml-32 d-flex fd-col">
        <span>Localidad {{gridRowData.locationName}}</span>
        <span>Corredor {{gridRowData.brokerName}}</span>
        <span>Orientación {{gridRowData.orientName}}</span>
        <span>{{gridRowData.zoneName}}</span>
      </div>
    <div>
        <sit-icon
        alt="Volver"
        [icon]="Icon.Ciempies"
        class="text-color-white mr-8"
        [sizeClass]="'icon-size-24'">
        </sit-icon>
        <span>{{gridRowData.classesName}}</span>
    </div>
    <div class="ml-32 d-flex fd-col">
      <span>Gravedad {{gridRowData.gravityName}}</span>
      <span>Núm. de implicados {{gridRowData.implicatedName}}</span>
      <span>Prioridad {{gridRowData.priorityName}}</span>
      <span>Fuente {{gridRowData.sourceName}}</span>
    </div>
    <div class="mt-16 lh-15">
      <span class="text-size-14">{{gridRowData.observations[0].observation}} </span>
    </div>
</div>

<div class="bg-color-background-1 d-flex mt-2  ml-16 left-line">
  <div class="left-subtitle-block bg-color-primary-1 h-auto"></div>
  <div class="d-flex full-width py-05">
      <sit-icon
          alt="Volver"
          [icon]="Icon.ResourcesWhite"
          class="text-color-white ml-28"
          [sizeClass]="'icon-size-24'"
      ></sit-icon>
      <h3
          class="flex-grow text-weight-600 text-size-18 ml-16 text-md-size-18 text-color-white align-self-center m-0"
      >
          Recursos humanos asignados
      </h3>
      </div>
  </div>
  <ng-container *ngFor="let item of listResourcesHuman">
    <div class="card-container d-flex fd-row justify-space-between">
      <div>
        <div class="d-flex fd-row">
          <sit-icon
          alt="Volver"
          [icon]="Icon.AlColegioenBici"
          class="text-color-white"
          [sizeClass]="'icon-size-24'">
          </sit-icon>
          <span class=" ml-16 text-color-primary-1 fw-bold">{{item.id}} - </span>
          <span class=" fw-bold"> {{item.names}}</span>
          <span class=" fw-bold">{{item.lastNames}}</span>
        </div>
        <span>{{item.groupName}}</span>
      </div>
      <div>
        <sit-icon
        alt="Volver"
        [icon]="Icon.delete_w"
        class="text-color-white"
        (click)="deleteHumanResource(item.humanResourceId)"
        [sizeClass]="'icon-size-24'">
        </sit-icon>
      </div>
    </div>
  </ng-container>
