import {
  IGeometryModel,
  MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsVehiclesModel {
  id?: string;
  siteId: string;
  speedAverage?: string;
  name?: string;
  address?: string;
  flowSum?:string;
  serviceLevel?:string;
  detailSite?: [];
  reportVehicle?: [];
  reportVehicleAll?: [];
  // color?:string;
  // level_of_service?:string;
  // location?: string;
  // sensores?: [];
  title: string;
  markerPopupText: string;
  icon: unknown;
}

export class VehiclesModel extends MarkerModelBase {
  static className = 'VehiclesModel';
  classProperties: IPropsVehiclesModel;

  constructor(props: IPropsVehiclesModel, geometry: IGeometryModel) {
    super(geometry, VehiclesModel.className);
    this.classProperties = props;
  }
}
