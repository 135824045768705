<div class="mat-input mt-05 custom-scrollbar" style="max-height: calc(100vh - 210px); overflow: auto;">
  <form *ngIf="incidentForm" [formGroup]="incidentForm">
      <div *ngFor="let item of incidentsList">
        <input [formControlName]="item" type="checkbox" />{{ item }}
      </div>
    <div class="time-filters mt-05">
      <mat-form-field
        appearance="outline"
        [ngClass]="{
          'error-field':
          startDate.errors?.['greaterThanCurrentDate']
        }"
      >
        <mat-label>Fecha Inicial</mat-label>
        <input formControlName="dateInit" type="date" matInput min="{{minStartDate.toLocaleDateString('fr-CA')}}" />
        <mat-error *ngIf="startDate.errors?.['greaterThanCurrentDate']">
          La fecha inicial debe ser igual o menor a la fecha actual
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Hora Inicial</mat-label>
        <input formControlName="timeInit" type="time" matInput />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="mt-05"
        [ngClass]="{
        'error-field':
        endDate.errors?.['greaterThanCurrentDate'] ||  endDate.errors?.['lessThanStartDate']
      }"
      >
        <mat-label>Fecha Final</mat-label>
        <input formControlName="dateEnd" type="date" matInput />
        <mat-error *ngIf="endDate.errors?.['greaterThanCurrentDate']">
          La fecha final debe ser igual o menor a la fecha actual
        </mat-error>
        <mat-error *ngIf="endDate.errors?.['lessThanStartDate']">
          La fecha final debe ser igual o mayor a la fecha inicial
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="">
        <mat-label>Hora Final</mat-label>
        <input formControlName="timeEnd" type="time" matInput />
      </mat-form-field>
    </div>
    <div class="d-flex fd-row justify-end">
      <sit-button
        (click)="incidentForm.valid ? getInformation() : undefined"
        [disabled]="!incidentForm.valid"
        [buttonProperties]="createButtonProps"
        class="width-49"
      ></sit-button>
    </div>
  </form>
</div>
