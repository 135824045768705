import { ButtonComponent } from "./controls/button/button.component";
import { CardCamerasComponent } from "./controls/card-cameras/card-cameras.component";
import { CardRoadSituationsComponent } from "./controls/card-road-situations/card-road-situations.component";
import { CardTrafficComponent } from "./controls/card-traffic/card-traffic.component";
import { CommonModule } from "@angular/common";
import { DragDropDirective } from "@ui-core/directives/drag-drop.directive";
import { DropdownComponent } from "./controls/dropdown/dropdown.component";
import { DropFilesComponent } from "@ui-core/components/drop-files/drop-files.component";
import { DynamicFilterComponent } from "./components/dynamic-filter/dynamic-filter.component";
import { DynamicSideMenuComponent } from "./components/dynamic-side-menu/dynamic-side-menu.component";
import { FiltersComponent } from "./components/filters/filters.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GridPageComponent } from "./components/grid-page/grid-page.component";
import { GridTableComponent } from "./controls/grid-table/grid-table.component";
import { HeaderComponent } from "./components/header/header.component";
import { HorizontalMenuComponent } from "./controls/horizontal-menu/horizontal-menu.component";
import { IconButtonComponent } from "./controls/icon-button/icon-button.component";
import { IconComponent } from "./controls/icon/icon.component";
import { ImgLoaderComponent } from "./controls/img-loader/img-loader.component";
import { ListButtonsComponent } from "./components/list-buttons/list-buttons.component";
import { LoaderOverlayComponent } from "./components/loader-overlay/loader-overlay.component";
import { LocationSelectorComponent } from "./components/location-selector/location-selector.component";
import { MapViewerComponent } from "./components/map-viewer/map-viewer.component";
import { MaterialModule } from "./shared/material.module";
import { MatRadioModule } from "@angular/material/radio";
import { MenuComponent } from "./components/menu/menu.component";
import { ModalComponent } from "./components/modal/modal.component";
import { ModalErrorComponent } from "./components/modal-error/modal-error.component";
import { ModalImageComponent } from "./components/modal-image/modal-image.component";
import { ModalNoInfoComponent } from "./components/modal-no-info/modal-no-info.component";
import { ModalRoadworksComponent } from "./components/modal-roadworks/modal-roadworks.component";
import { ModalSuccessComponent } from "./components/modal-success/modal-success.component";
import { ModalTrafficComponent } from "./components/modal-traffic/modal-traffic.component";
import { ModalCameraComponent } from "./components/modal-camera/modal-camera.component";
import { NgModule } from "@angular/core";
import { PageTitleComponent } from "./controls/page-title/page-title.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { PanelRoadSituationsComponent } from "./components/panel-road-situations/panel-road-situations.component";
import { PanelTrafficComponent } from "./components/panel-traffic/panel-traffic.component";
import { RendererComponent } from "./controls/renderer/renderer.component";
import { ScrollHandlerDirective } from "./directives/scroll-handler.directive";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { SpinnerComponent } from "./controls/spinner/spinner.component";
import { StateIndicatorComponent } from "./controls/state-indicator/state-indicator.component";
import { SheetBottomDirective } from "./directives/sheet-bottom.directive";
import { BottomSheetARDirective } from "./directives/bottom-sheet-ar.directive";
import { MapConventionsComponent } from "./components/map-conventions/map-conventions.component";
import { NgChartsModule } from "ng2-charts";
import { SitBarChartComponent } from "./components/sit-bar-chart/sit-bar-chart.component";
import { GraphicLineComponent } from "./components/graphic-line/graphic-line.component";
import { SitChartColumnComponent } from "./components/sit-chart-column/sit-chart-column.component";
import { SitChartLineColorComponent } from "./components/sit-chart-line-color/sit-chart-line-color.component";
import { SitChartSeriesComponent } from "./components/sit-chart-series/sit-chart-series.component";
import { SpeedometerComponent } from "./components/speedometer/speedometer.component";
import { IndicatorsComponent } from "./controls/indicators/indicators.component";
import { ModalCo2Component } from "./components/modal-co2/modal-co2.component";
import { PanelCo2Component } from "./components/panel-co2/panel-co2.component";
import { PanelTrafficSpeedrangeComponent } from "./components/panel-traffic-speedrange/panel-traffic-speedrange.component";
import { PanelTrafficConcordanceComponent } from "./components/panel-traffic-concordance/panel-traffic-concordance.component";
import { ModalTrafficSpeedrangeComponent } from "./components/modal-traffic-speedrange/modal-traffic-speedrange.component";
import { ModalTrafficConcordanceComponent } from "./components/modal-traffic-concordance/modal-traffic-concordance.component";
import { GenericFormComponent } from "./components/generic-form/generic-form.component";
import { ModalBicyclePathComponent } from "./components/modal-bicycle-path/modal-bicycle-path.component";
import { ModalSuccesUserComponent } from "./components/modal-succes-user/modal-succes-user.component";
import { ModalCoosComponent } from "./components/modal-coos/modal-coos.component";
import { ModalForgotPassComponent } from "./components/modal-forgot-pass/modal-forgot-pass.component";
import { ModalTrafficLightComponent } from "./components/modal-traffic-light/modal-traffic-light.component";
import { ModalChangePasswordComponent } from "./components/modal-change-password/modal-change-password.component";
import { LocationControlFromApiComponent } from "./components/location-control-from-api/location-control-from-api.component";
import { ModalForgotPasswordComponent } from "./components/modal-forgot-password/modal-forgot-password.component";
import { ModalAforoComponent } from "./components/modal-aforo/modal-aforo.component";
import { PanelAforoComponent } from "./components/panel-aforo/panel-aforo.component";
import { PanelAgentsComponent } from "./components/panel-agents/panel-agents.component";
import { ModalAgentsComponent } from "./components/modal-agents/modal-agents.component";
import { ModalAgentsLocationsComponent } from "./components/modal-agents-locations/modal-agents-locations.component";
import { AutocompleteControlComponent } from "./components/autocomplete-control/autocomplete-control.component";
import { MapFilterComponent } from "./components/map-filters/map-filter.component";
import { MapFilterAgentsComponent } from "./components/map-filters/agents/map-filter-agents.component";
import { MapFilterAgentsHistoricalComponent } from "./components/map-filters/agents-historical/map-filter-agents-historical.component";
import { ModalTrafficManagmentComponent } from "./components/modal-traffic-managment/modal-traffic-managment.component";
import { ModalStopBusComponent } from "./components/modal-stop-bus/modal-stop-bus.component";
import { MapFilterIncidentsComponent } from "./components/map-filters/incidents/map-filter-incidents.component";
import { ModalZonesComponent } from "./components/modal-zones/modal-zones.component";
import { ModalRoadRunComponent } from "./components/modal-road-run/modal-road-run.component";
import { PanelEditarPmvComponent } from "@ui-core/components/panel-editar-pmv/panel-editar-pmv.component";
import { PanelConsultaPmvComponent } from "@ui-core/components/panel-consulta-pmv/panel-consulta-pmv.component";
import { CreateMessageComponent } from "./components/panel-editar-pmv/create-message/create-message.component";
import { ListProgramMessageComponent } from "./components/panel-editar-pmv/list-programs-message/list-message.component";
import { ProgramMessageComponent } from "./components/panel-editar-pmv/program-message/program-message.component";
import { EditProgramMessageComponent } from "./components/panel-editar-pmv/edit-program-message/edit-program-message.component";
import { StateToogleComponent } from "./controls/state-toogle/state-toogle.component";
import { PanelAccidentComponent } from "./components/panel-accident/panel-accident.component";
import { PanelRuntDatexComponent } from "./components/panel-runt-datex/panel-runt-datex.component";
import { ModalRuntDatexComponent } from "./components/modal-runt-datex/modal-runt-datex.component";
import { ModalSuccesRolComponent } from "./components/modal-succes-rol/modal-succes-rol.component";
import { ListCardPageComponent } from "./components/list-card-page/list-card-page.component";
import { ListCardsComponent } from "./controls/list-cards/list-cards.component";
import { CardMenuComponent } from "./controls/card-menu/card-menu.component";
import { PanelRoadCorridorsComponent } from "./components/panel-road-corridors/panel-road-corridors.component";
import { SitListCorridorsComponent } from "./components/sit-list-corridors/sit-list-corridors.component";
import { PanelAgentsHistoricalComponent } from "./components/panel-agents-historical/panel-agents-historical.component";
import { PanelVehiclesComponent } from "./components/panel-vehicles/panel-vehicles.component";
import { ModalVehiculesComponent } from "./components/modal-vehicle/modal-vehicle.component";
import { IncidentsComponent } from "./components/incidents/incidents.component";
import { ModalTrafficJamComponent } from "./components/modal-traffic-jam/modal-traffic-jam.component";
import { SitTrafficjamCardComponent } from "./components/sit-trafficjam-card/sit-trafficjam-card.component";
import { ModalCoiComponent } from "@ui-core/components/modal-coi/modal-coi.component";
import { ModalRoadCorridorsComponentComponent } from "./components/modal-road-corridors-component/modal-road-corridors-component.component";
import { ModalCoosLineComponent } from "./components/modal-coos-line/modal-coos-line.component";
import { ModalCoilineComponent } from "./components/modal-coi-line/modal-coi-line.component";
import { ModalWazeComponent } from "./components/modal-waze/modal-waze.component";
import { PanelTrafficDataWazeComponentComponent } from "./components/panel-traffic-data-waze-component/panel-traffic-data-waze-component.component";
import { SitListTrafficWazeCardsComponent } from "./components/sit-list-traffic-waze-cards/sit-list-traffic-waze-cards.component";
import { PanelExodusReturnWazeComponent } from "./components/panel-exodus-return-waze/panel-exodus-return-waze.component";
import { SitListExodusReturnCardsComponent } from "./components/sit-list-exodus-return-cards/sit-list-exodus-return-cards.component";
import { WazeAlertsModalComponent } from "./components/waze-alerts-modal/waze-alerts-modal.component";
import { WazeAlertsPanelComponent } from "./components/waze-alerts-panel/waze-alerts-panel.component";
import { SitListWazeAlertsComponent } from "./components/sit-list-waze-alerts/sit-list-waze-alerts.component";
import { AlertsWazeComponent } from "./components/map-filters/alerts-waze/alerts-waze.component";
import { DropdownButtonComponent } from "./controls/dropdown-button/dropdown-button.component";
import { ChipsButtonFilterComponent } from "./controls/chips-button-filter/chips-button-filter.component";
import { PanelIncidentsLocationsComponent } from "./components/panel-incidents-locations/panel-incidents-locations.component";
import { ModalCranesComponent } from "./components/modal-cranes/modal-cranes.component";
import { PanelCranesComponent } from "./components/panel-cranes/panel-cranes.component";
import { ModalCranesLocationsComponent } from "./components/modal-cranes-locations/modal-cranes-locations.component";
import { PanelCranesLocationComponent } from "./components/panel-cranes-location/panel-cranes-location.component";
import { MapFilterCranesComponent } from "./components/map-filters/cranes/map-filter-cranes/map-filter-cranes.component";
import { MapFilterCranesHistoricalComponent } from "./components/map-filters/cranes-historical/map-filter-cranes-historical/map-filter-cranes-historical.component";
import { SitChartColumnInvertedComponent } from "./components/sit-chart-column-inverted/sit-chart-column-inverted.component";
import { ModalIncidentsComponent } from "./components/modal-incidents/modal-incidents.component";
import { IncidentsCardsComponent } from "./components/incidents-cards/incidents-cards.component";
import { PanelAgentsNumberComponent } from "./components/panel-agents-number/panel-agents-number.component";
import { PanelCranesNumberComponent } from "./components/panel-cranes-number/panel-cranes-number.component";
import { SitChartColumnWazeComponent } from "./components/sit-chart-column-waze/sit-chart-column-waze.component";
import { MapFilterHeatMapIncidentsComponent } from "./components/map-filters/heat-map-incidents/map-filter-heat-map-incidents.component";
import { DropFilesPhysicalResourcesComponent } from "./components/drop-files-physical-resources/drop-files-physical-resources.component";
import { GridTablePhysicalResourcesComponent } from "./controls/grid-table-physical-resources/grid-table-physical-resources.component";
import { GridPagePhysicalResourceComponent } from "./components/grid-page-physical-resource/grid-page-physical-resource.component";
import { FieldResourcesFilterComponent } from "./components/map-filters/field-resources-filter/field-resources-filter.component";
import { DropdownButtonDatePickerComponent } from "./controls/dropdown-button-date-picker/dropdown-button-date-picker.component";
import { ListCardsReportPageComponent } from "./components/list-cards-report-page/list-cards-report-page.component";
import { ListCardsReportComponent } from "./controls/list-cards-report/list-cards-report.component";
import { PanelFieldResourcesComponent } from "./components/panel-field-resources/panel-field-resources.component";
import { ModalFieldResourcesComponent } from "./components/modal-field-resources/modal-field-resources.component";
import { KPIResourcesComponent } from "./components/kpiresources/kpiresources.component";
import { ResourcesListLocalityComponent } from "./components/resources-list-locality/resources-list-locality.component";
import { DynamicKPIComponent } from "./components/dynamic-kpi/dynamic-kpi.component";
import { ModalConfirmationComponent } from "./components/modal-confirmation/modal-confirmation.component";
import { IncidentsFieldResourcesFilterComponent } from "./components/incidents-field-resources-filter/incidents-field-resources-filter.component";
import { PanelIncidentsFieldResourcesComponent } from "./components/panel-incidents-field-resources/panel-incidents-field-resources.component";
import { ModalIncidentsFieldResourcesComponent } from "./components/modal-incidents-field-resources/modal-incidents-field-resources.component";
import { GroundResourceHistoricalComponent } from "./components/map-filters/ground-resource-historical/ground-resource-historical.component";
import { GroundResourceGroupComponent } from "./components/map-filters/ground-resource-group/ground-resource-group.component";
import { PanelHistoryGroundResourceComponent } from "./components/panel-history-ground-resource/panel-history-ground-resource.component";
import { IncidentsKPIComponent } from "./components/incidents-kpi/incidents-kpi.component";
import { ModalIncidentsResourceComponent } from "./components/modal-incidents-resource/modal-incidents-resource.component";
import { GridButtonIconComponent } from "./components/grid-button-icon/grid-button-icon.component";
import { PanelNumberGroundResourceComponent } from "./components/panel-number-ground-resource/panel-number-ground-resource.component";
import { PredictiveResourcesPanelComponent } from "./components/predictive-resources-panel/predictive-resources-panel.component";
import { ListBikesComponent } from "./components/list-bikes/list-bikes.component";
import { ModalBikesComponent } from "./components/modal-bikes/modal-bikes.component";
import { PanelBikesComponent } from "./components/panel-bikes/panel-bikes.component";
import { GeneralCardComponent } from "./components/general-card/general-card.component";
import { PredictiveResourceFilterComponent } from "@ui-core/components/predictive-resource-filter/predictive-resource-filter.component";
import { ListpredictiveResourcesComponent } from "./components/listpredictive-resources/listpredictive-resources.component";
import { ActionsIncidentsComponent } from "./components/actions-incidents/actions-incidents.component";
import { WazeAlertsDuplicatesModalComponent } from "@ui-core/components/waze-alerts-duplicates-modal/waze-alerts-duplicates-modal.component";
import { WazeAlertsDuplicatesPanelComponent } from "@ui-core/components/waze-alerts-duplicates-panel/waze-alerts-duplicates-panel.component";
import { ManageNoveltyVehicleModule } from "@official/pages/manage-novelty-vehicle/manage-novelty-vehicle.module";
import { ModalAddResourcesComponent } from "./components/modal-add-resources/modal-add-resources.component";
import { MapFilterLocationIncidentsComponent } from "./components/map-filters/location-incidents/map-filter-location-incidents/map-filter-location-incidents.component";
import { MapFilterLastHoursIncidentsComponent } from "./components/map-filters/last-hours-incidents/map-filter-last-hours-incidents/map-filter-last-hours-incidents.component";
import { ActionsIncidentsSocialMediaTextComponent } from "./components/actions-incidents-social-media-text/actions-incidents-social-media-text.component";
import { AssignableResourcesComponent } from "./components/map-filters/assignable-resources/assignable-resources.component";
import { AirQualityModalComponent } from "./components/air-quality-modal/air-quality-modal.component";
import { AirQualityIndicatorsComponent } from "./components/air-quality-indicators/air-quality-indicators.component";

@NgModule({
  declarations: [
    ModalCoiComponent,
    ModalCoilineComponent,
    ModalCoosLineComponent,
    ButtonComponent,
    CardCamerasComponent,
    CardRoadSituationsComponent,
    CardTrafficComponent,
    DragDropDirective,
    DragDropDirective,
    DropdownComponent,
    DropFilesComponent,
    DropFilesComponent,
    DropFilesComponent,
    DynamicFilterComponent,
    DynamicSideMenuComponent,
    FiltersComponent,
    FooterComponent,
    GridPageComponent,
    GridTableComponent,
    HeaderComponent,
    HorizontalMenuComponent,
    IconButtonComponent,
    IconComponent,
    ImgLoaderComponent,
    ListButtonsComponent,
    LoaderOverlayComponent,
    LocationSelectorComponent,
    MapViewerComponent,
    MenuComponent,
    ModalComponent,
    ModalErrorComponent,
    ModalErrorComponent,
    ModalImageComponent,
    ModalNoInfoComponent,
    ModalNoInfoComponent,
    ModalRoadworksComponent,
    ModalSuccessComponent,
    ModalTrafficComponent,
    ModalCameraComponent,
    PageTitleComponent,
    PaginatorComponent,
    PanelRoadSituationsComponent,
    PanelTrafficComponent,
    RendererComponent,
    ScrollHandlerDirective,
    SideMenuComponent,
    SpinnerComponent,
    StateIndicatorComponent,
    SheetBottomDirective,
    BottomSheetARDirective,
    MapConventionsComponent,
    SitBarChartComponent,
    GraphicLineComponent,
    SpeedometerComponent,
    SitChartColumnComponent,
    SitChartLineColorComponent,
    SitChartSeriesComponent,
    IndicatorsComponent,
    ModalCo2Component,
    PanelCo2Component,
    PanelTrafficSpeedrangeComponent,
    PanelTrafficConcordanceComponent,
    ModalTrafficSpeedrangeComponent,
    ModalTrafficConcordanceComponent,
    GenericFormComponent,
    ModalBicyclePathComponent,
    ModalCoosComponent,
    ModalChangePasswordComponent,
    ModalSuccesUserComponent,
    ModalTrafficLightComponent,
    LocationControlFromApiComponent,
    ModalForgotPasswordComponent,
    ModalForgotPassComponent,
    ModalAforoComponent,
    PanelAforoComponent,
    PanelAgentsComponent,
    ModalAgentsComponent,
    ModalAgentsLocationsComponent,
    ModalVehiculesComponent,
    PanelVehiclesComponent,
    MapFilterComponent,
    MapFilterAgentsComponent,
    AutocompleteControlComponent,
    MapFilterAgentsHistoricalComponent,
    ModalTrafficManagmentComponent,
    ModalStopBusComponent,
    MapFilterIncidentsComponent,
    ModalZonesComponent,
    ModalRoadRunComponent,
    PanelEditarPmvComponent,
    PanelConsultaPmvComponent,
    CreateMessageComponent,
    ListProgramMessageComponent,
    ProgramMessageComponent,
    EditProgramMessageComponent,
    StateToogleComponent,
    PanelAccidentComponent,
    PanelRuntDatexComponent,
    ModalRuntDatexComponent,
    ModalSuccesRolComponent,
    ListCardPageComponent,
    ListCardsComponent,
    CardMenuComponent,
    ModalAgentsLocationsComponent,
    PanelRoadCorridorsComponent,
    SitListCorridorsComponent,
    PanelAgentsHistoricalComponent,
    IncidentsComponent,
    ModalTrafficJamComponent,
    SitTrafficjamCardComponent,
    ModalWazeComponent,
    ModalRoadCorridorsComponentComponent,
    PanelTrafficDataWazeComponentComponent,
    SitListTrafficWazeCardsComponent,
    PanelExodusReturnWazeComponent,
    SitListExodusReturnCardsComponent,
    WazeAlertsModalComponent,
    WazeAlertsPanelComponent,
    SitListWazeAlertsComponent,
    AlertsWazeComponent,
    DropdownButtonComponent,
    ChipsButtonFilterComponent,
    PanelIncidentsLocationsComponent,
    ModalCranesComponent,
    PanelCranesComponent,
    ModalCranesLocationsComponent,
    PanelCranesLocationComponent,
    MapFilterCranesComponent,
    MapFilterCranesHistoricalComponent,
    SitChartColumnInvertedComponent,
    ModalIncidentsComponent,
    IncidentsCardsComponent,
    PanelAgentsNumberComponent,
    PanelCranesNumberComponent,
    SitChartColumnWazeComponent,
    MapFilterHeatMapIncidentsComponent,
    DropFilesPhysicalResourcesComponent,
    GridTablePhysicalResourcesComponent,
    GridPagePhysicalResourceComponent,
    FieldResourcesFilterComponent,
    GridPageComponent,
    DropdownButtonDatePickerComponent,
    ListCardsReportPageComponent,
    ListCardsReportComponent,
    PanelFieldResourcesComponent,
    ModalFieldResourcesComponent,
    KPIResourcesComponent,
    ResourcesListLocalityComponent,
    DynamicKPIComponent,
    ModalConfirmationComponent,
    IncidentsFieldResourcesFilterComponent,
    PanelIncidentsFieldResourcesComponent,
    ModalIncidentsFieldResourcesComponent,
    GroundResourceHistoricalComponent,
    GroundResourceGroupComponent,
    PanelHistoryGroundResourceComponent,
    IncidentsKPIComponent,
    ModalIncidentsResourceComponent,
    GridButtonIconComponent,
    PanelNumberGroundResourceComponent,
    PredictiveResourcesPanelComponent,
    ListBikesComponent,
    ModalBikesComponent,
    PanelBikesComponent,
    GeneralCardComponent,
    PredictiveResourceFilterComponent,
    ListpredictiveResourcesComponent,
    ActionsIncidentsComponent,
    WazeAlertsDuplicatesModalComponent,
    WazeAlertsDuplicatesPanelComponent,
    ModalAddResourcesComponent,
    MapFilterLocationIncidentsComponent,
    MapFilterLastHoursIncidentsComponent,
    ActionsIncidentsSocialMediaTextComponent,
    AssignableResourcesComponent,
    AirQualityModalComponent,
    AirQualityIndicatorsComponent,
  ],
  exports: [
    ButtonComponent,
    CardCamerasComponent,
    CardRoadSituationsComponent,
    CardTrafficComponent,
    CardTrafficComponent,
    DropdownComponent,
    DropFilesComponent,
    DropFilesComponent,
    DropFilesComponent,
    DropFilesPhysicalResourcesComponent,
    DynamicFilterComponent,
    DynamicSideMenuComponent,
    FiltersComponent,
    FooterComponent,
    GridPageComponent,
    GridTableComponent,
    GridTablePhysicalResourcesComponent,
    GridPagePhysicalResourceComponent,
    HeaderComponent,
    IconButtonComponent,
    IconComponent,
    ImgLoaderComponent,
    ListButtonsComponent,
    LoaderOverlayComponent,
    MapViewerComponent,
    ModalComponent,
    PageTitleComponent,
    PaginatorComponent,
    SideMenuComponent,
    ScrollHandlerDirective,
    SitBarChartComponent,
    GraphicLineComponent,
    SitChartColumnComponent,
    SitChartLineColorComponent,
    SitChartSeriesComponent,
    SpeedometerComponent,
    ModalForgotPasswordComponent,
    GenericFormComponent,
    GridPageComponent,
    ListCardPageComponent,
    ListCardsReportPageComponent,
    GridButtonIconComponent,
    SitListWazeAlertsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MatRadioModule,
    ReactiveFormsModule,
    NgChartsModule,
  ],
})
export class UiCoreModule {}
