<div
  class="modal-traffic w-auto h-auto d-flex fd-col text-blue text-weight-600 pt-30"
>
  <div class="wrapper-speed pb-20">
    <div class="container-info indicator-container">

      <div
        class="container-graphic-speed d-flex fd-col justify-center align-items-center"
      >
        <sit-speedometer
          class="flex-basis-0"
          [properties]="{
            chartType: 4,
            value: +properties.speed,
            handClockRotation: speedometerRotation,
            unitLabel: 'Km/h',
            divId: 'id',
            labelsClass: 'mt-0',
            unitLabelClass: 'text-size-14'
          }"
        ></sit-speedometer>
      </div>
    </div>

    <div class="container-info indicator-container">

      <div
        class="d-flex fd-col justify-center align-items-flex-start text-white time-gap-10"
      >
        <div class="d-flex fd-col">
          <label class="text-size-16 text-weight-600 text-md-size-14 text-color-primary-1">
            {{ expMinTimeIndicator }}m {{ expSecTimeIndicator }}s
          </label>
          <label class="text-size-12 text-weight-500">Tiempo esperado</label>
        </div>
        <div class="d-flex fd-col">
          <label class="text-size-16 text-weight-600 text-md-size-14 text-color-primary-1">
            {{ actMinTimeIndicator }}m {{ actSecTimeIndicator }}s
          </label>
          <label class="text-size-12 text-weight-500">Tiempo real</label>
        </div>
      </div>
    </div>

    <div class="container-info indicator-container">

      <div class="d-flex fd-col justify-center full-width">

        <div class="height-line-bar mb-8" *ngIf="dataHistory.length > 0">
          <sit-bar-chart
                  [properties]="{
                    tid: +properties?.id,
                    dataAll: this.dataHistory,
                    type: 2
              }"
          ></sit-bar-chart>
        </div>
        <div
          class="d-flex fd-col justify-center text-white align-items-center range-gap-8"
        >
          <label class="text-size-14 text-weight-600">{{ statusIndicator.now }}</label>
          <label class="text-size-12 text-weight-500">Rango</label>
        </div>
      </div>
    </div>

    <div class="container-info indicator-container">

      <div
        class="container-graphic-speed d-flex fd-col justify-center align-items-center"
      >
        <sit-speedometer
          class="flex-basis-0"
          [properties]="{
            chartType: 5,
            value: +properties.concordance,
            handClockRotation: concordanceRotation,
            unitLabel: '%',
            divId: 'id',
            labelsClass: 'mt-0',
            unitLabelClass: 'text-size-14'
          }"
        ></sit-speedometer>
      </div>
    </div>
  </div>

  <div class="d-flex fd-row border-b-s-1 border-color-separator mb-16"></div>

  <div class="modal-body d-flex fd-col mb-1 justify-space-evenly">
    <div class="text-size-18 text-white text-weight-500 mb-25 text-md-size-14">
      <span>Rango de Velocidad</span>
    </div>

    <div class="d-flex full-width speed-range-chart">
      <sit-chart-line
        [properties]="dataGraphicList"
        [colorScale]="colorScale"
        style="width: 100%"
      >
      </sit-chart-line>
    </div>
  </div>
</div>
