import { Validators } from '@angular/forms';
import {
  CodeValidatorNameEnum,
  FormGroupNamesEnum,
  InputTypeEnum,
  IValidatorModel,
  ParametricFormI,
} from '@types-custom/models/ui/generic-form.model';
import { IncidentsListEnum } from '@types-custom/models/ui/incidents-list-enum.model';

export const involvedVehicleModel: ParametricFormI[][] = [
  [
    {
      type: InputTypeEnum.SELECT,
      label: 'Implicado ',
      formKey: 'vehicleTypeId',
      listOptionsType: IncidentsListEnum.IMPLICADO,
      formControl: {
        value: '',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.SELECT,
      label: 'Lesionados',
      formKey: 'injuredAmount',
      // listOptionsType: IncidentsListEnum.ACTOR,
      listOptionsType: IncidentsListEnum.NUM_LESIONADOS,
      formControl: {
        value: '1',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
    {
      type: InputTypeEnum.SELECT,
      label: 'Fallecidos',
      formKey: 'deadAmount',
      // listOptionsType: IncidentsListEnum.ACTOR,
      listOptionsType: IncidentsListEnum.NUM_FALLECIDOS,
      // hiddenControl: true,
      // parentFormName: FormGroupNamesEnum.MAIN,
      // parentFormKey: 'gravityId',
      formControl: {
        value: '0',
        options: {
          disabled: false,
          nonNullable: true,
        },
        validators: <IValidatorModel[]>[
          {
            validator: Validators.required,
            validatorCodeName: CodeValidatorNameEnum.REQUIRED,
            errorMessage: 'Campo obligatorio.',
          },
        ],
      },
    },
  ],
];
