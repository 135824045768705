import {
    IGeometryModel,
    MarkerModelBase,
} from '@types-custom/models/business/marker.model';

export interface IPropsFieldResourcesLocationsModel {
    id: string;
    firstName?: string;
    lastName?: string;
    documentTypeId ?: number;
    documentTypeName?: string;
    document?: string;
    email?: string;
    phone?: string;
    stateId?: number;
    stateName?: string;
    groupId?: number;
    groupName ?: string,
    indicative? : string,
    jobId ?: number,
    jobName ?: string,
    updateLocationDate? : string,
    localityName ?: string
    markerPopupText? : string
    icon?: unknown;
}

export class FieldResourcesLocationsModel extends MarkerModelBase {
    static className = 'FieldResourcesModel';
    classProperties: IPropsFieldResourcesLocationsModel;

    constructor(props: IPropsFieldResourcesLocationsModel, geometry: IGeometryModel) {
        super(geometry, FieldResourcesLocationsModel.className);
        this.classProperties = props;
    }
}
