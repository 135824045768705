export enum FormSituationsExtensionsAllowed {
  MPEG = '.mpeg',
  WMM = '.wmm',
  GP3 = '.3gp',
  AVI = '.avi',
  MP4 = '.mp4',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PNG = '.png',
  GIF = '.gif',
  BMP = '.bmp',
  TIFF = '.tiff',
  ODG = '.odg',
}

export const getExtensionAllowed = (allowedExtensions:{ [keys: string]: string }) => {
  const keys = Object.values(allowedExtensions).join(', ');
  return keys;
};

export function reverseMappingExtensionAllowed<
  T extends { [keys: string]: string }
>(myEnum: T, type: string) {
  const key = Object.keys(myEnum).find((x: string) => myEnum[x] == type);
  return key;
}
